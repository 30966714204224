import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import { IEdgeInfo, ITicketListing } from "@aptedge/lib-ui/src/types/entities";
import { GTM_EVENTS, dataLayerPush } from "@aptedge/lib-ui/src/utils/event";
import classNames from "classnames";
import React from "react";
import { useMutation } from "react-query";
import { addEdgeTicket } from "../../../clients/Edges/addEdgeTicket";
import { deleteEdgeTicket } from "../../../clients/Edges/deleteEdgeTicket";
import { MutationErrorToast } from "../../../components/ErrorHandler/MutationErrorToast";
import { TicketItem } from "../../../components/TicketItem/TicketItem";
import "./EdgeTicketResult.scss";

interface Props {
  edgeId: number;
  ticket: ITicketListing;
  onUpdate: (value: IEdgeInfo) => void;
  onPreview: (ticket: ITicketListing) => void;
}

const EdgeTicketResult: React.FC<Props> = ({
  edgeId,
  ticket,
  onUpdate,
  onPreview
}) => {
  const addEdgeTicketClient = useMutation(addEdgeTicket, {
    onSuccess: (e) => {
      setAdded(true);
      onUpdate(e);
    }
  });
  const deleteEdgeTicketClient = useMutation(deleteEdgeTicket, {
    onSuccess: (e) => {
      setAdded(false);
      onUpdate(e);
    }
  });

  const [added, setAdded] = React.useState<boolean>(
    ticket.edges.includes(edgeId)
  );

  const onAdd = (ticket: ITicketListing): void => {
    addEdgeTicketClient.mutate({ edgeId: edgeId, ticketId: ticket.id });
  };

  const onDelete = (ticket: ITicketListing): void => {
    deleteEdgeTicketClient.mutate({ edgeId: edgeId, ticketId: ticket.id });
  };

  const mutationsLoading =
    addEdgeTicketClient.isLoading || deleteEdgeTicketClient.isLoading;

  const handleAddEdge = (): void => {
    onDelete(ticket);
    dataLayerPush({ event: GTM_EVENTS.GTM_ADD_EDGE_EXAMPLE });
  };

  return (
    <>
      <TicketItem
        className={classNames("result", { added })}
        ticket={ticket}
        isLoading={mutationsLoading}
        actions={
          added ? (
            <div>
              <div className="added-label">Added</div>
              <Icons.Times
                data-testid={`delete-result-${ticket.id}`}
                onClick={() => handleAddEdge()}
              />
            </div>
          ) : (
            <Icons.Plus
              data-testid={`add-result-${ticket.id}`}
              onClick={(e) => onAdd(ticket)}
            />
          )
        }
        onSelect={onPreview}
      />
      <MutationErrorToast
        error={addEdgeTicketClient.error}
        resetError={addEdgeTicketClient.reset}
      />
      <MutationErrorToast
        error={deleteEdgeTicketClient.error}
        resetError={deleteEdgeTicketClient.reset}
      />
    </>
  );
};

export { EdgeTicketResult };
