import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import React from "react";
import { SelfServiceSettings } from "./SelfServiceSettings";
import { SupportAppSettings } from "./SupportAppSettings";

const AppsSettings: React.FC = () => {
  const { flags } = useFlags();
  return (
    <>
      <SupportAppSettings />
      {flags.cssConfigPage && <SelfServiceSettings />}
    </>
  );
};

export { AppsSettings };
