import {
  IEdgeInfo,
  IUserListData,
  IUserListing
} from "@aptedge/lib-ui/src/types/entities";
import classNames from "classnames";
import React from "react";
import { timestampToDateTimeString } from "../../utils/time";
import "./EdgeTimestamps.scss";

function EdgeTimestamps(props: {
  edge: IEdgeInfo;
  userListData: IUserListData[] | [];
}): React.ReactElement {
  const { edge, userListData } = props;
  const isBanned = (id: number): boolean => {
    const user = userListData.find((user) => user.id === +id);
    return user ? user.banned : false;
  };
  const isBannedCreatedBy = edge?.createdBy?.id
    ? isBanned(edge?.createdBy?.id)
    : false;
  const isBannedUpdatedBy = edge?.userUpdatedBy?.id
    ? isBanned(edge?.userUpdatedBy?.id)
    : false;

  return (
    <div className="edge-timestamps">
      <div className="edge-timestamps-date created">
        <label>Created</label>
        <div>{timestampToDateTimeString(edge.createdOn)}</div>
        <div>
          <span
            className={classNames("text-mutes", {
              banned: isBannedCreatedBy
            })}
          >
            {getUserDisplayValue(edge.createdBy)}{" "}
            {isBannedCreatedBy && " (Banned)"}
          </span>
        </div>
      </div>
      <div className="edge-timestamps-date updated">
        <label>Updated</label>
        <div>
          {timestampToDateTimeString(edge.userUpdatedOn || edge.updatedOn)}
        </div>
        <div>
          <span
            className={classNames("text-mutes", {
              banned: isBannedCreatedBy
            })}
          >
            {getUserDisplayValue(edge.userUpdatedBy)}{" "}
            {isBannedUpdatedBy && " (Banned)"}
          </span>
        </div>
      </div>
    </div>
  );
}

function getUserDisplayValue(user: IUserListing | null): string {
  return user?.email ?? "AptEdge";
}

export { EdgeTimestamps };
