import { getDetailsByResultType } from "@aptedge/lib-ui/src/hooks/useSearchResult";
import { useAppSelector } from "@aptedge/lib-ui/src/redux/hook/hook";
import { ResultType } from "@aptedge/lib-ui/src/types/entities";
import { useEffect, useMemo } from "react";
import { useRecentSearch } from "../../../components/GlobalSearch/useRecentSearches/useRecentSearches";

const useSearchResult = (): ResultType => {
  const { selectedSearchResult, searchResultsExpanded } = useAppSelector(
    (state) => state.search
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, addToRecentSearch] = useRecentSearch();

  const resultWithPreview = useMemo(
    () =>
      searchResultsExpanded.find(
        (searchResult) => searchResult.id === selectedSearchResult.id
      ) ?? selectedSearchResult,
    [searchResultsExpanded, selectedSearchResult]
  );

  useEffect(() => {
    addToRecentSearch(resultWithPreview);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultWithPreview]);

  const result = getDetailsByResultType(resultWithPreview);
  return result;
};

export default useSearchResult;
