import { IEdgeInfo } from "@aptedge/lib-ui/src/types/entities";
import React from "react";
import { ArchivedBadge } from "./ArchivedBadge";
import { AutoLinkBadges } from "./AutoLinkBadges";
import "./EdgeBadges.scss";
import { MergedIntoBadge } from "./MergedIntoBadge";

interface Props {
  edge: IEdgeInfo;
}

const EdgeBadges: React.FC<Props> = ({ edge }) => {
  return (
    <div className="edge-badges">
      <AutoLinkBadges
        isAutolinkEnabled={edge.isAutolinkEnabled}
        isAutoreplyEnabled={edge.isAutoreplyEnabled}
      />
      <ArchivedBadge archived={edge.archived} />
      <MergedIntoBadge id={edge.id} />
    </div>
  );
};

export { EdgeBadges };
