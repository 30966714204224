import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { ModalFooter } from "@aptedge/lib-ui/src/components/Modal/Modal";
import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import {
  IEdgeInfo,
  IEdgeSearchResult
} from "@aptedge/lib-ui/src/types/entities";
import React, { useEffect } from "react";
import { UseMutationResult } from "react-query";
import { IMergeEdgeArgs } from "../../../types/clients";

interface Props {
  client: UseMutationResult<IEdgeInfo, Error, IMergeEdgeArgs>;
  sourceEdges: IEdgeSearchResult[];
  targetEdge: IEdgeInfo;
  onBack: () => void;
}

function MergeEdgeModalExec(props: Props): React.ReactElement {
  const { client, sourceEdges, targetEdge, onBack } = props;
  const edgeIds = sourceEdges.map((e) => ({ id: e.id }));

  useEffect(() => {
    client.mutate({
      edgeId: targetEdge.id,
      mergeSourceEdges: edgeIds
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="merge-edge-content loading-screen">
        {client.isLoading && <Spinner />}
        {client.isError && (
          <div className="text-center text-danger">
            We&apos;re sorry. Something went wrong.
          </div>
        )}
      </div>
      {client.isError && (
        <ModalFooter>
          <div className="d-flex justify-content-start">
            <Button color="secondary" onClick={onBack}>
              Go Back
            </Button>
          </div>
        </ModalFooter>
      )}
    </>
  );
}

export { MergeEdgeModalExec };
