import classNames from "classnames";
import React from "react";
import { Column } from "./useTable";

type Props =
  | React.DetailedHTMLProps<
      React.TdHTMLAttributes<HTMLTableDataCellElement>,
      HTMLTableDataCellElement
    >
  | React.DetailedHTMLProps<
      React.ThHTMLAttributes<HTMLTableDataCellElement>,
      HTMLTableDataCellElement
    >;

function getCellProps<T>(col: Column<T>, isLoading?: boolean): Partial<Props> {
  const { align, justify } = col;

  return {
    className: classNames({
      [`align-${align}`]: !!align,
      [`text-${justify}`]: !!justify,
      loading: !!isLoading
    })
  };
}

function pxify(val: number | string): string {
  return typeof val === "string" ? val : `${val}px`;
}

function getCssGridMinMax(
  minWidth: number | "min-content" | "max-content",
  fr?: number | string
): string {
  const min = pxify(minWidth);
  const max = fr ? fr : "auto";

  const minMax = [min, pxify(max)];

  return `minmax(${minMax.join(", ")})`;
}

export { getCellProps, getCssGridMinMax };
