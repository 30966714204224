import { CACHE_KEY } from "@aptedge/lib-ui/src/data/cacheKeys";
import { useAppDispatch } from "@aptedge/lib-ui/src/redux/hook/hook";
import { updateCurrentUserRole } from "@aptedge/lib-ui/src/redux/reduxSlice/appSlice";
import { UserRole, UserSupport } from "@aptedge/lib-ui/src/types/entities";
import { WHOAMI_QUERY_OPTIONS } from "@aptedge/lib-ui/src/utils/query";
import { useEffect } from "react";
import { useQuery, useQueryClient, UseQueryResult } from "react-query";
import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";

export type WhoAmIResponse = UseQueryResult<UserSupport>;

function getWhoAmI(): Promise<UserSupport> {
  const url = `${getBackendApiUrl()}/whoami/`;

  return request(url);
}

export const useWhoami = (): WhoAmIResponse => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  // unlike the support app, no need to include the user email in the cache key -- we clear the
  // cache on logout or login, and we don't know the email at this point anyway (unlike in the
  // support app, where we have the email from the SDK)
  const query = useQuery([CACHE_KEY.WHOAMI], getWhoAmI, {
    ...WHOAMI_QUERY_OPTIONS,
    onSuccess: (data) => {
      dispatch(updateCurrentUserRole(data.role));
    }
  });

  // Check for cached data on component mount and dispatch it if present
  useEffect(() => {
    const cachedData: WhoAmIResponse | undefined = queryClient.getQueryData<
      WhoAmIResponse
    >([CACHE_KEY.WHOAMI]);
    if (cachedData && "role" in cachedData) {
      const { role } = cachedData;
      dispatch(updateCurrentUserRole(role as UserRole));
    }
  }, [queryClient, dispatch]);

  return query;
};
