import { ConfigResponse } from "@aptedge/lib-ui/src/types/selfService";
import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";

function fetchCssConfig(): Promise<ConfigResponse> {
  const url = `${getBackendApiUrl()}/self-service/config/`;

  return request(url);
}

export { fetchCssConfig };
