import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { ISharepointSource } from "@aptedge/lib-ui/src/types/entities";
import { useFormik } from "formik";
import React from "react";
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label
} from "reactstrap";
import * as Yup from "yup";

export interface SharepointFormData {
  tenantId: string;
}

interface Props {
  source: ISharepointSource | null;
  onSubmit: (formData: SharepointFormData) => void;
}

const SharepointConfigForm: React.FunctionComponent<Props> = ({
  source,
  onSubmit
}) => {
  const formik = useFormik<SharepointFormData>({
    validationSchema: Yup.object({
      tenantId: Yup.string().required("Please enter the Tenant ID!")
    }),
    initialValues: {
      tenantId: (source && source.tenantId) || ""
    },
    validateOnChange: false,
    onSubmit: (values: SharepointFormData) =>
      onSubmit({ tenantId: values.tenantId.trim() })
  });

  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <FormGroup row>
          <Label sm={3} for="tenantId">
            Tenant Id
          </Label>
          <Col sm={9}>
            <Input
              type="text"
              name="tenantId"
              placeholder=""
              value={formik.values.tenantId}
              onChange={(e) => formik.setValues({ tenantId: e.target.value })}
              invalid={!!formik.errors.tenantId}
            />
            <FormFeedback>{formik.errors.tenantId}</FormFeedback>
            <FormText>Tenant Id provided by Sharepoint</FormText>
          </Col>
        </FormGroup>
        <div className="float-right">
          <Button type="submit">Authorize</Button>
        </div>
      </Form>
    </>
  );
};

export { SharepointConfigForm };
