import { IFlagSet } from "@aptedge/lib-ui/src/context/FlagsContext/types";
import { FC } from "react";
import { AnswerSearchResult } from "../../components/AnswerSearchResult/AnswerSearchResult";

type AnswerResultType = {
  answerId: string;
  flags: IFlagSet;
};

const AnswerResult: FC<AnswerResultType> = ({ answerId }) => {
  return <>{answerId && <AnswerSearchResult />}</>;
};

export default AnswerResult;
