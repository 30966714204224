import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import React from "react";
import { EdgeBulkReply } from "../../../components/EdgeBulkReply/EdgeBulkReply";
import { useBulkReplyContext } from "../../../context/BulkReplyContext";

function BulkReplySuccessPage(props: {
  onClose: () => void;
}): React.ReactElement | null {
  const { onClose } = props;

  const [{ newReply }] = useBulkReplyContext();

  if (!newReply) return null;

  return (
    <React.Fragment>
      <div className="reply-summary">
        <EdgeBulkReply reply={newReply} />
      </div>
      <div className="buttons">
        <Button color="secondary" onClick={onClose}>
          Close
        </Button>
      </div>
    </React.Fragment>
  );
}

export { BulkReplySuccessPage };
