import { MarkdownContent } from "@aptedge/lib-ui/src/components/MarkdownContent/MarkdownContent";
import { IRichTextContent } from "@aptedge/lib-ui/src/components/TextEditor/TextEditor";
import { IEdgeAuditLogCommentChange } from "@aptedge/lib-ui/src/types/entities";
import classNames from "classnames";
import React from "react";
import { ArrowTransition } from "./ArrowTransition";
import { SummarySentence } from "./SummarySentence";
import { WithAuditLog } from "./WithAuditLog";
import type { AuditDetailsFC, AuditSummaryFC } from "./WithAuditLog";

const Details: AuditDetailsFC<IEdgeAuditLogCommentChange> = (props) => {
  const { log } = props;
  const { oldText, newText } = getOldAndNew(log.data);

  const from = oldText ? (
    <MarkdownContent
      className={classNames({ deleted: !!log.data.deleted.length })}
      content={oldText.content}
    />
  ) : null;
  const to = newText ? (
    <MarkdownContent
      className={classNames({ added: !!log.data.added.length })}
      content={newText.content}
    />
  ) : null;

  return (
    <div className="audit-log-comment-change">
      <ArrowTransition from={from} to={to} />
    </div>
  );
};

function getOldAndNew({
  added,
  edited,
  deleted
}: IEdgeAuditLogCommentChange["data"]): {
  oldText: IRichTextContent | null;
  newText: IRichTextContent | null;
} {
  const oldText = added.length
    ? null
    : edited.length
    ? edited[0].oldText
    : deleted.length
    ? deleted[0].oldText
    : null;

  const newText = added.length
    ? added[0].newText
    : edited.length
    ? edited[0].newText
    : deleted.length
    ? null
    : null;

  return { oldText, newText };
}

const Summary: AuditSummaryFC<IEdgeAuditLogCommentChange> = (props) => {
  const { VERBS, OBJECTS } = SummarySentence;
  const { log, author } = props;
  const didAdd = Object.keys(log.data.added).length > 0;
  const didEdit = Object.keys(log.data.edited).length > 0;

  const verb = didAdd ? VERBS.ADDED : didEdit ? VERBS.EDITED : VERBS.DELETED;

  return (
    <SummarySentence
      subject={author}
      verb={verb}
      object={OBJECTS.COMMENT}
      displayText={log?.displayText ?? ""}
    />
  );
};

Details.displayName = "AuditCommentChangeDetails";
const AuditLogCommentChange = WithAuditLog(Details, Summary);

export { AuditLogCommentChange };
