import classNames from "classnames";
import React, { HTMLAttributes, MouseEvent } from "react";
import { Icons } from "../Icon/Icons";
import "./Chip.scss";

export interface ChipProps extends HTMLAttributes<HTMLSpanElement> {
  label: string;
  className?: string;
  disabled?: boolean;
  onDelete?: () => void;
}

function Chip(props: ChipProps): React.ReactElement {
  const { label, disabled, className, onDelete, ...rest } = props;

  const handleDelete = (e: MouseEvent<HTMLSpanElement>): void => {
    e.preventDefault();

    if (!disabled && !!onDelete) {
      onDelete();
    }
  };

  return (
    <span
      {...rest}
      className={classNames("chip", className, {
        disabled,
        deleteable: !!onDelete
      })}
    >
      <span className="label">{label}</span>
      {!!onDelete && !disabled && (
        <div className="delete">
          <Icons.TimesCircle
            onClick={handleDelete}
            tabIndex={-1}
            data-testid="chip-icon"
          />
        </div>
      )}
    </span>
  );
}

export { Chip };
