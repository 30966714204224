import { FilterWrapper } from "@aptedge/lib-ui/src/components/Filter/FilterWrapper";
import { IQueryFilter } from "@aptedge/lib-ui/src/components/Filter/types";
import { MultiSelect } from "@aptedge/lib-ui/src/components/MultiSelect/MultiSelect";
import React from "react";
import { useTicketTypes } from "../../hooks/clients/useTicketTypes";
import { TICKET_TYPE_FILTER } from "./data";

type Props = {
  value?: IQueryFilter;
  onChange: (val: IQueryFilter) => void;
};

function TicketTypeFilter(props: Props): React.ReactElement {
  const ticketTypesQuery = useTicketTypes();

  const ticketTypes =
    ticketTypesQuery.data?.ticketTypes
      .slice()
      .sort((a, b) => a.localeCompare(b)) || [];

  return (
    <FilterWrapper {...TICKET_TYPE_FILTER} {...props}>
      {({ values, onChange }) => (
        <MultiSelect
          name="type-name-filter"
          value={values}
          data={ticketTypes}
          getOptionLabel={(status) => status}
          onChange={(statuses) => onChange(statuses)}
        />
      )}
    </FilterWrapper>
  );
}

export { TicketTypeFilter };
