import { IConfluenceCloudArticleSource } from "@aptedge/lib-ui/src/types/entities";
import { getBackendUrl } from "../../config";
import { request } from "../../utils/request";

function fetchConfluenceCloudArticleSources(): Promise<
  IConfluenceCloudArticleSource[]
> {
  const url = `${getBackendUrl()}/confluence/tap`;

  return request(url);
}

export { fetchConfluenceCloudArticleSources };
