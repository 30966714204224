import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";
import { post } from "../utils/buildRequest";

interface UpdateMyProductsArgs {
  selectedIds: string[];
  myProductsFilterId: string;
}

async function updateMyProducts({
  selectedIds,
  myProductsFilterId
}: UpdateMyProductsArgs): Promise<void> {
  const url = `${getBackendApiUrl()}/apt-answers/save-filters`;
  const options = post({
    id: myProductsFilterId,
    filterIds: { productFilters: selectedIds }
  });

  return request(url, options);
}

export { updateMyProducts };
