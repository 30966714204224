import { AeIcons } from "@aptedge/lib-ui/src/components/Icon/AeIcons";
import {
  AptAnswersFilterRule,
  AptAnswersFilterRuleUpdate
} from "@aptedge/lib-ui/src/types/entities";
import classNames from "classnames";
import * as React from "react";
import styles from "./FilterRulesItemValueInput.module.scss";

interface FilterRulesItemValueInputProps {
  rule: AptAnswersFilterRule;
  idx: number;
  handleRuleUpdate: (o: AptAnswersFilterRuleUpdate) => void;
  handleDeleteRule: (idx: number) => void;
  isDisabled: boolean;
  isTop: boolean;
  isBottom: boolean;
}

const FilterRulesItemValueInput: React.FC<FilterRulesItemValueInputProps> = ({
  rule,
  idx,
  handleRuleUpdate,
  handleDeleteRule,
  isDisabled,
  isTop,
  isBottom
}) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    handleRuleUpdate({ idx, key: "value", value: e.target.value });
  };

  return (
    <div
      className={classNames(styles.container, {
        [styles.inputTop]: isTop,
        [styles.inputBottom]: isBottom
      })}
    >
      <input
        className={classNames({
          [styles.inputTop]: isTop,
          [styles.inputBottom]: isBottom,

          [styles.inputDisabled]: isDisabled
        })}
        placeholder="Enter value here"
        value={rule.value}
        onChange={handleInputChange}
        disabled={isDisabled}
      />
      <button onClick={() => handleDeleteRule(idx)}>
        <AeIcons.Trash />
      </button>
    </div>
  );
};

export { FilterRulesItemValueInput };
