import {
  AptAnswersFilterType,
  MyProductsListItem
} from "@aptedge/lib-ui/src/types/entities";
import { useQuery } from "react-query";
import { fetchFilters } from "../../clients/AptAnswers/fetchFilters";
import { WebCacheKey } from "../../clients/cache";
import { fetchMyProducts } from "../../clients/MyProducts/fetchMyProducts";

interface UseMyProductsValue {
  isError: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  isEmpty: boolean;
  myProductsFilterId: string | undefined;
  savedSelectedIds: string[];
  productItems: MyProductsListItem[];
}

function useMyProducts(): UseMyProductsValue {
  const productFiltersQuery = useQuery(
    [WebCacheKey.APT_ANSWERS_FILTERS, AptAnswersFilterType.PRODUCT_FILTER],
    () => fetchFilters({ filterType: AptAnswersFilterType.PRODUCT_FILTER }),
    { refetchOnWindowFocus: false }
  );

  const myProductsQuery = useQuery(
    [WebCacheKey.APT_ANSWERS_MY_PRODUCTS],
    () => fetchMyProducts(),
    {
      refetchOnWindowFocus: false
    }
  );

  const myProductsFilterId = myProductsQuery.data?.id;

  const savedSelectedIds =
    myProductsQuery.data?.filterIds?.productFilters ?? [];

  const productFilterData =
    productFiltersQuery.data?.[AptAnswersFilterType.PRODUCT_FILTER] ?? {};
  const productItems = Object.entries(productFilterData).map((item) => {
    const name = item[0];
    const id = item[1][0]["id"];
    return {
      id,
      name
    };
  });

  const isLoading = productFiltersQuery.isLoading || myProductsQuery.isLoading;
  const isError = productFiltersQuery.isError || myProductsQuery.isError;
  const isSuccess = productFiltersQuery.isSuccess || myProductsQuery.isSuccess;
  const isEmpty = productItems.length === 0;

  return {
    isEmpty,
    isError,
    isLoading,
    isSuccess,
    savedSelectedIds,
    myProductsFilterId,
    productItems
  };
}

export { useMyProducts };
