import { IQueryFilter } from "@aptedge/lib-ui/src/components/Filter/types";
import {
  IEdgeInfo,
  ISavedQuery,
  SavedQueryType
} from "@aptedge/lib-ui/src/types/entities";
import { GTM_EVENTS, dataLayerPush } from "@aptedge/lib-ui/src/utils/event";
import { noop } from "@aptedge/lib-ui/src/utils/noop";
import {
  IQueryArgs,
  toLZCompressedString
} from "@aptedge/lib-ui/src/utils/query";
import React, { createContext, useState } from "react";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient
} from "react-query";
import { WebCacheKey } from "../clients/cache";
import { createSavedQuery } from "../clients/SavedQueries/createSavedQuery";
import { deleteSavedQuery } from "../clients/SavedQueries/deleteSavedQuery";
import { fetchSavedQueryById } from "../clients/SavedQueries/fetchSavedQueryById";
import { updateSavedQuery } from "../clients/SavedQueries/updateSavedQuery";
import { ICreateSavedQueryArgs, IUpdateSavedQueryArgs } from "../types/clients";

interface IContext {
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
  currentSavedQuery?: ISavedQuery;
  setCurrentSavedQuery: (value?: ISavedQuery) => void;
  isSaveModalOpen: boolean;
  setIsSaveModalOpen: (value: boolean) => void;
  isDeleteModalOpen: boolean;
  setIsDeleteModalOpen: (value: boolean) => void;
  updateQuery?: UseMutationResult<
    ISavedQuery,
    unknown,
    IUpdateSavedQueryArgs,
    unknown
  >;
  createQuery?: UseMutationResult<
    ISavedQuery,
    unknown,
    ICreateSavedQueryArgs,
    unknown
  >;
  deleteQuery?: UseMutationResult<void, unknown, number>;
  handleSubmit: (params: IQueryArgs, edgeId: number) => void;
  reply: string;
  setReply: (value: string) => void;
  isInternal: boolean;
  setIsInternal: (value: boolean) => void;
  isDisabled: boolean;
  setIsDisabled: (value: boolean) => void;
  selectedTimeDimensionFilter: string;
  setSelectedTimeDimensionFilter: React.Dispatch<React.SetStateAction<string>>;
}

const defaultContext: IContext = {
  isEditing: false,
  setIsEditing: noop,
  currentSavedQuery: undefined,
  setCurrentSavedQuery: noop,
  isSaveModalOpen: false,
  setIsSaveModalOpen: noop,
  isDeleteModalOpen: false,
  setIsDeleteModalOpen: noop,
  updateQuery: undefined,
  createQuery: undefined,
  deleteQuery: undefined,
  handleSubmit: noop,
  reply: "",
  setReply: noop,
  isInternal: true,
  setIsInternal: noop,
  isDisabled: false,
  setIsDisabled: noop,
  setSelectedTimeDimensionFilter: noop,
  selectedTimeDimensionFilter: "createdOn"
};

interface Props {
  edge: IEdgeInfo;
}

const AutoLinkContext = createContext<IContext>(defaultContext);

const AutoLinkProvider: React.FC<Props> = ({ edge, children }) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [currentSavedQuery, setCurrentSavedQuery] = useState<ISavedQuery>();
  const autoLinkId = !!edge.filters.length ? edge.filters[0] : undefined;
  const queryClient = useQueryClient();
  const [isSaveModalOpen, setIsSaveModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [reply, setReply] = useState<string>("");
  const [isInternal, setIsInternal] = useState<boolean>(true);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [
    selectedTimeDimensionFilter,
    setSelectedTimeDimensionFilter
  ] = useState<string>("createdOn");
  useQuery(
    [WebCacheKey.SAVED_QUERY, autoLinkId],
    () => fetchSavedQueryById(autoLinkId || 0),
    {
      enabled: !!autoLinkId,
      onSuccess: (s) => {
        setCurrentSavedQuery(s);
        if (s.reply) {
          setReply(s.reply);
        }
        if (s.replyDisabled !== undefined) {
          setIsDisabled(s.replyDisabled);
        }
        if (s.replyInternal !== undefined) {
          setIsInternal(s.replyInternal);
        }
      }
    }
  );

  const onSaveSuccess = (value: ISavedQuery): void => {
    dataLayerPush({ event: GTM_EVENTS.GTM_UPDATE_AUTO_LINK });
    setIsSaveModalOpen(false);
    setCurrentSavedQuery(value);
    queryClient.invalidateQueries([WebCacheKey.SAVED_QUERY, autoLinkId]);
  };

  const updateQuery = useMutation(updateSavedQuery, {
    onSuccess: onSaveSuccess
  });
  const createQuery = useMutation(createSavedQuery, {
    onSuccess: (data) => {
      onSaveSuccess(data);
      queryClient.invalidateQueries(WebCacheKey.EDGE);
    }
  });

  const deleteQuery = useMutation(deleteSavedQuery, {
    onSuccess: () => {
      setIsDeleteModalOpen(false);
      setCurrentSavedQuery(undefined);
      setReply("");
      setIsDisabled(false);
      queryClient.invalidateQueries(WebCacheKey.EDGE);
      deleteQuery.reset();
    }
  });

  const getUpdatedFilters = (
    autoLinkfilters: IQueryFilter[] = []
  ): IQueryFilter[] => {
    const autoLinkfiltersWithoutTimeDimensionFilter = autoLinkfilters.filter(
      (autoLinkFilter: IQueryFilter) => autoLinkFilter.id !== "timestamp"
    );
    const timeDimensionFilter = {
      id: "timestamp",
      operator: "equals",
      values: [selectedTimeDimensionFilter]
    } as IQueryFilter;

    const newFilters = [
      ...autoLinkfiltersWithoutTimeDimensionFilter,
      timeDimensionFilter
    ];
    return newFilters;
  };
  const handleSubmit = (params: IQueryArgs, edgeId: number): void => {
    // Saves only the filters/time dimensions in the query string

    const queryString = toLZCompressedString({
      timeDimensions: params.timeDimensions,
      filters: getUpdatedFilters(params.filters)
    });

    if (!!currentSavedQuery) {
      if (reply) {
        dataLayerPush({ event: GTM_EVENTS.GTM_UPDATE_AUTO_REPLY });
        updateQuery.mutate({
          id: currentSavedQuery.id,
          query: queryString,
          reply,
          replyDisabled: reply.trim() ? isDisabled : true,
          replyInternal: isInternal
        });
      } else {
        updateQuery.mutate({
          id: currentSavedQuery.id,
          query: queryString
        });
      }
    } else if (!!queryString) {
      if (reply) {
        dataLayerPush({ event: GTM_EVENTS.GTM_UPDATE_AUTO_REPLY });
        createQuery.mutate({
          name: `EDGE-${edgeId} Autolink Conditions`,
          type: SavedQueryType.EDGE_AUTOLINKING,
          query: queryString,
          reply,
          replyDisabled: reply.trim() ? isDisabled : true,
          replyInternal: isInternal,
          edgeId
        });
      } else {
        createQuery.mutate({
          name: `EDGE-${edgeId} Autolink Conditions`,
          type: SavedQueryType.EDGE_AUTOLINKING,
          query: queryString,
          edgeId
        });
      }
    }
  };

  React.useEffect(() => {
    if (updateQuery.isSuccess) {
      updateQuery.reset();
    }
  }, [updateQuery]);

  React.useEffect(() => {
    if (createQuery.isSuccess) {
      createQuery.reset();
    }
  }, [createQuery]);

  React.useEffect(() => {
    if (!autoLinkId) {
      setCurrentSavedQuery(undefined);
    }
  }, [autoLinkId]);

  return (
    <AutoLinkContext.Provider
      value={{
        isEditing,
        setIsEditing,
        currentSavedQuery,
        setCurrentSavedQuery,
        handleSubmit,
        isSaveModalOpen,
        setIsSaveModalOpen,
        isDeleteModalOpen,
        setIsDeleteModalOpen,
        updateQuery,
        createQuery,
        deleteQuery,
        reply,
        setReply,
        isInternal,
        setIsInternal,
        isDisabled,
        setIsDisabled,
        selectedTimeDimensionFilter,
        setSelectedTimeDimensionFilter
      }}
    >
      {children}
    </AutoLinkContext.Provider>
  );
};

export { AutoLinkProvider, AutoLinkContext };
