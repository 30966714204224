import classNames from "classnames";
import React from "react";
import { deleteKBExclusionConfig } from "../../../clients/KnowledgeBaseConfig/deleteKBExclusionConfig";
import { InlineDeleteConfirmation } from "../common/InlineDeleteConfirmation";
import { ListItem } from "../common/ListItem";

interface Props {
  url: string;
  className?: string;
  onChange: () => void;
}
const KnowledgeBaseExclusionItem: React.FC<Props> = (props) => {
  const { url, className, onChange } = props;

  return (
    <>
      <ListItem className={classNames("article-list-item", "group", className)}>
        <InlineDeleteConfirmation
          deleteFn={() => deleteKBExclusionConfig(url)}
          renderContent={() => <div>{url}</div>}
          renderConfirmation={() => (
            <span className="text-muted pr-2">
              <span>Are you sure you want to delete {url}?</span>
            </span>
          )}
          onConfirm={onChange}
        />
      </ListItem>
    </>
  );
};

export { KnowledgeBaseExclusionItem };
