import * as React from "react";
import styles from "./FilterSaveButton.module.scss";

interface FilterSaveButtonProps {
  handleSave: () => void;
}

const FilterSaveButton: React.FC<FilterSaveButtonProps> = ({ handleSave }) => {
  return (
    <button className={styles.saveButton} onClick={handleSave}>
      Save
    </button>
  );
};

export { FilterSaveButton };
