import { IEdgeInfo } from "@aptedge/lib-ui/src/types/entities";
import { GTM_EVENTS, dataLayerPush } from "@aptedge/lib-ui/src/utils/event";
import React from "react";
import { useMutation } from "react-query";
import { updateEdge } from "../../clients/Edges/updateEdge";
import { EdgeSectionLabel } from "./EdgeSectionLabel";
import { SpaceSelect } from "./SpaceSelect";

interface Props {
  edge: IEdgeInfo;
  onUpdateEdge: (updatedEdge: IEdgeInfo) => void;
}

function EdgeSpace({ edge, onUpdateEdge }: Props): React.ReactElement {
  const updateSpace = useMutation(updateEdge, {
    onSuccess: (updatedEdge) => {
      dataLayerPush({ event: GTM_EVENTS.GTM_UPDATE_EDGE_SPACE });
      onUpdateEdge(updatedEdge);
    }
  });

  return (
    <React.Fragment>
      <EdgeSectionLabel>Space</EdgeSectionLabel>
      <SpaceSelect
        value={edge.space}
        isDisabled={edge.archived}
        isLoading={updateSpace.isLoading}
        onChange={(s) =>
          updateSpace.mutate({
            edgeId: edge.id,
            patchContent: { spaceName: s }
          })
        }
      />
    </React.Fragment>
  );
}

export { EdgeSpace };
