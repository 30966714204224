import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import { ProgressBar } from "@aptedge/lib-ui/src/components/ProgressBar/ProgressBar";
import { IBulkReply, IJobStatus } from "@aptedge/lib-ui/src/types/entities";
import capitalize from "lodash/capitalize";
import React from "react";
import { jobIsPending, useJobStatus } from "../../hooks/clients/useJobStatus";
import "./EdgeBulkReplyStatus.scss";

interface Props {
  reply: IBulkReply;
}

function EdgeBulkReplyStatus(props: Props): React.ReactElement {
  const { reply } = props;
  const { runId, status: replyStatus } = reply;

  // Only poll for job status if the Reply's status is either "pending" or "running"
  const isPending = jobIsPending(replyStatus);
  const jobStatusQuery = useJobStatus(runId, {
    enabled: isPending
  });
  const status = isPending ? jobStatusQuery.data?.status : replyStatus;

  return (
    <div className="edge-bulk-reply-status">
      <Status status={status} />
    </div>
  );
}

function Status(props: { status?: IJobStatus }): React.ReactElement | null {
  const { status } = props;

  if (!status) {
    return null;
  }

  if (jobIsPending(status)) {
    return (
      <div className="progress-container">
        <ProgressBar amount={50} />
        <span>{capitalize(status)}</span>
      </div>
    );
  }

  if (status === "success") {
    return (
      <div>
        <Icons.Check />
        <span>Sent</span>
      </div>
    );
  }

  if (status === "failed") {
    return (
      <div>
        <Icons.Exclamation />
        <span>Failed to send</span>
      </div>
    );
  }

  return null;
}

export { EdgeBulkReplyStatus };
