import { PaginationSortOption } from "../PaginationSort/PaginationSort";

const PINNED_SORT: PaginationSortOption = {
  id: "pinned",
  desc: true,
  label: "Pinned",
  omitLabelFromJoin: true
};

const TICKET_SORT: PaginationSortOption = {
  id: "ticketCount",
  desc: true,
  label: "All Tickets"
};

const NAME_SORT: PaginationSortOption = {
  id: "name",
  desc: false,
  label: "Name",
  omitLabelFromJoin: true
};

const REVENUE_SORT: PaginationSortOption = {
  id: "value",
  desc: true,
  label: "Revenue at Risk"
};

const CUST_TICKET_SORT: PaginationSortOption[] = [
  PINNED_SORT,
  TICKET_SORT,
  NAME_SORT
];

const CUST_REVENUE_SORT: PaginationSortOption[] = [
  PINNED_SORT,
  REVENUE_SORT,
  NAME_SORT
];

export { CUST_TICKET_SORT, CUST_REVENUE_SORT };
