import { IEdgeAuditLogTeamChange } from "@aptedge/lib-ui/src/types/entities";
import React from "react";
import { ArrowTransition } from "./ArrowTransition";
import { SummarySentence } from "./SummarySentence";
import { AuditDetailsFC, AuditSummaryFC, WithAuditLog } from "./WithAuditLog";

const UNASSIGNED = "Unassigned";

const Details: AuditDetailsFC<IEdgeAuditLogTeamChange> = (props) => {
  const { log } = props;
  const { currentTeam, previousTeam } = log.data;
  const from = previousTeam ? previousTeam.name : UNASSIGNED;
  const to = currentTeam ? currentTeam.name : UNASSIGNED;

  return (
    <div className="audit-log-team-change">
      <ArrowTransition from={<span>{from}</span>} to={<span>{to}</span>} />
    </div>
  );
};

const Summary: AuditSummaryFC<IEdgeAuditLogTeamChange> = (props) => {
  const { author, log } = props;

  return (
    <SummarySentence
      subject={author}
      verb={SummarySentence.VERBS.UPDATED}
      object={SummarySentence.OBJECTS.TEAM}
      displayText={log?.displayText ?? ""}
    />
  );
};

Details.displayName = "AuditTeamChangeDetails";
const AuditLogTeamChange = WithAuditLog(Details, Summary);

export { AuditLogTeamChange };
