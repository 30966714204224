import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { IJiraCloudInternalIssueSource } from "@aptedge/lib-ui/src/types/entities";
import { useFormik } from "formik";
import React from "react";
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label
} from "reactstrap";
import * as Yup from "yup";

export interface JiraCloudFormData {
  oauthClientId: string;
  oauthClientSecret: string;
  configuredBaseUrl: string;
}

interface Props {
  source: IJiraCloudInternalIssueSource | null;
  onSubmit: (formData: JiraCloudFormData) => void;
}

const JiraCloudConfigForm: React.FunctionComponent<Props> = ({
  source,
  onSubmit
}) => {
  const formik = useFormik<JiraCloudFormData>({
    validationSchema: Yup.object({
      oauthClientId: Yup.string().required("Please enter a client ID."),
      oauthClientSecret: Yup.string().required("Please enter a client secret.")
    }),
    initialValues: {
      oauthClientId: (source && source.oauthClientId) || "",
      oauthClientSecret: (source && source.oauthClientSecret) || "",
      configuredBaseUrl: (source && source.configuredBaseUrl) || ""
    },
    validateOnChange: false,
    onSubmit: onSubmit
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <FormGroup row>
        <Label sm={3} for="clientId">
          OAuth Client ID
        </Label>
        <Col sm={9}>
          <Input
            data-testid="jira-oauthClientId"
            type="text"
            name="oauthClientId"
            placeholder=""
            value={formik.values.oauthClientId}
            onChange={formik.handleChange}
            invalid={!!formik.errors.oauthClientId}
          />
          <FormFeedback>{formik.errors.oauthClientId}</FormFeedback>
          <FormText>Name of the configured OAuth client</FormText>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3} for="clientSecret">
          OAuth Client Secret
        </Label>
        <Col sm={9}>
          <Input
            data-testid="jira-oauthClientSecret"
            type="text"
            name="oauthClientSecret"
            placeholder=""
            value={formik.values.oauthClientSecret}
            onChange={formik.handleChange}
            invalid={!!formik.errors.oauthClientSecret}
          />
          <FormFeedback>{formik.errors.oauthClientSecret}</FormFeedback>
          <FormText>
            Client Secret provided to you in creating an OAuth client
          </FormText>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3} for="configuredBaseUrl">
          Jira Base URL
        </Label>
        <Col sm={9}>
          <Input
            data-testid="jira-configuredBaseUrl"
            type="text"
            name="configuredBaseUrl"
            placeholder=""
            value={formik.values.configuredBaseUrl}
            onChange={formik.handleChange}
            invalid={!!formik.errors.configuredBaseUrl}
          />
          <FormFeedback>{formik.errors.configuredBaseUrl}</FormFeedback>
          <FormText>
            This is also the domain name URL of your jira instance. E.g.
            https://aptedge.atlassian.net
          </FormText>
        </Col>
      </FormGroup>
      <div className="float-right">
        <Button type="submit">Authorize</Button>
      </div>
    </Form>
  );
};

export { JiraCloudConfigForm };
