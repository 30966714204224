import { INotificationConfig } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";
import { put } from "../utils/buildRequest";

function updateNotificationConfig(
  args: Partial<INotificationConfig>
): Promise<void> {
  const url = `${getBackendApiUrl()}/users/notification-config/`;

  return request(url, put(args));
}

export { updateNotificationConfig };
