import classNames from "classnames";
import { DateTime, Interval } from "luxon";
import React, { useState, useEffect, useRef } from "react";
import { useOnKeydownOutside } from "../../hooks/useOnKeyDownOutside";
import { useOnMouseDownOutside } from "../../hooks/useOnMouseDownOutside";
import { Keys } from "../../styles/keys";
import { DateRange, DateType } from "../../types/date";
import { dateRangeToLabel } from "../../utils/date";
import { Calendar } from "../Calendar/Calendar";
import { DateRangeShortcuts } from "../DateRangeShortcuts/DateRangeShortcuts";
import { Shortcut } from "../DateRangeShortcuts/shortcuts";
import { Icons } from "../Icon/Icons";
import { Label } from "../Label/Label";
import { Popover } from "../Popover/Popover";
import { DEFAULT_PREVENT_OVERFLOW_MODIFIER } from "../Popper/popper";
import "./DateRangePicker.scss";

interface Props {
  min?: DateTime;
  max?: DateTime;
  value?: DateRange;
  placeholder?: string;
  disabled?: boolean;
  label?: string;
  shortcuts?: Shortcut[];
  onChange: (value: DateRange) => void;
}

const DateRangePicker: React.FC<Props> = ({
  value,
  label = "Date range",
  placeholder,
  min,
  max,
  disabled,
  shortcuts,
  onChange
}) => {
  const popupRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [endMonth, setEndMonth] = useState<DateTime>(() =>
    getFirstDayOfMonth(value)
  );
  const [firstDay, setFirstDay] = useState<DateTime>();
  const startMonth = endMonth.minus({ months: 1 });
  const [calendarOpen, setCalendarOpen] = useState<boolean>(false);

  const close = (): void => {
    if (isOpen) setIsOpen(false);
    setCalendarOpen(false);
  };
  const toggle = (e: React.MouseEvent<HTMLSpanElement>): void => {
    // Prevent the toggle from bubbling up to the onMouseDownOutside handler.
    e.stopPropagation();
    if (!disabled) setIsOpen(!isOpen);
    setCalendarOpen(false);
  };

  useOnKeydownOutside(popupRef, [Keys.ESCAPE, Keys.TAB], close);
  useOnMouseDownOutside(popupRef, close);

  const handleSelectDay = (day: DateTime): void => {
    if (!!firstDay) {
      const isBackwards = day < firstDay;
      const start = isBackwards ? day.startOf("day") : firstDay.startOf("day");
      const end = isBackwards ? firstDay.endOf("day") : day.endOf("day");

      onChange({
        type: DateType.ABSOLUTE,
        value: Interval.fromDateTimes(start, end)
      });
      setFirstDay(undefined);
      close();
    } else {
      setFirstDay(day);
    }
  };

  const handleShortcut = (dateRange: DateRange): void => {
    if (dateRange.type === DateType.RELATIVE) {
      onChange(dateRange);
      close();
    } else {
      setCalendarOpen(true);
    }
  };

  useEffect(() => {
    if (!!isOpen) {
      setFirstDay(undefined);
      setEndMonth(getFirstDayOfMonth(value));
    }
  }, [value, isOpen]);

  return (
    <Popover.Container>
      <Popover.Toggle onMouseDown={toggle}>
        <div
          className={classNames("date-range-picker", {
            open: isOpen,
            selected: value?.type === DateType.ABSOLUTE,
            disabled
          })}
        >
          <Label
            pinned
            className={classNames({
              selected: value?.type === DateType.ABSOLUTE
            })}
          >
            {label}
          </Label>
          <span className="value">{dateRangeToLabel(value, placeholder)}</span>
          <Icons.ChevronDown />
        </div>
      </Popover.Toggle>
      <Popover.Content
        key={value?.type}
        isOpen={isOpen}
        placement="bottom"
        modifiers={[
          { name: "offset", options: { offset: [0, 8] } },
          DEFAULT_PREVENT_OVERFLOW_MODIFIER
        ]}
      >
        <div ref={popupRef} className="date-range-picker-content">
          {!!shortcuts?.length && (
            <DateRangeShortcuts
              min={min}
              max={max}
              value={value}
              shortcuts={shortcuts}
              onSelect={handleShortcut}
              calendarOpen={calendarOpen}
            />
          )}
          {(calendarOpen ||
            value?.type === DateType.ABSOLUTE ||
            !shortcuts?.length) && (
            <div className="calendar-wrapper" data-testid="calendar-open">
              <header>
                <Icons.ArrowCircleLeft
                  className="arrow left"
                  data-testid="arrow-left"
                  onClick={() => setEndMonth((c) => c.minus({ months: 1 }))}
                />
                <span className="title">
                  {startMonth.monthLong} {startMonth.year}
                </span>
                <span className="title">
                  {endMonth.monthLong} {endMonth.year}
                </span>
                <Icons.ArrowCircleRight
                  className="arrow right"
                  data-testid="arrow-right"
                  onClick={() => setEndMonth((c) => c.plus({ months: 1 }))}
                />
              </header>
              <div className="calendars">
                <Calendar
                  min={min}
                  max={max}
                  month={startMonth}
                  selected={firstDay}
                  selectedRange={
                    !firstDay && value?.type === DateType.ABSOLUTE
                      ? value?.value
                      : undefined
                  }
                  onSelectDay={handleSelectDay}
                />
                <Calendar
                  min={min}
                  max={max}
                  month={endMonth}
                  selected={firstDay}
                  selectedRange={
                    !firstDay && value?.type === DateType.ABSOLUTE
                      ? value.value
                      : undefined
                  }
                  onSelectDay={handleSelectDay}
                />
              </div>
            </div>
          )}
        </div>
      </Popover.Content>
    </Popover.Container>
  );
};

function getFirstDayOfMonth(value?: DateRange): DateTime {
  if (value?.value) {
    if (value.type === DateType.ABSOLUTE) {
      return value.value.end.startOf("month");
    }
  }

  return DateTime.local().startOf("month");
}

export { DateRangePicker };
