import { SDKClient } from "@aptedge/lib-support-ui/src/clients/sdkClient";
import { useQueryParams } from "@aptedge/lib-ui/src/hooks/useQueryParams";
import useSearchResult from "@aptedge/lib-ui/src/hooks/useSearchResult";
import {
  useAppDispatch,
  useAppSelector
} from "@aptedge/lib-ui/src/redux/hook/hook";
import { updateAnswerCardVisibility } from "@aptedge/lib-ui/src/redux/reduxSlice/answerGPTSlice";
import {
  updateSearchCardVisibility,
  updateShowBackButtonInPreview
} from "@aptedge/lib-ui/src/redux/reduxSlice/searchSlice";
import {
  ICompositeResult,
  SEARCH_RESULT_TYPE,
  QUERY_PARAMS,
  TopSearchResults,
  ICompositeResultSupport,
  DOCUMENT_SOURCE_TYPE
} from "@aptedge/lib-ui/src/types/entities";
import { isLocal } from "@aptedge/lib-ui/src/utils/config";
import {
  GTM_EVENTS,
  dataLayerPush,
  isWebApp
} from "@aptedge/lib-ui/src/utils/event";
import { sanitizeText } from "@aptedge/lib-ui/src/utils/generator";
import { FC } from "react";
import "./SourceLink.scss";

interface ISourcesLinkProps {
  sourceDetails: ICompositeResult;
  topResults: Map<string, ICompositeResult>;
  totalResults: number;
  client?: SDKClient;
  aptEdgeBaseUrl?: string;
}
const MAX_TITLE_LENGTH = 60;
const PER_PAGE = isWebApp ? 10 : 5;

const handleMouseDown = (
  event: React.MouseEvent<HTMLSpanElement, MouseEvent>
): void => {
  if (isWebApp) event.preventDefault();
};

const SourceLink: FC<ISourcesLinkProps> = ({
  sourceDetails,
  topResults,
  totalResults,
  client,
  aptEdgeBaseUrl,
  ...props
}) => {
  const {
    title,
    icon,
    type,
    externalId,
    sourceType,
    sourceId
  } = useSearchResult(
    sourceDetails as ICompositeResult | ICompositeResultSupport
  );

  const {
    searchQuery,
    searchResults,
    totalSearchResults,
    page
  } = useAppSelector((state) => state.search);

  const dispatch = useAppDispatch();

  const { updateParams } = useQueryParams();

  const { ticket } = useAppSelector((state) => state.cookedTicket);
  const { ticketId, ticketSubject } = ticket;

  const generateTopSearchResults = (): TopSearchResults[] => {
    if (searchResults && searchResults.length > 0) {
      const results: TopSearchResults[] = searchResults.map(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (searchResult: any) => ({
          type: searchResult.type,
          id:
            searchResult.type !== SEARCH_RESULT_TYPE.TICKET
              ? searchResult.id
              : searchResult.externalId,
          title: [
            searchResult.title,
            searchResult.summary,
            searchResult.name,
            searchResult.subject
          ].find((t) => t),
          preview: searchResult.preview,
          sourceType: searchResult.sourceType ?? ""
        })
      );
      return results;
    }
    if (topResults && topResults.size > 0) {
      const results: TopSearchResults[] = [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      topResults.forEach((topResult: any) =>
        results.push({
          type: topResult.type,
          id:
            topResult.type !== SEARCH_RESULT_TYPE.TICKET
              ? topResult.id
              : topResult.externalId,
          title: [
            topResult.title,
            topResult.summary,
            topResult.name,
            topResult.subject
          ].find((t) => t),
          preview: topResult.preview,
          sourceType: topResult.sourceType ?? ""
        })
      );
      return results;
    }
    return [];
  };

  const calculatePosition = (): number => {
    if (searchResults && searchResults.length > 0)
      return (
        searchResults.findIndex((result) => result.id === sourceDetails.id) + 1
      );
    if (topResults && topResults.size > 0)
      return (
        Array.from(topResults.values()).findIndex(
          (result) => result.id === sourceDetails.id
        ) + 1
      );
    return 0;
  };

  const trigggerAnswerSourceClickEvent = (): void => {
    const search_result_position_computed = searchQuery
      ? calculatePosition()
      : 0;
    dataLayerPush({
      event: GTM_EVENTS.GTM_ANSWER_SOURCE_CLICK,
      data: {
        ticket_id: isWebApp ? undefined : ticketId,
        ticket_title: isWebApp ? undefined : ticketSubject,
        search_query: searchQuery,
        total_search_results: totalSearchResults
          ? totalSearchResults
          : totalResults,
        top_search_results: generateTopSearchResults(),
        search_result_position:
          searchQuery && page && search_result_position_computed
            ? (page - 1) * PER_PAGE + search_result_position_computed
            : 0,
        page: page,
        search_result_position_on_page: search_result_position_computed
      }
    });
  };

  const isClassic =
    document.referrer.indexOf(".lightning.force.com") < 0 &&
    document.referrer.indexOf("zendesk") < 0;

  const onSourceClick = (): void => {
    const protocol = isLocal() ? "http" : "https";
    const baseUrlNoSlashes = aptEdgeBaseUrl?.replace("/", "");
    if (isWebApp) {
      updateParams(QUERY_PARAMS.RESULT_ID, sourceDetails.id.toString());
      dispatch(updateAnswerCardVisibility(false));
      dispatch(updateSearchCardVisibility(true));
      dispatch(updateShowBackButtonInPreview(true));
    } else {
      switch (type) {
        case SEARCH_RESULT_TYPE.EDGE:
          window.open(
            `${protocol}://${baseUrlNoSlashes}/edges/${sourceDetails.id}`
          );
          break;
        case SEARCH_RESULT_TYPE.TICKET:
          client?.navigateTo("ticket", String(externalId));
          if (isClassic) window.open(`${document.referrer}/${externalId}`);
          break;
        case SEARCH_RESULT_TYPE.DOCUMENT:
          if (sourceType === DOCUMENT_SOURCE_TYPE.EDGE)
            window.open(`${protocol}://${baseUrlNoSlashes}/edges/${sourceId}`);
          else
            window.open(
              `${protocol}://${baseUrlNoSlashes}${sourceDetails.url}`
            );
          break;
        case SEARCH_RESULT_TYPE.GENERATED_KNOWLEDGE:
          return;
        default:
          if ("url" in sourceDetails) {
            window.open(sourceDetails.url);
          }
          break;
      }
    }
    trigggerAnswerSourceClickEvent();
  };

  const titleText =
    title.length > MAX_TITLE_LENGTH
      ? sanitizeText(title).substring(0, MAX_TITLE_LENGTH) + "..."
      : sanitizeText(title);

  return (
    <span
      {...props}
      className="answer-link"
      onClick={onSourceClick}
      onMouseDown={handleMouseDown}
      data-testid="answer-link"
    >
      {icon} <span className="link-text">{titleText}</span>
    </span>
  );
};

export default SourceLink;
