import {
  IQueryFilterDefinition,
  ITimeDimensionFilterDefinition
} from "@aptedge/lib-ui/src/components/Filter/types";
import { AeIcons } from "@aptedge/lib-ui/src/components/Icon/AeIcons";
import { Icon } from "@aptedge/lib-ui/src/components/Icon/Icon";
import { faAppStore } from "@fortawesome/free-brands-svg-icons/faAppStore";
import { faJira } from "@fortawesome/free-brands-svg-icons/faJira";
import { faCalendar } from "@fortawesome/free-regular-svg-icons/faCalendar";
import { faUser } from "@fortawesome/free-regular-svg-icons/faUser";
import { faArchive } from "@fortawesome/free-solid-svg-icons/faArchive";
import { faBullseye } from "@fortawesome/free-solid-svg-icons/faBullseye";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons/faExclamationCircle";
import { faGlobe } from "@fortawesome/free-solid-svg-icons/faGlobe";
import { faReceipt } from "@fortawesome/free-solid-svg-icons/faReceipt";
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import { faStream } from "@fortawesome/free-solid-svg-icons/faStream";
import { faStroopwafel } from "@fortawesome/free-solid-svg-icons/faStroopwafel";
import { faTag } from "@fortawesome/free-solid-svg-icons/faTag";
import { faUsers } from "@fortawesome/free-solid-svg-icons/faUsers";
import React from "react";

const OPEN_ON_FILTER: ITimeDimensionFilterDefinition = {
  id: "openOn",
  label: "Open during",
  canDuplicate: true,
  icon: <Icon icon={faCalendar} />,
  kind: "ITimeDimensionFilterDefinition"
};
const CREATED_AFTER_FILTER: ITimeDimensionFilterDefinition = {
  id: "createdOn",
  label: "Created",
  canDuplicate: true,
  icon: <Icon icon={faCalendar} />,
  kind: "ITimeDimensionFilterDefinition"
};
const PRODUCT_FILTER: IQueryFilterDefinition = {
  id: "products",
  label: "Product",
  canDuplicate: true,
  operators: ["equals", "notEquals"],
  icon: <Icon icon={faAppStore} />,
  kind: "IQueryFilterDefinition"
};
const FEATURE_FILTER: IQueryFilterDefinition = {
  id: "features",
  label: "Feature",
  canDuplicate: true,
  operators: ["equals", "notEquals"],
  icon: <Icon icon={faAppStore} />,
  kind: "IQueryFilterDefinition"
};
const THEME_FILTER: IQueryFilterDefinition = {
  id: "themes",
  label: "Theme",
  canDuplicate: true,
  operators: ["equals", "notEquals"],
  icon: <Icon icon={faTag} />,
  kind: "IQueryFilterDefinition"
};
const PRIORITY_FILTER: IQueryFilterDefinition = {
  id: "priority",
  label: "Priority",
  canDuplicate: true,
  operators: ["equals", "notEquals"],
  icon: <Icon icon={faExclamationCircle} />,
  kind: "IQueryFilterDefinition"
};
const CUSTOMER_FILTER: IQueryFilterDefinition = {
  id: "customers",
  label: "Customer",
  canDuplicate: true,
  operators: ["equals", "notEquals"],
  icon: <Icon icon={faUser} />,
  kind: "IQueryFilterDefinition"
};
const TEXT_FILTER: IQueryFilterDefinition = {
  id: "text",
  label: "Keyword",
  canDuplicate: false,
  operators: ["contains"],
  icon: <Icon icon={faSearch} />,
  kind: "IQueryFilterDefinition"
};
const SUBJECT_FILTER: IQueryFilterDefinition = {
  id: "subject",
  label: "Subject",
  canDuplicate: false,
  operators: ["contains"],
  icon: <Icon icon={faSearch} />,
  kind: "IQueryFilterDefinition"
};
const EXTERNAL_TICKET_ID_FILTER: IQueryFilterDefinition = {
  id: "externalTicket",
  label: "Ticket ID",
  canDuplicate: true,
  operators: ["equals", "notEquals"],
  icon: <Icon icon={faBullseye} />,
  kind: "IQueryFilterDefinition"
};
const DESCRIPTION_FILTER: IQueryFilterDefinition = {
  id: "description",
  label: "Description",
  canDuplicate: false,
  operators: ["contains"],
  icon: <Icon icon={faSearch} />,
  kind: "IQueryFilterDefinition"
};
const JIRA_FILTER: IQueryFilterDefinition = {
  id: "jiras",
  label: "Jira",
  canDuplicate: true,
  operators: ["equals", "notEquals"],
  icon: <Icon icon={faJira} className="text-blue" />,
  kind: "IQueryFilterDefinition"
};

const STATUS_FILTER: IQueryFilterDefinition = {
  id: "status",
  label: "Status",
  canDuplicate: true,
  operators: ["equals", "notEquals"],
  icon: <Icon icon={faStream} />,
  kind: "IQueryFilterDefinition"
};

const TICKET_TYPE_FILTER: IQueryFilterDefinition = {
  id: "ticketType",
  label: "Ticket Type",
  canDuplicate: true,
  operators: ["equals", "notEquals"],
  icon: <Icon icon={faStroopwafel} />,
  kind: "IQueryFilterDefinition"
};

const TICKET_REASON_FILTER: IQueryFilterDefinition = {
  id: "reason",
  label: "Reason",
  canDuplicate: true,
  operators: ["equals", "notEquals"],
  icon: <Icon icon={faReceipt} />,
  kind: "IQueryFilterDefinition"
};

const EDGE_FILTER: IQueryFilterDefinition = {
  id: "edges",
  label: "Edge",
  canDuplicate: true,
  operators: ["equals", "notEquals"],
  icon: <AeIcons.Edge />,
  kind: "IQueryFilterDefinition"
};

// Edge listing.
const CREATED_ON_FILTER: ITimeDimensionFilterDefinition = {
  id: "createdOn",
  label: "Created On",
  canDuplicate: true,
  icon: <AeIcons.CreatedOn />,
  kind: "ITimeDimensionFilterDefinition"
};
const UPDATED_ON_FILTER: ITimeDimensionFilterDefinition = {
  id: "updatedOn",
  label: "Updated On",
  canDuplicate: true,
  icon: <AeIcons.UpdatedOn />,
  kind: "ITimeDimensionFilterDefinition"
};
const ASSIGNEE_FILTER: IQueryFilterDefinition = {
  id: "assignee",
  label: "Assigned To",
  canDuplicate: true,
  operators: ["equals", "notEquals"],
  icon: <Icon icon={faUser} />,
  kind: "IQueryFilterDefinition"
};
const CREATED_BY_FILTER: IQueryFilterDefinition = {
  id: "createdBy",
  label: "Created By",
  canDuplicate: true,
  operators: ["equals", "notEquals"],
  icon: <Icon icon={faUser} />,
  kind: "IQueryFilterDefinition"
};
const EDGE_TEAM_FILTER: IQueryFilterDefinition = {
  id: "teams",
  label: "Team",
  canDuplicate: true,
  operators: ["equals", "notEquals"],
  icon: <Icon icon={faUsers} />,
  kind: "IQueryFilterDefinition"
};
const SPACE_FILTER: IQueryFilterDefinition = {
  id: "spaces",
  label: "Space",
  canDuplicate: true,
  operators: ["equals", "notEquals"],
  icon: <Icon icon={faGlobe} />,
  kind: "IQueryFilterDefinition"
};
const ARCHIVED_FILTER: IQueryFilterDefinition = {
  id: "archived",
  label: "Archived",
  canDuplicate: true,
  operators: ["equals", "notEquals"],
  icon: <Icon icon={faArchive} />,
  kind: "IQueryFilterDefinition"
};

const PATTERN_FILTER: IQueryFilterDefinition = {
  id: "text",
  label: "Pattern",
  canDuplicate: false,
  operators: ["contains"],
  icon: <Icon icon={faSearch} />,
  kind: "IQueryFilterDefinition"
};

export {
  OPEN_ON_FILTER,
  CREATED_AFTER_FILTER,
  CREATED_ON_FILTER,
  UPDATED_ON_FILTER,
  PRODUCT_FILTER,
  PRIORITY_FILTER,
  STATUS_FILTER,
  TICKET_TYPE_FILTER,
  TICKET_REASON_FILTER,
  FEATURE_FILTER,
  THEME_FILTER,
  CUSTOMER_FILTER,
  ASSIGNEE_FILTER,
  CREATED_BY_FILTER,
  ARCHIVED_FILTER,
  EDGE_TEAM_FILTER,
  SPACE_FILTER,
  TEXT_FILTER,
  SUBJECT_FILTER,
  DESCRIPTION_FILTER,
  JIRA_FILTER,
  EDGE_FILTER,
  EXTERNAL_TICKET_ID_FILTER,
  PATTERN_FILTER
};
