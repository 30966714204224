import { AeIcons } from "@aptedge/lib-ui/src/components/Icon/AeIcons";
import * as React from "react";
import { Link } from "react-router-dom";
import styles from "./EmptyState.module.scss";

const EmptyState: React.FC = () => {
  return (
    <div className={styles.container}>
      <p className={styles.heading}>
        All done{" "}
        <span role="img" aria-label="hands-raised">
          🙌
        </span>
      </p>
      <p className={styles.subheading}>
        Great job reviewing all articles, you can close this tab now!
      </p>
      <Link to="/articles" className={styles.articlesLink}>
        <AeIcons.GeneratedKnowledgeBlue />
        View All Articles
      </Link>
    </div>
  );
};

export { EmptyState };
