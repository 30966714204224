import { FilterWrapper } from "@aptedge/lib-ui/src/components/Filter/FilterWrapper";
import {
  IQueryFilter,
  IQueryFilterDefinition
} from "@aptedge/lib-ui/src/components/Filter/types";
import { GTM_EVENTS, dataLayerPush } from "@aptedge/lib-ui/src/utils/event";
import React from "react";
import { DebouncedTextInput } from "../DebouncedTextInput/DebouncedTextInput";
import {
  SUBJECT_FILTER,
  EXTERNAL_TICKET_ID_FILTER,
  DESCRIPTION_FILTER,
  TEXT_FILTER,
  PATTERN_FILTER
} from "./data";

type Props = {
  value?: IQueryFilter;
  onChange: (val: IQueryFilter) => void;
};

function ExternalTicketIdFilter(props: Props): React.ReactElement {
  return makeTextFilterWithPlaceholder(
    props,
    EXTERNAL_TICKET_ID_FILTER,
    "Enter Ticket ID"
  );
}

function SubjectTextFilter(props: Props): React.ReactElement {
  return makeTextFilter(props, SUBJECT_FILTER);
}

function DescriptionTextFilter(props: Props): React.ReactElement {
  return makeTextFilter(props, DESCRIPTION_FILTER);
}

function TextFilter(props: Props): React.ReactElement {
  return makeTextFilter(props, TEXT_FILTER);
}

function PatternFilter(props: Props): React.ReactElement {
  return makeTextFilter(props, PATTERN_FILTER, "Enter a string");
}

function makeTextFilter(
  props: Props,
  queryFilterDefinition: IQueryFilterDefinition,
  placeholder?: string
): React.ReactElement {
  return makeTextFilterWithPlaceholder(
    props,
    queryFilterDefinition,
    placeholder ?? "Enter a search term"
  );
}

function makeTextFilterWithPlaceholder(
  props: Props,
  queryFilterDefinition: IQueryFilterDefinition,
  placeholderText: string
): React.ReactElement {
  return (
    <FilterWrapper {...queryFilterDefinition} {...props}>
      {({ values, onChange }) => (
        <DebouncedTextInput
          value={values?.[0]}
          placeholder={placeholderText}
          isClearable={false}
          onChange={(val) => {
            if (values?.[0] !== val) {
              onChange(!!val ? [val] : []);
            }
            dataLayerPush({
              event: GTM_EVENTS.GTM_TEXT_FILTER
            });
          }}
        />
      )}
    </FilterWrapper>
  );
}

export {
  TextFilter,
  SubjectTextFilter,
  DescriptionTextFilter,
  ExternalTicketIdFilter,
  PatternFilter
};
