import classNames from "classnames";
import React from "react";
import Switch from "react-switch";

interface ToggleProps {
  disabled?: boolean;
  name?: string;
  checked: boolean;
  handleSize?: number;
  width?: number;
  height?: number;
  offColor?: string;
  onChange: ((checked: boolean) => void) | (() => void);
  isLoading?: boolean;
}

const Toggle: React.FC<ToggleProps> = (props) => {
  const { handleSize = 20, offColor = "#88979b", disabled, checked } = props;
  const className = classNames("toggle", { disabled, checked });

  return (
    <Switch
      name={props.name}
      className={className}
      width={props.width || 36}
      height={props.height || 16}
      handleDiameter={handleSize}
      checked={checked}
      checkedIcon={false}
      uncheckedIcon={false}
      disabled={disabled || props.isLoading}
      boxShadow="0px 1px 5px 0 var(--shadow)"
      onChange={props.onChange}
      onColor="#06778c"
      offColor={offColor}
      data-testid={className}
      onClick={(e) => e.stopPropagation()} // Rely on onChange only
    />
  );
};

export { Toggle };
