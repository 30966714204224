import { AeIcons } from "@aptedge/lib-ui/src/components/Icon/AeIcons";
import { MyProductsListItem } from "@aptedge/lib-ui/src/types/entities";
import classNames from "classnames";
import * as React from "react";
import styles from "./MyProductsItem.module.scss";

interface MyProductsItemProps {
  item: MyProductsListItem;
  isSelected: boolean;
  handleItemClick: (itemId: string) => void;
}

const MyProductsItem: React.FC<MyProductsItemProps> = ({
  item,
  isSelected,
  handleItemClick
}) => {
  const { id, name } = item;
  return (
    <div
      className={classNames(styles.container, {
        [styles.selectedContainer]: isSelected,
        [styles.unselectedContainer]: !isSelected
      })}
      onClick={() => handleItemClick(id)}
    >
      <span
        className={classNames(styles.icon, {
          [styles.selectedIcon]: isSelected,
          [styles.unselectedIcon]: !isSelected
        })}
      >
        {isSelected ? (
          <AeIcons.ProductFiltersSelected />
        ) : (
          <AeIcons.ProductFiltersUnselected />
        )}
      </span>
      <span className={styles.label}>{name}</span>
    </div>
  );
};

export { MyProductsItem };
