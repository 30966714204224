import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import ImageIcon from "@aptedge/lib-ui/src/components/Icon/ImageIcon";
import {
  AptAnswersFilterRule,
  AptAnswersFilterPurpose
} from "@aptedge/lib-ui/src/types/entities";
import * as React from "react";
import styles from "./FilterHeader.module.scss";
import { FilterHeaderMessage } from "./FilterHeaderMessage";

interface FilterHeaderProps {
  integrationName: string;
  integrationIcon: string | null;
  filterPurpose: AptAnswersFilterPurpose;
  isExpanded: boolean;
  manageAllSelected: boolean;
  handleExpandClick: () => void;
  rules?: AptAnswersFilterRule[];
}

const FilterHeader: React.FC<FilterHeaderProps> = ({
  integrationName,
  integrationIcon,
  filterPurpose,
  isExpanded,
  manageAllSelected,
  handleExpandClick,
  rules
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.integrationInfo}>
        <div className={styles.iconContainer}>
          {integrationIcon && <ImageIcon src={integrationIcon} />}
        </div>
        <p className={styles.integrationName}>{integrationName}</p>
      </div>
      <div className={styles.controlsContainer}>
        {!isExpanded && (
          <FilterHeaderMessage
            filterPurpose={filterPurpose}
            rules={rules ?? []}
            manageAllSelected={manageAllSelected}
          />
        )}
        <button className={styles.chevronIcon} onClick={handleExpandClick}>
          {isExpanded ? <Icons.ChevronUp /> : <Icons.ChevronDown />}
        </button>
      </div>
    </div>
  );
};

export { FilterHeader };
