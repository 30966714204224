import classNames from "classnames";
import React, {
  ChangeEventHandler,
  forwardRef,
  PropsWithChildren,
  useEffect,
  useImperativeHandle,
  useRef
} from "react";
import { Feedback } from "../Feedback/Feedback";
import { Icons } from "../Icon/Icons";
import "./TextArea.scss";

export interface TextAreaProps
  extends React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  id?: string;
  name?: string;
  label?: string;
  value?: string;
  error?: string;
  className?: string;
  placeholder?: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  onClear?: () => void;
  onFocus?: () => void;
  resizable?: boolean;
  autoFocus?: boolean;
}

const TextArea = forwardRef<
  HTMLTextAreaElement,
  PropsWithChildren<TextAreaProps>
>((props, ref) => {
  const {
    className,
    label,
    onClear,
    error,
    children,
    value = "",
    resizable = true,
    autoFocus = false,
    ...rest
  } = props;
  const hasValue = !!value;
  const innerRef = useRef<HTMLTextAreaElement>(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useImperativeHandle<any, any>(ref, () => ({
    focus: () => {
      innerRef.current?.focus();
    }
  }));

  useEffect(() => {
    if (autoFocus) {
      innerRef.current?.focus();
    }
  }, [autoFocus, ref]);

  return (
    <div className={classNames("text-area", className, { error, resizable })}>
      <div className="text-area-container">
        {label && <label>{label}</label>}
        <textarea ref={innerRef} value={value} {...rest} />
        {onClear && hasValue && <Icons.Times onClick={onClear} />}
      </div>
      {error && <Feedback>{error}</Feedback>}
    </div>
  );
});

TextArea.displayName = "TextArea";

export { TextArea };
