import React from "react";
import Helmet from "react-helmet";
import { Container } from "reactstrap";
import { PageContent } from "../../components/PageContent/PageContent";
import { SettingsContent } from "../SettingsPage/SettingsContent";
import { WatchSettings } from "./WatchSettings";

const UserSettingsPage: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>User Settings</title>
      </Helmet>
      <SettingsContent>
        <PageContent>
          <Container>
            <h2 className="mt-4 mb-4">User Settings</h2>
            <WatchSettings />
          </Container>
        </PageContent>
      </SettingsContent>
    </>
  );
};

export { UserSettingsPage };
