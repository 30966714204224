import { ISortArg } from "../../types/clients";

const sortRegex = /(asc|desc)\(([a-zA-Z]+)\)/;

function toSortObject<T extends string>(
  sortByString?: string | null
): ISortArg<T>[] | null {
  if (!sortByString) {
    return null;
  }

  const args = sortByString.split(",");

  const sortArgs: ISortArg<T>[] = args
    .map((value) => {
      const match = sortRegex.exec(value);

      if (!match || match.length !== 3) {
        return null;
      }

      return {
        id: match[2] as T,
        desc: match[1] === "desc"
      };
    })
    .filter((v): v is ISortArg<T> => v !== null);

  if (!sortArgs.length) {
    return null;
  }

  return sortArgs;
}

function toSortString<T extends string>(
  args?: ISortArg<T>[] | null
): string | null {
  if (!args) {
    return null;
  }

  return args
    .map((arg) => {
      const order = arg.desc ? "desc" : "asc";

      return `${order}(${arg.id})`;
    })
    .join(",");
}

export { toSortObject, toSortString };
