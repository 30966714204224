import { FilterWrapper } from "@aptedge/lib-ui/src/components/Filter/FilterWrapper";
import { IQueryFilter } from "@aptedge/lib-ui/src/components/Filter/types";
import { IProductIssue } from "@aptedge/lib-ui/src/types/entities";
import React from "react";
import { WebCacheKey } from "../../clients/cache";
import { fetchProductIssues } from "../../clients/ProductIssues/fetchProductIssues";
import { unwrapPaginatedClient } from "../../clients/utils/paginated";
import { MultiSelectAsync } from "../SelectAsync/MultiSelectAsync";
import { JIRA_FILTER } from "./data";

const fetchJiras = unwrapPaginatedClient(fetchProductIssues);
const hackyFetchJira: (keys: string[]) => Promise<IProductIssue[]> = (keys) =>
  Promise.resolve(
    keys.map((k) => ({
      id: 0,
      externalId: k,
      urlReference: { id: 0, url: "" }
    }))
  );

type Props = {
  value?: IQueryFilter;
  onChange: (val: IQueryFilter) => void;
};

function JiraFilter(props: Props): React.ReactElement {
  return (
    <FilterWrapper {...JIRA_FILTER} {...props}>
      {({ values, onChange }) => {
        return (
          <MultiSelectAsync<IProductIssue, string>
            name="jira-filter"
            value={values}
            valueKey={WebCacheKey.JIRAS_BY_ID}
            valueLoader={hackyFetchJira}
            getOptionAsValue={(issue) => issue.externalId}
            optionsKey={WebCacheKey.JIRAS}
            optionsLoader={(textSearch) =>
              fetchJiras({
                externalIdPrefix: textSearch,
                page: 1,
                perPage: 30,
                sortBy: null
              })
            }
            getOptionLabel={(issue) => issue.externalId}
            onChange={(issues) => onChange(issues || [])}
          />
        );
      }}
    </FilterWrapper>
  );
}

export { JiraFilter };
