import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";
import { post } from "../utils/buildRequest";

async function inviteUser(email: string): Promise<string> {
  const url = `${getBackendApiUrl()}/users/invite/`;
  const options = post({ email });

  return request(url, options);
}

export { inviteUser };
