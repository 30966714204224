import { IEdgeAuditLogAssigneeChange } from "@aptedge/lib-ui/src/types/entities";
import React from "react";
import { ArrowTransition } from "./ArrowTransition";
import { SummarySentence } from "./SummarySentence";
import { WithAuditLog, AuditDetailsFC, AuditSummaryFC } from "./WithAuditLog";

const UNASSIGNED = "Unassigned";

const Details: AuditDetailsFC<IEdgeAuditLogAssigneeChange> = (props) => {
  const { log } = props;
  const { newUser, oldUser } = log.data;
  const from = oldUser ? oldUser.email : UNASSIGNED;
  const to = newUser ? newUser.email : UNASSIGNED;

  return (
    <div className="audit-log-assignee-change">
      <ArrowTransition from={<span>{from}</span>} to={<span>{to}</span>} />
    </div>
  );
};

const Summary: AuditSummaryFC<IEdgeAuditLogAssigneeChange> = (props) => {
  const { author, log } = props;

  return (
    <SummarySentence
      subject={author}
      verb={SummarySentence.VERBS.UPDATED}
      object={SummarySentence.OBJECTS.ASSIGNEE}
      displayText={log?.displayText ?? ""}
    />
  );
};

Details.displayName = "AuditAssigneeChangeDetails";
const AuditLogAssigneeChange = WithAuditLog(Details, Summary);

export { AuditLogAssigneeChange };
