import { getBackendApiUrl } from "../../config";
import { IUpdatePasswordArgs } from "../../types/clients";
import { request } from "../../utils/request";
import { post } from "../utils/buildRequest";

function updatePassword(
  args: IUpdatePasswordArgs
): Promise<Record<string, unknown>> {
  const url = `${getBackendApiUrl()}/password`;

  const options = post(args);
  return request<Record<string, unknown>>(url, options);
}

export { updatePassword };
