import { AeIcons } from "@aptedge/lib-ui/src/components/Icon/AeIcons";
import LanguageIcon from "@mui/icons-material/Language";
import Cookies from "js-cookie";
import { FC, useRef, useState } from "react";
import "./OAuthLogout.scss";
import { OAUTH_REDIRECT_URL } from "../../types/clients";
import { APTEDGE_COOKIE_DOMAIN } from "../../utils/oauth";

const OAuthLogout: FC = () => {
  const [instance, setInstance] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const processRedirection = (): void => {
    Cookies.set(OAUTH_REDIRECT_URL, `${instance}.aptedge.io`, {
      expires: 30,
      domain: APTEDGE_COOKIE_DOMAIN
    });

    window.location.href = `https://${instance}.aptedge.io`;
  };

  const handleInstanceInput = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const text = event.target.value.replace(/[^a-zA-Z]/g, "");
    setInstance(text);
  };
  return (
    <div className="settingsPageContainer">
      <div className="welcomeContainer">
        <AeIcons.LogoutLeftIG />
      </div>
      <div className="settingsContainer">
        <img alt="logo" className="logo" src="/trimmed-logo.png" />
        <div>
          <h4>URL</h4>
          <div
            className="inputs"
            onClick={() => {
              if (inputRef.current) {
                inputRef.current.focus();
              }
            }}
          >
            <div className="input-icons">
              <LanguageIcon className="globe-icon" />
              <input
                ref={inputRef}
                value={instance}
                placeholder="instance"
                className="instance"
                onChange={handleInstanceInput}
                autoFocus
              />
              <span>.aptedge.io</span>
            </div>
          </div>
          <button className="update-instance" onClick={processRedirection}>
            Continue
          </button>
        </div>
        <div className="policy">
          <a
            href="https://www.aptedge.io/website-privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <AeIcons.DocIcon /> Privacy Policy
          </a>
        </div>
      </div>
    </div>
  );
};

export default OAuthLogout;
