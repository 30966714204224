import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";
import { post } from "../utils/buildRequest";

function createFederatedURLPrefixConfig(value: string): Promise<string> {
  const url = `${getBackendApiUrl()}/config/FEDERATED_SEARCH_URL_PREFIXES/`;
  const options = post({ value });

  return request(url, options);
}

export { createFederatedURLPrefixConfig };
