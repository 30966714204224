import { AptAnswersFilterPurpose } from "@aptedge/lib-ui/src/types/entities";
import * as React from "react";
import styles from "./FilterActions.module.scss";
import { FilterAddRuleButton } from "./FilterAddRuleButton";
import { FilterManageAllOption } from "./FilterManageAllOption";
import { FilterSaveButton } from "./FilterSaveButton";

interface FilterActionsProps {
  handleAddRule: () => void;
  handleManageAllClick: () => void;
  manageAllSelected: boolean;
  addRuleIsDisabled: boolean;
  integrationId: string;
  integrationName: string;
  filterPurpose: AptAnswersFilterPurpose;
  handleItemSave?: (integrationName: string) => void;
  resetOnSave: () => void;
}

const FilterActions: React.FC<FilterActionsProps> = ({
  handleItemSave,
  handleAddRule,
  handleManageAllClick,
  manageAllSelected,
  addRuleIsDisabled,
  integrationName,
  integrationId,
  filterPurpose,
  resetOnSave
}) => {
  const handleSave = (): void => {
    if (!handleItemSave) {
      return;
    }
    handleItemSave(integrationId);
    resetOnSave();
  };
  return (
    <div className={styles.container}>
      <div className={styles.buttonContainer}>
        {!!handleItemSave ? <FilterSaveButton handleSave={handleSave} /> : null}
        <FilterAddRuleButton
          handleAddRule={handleAddRule}
          isDisabled={addRuleIsDisabled}
        />
      </div>
      <div className={styles.manageAllContainer}>
        <FilterManageAllOption
          integrationName={integrationName}
          manageAllSelected={manageAllSelected}
          handleManageAllClick={handleManageAllClick}
          filterPurpose={filterPurpose}
        />
      </div>
    </div>
  );
};

export { FilterActions };
