import { IJiraOnPremiseInternalIssueSource } from "@aptedge/lib-ui/src/types/entities";
import { getBackendUrl } from "../../config";
import { request } from "../../utils/request";

function fetchJiraOnPremiseInternalIssueSources(): Promise<
  IJiraOnPremiseInternalIssueSource[]
> {
  const url = `${getBackendUrl()}/jira-onprem/tap`;

  return request(url);
}

export { fetchJiraOnPremiseInternalIssueSources };
