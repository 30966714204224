import { ActionBar } from "@aptedge/lib-ui/src/components/ActionBar/ActionBar";
import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { ButtonGroup } from "@aptedge/lib-ui/src/components/ButtonGroup/ButtonGroup";
import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import classNames from "classnames";
import clamp from "lodash/clamp";
import range from "lodash/range";
import React from "react";

export enum PaginationAlignment {
  LEFT = "left",
  RIGHT = "right",
  CENTER = "center"
}

interface Props {
  className?: string;
  align?: PaginationAlignment;
  options?: 5 | 9;
  page: number;
  totalPages: number;
  onChange: (val: { page: number }) => void;
}

/**
 * Component used to control pagination via a button group.
 *
 * Will try to keep the current page centered, unless we are in
 * the first or last "block" of options (i.e. 1-5, or 45-50) for
 * a 50 page response.
 *
 * So, if we're on page 1 out of 50, it should appear as:
 *
 * << < [1] 2  3  4  5  > >>
 *
 * If we're on page 12 out of 50:
 *
 * << < 10 11 [12] 13 14 > >>
 *
 * If we're on page 49 out of 50:
 *
 * << < 46 47 48 [49] 50 > >>
 */
const PaginationBar: React.FunctionComponent<Props> = (props) => {
  const {
    page,
    totalPages,
    className,
    onChange,
    align = "right",
    options = 5
  } = props;

  if (totalPages <= 1) {
    return null;
  }

  let BarAlign;
  switch (align) {
    case PaginationAlignment.LEFT:
      BarAlign = ActionBar.Left;
      break;
    case PaginationAlignment.CENTER:
      BarAlign = ActionBar.Center;
      break;
    default:
      BarAlign = ActionBar.Right;
      break;
  }

  const inFirstBlock = page < options;
  const inLastBlock = page > totalPages - options;
  const hasMoreThanOneBlock = totalPages > options;

  const jumpBackToFirstPageEnabled = page > 1;
  const jumpForwardToLastPageEnabled = page < totalPages;

  const prevPage = Math.max(page - 1, 1);
  const nextPage = Math.min(page + 1, totalPages);

  let min = 1;
  let max = totalPages;
  const blockSize = options - 1;
  const padding = blockSize / 2;

  if (hasMoreThanOneBlock) {
    if (inFirstBlock) {
      min = 1;
      max = clamp(options, 1, totalPages);
    } else if (inLastBlock) {
      min = clamp(totalPages - blockSize, 1, totalPages);
    } else {
      min = clamp(page - padding, 1, totalPages);
      max = min + blockSize;
    }
  }

  // _.range does not include the given end value, thus the + 1.
  const displayedPages: number[] = range(min, max + 1);

  return (
    <ActionBar.Container className={classNames("pagination-bar", className)}>
      <BarAlign>
        <ButtonGroup>
          <Button
            disabled={!jumpBackToFirstPageEnabled}
            onClick={() => onChange({ page: 1 })}
            data-testid="first-page"
          >
            <Icons.AngleDoubleLeft />
          </Button>
          <Button
            disabled={inFirstBlock}
            onClick={() => onChange({ page: prevPage })}
            data-testid="jump-back"
          >
            <Icons.AngleLeft />
          </Button>
          {displayedPages.map((p) => (
            <Button
              key={p}
              active={p === page}
              onClick={() => onChange({ page: p })}
              data-testid={p === page ? `active-page-${p}` : `page-${p}`}
            >
              <span>{p}</span>
            </Button>
          ))}
          <Button
            disabled={inLastBlock || !hasMoreThanOneBlock}
            onClick={() => onChange({ page: nextPage })}
            data-testid="jump-forward"
          >
            <Icons.AngleRight />
          </Button>
          <Button
            disabled={!jumpForwardToLastPageEnabled}
            onClick={() => onChange({ page: totalPages })}
            data-testid="last-page"
          >
            <Icons.AngleDoubleRight />
          </Button>
        </ButtonGroup>
      </BarAlign>
    </ActionBar.Container>
  );
};

export { PaginationBar };
