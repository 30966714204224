import { ITicketListing } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import {
  IFetchTicketArgs,
  IPaginatedRequest,
  IPaginatedResponse
} from "../../types/clients";
import { request } from "../../utils/request";
import { toSortString } from "../utils/sort";
import { toQueryString } from "../utils/url";

async function fetchTicketsForEdge(
  args: IPaginatedRequest<{ edge: number; textQuery?: string }>
): Promise<IPaginatedResponse<ITicketListing[]>> {
  const query = toQueryString<IFetchTicketArgs>({
    ...args,
    daysFromNow: null,
    products: [],
    features: [],
    themes: [],
    customers: [],
    ticketPriorities: [],
    closedTickets: null,
    urlReferences: null,
    jiraIssueKeys: null,
    sortBy: toSortString([{ id: "createdOn", desc: true }]),
    textQuery: args.textQuery || null
  });
  const url = `${getBackendApiUrl()}/tickets/?${query}`;

  return request(url);
}

export { fetchTicketsForEdge };
