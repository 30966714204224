import { useInterval } from "@aptedge/lib-ui/src/hooks/useInterval";
import { useState } from "react";
import { displayAge } from "../../utils/time";

const POLL_FREQUENCY = 5 * 60 * 1000; // 5 min.

const Age: React.FC<Props> = ({ dateInSeconds }) => {
  const [age, setAge] = useState<string | null>(displayAge(dateInSeconds));

  useInterval(() => {
    setAge(displayAge(dateInSeconds));
  }, POLL_FREQUENCY);

  return <span className="age">{age}</span>;
};

interface Props {
  dateInSeconds: number;
}

export { Age };
