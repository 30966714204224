import { getBackendApiUrl } from "../../config";
import { deleteRequest } from "../../utils/request";
import { deleteInit } from "../utils/buildRequest";

export function deleteEdgeComment({
  edgeId,
  commentId
}: {
  edgeId: number;
  commentId: number;
}): Promise<void> {
  const url = `${getBackendApiUrl()}/edges/${edgeId}/comments/${commentId}/`;

  return deleteRequest(url, deleteInit());
}
