import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import { useOnMouseDownOutside } from "@aptedge/lib-ui/src/hooks/useOnMouseDownOutside";
import { ICustomFieldMapping } from "@aptedge/lib-ui/src/types/entities";
import classNames from "classnames";
import React, { useState, createRef } from "react";
import { CustomFieldMappingCreateItem } from "./CustomFieldMappingCreateItem";
import { CustomFieldMappingListItem } from "./CustomFieldMappingListItem";

interface Props {
  customFieldMappings: ICustomFieldMapping[];
  onChange: () => void;
}

const CustomFieldMappingList: React.FC<Props> = (props) => {
  const { customFieldMappings, onChange } = props;
  const [showAddCustomFieldMapping, setShowAddCustomFieldMapping] = useState(
    false
  );

  const wrapperRef = createRef<HTMLUListElement>();

  useOnMouseDownOutside(wrapperRef, () => {
    setShowAddCustomFieldMapping(false);
  });

  const handleSave = (): void => {
    setShowAddCustomFieldMapping(false);
    onChange();
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-start">
        <div>
          <h5>Custom Field Mapping</h5>
          <p>Add Custom Fields you wish to map from Zendesk</p>
        </div>
        <div>
          <Button
            color="secondary"
            data-testid="custom-field-mapping-add"
            onClick={() => setShowAddCustomFieldMapping(true)}
          >
            <Icons.Plus />
            <span>Add</span>
          </Button>
        </div>
      </div>
      <ul
        ref={wrapperRef}
        className={classNames("custom-field-list list-group")}
      >
        {showAddCustomFieldMapping && (
          <CustomFieldMappingCreateItem
            onSave={handleSave}
            onCancel={() => setShowAddCustomFieldMapping(false)}
          />
        )}
        {customFieldMappings.map((c, key) => (
          <CustomFieldMappingListItem
            key={`custom-field-mapping-${c.id}`}
            customFieldMapping={c}
            onChange={onChange}
          />
        ))}
      </ul>
    </>
  );
};

export { CustomFieldMappingList };
