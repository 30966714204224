import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { IConfluenceOnPremArticleSource } from "@aptedge/lib-ui/src/types/entities";
import { useFormik } from "formik";
import React from "react";
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label
} from "reactstrap";
import * as Yup from "yup";

export interface ConfluenceOnPremFormData {
  configuredBaseUrl: string;
  accessToken: string;
}

interface Props {
  source: IConfluenceOnPremArticleSource | null;
  onSubmit: (formData: ConfluenceOnPremFormData) => void;
}

const ConfluenceOnPremConfigForm: React.FunctionComponent<Props> = ({
  source,
  onSubmit
}) => {
  const formik = useFormik<ConfluenceOnPremFormData>({
    validationSchema: Yup.object({
      configuredBaseUrl: Yup.string().required("Please enter a base URL."),
      accessToken: Yup.string().required(
        "Please enter a personal access token."
      )
    }),
    initialValues: {
      configuredBaseUrl: (source && source.configuredBaseUrl) || "",
      accessToken: (source && source.accessToken) || ""
    },
    validateOnChange: false,
    onSubmit: onSubmit
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <FormGroup row>
        <Label sm={3} for="configuredBaseUrl">
          Confluence Base URL
        </Label>
        <Col sm={9}>
          <Input
            data-testid="confluence-configuredBaseUrl"
            type="text"
            name="configuredBaseUrl"
            placeholder=""
            value={formik.values.configuredBaseUrl}
            onChange={formik.handleChange}
            invalid={!!formik.errors.configuredBaseUrl}
          />
          <FormFeedback>{formik.errors.configuredBaseUrl}</FormFeedback>
          <FormText>Base URL of the Confluence Server</FormText>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3} for="accessToken">
          Personal Access Token
        </Label>
        <Col sm={9}>
          <Input
            data-testid="confluence-accessToken"
            type="text"
            name="accessToken"
            placeholder=""
            value={formik.values.accessToken}
            onChange={formik.handleChange}
            invalid={!!formik.errors.accessToken}
          />
          <FormFeedback>{formik.errors.accessToken}</FormFeedback>
          <FormText>
            Personal Access Token generated by Confluence Server
          </FormText>
        </Col>
      </FormGroup>
      <div className="float-right">
        <Button type="submit">Save</Button>
      </div>
    </Form>
  );
};

export { ConfluenceOnPremConfigForm };
