import {
  ICreateSharepointSourceRequest,
  ISharepointSource
} from "@aptedge/lib-ui/src/types/entities";
import { getBackendUrl } from "../../config";
import { request } from "../../utils/request";
import { post } from "../utils/buildRequest";

async function createSharepointSource(
  args: ICreateSharepointSourceRequest
): Promise<ISharepointSource> {
  const url = `${getBackendUrl()}/api/integrations/sharepoint`;
  const options: RequestInit = post(args);
  return request(url, options);
}

export { createSharepointSource };
