import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";
import { post } from "../utils/buildRequest";

function generateArticle(ticketId: string): Promise<string[]> {
  const url = `${getBackendApiUrl()}/insights/kb-draft`;

  const options: RequestInit = post({
    format: "qna--insight-kb-draft",
    template_params: { TICKET_ID: ticketId }
  });

  return request(url, options);
}

export { generateArticle };
