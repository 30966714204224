import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { useModalRouter } from "@aptedge/lib-ui/src/components/Modal/ModalRouter";
import { TabsBar } from "@aptedge/lib-ui/src/components/Tabs/TabsBar";
import React, { useContext } from "react";
import { AutoLinkContext } from "../../../context/AutoLinkContext";
import { ReplyTextArea } from "../ReplyTextArea";
import { Page } from "./AutoLinkRoutes";

import "./AutoReplyPage.scss";

interface Props {
  onCancel: () => void;
}

const AutoReplyPage: React.FC<Props> = ({ onCancel }) => {
  const router = useModalRouter();
  const { reply, setReply, isInternal, setIsInternal } = useContext(
    AutoLinkContext
  );
  const nextPage = (): void => router.push(Page.PREVIEW);

  const handleSkip = (): void => {
    setReply("");
    nextPage();
  };

  return (
    <div>
      <div className="auto-reply-wrapper">
        <h5>Reply</h5>
        <div className="tabs-header">
          <TabsBar
            tabs={["Internal", "External"]}
            activeTab={isInternal ? 0 : 1}
            onChangeTab={(i) => setIsInternal(i === 0 ? true : false)}
          />
        </div>
        <div className="text">
          <ReplyTextArea
            value={reply}
            internal={isInternal}
            onChange={(e) => setReply(e.target.value)}
          />
        </div>
      </div>
      <div className="d-flex justify-content-between">
        <Button className="mr-3" color="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <div>
          <div
            className="mr-4 auto-secondary-button d-inline"
            onClick={handleSkip}
          >
            Skip
          </div>
          <Button
            className="mr-3"
            color="secondary"
            onClick={() => router.push(Page.CONDITIONS)}
          >
            Back
          </Button>
          <Button type="submit" onClick={nextPage}>
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export { AutoReplyPage };
