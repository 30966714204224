import {
  ICreateJiraOnPremiseInternalIssueSourceRequest,
  IJiraOnPremiseInternalIssueSource
} from "@aptedge/lib-ui/src/types/entities";
import { getBackendUrl } from "../../config";
import { request } from "../../utils/request";
import { post } from "../utils/buildRequest";

async function createInternalJiraOnPremiseSource(
  args: ICreateJiraOnPremiseInternalIssueSourceRequest
): Promise<IJiraOnPremiseInternalIssueSource> {
  const url = `${getBackendUrl()}/jira-onprem/collect/`;

  const options: RequestInit = post(args);

  return request(url, options);
}

export { createInternalJiraOnPremiseSource };
