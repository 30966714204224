import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import classNames from "classnames";
import React from "react";

interface FaviconProps {
  className?: string;
  linkSrc: string;
}

const Favicon: React.FC<FaviconProps> = (props) => {
  const { className, linkSrc } = props;
  const [src, setSrc] = React.useState<string>();

  React.useEffect(() => {
    const baseUrl = getBaseUrl(linkSrc);
    const faviconSrc = `${baseUrl}/favicon.ico`;
    loadImage(faviconSrc)
      .then((validSrc) => setSrc(validSrc))
      .catch((e) => {
        // Swallow. We'll fallback to the Icon.
      });
  }, [linkSrc]);

  if (!src) {
    return (
      <Icons.Link className={classNames("favicon", className)} size="sm" />
    );
  }

  return (
    <img
      className={classNames("favicon", className)}
      height={20}
      src={src}
      alt="favicon"
    />
  );
};

function loadImage(src: string): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const image = new Image();
    image.src = src;
    image.onload = () => resolve(src);
    image.onerror = (e) => reject(e);
  });
}

function getBaseUrl(src: string): string {
  try {
    const url = new URL(src);

    if (url.protocol && url.hostname) {
      return `${url.protocol}//${url.hostname}`;
    }
  } finally {
    return src;
  }
}

export { Favicon };
