import { ITeam } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";
import { patch } from "../utils/buildRequest";

export async function updateTeam(
  args: Pick<ITeam, "id" | "name">
): Promise<ITeam> {
  const url = `${getBackendApiUrl()}/teams/${args.id}/`;
  const options = patch({ name: args.name });

  return request(url, options);
}
