import { Toggle } from "@aptedge/lib-ui/src/components/Toggle/Toggle";
import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { WebCacheKey } from "../../../clients/cache";
import { editTicketSummaryAlertConfig } from "../../../clients/SupportAppConfig/editTicketSummaryAlertConfig";
import { fetchTicketSummaryAlertConfig } from "../../../clients/SupportAppConfig/fetchTicketSummaryAlertConfig";
import { Toast } from "../../../components/Toast/Toast";

function SupportAppConfiguration(): React.ReactElement {
  const queryClient = useQueryClient();

  const fetchSummaryAlertConfig = useQuery(
    [WebCacheKey.TICKET_SUMMARY_ALERT],
    () => fetchTicketSummaryAlertConfig()
  );

  const editSummaryAlertConfig = useMutation(editTicketSummaryAlertConfig, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(WebCacheKey.TICKET_SUMMARY_ALERT);
      queryClient.setQueryData(WebCacheKey.TICKET_SUMMARY_ALERT, data);
    }
  });

  const isChecked = !!fetchSummaryAlertConfig.data || false;
  return (
    <>
      <div className="mt-5">
        <h5>Configuration</h5>
        <div className="mt-3">
          <hr />
          <div className="d-flex justify-content-between">
            <label className="mb-0">
              Require an Agent Description when linking a ticket to an Edge
            </label>
            <Toggle
              isLoading={
                fetchSummaryAlertConfig.isLoading ||
                editSummaryAlertConfig.isLoading
              }
              checked={isChecked}
              onChange={() => {
                editSummaryAlertConfig.mutate(!isChecked);
              }}
            />
          </div>
          <hr />
        </div>
      </div>
      {(fetchSummaryAlertConfig.isError || editSummaryAlertConfig.isError) && (
        <Toast type="error">Something went wrong.</Toast>
      )}
    </>
  );
}

export { SupportAppConfiguration };
