import { IConfluenceOnPremArticleSource } from "@aptedge/lib-ui/src/types/entities";
import { getBackendUrl } from "../../config";
import { request } from "../../utils/request";

function fetchConfluenceOnPremArticleSources(): Promise<
  IConfluenceOnPremArticleSource[]
> {
  const url = `${getBackendUrl()}/api/integrations/confluence-onprem`;

  return request(url);
}

export { fetchConfluenceOnPremArticleSources };
