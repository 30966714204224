import { DateTime, ToRelativeOptions } from "luxon";

//! Luxon formatting documentation https://github.com/moment/luxon/blob/master/docs/formatting.md

const MILLIS_PER_SECOND = 1000;
export function displayAge(
  timestampInSecs: number | null | undefined,
  options?: ToRelativeOptions,
  shortFormat?: boolean
): string | null {
  if (!timestampInSecs) return null;
  const timestamp = DateTime.fromSeconds(timestampInSecs);
  const age = timestamp.toRelative(options);
  const timePassed =
    DateTime.now().diff(timestamp, "hours").toObject().hours ?? 0;
  if (timePassed >= 23) {
    if (shortFormat) return timestamp.toFormat("ccc, dd LLL yy, h:mm a");

    return timestamp.toFormat("DDDD, h:mm a");
  }
  if (age === "in 0 seconds") {
    return "now";
  }

  return age;
}

const DisplayDate = {
  FULL: {
    month: "long",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit"
  } as const
} as const;

function timestampToDateTimeString(timestamp: number): string {
  return DateTime.fromSeconds(timestamp).toLocaleString(DateTime.DATETIME_MED);
}

function timestampToDateTimeStringShort(timestamp: number): string {
  return DateTime.fromSeconds(timestamp).toLocaleString(
    DateTime.DATETIME_SHORT
  );
}

function timestampToDateStringShort(timestamp: number): string {
  return DateTime.fromSeconds(timestamp).toLocaleString(DateTime.DATE_SHORT);
}

export {
  MILLIS_PER_SECOND,
  DisplayDate,
  timestampToDateStringShort,
  timestampToDateTimeString,
  timestampToDateTimeStringShort
};
