import classNames from "classnames";
import React, { ReactNode } from "react";
import "./Card.scss";

interface CardProps {
  className?: string;
  title?: ReactNode;
}

const Card: React.FC<CardProps> = ({ title, children, className }) => {
  const renderTitle = React.isValidElement(title) ? (
    <div className="card-title">{title}</div>
  ) : (
    <h3>{title}</h3>
  );

  return (
    <div className={classNames("card", className)}>
      {title && <div className="card-head">{renderTitle}</div>}
      <div className="card-content">{children}</div>
    </div>
  );
};

export { Card };
