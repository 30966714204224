import { ITemplate } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";
import { post } from "../utils/buildRequest";

type Args = Omit<ITemplate, "id">;

async function createTemplate(args: Args): Promise<ITemplate> {
  const url = `${getBackendApiUrl()}/templates`;
  const options = post(args);

  return request(url, options);
}

export { createTemplate };
