import { AeIcons } from "@aptedge/lib-ui/src/components/Icon/AeIcons";
import * as React from "react";
import styles from "./ErrorMessage.module.scss";

const ErrorMessage: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.iconContainer}>
        <AeIcons.GeneratedKnowledgeGrayLarge />
      </div>
      <h3 className={styles.headline}>
        There was an error loading your articles
      </h3>
      <p className={styles.subheading}>Please refresh the page to try again.</p>
    </div>
  );
};

export { ErrorMessage };
