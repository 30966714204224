import React from "react";
import { FallbackProps } from "react-error-boundary";
import { useHistory } from "react-router-dom";
import "./AuditLogErrorFallback.scss";

const AuditLogErrorFallback: React.FC<FallbackProps> = (props) => {
  const navigate = useHistory();

  return (
    <div className="audit-log-error-fallback">
      <p>
        <span>Sorry, we ran into an issue displaying this audit log.</span>
        <button
          className="btn btn-link d-inline p-0 m-0 align-baseline"
          onClick={() => navigate.go(0)}
        >
          Try refreshing the page
        </button>
      </p>
    </div>
  );
};

export { AuditLogErrorFallback };
