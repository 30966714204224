import { ITicketInfo } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";

function fetchSocialById(id: string): Promise<ITicketInfo> {
  const url = `${getBackendApiUrl()}/social-thread/${id}/`;

  return request(url);
}

export { fetchSocialById };
