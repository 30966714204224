import { EntityType, IEdgeListing } from "@aptedge/lib-ui/src/types/entities";
import React from "react";
import { AutoLinkBadges } from "../../../pages/EdgePage/badges/AutoLinkBadges";
import { LabelChip } from "../../LabelChip/LabelChip";
import { LabelChipList } from "../../LabelChip/LabelChipList";
import { useLabelList } from "../useLabelList";
import { CellComponentProps } from "../useTable";
import "./EdgeNameCell.scss";

function EdgeNameCell(
  props: CellComponentProps<IEdgeListing>
): React.ReactElement {
  const { data: edge } = props;

  const { ref, labels: displayedLabels, showMore } = useLabelList({
    labels: edge.labels,
    minWidth: 200
  });

  return (
    <div className="edge-name-cell" ref={ref}>
      <div className="edge-name-cell-name">
        <span>{edge.name}</span>
      </div>
      <div className="edge-name-cell-badges">
        <AutoLinkBadges
          isAutolinkEnabled={edge.isAutolinkEnabled}
          isAutoreplyEnabled={edge.isAutoreplyEnabled}
        />
      </div>
      <div className="edge-name-cell-chips">
        <LabelChipList>
          {displayedLabels.map((l) => (
            <LabelChip key={l.name} label={l} />
          ))}
          {showMore && (
            <LabelChip
              label={{ id: -1, name: "...", type: EntityType.THEME }}
            />
          )}
        </LabelChipList>
      </div>
    </div>
  );
}

export { EdgeNameCell };
