import {
  ICreateConfluenceOnPremArticleSourceRequest,
  IConfluenceCloudArticleSource
} from "@aptedge/lib-ui/src/types/entities";
import { getBackendUrl } from "../../config";
import { request } from "../../utils/request";
import { patch } from "../utils/buildRequest";

async function updateConfluenceOnPremArticleSource(
  args: ICreateConfluenceOnPremArticleSourceRequest
): Promise<IConfluenceCloudArticleSource> {
  const url = `${getBackendUrl()}/api/integrations/confluence-onprem`;

  const options: RequestInit = patch(args);

  return request(url, options);
}

export { updateConfluenceOnPremArticleSource };
