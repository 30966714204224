import {
  ICompositeResult,
  SEARCH_RESULT_TYPE
} from "@aptedge/lib-ui/src/types/entities";
export type ResultsMap = Map<string, ICompositeResult>;

export const getBaseUrl = (): string =>
  window.location.origin.includes("localhost")
    ? "https://mock_data.aptedge.io"
    : window.location.origin;

export const processMappedResults = (results: ICompositeResult[]): ResultsMap =>
  results.reduce?.((map: ResultsMap, result: ICompositeResult) => {
    let url = "";
    if (result.type === SEARCH_RESULT_TYPE.EDGE)
      url = `${getBaseUrl()}/edges/${result.id}/`;
    if (result.type === SEARCH_RESULT_TYPE.GENERATED_KNOWLEDGE)
      url = `${getBaseUrl()}/articles?${result.id}/`;
    else if ("displayUrl" in result) url = result.displayUrl;
    else url = result.url;

    if (url) map.set(url, { ...result, url });
    return map;
  }, new Map<string, ICompositeResult>());
