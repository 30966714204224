import { useAppSelector } from "../redux/hook/hook";
import {
  CommentOffset,
  ICompositeResult,
  ResultOffsetType,
  ResultType,
  SEARCH_RESULT_TYPE
} from "../types/entities";
import { LocalStorageKeys, getFromLocalStorage } from "./useLocalStorage";

type HighlightOffsetProps = {
  prepareHighlightSearchResult: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    result: any,
    type: string | undefined
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getHighlightedContentByOffset: (result: any) => any;
};

enum ContentType {
  TITLE = "title",
  BODY = "body",
  COMMENT = "comment"
}

const getHighlightedResult = (
  destructuredContent: string[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  searchResult: any,
  type: string | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  switch (type) {
    case SEARCH_RESULT_TYPE.TICKET:
      return {
        ...searchResult,
        description: destructuredContent[0],
        ...(searchResult.comments && searchResult.comments.length > 0
          ? {
              comments: [...destructuredContent[1]]
            }
          : {})
      };
    case SEARCH_RESULT_TYPE.SOCIAL:
      return {
        ...searchResult,
        body: destructuredContent[0],
        ...(searchResult.comments && searchResult.comments.length > 0
          ? {
              comments: [...destructuredContent[1]]
            }
          : {})
      };
  }
};

const getHighlightedText = (
  offset: Array<[number, number]>,
  content: string,
  contentType: string,
  currentIdx: number,
  highlightedText = ""
): string => {
  offset.forEach(([start, end]) => {
    if (start < content.length) {
      const adjustedEnd = Math.min(end, content.length);
      if (start > currentIdx) {
        highlightedText += content.substring(currentIdx, start);
      }
      const textToHighlight = content.substring(start, adjustedEnd).trim();
      highlightedText +=
        contentType === ContentType.BODY
          ? `<div class='offset-highlight'>${textToHighlight}</div>`
          : `<span class='offset-highlight'>${textToHighlight}</span>`;
      currentIdx = adjustedEnd;
    }
  });

  if (currentIdx < content.length) {
    highlightedText += content.substring(currentIdx);
  }
  return highlightedText;
};

const getHighlightedContent = (
  offset: Array<[number, number]>,
  content: string,
  contentType: string
): string => {
  let newContent = "";
  if (Boolean(offset.length > 0)) {
    newContent = getHighlightedText(offset, content, contentType, 0);
  } else {
    newContent = content;
  }
  return newContent;
};

const highlightText = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  searchResult: any,
  bodyOffset: Array<[number, number]>,
  commentsOffset: CommentOffset,
  type: string | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  const body = [searchResult.description, searchResult.body].find((t) => t);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let highlightedComments: any = [];
  const highlightedBody = getHighlightedContent(
    bodyOffset,
    body,
    ContentType.BODY
  );

  if (Boolean(searchResult && searchResult.comments.length > 0)) {
    for (const key in commentsOffset) {
      const matchingComment = searchResult.comments.find(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (comment: any) => comment.id === key || comment.externalId === key
      );
      if (matchingComment) {
        const commentText =
          type === SEARCH_RESULT_TYPE.TICKET
            ? matchingComment.plainText
            : matchingComment.body;

        const commentOffset = commentsOffset[key];
        for (let i = 0; i < commentOffset.length; i++) {
          const highlightedCommentText = getHighlightedText(
            commentOffset,
            commentText,
            ContentType.COMMENT,
            0
          );
          const highlightedComment = {
            ...matchingComment,
            [type === SEARCH_RESULT_TYPE.TICKET
              ? "plainText"
              : "body"]: highlightedCommentText
          };
          highlightedComments.push(highlightedComment);
        }
      }
    }
  }
  if (Boolean(highlightedComments.length === 0)) {
    highlightedComments = [...searchResult.comments];
  }

  return getHighlightedResult(
    [highlightedBody, highlightedComments],
    searchResult,
    type
  );
};

/* 
  This function is triggered when search results are fetched after a search API call. 
  Based on each search result's offset values, the text in the title and body fields is 
  highlighted and rendered in the UI. For each search result, the generated highlighted 
  content is mapped according to its source type.
*/
const getHighlightedContentByOffset = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  result: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any => {
  const title = [result.title, result.name, result.subject].find((t) => t);
  const body = [
    result.description,
    result.body?.body,
    result.body,
    result.richSummary?.content
  ].find((t) => t);

  const highlightedTitle = getHighlightedContent(
    result.offset?.title || [],
    title || "",
    ContentType.TITLE
  );

  const highlightedBody = getHighlightedContent(
    result.offset?.body || [],
    body || "",
    ContentType.BODY
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const keyMappings: any = {
    [SEARCH_RESULT_TYPE.EDGE]: {
      name: highlightedTitle,
      richSummary: {
        content: highlightedBody
      }
    },
    [SEARCH_RESULT_TYPE.TICKET]: {
      subject: highlightedTitle
    },
    [SEARCH_RESULT_TYPE.ARTICLE]: {
      title: highlightedTitle,
      body: highlightedBody
    },
    [SEARCH_RESULT_TYPE.GENERATED_KNOWLEDGE]: {
      title: highlightedTitle,
      body: { body: highlightedBody }
    },
    [SEARCH_RESULT_TYPE.DOCUMENT]: {
      title: highlightedTitle,
      body: highlightedBody
    },
    [SEARCH_RESULT_TYPE.ISSUE]: {
      title: highlightedTitle,
      description: highlightedBody
    },
    [SEARCH_RESULT_TYPE.SOCIAL]: {
      title: highlightedTitle
    }
  };

  return {
    ...result,
    ...keyMappings[result.type]
  };
};

const getOffsets = (
  searchResultOffsets: ResultOffsetType[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recentSearches: any,
  offsetIndex: number
): [Array<[number, number]>, CommentOffset] => {
  if (Boolean(searchResultOffsets.length > 0)) {
    return [
      searchResultOffsets[offsetIndex].body,
      searchResultOffsets[offsetIndex].comment
    ];
  } else if (
    recentSearches &&
    Boolean(recentSearches.length > 0) &&
    recentSearches[offsetIndex].type !== SEARCH_RESULT_TYPE.FEDERATED
  ) {
    return [
      recentSearches[offsetIndex].offset.body,
      recentSearches[offsetIndex].offset.comment
    ];
  }
  return [[], {}];
};

const getOffsetIndex = (
  results: ICompositeResult[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  result: any
): number => {
  let index;
  index = results.findIndex(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (searchResult: any) => searchResult.id === result.id
  );
  if (index < 0) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    index = results.findIndex((searchResult: any) =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      result.comments.some((comment: any) => comment.id === searchResult.id)
    );
  }
  return index;
};

const useHighlightByOffset = (): HighlightOffsetProps => {
  const { searchResultOffsets, searchResults } = useAppSelector(
    (state) => state.search
  );

  const recentSearches: ICompositeResult[] | null = getFromLocalStorage(
    LocalStorageKeys.RECENT_SEARCH
  );

  /* 
    This function is triggered when we open ticket or social source types in preview. 
    It is necessary because comments are retrieved from a separate API call, not from 
    the search API call. The function uses the offset values stored in the Redux state 
    during the search API call to generate highlighted content for the body and comments. 
    It then maps the highlighted content based on the ticket and social source types.
  */
  const prepareHighlightSearchResult = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    result: any,
    type: string | undefined
  ): ResultType => {
    if (result) {
      const results = Boolean(searchResults.length > 0)
        ? searchResults
        : recentSearches && Boolean(recentSearches.length > 0)
        ? recentSearches
        : [];

      const offsetIndex = getOffsetIndex(results, result);

      const offsets = getOffsets(
        searchResultOffsets,
        recentSearches,
        offsetIndex
      );

      return highlightText(result, offsets[0], offsets[1], type);
    }
    return result;
  };

  return {
    prepareHighlightSearchResult,
    getHighlightedContentByOffset
  };
};

export { useHighlightByOffset };
