import { FilterWrapper } from "@aptedge/lib-ui/src/components/Filter/FilterWrapper";
import { IQueryFilter } from "@aptedge/lib-ui/src/components/Filter/types";
import { IEdgeInfo } from "@aptedge/lib-ui/src/types/entities";
import { IQueryArgs } from "@aptedge/lib-ui/src/utils/query";
import React from "react";
import { WebCacheKey } from "../../clients/cache";
import { fetchEdges } from "../../clients/Edges/fetchEdges";
import { fetchEdgesById } from "../../clients/Edges/fetchEdgesById";
import { unwrapPaginatedClient } from "../../clients/utils/paginated";
import { IPaginatedRequest } from "../../types/clients";
import { MultiSelectAsync } from "../SelectAsync/MultiSelectAsync";
import { EDGE_FILTER } from "./data";

type Props = {
  value?: IQueryFilter;
  onChange: (val: IQueryFilter) => void;
};

const getEdges = (unwrapPaginatedClient(fetchEdges) as unknown) as (
  q: IPaginatedRequest<IQueryArgs>
) => Promise<IEdgeInfo[]>;

const paginationArgs = {
  sortBy: null,
  perPage: 20,
  page: 1
};

function EdgeFilter(props: Props): React.ReactElement {
  return (
    <FilterWrapper {...EDGE_FILTER} {...props}>
      {({ values, onChange }) => {
        const ids = values?.map(Number);

        return (
          <MultiSelectAsync<IEdgeInfo, number>
            name="edge-filter"
            value={ids}
            valueKey={WebCacheKey.EDGES_BY_ID}
            valueLoader={fetchEdgesById}
            getOptionAsValue={(e) => e.id}
            optionsKey={WebCacheKey.EDGE_LISTING}
            autoComplete={false}
            optionsLoader={(textSearch) => {
              const filters: IQueryFilter[] = !!textSearch
                ? [{ id: "text", operator: "contains", values: [textSearch] }]
                : [];

              return getEdges({
                filters,
                ...paginationArgs
              });
            }}
            getOptionLabel={(e) => `EDGE-${e.id}`}
            renderOption={(e) => <>{e.displayName}</>}
            onChange={(val) => onChange(val ? val.map(String) : [])}
          />
        );
      }}
    </FilterWrapper>
  );
}

export { EdgeFilter };
