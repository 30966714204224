import { AeIcons } from "@aptedge/lib-ui/src/components/Icon/AeIcons";
import { Tooltip } from "@aptedge/lib-ui/src/components/Tooltip/Tooltip";
import React from "react";
import "./AutoLinkBadge.scss";

interface Props {
  isAutolinkEnabled: boolean;
  isAutoreplyEnabled: boolean;
}

const AutoLinkBadges: React.FC<Props> = ({
  isAutolinkEnabled,
  isAutoreplyEnabled
}) => {
  return (
    <div>
      {isAutolinkEnabled && (
        <Tooltip content="Auto link conditions are enabled">
          <AeIcons.AutoLink />
        </Tooltip>
      )}
      {isAutoreplyEnabled && (
        <Tooltip content="Auto reply conditions are enabled">
          <AeIcons.AutoReply />
        </Tooltip>
      )}
    </div>
  );
};

export { AutoLinkBadges };
