import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import { Tooltip } from "@aptedge/lib-ui/src/components/Tooltip/Tooltip";
import classNames from "classnames";
import "./SuggestSearchList.scss";
import { removeCharFromStringAndJoin } from "../../utils/utils";

interface SuggestProps
  extends Omit<
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >,
    "ref"
  > {
  className?: string;
  result: string;
  isSelected: boolean;
  isHidden: boolean;
  handleSearchQueryParamsOnSuggestions: (query: string) => void;
}

const SuggestSearchList: React.FC<SuggestProps> = ({
  isHidden,
  result,
  isSelected,
  handleSearchQueryParamsOnSuggestions
}) => {
  const displayResult = (): string => {
    return removeCharFromStringAndJoin(result, "_");
  };

  const updateQueryOnClick = (): void => {
    handleSearchQueryParamsOnSuggestions(result);
  };

  return (
    <div
      className={classNames("suggestion-item", {
        "selected-suggestion": isSelected,
        "display-hidden": isHidden
      })}
      role="button"
      onClick={updateQueryOnClick}
    >
      <Icons.Search className="search-icon" />
      <Tooltip content={<span>{displayResult()}</span>} placement="top">
        <span className="suggestion-txt">{displayResult()}</span>
      </Tooltip>
    </div>
  );
};

export { SuggestSearchList };
