import { getBackendApiUrl } from "../../config";
import { deleteRequest } from "../../utils/request";
import { deleteInit } from "../utils/buildRequest";

async function deletePhrase({ phraseId }: { phraseId: number }): Promise<void> {
  const url = `${getBackendApiUrl()}/phrases/${phraseId}/`;

  return deleteRequest(url, deleteInit());
}

export { deletePhrase };
