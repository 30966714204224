import { IEdgeAuditLog } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { IPaginatedRequest, IPaginatedResponse } from "../../types/clients";
import { request } from "../../utils/request";
import { toQueryString } from "../utils/url";

function fetchEdgeAuditLogs(
  edgeId: number,
  args: IPaginatedRequest<Record<string, unknown>>
): Promise<IPaginatedResponse<IEdgeAuditLog[]>> {
  const query = toQueryString(args);
  const url = `${getBackendApiUrl()}/edges/${edgeId}/audit-logs/?${query}`;

  return request(url);
}

export { fetchEdgeAuditLogs };
