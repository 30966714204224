import { getBackendApiUrl } from "../../config";
import { deleteRequest } from "../../utils/request";
import { deleteInit } from "../utils/buildRequest";

async function unwatchEdge({ edgeId }: { edgeId: number }): Promise<void> {
  const url = `${getBackendApiUrl()}/users/watch-edge/${edgeId}/`;

  return deleteRequest(url, deleteInit());
}

export { unwatchEdge };
