import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { faJira } from "@fortawesome/free-brands-svg-icons/faJira";
import { faAngry } from "@fortawesome/free-regular-svg-icons/faAngry";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons/faCalendarAlt";
import { faCheckSquare } from "@fortawesome/free-regular-svg-icons/faCheckSquare";
import { faClock } from "@fortawesome/free-regular-svg-icons/faClock";
import { faCopy } from "@fortawesome/free-regular-svg-icons/faCopy";
import { faObjectGroup } from "@fortawesome/free-regular-svg-icons/faObjectGroup";
import { faSave } from "@fortawesome/free-regular-svg-icons/faSave";
import { faSquare } from "@fortawesome/free-regular-svg-icons/faSquare";
import { faStar } from "@fortawesome/free-regular-svg-icons/faStar";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons/faTrashAlt";
import { faAngleDoubleLeft } from "@fortawesome/free-solid-svg-icons/faAngleDoubleLeft";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons/faAngleDoubleRight";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons/faAngleLeft";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons/faAngleRight";
import { faAngleUp } from "@fortawesome/free-solid-svg-icons/faAngleUp";
import { faArchive } from "@fortawesome/free-solid-svg-icons/faArchive";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons/faArrowCircleLeft";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons/faArrowCircleRight";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight";
import { faBolt } from "@fortawesome/free-solid-svg-icons/faBolt";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons/faCaretDown";
import { faCaretUp } from "@fortawesome/free-solid-svg-icons/faCaretUp";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons/faChevronRight";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons/faChevronUp";
import { faCog } from "@fortawesome/free-solid-svg-icons/faCog";
import { faCompress } from "@fortawesome/free-solid-svg-icons/faCompress";
import { faDownload } from "@fortawesome/free-solid-svg-icons/faDownload";
import { faEdit } from "@fortawesome/free-solid-svg-icons/faEdit";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons/faEllipsisH";
import { faExclamation } from "@fortawesome/free-solid-svg-icons/faExclamation";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons/faExclamationCircle";
import { faExpand } from "@fortawesome/free-solid-svg-icons/faExpand";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons/faExternalLinkAlt";
import { faEye } from "@fortawesome/free-solid-svg-icons/faEye";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons/faEyeSlash";
import { faFile } from "@fortawesome/free-solid-svg-icons/faFile";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons/faFileAlt";
import { faFileImport } from "@fortawesome/free-solid-svg-icons/faFileImport";
import { faHome } from "@fortawesome/free-solid-svg-icons/faHome";
import { faInfo } from "@fortawesome/free-solid-svg-icons/faInfo";
import { faLightbulb } from "@fortawesome/free-solid-svg-icons/faLightbulb";
import { faLink } from "@fortawesome/free-solid-svg-icons/faLink";
import { faMoneyBill } from "@fortawesome/free-solid-svg-icons/faMoneyBill";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons/faPaperPlane";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons/faPlusCircle";
import { faRedo } from "@fortawesome/free-solid-svg-icons/faRedo";
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import { faShareAlt } from "@fortawesome/free-solid-svg-icons/faShareAlt";
import { faShareSquare } from "@fortawesome/free-solid-svg-icons/faShareSquare";
import { faStar as faStarFilled } from "@fortawesome/free-solid-svg-icons/faStar";
import { faStream } from "@fortawesome/free-solid-svg-icons/faStream";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons/faTimesCircle";
import { faTools } from "@fortawesome/free-solid-svg-icons/faTools";
import { faUser } from "@fortawesome/free-solid-svg-icons/faUser";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons/faUserPlus";
import classNames from "classnames";
import React, { HTMLAttributes } from "react";
import { Icon, IconProps } from "./Icon";
import "./Icon.scss";

type WithIconComponent = React.FC<Props>;

const withIcon = (
  component: React.FC<IconProps>,
  icon: IconDefinition,
  className: string
): WithIconComponent => {
  const WrappedComponent = component;

  const Icon: WithIconComponent = ({
    onClick,
    size,
    className: classNameProp,
    ...rest
  }) => (
    <span
      onClick={onClick}
      className={classNames("icon", className, classNameProp, {
        link: !!onClick
      })}
      data-testid={className}
      {...rest}
    >
      <WrappedComponent icon={icon} size={size} />
    </span>
  );

  return Icon;
};

interface Props extends HTMLAttributes<HTMLSpanElement> {
  size?: SizeProp;
}

const Icons = {
  AlertCircle: withIcon(Icon, faExclamationCircle, "alert-icon"),
  AngleDoubleLeft: withIcon(Icon, faAngleDoubleLeft, "angle-double-left-icon"),
  AngleDoubleRight: withIcon(
    Icon,
    faAngleDoubleRight,
    "angle-double-right-icon"
  ),
  Angry: withIcon(Icon, faAngry, "angry-icon"),
  AngleLeft: withIcon(Icon, faAngleLeft, "angle-left-icon"),
  AngleRight: withIcon(Icon, faAngleRight, "angle-right-icon"),
  AngleUp: withIcon(Icon, faAngleUp, "angle-up-icon"),
  Archive: withIcon(Icon, faArchive, "archive-icon"),
  ArrowCircleLeft: withIcon(Icon, faArrowCircleLeft, "arrow-circle-left"),
  ArrowCircleRight: withIcon(Icon, faArrowCircleRight, "arrow-circle-left"),
  ArrowLeft: withIcon(Icon, faArrowLeft, "arrow-left"),
  ArrowRight: withIcon(Icon, faArrowRight, "arrow-right"),
  Bolt: withIcon(Icon, faBolt, "bolt-icon"),
  CalendarAlt: withIcon(Icon, faCalendarAlt, "calendar-alt-icon"),
  CaretUp: withIcon(Icon, faCaretUp, "caret-up-icon"),
  CaretDown: withIcon(Icon, faCaretDown, "caret-down-icon"),
  Clock: withIcon(Icon, faClock, "clock-icon"),
  Check: withIcon(Icon, faCheck, "check-icon"),
  CheckSquare: withIcon(Icon, faCheckSquare, "check-square-icon"),
  ChevronUp: withIcon(Icon, faChevronUp, "chevron-up-icon"),
  ChevronDown: withIcon(Icon, faChevronDown, "chevron-down-icon"),
  ChevronLeft: withIcon(Icon, faChevronLeft, "chevron-left-icon"),
  ChevronRight: withIcon(Icon, faChevronRight, "chevron-right-icon"),
  Compress: withIcon(Icon, faCompress, "compress-icon"),
  Copy: withIcon(Icon, faCopy, "copy-icon"),
  Download: withIcon(Icon, faDownload, "download-icon"),
  Edit: withIcon(Icon, faEdit, "edit-icon"),
  Elipsis: withIcon(Icon, faEllipsisH, "elipsis-icon"),
  Expand: withIcon(Icon, faFileImport, "expand-icon"),
  ExternalLink: withIcon(Icon, faExternalLinkAlt, "external-link-icon"),
  Eye: withIcon(Icon, faEye, "eye-icon"),
  Fullscreen: withIcon(Icon, faExpand, "fullscreen-icon"),
  EyeSlash: withIcon(Icon, faEyeSlash, "eye-slash-icon"),
  Exclamation: withIcon(Icon, faExclamation, "exclamation-icon"),
  File: withIcon(Icon, faFile, "file-icon"),
  FileAlt: withIcon(Icon, faFileAlt, "file-alt-icon"),
  Home: withIcon(Icon, faHome, "home-icon"),
  Info: withIcon(Icon, faInfo, "info-icon"),
  Jira: withIcon(Icon, faJira, "jira-icon"),
  Lightbulb: withIcon(Icon, faLightbulb, "lightbulb-icon"),
  Link: withIcon(Icon, faLink, "link-icon"),
  MoneyBill: withIcon(Icon, faMoneyBill, "money-bill-icon"),
  ObjectGroup: withIcon(Icon, faObjectGroup, "object-group-icon"),
  PaperPlane: withIcon(Icon, faPaperPlane, "plane-icon"),
  Plus: withIcon(Icon, faPlus, "add-icon"),
  PlusCircle: withIcon(Icon, faPlusCircle, "add-icon"),
  Redo: withIcon(Icon, faRedo, "redo-icon"),
  Save: withIcon(Icon, faSave, "save-icon"),
  Search: withIcon(Icon, faSearch, "search-icon"),
  Settings: withIcon(Icon, faCog, "settings-icon"),
  Share: withIcon(Icon, faShareAlt, "share-icon"),
  ShareSquare: withIcon(Icon, faShareSquare, "share-square-icon"),
  Square: withIcon(Icon, faSquare, "square-icon"),
  Star: withIcon(Icon, faStar, "star-icon"),
  StarFilled: withIcon(Icon, faStarFilled, "star-filled-icon"),
  Stream: withIcon(Icon, faStream, "stream-icon"),
  Times: withIcon(Icon, faTimes, "close-icon"),
  TimesCircle: withIcon(Icon, faTimesCircle, "times-circle-icon"),
  Tools: withIcon(Icon, faTools, "tools-icon"),
  Trash: withIcon(Icon, faTrashAlt, "trash-icon"),
  User: withIcon(Icon, faUser, "user-icon"),
  UserPlus: withIcon(Icon, faUserPlus, "user-plus-icon")
};

export { Icons };
