import classNames from "classnames";
import React, { useState } from "react";
import sanitizeHtml from "sanitize-html";
import "./MarkdownContent.scss";
import { useTruncatedHtml } from "./toTruncatedHtml";

interface Props
  extends Omit<
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >,
    "ref" | "placeholder"
  > {
  content?: string;
  placeholder?: React.ReactNode;
  maxLength?: number;
  disableShowMore?: boolean;
}

const MarkdownContent: React.FC<Props> = (props) => {
  const {
    content,
    className,
    placeholder,
    maxLength,
    disableShowMore,
    ...htmlProps
  } = props;
  const [showMore, setShowMore] = useState(false);

  const { html, truncatedHtml } = useTruncatedHtml(content, { maxLength });
  const shouldShowMore = !!html && !!maxLength && html !== truncatedHtml;
  const resolvedHtml = shouldShowMore
    ? showMore
      ? html
      : truncatedHtml
    : html;

  const sanitizeConfig = {
    allowedTags: [
      "p",
      "br",
      "h1",
      "h2",
      "h3",
      "h4",
      "h5",
      "b",
      "i",
      "strong",
      "em",
      "a",
      "ul",
      "ol",
      "li",
      "img"
    ],
    allowedAttributes: {
      a: ["href", "name", "target"],
      img: ["src", "alt"]
    },
    allowedSchemes: ["data", "https"]
  };

  const cleanResolvedHtml = resolvedHtml
    ? sanitizeHtml(resolvedHtml, sanitizeConfig)
    : resolvedHtml;

  const stopSomeMouseEventPropagation = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    // If the user is clicking a link in the Markdown text, stopPropagation() to
    // prevent bubbling to handlers that close/open the Editor.
    const target = e.target as HTMLElement;
    if (target.nodeName.toLowerCase() === "a") {
      e.stopPropagation();
    }
  };

  const mkClassName = classNames("markdown-content", className);

  if (resolvedHtml) {
    return (
      <div
        className={mkClassName}
        {...htmlProps}
        onMouseUp={stopSomeMouseEventPropagation}
        onClick={stopSomeMouseEventPropagation}
      >
        <div
          className="markdown-text"
          dangerouslySetInnerHTML={{ __html: cleanResolvedHtml as string }}
        />
        {!disableShowMore && shouldShowMore && (
          <div className="text-right text-primary cursor-pointer mr-1">
            {showMore ? (
              <div onClick={() => setShowMore(false)}>less</div>
            ) : (
              <div onClick={() => setShowMore(true)}>more</div>
            )}
          </div>
        )}
      </div>
    );
  }

  if (placeholder) {
    return (
      <div className={mkClassName}>
        <div className="text-muted">{placeholder}</div>
      </div>
    );
  }

  return null;
};

export { MarkdownContent };
