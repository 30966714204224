import { IEdgeInfo } from "@aptedge/lib-ui/src/types/entities";
import React from "react";
import { useMutation } from "react-query";
import { updateEdge } from "../../clients/Edges/updateEdge";
import { EdgeSectionLabel } from "./EdgeSectionLabel";
import { StatusSelect } from "./StatusSelect";

interface Props {
  edge: IEdgeInfo;
  onUpdateEdge: (edge: IEdgeInfo) => void;
}

const EdgeStatus: React.FC<Props> = (props) => {
  const { edge, onUpdateEdge } = props;

  const edgeMutation = useMutation(updateEdge, { onSuccess: onUpdateEdge });

  return (
    <React.Fragment>
      <EdgeSectionLabel>Status</EdgeSectionLabel>
      <StatusSelect
        value={edge.status}
        onChange={(status) => {
          if (status)
            edgeMutation.mutate({
              edgeId: edge.id,
              patchContent: { status }
            });
        }}
        isDisabled={edge.archived}
        isLoading={edgeMutation.isLoading}
        isError={edgeMutation.isError}
      />
    </React.Fragment>
  );
};

export { EdgeStatus };
