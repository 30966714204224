import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import classNames from "classnames";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { WebCacheKey } from "../../../clients/cache";
import { fetchTeams } from "../../../clients/Teams/fetchTeams";
import WithLoading from "../../../components/WithLoading/WithLoading";
import { TeamCreateItem } from "./TeamCreateItem";
import { TeamItem } from "./TeamItem";

const TeamSettings: React.FC = () => {
  const teamQuery = useQuery([WebCacheKey.TEAMS], () =>
    fetchTeams({ sortBy: null, perPage: 1000, page: 1 })
  );
  const [showAddTeam, setShowAddTeam] = useState<boolean>(false);

  const teams = teamQuery.data?.items || [];

  const onChange = (): void => {
    setShowAddTeam(false);
    teamQuery.refetch();
  };

  return (
    <>
      <section className="row mb-5">
        <div className="col-md-5 p-2 pr-3">
          <h4 className="text-uppercase">Teams</h4>
          <p>Configure teams to route Edges to the right Support team.</p>
        </div>
        <div className="col-md-7 p-2 pl-3">
          <div className="mb-3 d-flex justify-content-between">
            <div className="mr-2">
              <h5>Teams</h5>
              <p>Add labels to the appropriate team</p>
            </div>
            <div>
              <Button color="secondary" onClick={() => setShowAddTeam(true)}>
                <Icons.Plus />
                <span>Add</span>
              </Button>
            </div>
          </div>
          {showAddTeam && (
            <TeamCreateItem
              onSave={onChange}
              onCancel={() => setShowAddTeam(false)}
            />
          )}
          <WithLoading
            isLoading={teamQuery.isLoading}
            fallback={<Spinner />}
            type="overlay"
          >
            <ul className={classNames("team-list list-group")}>
              {teams.map((t) => (
                <TeamItem key={t.id} team={t} onChange={onChange} />
              ))}
            </ul>
          </WithLoading>
        </div>
      </section>
      <hr className="mt-4" />
    </>
  );
};

export { TeamSettings };
