import merge from "lodash/merge";
import { IFlagSet } from "./types";

type IDevToolsOverridesState = Partial<IFlagSet>;
type IDevToolsFlagsAction =
  | {
      type: "setOverrides";
      payload: Partial<IFlagSet>;
    }
  | { type: "reset" };

function devToolsFlagsReducer(
  state: IDevToolsOverridesState,
  action: IDevToolsFlagsAction
): IDevToolsOverridesState {
  switch (action.type) {
    case "setOverrides":
      return merge({}, state, action.payload);
    case "reset":
      return {};
    default:
      return state;
  }
}

export { devToolsFlagsReducer };
