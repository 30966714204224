import classNames from "classnames";
import React, { CSSProperties } from "react";
import { gradient } from "../../styles/colors";
import "./SentimentScore.scss";

interface Props {
  score: number;
  size?: number;
  className?: string;
  style?: CSSProperties;
}

function SentimentScore(props: Props): React.ReactElement {
  const { score, size = 16, className, style } = props;

  return (
    <div
      className={classNames("sentiment-score", className)}
      style={{
        ...style,
        fontSize: size,
        backgroundColor: gradient("#f9b73e", "#ed6a5a", score)
      }}
    >
      {score}
    </div>
  );
}

export { SentimentScore };
