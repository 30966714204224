import {
  ICreateSharepointDriveSourceRequest,
  ISharepointDriveSource
} from "@aptedge/lib-ui/src/types/entities";
import { getBackendUrl } from "../../config";
import { request } from "../../utils/request";
import { patch } from "../utils/buildRequest";

async function updateSharepointDriveSource(
  args: ICreateSharepointDriveSourceRequest
): Promise<ISharepointDriveSource> {
  const url = `${getBackendUrl()}/api/integrations/sharepoint-drive`;
  const options: RequestInit = patch(args);
  return request(url, options);
}

export { updateSharepointDriveSource };
