import styled from "@emotion/styled";
import { Tooltip, TooltipProps, tooltipClasses } from "@mui/material";

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    fontSize: 12
  },
  [`& .${tooltipClasses.arrow}`]: {
    "&:before": {
      border: "1px solid #FFFFFF"
    },
    color: "#FFFFFF"
  }
}));
