/* eslint-disable @typescript-eslint/no-explicit-any */
import log from "loglevel";

/**
 * Custom Log functions that only log to the console
 * in `production` and `development` modes (not `test`).
 */
const logger = {
  log: (...data: any[]): void => {
    log.log(...data);
  },
  warn: (...data: any[]): void => {
    log.warn(...data);
  },
  error: (...data: any[]): void => {
    log.error(...data);
  }
};

export { logger };
