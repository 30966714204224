import { FilterWrapper } from "@aptedge/lib-ui/src/components/Filter/FilterWrapper";
import {
  IQueryFilter,
  IQueryFilterDefinition
} from "@aptedge/lib-ui/src/components/Filter/types";
import { MultiSelect } from "@aptedge/lib-ui/src/components/MultiSelect/MultiSelect";
import React from "react";
import { useQuery } from "react-query";
import { WebCacheKey } from "../../clients/cache";
import { fetchTicketUsers } from "../../clients/Tickets/fetchTicketUsers";
import { Routes } from "../../routes/routes";
import { StyledLink } from "../StyledLink/StyledLink";
import { ASSIGNEE_FILTER } from "./data";

const ONE_HOUR = 60 * 1000 * 60;

type Props = {
  value?: IQueryFilter;
  onChange: (val: IQueryFilter) => void;
};

function WithUserFilter(
  spec: IQueryFilterDefinition
): (props: Props) => React.ReactElement {
  function Component(props: Props): React.ReactElement {
    const ticketUsersQuery = useQuery(
      WebCacheKey.TICKET_USERS,
      fetchTicketUsers,
      {
        refetchOnWindowFocus: false, // Don't need to revalidate these on window refocus.
        refetchOnMount: false,
        // The time in milliseconds after data is considered stale. Once marked stale, RQ will refetch.
        staleTime: ONE_HOUR,
        // The time in milliseconds that unused/inactive cache (no components using it are mounted) data remains in memory.
        cacheTime: ONE_HOUR
      }
    );

    const users =
      ticketUsersQuery.data
        ?.slice()
        .sort((a, b) => a.name.localeCompare(b.name)) || [];

    return (
      <FilterWrapper {...spec} {...props}>
        {({ values, onChange }) => {
          const selected = users.filter(
            (u) => !!values && values.includes(String(u.id))
          );

          return (
            <MultiSelect
              name="status-filter"
              value={selected}
              data={users}
              getOptionLabel={(user) => user.email}
              onChange={(users) => onChange(users.map((u) => String(u.id)))}
              noDataPlaceholder={
                <div className="mt-2 mb-2 text-center">
                  There are no agent email domains configured. Try configuring
                  some &nbsp;
                  <StyledLink to={Routes.SETTINGS_MACHINE_LEARNING}>
                    here
                  </StyledLink>
                  .
                </div>
              }
            />
          );
        }}
      </FilterWrapper>
    );
  }

  Component.displayName = `WithTicketUserFilter-${spec.label}`;

  return Component;
}

const TicketAssigneeFilter = WithUserFilter(ASSIGNEE_FILTER);

export { TicketAssigneeFilter };
