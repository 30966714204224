import { ModalWidth } from "@aptedge/lib-ui/src/components/Modal/Modal";
import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import {
  IJiraOnPremiseInternalIssueSource,
  IJiraOnPremStatus,
  IntegrationStatus
} from "@aptedge/lib-ui/src/types/entities";
import { GATEWAY_TIMEOUT } from "http-status-codes";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { ToastType } from "react-toastify";
import { createInternalJiraOnPremiseSource } from "../../../clients/InternalIssueSources/createJiraOnPremiseInternalSource";
import { ConfirmationModal } from "../../../components/ConfirmationModal/ConfirmationModal";
import { Toast } from "../../../components/Toast/Toast";
import WithLoading from "../../../components/WithLoading/WithLoading";
import { ClientStatus } from "../../../types/clients";
import { AddButton } from "../common/AddButton";
import { StatusButton } from "../common/StatusButton";
import {
  JiraOnPremiseConfigForm,
  JiraOnPremiseFormData
} from "./JiraOnPremiseConfigForm";

interface Props {
  issueResource: IJiraOnPremiseInternalIssueSource | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
  reloadTap: () => void;
  isLoading: boolean;
}

const JiraOnPremiseConfig: React.FunctionComponent<Props> = (props) => {
  const { issueResource, error, isLoading, reloadTap } = props;

  const [showModal, setShowModal] = useState<boolean>(false);

  const [status, setStatus] = React.useState(ClientStatus.PROMPT);

  const internalIssueSource = issueResource || null;

  const addSource = useMutation(createInternalJiraOnPremiseSource, {
    onError: () => {
      setStatus(ClientStatus.ERROR);
    },
    onSuccess: () => {
      setShowModal(false);
      setStatus(ClientStatus.SUCCESS);
      reloadTap();
    }
  });

  const onFormSubmit = (formData: JiraOnPremiseFormData): void => {
    setStatus(ClientStatus.LOADING);
    addSource.mutate(formData);
  };

  const getStatus = (): IntegrationStatus => {
    if (internalIssueSource?.status === IJiraOnPremStatus.AUTHENTICATED)
      return "ok";
    if (error?.code === GATEWAY_TIMEOUT) return "timeOut";
    return "error";
  };

  const isLoaderVisible = status === ClientStatus.LOADING || isLoading;
  const isConnectionExist = internalIssueSource || getStatus() === "timeOut";

  return (
    <>
      <div className="mb-3">
        <h5>Jira On-Premise</h5>
        <p>Configure your Jira On-Premise below.</p>
      </div>
      <WithLoading
        isLoading={isLoaderVisible}
        fallback={
          <div className="d-flex justify-content-center m-4">
            <Spinner />
          </div>
        }
      >
        {isConnectionExist ? (
          <StatusButton
            status={getStatus()}
            onClickEdit={() => setShowModal(true)}
          >
            <>
              <div>Jira On-Premise</div>
            </>
          </StatusButton>
        ) : (
          <AddButton
            data-testid="jira-on-prem-connect-btn"
            onClick={() => setShowModal(true)}
          >
            Connect
          </AddButton>
        )}
      </WithLoading>
      <ConfirmationModal
        title="Jira On-Premise Settings"
        width={ModalWidth.MEDIUM}
        loadingTitle="Loading..."
        status={status}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      >
        <p className="mb-4">Configure your settings below.</p>
        <JiraOnPremiseConfigForm
          source={internalIssueSource}
          onSubmit={onFormSubmit}
        />
      </ConfirmationModal>

      {addSource.isError && (
        <Toast type={ToastType.ERROR}>Something went wrong.</Toast>
      )}
    </>
  );
};

export { JiraOnPremiseConfig };
