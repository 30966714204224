import { ModalWidth } from "@aptedge/lib-ui/src/components/Modal/Modal";
import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import {
  IntegrationStatus,
  IZendeskKBArticleSource,
  OAuthStatus
} from "@aptedge/lib-ui/src/types/entities";
import { GATEWAY_TIMEOUT } from "http-status-codes";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { ToastType } from "react-toastify";
import { createZendeskKBArticleSource } from "../../../clients/ArticleSources/createZendeskKBArticleSource";
import { ConfirmationModal } from "../../../components/ConfirmationModal/ConfirmationModal";
import { Toast } from "../../../components/Toast/Toast";
import WithLoading from "../../../components/WithLoading/WithLoading";
import { ClientStatus } from "../../../types/clients";
import { AddButton } from "../common/AddButton";
import { StatusButton } from "../common/StatusButton";
import { ZendeskKBConfigForm, ZendeskKBFormData } from "./ZendeskKBConfigForm";

interface Props {
  articleIssueSource: IZendeskKBArticleSource | null;
  isLoading: boolean;
  refetchZendeskKBtap: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
}

const ZendeskKBConfig: React.FunctionComponent<Props> = (props) => {
  const { articleIssueSource, isLoading, error, refetchZendeskKBtap } = props;
  const [showModal, setShowModal] = useState<boolean>(false);
  const [redirectStatus, setRedirectStatus] = useState(ClientStatus.PROMPT);

  const addSource = useMutation(createZendeskKBArticleSource, {
    onError: () => {
      setRedirectStatus(ClientStatus.ERROR);
    },
    onSuccess: () => {
      refetchZendeskKBtap();
      setRedirectStatus(ClientStatus.SUCCESS);
      setShowModal(false);
    }
  });

  const onZendeskKBFormSubmit = (formData: ZendeskKBFormData): void => {
    setRedirectStatus(ClientStatus.LOADING);
    addSource.mutate(formData);
  };

  const getStatus = (): IntegrationStatus => {
    if (error?.code === GATEWAY_TIMEOUT) return "timeOut";
    if (articleIssueSource?.status === OAuthStatus.AUTHENTICATED) return "ok";
    return "error";
  };

  const isConnectionExist = articleIssueSource || getStatus() === "timeOut";
  const isLoaderVisible = redirectStatus === ClientStatus.LOADING || isLoading;

  return (
    <div>
      <div className="mt-5 mb-3">
        <h5>Zendesk Knowledge Base</h5>
        <p>Configure your Zendesk Knowledge Base settings below.</p>
      </div>
      <WithLoading
        isLoading={isLoaderVisible}
        fallback={
          <div className="d-flex justify-content-center m-4">
            <Spinner />
          </div>
        }
      >
        {isConnectionExist ? (
          <StatusButton
            data-testid="zendesk-kb-status-btn"
            status={getStatus()}
            onClickEdit={() => setShowModal(true)}
          >
            Zendesk Knowledge Base
          </StatusButton>
        ) : (
          <AddButton
            data-testid={"zendesk-kb-add-btn"}
            onClick={() => setShowModal(true)}
          >
            Connect to Zendesk Knowledge Base
          </AddButton>
        )}
      </WithLoading>
      <div>
        <ConfirmationModal
          title="Zendesk Knowledge Base Auth Settings"
          width={ModalWidth.MEDIUM}
          loadingTitle="Loading ..."
          status={redirectStatus}
          isOpen={showModal}
          onClose={() => setShowModal(false)}
        >
          <p className="mb-4">Configure your settings below.</p>
          <ZendeskKBConfigForm
            source={articleIssueSource}
            onSubmit={onZendeskKBFormSubmit}
          />
        </ConfirmationModal>
      </div>
      {addSource.isError && (
        <Toast type={ToastType.ERROR}>Something went wrong.</Toast>
      )}
    </div>
  );
};

ZendeskKBConfig.defaultProps = {
  articleIssueSource: null
};

export { ZendeskKBConfig };
