import { ISharepointSource } from "@aptedge/lib-ui/src/types/entities";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

type SliceStateType = {
  dataSource: ISharepointSource | null;
};

const initialState: SliceStateType = {
  dataSource: null
};

const sharepointSlice = createSlice({
  name: "sharepoint",
  initialState,
  reducers: {
    setSharepointTapData: (
      state,
      action: PayloadAction<ISharepointSource | null>
    ) => {
      state.dataSource = action.payload;
    }
  }
});

export const { setSharepointTapData } = sharepointSlice.actions;

export default sharepointSlice.reducer;
