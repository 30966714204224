import {
  getFromLocalStorage,
  LocalStorageKeys,
  saveToLocalStorage
} from "@aptedge/lib-ui/src/hooks/useLocalStorage";
import { isEmpty } from "lodash";
import { CssConfig, SELF_SERVICE_USAGE_LIMIT } from "./configFieldKeys";

export const storeTempConfig = (payload: CssConfig): void => {
  if (isEmpty(payload)) return;

  const currentConfig = (getFromLocalStorage(
    LocalStorageKeys.CSS_APP_CONFIG_TEMP
  ) || {}) as Record<string, unknown>;

  const updatedConfig = {
    ...currentConfig,
    ...payload
  };

  saveToLocalStorage(LocalStorageKeys.CSS_APP_CONFIG_TEMP, updatedConfig);
};

export const validateInputByName = (
  name: string,
  value: string | number | boolean
): boolean => {
  if (name === SELF_SERVICE_USAGE_LIMIT && Number(value) > 3000) return true;
  return false;
};
