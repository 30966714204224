import React from "react";
import { Route } from "react-router-dom";
import { ArticlesPage } from "../pages/ArticlesPage/ArticlesPage";
import { ArticlesPendingPage } from "../pages/ArticlesPendingPage/ArticlesPendingPage";
import { ArticlesReviewPage } from "../pages/ArticlesReviewPage/ArticlesReviewPage";
import { Routes } from "./routes";

const ArticlesRoutes: React.FunctionComponent = () => {
  return (
    <>
      <Route exact path={Routes.ARTICLES} component={ArticlesPage} />
      <Route
        exact
        path={Routes.ARTICLES_PENDING}
        component={ArticlesPendingPage}
      />
      <Route
        exact
        path={Routes.ARTICLES_REVIEW}
        component={ArticlesReviewPage}
      />
    </>
  );
};

export { ArticlesRoutes };
