import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { TextInput } from "@aptedge/lib-ui/src/components/TextInput/TextInput";
import { useFormik, validateYupSchema, yupToFormErrors } from "formik";
import React, { useRef } from "react";
import Helmet from "react-helmet";
import { useMutation, useQuery } from "react-query";
import { Redirect } from "react-router-dom";
import * as Yup from "yup";
import { updatePassword } from "../../clients/Account/updatePassword";
import { WebCacheKey } from "../../clients/cache";
import { fetchPasswordComplexityConfig } from "../../clients/Config/fetchPasswordComplexityConfig";
import { Toast } from "../../components/Toast/Toast";
import WithLoading from "../../components/WithLoading/WithLoading";
import { useUrlSearchParams } from "../../hooks/useUrlSearchParams";
import { Routes } from "../../routes/routes";
import { createErrorMessage } from "../../utils/createErrorMessage";
import { LoginContent } from "../LoginPage/LoginContent";

const LoginRecoveryPage: React.FunctionComponent = () => {
  const [{ token }] = useUrlSearchParams<{ token?: string }>({});
  const highComplexityQuery = useQuery(
    WebCacheKey.PASSWORD_COMPLEXITY_CONFIG,
    () => fetchPasswordComplexityConfig()
  );
  const updatePasswordClient = useMutation(updatePassword);

  const inputRef = useRef<HTMLInputElement>(null);

  const validationSchema = Yup.object({
    password: Yup.string()
      .required("Password is required.")
      .when("$requireHighComplexity", {
        is: true,
        then: Yup.string().matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])(?=.{12,})/,
          "Passwords must contain at least 12 characters, including: one uppercase, one lowercase, one number and one special character."
        ),
        otherwise: Yup.string().min(
          8,
          "Passwords must contain at least 8 characters."
        )
      }),
    confirmPassword: Yup.string()
      .required("You must confirm your password.")
      .oneOf([Yup.ref("password"), ""], "Passwords do not match.")
  });

  const formik = useFormik<FormState>({
    initialValues: { password: "", confirmPassword: "" },
    validate: (values: FormState) => {
      try {
        validateYupSchema(values, validationSchema, true, {
          requireHighComplexity: !!highComplexityQuery.data
        });
      } catch (e) {
        return yupToFormErrors(e);
      }

      return {};
    },
    onSubmit: (data: FormState): void => {
      if (token) {
        updatePasswordClient.mutate({ token, password: data.password });
      }
    },
    validateOnMount: false,
    validateOnBlur: true
  });

  if (!token) {
    return <Redirect to={Routes.LOGIN} />;
  }

  return (
    <>
      <Helmet>
        <title>Password Reset</title>
      </Helmet>
      <WithLoading isLoading={highComplexityQuery.isLoading}>
        <LoginContent>
          <div className="header">
            <img alt="logo" className="logo" src="/trimmed-logo.png" />
            <h2>Password reset</h2>
          </div>
          <p>Please enter your new password below.</p>
          <form onBlur={formik.handleBlur} onSubmit={formik.handleSubmit}>
            {!!updatePasswordClient.error && (
              <div className="alert alert-danger">
                {createErrorMessage(updatePasswordClient.error)}
              </div>
            )}
            <div className="form-group">
              <TextInput
                ref={inputRef}
                type="password"
                name="password"
                value={formik.values.password}
                error={formik.errors.password}
                label="Password"
                onChange={formik.handleChange}
                onClear={() => formik.setFieldValue("password", "")}
                autoFocus
                data-testid="password"
              />
            </div>
            <div className="form-group">
              <TextInput
                type="password"
                name="confirmPassword"
                value={formik.values.confirmPassword}
                error={formik.errors.confirmPassword}
                label="Confirm Password"
                onChange={formik.handleChange}
                onClear={() => formik.setFieldValue("confirmPassword", "")}
                data-testid="confirm-password"
              />
            </div>
            <Button
              className="mt-3"
              disabled={updatePasswordClient.isLoading || !formik.isValid}
              type="submit"
            >
              Reset
            </Button>
          </form>
        </LoginContent>
        {updatePasswordClient.isSuccess && (
          <>
            <Toast type="success">Successfully updated password!</Toast>
            <Redirect to={Routes.LOGIN} />
          </>
        )}
      </WithLoading>
    </>
  );
};

interface FormState {
  password: string;
  confirmPassword: string;
}

export { LoginRecoveryPage };
