import { FontSize } from "@aptedge/lib-ui/src/styles/typography";
import {
  IClassificationMetrics,
  ITheme,
  TicketStatus
} from "@aptedge/lib-ui/src/types/entities";
import React, { PropsWithChildren, ReactElement } from "react";
import { CardBar } from "../../components/SlimCard/CardBar";
import { SlimCard } from "../../components/SlimCard/SlimCard";

const ROW_BASE_CLASSNAME = "row mb-3 bg-transparent-sm";
const CARD_BASE_CLASSNAME = "col-md-4 col-12 mb-2";

function MetricsContent({ data, themes }: Props): ReactElement | null {
  const openData = data.find((d) => d.status === TicketStatus.OPEN);
  const closedData = data.find((d) => d.status === TicketStatus.CLOSED);

  const totalTickets = sumOptionals(openData?.total, closedData?.total);
  const totalWithAnyClassification = sumOptionals(
    openData?.withAnyClassification,
    closedData?.withAnyClassification
  );

  return (
    <div className="container" style={{ maxWidth: 900 }}>
      <CardBar className={ROW_BASE_CLASSNAME}>
        <SlimCard
          className={CARD_BASE_CLASSNAME}
          title="Tickets Classified"
          value={totalWithAnyClassification}
        />
        <SlimCard
          className={CARD_BASE_CLASSNAME}
          title="Tickets Ingested"
          value={totalTickets}
        />
        <SlimCard
          className={CARD_BASE_CLASSNAME}
          title="Domain Themes"
          value={themes.filter((t) => !t.common).length}
        />
      </CardBar>
      <H5>Open Tickets</H5>
      <div className={ROW_BASE_CLASSNAME}>
        <SlimCard
          className={CARD_BASE_CLASSNAME}
          title="With Product Areas"
          value={openData?.withProducts}
        />
        <SlimCard
          className={CARD_BASE_CLASSNAME}
          title="With Features"
          value={openData?.withFeatures}
        />
        <SlimCard
          className={CARD_BASE_CLASSNAME}
          title="With Linked Issues"
          value={openData?.withLinkedIssue}
        />
        <SlimCard
          className={CARD_BASE_CLASSNAME}
          title="With Domain Themes"
          value={openData?.withDomainThemes}
        />
        <SlimCard
          className={CARD_BASE_CLASSNAME}
          title="With Common Themes"
          value={openData?.withCommonThemes}
        />
      </div>
      <H5>Closed Tickets</H5>
      <div className={ROW_BASE_CLASSNAME}>
        <SlimCard
          className={CARD_BASE_CLASSNAME}
          title="With Product Areas"
          value={closedData?.withProducts}
        />
        <SlimCard
          className={CARD_BASE_CLASSNAME}
          title="With Features"
          value={closedData?.withFeatures}
        />
        <SlimCard
          className={CARD_BASE_CLASSNAME}
          title="With Linked Issues"
          value={closedData?.withLinkedIssue}
        />
        <SlimCard
          className={CARD_BASE_CLASSNAME}
          title="With Domain Themes"
          value={closedData?.withDomainThemes}
        />
        <SlimCard
          className={CARD_BASE_CLASSNAME}
          title="With Common Themes"
          value={closedData?.withCommonThemes}
        />
      </div>
    </div>
  );
}

function H5({
  children
}: PropsWithChildren<Record<string, unknown>>): ReactElement {
  return (
    <h5
      className="text-uppercase mb-3 mt-4 pl-2"
      style={{ fontSize: FontSize.XSMALL, color: "var(--gray-800)" }}
    >
      {children}
    </h5>
  );
}

function sumOptionals(n1?: number, n2?: number): number | undefined {
  return !n1 && !n2 ? undefined : (n1 || 0) + (n2 || 0);
}

interface Props {
  data: IClassificationMetrics[];
  themes: ITheme[];
}

export { MetricsContent };
