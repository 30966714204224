import {
  useAppSelector,
  useAppDispatch
} from "@aptedge/lib-ui/src/redux/hook/hook";
import { updateMSTeamsFlags } from "@aptedge/lib-ui/src/redux/reduxSlice/msTeamsSlice";
import {
  IMSTeamsGroup,
  IMSTeamsFlags
} from "@aptedge/lib-ui/src/types/entities";
import { useCallback, useEffect, useRef, useState } from "react";

interface MSTeamsList {
  handleUpdateChannels: () => void;
  handleClick: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => void;
  handleClose: (index: number) => void;
  anchorEls: Array<HTMLElement | null>;
  selectedIndex: number;
  indexRef: React.RefObject<HTMLDivElement> | null;
}

function useMSTeamsListItemConfig(
  handleOnCancel: (index: number) => void,
  handleShowChannels: () => void,
  groupList: IMSTeamsGroup[]
): MSTeamsList {
  const dispatch = useAppDispatch();
  const initialAnchorEl = document.createElement("div");
  const [anchorEls, setAnchorEls] = useState<Array<HTMLElement | null>>(
    Array(groupList?.length).fill(initialAnchorEl)
  );
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [channelsAdded, setChannelsAdded] = useState<boolean>(false);

  const indexRef = useRef<HTMLDivElement>(null);

  const { channelsLoaded } = useAppSelector((state) => state.msTeams);

  const updateAnchors = useCallback(
    (element: HTMLElement | null, index: number) => {
      const newAnchorEls = [...anchorEls];
      if (element) {
        newAnchorEls[index] = element;
      }
      setAnchorEls(newAnchorEls);
    },
    [anchorEls]
  );

  const handleClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ): void => {
    setSelectedIndex(index);
    updateAnchors(event.currentTarget, index);
  };

  const handleUpdateChannels = (): void => {
    handleShowChannels();
    setChannelsAdded(true);
  };

  const handleCloseRef = useRef(() => {
    updateAnchors(null, selectedIndex);
    setSelectedIndex(-1);
    setChannelsAdded(false);
  });

  const handleClose = useCallback(
    (index: number): void => {
      handleCloseRef.current();
      if (index !== -1) {
        handleOnCancel(index);
      }
    },
    [handleOnCancel]
  );

  useEffect(() => {
    if (channelsAdded) {
      handleClose(-1);
    }
    if (channelsLoaded) {
      if (indexRef.current) {
        updateAnchors(indexRef.current, groupList.length - 1);
      }
      setSelectedIndex(groupList.length - 1);
      dispatch(updateMSTeamsFlags({ mode: IMSTeamsFlags.LOAD, value: false }));
    }
  }, [
    channelsAdded,
    handleClose,
    channelsLoaded,
    updateAnchors,
    setSelectedIndex,
    groupList,
    dispatch
  ]);

  return {
    handleUpdateChannels,
    handleClick,
    handleClose,
    anchorEls,
    selectedIndex,
    indexRef
  };
}

export { useMSTeamsListItemConfig };
