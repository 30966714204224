import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import { IFlagSet } from "@aptedge/lib-ui/src/context/FlagsContext/types";
import { ICompositeResult } from "@aptedge/lib-ui/src/types/entities";
import { useCallback, useEffect, useState } from "react";
import { useAppSelector } from "../../../../redux/hook/hook";
import { ResultsMap, processMappedResults } from "../../utils";

interface UseAiAnswerReturnType {
  mappedResults: ResultsMap;
  totalSearchResults: number;
  flags: IFlagSet;
}

const useAiAnswer = (): UseAiAnswerReturnType => {
  const { flags } = useFlags();
  const { searchResults, totalSearchResults } = useAppSelector(
    (state) => state.search
  );
  const [mappedResults, setMappedResults] = useState<ResultsMap>(
    new Map() as ResultsMap
  );
  const memoisedProcessMappedResults = useCallback(
    () => processMappedResults(searchResults as ICompositeResult[]),
    [searchResults]
  );
  useEffect(() => {
    if (searchResults) {
      const results = memoisedProcessMappedResults();
      setMappedResults(results);
    }
  }, [memoisedProcessMappedResults, searchResults]);

  return {
    mappedResults,
    totalSearchResults,
    flags
  };
};

export default useAiAnswer;
