import { ModalWidth } from "@aptedge/lib-ui/src/components/Modal/Modal";
import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import { useAppDispatch } from "@aptedge/lib-ui/src/redux/hook/hook";
import {
  setMSTeamsTapData,
  setMSTeamsTenantId,
  updateMSTeamsFlags
} from "@aptedge/lib-ui/src/redux/reduxSlice/msTeamsSlice";
import {
  IntegrationStatus,
  INTEGRATION_STATUS,
  OAuthStatus,
  IMSTeamsFlags
} from "@aptedge/lib-ui/src/types/entities";
import { GATEWAY_TIMEOUT } from "http-status-codes";
import { useEffect, useState, useCallback, FC } from "react";
import { useMutation } from "react-query";
import { ToastType } from "react-toastify";
import { createMSTeamsConnection } from "../../../../clients/SocialSource/MSTeamsSource/createMSTeamsConnection";
import { updateMSTeamsConnection } from "../../../../clients/SocialSource/MSTeamsSource/updateMSTeamsConnection";
import { ConfirmationModal } from "../../../../components/ConfirmationModal/ConfirmationModal";
import { Toast } from "../../../../components/Toast/Toast";
import WithLoading from "../../../../components/WithLoading/WithLoading";
import { getBackendUrl } from "../../../../config";
import { useMsTeamsSource } from "../../../../hooks/clients/useDataSource";
import { ClientStatus } from "../../../../types/clients";
import { AddButton } from "../../common/AddButton";
import { StatusButton } from "../../common/StatusButton";
import { MSTeamsConfigForm, MSTeamsConfigFormData } from "./MSTeamsConfigForm";
import { MSTeamsListConfig } from "./MSTeamsListConfig";

const MSTeamConfig: FC = () => {
  const dataSource = useMsTeamsSource();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [redirectStatus, setRedirectStatus] = useState(ClientStatus.PROMPT);
  const dispatch = useAppDispatch();

  const msTeamsSource = dataSource?.dataSource ?? null;
  const addSource = useMutation(createMSTeamsConnection, {
    onError: () => {
      setRedirectStatus(ClientStatus.ERROR);
    },
    onSuccess: () => {
      setRedirectStatus(ClientStatus.SUCCESS);
      const url = `${getBackendUrl()}/api/integrations/msteams/login`;
      window.location.href = url;
    }
  });
  const updateSource = useMutation(updateMSTeamsConnection, {
    onError: () => {
      setRedirectStatus(ClientStatus.ERROR);
    },
    onSuccess: () => {
      setRedirectStatus(ClientStatus.SUCCESS);
      const url = `${getBackendUrl()}/api/integrations/msteams/login`;
      window.location.href = url;
    }
  });

  const onFormSubmit = (formData: MSTeamsConfigFormData): void => {
    setRedirectStatus(ClientStatus.LOADING);
    msTeamsSource ? updateSource.mutate(formData) : addSource.mutate(formData);
  };

  const getStatus = (): IntegrationStatus => {
    if (dataSource?.error?.code === GATEWAY_TIMEOUT)
      return INTEGRATION_STATUS.TIME_OUT;
    if (msTeamsSource?.status === OAuthStatus.AUTHENTICATED)
      return INTEGRATION_STATUS.OK;
    return INTEGRATION_STATUS.ERROR;
  };

  const handleUpdateTeamSource = useCallback((): void => {
    dispatch(setMSTeamsTapData(msTeamsSource ?? null));
    dispatch(setMSTeamsTenantId(msTeamsSource?.tenantId));
    dispatch(updateMSTeamsFlags({ mode: IMSTeamsFlags.RELOAD, value: false }));
  }, [dispatch, msTeamsSource]);

  const getDatasource = (): void => {
    dataSource.reload();
  };

  useEffect(() => {
    handleUpdateTeamSource();
  }, [handleUpdateTeamSource]);

  const isConnectionExist =
    msTeamsSource || getStatus() === INTEGRATION_STATUS.TIME_OUT;
  const isLoaderVisible = addSource.isLoading || dataSource.isLoading;

  return (
    <section>
      <div className="row">
        <WithLoading isLoading={isLoaderVisible} fallback={<Spinner />}>
          <div className="mt-2 pl-3">
            <h5>MS Teams</h5>
            <p>Configure your MS Teams settings below.</p>
          </div>

          <div className="col-md-12 p-2 pl-3">
            {isConnectionExist ? (
              <StatusButton
                status={getStatus()}
                onClickEdit={() => setShowModal(true)}
              >
                <>
                  <div>MS Teams</div>
                  <p className="tenantId">
                    Tenant ID: {msTeamsSource?.tenantId}
                  </p>
                </>
              </StatusButton>
            ) : (
              <AddButton onClick={() => setShowModal(true)}>Connect</AddButton>
            )}
          </div>
        </WithLoading>
        <ConfirmationModal
          title="MS Teams Settings"
          width={ModalWidth.MEDIUM}
          loadingTitle="Loading...."
          status={redirectStatus}
          isOpen={showModal}
          onClose={() => setShowModal(false)}
        >
          <p className="mb-4">Configure your settings below.</p>
          <MSTeamsConfigForm onSubmit={onFormSubmit} />
        </ConfirmationModal>
      </div>
      {msTeamsSource?.status === OAuthStatus.AUTHENTICATED && (
        <>
          <MSTeamsListConfig getDatasource={getDatasource} />
        </>
      )}
      {addSource.isError && (
        <Toast type={ToastType.ERROR}>Something went wrong.</Toast>
      )}
    </section>
  );
};

export { MSTeamConfig };
