import { MultiSelect } from "@aptedge/lib-ui/src/components/MultiSelect/MultiSelect";
import {
  EntityType,
  IEntity,
  ILabel
} from "@aptedge/lib-ui/src/types/entities";
import React from "react";
import { useProducts, useFeatures } from "../../hooks/clients/useProducts";
import { useThemes } from "../../hooks/clients/useThemes";

interface Props {
  className?: string;
  onChange?: (selected: ILabel[]) => void;
  name: string;
  value?: ILabel[];
  placeholder?: string;
}

function LabelMultiSelect(props: Props): React.ReactElement {
  const { className, onChange, name, placeholder, value } = props;

  const productsQuery = useProducts();
  const sortedProducts: ILabel[] = React.useMemo(
    () => sortAndAddType(EntityType.PRODUCT, productsQuery.data),
    [productsQuery.data]
  );

  const featuresQuery = useFeatures();
  const sortedFeatures: ILabel[] = React.useMemo(
    () => sortAndAddType(EntityType.FEATURE, featuresQuery.data),
    [featuresQuery.data]
  );

  const themesQuery = useThemes();
  const sortedThemes: ILabel[] = React.useMemo(
    () => sortAndAddType(EntityType.THEME, themesQuery.data),
    [themesQuery.data]
  );

  const labelOptions = [...sortedProducts, ...sortedFeatures, ...sortedThemes];

  return (
    <MultiSelect
      name={name}
      className={className}
      placeholder={placeholder}
      data={labelOptions}
      value={value}
      getOptionLabel={(l) => l.name}
      onChange={onChange}
    />
  );
}

const sortAndAddType = (type: EntityType, data?: IEntity[]): ILabel[] =>
  (data?.slice() ?? [])
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((t) => ({ ...t, type }));

export { LabelMultiSelect };
