import {
  ISocialComment,
  SEARCH_RESULT_TYPE
} from "@aptedge/lib-ui/src/types/entities";
import { useQuery, UseQueryResult } from "react-query";
import { WebCacheKey } from "../../clients/cache";
import { fetchSocialById } from "../../clients/SocialSource/fetchSocialById";

function useSocialQuery(
  ticketId?: string,
  sourceType?: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callbackFn?: (result: any, type: string | undefined) => any
): UseQueryResult<ISocialComment> {
  return useQuery(
    [WebCacheKey.SOCIAL_THREAD, ticketId],
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    () => fetchSocialById(ticketId!),
    {
      enabled: !!ticketId && sourceType === SEARCH_RESULT_TYPE.SOCIAL,
      select: (data) => {
        if (typeof callbackFn === "function" && sourceType) {
          return callbackFn(data, sourceType);
        } else {
          return data;
        }
      }
    }
  );
}

export { useSocialQuery };
