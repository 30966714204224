import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { IOneloginSource } from "@aptedge/lib-ui/src/types/entities";
import { useFormik } from "formik";
import React from "react";
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label
} from "reactstrap";
import * as Yup from "yup";
import "./OneloginConfigForm.scss";

export interface OneloginFormData {
  url: string;
  issuerUrl: string;
  certificate: string;
}

interface Props {
  dataSource: IOneloginSource | null;
  onSubmit: (formData: OneloginFormData) => void;
}

const OneloginConfigForm: React.FunctionComponent<Props> = ({
  dataSource,
  onSubmit
}) => {
  const formik = useFormik<OneloginFormData>({
    validationSchema: Yup.object({
      url: Yup.string().required("Please enter a base URL."),
      issuerUrl: Yup.string().required("Please enter an issuer URL."),
      certificate: Yup.string().required("Please enter an x509 certificate.")
    }),
    initialValues: {
      url: dataSource?.url ?? "",
      issuerUrl: dataSource?.issuerUrl ?? "",
      certificate: dataSource?.certificate ?? ""
    },
    validateOnChange: false,
    onSubmit: onSubmit
  });

  return (
    <Form className="onelogin-config-form" onSubmit={formik.handleSubmit}>
      <FormGroup row>
        <Label sm={3} for="url">
          OneLogin SAML Endpoint
        </Label>
        <Col sm={9}>
          <Input
            data-testid="onelogin-url"
            type="text"
            name="url"
            placeholder=""
            value={formik.values.url}
            onChange={formik.handleChange}
            invalid={!!formik.errors.url}
          />
          <FormFeedback>{formik.errors.url}</FormFeedback>
          <FormText>SAML 2.0 Endpoint (HTTP)</FormText>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3} for="issuerUrl">
          Issuer URL
        </Label>
        <Col sm={9}>
          <Input
            data-testid="onelogin-issuer-url"
            type="text"
            name="issuerUrl"
            placeholder=""
            value={formik.values.issuerUrl}
            onChange={formik.handleChange}
            invalid={!!formik.errors.issuerUrl}
          />
          <FormFeedback>{formik.errors.issuerUrl}</FormFeedback>
          <FormText>OneLogin Issuer URL</FormText>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3} for="certificate">
          OneLogin Certificate
        </Label>
        <Col sm={9}>
          <Input
            data-testid="onelogin-certificate"
            type="textarea"
            name="certificate"
            placeholder=""
            value={formik.values.certificate}
            onChange={formik.handleChange}
            invalid={!!formik.errors.certificate}
          />
          <FormFeedback>{formik.errors.certificate}</FormFeedback>
          <FormText>X. 509 Certificate for OneLogin</FormText>
        </Col>
      </FormGroup>
      <div className="float-right">
        <Button type="submit">Authorize</Button>
      </div>
    </Form>
  );
};

export { OneloginConfigForm };
