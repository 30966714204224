import { FilterBar } from "@aptedge/lib-ui/src/components/Filter/FilterBar";
import { ITimeDimensionFilter } from "@aptedge/lib-ui/src/components/Filter/types";
import {
  ModalRoute,
  useModalRouter
} from "@aptedge/lib-ui/src/components/Modal/ModalRouter";
import { IQueryArgs } from "@aptedge/lib-ui/src/utils/query";
import classNames from "classnames";
import { DateTime } from "luxon";
import React, { useContext, useState } from "react";
import { CustomerFilter } from "../../../components/Filters/CustomerFilter";
import {
  ASSIGNEE_FILTER,
  CREATED_ON_FILTER,
  CUSTOMER_FILTER,
  DESCRIPTION_FILTER,
  EDGE_FILTER,
  FEATURE_FILTER,
  JIRA_FILTER,
  PRIORITY_FILTER,
  PRODUCT_FILTER,
  STATUS_FILTER,
  SUBJECT_FILTER,
  EXTERNAL_TICKET_ID_FILTER,
  TEXT_FILTER,
  THEME_FILTER,
  UPDATED_ON_FILTER
} from "../../../components/Filters/data";
import { DateRangeFilter } from "../../../components/Filters/DateRangeFilter";
import { EdgeFilter } from "../../../components/Filters/EdgeFilter";
import { FeatureFilter } from "../../../components/Filters/FeatureFilter";
import { Filters } from "../../../components/Filters/Filters";
import { FilterSelect } from "../../../components/Filters/FilterSelect";
import { JiraFilter } from "../../../components/Filters/JiraFilter";
import { PriorityFilter } from "../../../components/Filters/PriorityFilter";
import { ProductFilter } from "../../../components/Filters/ProductFIlter";
import {
  DescriptionTextFilter,
  ExternalTicketIdFilter,
  SubjectTextFilter,
  TextFilter
} from "../../../components/Filters/TextFilter";
import { ThemeFilter } from "../../../components/Filters/ThemeFilter";
import { TicketStatusFilter } from "../../../components/Filters/TicketStatusFilter";
import { TicketAssigneeFilter } from "../../../components/Filters/TicketUserFilter";
import { TimeDimensions } from "../../../components/Filters/TimeDimensions";
import { AutoLinkContext } from "../../../context/AutoLinkContext";
import { IPaginatedRequest } from "../../../types/clients";
import { DEFAULT_CREATED } from "../../TicketListingPage/useTicketListingQuery";
import { AutoBreadcrumbs } from "../AutoLink/AutoBreadcrumbs";
import { AutoLinkButton } from "../AutoLink/AutoLinkButton";
import { AutoLinkConditions } from "../AutoLink/AutoLinkConditions";
import { AutoLinkPreview } from "../AutoLink/AutoLinkPreview";
import { Page } from "../AutoLink/AutoLinkRoutes";
import { AutoReplyPage } from "../AutoLink/AutoReplyPage";

import "./EdgeTicketsFilterBar.scss";

const FILTERS = [
  SUBJECT_FILTER,
  DESCRIPTION_FILTER,
  EXTERNAL_TICKET_ID_FILTER,
  STATUS_FILTER,
  PRIORITY_FILTER,
  ASSIGNEE_FILTER,
  CUSTOMER_FILTER,
  PRODUCT_FILTER,
  FEATURE_FILTER,
  THEME_FILTER,
  JIRA_FILTER,
  EDGE_FILTER
];

const filtersMap = {
  subject: SubjectTextFilter,
  description: DescriptionTextFilter,
  externalTicket: ExternalTicketIdFilter,
  products: ProductFilter,
  features: FeatureFilter,
  themes: ThemeFilter,
  customers: CustomerFilter,
  jiras: JiraFilter,
  priority: PriorityFilter,
  status: TicketStatusFilter,
  assignee: TicketAssigneeFilter,
  edges: EdgeFilter
};

const dimensionsMap = {
  createdOn: DateRangeFilter,
  updatedOn: DateRangeFilter
};

interface Props {
  edgeId: number;
  params: IQueryArgs;
  onChange: (updates: Partial<IPaginatedRequest<IQueryArgs>>) => void;
}

function EdgeTicketsFilterBar(props: Props): React.ReactElement {
  const { params, onChange, edgeId } = props;
  const [selectedTimeDimension, setSelectedTimeDimension] = useState<
    ITimeDimensionFilter | undefined
  >();
  const router = useModalRouter();

  const { setIsEditing, isEditing } = useContext(AutoLinkContext);

  const dateRangeMin = DateTime.local().endOf("day").minus({ months: 3 });

  const handleAutoCancel = (): void => {
    onChange({
      filters: []
    });
    setIsEditing(false);
    router.push(Page.START);
  };

  return (
    <div className="filter-bar-container">
      <AutoBreadcrumbs onCancel={() => handleAutoCancel()} />
      <FilterBar className={classNames("edge-tickets", { isEditing })}>
        <ModalRoute route={Page.START}>
          <div className="header">
            <Filters
              spec={[TEXT_FILTER]}
              filters={params.filters}
              filtersMap={{ text: TextFilter }}
              pinned={[TEXT_FILTER.id]}
              onChange={(filters) => onChange({ filters })}
            />
            <FilterSelect specs={FILTERS} query={params} onChange={onChange} />
            <AutoLinkButton onChange={onChange} />
          </div>
          <div className="filter-row">
            <TimeDimensions
              specs={[CREATED_ON_FILTER, UPDATED_ON_FILTER]}
              dimensions={params.timeDimensions}
              pinned={[CREATED_ON_FILTER.id, UPDATED_ON_FILTER.id]}
              dateRangeMin={dateRangeMin}
              dimensionsMap={dimensionsMap}
              selectedTimeDimension={selectedTimeDimension}
              onChange={(timeDimension) => {
                setSelectedTimeDimension(timeDimension);
                if (!timeDimension.id) {
                  onChange({
                    timeDimensions: [
                      { id: "createdOn", values: [DEFAULT_CREATED] }
                    ]
                  });
                } else {
                  onChange({ timeDimensions: [timeDimension] });
                }
              }}
            />
            <Filters
              spec={FILTERS}
              filters={params.filters}
              filtersMap={filtersMap}
              onChange={(filters) => onChange({ filters })}
            />
          </div>
        </ModalRoute>
        <ModalRoute route={Page.CONDITIONS}>
          <AutoLinkConditions
            params={params}
            onChange={onChange}
            onCancel={handleAutoCancel}
            selectedTimeDimension={selectedTimeDimension}
          />
        </ModalRoute>
        <ModalRoute route={Page.AUTO_REPLY}>
          <AutoReplyPage onCancel={handleAutoCancel} />
        </ModalRoute>
        <ModalRoute route={Page.PREVIEW}>
          <AutoLinkPreview
            params={params}
            edgeId={edgeId}
            onCancel={handleAutoCancel}
          />
        </ModalRoute>
      </FilterBar>
    </div>
  );
}
export { EdgeTicketsFilterBar };
