import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import React from "react";
import WithLoading from "../../../components/WithLoading/WithLoading";
import { useSlackSocialDataSource } from "../../../hooks/clients/useDataSource";
import { MSTeamConfig } from "./MSTeams/MSTeamsConfig";
import { SlackConfig } from "./SlackConfig";
import "./SocialSourceConfig.scss";

const SocialSourceConfig: React.FunctionComponent = () => {
  const slackSocialSource = useSlackSocialDataSource();
  const { flags } = useFlags();
  const slackSocialSourceData = slackSocialSource?.dataSource ?? null;
  const isLoading = slackSocialSource.isLoading;
  const slackError = slackSocialSource?.error;
  const refetchSlackTap = slackSocialSource.reload;
  const onChange = (): void => {
    slackSocialSource.reload();
  };

  return (
    <>
      <section className="social-section">
        <div className="left">
          <h4 className="text-uppercase">Social Settings</h4>
          <p>Connect AptEdge to your collaboration system.</p>
        </div>
        <div className="right">
          {flags.slackConfig && (
            <WithLoading isLoading={isLoading} fallback={<Spinner />}>
              <SlackConfig
                refetchSlackTap={refetchSlackTap}
                slackResource={slackSocialSourceData}
                onChange={onChange}
                isLoading={isLoading}
                error={slackError}
              />
            </WithLoading>
          )}
          <div className="divider" />
          {flags.msTeams && (
            <WithLoading isLoading={isLoading} fallback={<Spinner />}>
              <MSTeamConfig />
            </WithLoading>
          )}
        </div>
      </section>
      <hr className="mt-4" />
    </>
  );
};

export { SocialSourceConfig };
