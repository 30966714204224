import isEqual from "lodash/isEqual";
import React from "react";
import useResizeObserverLib from "use-resize-observer/polyfilled";

type ObservedSize = {
  width: number | undefined;
  height: number | undefined;
};

function useResizeObserver<T extends HTMLElement>(opts: {
  ref: React.RefObject<T>;
}): ObservedSize {
  const isMounted = React.useRef(false);
  const [size, setSize] = React.useState<ObservedSize>({
    width: undefined,
    height: undefined
  });

  // Wrap setSize in requestAnimationFrame to prevent "ResizeObserver loop limit exceeded"
  // see: https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
  useResizeObserverLib({
    ...opts,
    onResize: (newSize) =>
      requestAnimationFrame(() => {
        if (isMounted.current && !isEqual(newSize, size)) {
          setSize(newSize);
        }
      })
  });

  React.useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return size;
}

export { useResizeObserver };
