import { MyProductsResponse } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";

function fetchMyProducts(): Promise<MyProductsResponse> {
  const url = `${getBackendApiUrl()}/apt-answers/save-filters`;
  return request(url);
}

export { fetchMyProducts };
