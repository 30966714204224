import { INotificationConfig } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";

function fetchNotificationConfig(): Promise<INotificationConfig> {
  const url = `${getBackendApiUrl()}/users/notification-config/`;

  return request(url);
}

export { fetchNotificationConfig };
