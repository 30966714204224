import { ActionBar } from "@aptedge/lib-ui/src/components/ActionBar/ActionBar";
import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { Modal, ModalWidth } from "@aptedge/lib-ui/src/components/Modal/Modal";
import { MultiSelect } from "@aptedge/lib-ui/src/components/MultiSelect/MultiSelect";
import { Spacer } from "@aptedge/lib-ui/src/components/Spacer/Spacer";
import {
  IEntity,
  ITicketInfo,
  ITicketListing
} from "@aptedge/lib-ui/src/types/entities";
import React, { useEffect, useMemo, useState } from "react";
import { ToastType } from "react-toastify";
import { useThemes } from "../../hooks/clients/useThemes";
import { useTicketMutation } from "../../hooks/clients/useTicket";
import { Toast } from "../Toast/Toast";
import "./TicketThemesModal.scss";

interface Props {
  ticket: ITicketListing | ITicketInfo;
  onTicketUpdate: (ticket: ITicketInfo) => void;
  onClose: () => void;
  isOpen: boolean;
}

function TicketThemesModal(props: Props): React.ReactElement {
  const { ticket, onTicketUpdate, onClose, isOpen } = props;

  const themesQuery = useThemes();
  const allThemes = themesQuery.data ?? [];

  const ticketMutation = useTicketMutation({
    onSuccess: (ticket) => {
      onTicketUpdate(ticket);
      onClose();
    }
  });

  const options = useMemo(
    () => allThemes.map((t) => ({ id: t.id, name: t.name })),
    [allThemes]
  );
  const [themes, setThemes] = useState<IEntity[]>([]);
  const themeIds = themes.map((t) => t.id);
  const submitDisabled =
    ticketMutation.isLoading ||
    !themes.length ||
    (ticket.themes.every((t) => themeIds.includes(t.id)) &&
      ticket.themes.length === themeIds.length);

  const submitHandler = (): void => {
    ticketMutation.mutate({
      ticketId: ticket.id,
      patchContent: {
        themes: themes.map((t) => ({ id: t.id }))
      }
    });
  };

  useEffect(() => {
    setThemes(ticket.themes);
  }, [onClose, ticket.themes]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title="Edit Themes"
        width={ModalWidth.MEDIUM}
      >
        <div className="ticket-themes-modal">
          <div className="info">ID: {ticket.displayId}</div>
          <div className="info">Subject: {ticket.subject}</div>
          <Spacer size="large">
            <MultiSelect
              value={themes}
              data={options}
              name="associatedThemes"
              label="Associated Themes"
              placeholder="Search for themes to add"
              getOptionLabel={(theme) => theme.name}
              onChange={(selected) => setThemes(selected)}
              className="themes mt-2"
            />
            <ActionBar.Container className="actions">
              <ActionBar.Right>
                <Button type="button" color="secondary" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  onClick={submitHandler}
                  disabled={submitDisabled}
                >
                  Save
                </Button>
              </ActionBar.Right>
            </ActionBar.Container>
          </Spacer>
        </div>
      </Modal>
      {ticketMutation.error && (
        <Toast type={ToastType.ERROR}>
          Changes could not be saved. {ticketMutation.error.message}
        </Toast>
      )}
    </>
  );
}

export { TicketThemesModal };
