import {
  ISlackSocialChannelInfoRequest,
  ISlackChannel
} from "@aptedge/lib-ui/src/types/entities";
import { getBackendUrl } from "../../config";
import { request } from "../../utils/request";
import { post } from "../utils/buildRequest";

async function getSlackChannelInfo(
  args: ISlackSocialChannelInfoRequest
): Promise<ISlackChannel[]> {
  const url = `${getBackendUrl()}/slack/channel-info/`;
  const options: RequestInit = post(args);
  return request(url, options);
}

export { getSlackChannelInfo };
