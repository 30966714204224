import classNames from "classnames";
import React, { FC, ReactNode } from "react";
import { NavLink } from "react-router-dom";
import { NavLinkProps } from "reactstrap";

interface INavItem extends NavLinkProps {
  className: string;
  to: string;
  children: ReactNode;
  id?: string;
  style?: React.CSSProperties;
}
const NavItem: FC<INavItem> = ({
  className,
  to,
  children,
  id,
  style,
  ...rest
}) => {
  return (
    <NavLink
      className={classNames("nav-item", className)}
      to={to}
      id={id ?? "navItem"}
      style={style}
      {...rest}
    >
      {children}
    </NavLink>
  );
};

export default NavItem;
