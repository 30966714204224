import { Modal, ModalWidth } from "@aptedge/lib-ui/src/components/Modal/Modal";
import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import classNames from "classnames";
import React, { ComponentProps } from "react";
import { ClientStatus } from "../../types/clients";
import WithLoading from "../WithLoading/WithLoading";
import "./ConfirmationModal.scss";

interface Props extends Omit<ComponentProps<typeof Modal>, "showClose"> {
  title: string;
  className?: string;
  status: ClientStatus;
  loadingTitle: string;
}

const ConfirmationModal: React.FunctionComponent<Props> = ({
  status,
  children,
  loadingTitle,
  className,
  onClose,
  width = ModalWidth.SMALL,
  title,
  ...rest
}) => {
  const showContent = [
    ClientStatus.PROMPT,
    ClientStatus.ERROR,
    ClientStatus.SUCCESS
  ].includes(status);

  // Set the title to the loading title whenever the client has started.
  const displayedTitle = showContent ? title : loadingTitle;

  // Block while client is running.
  const onCloseOrNoop = showContent ? onClose : undefined;

  return (
    <Modal
      className={classNames("confirmation-modal", className)}
      title={displayedTitle}
      width={width}
      showClose={showContent}
      escClose={showContent}
      onClose={onCloseOrNoop}
      {...rest}
    >
      <WithLoading
        isLoading={status === ClientStatus.LOADING}
        fallback={
          <div className="d-flex justify-content-center m-4">
            <Spinner />
          </div>
        }
      >
        {showContent && children}
      </WithLoading>
    </Modal>
  );
};

export { ConfirmationModal };
