import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import {
  NoData,
  NoDataMessage
} from "@aptedge/lib-ui/src/components/NoData/NoData";
import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import { TextInput } from "@aptedge/lib-ui/src/components/TextInput/TextInput";
import { useDebounce } from "@aptedge/lib-ui/src/hooks/useDebounce";
import { useEventListener } from "@aptedge/lib-ui/src/hooks/useEventListener";
import { ILabel } from "@aptedge/lib-ui/src/types/entities";
import { eventWasTriggeredOutsideRef } from "@aptedge/lib-ui/src/utils/domUtils";
import { GTM_EVENTS, dataLayerPush } from "@aptedge/lib-ui/src/utils/event";
import React, { useRef, useState } from "react";
import { useQuery } from "react-query";
import { WebCacheKey } from "../../clients/cache";
import { searchLabels } from "../../clients/Labels/searchLabels";
import { useRecentLabel } from "../../hooks/useRecentLabel";
import WithLoading from "../WithLoading/WithLoading";
import { LabelsList } from "./LabelsList";

interface Props {
  labels: ILabel[];
  searchFilter: (item: ILabel) => boolean;
  onSelect: (selected: ILabel) => void;
}

const LabelSearch: React.FC<Props> = ({ onSelect, labels, searchFilter }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [searchTerm, setSearchTerm] = useState("");
  const { debouncedValue: debouncedSearchTerm } = useDebounce(searchTerm, 300);

  const searchQuery = useQuery(
    [WebCacheKey.LABELS_SEARCH, debouncedSearchTerm],
    () => searchLabels(debouncedSearchTerm),
    {
      enabled: !!debouncedSearchTerm,
      onSuccess: (res) => setResults(res.items)
    }
  );
  const loading = searchQuery.isLoading;

  const [results, setResults] = useState<ILabel[]>([]);
  const [recentLabels, addRecentLabels] = useRecentLabel();
  const [inputOpen, setInputOpen] = useState<boolean>(false);
  const searchRef = useRef<HTMLDivElement>(null);

  const filteredRecentLabel = recentLabels
    ? recentLabels.filter(
        (r) => !labels.some((l) => labelsAreEqual(l, r)) && searchFilter(r)
      )
    : [];

  const filteredResults = !!results
    ? results.filter(
        (r) => !labels.some((l) => labelsAreEqual(l, r)) && searchFilter(r)
      )
    : [];

  useEventListener("click", (e) => {
    if (inputOpen && eventWasTriggeredOutsideRef(e, searchRef)) {
      setInputOpen(false);
    }
  });

  const selectHandler = (selected: ILabel): void => {
    onSelect(selected);
    addRecentLabels([
      {
        id: selected.id,
        name: selected.name,
        parentName: selected.parentName,
        type: selected.type
      }
    ]);
    setInputOpen(false);
  };

  const addHandler = (): void => {
    setInputOpen(true);
    setSearchTerm("");
    dataLayerPush({
      event: GTM_EVENTS.GTM_CREATE_EDGE_LABEL
    });
  };

  return (
    <>
      {inputOpen ? (
        <div className="label-search" ref={searchRef}>
          <TextInput
            ref={inputRef}
            placeholder="Search for a label"
            onChange={(e) => setSearchTerm(e.currentTarget.value)}
            value={searchTerm}
            autoFocus
          />
          <div className="search-results-container">
            <WithLoading isLoading={loading} fallback={<Spinner />}>
              {searchTerm && !!searchQuery.data ? (
                <>
                  <LabelsList
                    containerRef={searchRef}
                    className="results"
                    title={<h5>Results</h5>}
                    items={filteredResults}
                    onSelect={selectHandler}
                  />
                  {!filteredResults.length && (
                    <NoData message={NoDataMessage.SEARCH_LABELS} />
                  )}
                </>
              ) : (
                <>
                  <LabelsList
                    containerRef={searchRef}
                    className="recents"
                    title={<h5>Recent</h5>}
                    items={filteredRecentLabel}
                    onSelect={selectHandler}
                  />
                  {!filteredRecentLabel.length && (
                    <NoData message={NoDataMessage.RECENT_LABELS} />
                  )}
                </>
              )}
            </WithLoading>
          </div>
        </div>
      ) : (
        <Button
          color="secondary"
          className="label-add"
          onClick={addHandler}
          data-testid="label-add"
        >
          <Icons.Plus />
          <span>Add</span>
        </Button>
      )}
    </>
  );
};

function labelsAreEqual(a: ILabel, b: ILabel): boolean {
  return a.id === b.id && a.type === b.type;
}

export { LabelSearch };
