import {
  TextArea,
  TextAreaProps
} from "@aptedge/lib-ui/src/components/TextArea/TextArea";
import classNames from "classnames";
import React from "react";

import "./ReplyTextArea.scss";

function getPlaceholder(isInternal: boolean): string {
  return isInternal ? "Add internal reply..." : "Add reply...";
}

const ROWS = 8;

type Props = Pick<
  TextAreaProps,
  "value" | "className" | "onChange" | "disabled"
> & { internal?: boolean } & { isDisabled?: boolean };

const ReplyTextArea: React.FC<Props> = ({
  value,
  className,
  internal,
  isDisabled,
  onChange,
  disabled
}) => {
  return (
    <TextArea
      disabled={disabled}
      rows={ROWS}
      value={value}
      placeholder={
        internal !== undefined ? getPlaceholder(internal) : undefined
      }
      className={classNames({ internal }, { isDisabled }, className, "reply")}
      onChange={onChange}
    />
  );
};

export { ReplyTextArea };
