import { ApiError } from "@aptedge/lib-ui/src/utils/ApiError";
import { logger } from "@aptedge/lib-ui/src/utils/logger";
import {
  decorateHeadersWithAuth,
  makeTimedResponse,
  TimedResponse
} from "@aptedge/lib-ui/src/utils/request";
import { MULTIPLE_CHOICES, OK, UNAUTHORIZED } from "http-status-codes";
import { toQueryString } from "../clients/utils/url";
import { history } from "../history";
import { Routes } from "../routes/routes";
/* eslint @typescript-eslint/no-explicit-any: 0 */

interface TypedResponse<T = any> extends Response {
  json<P = T>(): Promise<P>;
}

/**
 * Parses the JSON returned by a network request
 */
function parseJSON<T>(response: TypedResponse<T>): Promise<T> {
  return response.json();
}

/**
 * Checks status of a network response, and throws an error if something went wrong
 */
function checkStatus(url: string) {
  return async function handleErrors(response: Response): Promise<Response> {
    if (response.status === UNAUTHORIZED) {
      const location = history.location;
      // Only redirect and dispatch redirect action once.
      if (
        ![Routes.LOGIN, Routes.LOGOUT].includes(location.pathname as Routes)
      ) {
        const redirectTo = location.pathname + location.search;
        history.push({
          pathname: Routes.LOGOUT,
          search: toQueryString({ redirectTo })
        });
      }
    }

    if (response.status >= OK && response.status < MULTIPLE_CHOICES) {
      return response;
    }

    let message: string | undefined;

    try {
      const errorResponse = (await response.json()) as { error: string };
      message = errorResponse.error;
    } catch (e) {
      logger.warn("Failed to parse error message", e);
    }

    throw new ApiError(response.status, url, message);
  };
}

/**
 * Requests a URL, and returns a promise
 * @param url the URL to request
 * @param options options to pass to fetch
 *
 * @returns response data
 */
async function request<T = any>(
  url: string,
  options: RequestInit = {}
): Promise<T> {
  return fetch(url, decorateHeadersWithAuth(options))
    .then(checkStatus(url))
    .then<T>(parseJSON);
}

async function timedRequest<T = any>(
  url: string,
  options: RequestInit = {}
): Promise<TimedResponse<T>> {
  const startTime = Date.now();
  return request<T>(url, options).then<TimedResponse<T>>((data) =>
    makeTimedResponse(data, startTime)
  );
}
async function rawRequest(
  url: string,
  options: RequestInit = {}
): Promise<any> {
  return fetch(url, decorateHeadersWithAuth(options));
}
async function deleteRequest(url: string, options: RequestInit): Promise<void> {
  return fetch(url, decorateHeadersWithAuth(options))
    .then(checkStatus(url))
    .then((res) => Promise.resolve()); // Just return an empty Promise when request is successful.
}

export { request, deleteRequest, rawRequest, timedRequest };
