import { GeneratedKnowledgeResult } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";
import { deleteInit } from "../utils/buildRequest";

interface DeleteArticleArgs {
  articleId: string;
}

function deleteArticle({
  articleId
}: DeleteArticleArgs): Promise<GeneratedKnowledgeResult> {
  const url = `${getBackendApiUrl()}/generated_knowledge/${articleId}`;
  const options: RequestInit = deleteInit();
  return request(url, options);
}

export { deleteArticle };
