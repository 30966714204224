import { ILabelSetting, ITheme } from "@aptedge/lib-ui/src/types/entities";
import React from "react";
import { LabelsList } from "./LabelsList";

interface Props {
  labels: ILabelSetting[];
  commonThemes: ITheme[];
  onChange: () => void;
}

const LabelSettings: React.FC<Props> = (props) => {
  const { labels, commonThemes, onChange } = props;

  const sortedLabels = [...labels].sort((a, b) => {
    if (a.type === b.type) return a.name.localeCompare(b.name);

    return a.type.localeCompare(b.type);
  });

  return (
    <section>
      <div className="row">
        <div className="col-md-5 p-2 pr-3">
          <h4 className="text-uppercase">Label Configuration</h4>
          <p>
            Configure a set of labels to represent your company&apos;s products,
            features, campaigns, teams, etc.
          </p>
        </div>
        <div className="col-md-7 p-2 pl-3">
          <LabelsList labels={sortedLabels} onChange={onChange} />

          <div className="mt-5 mb-3">
            <h5>Common Labels</h5>
            <p>
              AptEdge includes these common labels by default. They cannot be
              edited.
            </p>
            <div>
              {commonThemes.map((t) => (
                <div
                  key={t.id}
                  className="p-2 ml-1 mr-2 mb-1 badge badge-light"
                >
                  {t.name}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <hr className="mt-4" />
    </section>
  );
};

export { LabelSettings };
