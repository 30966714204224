import React, {
  createContext,
  Dispatch,
  ReducerAction,
  ReducerState,
  useContext,
  useReducer
} from "react";
import { noop } from "../../utils/noop";
import { devToolsFlagsReducer } from "./flagsReducer";

type IFlagOverridesContext = [
  ReducerState<typeof devToolsFlagsReducer>,
  Dispatch<ReducerAction<typeof devToolsFlagsReducer>>
];

const FlagOverridesContext = createContext<IFlagOverridesContext>([{}, noop]);

const FlagOverridesProvider: React.FC = ({ children }) => {
  const value = useReducer(devToolsFlagsReducer, {});

  return (
    <FlagOverridesContext.Provider value={value}>
      {children}
    </FlagOverridesContext.Provider>
  );
};

const useFlagOverrides = (): IFlagOverridesContext =>
  useContext(FlagOverridesContext);

export { FlagOverridesProvider, useFlagOverrides };
