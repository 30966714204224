import { IRichTextContent } from "@aptedge/lib-ui/src/components/TextEditor/TextEditor";
import { IEdgeComment } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";
import { patch } from "../utils/buildRequest";

async function updateEdgeComment({
  edgeId,
  commentId,
  richText
}: {
  edgeId: number;
  commentId: number;
  richText: IRichTextContent;
}): Promise<IEdgeComment> {
  const url = `${getBackendApiUrl()}/edges/${edgeId}/comments/${commentId}/`;
  const options = patch({ richText });

  return request(url, options);
}

export { updateEdgeComment };
