import React from "react";

function useIsMounted(): React.MutableRefObject<boolean> {
  const isMounted = React.useRef(true);

  React.useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return isMounted;
}

export { useIsMounted };
