import { getBackendApiUrl } from "../../config";
import { deleteRequest } from "../../utils/request";
import { deleteInit } from "../utils/buildRequest";

async function deleteCustomFieldMapping({ id }: { id: number }): Promise<void> {
  const url = `${getBackendApiUrl()}/ticket-sources/fields/${id}/`;
  return deleteRequest(url, deleteInit());
}

export { deleteCustomFieldMapping };
