import { AeIcons } from "@aptedge/lib-ui/src/components/Icon/AeIcons";
import * as React from "react";
import styles from "./EmptyState.module.scss";

const EmptyState: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.iconContainer}>
        <AeIcons.GeneratedKnowledgeGrayLarge />
      </div>
      <h3 className={styles.headline}>Create your first article</h3>
      <p className={styles.subheading}>
        You can create articles directly from your support app tickets once
        they’re resolved.
      </p>
    </div>
  );
};

export { EmptyState };
