import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { Dropdown } from "@aptedge/lib-ui/src/components/Dropdown/Dropdown";
import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import { useOnMouseDownOutside } from "@aptedge/lib-ui/src/hooks/useOnMouseDownOutside";
import {
  ILabelSetting,
  LabelEntity,
  LabelEntityType
} from "@aptedge/lib-ui/src/types/entities";
import classNames from "classnames";
import React, { createRef, useState } from "react";
import { LabelCreateItem } from "./LabelCreateItem";
import "./LabelsList.scss";
import { LabelsListItem } from "./LabelsListItem";

interface Props {
  labels: ILabelSetting[];
  onChange: () => void;
}

const LabelsList: React.FC<Props> = (props) => {
  const { labels, onChange } = props;

  const wrapperRef = createRef<HTMLUListElement>();

  const [showAddFeature, setShowAddFeature] = useState(false);
  const [newLabelType, setNewLabelType] = useState<LabelEntityType | null>(
    null
  );

  const productCount = labels.reduce((memo, label) => {
    if (label.type === LabelEntity.PRODUCT) {
      memo += 1;
    }
    return memo;
  }, 0);

  useOnMouseDownOutside(wrapperRef, () => {
    setShowAddFeature(false);
    setNewLabelType(null);
  });

  const handleCancelAdd = (): void => {
    setNewLabelType(null);
  };

  const handleSave = (): void => {
    setNewLabelType(null);
    onChange();
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-start">
        <div>
          <h5>Labels</h5>
          <p>Add labels that AptEdge should use when classifying tickets.</p>
        </div>
        <Dropdown.Container>
          <Dropdown.Toggle>
            <Button color="secondary">
              <Icons.Plus />
              <span>Add</span>
            </Button>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => setNewLabelType(LabelEntity.PRODUCT)}>
              New Product
            </Dropdown.Item>
            <Dropdown.Item
              className={classNames({ "text-muted": !productCount })}
              onClick={() => setShowAddFeature(true)}
            >
              New Feature
            </Dropdown.Item>
            <Dropdown.Item onClick={() => setNewLabelType(LabelEntity.THEME)}>
              New Theme
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown.Container>
      </div>
      <ul
        ref={wrapperRef}
        className={classNames("labels-list list-group", {
          "add-feature": showAddFeature
        })}
      >
        {!!newLabelType && (
          <LabelCreateItem
            className="mt-2 mb-2"
            parent={null}
            heading={`New ${newLabelType} label`}
            type={newLabelType}
            onCancel={handleCancelAdd}
            onSave={handleSave}
          />
        )}
        {labels.map((item, key) => (
          <React.Fragment key={`setting-item-${item.name}-${key}`}>
            <LabelsListItem
              item={item}
              onDelete={() => onChange()}
              onShowAdd={() => setShowAddFeature(false)}
              onCreate={() => onChange()}
            />
            {item.children &&
              item.children.map((child, key) => (
                <LabelsListItem
                  key={`${item.name}-child-${child.name}-${key}`}
                  className="pl-5"
                  item={child}
                  onDelete={() => onChange()}
                />
              ))}
          </React.Fragment>
        ))}
      </ul>
    </>
  );
};

export { LabelsList };
