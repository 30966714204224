import {
  IMSTeamsSource,
  IMSTeamsGroup,
  IMSTeamsFlags
} from "@aptedge/lib-ui/src/types/entities";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

type SliceStateType = {
  dataSource: IMSTeamsSource | null;
  msTeamsGroups: IMSTeamsGroup[];
  isAdmin: boolean;
  mutationTriggered: boolean;
  selectionUpdated: boolean;
  checkSelection: boolean;
  groupDeleted: boolean;
  channelsLoaded: boolean;
  onReload: boolean;
  tenantId: string;
};

const initialState: SliceStateType = {
  dataSource: null,
  msTeamsGroups: [],
  isAdmin: false,
  mutationTriggered: false,
  selectionUpdated: true,
  checkSelection: true,
  groupDeleted: true,
  channelsLoaded: false,
  onReload: false,
  tenantId: ""
};

const msTeamsSlice = createSlice({
  name: "msTeams",
  initialState,
  reducers: {
    setMSTeamsTapData: (
      state,
      action: PayloadAction<IMSTeamsSource | null>
    ) => {
      state.dataSource = action.payload;
    },
    updateIsAdmin: (state, action) => {
      state.isAdmin = action.payload;
    },
    addMSTeamsGroups: (state, action) => {
      state.msTeamsGroups.push(action.payload);
    },
    updateMSTeamsGroups: (state, action) => {
      state.msTeamsGroups = action.payload;
    },
    removeMSTeamsGroup: (state, action) => {
      state.msTeamsGroups = action.payload;
    },
    updateMSTeamsFlags: (state, action) => {
      switch (action.payload.mode) {
        case IMSTeamsFlags.MUTATE:
          state.mutationTriggered = action.payload.value;
          break;
        case IMSTeamsFlags.SELECT:
          state.selectionUpdated = action.payload.value;
          break;
        case IMSTeamsFlags.CHECK:
          state.checkSelection = action.payload.value;
          break;
        case IMSTeamsFlags.DELETE:
          state.groupDeleted = action.payload.value;
          break;
        case IMSTeamsFlags.LOAD:
          state.channelsLoaded = action.payload.value;
          break;
        case IMSTeamsFlags.RELOAD:
          state.onReload = action.payload.value;
          break;
      }
    },
    setMSTeamsTenantId: (state, action) => {
      state.tenantId = action.payload;
    }
  }
});

export const {
  setMSTeamsTapData,
  updateIsAdmin,
  addMSTeamsGroups,
  updateMSTeamsGroups,
  removeMSTeamsGroup,
  updateMSTeamsFlags,
  setMSTeamsTenantId
} = msTeamsSlice.actions;

export default msTeamsSlice.reducer;
