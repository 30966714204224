/* eslint-disable @typescript-eslint/no-explicit-any */
import isEqual from "lodash/isEqual";
import { useRef } from "react";

/**
 * A hook that updates a value every render, if it has changed
 * (deep equal comparison).
 *
 */
function useLatest<T extends any>(value: T): T {
  const ref = useRef<T>(value);

  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

export { useLatest };
