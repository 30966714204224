import { Dropdown } from "@aptedge/lib-ui/src/components/Dropdown/Dropdown";
import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import { GTM_EVENTS, dataLayerPush } from "@aptedge/lib-ui/src/utils/event";
import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { Routes } from "../../routes/routes";
import { ReactComponent as UserIcon } from "./user.svg";
import "./UserDropdown.scss";

/**
 * Bootstrap navigation bar
 *
 * Only contains brand widget + mock customer name and a settings drop down for now
 */
const UserDropdown: React.FC = () => {
  const { user } = useAuth();

  const handleEvent = (): void => {
    dataLayerPush({
      event: GTM_EVENTS.GTM_PROFILE
    });
  };

  return (
    <Dropdown.Container className="user" placement="bottom">
      <Dropdown.Toggle>
        <UserIcon
          fontSize={24}
          data-testid="user-icon"
          onClick={() => handleEvent()}
        />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {user && (
          <Dropdown.Item className="current-user">
            <span>Signed in as</span>
            <div className="font-weight-bold">{user.email}</div>
          </Dropdown.Item>
        )}
        {(user?.onboardingComplete || user?.admin) && (
          <>
            <hr className="dropdown-divider" />

            <Link to={Routes.USER_SETTINGS}>
              <Dropdown.Item>
                <div className="d-flex justify-content-between align-items-center">
                  <span>User settings</span>
                  <Icons.ExternalLink />
                </div>
              </Dropdown.Item>
            </Link>
          </>
        )}
        <hr className="mt-2 mb-2" />
        <Link to={Routes.LOGOUT}>
          <Dropdown.Item className="mb-2">Logout</Dropdown.Item>
        </Link>
      </Dropdown.Menu>
    </Dropdown.Container>
  );
};

export default UserDropdown;
