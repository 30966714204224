import {
  ICustomerListItem,
  IOriginalCustomerListItem
} from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import {
  IFetchCustomersArgs,
  IPaginatedRequest,
  IPaginatedResponse
} from "../../types/clients";
import { request } from "../../utils/request";
import { toQueryString } from "../utils/url";

async function fetchCustomersPaginated(
  args: IPaginatedRequest<IFetchCustomersArgs>
): Promise<IPaginatedResponse<ICustomerListItem[]>> {
  const query = toQueryString(args);
  const url = `${getBackendApiUrl()}/customers/?${query}`;

  return request<IPaginatedResponse<IOriginalCustomerListItem[]>>(url).then(
    (result) => {
      const { items, ...rest } = result;

      const newItems = items.map(
        (c): ICustomerListItem => ({
          id: c.id,
          name: c.name,
          pinned: c.pinned,
          monetaryValue: c.value.amount,
          ticketCount: c.ticketCount
        })
      );

      return { ...rest, items: newItems };
    }
  );
}

export { fetchCustomersPaginated };
