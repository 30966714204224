import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import {
  ArticleStatus,
  GeneratedKnowledgeTicketId
} from "@aptedge/lib-ui/src/types/entities";
import * as React from "react";
import { useQuery } from "react-query";
import { WebCacheKey } from "../../clients/cache";
import { fetchArticles } from "../../clients/GeneratedKnowledge/fetchArticles";
import { TabItem } from "./TabItem";
import styles from "./Tabs.module.scss";

type TicketId = string | number;

interface TabsProps {
  currentTab: "savedArticles" | "pendingReview";
}

const PER_PAGE = 10;

const Tabs: React.FC<TabsProps> = ({ currentTab }) => {
  const { flags } = useFlags();
  const unpublishedArticlesQuery = useQuery(
    [WebCacheKey.GENERATED_KNOWLEDGE_UNPUBLISHED],
    () =>
      fetchArticles({
        perPage: flags.kbGenPublishProcess ? PER_PAGE : undefined,
        filters: [
          {
            id: "status",
            operator: "equals",
            values: [ArticleStatus.DRAFT]
          }
        ]
      }),
    { enabled: !flags.kbGenPublishProcess }
  );
  const tickets: TicketId[] = [];
  const unpublishedArticles = unpublishedArticlesQuery?.data;
  unpublishedArticles?.items.forEach((article) => {
    const { ticket_id: ticketId } =
      article.ticketIds[0] || ({} as GeneratedKnowledgeTicketId);
    const ticket = tickets.find((ticket) => ticket === ticketId);
    if (!ticket) {
      tickets.push(ticketId);
    }
  });
  const unpublishedTicketsCount = tickets.length;
  return (
    <div className={styles.container} data-testid="tabs-container">
      <TabItem
        to="/articles"
        label="Saved Articles"
        count={null}
        isCurrent={currentTab === "savedArticles"}
      />
      <TabItem
        to="/articles/pending"
        label="Pending Review"
        count={unpublishedTicketsCount}
        isCurrent={currentTab === "pendingReview"}
      />
    </div>
  );
};

export { Tabs };
