import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import React from "react";

import "./ArchivedBadge.scss";

interface Props {
  archived: boolean;
}

const ArchivedBadge: React.FC<Props> = ({ archived }) => {
  if (!archived) {
    return null;
  }

  return (
    <div className="archived-badge">
      <Icons.Archive />
      Archived
    </div>
  );
};

export { ArchivedBadge };
