import CodeMirror, { Editor, Position } from "codemirror";
import "codemirror/lib/codemirror.css";
import React from "react";
import { ImageUploadResult } from "../../types/entities";
import { MarkdownContent } from "../MarkdownContent/MarkdownContent";
import { MarkdownToolbar } from "../MarkdownToolbar/MarkdownToolbar";
import { createCodeMirror, supportedImageTypes } from "./createCodeMirror";
import "./MarkdownEditor.scss";

interface Props {
  content: string;
  autoFocus?: boolean;
  onChange: (markdown: string) => void;
  templateText?: string;
  handleImageUpload?: (f: File) => Promise<ImageUploadResult>;
  isImageDropAllowed: boolean;
}

const HIDDEN_STYLE: React.CSSProperties = {
  visibility: "hidden",
  height: 0
};

const MarkdownEditor: React.FC<Props> = ({
  content,
  autoFocus,
  onChange,
  templateText,
  handleImageUpload,
  isImageDropAllowed
}) => {
  const editorRef = React.createRef<HTMLDivElement>();
  const [codeMirror, setCodeMirror] = React.useState<CodeMirror.Editor>();
  const [showPreview, setShowPreviewState] = React.useState(false);
  // Used only to force children to re-render.
  const [, setSelection] = React.useState<Position>();

  React.useEffect(() => {
    if (editorRef.current && !codeMirror) {
      const cm = createCodeMirror(editorRef.current, {
        autofocus: autoFocus,
        value: content,
        screenReaderLabel: "markdown-editor"
      });
      setCodeMirror(cm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeHandler = React.useCallback(
    (cm: Editor): void => onChange(cm.getValue()),
    [onChange]
  );

  const onDropHandler = React.useCallback(
    async (cm: Editor, e: DragEvent): Promise<void> => {
      if (!isImageDropAllowed || !handleImageUpload) {
        return;
      }

      const droppedFile = e?.dataTransfer?.files?.[0];
      const isImage =
        droppedFile && supportedImageTypes.includes(droppedFile.type);
      if (droppedFile && isImage) {
        e.preventDefault();
        const cursor = cm.getCursor();
        const line = cm.getLine(cursor.line);
        const loadingText = "\n*Loading dropped image...*\n";
        cm.replaceRange(loadingText, {
          line: cursor.line,
          ch: line.length
        });
        const { success, name, url } = await handleImageUpload(droppedFile);
        if (success) {
          const imgMarkdown = `![${name}](${url})`;
          cm.replaceRange(
            imgMarkdown,
            {
              line: cursor.line + 1,
              ch: 0
            },
            {
              line: cursor.line + 1,
              ch: loadingText.length
            }
          );
        } else {
          const errorText = "*Unable to add dropped image.*";
          cm.replaceRange(
            errorText,
            {
              line: cursor.line + 1,
              ch: 0
            },
            {
              line: cursor.line + 1,
              ch: loadingText.length
            }
          );
        }
      }
    },
    [handleImageUpload, isImageDropAllowed]
  );

  const onClickTemplateHandler = (): void => {
    if (!!templateText && !!codeMirror) {
      codeMirror.setValue(templateText);
      codeMirror.focus();
    }
  };

  // Hook up listeners.
  React.useEffect(() => {
    const onChangeSelection = (e: Editor): void => setSelection(e.getCursor());

    codeMirror?.on("change", onChangeHandler);
    codeMirror?.on("drop", onDropHandler);
    codeMirror?.on("cursorActivity", onChangeSelection);

    return () => {
      codeMirror?.off("change", onChangeHandler);
      codeMirror?.off("drop", onDropHandler);
      codeMirror?.off("cursorActivity", onChangeSelection);
    };
  }, [codeMirror, editorRef, onChangeHandler, onDropHandler]);

  // If we're toggling back from preview, set focus.
  React.useEffect(() => {
    if (!showPreview && codeMirror && autoFocus) {
      codeMirror.focus();
    }
  }, [showPreview, codeMirror, autoFocus]);

  return (
    <div className="markdown-editor">
      {codeMirror && (
        <MarkdownToolbar
          editor={codeMirror}
          previewIsActive={showPreview}
          onTogglePreview={() => setShowPreviewState(!showPreview)}
          templateDisabled={!!content}
          onClickTemplate={templateText ? onClickTemplateHandler : undefined}
        />
      )}
      <div ref={editorRef} style={showPreview ? HIDDEN_STYLE : undefined} />
      {codeMirror && showPreview && (
        <MarkdownContent
          className="preview"
          content={content}
          onClick={() => setShowPreviewState(false)}
        />
      )}
    </div>
  );
};

MarkdownEditor.displayName = "MarkdownEditor";

// Exporting default for React.lazy()
export default MarkdownEditor;
