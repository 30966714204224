import classNames from "classnames";
import React from "react";
import "./Spinner.scss";

export enum SpinnerSize {
  SMALL = 10,
  DEFAULT = 18,
  LARGE = 24
}

interface Props {
  className?: string;
  size?: SpinnerSize;
}

const Spinner: React.FC<Props> = (props) => {
  const { className, size = SpinnerSize.DEFAULT } = props;

  const style = { width: size, height: size };

  return (
    <div className={classNames("spinner", className)} data-testid="spinner">
      <div style={style} className="ball-1" />
      <div style={style} className="ball-2" />
      <div style={style} className="ball-3" />
    </div>
  );
};

export { Spinner };
