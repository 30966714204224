import { QUERY_PARAMS } from "@aptedge/lib-ui/src/types/entities";
import { History } from "history";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { isSupportApp } from "../utils/supportApp";

export interface IUseQueryParams {
  queryParams: URLSearchParams;
  updateParams: (key: QUERY_PARAMS, value: string) => void;
  history: History<History.PoorMansUnknown>;
  removeQueryParams: (key: QUERY_PARAMS) => void;
  clearAllQueryParams: () => void;
  queryString: string;
}

export const useQueryParams = (): IUseQueryParams => {
  const searchPath =
    isSupportApp && window.location.hash.includes("?")
      ? `?${window.location.hash.split("?")[1]}`
      : window.location.search;

  const queryParams = new URLSearchParams(searchPath);
  const history = useHistory();

  const updateURLWithNewQueryParams = useCallback(
    (queryParams: URLSearchParams): void => {
      history.push(`?${queryParams.toString()}`);
    },
    [history]
  );
  const updateParams = useCallback(
    (key: QUERY_PARAMS, value: string): void => {
      queryParams.set(key, value);
      updateURLWithNewQueryParams(queryParams);
    },
    [queryParams, updateURLWithNewQueryParams]
  );
  const removeQueryParams = (key: QUERY_PARAMS): void => {
    if (!queryParams.toString().includes(key)) return;
    queryParams.delete(key);
    updateURLWithNewQueryParams(queryParams);
  };
  const clearAllQueryParams = (): void => {
    const queryParamsList = Object.values(QUERY_PARAMS);
    queryParamsList.forEach((key) => {
      if (queryParams.toString().includes(key)) queryParams.delete(key);
    });
    updateURLWithNewQueryParams(queryParams);
  };

  return {
    queryParams,
    updateParams,
    removeQueryParams,
    clearAllQueryParams,
    history,
    queryString: queryParams.toString()
  };
};
