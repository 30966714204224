import { IEdgeInfo } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { IMergeEdgeArgs } from "../../types/clients";
import { request } from "../../utils/request";
import { post } from "../utils/buildRequest";

async function mergeEdges(args: IMergeEdgeArgs): Promise<IEdgeInfo> {
  const options = post({ mergeSourceEdges: args.mergeSourceEdges });

  return request(`${getBackendApiUrl()}/edges/${args.edgeId}/`, options);
}

export { mergeEdges };
