import { Chip } from "@aptedge/lib-ui/src/components/Chip/Chip";
import { IEdgeAuditLogWeblinkChange } from "@aptedge/lib-ui/src/types/entities";
import React from "react";
import { ArrowTransition } from "./ArrowTransition";
import { SummarySentence } from "./SummarySentence";
import { WithAuditLog } from "./WithAuditLog";
import type { AuditDetailsFC, AuditSummaryFC } from "./WithAuditLog";

const Details: AuditDetailsFC<IEdgeAuditLogWeblinkChange> = (props) => {
  const { log } = props;
  const { added, removed } = log.data;

  const weblinks = added.length > 0 ? added : removed;
  const displayText = added.length > 0 ? "Added" : "Removed";

  const displayedTo = (
    <div>
      <p>
        <span>{displayText} </span>
        {weblinks.map((weblink) => (
          <Chip
            key={`audit-log-label-${weblink}`}
            className="mr-1"
            label={weblink}
          />
        ))}
      </p>
    </div>
  );

  return (
    <div className="audit-log-weblink-change">
      <ArrowTransition from={null} to={displayedTo} />
    </div>
  );
};

const Summary: AuditSummaryFC<IEdgeAuditLogWeblinkChange> = (props) => {
  const { VERBS, OBJECTS } = SummarySentence;
  const { author, log } = props;
  const didAdd = log.data.added.length > 0;

  const verb = didAdd ? VERBS.ADDED : VERBS.REMOVED;

  return (
    <SummarySentence
      subject={author}
      verb={verb}
      object={OBJECTS.WEBLINK}
      displayText={log?.displayText ?? ""}
    />
  );
};

Details.displayName = "AuditWeblinkChangeDetails";
const AuditLogWeblinkChange = WithAuditLog(Details, Summary);

export { AuditLogWeblinkChange };
