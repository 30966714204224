import { logger } from "@aptedge/lib-ui/src/utils/logger";

const SALESFORCE_TASK_LOCAL_STORAGE_KEY = "salesforce_task";

interface ITaskMetadata {
  businessDataSourceId: number;
  taskId: string;
}

function fetchSalesforceTaskFromStorage(): ITaskMetadata | null {
  try {
    const taskJson = localStorage.getItem(SALESFORCE_TASK_LOCAL_STORAGE_KEY);

    if (taskJson) {
      const taskMetadata: ITaskMetadata = JSON.parse(taskJson);

      if (!isValidTaskData(taskMetadata)) {
        throw new Error(`Invalid local storage data: ${taskJson}`);
      }

      return taskMetadata;
    }
  } catch (error) {
    logger.log(error);
  }

  return null;
}

function saveSalesforceTaskToStorage(taskMetadata: ITaskMetadata): void {
  const taskData: string = JSON.stringify(taskMetadata);

  try {
    localStorage.setItem(SALESFORCE_TASK_LOCAL_STORAGE_KEY, taskData);
  } catch (error) {
    logger.log(error);
  }
}

function deleteSalesforceTaskFromStorage(): void {
  try {
    localStorage.removeItem(SALESFORCE_TASK_LOCAL_STORAGE_KEY);
  } catch (e) {
    logger.log(e);
  }
}

const isValidTaskData = (taskData: ITaskMetadata): boolean => {
  return (
    taskData.hasOwnProperty("taskId") &&
    taskData.hasOwnProperty("businessDataSourceId")
  );
};

export {
  fetchSalesforceTaskFromStorage,
  saveSalesforceTaskToStorage,
  deleteSalesforceTaskFromStorage
};
