import { ICustomFieldMapping } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { ICreateCustomFieldMappingArgs } from "../../types/clients";
import { request } from "../../utils/request";
import { post } from "../utils/buildRequest";

async function createCustomFieldMapping(
  args: ICreateCustomFieldMappingArgs
): Promise<ICustomFieldMapping> {
  const url = `${getBackendApiUrl()}/ticket-sources/fields/`;
  const options = post(args);

  return request(url, options);
}

export { createCustomFieldMapping };
