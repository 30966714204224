import { useAppSelector } from "@aptedge/lib-ui/src/redux/hook/hook";
import { IMSTeamsGroup } from "@aptedge/lib-ui/src/types/entities";
import { FC } from "react";
import { MSTeamsListItem } from "./MSTeamsListItem";
import { useMSTeamsList } from "./useMSTeamsList";

interface Props {
  getDatasource: () => void;
}

const MSTeamsList: FC<Props> = (props: Props) => {
  const { getDatasource } = props;

  const { dataSource } = useAppSelector((state) => state.msTeams);

  const { updatedGroups, ...msTeamsHandlers } = useMSTeamsList(
    dataSource,
    getDatasource
  );

  const isUpdatedGroupsPresent = updatedGroups && updatedGroups.length > 0;

  return (
    <>
      {isUpdatedGroupsPresent && (
        <div>
          <h6>Microsoft Teams</h6>
          {updatedGroups.map((group: IMSTeamsGroup, index: number) => (
            <MSTeamsListItem
              key={group.groupId}
              group={group}
              index={index}
              groupList={updatedGroups}
              msTeamsHandlers={msTeamsHandlers}
            />
          ))}
        </div>
      )}
    </>
  );
};

export { MSTeamsList };
