import { Placement } from "@popperjs/core";
import { OffsetModifier } from "@popperjs/core/lib/modifiers/offset";
import { PreventOverflowModifier } from "@popperjs/core/lib/modifiers/preventOverflow";
import classNames from "classnames";
import React from "react";
import { Manager, Popper, Reference } from "react-popper";
import { findChildByType } from "../../utils/reactUtils";
import { PopperContent } from "../Popper/PopperContent";
import { Portal } from "../Portal/Portal";
import "./Popover.scss";

type SharedProps = {
  className?: string;
};

const Container: React.FC<SharedProps> = ({ children, className }) => {
  const toggle = findChildByType(children, Toggle);
  const content = findChildByType(children, Content);

  return (
    <Manager>
      {toggle}
      {content}
    </Manager>
  );
};

type ToggleProps = SharedProps & {
  onMouseDown?: React.MouseEventHandler<HTMLSpanElement>;
  onClick?: React.MouseEventHandler<HTMLSpanElement>;
};

const Toggle: React.FC<ToggleProps> = ({ children, onMouseDown, onClick }) => {
  return (
    <Reference>
      {({ ref }) => (
        <span
          className="toggle"
          ref={ref}
          onMouseDown={onMouseDown}
          onClick={onClick}
        >
          {children}
        </span>
      )}
    </Reference>
  );
};

// We can add more modifier types from here if needed: https://popper.js.org/docs/v2/modifiers/
type Modifiers = Partial<OffsetModifier | PreventOverflowModifier>[];

type ContentProps = SharedProps & {
  isOpen: boolean;
  placement?: Placement;
  modifiers?: Modifiers;
  pointerEvents?: boolean;
};

const Content: React.FC<ContentProps> = ({
  children,
  isOpen,
  placement,
  modifiers,
  pointerEvents = true
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <Portal>
      <Popper modifiers={modifiers} placement={placement}>
        {({ ref, style, placement, update }) => (
          <PopperContent
            ref={ref}
            style={style}
            className={classNames("popper popover-content", {
              "pointer-events-none": !pointerEvents
            })}
            placement={placement}
            scheduleUpdate={update}
          >
            {children}
          </PopperContent>
        )}
      </Popper>
    </Portal>
  );
};

Container.displayName = "PopoverContainer";
Toggle.displayName = "PopoverToggle";
Content.displayName = "PopoverContent";

const Popover = {
  Container,
  Toggle,
  Content
};

export { Popover };
