import { Modal, ModalWidth } from "@aptedge/lib-ui/src/components/Modal/Modal";
import {
  ModalRoute,
  ModalRouter
} from "@aptedge/lib-ui/src/components/Modal/ModalRouter";
import { IEdgeInfo } from "@aptedge/lib-ui/src/types/entities";
import React from "react";
import { BulkReplyProvider } from "../../../context/BulkReplyContext";
import { BulkReplyConfirmPage } from "./BulkReplyConfirmPage";
import { Page } from "./BulkReplyRoutes";
import { BulkReplyStartPage } from "./BulkReplyStartPage";
import { BulkReplySuccessPage } from "./BulkReplySuccessPage";
import "./EdgeBulkReplyModal.scss";

interface Props {
  edge: IEdgeInfo;
  isOpen: boolean;
  onClose: () => void;
}

function EdgeBulkReplyModal(props: Props): React.ReactElement {
  const { edge, isOpen, onClose } = props;

  return (
    <Modal
      width={ModalWidth.LARGE}
      className="edge-bulk-reply-modal"
      title="Reply to all"
      isOpen={isOpen}
      onClose={onClose}
    >
      {isOpen && (
        <BulkReplyProvider edge={edge}>
          <ModalRouter initialRoute={Page.START}>
            <ModalRoute route={Page.START}>
              <BulkReplyStartPage />
            </ModalRoute>
            <ModalRoute route={Page.CONFIRM}>
              <BulkReplyConfirmPage />
            </ModalRoute>
            <ModalRoute route={Page.SUCCESS}>
              <BulkReplySuccessPage onClose={onClose} />
            </ModalRoute>
          </ModalRouter>
        </BulkReplyProvider>
      )}
    </Modal>
  );
}

export { EdgeBulkReplyModal };
