import React from "react";
import { useFlags } from "./FlagsContext";
import { IFlagSet } from "./types";

interface Props {
  children: React.ReactNode;
  flag: keyof IFlagSet;
  fallback?: React.ReactElement;
}

const WithFlag: React.FC<Props> = ({ children, flag, fallback }) => {
  const { flags } = useFlags();

  if (!!flags[flag]) {
    return <>{children}</>;
  } else if (!!fallback) {
    return <>{fallback}</>;
  } else {
    return null;
  }
};

export { WithFlag };
