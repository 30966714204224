import classNames from "classnames";
import React from "react";
import "./TableRow.scss";

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLTableRowElement>,
  HTMLTableRowElement
> & {
  isClickable?: boolean;
  isLoading?: boolean;
};

const TableRow: React.FC<Props> = (props) => {
  const {
    children,
    className,
    isClickable,
    isLoading,
    onClick,
    ...htmlProps
  } = props;

  return (
    <tr
      className={classNames(className, {
        clickable: isClickable && !isLoading,
        loading: isLoading
      })}
      onClick={(e) => {
        if (isLoading) return;
        onClick?.(e);
      }}
      {...htmlProps}
    >
      {children}
    </tr>
  );
};

export { TableRow };
