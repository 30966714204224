import { IEdgeInfo } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { IMigrateEdgeTicketsArgs } from "../../types/clients";
import { request } from "../../utils/request";
import { post } from "../utils/buildRequest";

async function migrateEdgeTickets(
  args: IMigrateEdgeTicketsArgs
): Promise<IEdgeInfo> {
  const options = post(args);

  return request(`${getBackendApiUrl()}/edges/migrate/`, options);
}

export { migrateEdgeTickets };
