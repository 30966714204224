import { ITicketInfo } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { IUpdateTicketRequest } from "../../types/clients";
import { request } from "../../utils/request";
import { patch } from "../utils/buildRequest";

function updateTicket(args: {
  ticketId: number;
  patchContent: IUpdateTicketRequest;
}): Promise<ITicketInfo> {
  const { ticketId, patchContent } = args;
  const options = patch(patchContent);
  const url = `${getBackendApiUrl()}/tickets/${ticketId}/`;

  return request(url, options);
}

export { updateTicket };
