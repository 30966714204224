import { useQuery, UseQueryResult } from "react-query";
import { WebCacheKey } from "../../clients/cache";
import { fetchTicketStatuses } from "../../clients/Tickets/fetchTicketStatuses";

const ONE_HOUR = 60 * 1000 * 60;

function useTicketStatuses(): UseQueryResult<{ statuses: string[] }, Error> {
  return useQuery(WebCacheKey.TICKET_STATUSES, fetchTicketStatuses, {
    refetchOnWindowFocus: false, // Don't need to revalidate these on window refocus.
    refetchOnMount: false,
    // The time in milliseconds after data is considered stale. Once marked stale, RQ will refetch.
    staleTime: ONE_HOUR,
    // The time in milliseconds that unused/inactive cache (no components using it are mounted) data remains in memory.
    cacheTime: ONE_HOUR
  });
}

export { useTicketStatuses };
