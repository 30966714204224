import {
  IProviderSource,
  OAuthStatus
} from "@aptedge/lib-ui/src/types/entities";

function providerIsAuthorized(provider?: IProviderSource | null): boolean {
  return (
    !!provider &&
    !!provider.providerUserLogin &&
    provider.oauthStatus === OAuthStatus.AUTHENTICATED
  );
}

export { providerIsAuthorized };
