import { configureStore } from "@reduxjs/toolkit";
import answerGPTSlice from "../reduxSlice/answerGPTSlice";
import appSlice from "../reduxSlice/appSlice";
import azureDevopsSlice from "../reduxSlice/azureDevopsIssueSlice";
import cookedTicketSlice from "../reduxSlice/cookedTicketSlice";
import dynamicIntegrationSlice from "../reduxSlice/dynamicIntegrationSlice";
import kbGenSlice from "../reduxSlice/kbGenSlice";
import msTeamsSlice from "../reduxSlice/msTeamsSlice";
import notionArticleSlice from "../reduxSlice/notionArticleSlice";
import searchSlice from "../reduxSlice/searchSlice";
import sharepointDriveSlice from "../reduxSlice/sharepointDriveSlice";
import sharepointSlice from "../reduxSlice/sharepointSlice";
import snowArticleSlice from "../reduxSlice/snowArticleSlice";
import ticketInsightsSlice from "../reduxSlice/ticketInsightsSlice";

const reducer = {
  azureDevopsSlice,
  notionArticleSlice,
  search: searchSlice,
  msTeams: msTeamsSlice,
  sharepoint: sharepointSlice,
  sharepointDrive: sharepointDriveSlice,
  snow: snowArticleSlice,
  answerGPT: answerGPTSlice,
  app: appSlice,
  cookedTicket: cookedTicketSlice,
  ticketInsights: ticketInsightsSlice,
  dynamicIntegration: dynamicIntegrationSlice,
  knowledgeBase: kbGenSlice
};

export const store = configureStore({
  reducer
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
