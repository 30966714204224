import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import classNames from "classnames";
import React from "react";
import { useInfiniteQuery, useMutation } from "react-query";
import { WebCacheKey } from "../../clients/cache";
import { fetchNotifications } from "../../clients/Notifications/fetchNotifications";
import { markNotificationsRead } from "../../clients/Notifications/markNotificationsRead";
import { useIntersectionObserver } from "../../hooks/useIntersectionObserver";
import { Notification } from "./Notification";
import "./NotificationList.scss";

interface Props {
  unreadCount: number;
  onMarkRead: () => void;
  onClick: () => void;
}

const NotificationList: React.FC<Props> = (props) => {
  const { unreadCount, onMarkRead, onClick } = props;

  const wrapperRef = React.createRef<HTMLDivElement>();
  const targetRef = React.createRef<HTMLDivElement>();

  const loadNotifications = useInfiniteQuery(
    WebCacheKey.NOTIFICATIONS,
    ({ pageParam = 1 }) =>
      fetchNotifications({ page: pageParam, perPage: 5, sortBy: null }),
    {
      getPreviousPageParam: (data) => (data.page > 1 ? data.page - 1 : false),
      getNextPageParam: (data) => (data.hasNext ? data.page + 1 : false)
    }
  );

  useIntersectionObserver({
    root: wrapperRef,
    target: targetRef,
    onIntersect: loadNotifications.fetchNextPage,
    enabled:
      !!loadNotifications.hasNextPage && !loadNotifications.isFetchingNextPage
  });

  const refreshQueries = (): void => {
    loadNotifications.refetch();
    onMarkRead();
  };

  const markRead = useMutation(markNotificationsRead, {
    onSuccess: refreshQueries
  });

  const handleMarkAllRead = (): void => {
    if (unreadCount > 0) {
      markRead.mutate();
    }
  };

  const pages = loadNotifications.data?.pages || [];
  const notifications = pages.flatMap((page) => page.items);
  const isLoading = loadNotifications.status === "loading";

  return (
    <div ref={wrapperRef} className="notification-list">
      <div className="d-flex justify-content-between">
        <h3>Notifications</h3>
        <div>
          {!!notifications.length && (
            <span
              className={classNames("mark-all", {
                disabled: unreadCount === 0
              })}
              onClick={handleMarkAllRead}
            >
              Mark all read
            </span>
          )}
        </div>
      </div>
      <div className="inner">
        {notifications.map((notification, idx) => (
          <Notification
            key={`notification-${notification.id}-${idx}`}
            notification={notification}
            onMarkRead={refreshQueries}
            onClick={onClick}
          />
        ))}
        {isLoading && <Spinner className="mt-4" />}
        {!isLoading && loadNotifications.hasNextPage && (
          <div ref={targetRef} className="mb-3 fetch-placeholder">
            scroll to load more
          </div>
        )}
        {!isLoading && !notifications.length && (
          <div className="mt-6 mb-6 text-center all-clear">
            <div>
              <span role="img" aria-label="all-clear-emoji">
                😎
              </span>
            </div>
            <p className="text-muted">No notifications</p>
          </div>
        )}
      </div>
    </div>
  );
};

export { NotificationList };
