import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { IPhraseSetting } from "@aptedge/lib-ui/src/types/entities";
import classNames from "classnames";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { deletePhrase } from "../../../clients/Phrases/deletePhrase";
import { DeleteIcon } from "../common/DeleteIcon";
import { ListItem } from "../common/ListItem";

interface Props {
  phrase: IPhraseSetting;
  className?: string;
  onChange: () => void;
}
const PhraseListItem: React.FC<Props> = (props) => {
  const { phrase, className, onChange } = props;

  const [showConfirm, setShowConfirm] = useState(false);

  const removePhrase = useMutation(deletePhrase, { onSuccess: onChange });

  return (
    <ListItem className={classNames("phrase-list-item", "group", className)}>
      {!showConfirm ? (
        <>
          <div>
            &quot;<code>{phrase.phrase.value}</code>&quot;
          </div>
          <div className="d-flex align-items-center">
            <span className="badge text-uppercase text-muted mr-3">
              {phrase.label.name}
            </span>
            <DeleteIcon onClick={() => setShowConfirm(true)} />
          </div>
        </>
      ) : (
        <>
          <span className="text-muted pr-2">
            <span>Are you sure you want to delete {phrase.phrase.value}?</span>
          </span>
          <div className="d-flex">
            <Button
              color="secondary"
              className="mr-2"
              onClick={() => setShowConfirm(false)}
            >
              Cancel
            </Button>
            <Button
              color="danger"
              onClick={() =>
                removePhrase.mutate({ phraseId: phrase.phrase.id })
              }
            >
              Delete
            </Button>
          </div>
        </>
      )}
    </ListItem>
  );
};

export { PhraseListItem };
