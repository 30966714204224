import { Tooltip } from "@aptedge/lib-ui/src/components/Tooltip/Tooltip";
import { useSentry } from "@aptedge/lib-ui/src/context/SentryContext";
import { useIsMounted } from "@aptedge/lib-ui/src/hooks/useIsMounted";
import {
  IEdgeAuditLog,
  INotification
} from "@aptedge/lib-ui/src/types/entities";
import classNames from "classnames";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { markNotification } from "../../clients/Notifications/markNotification";
import { toEdgePath } from "../../routes/toPath";
import { AuditLog } from "../AuditLogs/AuditLog";
import "./Notification.scss";

interface Props {
  notification: INotification<IEdgeAuditLog>;
  onMarkRead: () => void;
  onClick: () => void;
}

const Notification: React.FC<Props> = (props) => {
  const { notification, onMarkRead, onClick } = props;

  const history = useHistory();
  const isMounted = useIsMounted();
  const { captureException } = useSentry();

  const [isRead, setIsRead] = React.useState(
    !!notification.markedReadOn && notification.markedReadOn > -1
  );
  const tooltipContent = isRead ? "Mark as unread" : "Mark as read";

  const markRead = useMutation(markNotification, {
    onSuccess: () => {
      if (isMounted.current) setIsRead((v) => !v);
      onMarkRead();
    }
  });

  const handleMarkRead = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    e.stopPropagation();

    markRead.mutate({ id: notification.id, markAsRead: !isRead });
  };

  const handleNotificationClick = (): void => {
    if (!isRead) {
      markRead.mutate({ id: notification.id, markAsRead: true });
    }

    onClick();
    history.push(toEdgePath(notification.details.edgeId));
  };

  // Reset local state if the parent updates us.
  React.useEffect(() => {
    setIsRead(!!notification.markedReadOn && notification.markedReadOn > -1);
  }, [notification]);

  // TODO - remove once we have all audit logs enabled.
  const willRender = !!AuditLog({ log: notification.details });

  if (!willRender) return null;

  return (
    <ErrorBoundary
      fallback={null}
      onError={(err, componentStack) =>
        captureException(err.message + componentStack)
      }
      resetKeys={[notification]}
    >
      <div className="notification" onClick={handleNotificationClick}>
        <AuditLog log={notification.details} showDetails={false}>
          <div>{notification.details.edgeDisplayName}</div>
          <div className="notification-display-text">
            {notification.details.displayText}
          </div>
        </AuditLog>
        <Tooltip content={tooltipContent}>
          <div
            className={classNames("unread-dot", { unread: !isRead })}
            onClick={handleMarkRead}
            data-testid={classNames("notification-dot", { unread: !isRead })}
          >
            <span className="dot" />
          </div>
        </Tooltip>
      </div>
    </ErrorBoundary>
  );
};

export { Notification };
