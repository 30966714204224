import Color from "color";

function toRgba(hexValue: string): string {
  return Color(hexValue).rgb().toString();
}

function darken(hexValue: string, ratio: number): string {
  const color = Color(hexValue);
  const lightness = color.lightness();

  return color.lightness(lightness - lightness * ratio).hex();
}

function desaturate(hexValue: string, ratio: number): string {
  const color = Color(hexValue);
  const saturation = color.saturationl();

  return color.saturationl(saturation - saturation * ratio).hex();
}

function lighten(hexValue: string, ratio: number): string {
  const color = Color(hexValue);
  const lightness = color.lightness();

  return color.lightness(lightness + (100 - lightness) * ratio).hex();
}

function transparentize(hexValue: string, amount: number): string {
  return Color(hexValue).fade(amount).hsl().string();
}

function gradient(
  hexValueA: string,
  hexValueB: string,
  amount: number
): string {
  const a = Color(hexValueA);
  const b = Color(hexValueB);

  function grade(a: number, b: number): number {
    return a + Math.round((b - a) * (amount / 100));
  }

  const r = grade(a.red(), b.red());
  const blue = grade(a.blue(), b.blue());
  const g = grade(a.green(), b.green());

  return Color({ r, b: blue, g }).hex();
}

export { darken, desaturate, lighten, toRgba, transparentize, gradient };
