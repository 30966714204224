import { getBackendApiUrl } from "../../config";
import { deleteRequest } from "../../utils/request";
import { deleteInit } from "../utils/buildRequest";

async function deleteTemplate({
  templateId
}: {
  templateId: number;
}): Promise<void> {
  const url = `${getBackendApiUrl()}/templates/${templateId}`;

  return deleteRequest(url, deleteInit());
}

export { deleteTemplate };
