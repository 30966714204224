import { ITeam } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";

async function fetchTeamsById(ids: number[]): Promise<ITeam[]> {
  const promises: Promise<ITeam>[] = [];

  ids.forEach((id) => {
    const url = `${getBackendApiUrl()}/teams/${id}/`;

    const promise = request(url);
    promises.push(promise);
  });

  return Promise.all(promises);
}

export { fetchTeamsById };
