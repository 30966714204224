import { IBulkReply, IEdgeInfo } from "@aptedge/lib-ui/src/types/entities";
import React, { createContext, useContext, useReducer } from "react";

type State = {
  edgeId: number;
  isInternal: boolean;
  content?: string;
  status: { from: string; to?: string };
  newReply: IBulkReply | null;
};

type Action =
  | { type: "setInternal"; payload: boolean }
  | { type: "setContent"; payload: string }
  | { type: "addNewReply"; payload: IBulkReply }
  | { type: "setFromStatus"; payload: { from: string } }
  | { type: "setToStatus"; payload: { to?: string } };

function bulkReplyReducer(state: State, action: Action): State {
  switch (action.type) {
    case "setInternal":
      return { ...state, isInternal: action.payload };
    case "setContent":
      return { ...state, content: action.payload };
    case "addNewReply":
      // Reset content and status filters after creating.
      return {
        ...state,
        content: undefined,
        status: { from: "All" },
        newReply: action.payload
      };
    case "setFromStatus":
      return {
        ...state,
        status: { from: action.payload.from, to: state.status.to }
      };
    case "setToStatus":
      return {
        ...state,
        status: { from: state.status.from, to: action.payload.to }
      };
    default:
      return state;
  }
}

type IContext = [
  React.ReducerState<typeof bulkReplyReducer>,
  React.Dispatch<React.ReducerAction<typeof bulkReplyReducer>>
];

const Context = createContext<IContext>([
  { edgeId: 0, isInternal: true, newReply: null, status: { from: "All" } },
  (state) => state
]);

const BulkReplyProvider: React.FC<{ edge: IEdgeInfo }> = ({
  children,
  edge
}) => {
  const value = useReducer(bulkReplyReducer, {
    isInternal: true,
    newReply: null,
    edgeId: edge.id,
    status: { from: "All" }
  });

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

function useBulkReplyContext(): IContext {
  return useContext(Context);
}

export { useBulkReplyContext, BulkReplyProvider };
