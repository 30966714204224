import { List } from "@aptedge/lib-ui/src/components/List/List";
import { ListItem } from "@aptedge/lib-ui/src/components/List/ListItem";
import { Pill } from "@aptedge/lib-ui/src/components/Pill/Pill";
import { useArrowDown } from "@aptedge/lib-ui/src/hooks/useArrowDown";
import { Keys } from "@aptedge/lib-ui/src/styles/keys";
import { ILabel } from "@aptedge/lib-ui/src/types/entities";
import classNames from "classnames";
import startCase from "lodash/startCase";
import React, { createRef, ReactNode, useEffect } from "react";

interface Props {
  items: ILabel[];
  title: ReactNode;
  containerRef: React.RefObject<HTMLDivElement>;
  onSelect: (item: ILabel) => void;
  className?: string;
}

const LabelsList: React.FunctionComponent<Props> = (props) => {
  const { items, title, className, containerRef, onSelect } = props;

  const listBoxRef = createRef<HTMLDivElement>();

  const { setHighlighted, getListBoxProps, getListItemProps } = useArrowDown({
    ref: containerRef,
    listBoxRef,
    items,
    onKeyDown: (idx, key) => {
      if (key === Keys.ENTER) {
        if (items[idx]) {
          onSelect(items[idx]);
        }
      }
    }
  });

  // Reset highlighted to first item when items change.
  useEffect(() => {
    setHighlighted(0);
  }, [items, setHighlighted]);

  return (
    <div className="results-container" {...getListBoxProps()}>
      {title}
      <List className={classNames("results-list", className)} ref={listBoxRef}>
        {items.map((item: ILabel, idx: number) => (
          <ListItem
            key={idx}
            {...getListItemProps(idx)}
            onClick={() => onSelect(item)}
          >
            <div>
              <div className="results-name">{item.name}</div>
              {item.parentName && (
                <div className="results-parent-name">{item.parentName}</div>
              )}
            </div>
            <Pill text={startCase(item.type)} />
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export { LabelsList };
