import { IEdgeInfo } from "@aptedge/lib-ui/src/types/entities";
import React from "react";
import { useMutation } from "react-query";
import { updateEdge } from "../../clients/Edges/updateEdge";
import { EdgeSectionLabel } from "./EdgeSectionLabel";
import { UserSelect } from "./UserSelect";

interface Props {
  edge: IEdgeInfo;
  onUpdateEdge: (updatedEdge: IEdgeInfo) => void;
}

const EdgeAssignee: React.FC<Props> = (props) => {
  const { edge, onUpdateEdge } = props;

  const updateAssignee = useMutation(updateEdge, {
    onSuccess: onUpdateEdge
  });

  const handleSelect = (userId: number): void => {
    if (userId !== edge.assignee?.id) {
      updateAssignee.mutate({
        edgeId: edge.id,
        patchContent: { assignee: { id: userId } }
      });
    }
  };

  const handleUnassign = (): void => {
    if (edge.assignee) {
      updateAssignee.mutate({
        edgeId: edge.id,
        patchContent: { assignee: null }
      });
    }
  };

  return (
    <React.Fragment>
      <EdgeSectionLabel>Assignee</EdgeSectionLabel>
      <UserSelect
        value={edge.assignee?.id || undefined}
        isError={updateAssignee.isError}
        isDisabled={edge.archived}
        isLoading={updateAssignee.isLoading}
        onChange={(val) => {
          if (val) {
            handleSelect(val);
          } else {
            handleUnassign();
          }
        }}
      />
    </React.Fragment>
  );
};

export { EdgeAssignee };
