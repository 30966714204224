import { IUser } from "@aptedge/lib-ui/src/types/entities";
import { inProdReleaseOnly } from "@aptedge/lib-ui/src/utils/env";
import {
  dataLayerPushToGTM,
  GTM_EVENTS
} from "@aptedge/lib-ui/src/utils/event";
import { logger } from "@aptedge/lib-ui/src/utils/logger";
import { noop } from "@aptedge/lib-ui/src/utils/noop";
import { configureScope, User } from "@sentry/react";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { useQueryClient } from "react-query";
import { login as loginClient } from "../clients/User/login";
import { logout as logoutClient } from "../clients/User/logout";
import {
  deleteUserFromLocalStorage,
  fetchUserFromLocalStorage,
  saveUserToLocalStorage
} from "../clients/User/utils";
import { getAccount } from "../config";
import { IUserCredentials } from "../types/clients";

export enum AuthStatus {
  UNKNOWN,
  LOGGED_OUT,
  LOGGED_IN,
  PENDING,
  FAILED
}

interface ContextState {
  user?: IUser;
  status: AuthStatus;
  error?: Error;
}

interface Context extends ContextState {
  login: (credentials: IUserCredentials) => Promise<void>;
  logout: () => Promise<void>;
}

const defaultState: Context = {
  login: noop,
  logout: noop,
  status: AuthStatus.UNKNOWN
};

const AuthContext = createContext<Context>(defaultState);

const AuthProvider: React.FunctionComponent = ({ children }) => {
  const queryClient = useQueryClient();

  const [state, setState] = useState<ContextState>(() => {
    const user = fetchUserFromLocalStorage() || undefined;

    if (user?.email) {
      dataLayerPushToGTM({
        event: GTM_EVENTS.GTM_USER_CONTEXT,
        data: {
          user_email: user.email,
          account_name: user.accountName
        }
      });
    }

    return {
      user,
      status: user?.email ? AuthStatus.LOGGED_IN : AuthStatus.LOGGED_OUT
    };
  });

  const login = useCallback(
    async (credentials: IUserCredentials): Promise<void> => {
      // clear cache in case we didn't log out properly last time (e.g. auth timed out)
      queryClient.clear();

      setState({
        status: AuthStatus.PENDING,
        error: undefined,
        user: undefined
      });

      try {
        const { login, ...rest } = await loginClient(credentials);

        saveUserToLocalStorage({
          email: credentials.email,
          ...rest
        });

        if (login) {
          dataLayerPushToGTM({
            event: GTM_EVENTS.GTM_USER_CONTEXT,
            data: {
              user_email: credentials.email,
              account_name: rest.accountName
            }
          });
        }

        setState({
          status: AuthStatus.LOGGED_IN,
          user: { email: credentials.email, ...rest }
        });
      } catch (e) {
        logger.error(e);

        // Window layer is controlled on GTM, so typecheck is not feasible here.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        window.dataLayer = window.dataLayer?.filter((x: any) => !x.user_email);

        deleteUserFromLocalStorage();
        setState({ status: AuthStatus.FAILED, error: e, user: undefined });
      }
    },
    [queryClient]
  );

  const logout = useCallback(async (): Promise<void> => {
    deleteUserFromLocalStorage();

    // we do clear the cache on login, but do it here too, just for consistency with deleting other
    // user-specific info on logout
    queryClient.clear();

    dataLayerPushToGTM({
      event: "user_logout"
    });

    // Window layer is controlled on GTM, so typecheck is not feasible here.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    window.dataLayer = window.dataLayer?.filter((x: any) => !x.user_email);

    setState({ status: AuthStatus.LOGGED_OUT, user: undefined });

    try {
      await logoutClient();
    } catch (e) {
      logger.log(e);
    }
  }, [queryClient]);

  // Update sentry scope and identify user with posthog whenever the user changes.
  useEffect(() => {
    inProdReleaseOnly(() => {
      if (state.user?.email) {
        // ensure valid email to avoid issues with non-unique identifiers
        const { email, accountName } = state.user;
        dataLayerPushToGTM({
          event: GTM_EVENTS.GTM_USER_CONTEXT,
          data: {
            user_email: email,
            account_name: accountName
          }
        });
      }
      configureScope((scope) => {
        scope.setUser(getSentryUser(state.user));
        if (state.user?.email) {
          dataLayerPushToGTM({
            event: GTM_EVENTS.GTM_USER_CONTEXT,
            data: {
              user_email: state.user?.email,
              account_name: state.user?.accountName
            }
          });
        }
      });
    });
  }, [state.user]);

  const value = useMemo(
    () => ({
      ...state,
      login,
      logout
    }),
    [login, logout, state]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

function getSentryUser(user?: IUser): User | null {
  return user
    ? {
        id: user.id.toLocaleString(),
        accountName: getAccount()
      }
    : null;
}

const useAuth = (): Context => useContext(AuthContext);

export { AuthProvider, useAuth };
