import { Chip } from "@aptedge/lib-ui/src/components/Chip/Chip";
import { IEdgeAuditLogLabelChange } from "@aptedge/lib-ui/src/types/entities";
import React from "react";
import { ArrowTransition } from "./ArrowTransition";
import { SummarySentence } from "./SummarySentence";
import { AuditDetailsFC, AuditSummaryFC, WithAuditLog } from "./WithAuditLog";

const Details: AuditDetailsFC<IEdgeAuditLogLabelChange> = (props) => {
  const { log } = props;
  const { product, feature, theme } = log.data;

  const added = [
    ...(product?.added || []),
    ...(feature?.added || []),
    ...(theme?.added || [])
  ].flatMap((item) => item);

  const removed = [
    ...(product?.removed || []),
    ...(feature?.removed || []),
    ...(theme?.removed || [])
  ].flatMap((item) => item);

  const labels = added.length > 0 ? added : removed;
  const displayText = added.length > 0 ? "Added" : "Removed";

  const displayedTo = (
    <div>
      <p>
        <span>{displayText} </span>
        {labels.map((l) => (
          <Chip
            key={`audit-log-label-${l.id}-${l.displayName}`}
            className="mr-1"
            label={l.displayName}
          />
        ))}
      </p>
    </div>
  );

  return (
    <div className="audit-log-assignee-change">
      <ArrowTransition from={null} to={displayedTo} />
    </div>
  );
};

const Summary: AuditSummaryFC<IEdgeAuditLogLabelChange> = (props) => {
  const { VERBS, OBJECTS } = SummarySentence;
  const { author, log } = props;

  return (
    <SummarySentence
      subject={author}
      verb={VERBS.UPDATED}
      object={OBJECTS.LABELS}
      displayText={log?.displayText ?? ""}
    />
  );
};

Details.displayName = "AuditLabelChangeDetails";
const AuditLogLabelChange = WithAuditLog(Details, Summary);

export { AuditLogLabelChange };
