import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { IZendeskKBArticleSource } from "@aptedge/lib-ui/src/types/entities";
import { URL_VALIDATION_REGEX } from "@aptedge/lib-ui/src/utils/regex";
import { useFormik } from "formik";
import React from "react";
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label
} from "reactstrap";
import * as Yup from "yup";
import { trimTrailingChars } from "../../../utils/functions";

export interface ZendeskKBFormData {
  username: string;
  zendeskInstanceName: string;
  accessToken: string;
}

interface Props {
  source: IZendeskKBArticleSource | null;
  onSubmit: (formData: ZendeskKBFormData) => void;
}

const ZendeskKBConfigForm: React.FunctionComponent<Props> = ({
  source,
  onSubmit
}) => {
  const getUrl = (url: string): string => {
    let result = url.replace(/(^\w+:|^)\/\//, "");
    result = trimTrailingChars(result, "/");
    return result;
  };

  const handleSubmit = (data: ZendeskKBFormData): void => {
    onSubmit({
      ...data,
      zendeskInstanceName: getUrl(data.zendeskInstanceName)
    });
  };

  const formik = useFormik<ZendeskKBFormData>({
    validationSchema: Yup.object({
      username: Yup.string().required("Please enter a valid username"),
      zendeskInstanceName: Yup.string()
        .required("Please enter a valid zendesk instance name")
        .matches(
          URL_VALIDATION_REGEX,
          "Please enter a valid zendesk instance name"
        ),
      accessToken: Yup.string().required("Please enter a valid access token")
    }),
    initialValues: {
      username: (source && source.username) || "",
      zendeskInstanceName: (source && source.zendeskInstanceName) || "",
      accessToken: (source && source.accessToken) || ""
    },
    validateOnChange: false,
    onSubmit: handleSubmit
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <FormGroup row>
        <Label sm={3} for="zendeskInstanceName">
          Zendesk Instance name
        </Label>
        <Col sm={9}>
          <Input
            data-testid="zendesk-instance-name"
            type="text"
            name="zendeskInstanceName"
            placeholder=""
            value={formik.values.zendeskInstanceName}
            onChange={formik.handleChange}
            invalid={!!formik.errors.zendeskInstanceName}
          />
          <FormFeedback>{formik.errors.zendeskInstanceName}</FormFeedback>
          <FormText>Zendesk Instance name</FormText>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3} for="username">
          Zendesk username
        </Label>
        <Col sm={9}>
          <Input
            data-testid="zendesk-username"
            type="text"
            name="username"
            placeholder=""
            value={formik.values.username}
            onChange={formik.handleChange}
            invalid={!!formik.errors.username}
          />
          <FormFeedback>{formik.errors.username}</FormFeedback>
          <FormText>Zendesk Username</FormText>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3} for="accessToken">
          Zendesk access token
        </Label>
        <Col sm={9}>
          <Input
            data-testid="zendesk-access-token"
            type="text"
            name="accessToken"
            placeholder=""
            value={formik.values.accessToken}
            onChange={formik.handleChange}
            invalid={!!formik.errors.accessToken}
          />
          <FormFeedback>{formik.errors.accessToken}</FormFeedback>
          <FormText>Zendesk access token</FormText>
        </Col>
      </FormGroup>
      <div className="float-right">
        <Button type="submit">Authorize</Button>
      </div>
    </Form>
  );
};

export { ZendeskKBConfigForm };
