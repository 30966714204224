import { ActionBar } from "@aptedge/lib-ui/src/components/ActionBar/ActionBar";
import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import { ModalFooter } from "@aptedge/lib-ui/src/components/Modal/Modal";
import { Spacer } from "@aptedge/lib-ui/src/components/Spacer/Spacer";
import { IEdgeInfo } from "@aptedge/lib-ui/src/types/entities";
import { GTM_EVENTS, dataLayerPush } from "@aptedge/lib-ui/src/utils/event";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { updateEdge } from "../../clients/Edges/updateEdge";
import { convertQueryStateToClientStatus } from "../../clients/utils/convertQueryStateToClientStatus";
import { ConfirmationModal } from "../../components/ConfirmationModal/ConfirmationModal";
import { EdgeWatchButton } from "./EdgeWatchButton";
import { MergeEdgeModal } from "./merge/MergeEdgeModal";
import "./EdgeButtons.scss";

interface Props {
  edge: IEdgeInfo;
  onMerge: (updatedEdge: IEdgeInfo) => void;
  onUpdateEdge: (updatedEdge: IEdgeInfo) => void;
  onRevalidate: () => void;
}

function EdgeButtons(props: Props): React.ReactElement {
  const { edge, onMerge, onUpdateEdge, onRevalidate } = props;

  const [mergeModalOpen, setMergeModalOpen] = useState(false);
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);

  const edgeMutation = useMutation(updateEdge, {
    onSuccess: (newEdge) => {
      onUpdateEdge(newEdge);
      setArchiveModalOpen(false);
      edgeMutation.reset();
    }
  });

  const handleEdgeState = (isArchive: boolean): void => {
    edgeMutation.mutate({
      edgeId: edge.id,
      patchContent: {
        archived: isArchive
      }
    });
    dataLayerPush({
      event: isArchive
        ? GTM_EVENTS.GTM_ARCHIVE_EDGE
        : GTM_EVENTS.GTM_UNARCHIVE_EDGE
    });
  };

  return (
    <React.Fragment>
      <div className="edge-buttons">
        {edge.archived ? (
          <Button className="mr-2" onClick={() => handleEdgeState(false)}>
            <Icons.Archive />
            <span>Unarchive</span>
          </Button>
        ) : (
          <Button className="mr-2" onClick={() => setArchiveModalOpen(true)}>
            <Icons.Archive />
            <span>Archive</span>
          </Button>
        )}
        <Button
          onClick={() => setMergeModalOpen(true)}
          disabled={edge.archived}
        >
          <Icons.ObjectGroup />
          <span>Merge</span>
        </Button>
        <EdgeWatchButton edge={edge} onUpdate={onRevalidate} className="ml-2" />
      </div>
      <MergeEdgeModal
        edge={edge}
        isOpen={mergeModalOpen}
        onClose={() => setMergeModalOpen(false)}
        onUpdate={onMerge}
      />
      <ConfirmationModal
        title={`Archive EDGE-${edge.id}?`}
        status={convertQueryStateToClientStatus(edgeMutation)}
        isOpen={archiveModalOpen}
        loadingTitle="Archiving Edge..."
        onClose={() => setArchiveModalOpen(false)}
      >
        <p>Are you sure you want to archive this Edge?</p>
        <ModalFooter>
          <ActionBar.Container>
            <ActionBar.Right>
              <Spacer flex row size="medium">
                <Button
                  color="secondary"
                  onClick={() => setArchiveModalOpen(false)}
                >
                  Cancel
                </Button>
                <Button
                  data-testid="delete button"
                  color="danger"
                  onClick={() => handleEdgeState(true)}
                >
                  Archive
                </Button>
              </Spacer>
            </ActionBar.Right>
          </ActionBar.Container>
        </ModalFooter>
      </ConfirmationModal>
    </React.Fragment>
  );
}

export { EdgeButtons };
