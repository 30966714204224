import { ModalWidth } from "@aptedge/lib-ui/src/components/Modal/Modal";
import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import { useAppDispatch } from "@aptedge/lib-ui/src/redux/hook/hook";
import { setAzureDevopsTapData } from "@aptedge/lib-ui/src/redux/reduxSlice/azureDevopsIssueSlice";
import {
  IntegrationStatus,
  INTEGRATION_STATUS,
  OAuthStatus
} from "@aptedge/lib-ui/src/types/entities";
import { GATEWAY_TIMEOUT } from "http-status-codes";
import React, { useEffect, useState, useCallback } from "react";
import { useMutation } from "react-query";
import { ToastType } from "react-toastify";
import { createAzureDevopsInternalIssueSource } from "../../../../clients/InternalIssueSources/createAzureDevopsInternalIssueSource";
import { ConfirmationModal } from "../../../../components/ConfirmationModal/ConfirmationModal";
import { Toast } from "../../../../components/Toast/Toast";
import WithLoading from "../../../../components/WithLoading/WithLoading";
import { useAzureDevopsIssueDataSource } from "../../../../hooks/clients/useDataSource";
import { ClientStatus } from "../../../../types/clients";
import { AddButton } from "../../common/AddButton";
import { StatusButton } from "../../common/StatusButton";
import {
  AzureDevopsCloudConfigFormData,
  AzureDevopsCloudConfigForm
} from "./AzureDevopsCloudConfigForm";

const AzureDevopsCloudConfig: React.FunctionComponent = () => {
  const dataSource = useAzureDevopsIssueDataSource();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [redirectStatus, setRedirectStatus] = React.useState(
    ClientStatus.PROMPT
  );
  const dispatch = useAppDispatch();

  const azureDevopsSource = dataSource?.dataSource ?? null;

  const addSource = useMutation(createAzureDevopsInternalIssueSource, {
    onError: () => {
      setRedirectStatus(ClientStatus.ERROR);
    },
    onSuccess: () => {
      setRedirectStatus(ClientStatus.SUCCESS);
      setShowModal(false);
      dataSource.reload();
    }
  });

  const onFormSubmit = (formData: AzureDevopsCloudConfigFormData): void => {
    setRedirectStatus(ClientStatus.LOADING);
    addSource.mutate(formData);
  };

  const getStatus = (): IntegrationStatus => {
    if (dataSource?.error?.code === GATEWAY_TIMEOUT)
      return INTEGRATION_STATUS.TIME_OUT;
    if (azureDevopsSource?.status === OAuthStatus.AUTHENTICATED)
      return INTEGRATION_STATUS.OK;
    return INTEGRATION_STATUS.ERROR;
  };

  const handleReduxDispatch = useCallback((): void => {
    dispatch(setAzureDevopsTapData(azureDevopsSource ?? null));
  }, [dispatch, azureDevopsSource]);

  useEffect(() => {
    handleReduxDispatch();
  }, [handleReduxDispatch]);

  const isConnectionExist = azureDevopsSource || getStatus() === "timeOut";
  const isLoaderVisible = redirectStatus === ClientStatus.LOADING;

  return (
    <>
      <div className="mb-3">
        <h5>Azure Devops</h5>
        <p>Configure your Azure Devops Cloud settings below.</p>
      </div>
      <WithLoading
        isLoading={isLoaderVisible}
        fallback={
          <div className="d-flex justify-content-center m-4">
            <Spinner />
          </div>
        }
      >
        {isConnectionExist ? (
          <StatusButton
            status={getStatus()}
            onClickEdit={() => setShowModal(true)}
          >
            <>
              <div>Azure Devops</div>
              <div className="text-muted">azure.microsoft.com</div>
            </>
          </StatusButton>
        ) : (
          <AddButton onClick={() => setShowModal(true)}>Connect</AddButton>
        )}
      </WithLoading>

      <ConfirmationModal
        title="Azure Devops Settings"
        width={ModalWidth.MEDIUM}
        loadingTitle="Loading...."
        status={redirectStatus}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      >
        <p className="mb-4">Configure your settings below.</p>
        <AzureDevopsCloudConfigForm onSubmit={onFormSubmit} />
      </ConfirmationModal>
      {addSource.isError && (
        <Toast type={ToastType.ERROR}>Something went wrong.</Toast>
      )}
    </>
  );
};

export { AzureDevopsCloudConfig };
