import {
  Spinner,
  SpinnerSize
} from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import React from "react";
import { NavLink } from "react-router-dom";
import { NavbarBrand } from "reactstrap";
import { useWhoami } from "../../clients/User/useWhoami";
import WithLoading from "../WithLoading/WithLoading";

const AppBrand: React.FC<Props> = ({ to }) => {
  const { data: userData, isLoading } = useWhoami();

  return (
    <NavbarBrand className="mr-3" tag="span">
      <NavLink exact className="nav-logo" to={to} id={"navItem"}>
        <WithLoading
          isLoading={isLoading}
          fallback={<Spinner size={SpinnerSize.SMALL} />}
        >
          <img
            className="mr-2"
            alt="logo"
            src="/trimmed-logo.png"
            height="20px"
          />
          <span>{userData?.prettyAccountName}</span>
        </WithLoading>
      </NavLink>
    </NavbarBrand>
  );
};

interface Props {
  to: string;
}

export { AppBrand };
