import { useSearchPreview } from "@aptedge/lib-ui/src/hooks/useSearchPreview";
import {
  ICompositeResult,
  IFederated
} from "@aptedge/lib-ui/src/types/entities";
import classNames from "classnames";
import React from "react";
import SearchResultCardActionIcons from "../SearchResultCardActionIcons/SearchResultCardActionIcons";
import { SearchResultPreview } from "../SearchResultPreview/SearchResultPreview";

import "./FederatedSearchResult.scss";

interface Props
  extends Omit<
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >,
    "ref"
  > {
  className?: string;
  federated: IFederated;
  isSelected?: boolean;
  showActionIcons?: boolean;
}

const FederatedSearchResult: React.FC<Props> = ({
  className,
  federated,
  isSelected,
  showActionIcons,
  ...htmlProps
}) => {
  const { hasPreview, previewSnippet, previewType } = useSearchPreview({
    ...federated,
    type: "federated"
  });

  return (
    <div
      {...htmlProps}
      className={classNames("federated-search-result", className, {
        selectable: !!htmlProps.onClick,
        active: isSelected
      })}
    >
      <div>
        <div className="federated-header">
          <div>
            <img
              alt=""
              className="img-icon mr-3"
              src={federated.thumbnailUrl}
            />
          </div>
          <span className="subject">
            {hasPreview && previewType === "title" ? (
              <SearchResultPreview previewSnippet={previewSnippet} />
            ) : (
              federated.title
            )}
          </span>
          {showActionIcons && (
            <div className="federated-header-action-icons">
              <SearchResultCardActionIcons
                searchResult={federated as ICompositeResult}
              />
            </div>
          )}
        </div>
        {hasPreview && previewType === "body" && (
          <div className="federated-description">
            <SearchResultPreview previewSnippet={previewSnippet} />
          </div>
        )}
      </div>
    </div>
  );
};

export { FederatedSearchResult };
