import { ICustomFieldMapping } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";

async function fetchCustomFieldMappings(): Promise<ICustomFieldMapping[]> {
  const url = `${getBackendApiUrl()}/ticket-sources/fields/`;

  return request<ICustomFieldMapping[]>(url);
}

export { fetchCustomFieldMappings };
