import { INotionSource } from "@aptedge/lib-ui/src/types/entities";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

type SliceStateType = {
  dataSource: INotionSource | null;
};

const initialState: SliceStateType = {
  dataSource: null
};

const notionArticleSlice = createSlice({
  name: "notionArticle",
  initialState,
  reducers: {
    setNotionTapData: (state, action: PayloadAction<INotionSource | null>) => {
      state.dataSource = action.payload;
    }
  }
});

export const { setNotionTapData } = notionArticleSlice.actions;

export default notionArticleSlice.reducer;
