import {
  SEARCH_RESULT_TYPE,
  SearchTelemetryEvent,
  ICompositeResult,
  ISearchFilter,
  SearchFilterSubType
} from "@aptedge/lib-ui/src/types/entities";
import { GTM_EVENTS, dataLayerPush } from "@aptedge/lib-ui/src/utils/event";
import {
  calculatePosition,
  generateTopSearchResults
} from "@aptedge/lib-ui/src/utils/generator";

interface WebInteractionEvent {
  event: string;
  search_result_type: string;
  interaction_type: string;
  interaction_description: string;
  integration_type: string;
  id: string | number;
}

const PER_PAGE = 10;

export const triggerWebSearchPaginationEvent = (
  query: string,
  filter: string,
  page: number,
  totalSearchResults?: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  searchResults?: ICompositeResult[]
): void => {
  dataLayerPush({
    event: GTM_EVENTS.GTM_WEB_SEARCH_PAGINATION,
    data: {
      search_query: query,
      search_context: filter,
      page: page,
      total_search_results: totalSearchResults,
      top_search_results: generateTopSearchResults(searchResults)
    }
  });
};

const createWebInteractionEvent = (
  searchType: string,
  description: string,
  sourceType: string,
  searchResultId: number | string,
  eventObj: SearchTelemetryEvent
): void => {
  triggerWebInteractionEvent(
    {
      event: "web-search-result-interaction",
      search_result_type: searchType,
      interaction_type: "click_to_open",
      interaction_description: description,
      integration_type: sourceType,
      id: searchResultId
    },
    eventObj
  );
};

const triggerWebInteractionEventBySourceType = (
  result: ICompositeResult,
  searchQuery: string,
  searchResults: ICompositeResult[],
  totalSearchResults: number,
  idx: number,
  page: number,
  searchFilter: ISearchFilter,
  searchFilterSubType: SearchFilterSubType
): void => {
  const eventObj: SearchTelemetryEvent = {
    search_query: searchQuery,
    top_search_results: generateTopSearchResults(searchResults),
    total_search_results: totalSearchResults,
    search_result_position: idx,
    page: page,
    search_filter: searchFilter,
    search_sub_filter: searchFilterSubType
  };
  switch (result.type) {
    case SEARCH_RESULT_TYPE.EDGE:
      createWebInteractionEvent(
        SEARCH_RESULT_TYPE.EDGE,
        `Clicked to open Edge #${result.id}.`,
        "",
        result.id,
        eventObj
      );
      break;
    case SEARCH_RESULT_TYPE.TICKET:
      createWebInteractionEvent(
        SEARCH_RESULT_TYPE.TICKET,
        `Clicked to open Ticket #${result.id}.`,
        "",
        result.id,
        eventObj
      );
      break;
    case SEARCH_RESULT_TYPE.ARTICLE:
      createWebInteractionEvent(
        SEARCH_RESULT_TYPE.ARTICLE,
        `Clicked to open Article.`,
        result.sourceType,
        result.id,
        eventObj
      );
      break;
    case SEARCH_RESULT_TYPE.ISSUE:
      createWebInteractionEvent(
        SEARCH_RESULT_TYPE.ISSUE,
        `Clicked to open Issue.`,
        result.sourceType,
        result.id,
        eventObj
      );
      break;
    case SEARCH_RESULT_TYPE.SOCIAL:
      createWebInteractionEvent(
        SEARCH_RESULT_TYPE.SOCIAL,
        `Clicked to open Social.`,
        result.sourceType,
        result.id,
        eventObj
      );
      break;
    case SEARCH_RESULT_TYPE.DOCUMENT:
      createWebInteractionEvent(
        SEARCH_RESULT_TYPE.DOCUMENT,
        `Clicked to open Document #${result.id}.`,
        result.sourceType,
        result.id,
        eventObj
      );
      break;
    case SEARCH_RESULT_TYPE.GENERATED_KNOWLEDGE:
      createWebInteractionEvent(
        SEARCH_RESULT_TYPE.GENERATED_KNOWLEDGE,
        `Clicked to open Generated Knowledge #${result.id}.`,
        result.sourceType,
        result.id,
        eventObj
      );
      break;
    case SEARCH_RESULT_TYPE.FEDERATED:
      createWebInteractionEvent(
        SEARCH_RESULT_TYPE.FEDERATED,
        `Clicked to open Federated #${result.id}.`,
        result.sourceType,
        result.id,
        eventObj
      );
      break;
    default:
      break;
  }
};

const triggerWebInteractionEvent = (
  webEvent: WebInteractionEvent,
  telemetryEvent: SearchTelemetryEvent
): void => {
  const {
    event,
    search_result_type,
    integration_type,
    interaction_description,
    interaction_type,
    id
  } = webEvent;
  const {
    search_query,
    search_result_position,
    top_search_results,
    total_search_results,
    page,
    search_filter,
    search_sub_filter
  } = telemetryEvent;
  const search_result_position_computed = search_query
    ? search_result_position
    : 0;
  dataLayerPush({
    event,
    data: {
      search_result_type,
      interaction_type,
      interaction_description,
      integration_type,
      search_result_id: search_query ? id : 0,
      search_query: search_query,
      total_search_results: search_query ? total_search_results : 0,
      top_search_results: search_query ? top_search_results : "",
      search_result_position:
        search_query &&
        calculatePosition(search_result_position_computed, page, PER_PAGE),
      search_result_position_on_page: search_result_position_computed,
      page,
      search_filter,
      search_sub_filter
    }
  });
};

export { triggerWebInteractionEventBySourceType };
