import { FilterWrapper } from "@aptedge/lib-ui/src/components/Filter/FilterWrapper";
import {
  IQueryFilter,
  IQueryFilterDefinition,
  IQueryFilterId
} from "@aptedge/lib-ui/src/components/Filter/types";
import React, { useContext } from "react";
import { SavedQueriesContext } from "../../context/SavedQueriesContext";

type FilterProps = Omit<
  React.ComponentPropsWithoutRef<typeof FilterWrapper>,
  "id" | "icon" | "label" | "operators" | "children"
>;

type Props = {
  spec: IQueryFilterDefinition[];
  filters?: IQueryFilter[];
  pinned?: IQueryFilterId[];
  filtersMap: { [key: string]: React.ComponentType<FilterProps> };
  onChange?: (filters: IQueryFilter[]) => void;
};

function Filters(props: Props): React.ReactElement {
  const { spec, filters = [], pinned, filtersMap, onChange } = props;
  const saved = useContext(SavedQueriesContext);

  const displayedFilters = [...filters];

  if (pinned) {
    pinned.forEach((p) => {
      const pinnedSpec = spec.find((f) => f.id === p);
      const match = filters.findIndex((f) => f.id === p);

      if (match === -1 && pinnedSpec) {
        const operator = (pinnedSpec as IQueryFilterDefinition).operators[0];
        displayedFilters.unshift({ id: p, operator, values: [] });
      }
    });
  }

  const handleChange = (filter: IQueryFilter, idx: number): void => {
    const match = displayedFilters[idx];

    if (!match || match.id !== filter.id) {
      return;
    }

    const newFilters = displayedFilters.slice();
    newFilters.splice(idx, 1, filter);

    if (!saved.editing) {
      saved.setCurrentSavedQuery(undefined);
    }

    if (!!onChange) {
      onChange(newFilters);
    }
  };

  const handleRemove = (filter: IQueryFilter, idx: number): void => {
    const match = displayedFilters[idx];

    if (!match || match.id !== filter.id) {
      return;
    }

    const newFilters = displayedFilters.slice();
    newFilters.splice(idx, 1);

    if (!saved.editing) {
      saved.setCurrentSavedQuery(undefined);
    }

    if (!!onChange) {
      onChange(newFilters);
    }
  };

  return (
    <React.Fragment>
      {displayedFilters.map((f, idx) => {
        const Filter = filtersMap[f.id] || null;
        const isPinned = pinned?.find((p) => p === f.id);

        if (!Filter) return null;

        return (
          <Filter
            key={`${f.id}-${f.operator}-${idx}`}
            value={f}
            error={!isPinned && !f?.values}
            onChange={(newValue) => handleChange(newValue, idx)}
            onRemove={() => handleRemove(f, idx)}
          />
        );
      })}
    </React.Fragment>
  );
}

export { Filters };
