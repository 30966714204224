import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { ModalFooter } from "@aptedge/lib-ui/src/components/Modal/Modal";
import {
  NoData,
  NoDataMessage
} from "@aptedge/lib-ui/src/components/NoData/NoData";
import { TextInput } from "@aptedge/lib-ui/src/components/TextInput/TextInput";
import { useDebounce } from "@aptedge/lib-ui/src/hooks/useDebounce";
import {
  IEdgeInfo,
  IEdgeSearchResult
} from "@aptedge/lib-ui/src/types/entities";
import pluralize from "pluralize";
import React, { useRef, useState } from "react";
import { useQuery } from "react-query";
import { WebCacheKey } from "../../../clients/cache";
import { edgeSearch } from "../../../clients/CompositeSearch/edgeSearch";
import { EdgeSearchResult } from "../../../components/EdgeSearchResult/EdgeSearchResult";
import WithLoading from "../../../components/WithLoading/WithLoading";

interface Props {
  edge: IEdgeInfo;
  selectedEdges: IEdgeSearchResult[];
  setSelectedEdges: React.Dispatch<React.SetStateAction<IEdgeSearchResult[]>>;
  onContinue: () => void;
}

function MergeEdgeModalSearch(props: Props): React.ReactElement {
  const { edge, setSelectedEdges, onContinue, selectedEdges } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const [search, setSearch] = useState<string>("");
  const { debouncedValue: debouncedSearch } = useDebounce(search, 300);

  const searchEdgesQuery = useQuery(
    [WebCacheKey.EDGE_SEARCH, debouncedSearch],
    () =>
      edgeSearch({
        q: debouncedSearch
      }),
    { enabled: !!debouncedSearch }
  );

  const searchResults =
    searchEdgesQuery.data
      ?.filter((e) => e.id !== edge.id)
      .filter((e) => selectedEdges.findIndex((s) => s.id === e.id) === -1) ||
    [];

  return (
    <>
      <p className="disclaimer">
        Your selected source Edges will be archived and merged into EDGE-
        {edge.id}. Merging moves all Examples from the source Edges into the
        target Edge. This action cannot be undone.
      </p>
      <div className="merge-edge-content">
        <h5>Search</h5>
        <TextInput
          ref={inputRef}
          autoFocus
          placeholder={`Search for Edges to merge into EDGE-${edge.id}`}
          value={search}
          onChange={(e) => setSearch(e.currentTarget.value)}
          onClear={() => setSearch("")}
        />
        {!!selectedEdges.length && (
          <div className="edges-selected">
            <h5>Selected</h5>
            <div className="list-group">
              {selectedEdges.map((edge, idx) => (
                <EdgeSearchResult
                  key={idx}
                  edge={edge}
                  isSelected={true}
                  showHighlight={false}
                  onClick={() =>
                    setSelectedEdges((edges) =>
                      edges.filter((e) => e.id !== edge.id)
                    )
                  }
                />
              ))}
            </div>
          </div>
        )}
        <WithLoading isLoading={searchEdgesQuery.isLoading}>
          {!!search && !!searchEdgesQuery.data && (
            <div className="edges-selected">
              <h5>Search Results</h5>
              {!!searchResults.length ? (
                <div className="list-group">
                  {searchResults.map((edge, idx) => (
                    <EdgeSearchResult
                      key={idx}
                      edge={edge}
                      isSelected={false}
                      showHighlight={false}
                      onClick={() =>
                        setSelectedEdges((edges) => [...edges, edge])
                      }
                    />
                  ))}
                </div>
              ) : (
                <NoData message={NoDataMessage.SEARCH_EDGES} />
              )}
            </div>
          )}
        </WithLoading>
      </div>
      <ModalFooter>
        <div className="d-flex justify-content-between align-items-center">
          {selectedEdges.length > 0 ? (
            <>
              <div>
                {selectedEdges.length} {pluralize("Edge", selectedEdges.length)}{" "}
                selected
              </div>
              <Button color="primary" onClick={onContinue}>
                Continue
              </Button>
            </>
          ) : (
            <>
              <span className="text-muted">No other edges selected</span>
              <span className="text-muted">Select edges to merge</span>
            </>
          )}
        </div>
      </ModalFooter>
    </>
  );
}

export { MergeEdgeModalSearch };
