import { getBackendApiUrl } from "../../config";
import { ContentType } from "../../types/http";
import { deleteRequest } from "../../utils/request";
import { deleteInit } from "../utils/buildRequest";

function deleteCssConfig(key: string, value: string): Promise<void> {
  const url = `${getBackendApiUrl()}/config/${key}/`;
  const options = deleteInit(ContentType.APPLICATION_JSON, { value });
  return deleteRequest(url, options);
}

export { deleteCssConfig };
