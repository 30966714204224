import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";
import { post } from "../utils/buildRequest";

async function markNotificationsRead(): Promise<null> {
  const url = `${getBackendApiUrl()}/users/notifications/mark-all-read/`;

  return request(url, post({}));
}

export { markNotificationsRead };
