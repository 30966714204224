import { Modal, ModalWidth } from "@aptedge/lib-ui/src/components/Modal/Modal";
import {
  IEdgeInfo,
  IEdgeSearchResult,
  ITicketListing
} from "@aptedge/lib-ui/src/types/entities";
import React, { ComponentProps, useState } from "react";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { migrateEdgeTickets } from "../../../clients/Edges/migrateEdgeTickets";
import { toEdgePath } from "../../../routes/toPath";
import { IMigrateEdgeTicketsArgs } from "../../../types/clients";
import { EdgeMigrateModalConfirm } from "./EdgeMigrateModalConfirm";
import { EdgeMigrateModalExec } from "./EdgeMigrateModalExec";
import { EdgeMigrateModalSearch } from "./EdgeMigrateModalSearch";
import { EdgeMigrateModalSelect } from "./EdgeMigrateModalSelect";
import "./EdgeTicketsMigrateModal.scss";
import { MigrateTicketModal } from "./types";

interface Props
  extends Omit<ComponentProps<typeof Modal>, "title" | "width" | "onClose"> {
  edge: IEdgeInfo;
  onClose: () => void;
}

function EdgeTicketsMigrateModal(props: Props): React.ReactElement {
  const { edge, onClose, ...rest } = props;

  const history = useHistory();

  const [currentPage, setCurrentPage] = useState<MigrateTicketModal>(
    MigrateTicketModal.ONE
  );
  const [selectedEdge, setSelectedEdge] = useState<IEdgeSearchResult>();
  const [selectedTickets, setSelectedTickets] = useState<ITicketListing[]>([]);

  const handleClose = (): void => {
    setSelectedEdge(undefined);
    setSelectedTickets([]);
    setCurrentPage(MigrateTicketModal.ONE);
    onClose();
  };

  const migrateTicketsClient = useMutation<
    IEdgeInfo,
    Error,
    IMigrateEdgeTicketsArgs
  >(migrateEdgeTickets, {
    onSuccess: (newEdge) => {
      handleClose();
      history.push(toEdgePath(newEdge.id));
    }
  });

  const allowClosing = currentPage !== MigrateTicketModal.FOUR;

  return (
    <Modal
      title="Migrate Examples"
      className="edge-migrate"
      width={ModalWidth.LARGE}
      showClose={allowClosing}
      escClose={allowClosing}
      onClose={allowClosing ? handleClose : undefined}
      {...rest}
    >
      {rest.isOpen && (
        <>
          {
            {
              [MigrateTicketModal.ONE]: (
                <EdgeMigrateModalSelect
                  edge={edge}
                  selectedTickets={selectedTickets}
                  setSelectedTickets={setSelectedTickets}
                  setCurrentPage={setCurrentPage}
                />
              ),
              [MigrateTicketModal.TWO]: (
                <EdgeMigrateModalSearch
                  edge={edge}
                  setCurrentPage={setCurrentPage}
                  setSelectedEdge={setSelectedEdge}
                  selectedTickets={selectedTickets}
                />
              ),
              [MigrateTicketModal.THREE]: (
                <EdgeMigrateModalConfirm
                  selectedEdge={selectedEdge}
                  selectedTickets={selectedTickets}
                  setCurrentPage={setCurrentPage}
                />
              ),
              [MigrateTicketModal.FOUR]: selectedEdge && (
                <EdgeMigrateModalExec
                  client={migrateTicketsClient}
                  selectedEdge={selectedEdge}
                  selectedTickets={selectedTickets}
                  setCurrentPage={setCurrentPage}
                  sourceEdge={edge}
                />
              )
            }[currentPage]
          }
        </>
      )}
    </Modal>
  );
}

export { EdgeTicketsMigrateModal };
