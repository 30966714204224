import { IOneloginSource } from "@aptedge/lib-ui/src/types/entities";
import { getBackendUrl } from "../../config";
import { request } from "../../utils/request";

function fetchOneloginSource(): Promise<IOneloginSource> {
  const url = `${getBackendUrl()}/onelogin/tap`;
  return request(url);
}

export { fetchOneloginSource };
