import { FilterWrapper } from "@aptedge/lib-ui/src/components/Filter/FilterWrapper";
import { IQueryFilter } from "@aptedge/lib-ui/src/components/Filter/types";
import { MultiSelect } from "@aptedge/lib-ui/src/components/MultiSelect/MultiSelect";
import React from "react";
import { useThemes } from "../../hooks/clients/useThemes";
import { THEME_FILTER } from "./data";

type Props = {
  value?: IQueryFilter;
  onChange: (val: IQueryFilter) => void;
};

function ThemeFilter(props: Props): React.ReactElement {
  const themesQuery = useThemes();

  const themes = themesQuery.data ?? [];
  const sortedThemes = themes
    .slice()
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <FilterWrapper {...THEME_FILTER} {...props}>
      {({ values, onChange }) => {
        const selected = values
          ? themes.filter((t) => values.includes(String(t.id)))
          : undefined;

        return (
          <MultiSelect
            name="theme-filter"
            data={sortedThemes}
            value={selected}
            getOptionLabel={(t) => t.name}
            onChange={(themes) => onChange(themes.map((t) => String(t.id)))}
          />
        );
      }}
    </FilterWrapper>
  );
}

export { ThemeFilter };
