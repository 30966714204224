enum FontSize {
  MINI = "10px",
  XSMALL = "12px",
  SMALL = "13px",
  REGULAR = "14px",
  MEDIUM = "16px",
  LARGE = "18px",
  XLARGE = "24px",
  TITLE = "32px"
}

enum FontWeight {
  LIGHT = 300,
  REGULAR = 400,
  MEDIUM = 500,
  SEMI_BOLD = 600,
  BOLD = 700
}

export { FontSize, FontWeight };
