import {
  ISnowKnowledgeArticleSourceRequest,
  ISalesforceKnowledgeArticleSourceData
} from "@aptedge/lib-ui/src/types/entities";
import { getBackendUrl } from "../../config";
import { request } from "../../utils/request";
import { post } from "../utils/buildRequest";

async function createSNowArticleSource(
  args: ISnowKnowledgeArticleSourceRequest
): Promise<ISalesforceKnowledgeArticleSourceData> {
  const url = `${getBackendUrl()}/servicenow/collect/`;

  const options: RequestInit = post(args);

  return request(url, options);
}

export { createSNowArticleSource };
