import { AeIcons } from "@aptedge/lib-ui/src/components/Icon/AeIcons";
import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import { MarkdownContent } from "@aptedge/lib-ui/src/components/MarkdownContent/MarkdownContent";
import { useSearchPreview } from "@aptedge/lib-ui/src/hooks/useSearchPreview";
import {
  ICompositeResult,
  IEdgeSearchResult
} from "@aptedge/lib-ui/src/types/entities";
import classNames from "classnames";
import { LabelChip } from "../LabelChip/LabelChip";
import { LabelChipList } from "../LabelChip/LabelChipList";
import SearchCardTimings from "../SearchCardTimings/SearchCardTimings";
import SearchResultCardActionIcons from "../SearchResultCardActionIcons/SearchResultCardActionIcons";
import { SearchResultPreview } from "../SearchResultPreview/SearchResultPreview";
import "./EdgeSearchResult.scss";

interface Props
  extends Omit<
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >,
    "ref"
  > {
  edge: IEdgeSearchResult;
  className?: string;
  isSelected?: boolean;
  showIcons?: boolean;
  showHighlight: boolean;
  showActionIcons?: boolean;
}

const EdgeSearchResult: React.FC<Props> = ({
  edge,
  isSelected,
  className,
  showIcons = true,
  showHighlight,
  showActionIcons,
  ...htmlProps
}) => {
  const { hasPreview, previewSnippet, previewType } = useSearchPreview({
    ...edge,
    type: "edge"
  });
  return (
    <div
      className={classNames("edge-search-result", className, {
        active: isSelected,
        selectable: !!htmlProps.onClick
      })}
      data-testid={`edge-result-${edge.id}`}
      {...htmlProps}
    >
      <div className="w-100">
        <div className="title">
          <>
            <div>
              <h4>
                <AeIcons.EdgeSR className="p-0 mr-2" />
                <span>
                  {`EDGE-${edge.id}`}:&nbsp;
                  {showHighlight ? (
                    <SearchResultPreview
                      previewSnippet={
                        hasPreview && previewType === "name"
                          ? previewSnippet
                          : edge.name
                      }
                    />
                  ) : (
                    edge.name
                  )}
                </span>
              </h4>
              {edge.archived && <div className="archived-label">Archived</div>}
            </div>
            <div className="actions">
              {showIcons && (isSelected ? <Icons.Times /> : <Icons.Plus />)}
            </div>
          </>
          <>
            {!!edge?.ticketCount && edge.ticketCount > 0 && (
              <div className="ticket-details">
                <AeIcons.Tickets />
                <span className="tickets" data-testid="ticketCount">
                  {edge.ticketCount}
                </span>
              </div>
            )}
          </>
          {showActionIcons && (
            <div className="action-icons">
              <SearchResultCardActionIcons
                searchResult={edge as ICompositeResult}
              />
            </div>
          )}
        </div>
        <div className="edge-labels">
          <LabelChipList>
            {edge.labels?.map((label) => (
              <LabelChip key={`${label.id}-${label.type}`} label={label} />
            ))}
          </LabelChipList>
        </div>
        <div className="description">
          {hasPreview && previewType === "content" ? (
            <SearchResultPreview previewSnippet={previewSnippet} />
          ) : (
            <MarkdownContent
              content={edge.richSummary?.content}
              maxLength={120}
              disableShowMore={true}
            />
          )}
        </div>
        <div className="info">
          <SearchCardTimings
            createdOn={edge.createdOn}
            updatedOn={edge.updatedOn}
          />
          <div className="status">
            <span className="dot" />
            <span className="status-text">{edge?.status}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export { EdgeSearchResult };
