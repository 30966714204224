import { Interval } from "luxon";

export enum RelativeRange {
  THREE_DAYS = "3D",
  ONE_WEEK = "1W",
  TWO_WEEKS = "2W",
  ONE_MONTH = "1M",
  THREE_MONTHS = "3M"
}

export enum RelativeLabel {
  THREE_DAYS = "Past 3 days",
  ONE_WEEK = "Past week",
  TWO_WEEKS = "Past 2 weeks",
  ONE_MONTH = "Past month",
  THREE_MONTHS = "Past 3 months"
}

export enum DateType {
  ABSOLUTE = "absolute",
  RELATIVE = "relative"
}

export type DateRange =
  | {
      type: DateType.ABSOLUTE;
      value?: Interval;
    }
  | { type: DateType.RELATIVE; value: RelativeRange };
