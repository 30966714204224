import {
  LocalStorageKeys,
  useLocalStorage
} from "@aptedge/lib-ui/src/hooks/useLocalStorage";
import {
  EntityType,
  IEdgeInfo,
  IEdgeSearchResult
} from "@aptedge/lib-ui/src/types/entities";
import pick from "lodash/pick";
import { useCallback } from "react";
import * as Yup from "yup";

const MAX_RECENTS = 3;

function useRecentEdges(): [
  IEdgeSearchResult[] | null,
  (val: IEdgeInfo) => void
] {
  const [recents, setRecents] = useLocalStorage<IEdgeSearchResult[]>(
    LocalStorageKeys.RECENT_EDGES,
    Yup.array().of(
      Yup.object({
        id: Yup.number().required(),
        name: Yup.string().required(),
        labels: Yup.array()
          .of(
            Yup.object({
              id: Yup.number(),
              name: Yup.string(),
              type: Yup.mixed().oneOf(Object.values(EntityType)).required()
            })
          )
          .defined(),
        richSummary: Yup.object({
          content: Yup.string().required(),
          version: Yup.string()
        }).nullable(),
        createdOn: Yup.number().required(),
        updatedOn: Yup.number().required(),
        status: Yup.string().required(),
        ticketCount: Yup.number(),
        archived: Yup.boolean().required()
      })
    )
  );

  const addRecentEdge = useCallback(
    (edge: IEdgeInfo): void => {
      const previousState = recents ? [...recents] : [];

      const edgeResult: IEdgeSearchResult = pick(
        edge,
        "id",
        "ticketCount",
        "name",
        "richSummary",
        "labels",
        "createdOn",
        "archived",
        "updatedOn",
        "status",
        "offset"
      );
      const idx = previousState.findIndex((val) => val.id === edgeResult.id);

      // Sort labels for isEqual check.
      edgeResult.labels.sort((a, b) => a.id - b.id);

      if (idx > -1) {
        previousState.splice(idx, 1);
      }

      setRecents([edgeResult, ...previousState.slice(0, MAX_RECENTS - 1)]);
    },
    [recents, setRecents]
  );

  return [recents, addRecentEdge];
}

export { useRecentEdges };
