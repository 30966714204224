import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import * as React from "react";
import ReactMarkdown from "react-markdown";
import styles from "./ArticleMarkdownDisplay.module.scss";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const remarkGfm = require("remark-gfm").default;

interface ArticleMarkdownDisplayProps {
  articleBody: string;
}

const ArticleMarkdownDisplay: React.FC<ArticleMarkdownDisplayProps> = ({
  articleBody
}) => {
  const { flags } = useFlags();
  const formattedArticleBody = articleBody.replace(/\\n/g, "  \n");
  return (
    <div className={styles.container}>
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        components={{
          a: (props) => {
            const handleClick = (event: React.MouseEvent): void => {
              if (flags.kbGenPublishProcess) event.stopPropagation();
            };
            return (
              <a
                href={props.href}
                className={styles.previewLink}
                target="_blank"
                rel="noreferrer"
                onClick={handleClick}
              >
                {props.children[0]}
              </a>
            );
          }
        }}
      >
        {formattedArticleBody}
      </ReactMarkdown>
    </div>
  );
};

export { ArticleMarkdownDisplay };
