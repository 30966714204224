import { IEdgeInfo } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { IFetchEdgeArgs } from "../../types/clients";
import { request } from "../../utils/request";

async function fetchEdgeById(args: IFetchEdgeArgs): Promise<IEdgeInfo> {
  const url = `${getBackendApiUrl()}/edges/${args.edgeId}/`;

  return request(url);
}

export { fetchEdgeById };
