export const RATE_LIMIT_EXCEEDED_MARKDOWN =
  "**<We detected unusual activity from your account. Please try again later.>**";

export const UNKNOWN_ERROR_MARKDOWN = "**<Error.>**";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function toUIErrorMessage(error: any): string {
  if (error?.code === 429) {
    return RATE_LIMIT_EXCEEDED_MARKDOWN;
  }
  return UNKNOWN_ERROR_MARKDOWN;
}
