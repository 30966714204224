import React from "react";
import { WebCacheKey } from "../../../clients/cache";
import { createFederatedURLPrefixConfig } from "../../../clients/FederatedSearchConfig/createFederatedURLPrefixConfig";
import { deleteFederatedURLPrefixConfig } from "../../../clients/FederatedSearchConfig/deleteFederatedURLPrefixConfig";
import { fetchFederatedURLPrefixConfig } from "../../../clients/FederatedSearchConfig/fetchFederatedURLPrefixConfig";
import { GetDataErrorBoundary } from "../../../components/ErrorHandler/GetDataErrorBoundary";
import { SingleTextItemList } from "../SingleTextItemList";

function FederatedSearchSettings(): React.ReactElement {
  return (
    <>
      <section className="row mb-5">
        <div className="col-md-5 p-2 pr-3">
          <h4 className="text-uppercase">Federated Search</h4>
          <p>Configure a set of URL prefixes to be searched live on the web.</p>
        </div>
        <div className="col-md-7 p-2 pl-3">
          <GetDataErrorBoundary message="Unable to get federated search URL prefixes.">
            <SingleTextItemList
              cacheKey={WebCacheKey.FEDERATED_SEARCH_URL_PREFIXES}
              subtitle="Add URL prefixes that AptEdge should search."
              createEmailConfig={createFederatedURLPrefixConfig}
              deleteEmailConfig={deleteFederatedURLPrefixConfig}
              fetchEmailConfig={fetchFederatedURLPrefixConfig}
            />
          </GetDataErrorBoundary>
        </div>
      </section>
      <hr className="mt-4" />
    </>
  );
}

export { FederatedSearchSettings };
