import { MarkdownContent } from "@aptedge/lib-ui/src/components/MarkdownContent/MarkdownContent";
import { IEdgeAuditLogSummaryChange } from "@aptedge/lib-ui/src/types/entities";
import React from "react";
import { ArrowTransition } from "./ArrowTransition";
import { SummarySentence } from "./SummarySentence";
import { WithAuditLog } from "./WithAuditLog";
import type { AuditDetailsFC, AuditSummaryFC } from "./WithAuditLog";

const Details: AuditDetailsFC<IEdgeAuditLogSummaryChange> = (props) => {
  const { log } = props;
  const { newText, oldText } = log.data;

  const from = oldText ? <MarkdownContent content={oldText.content} /> : null;
  const to = newText ? <MarkdownContent content={newText.content} /> : null;

  return (
    <div className="audit-log-summary-change">
      <ArrowTransition from={from} to={to} />
    </div>
  );
};

const Summary: AuditSummaryFC<IEdgeAuditLogSummaryChange> = (props) => {
  const { author, log } = props;
  const { VERBS, OBJECTS } = SummarySentence;

  return (
    <SummarySentence
      subject={author}
      verb={VERBS.UPDATED}
      object={OBJECTS.SUMMARY}
      displayText={log?.displayText ?? ""}
    />
  );
};

Details.displayName = "AuditSummaryChangeDetails";
const AuditLogSummaryChange = WithAuditLog(Details, Summary);

export { AuditLogSummaryChange };
