import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import { Truthy } from "@aptedge/lib-ui/src/utils/utils";
import { PaginationSortOption } from "../components/PaginationSort/PaginationSort";

const DEFAULT_SORT: PaginationSortOption = {
  id: "createdOn",
  label: "Newest",
  desc: true
};

function useTicketSort(): [PaginationSortOption, PaginationSortOption[]] {
  const { flags } = useFlags();
  const sortOption = [
    DEFAULT_SORT,
    { id: "createdOn", label: "Oldest", desc: false },
    { id: "updatedOn", label: "Last Updated", desc: true }
  ].filter(Truthy);
  if (flags.sentimentScore) {
    sortOption.push({
      id: "frustration",
      label: "Most Frustrated",
      desc: true
    });
  }
  return [DEFAULT_SORT, sortOption];
}

export { useTicketSort };
