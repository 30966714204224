import classNames from "classnames";
import { FC } from "react";
import styles from "./styles.module.scss";

interface Props {
  title?: string;
  description?: string;
  className?: string;
  titleClassName?: string;
  subTitle?: string;
}

const ConfigInput: FC<Props> = ({
  title,
  description = "",
  children,
  className,
  titleClassName,
  subTitle
}) => {
  return (
    <div className={classNames(styles.inputContainer, className)}>
      <div>
        <div className={styles.titleContainer}>
          {title && (
            <label className={classNames(styles.label, titleClassName)}>
              {title}
            </label>
          )}
          {subTitle && <span className={styles.subTitle}>{subTitle}</span>}
        </div>
        {description && <p className={styles.desc}>{description}</p>}
      </div>
      {children}
    </div>
  );
};

export default ConfigInput;
