import { addCommas } from "./value";

/**
 * Converts a number to a rounded approximate number, and adds commas for every 3 number characters
 */
export function toApproximateStringFromNumber(n: number): string {
  let divisor = 1;

  if (n > 10000) {
    divisor = 1000;
  } else if (n > 1000) {
    divisor = 100;
  } else if (n > 500) {
    divisor = 50;
  } else if (n > 100) {
    divisor = 10;
  } else if (n > 20) {
    divisor = 5;
  } else if (n > 5) {
    divisor = 2;
  }

  const approximateTotal = Math.ceil(n / divisor) * divisor;

  return addCommas(approximateTotal);
}
