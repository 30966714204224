enum Keys {
  ARROW_UP = "ArrowUp",
  ARROW_DOWN = "ArrowDown",
  BACKSPACE = "Backspace",
  COMMAND = "Meta",
  CONTROL = "Control",
  ENTER = "Enter",
  ESCAPE = "Escape",
  TAB = "Tab",
  K = "k"
}

export { Keys };
