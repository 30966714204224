import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import { TextArea } from "@aptedge/lib-ui/src/components/TextArea/TextArea";
import { IEdgeInfo } from "@aptedge/lib-ui/src/types/entities";
import classNames from "classnames";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useMutation } from "react-query";
import * as Yup from "yup";
import { updateEdge } from "../../clients/Edges/updateEdge";
import { MutationErrorToast } from "../../components/ErrorHandler/MutationErrorToast";
import WithLoading from "../../components/WithLoading/WithLoading";
import "./EdgeTitle.scss";

interface Props {
  edge: IEdgeInfo;
  onUpdateEdge: (edge: IEdgeInfo) => void;
}

const MAX_LENGTH = 500;

const EdgeTitle: React.FC<Props> = (props) => {
  const { edge, onUpdateEdge } = props;

  const [isEditing, setIsEditing] = useState(false);

  const edgeMutation = useMutation(updateEdge, {
    onSuccess: (updatedEdge) => {
      onUpdateEdge(updatedEdge);
      setIsEditing(false);
    }
  });

  const formik = useFormik<{ title: string }>({
    validationSchema: Yup.object({
      title: Yup.string().required().max(MAX_LENGTH)
    }),
    initialValues: { title: edge.name },
    onSubmit: (formData) => {
      edgeMutation.mutate({
        edgeId: edge.id,
        patchContent: { name: formData.title }
      });
    }
  });

  const handleCancel = (): void => {
    setIsEditing(false);
    formik.resetForm();
  };

  return (
    <React.Fragment>
      <div className="edge-title">
        {isEditing ? (
          <form onSubmit={formik.handleSubmit}>
            <TextArea
              name="title"
              value={formik.values.title}
              onChange={formik.handleChange}
              maxLength={MAX_LENGTH}
              resizable={false}
              autoFocus
            />
            <div className="edge-title-buttons">
              <Button
                color="secondary"
                onClick={handleCancel}
                disabled={edgeMutation.isLoading}
                type="reset"
              >
                Cancel
              </Button>
              <Button
                disabled={
                  !formik.values.title.trim() ||
                  formik.values.title === edge.name ||
                  edgeMutation.isLoading
                }
                color="primary"
                type="submit"
              >
                Save
              </Button>
            </div>
          </form>
        ) : (
          <WithLoading
            isLoading={edgeMutation.isLoading}
            fallback={<Spinner />}
            type="overlay"
          >
            <h2
              className={classNames("edge-name", { disabled: edge.archived })}
              onClick={() => {
                if (!edge.archived) setIsEditing(true);
              }}
            >
              {edge.name}
            </h2>
          </WithLoading>
        )}
      </div>
      <MutationErrorToast
        error={edgeMutation.error}
        resetError={edgeMutation.reset}
      />
    </React.Fragment>
  );
};

export { EdgeTitle };
