import React from "react";
import { GetDataErrorBoundary } from "../../../components/ErrorHandler/GetDataErrorBoundary";
import { KnowledgeBaseExclusions } from "./KnowledgeBaseExclusions";
import { KnowledgeBaseList } from "./KnowledgeBaseList";

function KnowledgeBaseSettings(): React.ReactElement {
  return (
    <>
      <section className="row mb-5">
        <div className="col-md-5 p-2 pr-3">
          <h4 className="text-uppercase">Knowledge Urls</h4>
          <p>
            Configure a set of URLs that represent your company&apos;s knowledge
            references.
          </p>
        </div>
        <div className="col-md-7 p-2 pl-3">
          <GetDataErrorBoundary message="Unable to get Knowledge URLs.">
            <KnowledgeBaseList />
          </GetDataErrorBoundary>
          <GetDataErrorBoundary message="Unable to get Knowledge exclusions.">
            <KnowledgeBaseExclusions />
          </GetDataErrorBoundary>
        </div>
      </section>
      <hr className="mt-4" />
    </>
  );
}

export { KnowledgeBaseSettings };
