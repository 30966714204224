import { IEdgeAuditLogArchiving } from "@aptedge/lib-ui/src/types/entities";
import React from "react";
import { ArrowTransition } from "./ArrowTransition";
import { SummarySentence } from "./SummarySentence";
import { WithAuditLog } from "./WithAuditLog";
import type { AuditDetailsFC, AuditSummaryFC } from "./WithAuditLog";

const ARCHIVED = "Archived";
const ACTIVE = "Active";

const Details: AuditDetailsFC<IEdgeAuditLogArchiving> = (props) => {
  const { log } = props;

  const previousStatus = log.data.archived ? ACTIVE : ARCHIVED;
  const currentStatus = log.data.archived ? ARCHIVED : ACTIVE;

  return (
    <div className="audit-log-archiving">
      <ArrowTransition
        from={<span>{previousStatus}</span>}
        to={<span>{currentStatus}</span>}
      />
    </div>
  );
};

const Summary: AuditSummaryFC<IEdgeAuditLogArchiving> = (props) => {
  const { author, log } = props;

  return (
    <SummarySentence
      subject={author}
      verb={SummarySentence.VERBS.UPDATED}
      object={SummarySentence.OBJECTS.ARCHIVE_STATUS}
      displayText={log?.displayText ?? ""}
    />
  );
};

Details.displayName = "AuditArchivingDetails";
const AuditLogArchiving = WithAuditLog(Details, Summary);

export { AuditLogArchiving };
