import { IUserListingResponse } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";
import { toQueryString } from "../utils/url";

async function fetchUsersList(args: {
  search?: string;
  page: number;
  paginate: string;
  "per-page": number;
}): Promise<IUserListingResponse> {
  const query = toQueryString({ ...args });
  let url = "";

  url = `${getBackendApiUrl()}/users/?${query}`;

  return request(url);
}
export { fetchUsersList };
