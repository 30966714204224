import { ITemplate } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";

async function fetchTemplates(): Promise<ITemplate[]> {
  const url = `${getBackendApiUrl()}/templates`;

  return request(url);
}

export { fetchTemplates };
