import {
  LocalStorageKeys,
  useLocalStorage
} from "@aptedge/lib-ui/src/hooks/useLocalStorage";
import { IJobStatus } from "@aptedge/lib-ui/src/types/entities";
import { useState } from "react";
import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { WebCacheKey } from "../../clients/cache";
import { fetchJobStatus } from "../../clients/Jobs/fetchJobStatus";

const FIVE_SECONDS = 5 * 1000;
const LOADING_STATUSES: IJobStatus[] = ["pending", "running"];

function useJobStatus(
  jobId: string,
  options?: UseQueryOptions<{ status: IJobStatus }>
): UseQueryResult<{ status: IJobStatus }> {
  const [shouldPoll, setShouldPoll] = useState(false);

  return useQuery([WebCacheKey.JOB, jobId], () => fetchJobStatus(jobId), {
    ...options,
    refetchInterval: shouldPoll ? FIVE_SECONDS : undefined,
    onSuccess: ({ status }) => {
      if (jobIsPending(status)) {
        setShouldPoll(true);
      } else {
        setShouldPoll(false);
      }
    }
  });
}

type RecentJob = { id: string };

function useRecentJob(): [RecentJob | null, (val: RecentJob | null) => void] {
  return useLocalStorage<RecentJob>(LocalStorageKeys.RECENT_JOB);
}

function jobIsPending(status: IJobStatus): boolean {
  return LOADING_STATUSES.includes(status);
}

export { useJobStatus, useRecentJob, jobIsPending };
