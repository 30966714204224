import ImageIcon from "@aptedge/lib-ui/src/components/Icon/ImageIcon";
import classNames from "classnames";
import { FC } from "react";
import styles from "./styles.module.scss";

interface IProps {
  icon: string;
  className?: string;
  sourceType: string;
}
const HeaderIcon: FC<IProps> = ({ icon, className, sourceType }) => {
  if (!icon) return <></>;
  return (
    <ImageIcon
      src={icon}
      className={classNames(className, styles.icon)}
      data-testid={`${sourceType}-icon`}
    />
  );
};

export default HeaderIcon;
