import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { Checkbox } from "@aptedge/lib-ui/src/components/Checkbox/Checkbox";
import { Chip } from "@aptedge/lib-ui/src/components/Chip/Chip";
import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import { ModalFooter } from "@aptedge/lib-ui/src/components/Modal/Modal";
import { stopPropagation } from "@aptedge/lib-ui/src/utils/eventUtils";
import { removeEmptyFilters } from "@aptedge/lib-ui/src/utils/query";
import pluralize from "pluralize";
import React, { useMemo, useRef, useState } from "react";
import Helmet from "react-helmet";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { ToastType } from "react-toastify";
import { WebCacheKey } from "../../clients/cache";
import { bulkUpdateEdges } from "../../clients/Edges/bulkUpdateEdges";
import { fetchEdges } from "../../clients/Edges/fetchEdges";
import { convertQueryStateToClientStatus } from "../../clients/utils/convertQueryStateToClientStatus";
import { toSortObject, toSortString } from "../../clients/utils/sort";
import { ConfirmationModal } from "../../components/ConfirmationModal/ConfirmationModal";
import { PageContent } from "../../components/PageContent/PageContent";
import { PaginationAnalytics } from "../../components/PaginationAnalytics/PaginationAnalytics";
import { PaginationBar } from "../../components/PaginationBar/PaginationBar";
import { Table } from "../../components/Table/Table";
import { TableBody } from "../../components/Table/TableBody";
import { TableHead } from "../../components/Table/TableHead";
import { TableRow } from "../../components/Table/TableRow";
import { Td } from "../../components/Table/Td";
import { Th } from "../../components/Table/Th";
import { useEdgeTable } from "../../components/Table/useEdgeTable";
import { Toast } from "../../components/Toast/Toast";
import { useEffectOnlyOnDeepChange } from "../../hooks/useEffectOnlyOnDeepChange";
import { toEdgePath } from "../../routes/toPath";
import { BulkEditModal } from "./BulkEditModal";
import { EdgesFilterBar } from "./EdgesFilterBar";
import { useEdgeListingParams } from "./useEdgeListingParams";
import "./EdgesListingPage.scss";

const EdgesListingPage: React.FC = () => {
  const contentRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const [queryParams, setQueryParams] = useEdgeListingParams();
  const [bulkEditModalOpen, setBulkEditModalOpen] = useState(false);
  const [archiveConfirmModalOpen, setArchiveConfirmModalOpen] = useState(false);

  const apiParams = removeEmptyFilters(queryParams);
  const currentSort = useMemo(() => toSortObject(queryParams.sortBy), [
    queryParams.sortBy
  ]);

  const edgeListingQuery = useQuery(
    [WebCacheKey.EDGE_LISTING, apiParams],
    () => fetchEdges(apiParams),
    {
      onSuccess: (response) => {
        const { items, hasNext, totalPages, total, ...rest } = response;
        setQueryParams(rest);
      }
    }
  );

  const bulkEditMutation = useMutation(bulkUpdateEdges, {
    onSettled: () => {
      setBulkEditModalOpen(false);
      setArchiveConfirmModalOpen(false);
      bulkEditMutation.reset();
      edgeListingQuery.refetch();
    }
  });

  const {
    rows,
    displayedColumns,
    selected,
    isRowSelected,
    clearSelectedRows,
    allRowsAreSelected,
    toggleRow,
    toggleAllRows,
    getTableProps
  } = useEdgeTable({
    data: edgeListingQuery.data?.items,
    isLoading: edgeListingQuery.isLoading,
    isSelectable: true
  });

  const handleEdgeClick = (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    id: number
  ): void => {
    const url = toEdgePath(id);

    if (event.metaKey || event.ctrlKey) {
      window.open(url, "_blank", "noopener,noreferrer");
    } else {
      history.push(url);
    }
  };

  const selectedEdgeIds = selected.map((e) => e.id);

  useEffectOnlyOnDeepChange(() => {
    if (contentRef.current) {
      contentRef.current.scrollIntoView({ block: "start" });
    }
  }, [queryParams]);

  return (
    <>
      <Helmet>
        <title>Edges</title>
      </Helmet>
      <div className="edge-listing">
        <PageContent ref={contentRef}>
          <div className="header-bar-container container-xxl mb-2">
            <section className="w-100">
              {!selected.length ? (
                <EdgesFilterBar
                  params={queryParams}
                  onChange={setQueryParams}
                />
              ) : (
                <div className="d-flex py-2 justify-content-end">
                  <Button
                    color="secondary"
                    className="mr-4"
                    onClick={() => clearSelectedRows()}
                  >
                    <span>Cancel</span>
                  </Button>
                  <Button
                    className="mr-3"
                    onClick={() => setArchiveConfirmModalOpen(true)}
                  >
                    <Icons.Archive />
                    <span>Archive</span>
                  </Button>
                  <Button onClick={() => setBulkEditModalOpen(true)}>
                    <Icons.Edit />
                    <span>Edit</span>
                  </Button>
                </div>
              )}
            </section>
          </div>
          <div className="edges-content">
            <div className="container-xxl">
              <PaginationAnalytics
                data={edgeListingQuery.data}
                isLoading={edgeListingQuery.isLoading}
                isApproximate={true}
              />
              <Table {...getTableProps()}>
                <TableHead>
                  <TableRow>
                    <th className="align-middle">
                      <Checkbox
                        checked={allRowsAreSelected}
                        onChange={() => toggleAllRows()}
                      />
                    </th>
                    {displayedColumns.map((col) => (
                      <Th
                        key={col.id}
                        col={col}
                        sortBy={currentSort}
                        onSortChange={(sortArgs) =>
                          setQueryParams({ sortBy: toSortString(sortArgs) })
                        }
                      />
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, idx) => (
                    <TableRow
                      onClick={(e) => handleEdgeClick(e, row.id)}
                      key={`tr-${idx}`}
                      isClickable
                      isLoading={edgeListingQuery.isLoading}
                    >
                      <td className="align-top" onClick={stopPropagation()}>
                        <Checkbox
                          checked={isRowSelected(row)}
                          onChange={() => toggleRow(row)}
                        />
                      </td>
                      {displayedColumns.map((col) => (
                        <Td key={col.id} col={col} row={row} />
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {edgeListingQuery.data && (
                <PaginationBar
                  className="mt-3"
                  page={edgeListingQuery.data.page}
                  totalPages={edgeListingQuery.data.totalPages}
                  onChange={setQueryParams}
                />
              )}
            </div>
          </div>
        </PageContent>
      </div>
      {!edgeListingQuery.isFetching && edgeListingQuery.error && (
        <Toast type={ToastType.ERROR}>Something went wrong.</Toast>
      )}
      <BulkEditModal
        edges={selected}
        isOpen={bulkEditModalOpen}
        isEditing={bulkEditMutation.isLoading}
        onClose={() => setBulkEditModalOpen(false)}
        onSave={(changes) => {
          if (Object.keys(changes).length) {
            bulkEditMutation.mutate({
              edgeIds: selectedEdgeIds,
              update: changes
            });
          }
        }}
      />
      <ConfirmationModal
        title="Archive"
        status={convertQueryStateToClientStatus(bulkEditMutation)}
        isOpen={archiveConfirmModalOpen}
        loadingTitle="Archiving..."
        onClose={() => setArchiveConfirmModalOpen(false)}
      >
        <p className="mb-2">
          Are you sure you want to archive {pluralize("this", selected.length)}{" "}
          {pluralize("Edge", selected.length)}?
        </p>
        <div className="d-flex flex-wrap">
          {selectedEdgeIds.map((id) => (
            <Chip className="mr-1 mb-1" key={id} label={`EDGE-${id}`} />
          ))}
        </div>
        <p className="text-muted mt-3">
          You can find {selected.length > 1 ? "them" : "it"} later using the
          archived filter.
        </p>
        <ModalFooter>
          <div className="d-flex justify-content-end">
            <Button
              color="secondary"
              className="mr-2"
              onClick={() => setArchiveConfirmModalOpen(false)}
            >
              Cancel
            </Button>
            <Button
              data-testid="delete button"
              onClick={() =>
                bulkEditMutation.mutate({
                  edgeIds: selectedEdgeIds,
                  update: {
                    archived: true
                  }
                })
              }
            >
              Archive
            </Button>
          </div>
        </ModalFooter>
      </ConfirmationModal>
    </>
  );
};

export { EdgesListingPage };
