import {
  TWENTY_FOUR_HOURS_MILLIS,
  TWENTY_MINUTES_MILLIS
} from "@aptedge/lib-ui/src/utils/query";
import { useQuery } from "react-query";
import { CACHE_KEY } from "../../data/cacheKeys";
import { useAppDispatch } from "../../redux/hook/hook";
import { updateSearchFilters } from "../../redux/reduxSlice/searchSlice";
import {
  SearchFilters,
  getSearchFilters
} from "../../services/search/searchFilterService";

export interface IFilterDropdown {
  id: string;
  label: string;
  key: string;
  Icon: string;
  flag?: boolean;
}

interface IUseSearchFilterData {
  searchFilterList: SearchFilters[];
  isLoading: boolean;
}

export const useSearchFilterData = (
  userEmail?: string | null,
  fetchDynamicFilters?: () => Promise<{ groups: SearchFilters[] }>
): IUseSearchFilterData => {
  const queryFn = fetchDynamicFilters ?? getSearchFilters;
  const dispatch = useAppDispatch();
  const { data, isLoading } = useQuery(
    [CACHE_KEY.SEARCH_FILTERS, userEmail],
    queryFn,
    {
      cacheTime: TWENTY_FOUR_HOURS_MILLIS,
      staleTime: TWENTY_MINUTES_MILLIS,
      onSuccess: (data) => {
        dispatch(updateSearchFilters(data.groups));
        return data;
      },
      // In the support app, we want to wait until the user email is available, in order to use it
      // as a cache-buster. (See comment in EnvironmentContext.) But in the web app it doesn't
      // matter, because we clear the cache on login. So for speed, we allow the web app to specify
      // userEmail as undefined, but the support app can only specify a string or null, and we'll
      // wait if it's null. (Sounds complicated, but this is technically the distinction between
      // null and undefined in a broad sense.)
      enabled: userEmail !== null
    }
  );

  const searchFilters = data?.groups ?? [];
  return { searchFilterList: searchFilters, isLoading };
};
