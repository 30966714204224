import {
  INotionSourceRequest,
  INotionSource
} from "@aptedge/lib-ui/src/types/entities";
import { getBackendUrl } from "../../config";
import { request } from "../../utils/request";
import { post } from "../utils/buildRequest";

async function createNotionArticleSource(
  args: INotionSourceRequest
): Promise<INotionSource> {
  const url = `${getBackendUrl()}/notion/collect/`;

  const options: RequestInit = post(args);

  return request(url, options);
}

export { createNotionArticleSource };
