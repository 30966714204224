enum BoxSizing {
  SMALL = "2px",
  REGULAR = "4px",
  MEDIUM = "8px",
  LARGE = "16px",
  XL = "20px",
  XXL = "24px",
  INPUT = "32px",
  XXXL = "36px",
  VIEW = "40px",
  NAVIGATION_BAR = "60px",
  SECONDARY_BAR = "76px"
}

const BoxShadow = {
  DEFAULT: shadow(4, 4, 8, 0, "hsla(var(--shadow))"),
  HOVER: shadow(4, 4, 12, 0, "hsla(var(--shadow))")
} as const;

enum LineHeight {
  INPUT = "18px"
}

function shadow(
  offsetX: number,
  offsetY: number,
  blurRadius: number,
  spreadRadius: number,
  hslaString: string
): string {
  return `${offsetX}px ${offsetY}px ${blurRadius}px ${spreadRadius}px ${hslaString}`;
}

export { BoxShadow, BoxSizing, LineHeight };
