import classNames from "classnames";
import React from "react";
import { Dropdown } from "../Dropdown/Dropdown";
import { Icons } from "../Icon/Icons";
import { ReactComponent as ContainsIcon } from "./contains.svg";
import { ReactComponent as EqualsIcon } from "./equals.svg";
import "./FilterWrapper.scss";
import { ReactComponent as NotContainsIcon } from "./notContains.svg";
import { ReactComponent as NotEqualsIcon } from "./notEquals.svg";
import { IQueryFilter, IQueryFilterId, IQueryFilterOperator } from "./types";

const operatorIcons = {
  equals: EqualsIcon,
  notEquals: NotEqualsIcon,
  contains: ContainsIcon,
  notContains: NotContainsIcon
};

const operatorLabels = {
  equals: "Equal",
  notEquals: "Is not equal",
  contains: "Contains",
  notContains: "Does not contain"
};

interface Props {
  id: IQueryFilterId;
  icon: React.ReactNode;
  label: string;
  operators: IQueryFilterOperator[];
  value?: IQueryFilter;
  error?: boolean;
  onChange: (newValue: IQueryFilter) => void;
  onRemove?: () => void;
  children: (args: {
    values?: string[];
    onChange: (newValues: string[]) => void;
  }) => React.ReactNode;
}

function FilterWrapper(props: Props): React.ReactElement {
  const {
    id,
    icon,
    label,
    value,
    error,
    operators,
    onChange,
    onRemove,
    children
  } = props;

  const operator = value?.operator || operators[0];
  const OpIcon = operatorIcons[operator];
  const testId = label.toLowerCase().split(" ").join("-");
  const handleChange = (newValues: string[]): void => {
    onChange({ id, operator, values: newValues });
  };

  const handleRemove = (): void => {
    if (onRemove) onRemove();
  };

  return (
    <div
      className={classNames("filter-wrapper", { error })}
      data-testid={`filter-wrapper-${testId}`}
    >
      <div className="label">
        <span className="icon">{icon}</span>
        <span>{label}</span>
      </div>
      <div className="operator">
        <Dropdown.Container placement="bottom">
          <Dropdown.Toggle>
            <OpIcon />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {operators.map((o, idx) => {
              const Icon = operatorIcons[o];
              const label = operatorLabels[o];
              return (
                <Dropdown.Item
                  key={idx}
                  onClick={() =>
                    onChange({ id, operator: o, values: value?.values ?? [] })
                  }
                >
                  <span>
                    <Icon />
                  </span>
                  <span className="pl-1">{label}</span>
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown.Container>
      </div>
      <div className="filter">
        {children({ values: value?.values, onChange: handleChange })}
      </div>
      {onRemove && (
        <div
          className="end-cap"
          data-testid={`filter-wrapper-${testId}-remove`}
          onClick={handleRemove}
        >
          <Icons.Times />
        </div>
      )}
    </div>
  );
}

export { FilterWrapper };
