import { useQueryParams } from "@aptedge/lib-ui/src/hooks/useQueryParams";
import {
  useAppDispatch,
  useAppSelector
} from "@aptedge/lib-ui/src/redux/hook/hook";
import {
  updateArticleCreatedUser,
  updateArticleReviewedUser,
  updateFilterArticleStatus,
  updatePage,
  updateResetFilters,
  updateSearchArticle,
  updateTabArticleStatus
} from "@aptedge/lib-ui/src/redux/reduxSlice/kbGenSlice";
import { updateAnswerId } from "@aptedge/lib-ui/src/redux/reduxSlice/searchSlice";
import {
  ArticleStatus,
  IUserListing,
  QUERY_PARAMS,
  UserRole
} from "@aptedge/lib-ui/src/types/entities";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { TextField, TextareaAutosize } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import classNames from "classnames";
import { ChangeEvent, FC, KeyboardEvent, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import styles from "../ArticleSearch/ArticleSearch.module.scss";

const ArticleSearch: FC = () => {
  const searchRef = useRef<HTMLInputElement>(null);
  const { removeQueryParams } = useQueryParams();
  const [searchQuery, setSearchQuery] = useState("");
  const {
    searchArticle,
    articleCreatedUser,
    articleReviewedUser,
    filterArticleStatus,
    tabArticleStatus,
    draftArticleCount,
    reviewArticleCount
  } = useAppSelector((state) => state.knowledgeBase);

  const { currentUserRole } = useAppSelector((state) => state.app);

  const dispatch = useAppDispatch();

  const handlePageParam = (): void => {
    removeQueryParams(QUERY_PARAMS.PAGE);
    dispatch(updatePage(1));
  };

  const handleTabChange = (tabState: ArticleStatus): void => {
    if (tabState === ArticleStatus.ALL) {
      dispatch(updateArticleCreatedUser({} as IUserListing));
      dispatch(updateArticleReviewedUser({} as IUserListing));
      dispatch(updateResetFilters(true));
    }
    handlePageParam();
    dispatch(updateFilterArticleStatus([]));
    dispatch(updateSearchArticle(""));
    dispatch(updateTabArticleStatus(tabState));
  };

  const searchOnEnter = (event: KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === "Enter") {
      event.preventDefault();
      dispatch(updateSearchArticle(searchQuery));
      const answerId = uuidv4();
      dispatch(updateAnswerId(answerId));
    }
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setSearchQuery(e.target.value);
  };

  const onCancelClick = (): void => {
    setSearchQuery("");
    dispatch(updateSearchArticle(""));
  };

  const isDisableArticleSearch = Boolean(
    articleCreatedUser.email ||
      articleReviewedUser.email ||
      filterArticleStatus.length > 0
  );

  return (
    <div className={styles.articleSearchContainer}>
      <TextField
        inputRef={searchRef}
        data-testid="article-search-bar"
        className={classNames(styles.searchContainer, {
          [styles.disableSearch]: isDisableArticleSearch
        })}
        placeholder="Search articles"
        value={searchQuery}
        onChange={onInputChange}
        onKeyDown={searchOnEnter}
        fullWidth={true}
        variant="standard"
        disabled={isDisableArticleSearch}
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon
                className={classNames(styles.articleSearchIcon, {
                  [styles.disableSearch]: isDisableArticleSearch
                })}
              />
            </InputAdornment>
          ),
          endAdornment: (
            <>
              {Boolean(searchQuery) && (
                <InputAdornment onClick={onCancelClick} position="end">
                  <ClearOutlinedIcon className={styles.articleClearIcon} />
                </InputAdornment>
              )}
            </>
          ),
          inputComponent: TextareaAutosize,
          inputProps: {
            minRows: 1,
            className: classNames(styles.textfield, {
              [styles.disableSearch]: isDisableArticleSearch
            })
          }
        }}
      />
      <div className={styles.articleTabsContainer}>
        <span
          className={classNames(styles.articleTab, {
            [styles.articleTabSelected]: tabArticleStatus === ArticleStatus.ALL,
            [styles.unselectTab]: searchArticle
          })}
          onClick={() => handleTabChange(ArticleStatus.ALL)}
          data-testid="all-articles-tab"
        >
          All articles
        </span>
        <div
          className={classNames(styles.articleTab, {
            [styles.articleTabSelected]:
              tabArticleStatus === ArticleStatus.DRAFT
          })}
          onClick={() => handleTabChange(ArticleStatus.DRAFT)}
          data-testid="my-drafts-tab"
        >
          <span>My drafts</span>
          {Boolean(draftArticleCount) && (
            <div
              className={styles.countBubbleContainer}
              data-testid="draft-count-indicator"
            >
              <span className={styles.countBubbleLabel}>
                {draftArticleCount}
              </span>
            </div>
          )}
        </div>
        {currentUserRole !== UserRole.AGENT && (
          <div
            className={classNames(styles.articleTab, {
              [styles.articleTabSelected]:
                tabArticleStatus === ArticleStatus.IN_REVIEW
            })}
            onClick={() => handleTabChange(ArticleStatus.IN_REVIEW)}
            data-testid="my-requests-tab"
          >
            <span>My requests</span>
            {Boolean(reviewArticleCount) && (
              <div
                className={styles.countBubbleContainer}
                data-testid="review-count-indicator"
              >
                <span className={styles.countBubbleLabel}>
                  {reviewArticleCount}
                </span>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export { ArticleSearch };
