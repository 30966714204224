/* istanbul ignore file */
import {
  ARTICLE_SOURCE_TYPE,
  EntityType,
  ISSUES_SOURCE_TYPE
} from "@aptedge/lib-ui/src/types/entities";
import * as Yup from "yup";

const edgeValiditor = Yup.object({
  id: Yup.number().required(),
  name: Yup.string().required(),
  labels: Yup.array()
    .of(
      Yup.object({
        id: Yup.number(),
        name: Yup.string(),
        type: Yup.mixed().oneOf(Object.values(EntityType)).required()
      })
    )
    .defined(),
  richSummary: Yup.object({
    content: Yup.string().required(),
    version: Yup.string()
  }).nullable(),
  createdOn: Yup.number().required(),
  archived: Yup.boolean().required(),
  type: Yup.string().matches(/edge/).required()
}).optional();

const ticketValidator = Yup.object({
  assigneeUser: Yup.object({
    id: Yup.number().required(),
    email: Yup.string().required(),
    name: Yup.string().required()
  }).notRequired(),
  createdOn: Yup.number().required(),
  customer: Yup.object({
    id: Yup.number().notRequired(),
    name: Yup.string()
  }).notRequired(),
  displayId: Yup.string().required(),
  features: Yup.array()
    .of(
      Yup.object({
        id: Yup.number().required(),
        name: Yup.string().required(),
        type: Yup.string().notRequired()
      })
    )
    .notRequired()
    .nullable(),
  frustration: Yup.object({
    id: Yup.number(),
    text: Yup.string(),
    ticket: Yup.number(),
    score: Yup.number().required()
  })
    .notRequired()
    .nullable(),
  id: Yup.number().required(),
  products: Yup.array()
    .of(
      Yup.object({
        id: Yup.number().required(),
        name: Yup.string().required(),
        type: Yup.string().notRequired()
      }).nullable()
    )
    .notRequired()
    .nullable(),
  status: Yup.string().required(),
  subject: Yup.string().required(),
  summary: Yup.object({
    content: Yup.string().required(),
    version: Yup.string()
  }).nullable(),
  themes: Yup.array()
    .of(
      Yup.object({
        id: Yup.number().required(),
        name: Yup.string().required(),
        type: Yup.string().notRequired()
      })
    )
    .notRequired()
    .nullable(),
  type: Yup.string()
    .matches(/ticket/)
    .required(),
  updatedOn: Yup.number().required()
});

const articleValidator = Yup.object({
  id: Yup.string().required(),
  url: Yup.string().required(),
  type: Yup.string()
    .matches(/article/)
    .required(),
  title: Yup.string().required(),
  createdOn: Yup.string().required(),
  sourceType: Yup.mixed<ARTICLE_SOURCE_TYPE>()
    .oneOf(Object.values(ARTICLE_SOURCE_TYPE))
    .required()
});
const issueValidator = Yup.object({
  id: Yup.string().required(),
  url: Yup.string().required(),
  type: Yup.string().matches(/issue/).required(),
  title: Yup.string().required(),
  createdOn: Yup.string().required(),
  sourceType: Yup.mixed<ISSUES_SOURCE_TYPE>()
    .oneOf(Object.values(ISSUES_SOURCE_TYPE))
    .required()
});
const socialValidator = Yup.object({
  id: Yup.string().required(),
  url: Yup.string().required(),
  type: Yup.string().required(),
  title: Yup.string()
    .matches(/social/)
    .required(),
  createdOn: Yup.number().required(),
  preview: Yup.string().required(),
  updatedOn: Yup.number().required(),
  displayUrl: Yup.string().required()
});

export {
  issueValidator,
  articleValidator,
  ticketValidator,
  edgeValiditor,
  socialValidator
};
