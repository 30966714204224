import { getBackendApiUrl } from "../../config";
import { deleteRequest } from "../../utils/request";
import { deleteInit } from "../utils/buildRequest";

async function deleteUser({ userId }: { userId: number }): Promise<void> {
  const url = `${getBackendApiUrl()}/users/${userId}`;

  return deleteRequest(url, deleteInit());
}

export { deleteUser };
