import { IQueryArgs } from "@aptedge/lib-ui/src/utils/query";
import { toSortString } from "../../clients/utils/sort";
import { useLZQuery } from "../../hooks/useLZQuery";
import { IPaginatedRequest } from "../../types/clients";

export type EdgeListingQueryParams = IPaginatedRequest<IQueryArgs>;

const DEFAULT_SORT = {
  id: "createdOn",
  desc: true
};

function useEdgeListingParams(): [
  EdgeListingQueryParams,
  (partial: Partial<EdgeListingQueryParams>) => void
] {
  return useLZQuery<EdgeListingQueryParams>({
    page: 1,
    perPage: 10,
    filters: [],
    timeDimensions: [],
    sortBy: toSortString([DEFAULT_SORT])
  });
}

export { useEdgeListingParams };
