import {
  IQueryArgs,
  toLZCompressedString
} from "@aptedge/lib-ui/src/utils/query";
import { compile } from "path-to-regexp";
import { Routes } from "./routes";

function toEdgeListPath(args: IQueryArgs): string {
  const query = toLZCompressedString(args);

  return `${Routes.EDGES}?q=${query}`;
}

function toEdgePath(edgeId: number): string {
  const toPath = compile(Routes.EDGES_EDGE);

  return toPath({ edgeId });
}

function toTicketPath(ticketId: number): string {
  const toPath = compile(Routes.TICKET);

  return toPath({ ticketId });
}

function toGeneratedKnowledgePath(articleId: string): string {
  return `${Routes.ARTICLES}?articleId=${articleId}`;
}

export { toTicketPath, toEdgePath, toEdgeListPath, toGeneratedKnowledgePath };
