import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";

function fetchTicketReasons(): Promise<{ statuses: string[] }> {
  const url = `${getBackendApiUrl()}/tickets/reasons/`;

  return request(url);
}

export { fetchTicketReasons };
