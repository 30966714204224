import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";
import { post } from "../utils/buildRequest";

async function watchEdge({
  edgeId
}: {
  edgeId: number;
}): Promise<{ success: boolean }> {
  const url = `${getBackendApiUrl()}/users/watch-edge/${edgeId}/`;

  return request(url, post({})).then((res) => ({ success: true }));
}

export { watchEdge };
