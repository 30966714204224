import {
  IRichTextContent,
  TextEditor
} from "@aptedge/lib-ui/src/components/TextEditor/TextEditor";
import {
  ImageUploadResult,
  IEdgeInfo,
  TemplateLocation
} from "@aptedge/lib-ui/src/types/entities";
import { GTM_EVENTS, dataLayerPush } from "@aptedge/lib-ui/src/utils/event";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Prompt } from "react-router-dom";
import { WebCacheKey } from "../../clients/cache";
import { updateEdge } from "../../clients/Edges/updateEdge";
import { uploadEdgeFiles } from "../../clients/Edges/uploadEdgeFiles";
import { fetchTemplates } from "../../clients/Templates/fetchTemplates";
import { MutationErrorToast } from "../../components/ErrorHandler/MutationErrorToast";
import { getBackendApiUrl } from "../../config";
import { EdgeSectionLabel } from "./EdgeSectionLabel";
import "./EdgeSummaryWidget.scss";

interface Props {
  edge: IEdgeInfo;
  onUpdateEdge: (edge: IEdgeInfo) => void;
}

function EdgeSummaryWidget(props: Props): React.ReactElement {
  const { edge, onUpdateEdge } = props;

  const [dirty, setDirty] = useState(false);
  const fetchTemplatesQuery = useQuery(WebCacheKey.TEMPLATES, fetchTemplates);
  const template = fetchTemplatesQuery.data?.find(
    (t) => t.location === TemplateLocation.EDGE_DESCRIPTION
  );
  const edgeMutation = useMutation(updateEdge, { onSuccess: onUpdateEdge });
  const uploadFilesMutation = useMutation(uploadEdgeFiles);

  const handleImageUpload = async (f: File): Promise<ImageUploadResult> => {
    try {
      const uploadRes = await uploadFilesMutation.mutateAsync({
        edgeId: edge.id,
        files: [f]
      });
      if (uploadRes) {
        dataLayerPush({ event: GTM_EVENTS.GTM_UPLOAD_EDGE_FILE });
        const url = `${getBackendApiUrl()}/edges/${edge.id}/files/${
          uploadRes[0]
        }`;
        return { success: true, name: f.name, url };
      }
      return { success: false, name: f.name };
    } catch (e) {
      return { success: false, name: f.name };
    }
  };

  const handleSave = (newContent: IRichTextContent): void => {
    setDirty(false);
    dataLayerPush({ event: GTM_EVENTS.GTM_UPDATE_EDGE_DESCRIPTION });
    edgeMutation.mutate({
      edgeId: edge.id,
      patchContent: { richSummary: newContent }
    });
  };

  return (
    <div className="edge-summary">
      <EdgeSectionLabel>Description</EdgeSectionLabel>
      <TextEditor
        content={edge.richSummary}
        placeholder="Add a description..."
        onChange={() => setDirty(true)}
        onSave={(newContent) => handleSave(newContent)}
        templateText={template?.content}
        readOnly={edge.archived || edgeMutation.isLoading}
        handleImageUpload={handleImageUpload}
        isImageDropAllowed={true}
      />
      <Prompt when={dirty} message={EdgeSummaryWidget.PROMPT} />
      <MutationErrorToast
        error={edgeMutation.error}
        resetError={edgeMutation.reset}
      />
    </div>
  );
}

EdgeSummaryWidget.PROMPT =
  "Your changes to the Edge Summary will be lost if you continue.";

export { EdgeSummaryWidget };
