import { Checkbox } from "@aptedge/lib-ui/src/components/Checkbox/Checkbox";
import { Dropdown } from "@aptedge/lib-ui/src/components/Dropdown/Dropdown";
import ImageIcon from "@aptedge/lib-ui/src/components/Icon/ImageIcon";
import { getUpdatedDropDownMenu } from "@aptedge/lib-ui/src/features/SearchFilters/utils";
import {
  useAppDispatch,
  useAppSelector
} from "@aptedge/lib-ui/src/redux/hook/hook";
import { updateSearchFilterSubType } from "@aptedge/lib-ui/src/redux/reduxSlice/searchSlice";
import { SearchFilterDropdown } from "@aptedge/lib-ui/src/services/search/searchFilterService";
import { SearchFilterSubType } from "@aptedge/lib-ui/src/types/entities";
import { FC, ReactNode } from "react";
import styles from "./styles.module.scss";

interface IProps {
  dropDownMenu: SearchFilterDropdown[];
  children: ReactNode;
  onFilterClick: () => void;
  onSubFilterClick: (index: number) => void;
}

const ArticleFilterDropdown: FC<IProps> = ({
  dropDownMenu,
  children,
  onFilterClick,
  onSubFilterClick
}) => {
  const { searchFilterSubType } = useAppSelector((state) => state.search);
  const dispatch = useAppDispatch();

  const handleChangeSourceType = (label: string, index: number): void => {
    if (label === searchFilterSubType) return;
    else dispatch(updateSearchFilterSubType(label as SearchFilterSubType));
    onSubFilterClick(index);
  };

  return (
    <Dropdown.Container placement="bottom">
      <Dropdown.Toggle>
        <div onClick={onFilterClick}>{children}</div>
      </Dropdown.Toggle>
      <Dropdown.Menu data-testid="article-dropdown-menu">
        {getUpdatedDropDownMenu(dropDownMenu).map(
          ({ label, filter_key: key, icon }, index) => {
            return (
              <Dropdown.Item
                disableToggleOnItem={true}
                key={label}
                onClick={() => handleChangeSourceType(key, index)}
              >
                <div className={styles.dropdownItemContainer}>
                  <div className={styles.dropdownItem}>
                    <ImageIcon src={icon} className={styles.filterIcon} />
                    <span>{label}</span>
                  </div>
                  <div className={styles.dropdownCheck}>
                    <Checkbox
                      checked={key === searchFilterSubType}
                      onChange={() => handleChangeSourceType(key, index)}
                    />
                  </div>
                </div>
              </Dropdown.Item>
            );
          }
        )}
      </Dropdown.Menu>
    </Dropdown.Container>
  );
};

export default ArticleFilterDropdown;
