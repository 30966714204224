import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import { useOnMouseDownOutside } from "@aptedge/lib-ui/src/hooks/useOnMouseDownOutside";
import classNames from "classnames";
import React, { createRef, useState } from "react";
import { useQuery } from "react-query";
import { WebCacheKey } from "../../../clients/cache";
import { fetchKnowledgeBaseConfig } from "../../../clients/KnowledgeBaseConfig/fetchKnowledgeBaseConfig";
import WithLoading from "../../../components/WithLoading/WithLoading";
import { KnowledgeBaseCreateItem } from "./KnowledgeBaseCreateItem";
import { KnowledgeBaseListItem } from "./KnowledgeBaseListItem";

function KnowledgeBaseList(): React.ReactElement {
  const [showAddUrl, setShowAddUrl] = useState(false);

  const knowledgeBaseUrlQuery = useQuery(
    [WebCacheKey.KNOWLEDGE_BASE_URLS],
    () => fetchKnowledgeBaseConfig(),
    { useErrorBoundary: true }
  );

  const urls = knowledgeBaseUrlQuery.data || [];

  const wrapperRef = createRef<HTMLUListElement>();

  useOnMouseDownOutside(wrapperRef, () => {
    setShowAddUrl(false);
  });

  const handleSave = (): void => {
    setShowAddUrl(false);
    knowledgeBaseUrlQuery.refetch();
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-start">
        <div className="pr-2">
          <h5>Urls</h5>
          <p>
            Add URL prefixes that AptEdge should use when searching for
            knowledge articles.
          </p>
        </div>
        <div>
          <Button
            color="secondary"
            onClick={() => setShowAddUrl(true)}
            disabled={knowledgeBaseUrlQuery.isLoading}
          >
            <Icons.Plus />
            <span>Add</span>
          </Button>
        </div>
      </div>
      <ul ref={wrapperRef} className={classNames("article-list list-group")}>
        {showAddUrl && (
          <KnowledgeBaseCreateItem
            onSave={handleSave}
            onCancel={() => setShowAddUrl(false)}
          />
        )}
        <WithLoading isLoading={knowledgeBaseUrlQuery.isLoading}>
          {urls.map((u, key) => (
            <KnowledgeBaseListItem
              key={`article-${key}`}
              url={u}
              onChange={() => knowledgeBaseUrlQuery.refetch()}
            />
          ))}
        </WithLoading>
      </ul>
    </>
  );
}

export { KnowledgeBaseList };
