import { IEdgeAuditLogMerging } from "@aptedge/lib-ui/src/types/entities";
import React from "react";
import { Link } from "react-router-dom";
import { toEdgePath } from "../../routes/toPath";
import { ArrowTransition } from "./ArrowTransition";
import { SummarySentence } from "./SummarySentence";
import { WithAuditLog } from "./WithAuditLog";
import type { AuditDetailsFC, AuditSummaryFC } from "./WithAuditLog";

const Details: AuditDetailsFC<IEdgeAuditLogMerging> = (props) => {
  const { from, to } = props.log.data;

  const fromDisplay = (
    <div>
      <p>
        <span>Merged </span>
        {!!from?.length &&
          from.map((e, idx) => (
            <React.Fragment key={`audit-log-merge-${e.id}`}>
              <Link to={toEdgePath(e.id)}>{e.displayName}</Link>
              {from[idx + 1] && <span>, </span>}
            </React.Fragment>
          ))}
        {!!to?.length && (
          <>
            <span>into </span>
            {to.map((e, idx) => (
              <React.Fragment key={`audit-log-merge-${e.id}`}>
                <Link to={toEdgePath(e.id)}>{e.displayName}</Link>
                {to[idx + 1] && <span>, </span>}
              </React.Fragment>
            ))}
          </>
        )}
      </p>
    </div>
  );

  return (
    <div className="audit-log-merging">
      <ArrowTransition from={fromDisplay} to={null} />
    </div>
  );
};

const Summary: AuditSummaryFC<IEdgeAuditLogMerging> = (props) => {
  const { log, author } = props;
  const { VERBS, OBJECTS } = SummarySentence;

  const mergedInto = !!log.data.to?.length;
  const mergeVerb = mergedInto ? VERBS.MERGED_TO : VERBS.MERGED_FROM;

  return (
    <SummarySentence
      subject={author}
      verb={mergeVerb}
      object={OBJECTS.EDGE}
      displayText={log?.displayText ?? ""}
    />
  );
};

Details.displayName = "AuditMergingDetails";
const AuditLogMerging = WithAuditLog(Details, Summary);

export { AuditLogMerging };
