import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";
import { post } from "../utils/buildRequest";

interface SaveMyProductsArgs {
  selectedIds: string[];
}

async function saveMyProducts({
  selectedIds
}: SaveMyProductsArgs): Promise<void> {
  const url = `${getBackendApiUrl()}/apt-answers/save-filters`;
  const options = post({ filterIds: { productFilters: selectedIds } });
  return request(url, options);
}

export { saveMyProducts };
