import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import { useSentry } from "@aptedge/lib-ui/src/context/SentryContext";
import { IEdgeAuditLog } from "@aptedge/lib-ui/src/types/entities";
import React, { useRef } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { AuditLog } from "../../../components/AuditLogs/AuditLog";
import { useIntersectionObserver } from "../../../hooks/useIntersectionObserver";
import { AuditLogErrorFallback } from "./AuditLogErrorFallback";

const EdgeAuditWidget: React.FC<{
  logs: IEdgeAuditLog[];
  fetchNextPage: () => void;
  isLoading: boolean;
  isFetchingNextPage: boolean;
  hasNextPage?: boolean;
}> = (props) => {
  const {
    logs,
    fetchNextPage,
    isLoading,
    hasNextPage,
    isFetchingNextPage
  } = props;
  const { captureException } = useSentry();
  const targetRef = useRef<HTMLDivElement>(null);

  useIntersectionObserver({
    root: null,
    target: targetRef,
    onIntersect: fetchNextPage,
    enabled: hasNextPage && !isFetchingNextPage
  });

  return (
    <>
      {logs.map((log, idx) => (
        <React.Fragment key={`audit-log-${log.id}-${idx}`}>
          <ErrorBoundary
            FallbackComponent={AuditLogErrorFallback}
            onError={(err, componentStack) =>
              captureException(err.message + componentStack)
            }
            resetKeys={[log]}
          >
            <AuditLog log={log} />
          </ErrorBoundary>
        </React.Fragment>
      ))}
      {isLoading && <Spinner className="mt-4" />}
      {!isLoading && hasNextPage && (
        <div className="load-more" ref={targetRef}>
          scroll to load more
        </div>
      )}
    </>
  );
};

export { EdgeAuditWidget };
