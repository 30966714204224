import { Dropdown } from "@aptedge/lib-ui/src/components/Dropdown/Dropdown";
import { ReactComponent as FilterIcon } from "@aptedge/lib-ui/src/components/Filter/filter.svg";
import {
  IQueryFilter,
  ITimeDimensionFilter,
  IFilterDefinition,
  IQueryFilterDefinition,
  ITimeDimensionFilterDefinition
} from "@aptedge/lib-ui/src/components/Filter/types";
import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import { ISavedQuery } from "@aptedge/lib-ui/src/types/entities";
import { fromLZCompressedString } from "@aptedge/lib-ui/src/utils/query";
import classNames from "classnames";
import truncate from "lodash/truncate";
import React, { useContext, useState } from "react";
import { SavedQueriesContext } from "../../context/SavedQueriesContext";
import "./FilterSelect.scss";

type Query = {
  filters?: IQueryFilter[];
  timeDimensions?: ITimeDimensionFilter[];
};
interface Props {
  label?: string;
  specs: IFilterDefinition[];
  query: Query;
  onChange: (partial: Partial<Query>) => void;
}

function FilterSelect(props: Props): React.ReactElement {
  const { label = "Filter", specs, query, onChange } = props;
  const filters = query.filters || [];
  const activeQueryFilterIds = filters.map((f) => {
    return f.id;
  });
  const timeDimensions = query.timeDimensions || [];
  const activeTimeDimensionFilterIds = timeDimensions.map((f) => {
    return f.id;
  });
  const duplicateFilterIds = specs
    .filter((f) => {
      return f.canDuplicate;
    })
    .map((f) => {
      return f.id;
    });
  const filteredSpecs = specs.filter((spec) => {
    if (spec.kind === "IQueryFilterDefinition") {
      if (
        activeQueryFilterIds.includes(spec.id) &&
        !duplicateFilterIds.includes(spec.id)
      ) {
        return false;
      }
    }
    if (spec.kind === "ITimeDimensionFilterDefinition") {
      if (
        activeTimeDimensionFilterIds.includes(spec.id) &&
        !duplicateFilterIds.includes(spec.id)
      ) {
        return false;
      }
    }
    return true;
  });
  const [editHovered, setEditHovered] = useState<boolean>(false);
  const saved = useContext(SavedQueriesContext);

  const handleChange = (value: Partial<Query>): void => {
    if (!saved.editing) {
      saved.setCurrentSavedQuery(undefined);
    }

    onChange(value);
  };

  const handleSelect = (filter: IFilterDefinition): void => {
    const isQueryFilter = !!(filter as { [key: string]: unknown }).operators;
    if (isQueryFilter) {
      const f = filter as IQueryFilterDefinition;
      const newFilters = [
        ...filters,
        { id: f.id, operator: f.operators[0], values: [] }
      ];
      handleChange({ filters: newFilters });
    } else {
      const f = filter as ITimeDimensionFilterDefinition;
      const newDimensions = [...timeDimensions, { id: f.id, values: [] }];
      handleChange({ timeDimensions: newDimensions });
    }
  };

  const handleSavedQuery = (value: ISavedQuery): void => {
    if (editHovered) {
      saved.setEditing(true);
      setEditHovered(false);
    }

    const query = fromLZCompressedString(value.query);
    onChange(query);
    saved.setCurrentSavedQuery(value);
  };

  return (
    <Dropdown.Container className="filter-select">
      <Dropdown.Toggle>
        <div className="filter-select-toggle">
          <div>
            <span className="icon">
              <FilterIcon />
            </span>
            <span>{label}</span>
          </div>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {filteredSpecs.map((filter, idx) => (
          <Dropdown.Item
            key={`filter-option-${idx}`}
            onClick={() => handleSelect(filter)}
          >
            <div className={classNames("filter-select-item")}>
              <span
                className="filter-icon"
                data-testid={`filter-icon-${filter.id}`}
              >
                {filter.icon}
              </span>
              <span>{filter.label}</span>
            </div>
          </Dropdown.Item>
        ))}
        {!saved.editing && !!saved.savedQueries?.length && (
          <>
            <hr className="mt-2 mb-2" />
            {saved.savedQueries.map((s) => (
              <Dropdown.Item
                key={`saved-filter-${s.id}`}
                onClick={() => handleSavedQuery(s)}
              >
                <div className={classNames("filter-select-item", "saved-item")}>
                  <div className="mr-3">
                    <span className="filter-icon">
                      <Icons.StarFilled />
                    </span>
                    <span>{truncate(s.name, { length: 25 })}</span>
                  </div>
                  <div>
                    <Icons.Edit
                      className="text-gray-400 hover:text-primary"
                      onMouseEnter={() => setEditHovered(true)}
                      onMouseLeave={() => setEditHovered(false)}
                      size="sm"
                    />
                  </div>
                </div>
              </Dropdown.Item>
            ))}
          </>
        )}
      </Dropdown.Menu>
    </Dropdown.Container>
  );
}

export { FilterSelect };
