import {
  IDynamicSource,
  IDynamicSourceRequest
} from "@aptedge/lib-ui/src/types/entities";
import { getBackendUrl } from "../../../config";
import { request } from "../../../utils/request";
import { patch } from "../../utils/buildRequest";

async function updateDynamicArticleSource(
  args: IDynamicSourceRequest
): Promise<IDynamicSource> {
  const { formData, id } = args;
  const url = `${getBackendUrl()}/website/update/${id}`;

  const options: RequestInit = patch(formData);

  return request(url, options);
}

export { updateDynamicArticleSource };
