import {
  AptAnswersFilterType,
  AptAnswersIntegrationsObject
} from "@aptedge/lib-ui/src/types/entities";
import * as React from "react";
import { IntegrationItem } from "./IntegrationItem";
import {
  IntegrationUpdate,
  updateIntegrationState
} from "./utils/updateIntegrationState";

interface AptAnswersFiltersProps {
  integrations: AptAnswersIntegrationsObject[];
  setIntegrationValues: (integrations: AptAnswersIntegrationsObject[]) => void;
  filterType: AptAnswersFilterType;
  filterName: string;
  handleItemSave?: (integrationName: string) => void;
}

const AptAnswersFilters: React.FC<AptAnswersFiltersProps> = ({
  integrations,
  setIntegrationValues,
  filterType,
  filterName,
  handleItemSave
}) => {
  const handleRulesUpdate = ({
    integrationName,
    updateType,
    ruleIdx,
    ruleUpdate
  }: IntegrationUpdate): void => {
    const updatedIntegrationValues = updateIntegrationState({
      integrationValues: integrations,
      integrationUpdate: { integrationName, updateType, ruleIdx, ruleUpdate },
      filterType
    });
    setIntegrationValues(updatedIntegrationValues);
  };

  return (
    <div>
      {integrations.map((integration, idx) => (
        <IntegrationItem
          key={integration.name}
          filterType={filterType}
          integration={integration}
          isTop={idx === 0}
          isBottom={idx === integrations.length - 1}
          filterName={filterName}
          handleRulesUpdate={handleRulesUpdate}
          handleItemSave={handleItemSave}
        />
      ))}
    </div>
  );
};

export { AptAnswersFilters };
