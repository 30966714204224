import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import useModal from "@aptedge/lib-ui/src/hooks/useModal/useModal";
import { AptAnswersFilterType } from "@aptedge/lib-ui/src/types/entities";
import * as React from "react";
import { AptAnswersErrorMessage } from "../../../components/AptAnswers/AptAnswersErrorMessage";
import { FilterForm } from "../../../components/AptAnswers/FilterForm";
import { FilterList } from "../../../components/AptAnswers/FilterList";
import { FilterListEmptyState } from "../../../components/AptAnswers/FilterListEmptyState";
import { getAptAnswersFilterInfo } from "../../../components/AptAnswers/utils/getAptAnswersFilterInfo";
import { useAptAnswersFilters } from "../../../hooks/useAptAnswersFilters";
import { PageHeader } from "./PageHeader";
import styles from "./QuickFilters.module.scss";

const QuickFilters: React.FC = () => {
  const { filterType } = getAptAnswersFilterInfo(
    AptAnswersFilterType.QUICK_FILTER
  );
  const { isError, isLoading, isSuccess, filtersData } = useAptAnswersFilters(
    filterType
  );
  const quickFilterItems = Object.entries(filtersData);
  const quickFilterItemsLength = quickFilterItems.length;
  const showEmptyState = quickFilterItemsLength === 0;

  const [selectedFormName, setSelectedFormName] = React.useState<string>("");

  const { modalRef, modalVisibility, closeModal } = useModal({
    closeModalOnClickOutside: false
  });

  const handleCreateFilter = (): void => {
    setSelectedFormName("");
    closeModal(true);
  };

  const handleUpdateFilter = (filterName: string): void => {
    setSelectedFormName(filterName);
    closeModal(true);
  };

  const handleCloseModal = (): void => {
    setSelectedFormName("");
    closeModal(false);
  };

  if (showEmptyState) {
    return (
      <div className={styles.emptyStateContainer}>
        {isLoading && <Spinner />}
        {isError && (
          <AptAnswersErrorMessage message="There was an error loading Quick Filters." />
        )}
        {isSuccess && (
          <>
            <FilterListEmptyState
              handleCreateFilter={handleCreateFilter}
              filterType={filterType}
            />
            {modalVisibility ? (
              <div className={styles.formContainerOverlay}>
                <div className={styles.formContainer} ref={modalRef}>
                  <FilterForm
                    filterType={filterType}
                    filterName={selectedFormName}
                    handleCloseModal={handleCloseModal}
                  />
                </div>
              </div>
            ) : null}
          </>
        )}
      </div>
    );
  }

  return (
    <div className={styles.container}>
      {isLoading && <Spinner />}
      {isError && (
        <AptAnswersErrorMessage message="There was an error loading Quick Filters." />
      )}
      {isSuccess && (
        <>
          <PageHeader
            title="Quick filters"
            subtitle="Use quick filters to scope answers and search results based on custom rules"
          />
          <div className={styles.listContainer}>
            <FilterList
              handleCreateFilter={handleCreateFilter}
              handleUpdateFilter={handleUpdateFilter}
              items={quickFilterItems}
              filterType={filterType}
            />
          </div>
          {modalVisibility ? (
            <div className={styles.formContainerOverlay}>
              <div className={styles.formContainer} ref={modalRef}>
                <FilterForm
                  filterType={filterType}
                  filterName={selectedFormName}
                  handleCloseModal={handleCloseModal}
                />
              </div>
            </div>
          ) : null}
        </>
      )}
    </div>
  );
};

export { QuickFilters };
