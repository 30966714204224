import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import { IEdgeInfo } from "@aptedge/lib-ui/src/types/entities";
import { GTM_EVENTS, dataLayerPush } from "@aptedge/lib-ui/src/utils/event";
import classNames from "classnames";
import React from "react";
import { useMutation } from "react-query";
import { unwatchEdge } from "../../clients/Notifications/unwatchEdge";
import { watchEdge } from "../../clients/Notifications/watchEdge";
import { useAuth } from "../../context/AuthContext";

interface Props {
  edge: IEdgeInfo;
  onUpdate: () => void;
  className?: string;
}

const EdgeWatchButton: React.FC<Props> = (props) => {
  const { edge, onUpdate, className } = props;
  const { user } = useAuth();
  const userIsWatchingEdge = !!user && edge.usersWatching.includes(user.id);

  const classes = {
    base: classNames("edge-watch-button", className)
  };

  const startWatching = useMutation(watchEdge, {
    onSuccess: () => {
      dataLayerPush({ event: GTM_EVENTS.GTM_WATCH_EDGE });
      onUpdate();
    }
  });
  const stopWatching = useMutation(unwatchEdge, {
    onSuccess: () => {
      dataLayerPush({ event: GTM_EVENTS.GTM_UNWATCH_EDGE });
      onUpdate();
    }
  });

  const handleWatch = (): void => {
    startWatching.mutate({ edgeId: edge.id });
  };

  const handleUnWatch = (): void => {
    stopWatching.mutate({ edgeId: edge.id });
  };

  if (userIsWatchingEdge) {
    return (
      <Button
        className={classes.base}
        onClick={handleUnWatch}
        disabled={edge.archived}
      >
        <Icons.EyeSlash />
        <span>Unwatch</span>
      </Button>
    );
  }

  return (
    <Button
      className={classes.base}
      onClick={handleWatch}
      disabled={edge.archived}
    >
      <Icons.Eye />
      <span>Watch</span>
    </Button>
  );
};

export { EdgeWatchButton };
