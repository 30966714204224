import { FC } from "react";
import SectionLayout from "../../../common/SectionLayout/SectionLayout";
import ConfigInput from "../../ConfigInput/ConfigInput";
import ConfigInputField from "../../ConfigInput/ConfigInputField/ConfigInputField";
import {
  ConfigProps,
  SELF_SERVICE_INTERNAL_DASHBOARD_LINK
} from "../../utils/configFieldKeys";

export const InternalConfig: FC<ConfigProps> = ({
  config,
  handleConfigChange,
  divider,
  isLoading
}) => {
  return (
    <div>
      <SectionLayout
        title="Internal Config"
        description="This section is dedicated to Aptedge Admins for CSS app configuration"
        divider={divider}
      >
        <ConfigInput
          title="PostHog Dashboard"
          description="Configure PostHog dashboard"
        >
          <ConfigInputField
            name={SELF_SERVICE_INTERNAL_DASHBOARD_LINK}
            placeholder="https://us.posthog.com/embedded/DASHBOARD_ID"
            value={config?.[SELF_SERVICE_INTERNAL_DASHBOARD_LINK] || ""}
            onChange={handleConfigChange}
            attrs={{ disabled: isLoading }}
          />
        </ConfigInput>
      </SectionLayout>
    </div>
  );
};
