import { useEffect, useState } from "react";
import { FeedbackSource } from "../components/SearchFeedback/SearchFeedback";
import { useFlags } from "../context/FlagsContext/FlagsContext";
import { useAppSelector, useAppDispatch } from "../redux/hook/hook";
import { updateFeedbackSubmittedIds } from "../redux/reduxSlice/searchSlice";
import { APPROVAL_STATUS, FeedbackMessageState } from "../types/entities";
import { GTM_EVENTS, dataLayerPush, isWebApp } from "../utils/event";
import { generateTopSearchResults } from "../utils/generator";

export interface SearchFeedbackProps {
  additionalFeedback: string;
  isVisible: boolean;
  feedbackMessageState: string;
  isFeedbackStateSubmitted: () => boolean;
  onFeedbackBtnClicked: (feedbackType: APPROVAL_STATUS) => void;
  handleSubmit: () => void;
  handleOptionClick: (label: string) => void;
  handleInputChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  isOptionSelected: (label: string) => boolean;
  getFeedbackText: () => string;
  answerFeedbackType?: string;
}

interface EventData {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const triggerEvent = (eventName: string, eventData: EventData): void => {
  dataLayerPush({
    event: eventName,
    data: eventData
  });
};

const prepareEventDataForSearch = (
  eventName: string,
  eventData: EventData
): void => {
  const {
    feedbackType,
    totalSearchResults,
    searchQuery,
    answer,
    ticketSubject,
    ticketId,
    searchResults,
    selectedFeedbacks,
    additionalFeedback,
    answerId
  } = eventData;
  const preparedEventData: EventData = {
    feedbackStatus: feedbackType,
    topResults: generateTopSearchResults(searchResults),
    total_search_results: totalSearchResults
  };
  if (answer) {
    const sourcesIndex = answer?.toLowerCase().indexOf("sources:");
    preparedEventData["answer_generated"] = answer;
    preparedEventData["sources"] =
      sourcesIndex && sourcesIndex !== -1
        ? answer?.slice(sourcesIndex + "sources:".length).trim()
        : "";
    preparedEventData["answerId"] = answerId;
  }
  if (additionalFeedback) {
    preparedEventData["additionalFeedback"] = additionalFeedback;
  }
  if (selectedFeedbacks?.length > 0) {
    preparedEventData["review"] = [selectedFeedbacks];
  }
  if (!isWebApp) {
    preparedEventData["ticket_title"] = ticketSubject;
    preparedEventData["ticket_id"] = ticketId;
  }
  preparedEventData[answer ? "question" : "search_query"] = searchQuery;
  triggerEvent(eventName, preparedEventData);
};

const prepareEventDataForTicketSummary = (
  eventName: string,
  eventData: EventData
): void => {
  const {
    feedbackType,
    selectedFeedbacks,
    ticketSummary,
    additionalFeedback,
    ticketSubject,
    ticketId
  } = eventData;
  const preparedEventData: EventData = {
    feedbackStatus: feedbackType
  };
  if (additionalFeedback) {
    preparedEventData["additionalFeedback"] = additionalFeedback;
  }
  if (selectedFeedbacks?.length > 0) {
    preparedEventData["review"] = [selectedFeedbacks];
  }
  if (ticketSummary) {
    preparedEventData["ticket_summary"] = ticketSummary;
  }
  if (!isWebApp) {
    preparedEventData["ticket_title"] = ticketSubject;
    preparedEventData["ticket_id"] = ticketId;
  }
  triggerEvent(eventName, preparedEventData);
};

const prepareEventDataForKbGen = (
  eventName: string,
  eventData: EventData
): void => {
  const {
    feedbackType,
    selectedFeedbacks,
    kbDraftContent,
    additionalFeedback,
    ticketSubject,
    ticketId
  } = eventData;
  const preparedEventData: EventData = {
    feedbackStatus: feedbackType
  };
  if (additionalFeedback) {
    preparedEventData["additionalFeedback"] = additionalFeedback;
  }
  if (selectedFeedbacks?.length > 0) {
    preparedEventData["review"] = [selectedFeedbacks];
  }
  if (kbDraftContent) {
    preparedEventData["kb_draft"] = kbDraftContent;
  }
  if (!isWebApp) {
    preparedEventData["ticket_title"] = ticketSubject;
    preparedEventData["ticket_id"] = ticketId;
  }
  triggerEvent(eventName, preparedEventData);
};

const triggerFeedbackEventBySource = (
  eventSource: string,
  isSubmitted: boolean,
  eventData: EventData
): void => {
  switch (eventSource) {
    case FeedbackSource.SEARCH:
      prepareEventDataForSearch(
        isSubmitted
          ? GTM_EVENTS.GTM_SEARCH_FEEDBACK_OVERVIEW
          : GTM_EVENTS.GTM_SEARCH_FEEDBACK_STATUS,
        eventData
      );
      break;
    case FeedbackSource.TICKET_SUMMARY:
      prepareEventDataForTicketSummary(
        isSubmitted
          ? GTM_EVENTS.GTM_SUPPORT_TICKET_SUMMARY_FEEDBACK_OVERVIEW
          : GTM_EVENTS.GTM_SUPPORT_TICKET_SUMMARY_FEEDBACK_STATUS,
        eventData
      );
      break;
    case FeedbackSource.KB_GEN:
      prepareEventDataForKbGen(
        isSubmitted
          ? GTM_EVENTS.GTM_SUPPORT_KB_GEN_FEEDBACK_OVERVIEW
          : GTM_EVENTS.GTM_SUPPORT_KB_GEN_FEEDBACK_STATUS,
        eventData
      );
      break;
    case FeedbackSource.ANSWERGPT:
      prepareEventDataForSearch(
        isSubmitted
          ? GTM_EVENTS.GTM_ANSWER_FEEDBACK_OVERVIEW
          : GTM_EVENTS.GTM_ANSWER_FEEDBACK_STATUS,
        eventData
      );
      break;
  }
};

export const useSearchFeedback = (
  showFeedback: boolean,
  eventSource: string,
  feedbackId?: string
): SearchFeedbackProps => {
  const { flags } = useFlags();
  const { feedbackTitle } = flags;

  const { answer } = useAppSelector((state) => state.answerGPT);

  const {
    totalSearchResults,
    searchQuery,
    searchResults,
    answerId,
    feedbackSubmittedIds
  } = useAppSelector((state) => state.search);

  const { ticket } = useAppSelector((state) => state.cookedTicket);
  const { ticketId, ticketSubject } = ticket;

  const { ticketSummary, kbDraftContent } = useAppSelector(
    (state) => state.ticketInsights
  );

  const [additionalFeedback, setAdditionalFeedback] = useState("");
  const [answerFeedbackType, setAnswerFeedbackType] = useState("");
  const [selectedFeedbacks, setSelectedFeedbacks] = useState<string[]>([]);
  const [feedbackMessageState, setFeedbackMessageState] = useState(
    FeedbackMessageState.DEFAULT
  );

  const [isVisible, setIsVisible] = useState(false);
  /* eslint-disable @typescript-eslint/no-explicit-any */
  let messageTimeout: any;

  const dispatch = useAppDispatch();

  const handleFeedbackSubmittedIds = (): void => {
    const newFeedbackSubmittedIds = { ...feedbackSubmittedIds };
    if (feedbackId) {
      newFeedbackSubmittedIds[eventSource] = feedbackId;
    }
    dispatch(updateFeedbackSubmittedIds(newFeedbackSubmittedIds));
  };

  const handleFeedbackSubmissionState = (): void => {
    handleFeedbackSubmittedIds();
    setFeedbackMessageState(FeedbackMessageState.SUBMIT);
  };

  const onFeedbackBtnClicked = (feedbackType: APPROVAL_STATUS): void => {
    setSelectedFeedbacks([]);
    setAnswerFeedbackType(feedbackType);
    setFeedbackMessageState(
      feedbackType === APPROVAL_STATUS.APPROVAL
        ? FeedbackMessageState.APPROVAL
        : FeedbackMessageState.DISAPPROVAL
    );

    messageTimeout = setTimeout(() => {
      if (feedbackType === APPROVAL_STATUS.APPROVAL) {
        handleFeedbackSubmissionState();
      } else {
        setFeedbackMessageState(FeedbackMessageState.ADDITIONAL);
      }
    }, 1000);
    triggerFeedbackEventBySource(eventSource, false, {
      feedbackType,
      totalSearchResults,
      searchQuery,
      answer,
      ticketSubject,
      ticketId,
      searchResults,
      ticketSummary,
      kbDraftContent,
      answerId
    });
  };

  const handleSubmit = (): void => {
    messageTimeout = setTimeout(() => {
      handleFeedbackSubmissionState();
    }, 1000);
    triggerFeedbackEventBySource(eventSource, true, {
      feedbackType: answerFeedbackType,
      totalSearchResults,
      searchQuery,
      answer,
      ticketSubject,
      ticketId,
      searchResults,
      selectedFeedbacks,
      additionalFeedback,
      ticketSummary,
      kbDraftContent,
      answerId
    });
  };

  const handleOptionClick = (label: string): void => {
    const newSelectedFeedbacks = selectedFeedbacks.includes(label)
      ? selectedFeedbacks.filter((item) => item !== label)
      : [...selectedFeedbacks, label];
    setSelectedFeedbacks(newSelectedFeedbacks);
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    // we are going to use this value in feedback events.
    setAdditionalFeedback(e.target.value);
  };

  const isOptionSelected = (label: string): boolean => {
    return selectedFeedbacks.includes(label);
  };

  const getFeedbackText = (): string => {
    switch (eventSource) {
      case FeedbackSource.SEARCH:
        return isWebApp
          ? feedbackTitle.searchFeedbackTitle
          : feedbackTitle.defaultFeedbackTitle;
      case FeedbackSource.TICKET_SUMMARY:
        return feedbackTitle.ticketSummaryFeedbackTitle;
      case FeedbackSource.KB_GEN:
        return feedbackTitle.kbGenFeedbackTitle;
      case FeedbackSource.ANSWERGPT:
        return feedbackTitle.answerFeedbackTitle;
      default:
        return feedbackTitle.defaultFeedbackTitle;
    }
  };

  const isFeedbackStateSubmitted = (): boolean => {
    return Boolean(
      feedbackId &&
        feedbackSubmittedIds.hasOwnProperty(eventSource) &&
        feedbackSubmittedIds[eventSource] === feedbackId
    );
  };

  useEffect(() => {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    let timeout: any;
    if (showFeedback) {
      timeout = setTimeout(() => {
        setIsVisible(true);
      }, 800);
    } else {
      setIsVisible(false);
    }

    return () => {
      clearTimeout(timeout);
      clearTimeout(messageTimeout);
    };
  }, [showFeedback, setIsVisible, messageTimeout]);

  return {
    additionalFeedback,
    isVisible,
    feedbackMessageState,
    isFeedbackStateSubmitted,
    onFeedbackBtnClicked,
    handleSubmit,
    handleOptionClick,
    handleInputChange,
    isOptionSelected,
    getFeedbackText,
    answerFeedbackType
  };
};
