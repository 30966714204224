import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";

function fetchKnowledgeBaseConfig(): Promise<string[]> {
  const url = `${getBackendApiUrl()}/config/ML_KNOWLEDGE_BASE_ARTICLE_BASE_URLS/`;

  return request<string[]>(url);
}

export { fetchKnowledgeBaseConfig };
