import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import { EdgeAuditLogType } from "@aptedge/lib-ui/src/types/entities";
import React from "react";
import { useQuery } from "react-query";
import { WebCacheKey } from "../../../clients/cache";
import { fetchEdgeAuditLogs } from "../../../clients/Edges/fetchEdgeAuditLogs";

import "./MergedIntoBadge.scss";

interface Props {
  id: number;
}

const MergedIntoBadge: React.FC<Props> = ({ id }) => {
  const auditLogs = useQuery([WebCacheKey.AUDIT_LOGS, id], () =>
    fetchEdgeAuditLogs(id, { sortBy: null, perPage: 10, page: 1 })
  );
  const wasMergedRecently = auditLogs.data?.items.find(
    (l) => l.type === EdgeAuditLogType.EDGE_MERGING
  );
  const mergedIntoData =
    !!wasMergedRecently &&
    wasMergedRecently.type === EdgeAuditLogType.EDGE_MERGING
      ? wasMergedRecently.data.to
      : undefined;
  const mergedEdgeId = !!mergedIntoData?.length
    ? mergedIntoData[0].id
    : undefined;

  if (!mergedEdgeId) {
    return null;
  }

  return (
    <div className="merged-into-badge">
      <Icons.ObjectGroup />
      Merged Into EDGE-{mergedEdgeId}
    </div>
  );
};

export { MergedIntoBadge };
