import { Card } from "@aptedge/lib-ui/src/components/Card/Card";
import React from "react";
import "./SlimCardSkeleton.scss";

function SlimCardSkeleton(): React.ReactElement {
  return (
    <Card className="skeleton">
      <h3 className="slim-card-title">Loading</h3>
      <div className="slim-card-content">
        <div className="value">0000</div>
        <div className="change">000</div>
      </div>
    </Card>
  );
}

export { SlimCardSkeleton };
