import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import { ITicketListing } from "@aptedge/lib-ui/src/types/entities";
import React from "react";
import { TicketItem } from "../../../components/TicketItem/TicketItem";

interface Props {
  ticket: ITicketListing;
  onDelete: (ticket: ITicketListing) => void;
  disabled?: boolean;
  onSelect: (ticket: ITicketListing) => void;
}

const EdgeTicket: React.FC<Props> = ({
  ticket,
  onDelete,
  disabled,
  onSelect
}) => {
  const handleDelete = (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ): void => {
    onDelete(ticket);
  };

  return (
    <TicketItem
      ticket={ticket}
      onSelect={onSelect}
      actions={
        <Icons.Times
          className="remove-icon"
          data-testid="delete-ticket"
          onClick={handleDelete}
        />
      }
      disabled={disabled}
    />
  );
};

export { EdgeTicket };
