import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import { Tooltip } from "@aptedge/lib-ui/src/components/Tooltip/Tooltip";
import { IEdgeListing } from "@aptedge/lib-ui/src/types/entities";
import React from "react";
import { TooltipMessage } from "../../../registry/TooltipMessage";
import { toAbbreviatedMonetaryStringFromNumber } from "../../../utils/value";
import { CellComponentProps } from "../useTable";
import "./EdgeRevenueCell.scss";

function EdgeRevenueCell(
  props: CellComponentProps<IEdgeListing>
): React.ReactElement {
  const { data: edge } = props;

  return (
    <div className="edge-revenue-cell">
      <Tooltip content={TooltipMessage.REVENUE_AT_RISK}>
        <div className="edge-revenue-cell-content">
          <Icons.MoneyBill />
          {toAbbreviatedMonetaryStringFromNumber(edge.revenueUsd || 0)}
        </div>
      </Tooltip>
    </div>
  );
}

export { EdgeRevenueCell };
