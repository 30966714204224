import { Select } from "@aptedge/lib-ui/src/components/Select/Select";
import React from "react";
import { toSortObject, toSortString } from "../../clients/utils/sort";
import "./PaginationSort.scss";

const PaginationSort: React.FunctionComponent<Props> = ({
  options,
  selected,
  defaultValue: defaultSort,
  onChange,
  label
}) => {
  const rules = toSortObject(selected);
  const selectedRule = rules ? rules[0] : defaultSort;

  const selectedOption = selectedRule
    ? options.find(
        (opt) => opt.id === selectedRule.id && opt.desc === selectedRule.desc
      )
    : undefined;

  return (
    <div className="pagination-sort">
      <Select
        data={options}
        value={selectedOption}
        label={label}
        getOptionLabel={(opt) => opt.label}
        // Always set page = 1, since we've resorted and
        // page 7 or whatever is meaningless in new context.
        onChange={(opt) =>
          onChange({ sortBy: toSortString(opt ? [opt] : null), page: 1 })
        }
      />
    </div>
  );
};

export type PaginationSortOption = {
  id: string;
  label: string;
  desc: boolean;
  omitLabelFromJoin?: boolean;
};

interface Props {
  options: PaginationSortOption[];
  defaultValue: PaginationSortOption;
  selected: string | null;
  onChange: (val: { sortBy: string | null; page: number }) => void;
  label?: string;
}

export { PaginationSort };
