import { Modal, ModalWidth } from "@aptedge/lib-ui/src/components/Modal/Modal";
import {
  ModalRouter,
  ModalRouterContext
} from "@aptedge/lib-ui/src/components/Modal/ModalRouter";
import {
  NoData,
  NoDataMessage
} from "@aptedge/lib-ui/src/components/NoData/NoData";
import { RelativeRange } from "@aptedge/lib-ui/src/types/date";
import { IEdgeInfo } from "@aptedge/lib-ui/src/types/entities";
import {
  IQueryArgs,
  removeEmptyFilters
} from "@aptedge/lib-ui/src/utils/query";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { WebCacheKey } from "../../../clients/cache";
import { fetchTickets } from "../../../clients/Tickets/fetchTickets";
import { toSortString } from "../../../clients/utils/sort";
import WithLoading from "../../../components/WithLoading/WithLoading";
import { AutoLinkProvider } from "../../../context/AutoLinkContext";
import { IPaginatedRequest } from "../../../types/clients";
import { Page } from "../AutoLink/AutoLinkRoutes";
import { EdgeTicketResults } from "./EdgeTicketResults";
import "./EdgeTicketsAddModal.scss";
import { EdgeTicketsFilterBar } from "./EdgeTicketsFilterBar";

const SORT = toSortString([{ id: "createdOn", desc: true }]);
const initialParams: IPaginatedRequest<IQueryArgs> = {
  page: 1,
  perPage: 10,
  filters: [],
  timeDimensions: [
    { id: "createdOn", values: [RelativeRange.TWO_WEEKS] },
    { id: "updatedOn", values: [RelativeRange.TWO_WEEKS] }
  ],
  sortBy: SORT
};

interface Props {
  edge: IEdgeInfo;
  isOpen: boolean;
  onClose: () => void;
  onUpdateEdge: (edge: IEdgeInfo) => void;
}

function EdgeTicketsAddModal(props: Props): React.ReactElement {
  const { edge, isOpen, onClose, onUpdateEdge } = props;

  const [params, setParams] = useState<IPaginatedRequest<IQueryArgs>>(
    initialParams
  );

  const apiParams = removeEmptyFilters(params);
  const hasFilters = !!apiParams.filters?.length;
  const renderTitle = hasFilters ? "Results" : "";

  const ticketsQuery = useQuery(
    [WebCacheKey.TICKET_SEARCH, apiParams],
    () => fetchTickets(apiParams),
    { enabled: hasFilters && isOpen }
  );

  const onUpdate = (edge: IEdgeInfo): void => {
    ticketsQuery.refetch();
    onUpdateEdge(edge);
  };

  // Cleanup when Modal re-opens.
  useEffect(() => {
    if (isOpen) {
      setParams(initialParams);
    }
  }, [isOpen]);

  return (
    <Modal
      title="Add Tickets"
      className="add-ticket-modal"
      isOpen={isOpen}
      onClose={onClose}
      width={ModalWidth.LARGE}
    >
      <AutoLinkProvider edge={edge}>
        <ModalRouter initialRoute={Page.START}>
          <EdgeTicketsFilterBar
            edgeId={edge.id}
            params={params}
            onChange={(partial) => {
              setParams((prev) => ({ ...prev, ...partial }));
            }}
          />
          <ModalRouterContext.Consumer>
            {(router) =>
              (router[0] === Page.START || router[0] === Page.CONDITIONS) && (
                <div className="add-ticket-modal-content">
                  <div className="list-title">
                    {router[0] === Page.CONDITIONS && hasFilters
                      ? "Condition Preview"
                      : renderTitle}
                  </div>
                  {hasFilters && (
                    <section>
                      <WithLoading
                        isLoading={ticketsQuery.isLoading}
                        type="overlay"
                      >
                        {ticketsQuery.data &&
                        !!ticketsQuery.data.items.length ? (
                          <EdgeTicketResults
                            edge={edge}
                            onUpdate={onUpdate}
                            results={ticketsQuery.data.items}
                          />
                        ) : (
                          <NoData message={NoDataMessage.SEARCH_TICKETS} />
                        )}
                      </WithLoading>
                    </section>
                  )}
                </div>
              )
            }
          </ModalRouterContext.Consumer>
        </ModalRouter>
      </AutoLinkProvider>
    </Modal>
  );
}

export { EdgeTicketsAddModal };
