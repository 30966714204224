import { useSearchFilterData } from "@aptedge/lib-ui/src/features/SearchFilters/useSearchFilter";
import {
  AptAnswersFilterType,
  AptAnswersFilterRuleUpdate,
  AptAnswersIntegrationsObject
} from "@aptedge/lib-ui/src/types/entities";
import classNames from "classnames";
import * as React from "react";
import { FilterActions } from "./FilterActions";
import { FilterDescription } from "./FilterDescription";
import { FilterHeader } from "./FilterHeader";
import { FilterRules } from "./FilterRules";
import styles from "./IntegrationItem.module.scss";
import { getAptAnswersFilterInfo } from "./utils/getAptAnswersFilterInfo";
import { getIntegrationInfo } from "./utils/getIntegrationInfo";
import {
  IntegrationStateUpdateType,
  IntegrationUpdate
} from "./utils/updateIntegrationState";

interface IntegrationItemProps {
  filterType: AptAnswersFilterType;
  integration: AptAnswersIntegrationsObject;
  isTop: boolean;
  isBottom: boolean;
  filterName: string;
  handleRulesUpdate: (arg0: IntegrationUpdate) => void;
  handleItemSave?: (integrationName: string) => void;
}

const IntegrationItem: React.FC<IntegrationItemProps> = ({
  filterType,
  integration,
  isTop,
  isBottom,
  filterName,
  handleRulesUpdate,
  handleItemSave
}) => {
  const { filterPurpose, filterConditions } = getAptAnswersFilterInfo(
    filterType
  );

  const { searchFilterList } = useSearchFilterData();
  const integrationInfo = getIntegrationInfo(
    integration.name,
    searchFilterList
  );
  const integrationName = integrationInfo.label;
  const integrationIcon = integrationInfo.iconSrc;

  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);
  const handleExpandClick = (): void => {
    setIsExpanded(!isExpanded);
  };

  const manageAllSelected = integration.filter?.manageAllSelected ?? false;

  const hasExistingRules =
    !!integration?.filter?.id && integration?.filter?.filterRules.length > 0;
  const filterRules = integration?.filter?.filterRules;

  const handleAddRule = (): void => {
    handleRulesUpdate({
      integrationName: integration.name,
      updateType: IntegrationStateUpdateType.ADD_RULE,
      ruleIdx: filterRules?.length ?? 0
    });
  };

  const handleDeleteRule = (idx: number): void => {
    handleRulesUpdate({
      integrationName: integration.name,
      updateType: IntegrationStateUpdateType.DELETE_RULE,
      ruleIdx: idx
    });
  };

  const handleManageAllClick = (): void => {
    if (manageAllSelected) {
      handleRulesUpdate({
        integrationName: integration.name,
        updateType: IntegrationStateUpdateType.UNSELECT_ALL,
        ruleIdx: filterRules?.length ?? 0
      });
    } else {
      handleRulesUpdate({
        integrationName: integration.name,
        updateType: IntegrationStateUpdateType.SELECT_ALL,
        ruleIdx: filterRules?.length ?? 0
      });
    }
  };

  const handleRuleUpdate = ({
    idx,
    key,
    value
  }: AptAnswersFilterRuleUpdate): void => {
    handleRulesUpdate({
      integrationName: integration.name,
      updateType: IntegrationStateUpdateType.UPDATE_RULE,
      ruleIdx: idx,
      ruleUpdate: { idx, key, value }
    });
  };

  const resetOnSave = (): void => {
    setIsExpanded(false);
  };

  return (
    <div
      className={classNames(styles.container, {
        [styles.containerTop]: isTop,
        [styles.containerBottom]: isBottom
      })}
    >
      <FilterHeader
        integrationName={integrationName}
        integrationIcon={integrationIcon}
        filterPurpose={filterPurpose}
        manageAllSelected={integration.filter?.manageAllSelected ?? false}
        rules={integration.filter?.filterRules}
        isExpanded={isExpanded}
        handleExpandClick={handleExpandClick}
      />
      {isExpanded && (
        <div className={styles.contentContainer}>
          <FilterDescription
            filterPurpose={filterPurpose}
            integrationName={integrationName}
          />
          <FilterRules
            rules={integration.filter?.filterRules ?? []}
            fields={integration.fields}
            hasExistingRules={hasExistingRules}
            filterConditions={filterConditions}
            handleRuleUpdate={handleRuleUpdate}
            handleDeleteRule={handleDeleteRule}
            ruleEditIsDisabled={manageAllSelected}
          />
          <div className={styles.actionsContainer}>
            <FilterActions
              filterPurpose={filterPurpose}
              integrationId={integration.name}
              integrationName={integrationName}
              handleItemSave={handleItemSave}
              resetOnSave={resetOnSave}
              handleAddRule={handleAddRule}
              manageAllSelected={manageAllSelected}
              handleManageAllClick={handleManageAllClick}
              addRuleIsDisabled={manageAllSelected}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export { IntegrationItem };
