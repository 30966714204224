import { ITicketInfo } from "@aptedge/lib-ui/src/types/entities";
import React from "react";
import { TicketComment } from "./TicketComment";

interface Props {
  ticket: ITicketInfo;
}

const TicketThread: React.FC<Props> = ({ ticket }) => {
  if (!ticket.comments) {
    return null;
  }
  return (
    <div className="main-section ticket-thread">
      {ticket.comments.map((c) => {
        const comment = {
          ...c,
          plainText: c.plainText.replace(/&nbsp;/g, " ")
        };
        return <TicketComment key={c.id} comment={comment} ticket={ticket} />;
      })}
    </div>
  );
};

export { TicketThread };
