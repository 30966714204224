import { ISavedQuery } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import {
  IFetchSavedQueryArgs,
  IPaginatedRequest,
  IPaginatedResponse
} from "../../types/clients";
import { request } from "../../utils/request";
import { toQueryString } from "../utils/url";

function fetchSavedQueries(
  args: IPaginatedRequest<IFetchSavedQueryArgs>
): Promise<IPaginatedResponse<ISavedQuery[]>> {
  const query = toQueryString(args);

  const url = `${getBackendApiUrl()}/saved-filters/?${query}`;

  return request(url);
}

export { fetchSavedQueries };
