import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";

function fetchTicketStatuses(): Promise<{ statuses: string[] }> {
  const url = `${getBackendApiUrl()}/tickets/statuses/`;

  return request(url);
}

export { fetchTicketStatuses };
