import { IDynamicIntegrations } from "@aptedge/lib-ui/src/types/entities";
import { getBackendUrl } from "../../../config";
import { request } from "../../../utils/request";

function fetchIntegrations(): Promise<IDynamicIntegrations[]> {
  const url = `${getBackendUrl()}/api/dynamic/settings`;

  return request(url);
}

export { fetchIntegrations };
