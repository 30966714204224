import { Skeleton } from "@mui/material";
import classNames from "classnames";
import { FC } from "react";
import { IFlagSet } from "../../../src/context/FlagsContext/types";
import { isWebApp } from "../../utils/event";
import "./SearchSkeletonLoader.scss";

interface SearchSkeletonLoaderProps {
  lines: number;
  flags?: IFlagSet;
}
const SearchSkeletonLoader: FC<SearchSkeletonLoaderProps> = ({
  lines,
  flags
}) => (
  <>
    {[...Array(lines)].map((_, i) => (
      <div
        data-testid="search-skeleton"
        key={i}
        className={classNames("search-result-loader", {
          "support-app-loader": !isWebApp
        })}
      >
        <div
          className={classNames("search-header", {
            "support-app-header": !isWebApp
          })}
        >
          <div
            className={classNames("search-title", { "align-title": !isWebApp })}
          >
            <Skeleton width="10%" />
            <Skeleton
              width={isWebApp ? "60%" : "90%"}
              className="skeleton-body"
            />
          </div>
          {isWebApp && (
            <div className="search-action">
              <Skeleton width="15%" />
              <Skeleton width="15%" className="skeleton-body" />
            </div>
          )}
        </div>
        <div>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </div>
      </div>
    ))}
  </>
);

export default SearchSkeletonLoader;
