import {
  IAzureDevopsIssuesSourceRequest,
  IAzureDevopsIssuesSource
} from "@aptedge/lib-ui/src/types/entities";
import { getBackendUrl } from "../../config";
import { request } from "../../utils/request";
import { post } from "../utils/buildRequest";

async function createAzureDevopsInternalIssueSource(
  args: IAzureDevopsIssuesSourceRequest
): Promise<IAzureDevopsIssuesSource> {
  const url = `${getBackendUrl()}/azure-devops/collect/`;

  const options: RequestInit = post(args);

  return request(url, options);
}

export { createAzureDevopsInternalIssueSource };
