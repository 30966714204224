import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import { AptAnswersFilterType } from "@aptedge/lib-ui/src/types/entities";
import * as React from "react";
import { useMutation, useQueryClient } from "react-query";
import { createFilter } from "../../clients/AptAnswers/createFilter";
import { updateFilter } from "../../clients/AptAnswers/updateFilter";
import { WebCacheKey } from "../../clients/cache";
import { useAptAnswersFilters } from "../../hooks/useAptAnswersFilters";
import { AptAnswersErrorMessage } from "./AptAnswersErrorMessage";
import { AptAnswersFilters } from "./AptAnswersFilters";
import styles from "./CustomerSelfServiceFilters.module.scss";
import { getAptAnswersFilterInfo } from "./utils/getAptAnswersFilterInfo";
import { getFilterRulesForPayload } from "./utils/getFilterRulesForPayload";
import { mergeFiltersWithIntegrations } from "./utils/mergeFiltersWithIntegrations";

const CustomerSelfServiceFilters: React.FC = () => {
  const { filterPurpose, filterType } = getAptAnswersFilterInfo(
    AptAnswersFilterType.CSS_FILTER
  );
  const filterName = AptAnswersFilterType.CSS_FILTER;

  // Get integration and filter data
  const {
    isError,
    isLoading,
    isSuccess,
    filtersData,
    integrationsData
  } = useAptAnswersFilters(filterType);

  // Merge integration and filter data
  const integrations = React.useMemo(
    () =>
      mergeFiltersWithIntegrations({
        filterName,
        filtersData,
        integrationsData
      }),
    [filterName, filtersData, integrationsData]
  );

  const [integrationValues, setIntegrationValues] = React.useState(
    integrations
  );

  React.useEffect(() => {
    // filtersData can be {}, which will always cause a re-render in this useEffect
    // Even useMemo doesn't work, so we check to make sure that filtersData is
    // not an empty object before updating
    if (
      integrations &&
      (integrationValues.length === 0 || Object.keys(filtersData).length !== 0)
    ) {
      setIntegrationValues(integrations);
    }
  }, [integrations, filtersData, integrationValues, setIntegrationValues]);

  // Define mutations
  const queryClient = useQueryClient();
  const resetOnMutation = React.useCallback(() => {
    queryClient.invalidateQueries([
      WebCacheKey.APT_ANSWERS_FILTERS,
      filterType
    ]);
  }, [queryClient, filterType]);
  const createFilterMutation = useMutation(createFilter, {
    onSuccess: () => {
      resetOnMutation();
    }
  });
  const updateFilterMutation = useMutation(updateFilter, {
    onSuccess: () => {
      resetOnMutation();
    }
  });

  // Execute mutations
  const handleItemSave = (integrationName: string): void => {
    const integration = integrationValues.find((integration) => {
      return integration.name === integrationName;
    });
    if (!integration || !integration.filter) {
      return;
    }
    const filterRules = getFilterRulesForPayload({
      filterPurpose,
      integration
    });
    integration.filter.id
      ? updateFilterMutation.mutate({
          filters: [
            {
              id: integration.filter.id,
              name: filterName,
              filterType,
              filterRules
            }
          ]
        })
      : createFilterMutation.mutate({
          filters: [
            {
              name: filterName,
              filterType,
              filterRules
            }
          ]
        });
  };

  return (
    <div className={styles.container}>
      {isLoading && <Spinner />}
      {isError && (
        <AptAnswersErrorMessage message="There as an error loading Knowledge Sources." />
      )}
      {isSuccess && (
        <AptAnswersFilters
          filterType={filterType}
          filterName={filterType}
          integrations={integrationValues}
          setIntegrationValues={setIntegrationValues}
          handleItemSave={handleItemSave}
        />
      )}
    </div>
  );
};

export { CustomerSelfServiceFilters };
