import classNames from "classnames";
import React, {
  ChangeEventHandler,
  forwardRef,
  PropsWithChildren,
  useEffect
} from "react";
import { Feedback } from "../Feedback/Feedback";
import { Icons } from "../Icon/Icons";
import "./TextInput.scss";

export interface TextInputProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  id?: string;
  name?: string;
  type?: string;
  label?: string;
  value?: string;
  error?: string;
  isDisabled?: boolean;
  className?: string;
  autoFocus?: boolean;
  placeholder?: string;
  inline?: boolean;
  readOnly?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  indicators?: React.ReactNode;
  onClear?: () => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  size?: "regular" | "small";
}

const TextInput = forwardRef<
  HTMLInputElement,
  PropsWithChildren<TextInputProps>
>((props, ref) => {
  const {
    className,
    label,
    onClear,
    error,
    children,
    autoFocus = false,
    value = "",
    isDisabled,
    inline,
    indicators,
    readOnly,
    size = "regular",
    ...rest
  } = props;
  const hasValue = !!value;
  const hasLabel = label !== undefined;
  const isReadOnly = !!readOnly || !!isDisabled;
  const showError = !isDisabled && !!error;

  useEffect(() => {
    if (autoFocus && ref && typeof ref !== "function") {
      ref.current?.focus();
    }
  }, [autoFocus, ref]);

  return (
    <div
      className={classNames("text-input", className, size, {
        error,
        inline,
        label: hasLabel,
        disabled: isDisabled
      })}
    >
      {label && <label>{label}</label>}
      <div className="input-container">
        <input
          ref={ref}
          type="text"
          value={value}
          readOnly={isReadOnly}
          {...rest}
        />
        <div className="indicator-container">
          {onClear && hasValue && <Icons.Times onClick={onClear} />}
          {indicators}
          {showError && <Icons.AlertCircle className="text-danger" />}
        </div>
      </div>
      {showError && <Feedback>{error}</Feedback>}
    </div>
  );
});

TextInput.displayName = "TextInput";

export { TextInput };
