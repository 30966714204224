import { FC } from "react";
import { ToolbarButtonProps } from "../../hooks/useCustomEditor";
import { Icon } from "../Icon/Icon";

const ToolbarButton: FC<ToolbarButtonProps> = ({
  onClick,
  icon,
  label,
  customIcon
}) => {
  let buttonBody;
  if (icon) {
    buttonBody = <Icon icon={icon} />;
  } else if (customIcon) {
    buttonBody = customIcon;
  } else {
    buttonBody = label;
  }

  return (
    <button onClick={onClick} title={label}>
      {buttonBody}
    </button>
  );
};

export { ToolbarButton };
