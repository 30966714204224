import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { Routes } from "./routes";

const OnboardedRoute: React.FunctionComponent<Omit<
  RouteProps,
  "component"
>> = ({ children, ...rest }) => {
  const { user } = useAuth();

  return (
    <Route
      {...rest}
      render={() => {
        if (user?.admin || user?.onboardingComplete) {
          return <>{children}</>;
        }

        return <Redirect to={Routes.WELCOME} />;
      }}
    />
  );
};

export { OnboardedRoute };
