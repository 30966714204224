import { IUserListData } from "@aptedge/lib-ui/src/types/entities";
import { useQuery, UseQueryResult } from "react-query";
import { fetchBatchUserList } from "../../clients/AdminDashboard/fetchBatchUserList";
import { WebCacheKey } from "../../clients/cache";

function useUserBatchQuery(
  userIDs: number[] | undefined
): UseQueryResult<IUserListData[]> {
  return useQuery(
    [WebCacheKey.BATCH_USER_LIST, userIDs],
    () => fetchBatchUserList({ ids: userIDs }),
    { enabled: !!userIDs }
  );
}

export { useUserBatchQuery };
