import { FilterWrapper } from "@aptedge/lib-ui/src/components/Filter/FilterWrapper";
import { IQueryFilter } from "@aptedge/lib-ui/src/components/Filter/types";
import { MultiSelect } from "@aptedge/lib-ui/src/components/MultiSelect/MultiSelect";
import React from "react";
import { useTicketReasons } from "../../hooks/clients/useTicketReasons";
import { TICKET_REASON_FILTER } from "./data";

type Props = {
  value?: IQueryFilter;
  onChange: (val: IQueryFilter) => void;
};

function TicketReasonFilter(props: Props): React.ReactElement {
  const ticketReasonsQuery = useTicketReasons();

  const reasons =
    ticketReasonsQuery.data?.reasons
      .slice()
      .sort((a, b) => a.localeCompare(b)) || [];

  return (
    <FilterWrapper {...TICKET_REASON_FILTER} {...props}>
      {({ values, onChange }) => (
        <MultiSelect
          name="reason-filter"
          value={values}
          data={reasons}
          getOptionLabel={(status) => status}
          onChange={(statuses) => onChange(statuses)}
        />
      )}
    </FilterWrapper>
  );
}

export { TicketReasonFilter };
