import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { Feedback } from "@aptedge/lib-ui/src/components/Feedback/Feedback";
import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import classNames from "classnames";
import { useFormik } from "formik";
import React, { createRef, useEffect } from "react";
import { useMutation } from "react-query";
import * as Yup from "yup";
import { createTeam } from "../../../clients/Teams/createTeam";
import WithLoading from "../../../components/WithLoading/WithLoading";
import { createErrorMessage } from "../../../utils/createErrorMessage";

interface Props {
  className?: string;
  onSave: () => void;
  onCancel: () => void;
}

interface FormState {
  name: string;
}

const TeamCreateItem: React.FC<Props> = (props) => {
  const { className, onCancel, onSave } = props;

  const inputRef = createRef<HTMLInputElement>();

  const addTeam = useMutation(createTeam, {
    onSuccess: onSave,
    onError: (e) => {
      formik.setFieldError("name", createErrorMessage(e));
    }
  });

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  const formik = useFormik<FormState>({
    initialValues: {
      name: ""
    },
    onSubmit: (values, helpers) => {
      addTeam.mutate({ name: values.name });
    },
    validateOnBlur: false,
    validationSchema: Yup.object({
      name: Yup.string().required("A name for the team is required.")
    })
  });

  return (
    <WithLoading
      isLoading={addTeam.isLoading}
      type="overlay"
      fallback={<Spinner />}
    >
      <div className={classNames("label-create-item p-2", className)}>
        <h5>Add a new team</h5>
        <form onSubmit={formik.handleSubmit}>
          <div className="form-row">
            <div className="form-group col">
              <label htmlFor="name">Team</label>
              <input
                id="name"
                type="text"
                ref={inputRef}
                className={classNames("form-control", {
                  "is-invalid": !!formik.errors.name
                })}
                value={formik.values.name}
                onChange={formik.handleChange}
              />
              {formik.errors.name && <Feedback>{formik.errors.name}</Feedback>}
            </div>
          </div>
          <div className="d-flex mt-2 justify-content-end">
            <Button
              type="button"
              className="mr-2"
              color="secondary"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Save
            </Button>
          </div>
        </form>
      </div>
    </WithLoading>
  );
};

export { TeamCreateItem };
