import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import { FC, useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { useWhoami } from "../clients/User/useWhoami";
import { toQueryString } from "../clients/utils/url";
import { Routes } from "./routes";

const handleDynamicUrl = (url: string): string => {
  const regex = /\/edges\/([^/]+)\/files\/([^/]+)/;
  const match = url.match(regex);

  if (match) {
    const sourceType = match[1];
    if (sourceType === "sharepointdrive") {
      return `/api${url}`;
    }
  }
  return "";
};

const DocumentFiles: FC = () => {
  const { data: user, isLoading, isFetching } = useWhoami();
  const { pathname } = window.location;
  const updatedUrl = handleDynamicUrl(pathname);

  const [redirectStarted, setRedirectStarted] = useState(false);

  useEffect(() => {
    if (
      !isFetching &&
      !isLoading &&
      user?.userEmail &&
      updatedUrl &&
      !redirectStarted
    ) {
      setRedirectStarted(true);
      window.location.href = updatedUrl;
    }
  }, [user, updatedUrl, isLoading, isFetching, redirectStarted]);

  if (!isFetching && !isLoading && !redirectStarted && !user?.userEmail) {
    return (
      <Route
        render={({ location }) => {
          const redirectTo = location.pathname + location.search;
          return (
            <Redirect
              to={{
                pathname: Routes.LOGIN,
                search: toQueryString({ redirectTo })
              }}
            />
          );
        }}
      />
    );
  }

  if (isLoading || isFetching || redirectStarted) {
    return (
      <div className="d-flex justify-content-center my-auto">
        <Spinner />
      </div>
    );
  }

  return null;
};

export { DocumentFiles };
