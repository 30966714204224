import { TimeDimensionWrapper } from "@aptedge/lib-ui/src/components/Filter/TimeDimensionWrapper";
import {
  ITimeDimensionFilter,
  ITimeDimensionFilterDefinition,
  ITimeDimensionFilterId
} from "@aptedge/lib-ui/src/components/Filter/types";
import { DateTime } from "luxon";
import React, { useContext, useState } from "react";
import { SavedQueriesContext } from "../../context/SavedQueriesContext";

type DimensionProps = Omit<
  React.ComponentPropsWithoutRef<typeof TimeDimensionWrapper>,
  "children"
>;

type Props = {
  specs: ITimeDimensionFilterDefinition[];
  dimensions?: ITimeDimensionFilter[];
  pinned?: ITimeDimensionFilterId[];
  dateRangeMin?: DateTime;
  dimensionsMap: { [key: string]: React.ComponentType<DimensionProps> };
  selectedTimeDimension?: ITimeDimensionFilter;
  onChange: (filters: ITimeDimensionFilter) => void;
};

function TimeDimensions(props: Props): React.ReactElement {
  const {
    specs,
    dimensions = [],
    pinned,
    dimensionsMap,
    onChange,
    selectedTimeDimension
  } = props;

  const saved = useContext(SavedQueriesContext);
  const [selectedFilter, setSelectedFilter] = useState<ITimeDimensionFilter>({
    id: "createdOn",
    values: ["2W"]
  });

  const displayedDimensions = [...dimensions];

  if (pinned) {
    pinned.forEach((p) => {
      const pinnedSpec = specs.find((f) => f.id === p);
      const match = dimensions.findIndex((f) => f.id === p);

      if (match === -1 && pinnedSpec) {
        displayedDimensions.unshift({ id: p, values: [] });
      }
    });
  }

  const handleChange = (filter: ITimeDimensionFilter): void => {
    const match = displayedDimensions.find(
      (dimension) => dimension.id === filter.id
    );

    if (!match || match.id !== filter.id) {
      return;
    }

    setSelectedFilter(filter);
    if (!saved.editing) {
      saved.setCurrentSavedQuery(undefined);
    }

    onChange(filter);
  };

  const handleRemove = (filter: ITimeDimensionFilter, idx: number): void => {
    const match = displayedDimensions[idx];

    if (!match || match.id !== filter.id) {
      return;
    }

    if (!saved.editing) {
      saved.setCurrentSavedQuery(undefined);
    }

    onChange(filter);
  };

  const TimeDimensionComponent = dimensionsMap[specs[0].id];
  const currentdisplayedDimension = displayedDimensions.find(
    (dimension) => dimension.id === selectedFilter.id
  );
  const isPinned = pinned?.find((p) => p === currentdisplayedDimension?.id);

  return (
    <TimeDimensionComponent
      dateRangeMin={props.dateRangeMin}
      specs={specs}
      dimensions={dimensions}
      selectedTimeDimension={selectedTimeDimension}
      value={currentdisplayedDimension}
      error={!isPinned && !currentdisplayedDimension?.values}
      onChange={(newValue) => handleChange(newValue)}
      onRemove={(idx) => handleRemove(selectedFilter, idx)}
    />
  );
}

export { TimeDimensions };
