import { Placement } from "@popperjs/core";
import classNames from "classnames";
import React, { MouseEventHandler, useRef, useState } from "react";
import { Manager, Popper, Reference } from "react-popper";
import { useOnKeydownOutside } from "../../hooks/useOnKeyDownOutside";
import { useOnMouseDownOutside } from "../../hooks/useOnMouseDownOutside";
import { Keys } from "../../styles/keys";
import { findChildByType } from "../../utils/reactUtils";
import { DEFAULT_PREVENT_OVERFLOW_MODIFIER } from "../Popper/popper";
import { PopperContent } from "../Popper/PopperContent";
import "./Dropdown.scss";

interface ItemProps {
  className?: string;
  onClick?: () => void;
  disableToggleOnItem?: boolean;
}

interface DropdownProps {
  className?: string;
  placement?: Placement;
  disabled?: boolean;
}

interface IContext {
  toggle: (active: boolean) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const noop = (...args: any[]): any => null;

const DropdownContext = React.createContext<IContext>({
  toggle: noop
});

const Toggle: React.FC = ({ children }) => <>{children}</>;
const Menu: React.FC = ({ children }) => <>{children}</>;

Toggle.displayName = "DropdownToggle";
Menu.displayName = "DropdownMenu";

const Item: React.FC<ItemProps> = ({ children, className, ...props }) => {
  const onClickHandler = (toggle: (active: boolean) => void): void => {
    if (props.onClick) {
      props.onClick();
    }
    if (props?.disableToggleOnItem) {
      return;
    }
    toggle(false);
  };

  return (
    <DropdownContext.Consumer>
      {({ toggle }) => (
        <div
          className={classNames("option", className)}
          onMouseDown={(e) => e.preventDefault()}
          onClick={() => onClickHandler(toggle)}
        >
          {children}
        </div>
      )}
    </DropdownContext.Consumer>
  );
};

const Container: React.FC<DropdownProps> = ({
  children,
  placement = "bottom-end",
  disabled = false,
  ...props
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const [active, setActive] = useState<boolean>(false);

  const toggle = findChildByType(children, Dropdown.Toggle);
  const menu = findChildByType(children, Dropdown.Menu);

  const handleToggle: MouseEventHandler<HTMLDivElement> = (e) => {
    if (!disabled) {
      e.preventDefault();
      setActive(!active);
    }
  };

  // Register listeners for clicking out and pressing ESC/TAB.
  useOnKeydownOutside(menuRef, [Keys.ESCAPE, Keys.TAB], () => setActive(false));
  useOnMouseDownOutside(containerRef, (e) => {
    setActive(false);
  });

  return (
    <div
      className={classNames("dropdown", props.className, { open: active })}
      onClick={(e) => e.stopPropagation()}
      ref={containerRef}
    >
      <Manager>
        <Reference>
          {({ ref }) => (
            <div
              className={classNames("toggle", { disabled })}
              ref={ref}
              onClick={handleToggle}
              tabIndex={0}
            >
              {toggle}
            </div>
          )}
        </Reference>
        {active && (
          <Popper
            placement={placement}
            modifiers={[
              { name: "offset", options: { offset: [0, 4] } },
              DEFAULT_PREVENT_OVERFLOW_MODIFIER
            ]}
          >
            {({ ref, style, placement, update }) => (
              <PopperContent
                className="popper"
                ref={ref}
                style={style}
                placement={placement}
                scheduleUpdate={update}
              >
                <DropdownContext.Provider value={{ toggle: setActive }}>
                  <div className="content" ref={menuRef}>
                    {menu}
                  </div>
                </DropdownContext.Provider>
              </PopperContent>
            )}
          </Popper>
        )}
      </Manager>
    </div>
  );
};

const Dropdown = {
  Container,
  Toggle,
  Menu,
  Item
};

export { Dropdown };
