const PROD_ENV = "production";
const DEV_ENV = "development";
const TEST_ENV = "test";

function isProd(): boolean {
  return process.env.NODE_ENV === PROD_ENV;
}

function isDev(): boolean {
  return process.env.NODE_ENV === DEV_ENV;
}

function isTestEnv(): boolean {
  return process.env.NODE_ENV === TEST_ENV;
}

function isProdRelease(): boolean {
  return isProd() && !!getRelease();
}

function getRelease(): string | undefined {
  return process.env.COMMIT_HASH;
}

function printRelease(): void {
  console.log(
    `%cAptEdge release: ${process.env.COMMIT_HASH}`,
    "color: #06778c; font-weight: 900;"
  );
}

function inDevOnly(cb: () => void): void {
  if (isDev()) cb();
}

function inProdOnly(cb: () => void): void {
  if (isProd()) cb();
}

function inProdReleaseOnly(cb: () => void): void {
  if (isProdRelease()) cb();
}

export {
  getRelease,
  inDevOnly,
  inProdOnly,
  inProdReleaseOnly,
  isProd,
  isProdRelease,
  isTestEnv,
  printRelease,
  PROD_ENV,
  DEV_ENV,
  TEST_ENV
};
