import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import { TextInput } from "@aptedge/lib-ui/src/components/TextInput/TextInput";
import { SavedQueryType } from "@aptedge/lib-ui/src/types/entities";
import {
  IQueryArgs,
  toLZCompressedString
} from "@aptedge/lib-ui/src/utils/query";
import { useFormik } from "formik";
import React, { useContext, useRef } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import { WebCacheKey } from "../../clients/cache";
import { createSavedQuery } from "../../clients/SavedQueries/createSavedQuery";
import { updateSavedQuery } from "../../clients/SavedQueries/updateSavedQuery";
import { SavedQueriesContext } from "../../context/SavedQueriesContext";
import { getLZQuery } from "../../hooks/useLZQuery";
import { IPaginatedRequest } from "../../types/clients";

interface FormState {
  name: string;
}

interface Props {
  type: SavedQueryType;
}

const UpdateSavedFilter: React.FC<Props> = ({ type }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { search } = useLocation();
  const queryClient = useQueryClient();
  const saved = useContext(SavedQueriesContext);

  const { mutate: update } = useMutation(updateSavedQuery, {
    onSuccess: (value) => {
      queryClient.invalidateQueries(WebCacheKey.SAVED_QUERIES);
      formik.resetForm();
      saved.setCurrentSavedQuery(value);
      saved.setEditing(false);
    }
  });
  const { mutate: save } = useMutation(createSavedQuery, {
    onSuccess: (value) => {
      queryClient.invalidateQueries(WebCacheKey.SAVED_QUERIES);
      formik.resetForm();
      saved.setCurrentSavedQuery(value);
      saved.setEditing(false);
    }
  });

  const formik = useFormik<FormState>({
    enableReinitialize: true,
    initialValues: { name: saved.currentSavedQuery?.name || "" },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("A name is required.")
        .max(120, "A Saved Filter name can be no longer than 120 characters.")
    }),
    onSubmit: (data: FormState): void => {
      // Saves only the filters/time dimensions in the query string
      const { timeDimensions, filters } = getLZQuery<
        IPaginatedRequest<IQueryArgs>
      >(search);
      const query = toLZCompressedString({ timeDimensions, filters });

      if (!!saved.currentSavedQuery) {
        update({
          id: saved.currentSavedQuery.id,
          name: data.name,
          query
        });
      } else if (!!query) {
        save({
          name: data.name,
          type,
          query
        });
      }
    }
  });

  const handleCancel = (): void => {
    saved.setEditing(false);
    saved.setCurrentSavedQuery(undefined);
    formik.resetForm();
  };

  if (!saved.editing) {
    return null;
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="create-filter">
        <div>
          <Icons.StarFilled className="mr-1" />
          <TextInput
            size="small"
            placeholder="New Filter Name"
            name="name"
            ref={inputRef}
            autoFocus
            inline
            error={formik.errors.name}
            value={formik.values.name}
            onChange={formik.handleChange}
            onClear={() => formik.setFieldValue("name", "")}
          />
        </div>
        <div className="d-inline-flex">
          <Button className="mr-3" color="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          {!!saved.currentSavedQuery ? (
            <>
              <Button
                className="mr-3"
                color="danger"
                onClick={() => saved.setDeleteModalOpen(true)}
              >
                Delete
              </Button>
              <Button type="submit">Update</Button>
            </>
          ) : (
            <Button type="submit">Save</Button>
          )}
        </div>
      </div>
    </form>
  );
};

export { UpdateSavedFilter };
