import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import { FC } from "react";
import { ArticleFilter } from "../../components/ArticleFilter/ArticleFilter";
import { ArticleSearch } from "../../components/ArticleSearch/ArticleSearch";
import styles from "./Header.module.scss";

const Header: FC = () => {
  const { flags } = useFlags();

  return (
    <>
      <h3 className={styles.heading}>Articles</h3>
      {flags.kbGenPublishProcess && (
        <div className={styles.articleFilterContainer}>
          <ArticleSearch />
          <ArticleFilter />
        </div>
      )}
    </>
  );
};

export { Header };
