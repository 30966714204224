/* eslint-disable @typescript-eslint/no-explicit-any */
import { IPaginatedResponse } from "../../types/clients";

function unwrapPaginatedClient<D, A extends any[]>(
  client: (...args: A) => Promise<IPaginatedResponse<D>>
): (...args: A) => Promise<D> {
  return (...args) => client(...args).then((data) => data.items);
}

export { unwrapPaginatedClient };
