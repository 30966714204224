/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from "classnames";
import { LocationDescriptor } from "history";
import React from "react";
import { Link } from "react-router-dom";
import "./ListItem.scss";

const ListItem: React.FunctionComponent<Props> = (props) => {
  const { to, className, ...rest } = props;
  const Wrapper = !!to ? Link : "div";
  const resolvedProps = {
    className: classNames("list-item", className),
    to,
    ...rest
  } as any;

  return React.createElement(Wrapper, resolvedProps);
};

interface Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  to?: LocationDescriptor;
}

export { ListItem };
