import { useOutsideAlerter } from "@aptedge/lib-ui/src/hooks/useOutsideClick";
import { useAppSelector } from "@aptedge/lib-ui/src/redux/hook/hook";
import {
  ArticleReviewer,
  ArticleStatus,
  GeneratedKnowledgeResult
} from "@aptedge/lib-ui/src/types/entities";
import { GTM_EVENTS, dataLayerPush } from "@aptedge/lib-ui/src/utils/event";
import { RefObject, useRef, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { WebCacheKey } from "../clients/cache";
import { deleteArticle } from "../clients/GeneratedKnowledge/deleteArticle";
import { editArticle } from "../clients/GeneratedKnowledge/editArticle";

type UseArticleActionsProps = {
  isDiscardOpen: boolean;
  dropdownRef: RefObject<HTMLDivElement>;
  handleSaveArticle: () => void;
  handleHideDiscard: () => void;
  handleDeleteArticle: () => void;
  handleShowDiscard: () => void;
};

const triggerEvent = (
  eventName: string,
  articleId: string,
  articleTitle: string,
  articleBody: string,
  ticketId?: string | number,
  articleStatus?: ArticleStatus
): void => {
  dataLayerPush({
    event: eventName,
    data: {
      article_id: articleId,
      ticket_id: ticketId,
      article_title: articleTitle,
      article_body: articleBody,
      article_status: articleStatus
    }
  });
};

function useArticleActions(
  article: GeneratedKnowledgeResult,
  title: string,
  body: string,
  handleClose?: () => void
): UseArticleActionsProps {
  const queryClient = useQueryClient();
  const [isDiscardOpen, setIsDiscardOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const { availablePublicly, articleStatus, articleReviewers } = useAppSelector(
    (state) => state.knowledgeBase
  );

  const ticketId = article.ticketIds[0]?.ticket_id || "";

  const getArticleReviewersId = (reviewers: ArticleReviewer[]): number[] => {
    return reviewers?.map((reviewer: ArticleReviewer) => reviewer.reviewer_id);
  };

  const addedReviewersId = getArticleReviewersId(articleReviewers[article.id]);
  const existingReviewersId = getArticleReviewersId(article.reviewers);

  const deleteArticleMutation = useMutation(deleteArticle, {
    onSuccess: () => {
      if (handleClose instanceof Function) handleClose();
      queryClient.invalidateQueries([
        WebCacheKey.GENERATED_KNOWLEDGE_PUBLISHED
      ]);
      queryClient.invalidateQueries([WebCacheKey.GENERATED_KNOWLEDGE_ARTICLE]);
    }
  });

  const editArticleMutation = useMutation(editArticle, {
    onSuccess: () => {
      if (handleClose instanceof Function) handleClose();
      queryClient.invalidateQueries([
        WebCacheKey.GENERATED_KNOWLEDGE_PUBLISHED
      ]);
      queryClient.invalidateQueries([WebCacheKey.GENERATED_KNOWLEDGE_ARTICLE]);
    }
  });

  const handleShowDiscard = (): void => {
    setIsDiscardOpen(true);
  };

  const handleHideDiscard = (): void => {
    setIsDiscardOpen(false);
  };

  const getStatus = (): ArticleStatus | undefined => {
    if (
      articleStatus === ArticleStatus.DRAFT &&
      articleStatus === article.status
    ) {
      return ArticleStatus.IN_REVIEW;
    } else if (
      articleStatus !== article.status &&
      articleStatus !== ArticleStatus.ALL
    ) {
      return articleStatus;
    } else {
      return undefined;
    }
  };

  const handleSaveArticle = (): void => {
    if (article) {
      const updatedFields = {
        articleId: article.id,
        title: title !== article.title ? title : undefined,
        body: body !== article.body.body ? body : undefined,
        status: getStatus(),
        availablePublicly:
          availablePublicly[article.id] !== article.public
            ? availablePublicly[article.id]
            : undefined,
        reviewers:
          JSON.stringify(existingReviewersId) !==
          JSON.stringify(addedReviewersId)
            ? addedReviewersId
            : undefined
      };

      const fieldsToMutate = {
        articleId: article.id,
        ...Object.fromEntries(
          Object.entries(updatedFields).filter(
            ([_, value]) => value !== undefined
          )
        )
      };

      editArticleMutation.mutate(fieldsToMutate);
      triggerEvent(
        GTM_EVENTS.GTM_SAVE_KB_GEN_ARTICLE,
        article.id,
        article.title,
        article.body.body,
        ticketId,
        articleStatus
      );
    }
  };

  const handleDeleteArticle = (): void => {
    deleteArticleMutation.mutate({ articleId: article.id });
    handleHideDiscard();
    triggerEvent(
      GTM_EVENTS.GTM_DISCARD_KB_GEN_ARTICLE,
      article.id,
      article?.title || "",
      article?.body.body || "",
      ticketId,
      articleStatus
    );
  };

  const handleCloseActionMenu = (): void => {
    setIsDiscardOpen(false);
  };

  useOutsideAlerter(dropdownRef, handleCloseActionMenu);

  return {
    isDiscardOpen,
    dropdownRef,
    handleSaveArticle,
    handleShowDiscard,
    handleHideDiscard,
    handleDeleteArticle
  };
}

export { useArticleActions };
