import { ImageUploadResult } from "@aptedge/lib-ui/src/types/entities";
import classNames from "classnames";
import React from "react";
import { Button } from "../Button/Button";
import { MarkdownContent } from "../MarkdownContent/MarkdownContent";
import MarkdownEditor from "../MarkdownEditor/MarkdownEditor";
import "./TextEditor.scss";

// Rich Text Editor Content (currently markdown)
export interface IRichTextContent {
  version?: string; // Should indicate the version of the *editor*. Currently "markdown.0.1".
  content: string;
}

interface Props {
  content?: IRichTextContent | null;
  readOnly?: boolean;
  placeholder?: string;
  defaultOpen?: boolean;
  onSave?: (newContent: IRichTextContent) => void;
  onChange?: (newContent: IRichTextContent) => void;
  onCancel?: () => void;
  handleImageUpload?: (f: File) => Promise<ImageUploadResult>;
  isImageDropAllowed: boolean;
  templateText?: string;
}

// This is designed to handle necessary data migrations in the future,
// so it should only be changed if the document schema (currently a markdown string) changes.
const VERSION = "markdown.0.1";

function TextEditor(props: Props): React.ReactElement {
  const {
    content,
    onSave,
    onChange,
    onCancel,
    placeholder = "Add content",
    defaultOpen = false,
    readOnly,
    templateText,
    handleImageUpload,
    isImageDropAllowed
  } = props;

  const textContent = content ? content.content : "";
  const [editorOpen, setEditorOpen] = React.useState(defaultOpen);
  const [internalContent, setInternalContent] = React.useState(textContent);
  const [isHighlighting, setIsHighlighting] = React.useState<boolean>(false);

  const handleCancel = (): void => {
    if (editorOpen) {
      setInternalContent(textContent);
      setEditorOpen(false);

      if (!!onCancel) {
        onCancel();
      }
    }
  };

  const handleChange = (newValue: string): void => {
    setInternalContent(newValue);
    if (onChange)
      onChange({
        version: VERSION,
        content: newValue
      });
  };

  const handleSave = (): void => {
    if (onSave) {
      onSave({
        version: VERSION,
        content: internalContent
      });
    }
    setInternalContent(textContent);
    setEditorOpen(false);
  };

  React.useEffect(() => {
    setInternalContent(textContent);
  }, [textContent]);

  React.useEffect(() => {
    setEditorOpen(defaultOpen);
  }, [defaultOpen]);

  // Handle edge cases of saving only template text
  React.useEffect(() => {
    if (editorOpen && !internalContent) {
      setInternalContent(templateText || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorOpen, templateText]);

  const handleMouseUp = (): void => {
    if (readOnly || isHighlighting) {
      setEditorOpen(false);
    } else {
      setEditorOpen(true);
    }
  };

  const handleMouseMove = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void => {
    if (Math.abs(e.movementY) >= 1 || Math.abs(e.movementX) >= 1) {
      setIsHighlighting(true);
    }
  };

  return (
    <div className="text-editor">
      {editorOpen && !readOnly ? (
        <>
          <MarkdownEditor
            autoFocus={true}
            handleImageUpload={handleImageUpload}
            content={internalContent || templateText || ""}
            onChange={handleChange}
            isImageDropAllowed={isImageDropAllowed}
          />
          <div className="d-flex w-100 mt-2 mb-2 justify-content-end">
            <Button className="mr-2" color="secondary" onClick={handleCancel}>
              Cancel
            </Button>
            <Button color="primary" onClick={handleSave}>
              Save
            </Button>
          </div>
        </>
      ) : (
        <div
          className={classNames("rendered", { "read-only": readOnly })}
          onMouseUp={handleMouseUp}
          onMouseDown={() => setIsHighlighting(false)}
          onMouseMove={handleMouseMove}
        >
          <MarkdownContent
            className="display"
            placeholder={<p className="placeholder">{placeholder}</p>}
            content={internalContent}
          />
        </div>
      )}
    </div>
  );
}

export { TextEditor };
