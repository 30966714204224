import { NavLink } from "react-router-dom";
import { FilterTab } from "./FiltersNavigation";
import styles from "./FiltersNavigationItem.module.scss";

interface FiltersNavigationItemProps {
  filterTab: FilterTab;
}

const FiltersNavigationItem: React.FC<FiltersNavigationItemProps> = ({
  filterTab
}) => {
  return (
    <NavLink
      to={filterTab.path}
      exact={true}
      className={styles.container}
      activeClassName={styles.active}
    >
      <span className={styles.icon}>{filterTab.icon}</span>
      <span className={styles.label}>{filterTab.label}</span>
    </NavLink>
  );
};

export { FiltersNavigationItem };
