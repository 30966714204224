import React from "react";
import { DefaultTableCell } from "./cells/DefaultTableCell";
import { Column } from "./useTable";
import { getCellProps } from "./utils";

interface Props<T> {
  row: T;
  col: Column<T>;
}

function Td<T>(props: Props<T>): React.ReactElement {
  const { col, row } = props;
  const CellComponent = col.component || DefaultTableCell;

  return (
    <td {...getCellProps(col)}>
      <CellComponent key={col.id as string} id={col.id} col={col} data={row} />
    </td>
  );
}

export { Td };
