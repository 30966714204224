import { ModalWidth } from "@aptedge/lib-ui/src/components/Modal/Modal";
import { IConfluenceCloudArticleSource } from "@aptedge/lib-ui/src/types/entities";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { ToastType } from "react-toastify";
import { createConfluenceCloudArticleSource } from "../../../clients/ArticleSources/createConfluenceCloudArticleSource";
import { ConfirmationModal } from "../../../components/ConfirmationModal/ConfirmationModal";
import { Toast } from "../../../components/Toast/Toast";
import { getBackendUrl } from "../../../config";
import { ClientStatus } from "../../../types/clients";
import { AddButton } from "../common/AddButton";
import { StatusButton } from "../common/StatusButton";
import { providerIsAuthorized } from "../utils/providerIsAuthorized";
import {
  ConfluenceCloudConfigForm,
  ConfluenceCloudFormData
} from "./ConfluenceCloudConfigForm";
interface Props {
  articleIssueSource: IConfluenceCloudArticleSource | null;
}

const ConfluenceCloudConfig: React.FunctionComponent<Props> = (props) => {
  const { articleIssueSource } = props;
  const [showModal, setShowModal] = useState<boolean>(false);
  const [redirectStatus, setRedirectStatus] = React.useState(
    ClientStatus.PROMPT
  );

  const addSource = useMutation(createConfluenceCloudArticleSource, {
    onError: () => {
      setRedirectStatus(ClientStatus.PROMPT);
    },
    onSuccess: () => {
      setRedirectStatus(ClientStatus.LOADING);
      const url = `${getBackendUrl()}/confluence/login`;
      window.location.href = url;
    }
  });

  const onConfluenceCloudFormSubmit = (
    formData: ConfluenceCloudFormData
  ): void => {
    addSource.mutate(formData);
  };

  return (
    <div>
      <div className="mb-3">
        <h5>Confluence Cloud</h5>
        <p>Configure your Confluence Cloud settings below.</p>
      </div>
      <div>
        <ConfirmationModal
          title="Confluence OAuth Settings"
          width={ModalWidth.MEDIUM}
          loadingTitle="Redirecting to Atlassian..."
          status={redirectStatus}
          isOpen={showModal}
          onClose={() => setShowModal(false)}
        >
          <p className="mb-4">Configure your settings below.</p>
          <ConfluenceCloudConfigForm
            source={articleIssueSource}
            onSubmit={onConfluenceCloudFormSubmit}
          />
        </ConfirmationModal>
      </div>
      {articleIssueSource ? (
        <StatusButton
          status={providerIsAuthorized(articleIssueSource) ? "ok" : "error"}
          onClickEdit={() => setShowModal(true)}
        >
          <>
            <div data-testid="Confluence-cloud-status-btn">
              Confluence Cloud
            </div>
            <div className="text-muted">api.atlassian.net</div>
          </>
        </StatusButton>
      ) : (
        <AddButton onClick={() => setShowModal(true)}>
          Connect to Confluence Cloud
        </AddButton>
      )}
      {addSource.isError && (
        <Toast type={ToastType.ERROR}>Something went wrong.</Toast>
      )}
    </div>
  );
};

export { ConfluenceCloudConfig };
