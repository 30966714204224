import { ITeam } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { IPaginatedRequest, IPaginatedResponse } from "../../types/clients";
import { request } from "../../utils/request";
import { toQueryString } from "../utils/url";

async function fetchTeams(
  args: IPaginatedRequest<{ q?: string }>
): Promise<IPaginatedResponse<ITeam[]>> {
  const query = toQueryString(args);
  const url = `${getBackendApiUrl()}/teams/?${query}`;

  return request(url);
}

export { fetchTeams };
