/* eslint-disable @typescript-eslint/no-explicit-any */
import { UseMutationResult, UseQueryResult } from "react-query";
import { ClientStatus } from "../../types/clients";

function convertQueryStateToClientStatus(
  query?: UseQueryResult<any, any> | UseMutationResult<any, any, any, any>
): ClientStatus {
  if (!query) return ClientStatus.PROMPT;
  if (query.isLoading) return ClientStatus.LOADING;
  if (query.isError) return ClientStatus.ERROR;
  if (query.isIdle) return ClientStatus.PROMPT;
  if (query.isSuccess) return ClientStatus.SUCCESS;

  return ClientStatus.PROMPT;
}

export { convertQueryStateToClientStatus };
