import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { Feedback } from "@aptedge/lib-ui/src/components/Feedback/Feedback";
import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import { useAppSelector } from "@aptedge/lib-ui/src/redux/hook/hook";
import { ISharepointDriveSite } from "@aptedge/lib-ui/src/types/entities";
import classNames from "classnames";
import { useFormik } from "formik";
import React, { useRef, useEffect } from "react";
import { useMutation } from "react-query";
import * as Yup from "yup";
import { getSharepointDriveSiteInfo } from "../../../clients/ArticleSources/getSharepointDriveSiteInfo";
import { updateSharepointDriveSites } from "../../../clients/ArticleSources/updateSharepointDriveSites";
import WithLoading from "../../../components/WithLoading/WithLoading";

interface Props {
  getDatasource: () => void;
  onCancel: () => void;
}

interface FormState {
  siteDomain: string;
  sitePath: string;
}

const SharepointDriveAddSite: React.FunctionComponent<Props> = (
  props: Props
) => {
  const { getDatasource, onCancel } = props;
  const { dataSource } = useAppSelector((state) => state.sharepointDrive);

  const [errorMsg, setErrorMsg] = React.useState("");

  const inputRef = useRef<HTMLInputElement>(null);

  const updateSource = useMutation(updateSharepointDriveSites, {
    onError: () => {
      setErrorMsg("Unable to update sites");
    },
    onSuccess: () => {
      setErrorMsg("");
      getDatasource();
    }
  });

  const getSiteInfo = useMutation(getSharepointDriveSiteInfo, {
    onSuccess: (response: ISharepointDriveSite) => {
      if (response?.error) {
        setErrorMsg(
          "Error. This is not a valid site domain/path or the integration might not have access to this site"
        );
      } else {
        setErrorMsg("");
        updateSource.mutate({
          sites: [
            ...((dataSource?.sites ?? []) as ISharepointDriveSite[]).map(
              (site: ISharepointDriveSite) => site.siteId
            ),
            response.siteId
          ],
          tenantId: dataSource?.tenantId ?? ""
        });
      }
    }
  });

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddSite = (siteDomain: string, sitePath: string): void => {
    setErrorMsg("");
    getSiteInfo.mutate({ sitePath, siteDomain });
    formik.resetForm();
  };

  const formik = useFormik<FormState>({
    initialValues: {
      siteDomain: "",
      sitePath: ""
    },
    onSubmit: (values: FormState) => {
      handleAddSite(values?.siteDomain, values?.sitePath);
    },
    validateOnBlur: false,
    validationSchema: Yup.object({
      siteDomain: Yup.string().required("A Site Domain is required"),
      sitePath: Yup.string().required("A Site Path is required")
    })
  });

  return (
    <WithLoading
      isLoading={getSiteInfo.isLoading}
      type="overlay"
      fallback={<Spinner />}
    >
      <div className={classNames("label-create-item p-2")}>
        <h5>Add Site Domain and Site Path</h5>
        <form onSubmit={formik.handleSubmit}>
          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="fieldName">Site Domain</label>
            </div>
            <div className="form-group col-md-9">
              <input
                data-testid="sharepoint-site-domain"
                id="siteDomain"
                type="text"
                ref={inputRef}
                className={classNames("form-control", {
                  "is-invalid": !!errorMsg || !!formik.errors.siteDomain
                })}
                aria-describedby="sharepointSiteDomain"
                value={formik.values.siteDomain}
                onChange={formik.handleChange}
              />
              {(formik.errors.siteDomain || errorMsg) && (
                <Feedback>{errorMsg || formik.errors.siteDomain}</Feedback>
              )}
            </div>
            <div className="form-group col-md-3">
              <label htmlFor="fieldName">Site Path</label>
            </div>
            <div className="form-group col-md-9">
              <input
                data-testid="sharepoint-site-path"
                id="sitePath"
                type="text"
                ref={inputRef}
                className={classNames("form-control", {
                  "is-invalid": !!errorMsg || !!formik.errors.sitePath
                })}
                aria-describedby="sharepointSitePath"
                value={formik.values.sitePath}
                onChange={formik.handleChange}
              />
              {(formik.errors.sitePath || errorMsg) && (
                <Feedback>{errorMsg || formik.errors.sitePath}</Feedback>
              )}
            </div>
          </div>

          <div className="d-flex mt-2 justify-content-end">
            <Button
              data-testid="sharepoint-add-channel-site"
              type="button"
              className="mr-2"
              color="secondary"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Add Site
            </Button>
          </div>
        </form>
      </div>
    </WithLoading>
  );
};

export { SharepointDriveAddSite };
