import { INotionSource } from "@aptedge/lib-ui/src/types/entities";
import { getBackendUrl } from "../../config";
import { request } from "../../utils/request";

function fetchNotionArticleSource(): Promise<INotionSource[]> {
  const url = `${getBackendUrl()}/notion/tap`;

  return request(url);
}

export { fetchNotionArticleSource };
