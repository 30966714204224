import React, { createContext, useContext, useState } from "react";
import { noop } from "../../utils/noop";

type Route = number | undefined;

type ContextState = [Route, React.Dispatch<React.SetStateAction<Route>>];

const ModalRouterContext = createContext<ContextState>([undefined, noop]);

/**
 * A simple Router for complex Modals that have several "steps" in the process.
 */
const ModalRouter: React.FC<{ initialRoute: Route }> = (props) => {
  const { children, initialRoute } = props;

  const state = useState(initialRoute);

  return (
    <ModalRouterContext.Provider value={state}>
      {children}
    </ModalRouterContext.Provider>
  );
};

const useModalRouter = (): {
  currentRoute: ContextState[0];
  push: ContextState[1];
} => {
  const context = useContext(ModalRouterContext);

  if (!context) {
    throw new Error(
      "No ModalRouter found. Please wrap this component in a ModalRouter."
    );
  }

  return { currentRoute: context[0], push: context[1] };
};

/**
 * A simple Route component — like `react-router`'s <Route /> — that only renders its children
 * when the route prop matches the current Modal Route. If there is no route prop, it will always
 * render its children.
 */
const ModalRoute: React.FC<{ route?: Route }> = ({ children, route }) => {
  const [currentRoute] = useContext(ModalRouterContext);

  if (route === undefined || currentRoute === route) {
    return <>{children}</>;
  } else {
    return null;
  }
};

export { ModalRouter, ModalRouterContext, ModalRoute, useModalRouter };
