import React from "react";
import { useQueryClient } from "react-query";
import { WebCacheKey } from "../../../clients/cache";
import { AgentEmailSettings } from "./AgentEmailSettings";
import { KnowledgeBaseSettings } from "./KnowledgeBaseSettings";
import { LabelSettings } from "./LabelSettings";
import { PhraseSettings } from "./PhraseSettings";
import { useMLSettings } from "./useMLSettings";

const MLSettings: React.FC = () => {
  const queryClient = useQueryClient();
  const { commonThemes, phrases, labels } = useMLSettings();

  const onChange = (): void => {
    queryClient.refetchQueries([WebCacheKey.PRODUCTS]);
    queryClient.refetchQueries([WebCacheKey.THEMES]);
  };

  return (
    <>
      <AgentEmailSettings />
      <KnowledgeBaseSettings />
      <LabelSettings
        labels={labels}
        commonThemes={commonThemes}
        onChange={onChange}
      />
      <PhraseSettings labels={labels} phrases={phrases} onChange={onChange} />
    </>
  );
};

export { MLSettings };
