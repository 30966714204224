import DOMPurify from "dompurify";
import truncate from "lodash/truncate";
import { useMemo } from "react";
import { Remarkable } from "remarkable";

interface TruncateOptions {
  maxLength?: number;
}

const remark = new Remarkable({ breaks: true });
const trailingNewLine = /(\n)$/;

function toHtml(content: string): string {
  // Remark adds \n to the end of the HTML for some reason.
  const mdHtml = remark.render(content).replace(trailingNewLine, "");

  return DOMPurify.sanitize(mdHtml);
}

function toTruncatedHtml(
  content: string,
  options: TruncateOptions = {}
): string {
  const html = toHtml(content);

  if (!options.maxLength || html.length <= options.maxLength) {
    return html;
  }

  try {
    // Create an element in memory so that we can traverse its nodes.
    const el = document.createElement("div");
    el.innerHTML = html;
    const node = traverseAndTruncateNodes(el, options.maxLength);
    return node.innerHTML.replace(trailingNewLine, "");
  } catch (e) {
    // Fallback to the full html if something goes wrong.
    return html;
  }
}

function traverseAndTruncateNodes(node: Element, length: number): Element {
  const newNode = node;
  let currentLengthAtEndOfPreviousNode = 0;
  let currentNode = newNode.firstChild;

  while (currentNode && currentLengthAtEndOfPreviousNode <= length) {
    const currentNodeLength = currentNode.textContent?.length || 0;
    const shouldTruncateThisNode =
      currentNodeLength + currentLengthAtEndOfPreviousNode > length;

    if (shouldTruncateThisNode) {
      const newLength = length - currentLengthAtEndOfPreviousNode;

      // Remove any remaining siblings after this one.
      let sibling = currentNode.nextSibling;
      while (sibling) {
        const next = sibling.nextSibling;
        if (sibling.textContent !== "\n") {
          newNode.removeChild(sibling);
        }
        sibling = next;
      }

      // Recursively truncate the child nodes of this node if it has children.
      let newChildNode = currentNode.cloneNode(true);
      if (newChildNode.hasChildNodes()) {
        newChildNode = traverseAndTruncateNodes(
          currentNode as Element,
          newLength
        );
      } else {
        // Otherwise, just truncate the node's text content.
        newChildNode.textContent = truncate(currentNode.textContent ?? "", {
          length: newLength
        });
        newNode.replaceChild(newChildNode, currentNode);
      }

      // Break the loop.
      break;
    } else {
      currentNode = currentNode.nextSibling;
      currentLengthAtEndOfPreviousNode += currentNodeLength;
    }
  }

  return newNode;
}

function useTruncatedHtml(
  content?: string,
  options: TruncateOptions = {}
): { html: string | null; truncatedHtml: string | null } {
  const { maxLength } = options;
  // Memo'ize so we don't run these expensive translations unless relevant props change.
  return useMemo(() => {
    if (content === undefined) return { html: null, truncatedHtml: null };

    const html = toHtml(content);
    const truncatedHtml = maxLength
      ? toTruncatedHtml(content, { maxLength })
      : html;

    return { html, truncatedHtml };
  }, [content, maxLength]);
}

export { toHtml, toTruncatedHtml, useTruncatedHtml };
