/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback } from "react";
import { Spinner } from "../Spinner/Spinner";

type LazyImport = () => Promise<{
  default: React.ComponentType<any>;
}>;

interface Props {
  /**
   * The dynamic import, e.g. `() => import("./somefile")`
   */
  importFn: LazyImport;

  /**
   * The component to show while the import is being loaded. Defaults to `<Spinner />`
   */
  fallback?: React.ReactElement | null;

  /**
   * Class name to pass to the component when it's rendered.
   */
  className?: string;
}

const LazyLoad: React.FC<Props> = (props) => {
  const { importFn, className, fallback = <Spinner /> } = props;

  const importFnWithCustomError = useCallback(
    () =>
      importFn().then(
        (component) => component,
        (e) => {
          throw new LazyLoadError(e.message);
        }
      ),
    [importFn]
  );

  const Lazy = React.useMemo(() => React.lazy(importFnWithCustomError), [
    importFnWithCustomError
  ]);

  return (
    <React.Suspense fallback={fallback}>
      <Lazy className={className} />
    </React.Suspense>
  );
};

class LazyLoadError extends Error {
  public name = "LazyLoadError";

  constructor(msg?: string) {
    super(msg);

    // Set the prototype explicitly for instanceof checks.
    Object.setPrototypeOf(this, LazyLoadError.prototype);

    if (!!Error.captureStackTrace) {
      Error.captureStackTrace(this, LazyLoadError);
    }
  }
}

export type { LazyImport };
export { LazyLoad, LazyLoadError };
