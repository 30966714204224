import classNames from "classnames";
import React from "react";
import "./Spacer.scss";

interface SpacerProps {
  size: "small" | "regular" | "medium" | "large" | "xl" | "xxl" | "xxxl";
  row?: boolean;
  className?: string;
  flex?: boolean;
  surround?: boolean;
}

const Spacer: React.FC<SpacerProps> = (props) => {
  const { children, className, size, flex, row, surround } = props;

  return (
    <div
      className={classNames("spacer", className, size, {
        "spacer-row": !!row,
        flex,
        surround
      })}
    >
      {children}
    </div>
  );
};

export { Spacer };
