import {
  AptAnswersFilterType,
  AptAnswersGroupFilterItem
} from "@aptedge/lib-ui/src/types/entities";
import * as React from "react";
import styles from "./FilterList.module.scss";
import { FilterListAddRow } from "./FilterListAddRow";
import { FilterListItem } from "./FilterListItem";

interface FilterListProps {
  handleCreateFilter: () => void;
  handleUpdateFilter: (filterName: string) => void;
  items: AptAnswersGroupFilterItem[];
  filterType: AptAnswersFilterType;
}

const FilterList: React.FC<FilterListProps> = ({
  handleCreateFilter,
  handleUpdateFilter,
  items,
  filterType
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.itemsContainer}>
        {items.map((item, idx) => {
          const itemName = item[0];
          return (
            <FilterListItem
              key={itemName}
              isTopItem={idx === 0}
              isBottomItem={idx === items.length - 1}
              item={item}
              handleClick={handleUpdateFilter}
            />
          );
        })}
      </div>
      <div className={styles.addContainer}>
        <FilterListAddRow
          handleClick={handleCreateFilter}
          filterType={filterType}
        />
      </div>
    </div>
  );
};

export { FilterList };
