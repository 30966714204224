import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { IBusinessDataSource } from "@aptedge/lib-ui/src/types/entities";
import { useFormik } from "formik";
import React from "react";
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label
} from "reactstrap";
import * as Yup from "yup";

export interface SalesforceFormData {
  oauthClientId: string;
  oauthClientSecret: string;
  instanceName?: string;
}

interface Props {
  source: IBusinessDataSource | null;
  onSubmit: (formData: SalesforceFormData) => void;
}

const baseUrl = "login.salesforce.com";

const SalesforceConfigForm: React.FunctionComponent<Props> = ({
  source,
  onSubmit
}) => {
  const formik = useFormik<SalesforceFormData>({
    validationSchema: Yup.object({
      oauthClientId: Yup.string().required("Please enter a client ID."),
      oauthClientSecret: Yup.string().required("Please enter a client secret."),
      instanceName: Yup.string().required(
        `Please enter a URL (e.g. ${baseUrl})`
      )
    }),
    initialValues: {
      oauthClientId: (source && source.oauthClientId) || "",
      oauthClientSecret: (source && source.oauthClientSecret) || "",
      instanceName: (source && source.instanceName) || baseUrl
    },
    validateOnChange: false,
    onSubmit: onSubmit
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <FormGroup row>
        <Label sm={3} for="clientId">
          OAuth Client ID
        </Label>
        <Col sm={9}>
          <Input
            data-testid="salesforce-oauthClientId"
            type="text"
            name="oauthClientId"
            placeholder=""
            value={formik.values.oauthClientId}
            onChange={formik.handleChange}
            invalid={!!formik.errors.oauthClientId}
          />
          <FormFeedback>{formik.errors.oauthClientId}</FormFeedback>
          <FormText>Name of the configured OAuth client</FormText>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3} for="clientSecret">
          OAuth Client Secret
        </Label>
        <Col sm={9}>
          <Input
            data-testid="salesforce-oauthClientSecret"
            type="text"
            name="oauthClientSecret"
            placeholder=""
            value={formik.values.oauthClientSecret}
            onChange={formik.handleChange}
            invalid={!!formik.errors.oauthClientSecret}
          />
          <FormFeedback>{formik.errors.oauthClientSecret}</FormFeedback>
          <FormText>
            Client Secret provided to you in creating an OAuth client
          </FormText>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3} for="instanceName">
          Base URL
        </Label>
        <Col sm={9}>
          <Input
            data-testid="salesforce-instance-name"
            type="text"
            name="instanceName"
            placeholder="https://login.salesforce.com/"
            value={formik.values.instanceName}
            onChange={formik.handleChange}
            invalid={!!formik.errors.instanceName}
          />
          <FormFeedback>{formik.errors.instanceName}</FormFeedback>
          <FormText>URL for Salesforce OAuth</FormText>
        </Col>
      </FormGroup>
      <div className="float-right">
        <Button type="submit">Authorize</Button>
      </div>
    </Form>
  );
};

export { SalesforceConfigForm };
