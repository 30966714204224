import classNames from "classnames";
import React, { MouseEvent } from "react";
import { dePx } from "../../styles/utils";
import { Tooltip } from "../Tooltip/Tooltip";
import { getGravatarImageSrc } from "./util";
import "./Avatar.scss";

const Avatar: React.FunctionComponent<Props> = ({
  className,
  text,
  tooltip = text,
  onClick,
  email,
  showTooltip = true,
  size = "50px",
  color
}) => {
  const abbreviatedText = text
    .split(/[ -]+/)
    .slice(0, 3)
    .reduce((memo, s) => memo + s.charAt(0), "");

  const src = getGravatarImageSrc(email, {
    size: dePx(size),
    defaultImg: "mp"
  });

  return (
    <Tooltip
      disabled={!showTooltip}
      placement="bottom"
      content={tooltip}
      offset={{ x: 0, y: 12 }}
    >
      <div
        className={classNames("avatar", className)}
        style={{
          border: `1px solid ${color}`,
          backgroundColor: color,
          width: size,
          height: size
        }}
        color={color}
        onClick={onClick}
        data-testid="avatar"
      >
        {src ? (
          <img
            alt={`Avatar for ${text}`}
            style={{
              minWidth: size,
              minHeight: size
            }}
            height={size}
            width={size}
            src={src}
          />
        ) : (
          <span>{abbreviatedText}</span>
        )}
      </div>
    </Tooltip>
  );
};

interface Props {
  className?: string;
  showTooltip?: boolean;
  tooltip?: string;
  color: string;
  size?: string;
  email?: string;
  text: string;
  onClick?: (e: MouseEvent) => void;
}

Avatar.displayName = "Avatar";

export { Avatar };
