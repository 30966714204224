/**
 * Custom Error class for API requests so we can carry forward the HTTP status.
 */
class ApiError extends Error {
  public static DEFAULT_MESSAGE = "We're sorry, something went wrong.";

  public static generateErrorMessage(
    code: number,
    url: string,
    message = "Unknown Error"
  ): string {
    return `[${code}] | ${url} | ${message}`;
  }

  public name = "ApiError";
  /** User-friendly message for the UI */
  public displayMessage: string;

  constructor(public readonly code: number, url: string, message?: string) {
    super(ApiError.generateErrorMessage(code, url, message));
    this.code = code;
    this.displayMessage = message || ApiError.DEFAULT_MESSAGE;

    if (!!Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError);
    }
  }
}

export { ApiError };
