import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";
import { post } from "../utils/buildRequest";

async function markNotification({
  id,
  markAsRead
}: {
  id: number;
  markAsRead: boolean;
}): Promise<null> {
  const url = `${getBackendApiUrl()}/users/notifications/${id}/`;

  return request(url, post({ markAsRead }));
}

export { markNotification };
