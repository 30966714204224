import React, { FC, ImgHTMLAttributes } from "react";

interface Props extends ImgHTMLAttributes<HTMLImageElement> {
  src: string;
  alt?: string;
}

const ImageIcon: FC<Props> = ({ src, alt, ...rest }) => {
  return <img src={src} alt={alt} {...rest} />;
};

export default ImageIcon;
