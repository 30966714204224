/* eslint-disable @typescript-eslint/no-explicit-any */
import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import { FC } from "react";
import { useQuery } from "react-query";
import { fetchIntegrations } from "../../../../clients/ArticleSources/DynamicSettings/fetchIntegrations";
import { WebCacheKey } from "../../../../clients/cache";
import WithLoading from "../../../../components/WithLoading/WithLoading";
import { ArticleConfigForm } from "./DynamicConfigForms/ArticleConfigForm";
import Integration from "./Integration/Integration";

export type Field = {
  id: string;
  fieldType: string;
  label: string;
  hintText?: string;
  placeholder?: string;
};
export interface IIntegration {
  integrationName: string;
  fields: Field[];
  key: string;
  description: string;
}
const Integrations: FC = () => {
  const { data, isLoading } = useQuery(
    WebCacheKey.DYNAMIC_INTEGRATION_SETTINGS,
    fetchIntegrations
  );
  const getIntegration = (integration: any): any => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const fields = integration.inputs.reduce((acc: any, current: any) => {
      const { name, json_body, type } = current;
      return [
        ...acc,
        {
          id: json_body,
          fieldType: type,
          label: name
        }
      ];
    }, []);

    return {
      integrationName: integration.name,
      key: integration.key,
      fields: fields,
      ...integration
    };
  };

  return (
    <WithLoading isLoading={isLoading} fallback={<Spinner />}>
      {data?.map((integration) => (
        <Integration
          integration={getIntegration(integration)}
          key={integration.key}
          configForm={ArticleConfigForm}
        />
      ))}
    </WithLoading>
  );
};

export { Integrations };
