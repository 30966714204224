import { IEdgeFile } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";
import { postForm } from "../utils/buildRequest";

function uploadEdgeFiles({
  edgeId,
  files
}: {
  edgeId: number;
  files: File[];
}): Promise<IEdgeFile[]> {
  const url = `${getBackendApiUrl()}/edges/${edgeId}/files`;
  const formData = new FormData();
  files.forEach((f) => {
    formData.append("file", f);
  });

  const options = postForm(formData);

  return request(url, options);
}

export { uploadEdgeFiles };
