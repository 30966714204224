import classNames from "classnames";
import React, { forwardRef } from "react";
import "./List.scss";

const List = forwardRef<HTMLDivElement, Props>(
  ({ children, className, ...props }, ref) => {
    return (
      <div className={classNames("list", className)} {...props} ref={ref}>
        {children}
      </div>
    );
  }
);

List.displayName = "List";

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

export { List };
