import type { IRichTextContent } from "@aptedge/lib-ui/src/components/TextEditor/TextEditor";

interface Result {
  preview?: string;
}

interface ArticleResult extends Result {
  type: "article";
  title: string;
}

interface EdgeResult extends Result {
  type: "edge";
  name: string;
  richSummary: IRichTextContent | null;
}

interface IssueResult extends Result {
  type: "issue";
  title: string;
}

interface TicketResult extends Result {
  type: "ticket";
  subject: string;
  description: string;
}

interface DocumentResult extends Result {
  type: "document";
  title: string;
}

interface FederatedResult extends Result {
  type: "federated";
  title: string;
}

interface SocialPostResult extends Result {
  type: "social";
  title: string;
}

interface GeneratedKnowledgeResult extends Result {
  type: "generated_knowledge";
  title: string;
  body: string;
}

type SearchResult =
  | ArticleResult
  | EdgeResult
  | IssueResult
  | TicketResult
  | DocumentResult
  | FederatedResult
  | GeneratedKnowledgeResult
  | SocialPostResult;

interface PreviewSummary {
  hasPreview: true;
  previewSnippet: string;
  previewType:
    | EdgePreviewType
    | TicketPreviewType
    | ArticlePreviewType
    | IssuePreviewType;
}

interface PreviewSummaryEmpty {
  hasPreview: false;
  previewSnippet: null;
  previewType: null;
}

type EdgePreviewType = "name" | "content";
type TicketPreviewType = "subject" | "description";
type ArticlePreviewType = "title" | "body";
type DocumentPreviewType = "title" | "body";
type FederatedPreviewType = "title" | "body";
type GeneratedKnowledgePreviewType = "title" | "body";
type IssuePreviewType = "title" | "description";

interface PreviewTextProperties {
  previewSnippet: string;
}

// utils
// // general
const getPreviewTextFromPreview = (preview: string): string => {
  return preview.replace(/(<em>|<\/em>)/g, "");
};

const transfromPreviewText = (preview: string): string => {
  // Elasticsearch previews sometimes include empty <p> and <b> tags which produce weird spacing.
  // They're unexpected anyway (only <em> tags should be produced), so let's just remove them.
  return preview
    .replace(/<em>/g, '<em class="preview-highlight">')
    .replace(/(<p>|<\/p>)/g, "")
    .replace(/(<b>|<\/b>)/g, "");
};

const getPreviewSnippet = (preview: string, content: string): string => {
  const contentLength = content.length;
  const previewText = getPreviewTextFromPreview(preview);
  const previewLength = previewText.length;
  const previewIndex = content?.indexOf(previewText);
  const previewNeedsStartingEllipsis = !!previewIndex && previewIndex > 0;
  const previewNeedsEndingEllipsis =
    contentLength && previewIndex + previewLength < contentLength;
  const previewHasEndingPeriod =
    previewText.charAt(previewText.length - 1) === ".";
  const previewSnippet = `${
    previewNeedsStartingEllipsis ? "..." : ""
  }${preview}${
    previewNeedsEndingEllipsis ? (previewHasEndingPeriod ? ".." : "...") : ""
  }`;
  return transfromPreviewText(previewSnippet);
};

// // edge
const getEdgePreviewProperties = (
  result: EdgeResult
): PreviewSummary | PreviewSummaryEmpty => {
  if (!result.preview) {
    return {
      hasPreview: false,
      previewSnippet: null,
      previewType: null
    };
  }
  const previewType = getPreviewTypeForEdgeResult(result.preview, result.name);
  if (previewType === "name") {
    return {
      previewType,
      previewSnippet: transfromPreviewText(result.preview),
      hasPreview: true
    };
  } else {
    if (!result.richSummary || !result.richSummary.content) {
      return {
        hasPreview: false,
        previewSnippet: null,
        previewType: null
      };
    }
    const { previewSnippet } = getPreviewTextForEdgeResult(
      result.preview,
      result.richSummary.content
    );
    return {
      previewSnippet,
      previewType,
      hasPreview: true
    };
  }
};

const getPreviewTypeForEdgeResult = (
  preview: string,
  edgeName: string
): EdgePreviewType => {
  const previewText = getPreviewTextFromPreview(preview);
  return edgeName === previewText ? "name" : "content";
};

const getPreviewTextForEdgeResult = (
  preview: string,
  content: string
): PreviewTextProperties => {
  const previewSnippet = getPreviewSnippet(preview, content);

  return { previewSnippet };
};

// // ticket
const getTicketPreviewProperties = (
  result: TicketResult
): PreviewSummary | PreviewSummaryEmpty => {
  if (!result.preview) {
    return {
      hasPreview: false,
      previewSnippet: null,
      previewType: null
    };
  }
  const previewType = getPreviewTypeForTicket(result.preview, result.subject);
  if (previewType === "subject") {
    return {
      previewType,
      previewSnippet: transfromPreviewText(result.preview),
      hasPreview: true
    };
  } else {
    const { previewSnippet } = getPreviewTextForTicket(
      result.preview,
      result.description
    );
    return {
      previewSnippet,
      previewType,
      hasPreview: true
    };
  }
};

const getPreviewTypeForTicket = (
  preview: string,
  subject: string
): TicketPreviewType => {
  const ticketName = subject;
  const previewText = getPreviewTextFromPreview(preview);
  return ticketName === previewText ? "subject" : "description";
};

const getPreviewTextForTicket = (
  preview: string,
  description: string
): PreviewTextProperties => {
  const previewSnippet = getPreviewSnippet(preview, description);

  return { previewSnippet };
};

// // article
const getArticlePreviewProperties = (
  result: ArticleResult
): PreviewSummary | PreviewSummaryEmpty => {
  if (!result.preview) {
    return {
      hasPreview: false,
      previewSnippet: null,
      previewType: null
    };
  }
  const previewType = getPreviewTypeForArticle(result.preview, result.title);
  return {
    previewType,
    previewSnippet: transfromPreviewText(result.preview),
    hasPreview: true
  };
};

const getDocumentPreviewProperties = (
  result: DocumentResult
): PreviewSummary | PreviewSummaryEmpty => {
  if (!result.preview) {
    return {
      hasPreview: false,
      previewSnippet: null,
      previewType: null
    };
  }
  const previewType = getPreviewTypeForDocument(result.preview, result.title);
  return {
    previewType,
    previewSnippet: transfromPreviewText(result.preview),
    hasPreview: true
  };
};

const getFederatedPreviewProperties = (
  result: FederatedResult
): PreviewSummary | PreviewSummaryEmpty => {
  if (!result.preview) {
    return {
      hasPreview: false,
      previewSnippet: null,
      previewType: null
    };
  }
  const previewType = getPreviewTypeForFederated(result.preview, result.title);
  return {
    previewType,
    previewSnippet: result.preview,
    hasPreview: true
  };
};

const getPreviewTextForGeneratedKnowledge = (
  preview: string,
  content: string
): PreviewTextProperties => {
  const previewSnippet = getPreviewSnippet(preview, content);

  return { previewSnippet };
};

const getGeneratedKnowledgePreviewProperties = (
  result: GeneratedKnowledgeResult
): PreviewSummary | PreviewSummaryEmpty => {
  if (!result.preview) {
    return {
      hasPreview: false,
      previewSnippet: null,
      previewType: null
    };
  }
  const previewType = getPreviewTypeForGeneratedKnowledge(
    result.preview,
    result.title
  );
  if (previewType === "title") {
    return {
      previewType,
      previewSnippet: transfromPreviewText(result.preview),
      hasPreview: true
    };
  } else {
    if (!result.body) {
      return {
        hasPreview: false,
        previewSnippet: null,
        previewType: null
      };
    }
    const { previewSnippet } = getPreviewTextForGeneratedKnowledge(
      result.preview,
      result.body
    );
    return {
      previewSnippet,
      previewType,
      hasPreview: true
    };
  }
};

const getPreviewTypeForArticle = (
  preview: string,
  title: string
): ArticlePreviewType => {
  const previewText = getPreviewTextFromPreview(preview);
  return title === previewText ? "title" : "body";
};

const getPreviewTypeForDocument = (
  preview: string,
  title: string
): DocumentPreviewType => {
  const previewText = getPreviewTextFromPreview(preview);
  return title === previewText ? "title" : "body";
};

const getPreviewTypeForFederated = (
  preview: string,
  title: string
): FederatedPreviewType => {
  const previewText = getPreviewTextFromPreview(preview);
  return title === previewText ? "title" : "body";
};

const getPreviewTypeForGeneratedKnowledge = (
  preview: string,
  title: string
): GeneratedKnowledgePreviewType => {
  const previewText = getPreviewTextFromPreview(preview);
  return title === previewText ? "title" : "body";
};

// // issue
const getIssuePreviewProperties = (
  result: IssueResult
): PreviewSummary | PreviewSummaryEmpty => {
  if (!result.preview) {
    return {
      hasPreview: false,
      previewSnippet: null,
      previewType: null
    };
  }
  const previewType = getPreviewTypeForIssue(result.preview, result.title);
  return {
    previewType,
    previewSnippet: transfromPreviewText(result.preview),
    hasPreview: true
  };
};

const getPreviewTypeForIssue = (
  preview: string,
  title: string
): IssuePreviewType => {
  const previewText = getPreviewTextFromPreview(preview);
  return title === previewText ? "title" : "description";
};

// // article
const getSocialPostPreviewProperties = (
  result: SocialPostResult
): PreviewSummary | PreviewSummaryEmpty => {
  if (!result.preview) {
    return {
      hasPreview: false,
      previewSnippet: null,
      previewType: null
    };
  }
  const previewType = "body";
  return {
    previewType,
    previewSnippet: transfromPreviewText(result.preview),
    hasPreview: true
  };
};

const useSearchPreview = (
  result: SearchResult | undefined
): PreviewSummary | PreviewSummaryEmpty => {
  if (!result || !result.preview) {
    return {
      hasPreview: false,
      previewSnippet: null,
      previewType: null
    };
  }

  if (result.type === "edge") {
    return getEdgePreviewProperties(result);
  }

  if (result.type === "ticket") {
    return getTicketPreviewProperties(result);
  }

  if (result.type === "article") {
    return getArticlePreviewProperties(result);
  }

  if (result.type === "federated") {
    return getFederatedPreviewProperties(result);
  }

  if (result.type === "issue") {
    return getIssuePreviewProperties(result);
  }

  if (result.type === "document") {
    return getDocumentPreviewProperties(result);
  }

  if (result.type === "generated_knowledge") {
    return getGeneratedKnowledgePreviewProperties(result);
  }

  if (result.type === "social") {
    return getSocialPostPreviewProperties(result);
  }

  return {
    hasPreview: false,
    previewSnippet: null,
    previewType: null
  };
};

export { useSearchPreview };
