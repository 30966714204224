import { IZendeskKBArticleSource } from "@aptedge/lib-ui/src/types/entities";
import { getBackendUrl } from "../../config";
import { request } from "../../utils/request";

function fetchZendeskKBArticleSource(): Promise<IZendeskKBArticleSource[]> {
  const url = `${getBackendUrl()}/zendesk-kb/tap`;

  return request(url);
}

export { fetchZendeskKBArticleSource };
