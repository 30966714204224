import classNames from "classnames";
import React from "react";
import "./Label.scss";

interface Props {
  pinned?: boolean;
  className?: string;
}
const Label: React.FC<Props> = ({ pinned, className, children }) => {
  return (
    <label className={classNames("label", className, { pinned })}>
      {children}
    </label>
  );
};

export { Label };
