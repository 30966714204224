import { IQueryFilter } from "@aptedge/lib-ui/src/components/Filter/types";
import { IUserListing } from "@aptedge/lib-ui/src/types/entities";
import { isEmpty } from "lodash";
import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";

interface FetchUsersArgs {
  filters?: IQueryFilter[];
}

function fetchUsers({ filters }: FetchUsersArgs): Promise<IUserListing[]> {
  let url = `${getBackendApiUrl()}/users/`;
  if (!isEmpty(filters)) {
    url += `?filters=${JSON.stringify(filters)}`;
  }
  return request(url);
}

export { fetchUsers };
