import { Avatar } from "@aptedge/lib-ui/src/components/Avatar/Avatar";
import {
  Spinner,
  SpinnerSize
} from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import {
  IEdgeAuditLog,
  IUserListing
} from "@aptedge/lib-ui/src/types/entities";
import React from "react";
import { useUser } from "../../hooks/useUser";
import { Age } from "../Age/Age";
import WithLoading from "../WithLoading/WithLoading";
import "./AuditLogBase.scss";

function LogAvatar(props: { author: IUserListing | null }): React.ReactElement {
  const { author } = props;

  return !author ? (
    <img alt="logo" src="/trimmed-logo.png" height="20px" />
  ) : (
    <Avatar
      size="42px"
      email={author.email}
      text={author.email}
      color="var(--gray-000)"
    />
  );
}

type AuditDetailsFC<T extends IEdgeAuditLog> = React.FC<{ log: T }>;
type AuditSummaryFC<T extends IEdgeAuditLog> = React.FC<{
  log: T;
  author: IUserListing | null;
}>;

function WithAuditLog<T extends IEdgeAuditLog>(
  detailsComponent: AuditDetailsFC<T>,
  summaryComponent: AuditSummaryFC<T>
): React.FC<{ log: T; showDetails?: boolean }> {
  const Details = detailsComponent;
  const Summary = summaryComponent;
  const NewComponent: React.FC<{ log: T; showDetails?: boolean }> = (props) => {
    const { log, showDetails = true, children } = props;
    const { user: author, isLoading } = useUser(log.authorId);

    return (
      <div className="audit-log-wrapper d-flex">
        <WithLoading
          isLoading={isLoading}
          fallback={<Spinner size={SpinnerSize.SMALL} />}
        >
          <div className="audit-avatar mr-3">
            <LogAvatar author={author} />
          </div>
          <div className="audit-content">
            <div className="audit-header d-flex">
              <Summary author={author} log={log} />
              <div className="audit-age">
                <Age dateInSeconds={log.createdOn} />
              </div>
            </div>
            {/* Distinct AuditLog content for each type */}
            {showDetails ? (
              <div className="audit-body">
                <Details log={log as T} />
              </div>
            ) : (
              children
            )}
          </div>
        </WithLoading>
      </div>
    );
  };

  NewComponent.displayName = `WithAuditLog-${detailsComponent.displayName}`;

  return NewComponent;
}

export type { AuditDetailsFC, AuditSummaryFC };
export { WithAuditLog };
