import { FC } from "react";
import { CustomerSelfServiceFilters } from "../../../../../components/AptAnswers/CustomerSelfServiceFilters";
import SectionLayout from "../../../common/SectionLayout/SectionLayout";

export const KnowledgeSources: FC = () => {
  return (
    <SectionLayout title="Knowledge Sources" description="" divider={true}>
      <CustomerSelfServiceFilters />
    </SectionLayout>
  );
};
