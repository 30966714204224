import { IEdgeStatus } from "@aptedge/lib-ui/src/types/entities";

const EDGE_STATUS_LIST = [
  IEdgeStatus.NEW,
  IEdgeStatus.REVIEW,
  IEdgeStatus.CONFIRMED,
  IEdgeStatus.ESCALATED,
  IEdgeStatus.RESOLVED
];

export { EDGE_STATUS_LIST };
