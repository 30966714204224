import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { Feedback } from "@aptedge/lib-ui/src/components/Feedback/Feedback";
import classNames from "classnames";
import { useFormik } from "formik";
import { useEffect, useRef, FC } from "react";
import * as Yup from "yup";

interface Props {
  onCancel: () => void;
  getChannels: (url: string, tenantId: string, groupId: string) => void;
  errorMsg: string;
}

interface FormState {
  channels: string;
}

const MSTeamsAddChannel: FC<Props> = ({
  onCancel,
  getChannels,
  errorMsg
}: Props) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddChannel = (grpUrl: string): void => {
    getChannels(grpUrl, "", "");
  };

  const formik = useFormik<FormState>({
    initialValues: {
      channels: ""
    },
    onSubmit: (values: FormState) => {
      handleAddChannel(values.channels);
    },
    validateOnBlur: false,
    validationSchema: Yup.object({
      channels: Yup.string()
        .url("Invalid URL format")
        .test(
          "https://teams.microsoft.com/",
          'URL must start with "https://teams.microsoft.com/"',
          (value) => {
            if (typeof value === "string") {
              return /^https:\/\/teams\.microsoft\.com\/.*/.test(value);
            }
            return false;
          }
        )
        .required("A team URL is required")
    })
  });

  return (
    <div className={classNames("label-create-item p-2")}>
      <h5>Add a new team</h5>
      <form onSubmit={formik.handleSubmit}>
        <div className="form-row">
          <div className="form-group col-md-3">
            <label htmlFor="fieldName">Team URL</label>
          </div>
          <div className="form-group col-md-9">
            <input
              data-testid="msteams-group-url"
              id="channels"
              type="text"
              ref={inputRef}
              className={classNames("form-control", {
                "is-invalid": !!errorMsg || !!formik.errors.channels
              })}
              aria-describedby="channelsHelp"
              value={formik.values.channels}
              onChange={formik.handleChange}
            />
            {(formik.errors.channels || errorMsg) && (
              <Feedback>{errorMsg || formik.errors.channels}</Feedback>
            )}
          </div>
        </div>

        <div className="d-flex mt-2 justify-content-end">
          <Button
            data-testid="msteams-add-channel-cancel"
            type="button"
            className="mr-2"
            color="secondary"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            color="primary"
            disabled={
              !!formik.errors.channels || !!errorMsg || !formik.values.channels
            }
          >
            Connect
          </Button>
        </div>
      </form>
    </div>
  );
};

export { MSTeamsAddChannel };
