import classNames from "classnames";
import { FC } from "react";
import styles from "./styles.module.scss";

const ProgressBar: FC<{
  isUsageLimitReached: boolean;
  remainingSearchesPercentage: string | number;
}> = ({ isUsageLimitReached, remainingSearchesPercentage }) => {
  return (
    <div
      className={classNames(styles.progressBar, {
        [styles.completedProgressBar]: isUsageLimitReached
      })}
    >
      <div
        className={classNames({
          [styles.completedFillter]: isUsageLimitReached,
          [styles.progressBarFiller]: !isUsageLimitReached
        })}
        style={{ width: `${remainingSearchesPercentage}%` }}
      />
    </div>
  );
};

export default ProgressBar;
