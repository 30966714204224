import { ActionBar } from "@aptedge/lib-ui/src/components/ActionBar/ActionBar";
import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { AeIcons } from "@aptedge/lib-ui/src/components/Icon/AeIcons";
import { Icon } from "@aptedge/lib-ui/src/components/Icon/Icon";
import {
  ModalFooter,
  ModalWidth
} from "@aptedge/lib-ui/src/components/Modal/Modal";
import { Spacer } from "@aptedge/lib-ui/src/components/Spacer/Spacer";
import { Tooltip } from "@aptedge/lib-ui/src/components/Tooltip/Tooltip";
import { useAppSelector } from "@aptedge/lib-ui/src/redux/hook/hook";
import { ISharepointDriveSite } from "@aptedge/lib-ui/src/types/entities";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons/faTrashAlt";
import React, { useState, ReactNode } from "react";
import { useMutation } from "react-query";
import { updateSharepointDriveSites } from "../../../clients/ArticleSources/updateSharepointDriveSites";
import { ConfirmationModal } from "../../../components/ConfirmationModal/ConfirmationModal";
import { ClientStatus } from "../../../types/clients";
import "./SharepointDriveSiteList.scss";

interface Props {
  getDatasource: () => void;
}

const SharepointDriveSiteList: React.FC<Props> = (props: Props) => {
  const { getDatasource } = props;

  const { dataSource } = useAppSelector((state) => state.sharepointDrive);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [siteRemove, setSiteRemove] = useState<ISharepointDriveSite | null>(
    null
  );
  const [status, setStatus] = React.useState(ClientStatus.PROMPT);

  const handleDeleteClick = (site: ISharepointDriveSite): void => {
    setSiteRemove(site);
    setShowConfirm(true);
  };

  const handleCancelDeleteModal = (): void => {
    setSiteRemove(null);
    setShowConfirm(false);
  };

  const updateSource = useMutation(updateSharepointDriveSites, {
    onError: () => {
      setSiteRemove(null);
      setShowConfirm(false);
      setStatus(ClientStatus.PROMPT);
    },
    onSuccess: () => {
      setSiteRemove(null);
      setShowConfirm(false);
      setStatus(ClientStatus.PROMPT);
      getDatasource();
    }
  });

  const removeSiteFromList = (): void => {
    const dataSourceSites = dataSource?.sites ?? [];
    const index =
      dataSourceSites.findIndex(
        (item) => item?.siteId === siteRemove?.siteId
      ) ?? null;
    if (
      siteRemove?.siteId === dataSourceSites[index]?.siteId &&
      !!index &&
      !!dataSource
    ) {
      const tempList = dataSourceSites?.map(
        (site: ISharepointDriveSite) => site.siteId
      );
      tempList?.splice(index, 1);
      updateSource.mutate({ sites: tempList, tenantId: dataSource?.tenantId });
    }
  };

  const renderSiteItem = (item: ISharepointDriveSite): ReactNode => {
    return (
      <div
        key={`${item?.siteId}`}
        className="mt-2 mb-4 d-flex justify-content-between align-items-center site-list-border"
      >
        <div className="sharepoint-link-icon col-md-1">
          <AeIcons.Link />
        </div>
        <div className="text-dark col-md-3 site-id">
          <Tooltip content={`ID: ${item.siteId}`}>
            <div className="text-dark site-id">{`ID: ${item?.siteId}`}</div>
          </Tooltip>
        </div>
        <div className="text-dark col-md-4">{`#${item.siteName}`}</div>
        <div
          data-testid={`delete-site-${item?.siteId}`}
          className="col-md-1 cursor-pointer align-items-center justify-content-end"
          onClick={() => handleDeleteClick(item)}
        >
          <Icon icon={faTrashAlt} />
        </div>
      </div>
    );
  };

  return (
    <>
      {dataSource?.sites && dataSource?.sites?.length > 0 ? (
        <div className={"col-md-12 custom-field-list-item group"}>
          {dataSource.sites?.map((site: ISharepointDriveSite) =>
            renderSiteItem(site)
          )}
        </div>
      ) : (
        <p className="badge text-muted">No Site added</p>
      )}
      <ConfirmationModal
        title={`Confirmation Modal`}
        width={ModalWidth.MEDIUM}
        isOpen={showConfirm}
        status={status}
        loadingTitle="Loading..."
        onClose={handleCancelDeleteModal}
      >
        <p>{`Are you sure you want to remove this site.`}</p>
        <p>{`Site ID:    ${siteRemove?.siteId}`}</p>
        <p>{`Site Name:  ${siteRemove?.siteName}`}</p>
        <p></p>

        <ModalFooter>
          <ActionBar.Container>
            <ActionBar.Right>
              <Spacer flex row size="medium">
                <Button color="secondary" onClick={handleCancelDeleteModal}>
                  Cancel
                </Button>
                <Button
                  data-testid="delete button"
                  color="danger"
                  onClick={removeSiteFromList}
                >
                  Delete
                </Button>
              </Spacer>
            </ActionBar.Right>
          </ActionBar.Container>
        </ModalFooter>
      </ConfirmationModal>
    </>
  );
};

export { SharepointDriveSiteList };
