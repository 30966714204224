import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import styles from "./FilterAddRuleButton.module.scss";

interface FilterAddRuleButtonProps {
  handleAddRule: () => void;
  isDisabled: boolean;
}

const FilterAddRuleButton: React.FC<FilterAddRuleButtonProps> = ({
  handleAddRule,
  isDisabled
}) => {
  return (
    <button
      className={styles.addRuleButton}
      onClick={handleAddRule}
      disabled={isDisabled}
    >
      Add Rule
      <Icons.Plus size="xs" className="" />
    </button>
  );
};

export { FilterAddRuleButton };
