import { request } from "../../../../app-web/src/utils/request";
import { ISearchFilter, SearchFilterSubType } from "../../types/entities";
import { getBackendApiUrl } from "../../utils/config";

export interface SearchFilterDropdown {
  filter_key: SearchFilterSubType;
  icon: string | "";
  label: string;
}

export interface SearchFilters {
  filter_key: ISearchFilter;
  key: string;
  icon: string | "";
  label: string;
  filters: SearchFilterDropdown[] | undefined;
}
export const getSearchFilters = async (): Promise<{
  groups: SearchFilters[];
}> => {
  const baseURL = getBackendApiUrl();

  const url = `${baseURL}/dynamic/filters`;

  return request(url);
};
