import { PreventOverflowModifier } from "@popperjs/core/lib/modifiers/preventOverflow";

const DEFAULT_PREVENT_OVERFLOW_MODIFIER: Partial<PreventOverflowModifier> = {
  name: "preventOverflow",
  options: {
    altAxis: true,
    padding: 10,
    altBoundary: false,
    tether: false
  }
};

export { DEFAULT_PREVENT_OVERFLOW_MODIFIER };
