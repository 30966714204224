import { getBackendUrl } from "../../../config";
import { MSTeamsConfigFormData } from "../../../pages/SettingsPage/Integrations/MSTeams/MSTeamsConfigForm";
import { request } from "../../../utils/request";
import { post } from "../../utils/buildRequest";

async function createMSTeamsConnection(
  args: MSTeamsConfigFormData
): Promise<MSTeamsConfigFormData> {
  const url = `${getBackendUrl()}/api/integrations/msteams`;
  const options: RequestInit = post(args);
  return request(url, options);
}

export { createMSTeamsConnection };
