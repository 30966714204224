/* eslint-disable @typescript-eslint/no-explicit-any */
import { useLatest } from "@aptedge/lib-ui/src/hooks/useLatest";
import type { Dictionary } from "lodash";
import pick from "lodash/pick";
import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  boxValueIntoArray,
  fromQueryString,
  toQueryString
} from "../clients/utils/url";

/**
 * A hook that gets the current query string from the URL and parses it into an object,
 * and overlays the given defaults to replace any undefined values (not in the URL).
 *
 * @param defaultValues the defaults that should be used if those values are not in the URL.
 */
function useUrlSearchParams<T extends Record<string, unknown>>(
  defaultValues: T
): [T, (updates: Partial<T>) => void] {
  const history = useHistory();
  const { search } = useLocation();

  const defaults = useLatest(defaultValues);

  const queryParams = useMemo(() => {
    const allParams = fromQueryString(search);

    // If there were defaults, only keep matching keys.
    const whitelistedKeys = Object.keys(defaults);
    const values: Dictionary<any> = !!whitelistedKeys.length
      ? pick(allParams, whitelistedKeys)
      : allParams;

    // Go through all array properties and force them into arrays (1 -> [1]).
    whitelistedKeys
      .filter((k) =>
        Array.isArray(defaults[k as keyof Record<string, unknown>])
      )
      .forEach((key) => {
        if (values[key]) {
          values[key] = boxValueIntoArray(values[key]);
        }
      });

    // Override the defaults with boxed values.
    return {
      ...defaults,
      ...values
    };
  }, [defaults, search]);

  const setQueryParams = useCallback(
    // TODO - assign the correct type below where the generic type was temporarily modified to any.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (changes: Partial<any>): void => {
      const allParams = fromQueryString(search);
      const whitelistedKeys = Object.keys(defaults);

      // Only allow this consumer to change keys based on defaultValues.
      const allowedChanges = pick(changes, whitelistedKeys);
      const updated = {
        ...allParams,
        ...allowedChanges
      };

      history.replace({ search: toQueryString(updated) });
    },
    [defaults, search, history]
  );

  return [queryParams, setQueryParams];
}

export { useUrlSearchParams };
