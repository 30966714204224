import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import React from "react";

interface Props {
  message?: string;
  resetErrorBoundary: () => void;
}

const GetDataErrorFallback: React.FC<Props> = ({
  message = "Unable to get data.",
  resetErrorBoundary
}) => {
  return (
    <div className="alert alert-info m-3 text-center" role="alert">
      <p>{message}</p>
      <Button className="m-auto" size="small" onClick={resetErrorBoundary}>
        Try again
      </Button>
    </div>
  );
};

export { GetDataErrorFallback };
