import { filtersWithDefaults } from "@aptedge/lib-ui/src/components/Filter/filterUtils";
import { IEdgeListing } from "@aptedge/lib-ui/src/types/entities";
import { queryParamsToFlaskDateRange } from "@aptedge/lib-ui/src/utils/date";
import {
  IQueryArgs,
  toLZCompressedString
} from "@aptedge/lib-ui/src/utils/query";
import { getBackendApiUrl } from "../../config";
import { IPaginatedRequest, IPaginatedResponse } from "../../types/clients";
import { request } from "../../utils/request";

type Query = IPaginatedRequest<IQueryArgs>;

function fetchEdges(args: Query): Promise<IPaginatedResponse<IEdgeListing[]>> {
  const { timeDimensions: td, filters, sortBy, ...rest } = args;

  const timeDimensions = td?.map((t) => ({
    ...t,
    values: queryParamsToFlaskDateRange(t.values)
  }));

  const withDefaults = filtersWithDefaults(filters, [
    { id: "archived", operator: "equals", values: ["false"] }
  ]);

  const query = toLZCompressedString({
    filters: withDefaults,
    sortBy,
    timeDimensions,
    ...rest
  });
  const url = `${getBackendApiUrl()}/edges/?q=${query}`;

  return request(url);
}

export { fetchEdges };
