import { IUserListData } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { IUpdateUserRequest } from "../../types/clients";
import { request } from "../../utils/request";
import { patch } from "../utils/buildRequest";

async function updateUser({
  userId,
  patchContent
}: {
  userId: number;
  patchContent: IUpdateUserRequest;
}): Promise<IUserListData> {
  const options = patch(patchContent);

  return request(`${getBackendApiUrl()}/users/${userId}`, options);
}

export { updateUser };
