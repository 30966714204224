enum TransitionDurations {
  DEFAULT = ".2s"
}

enum Delay {
  DEFAULT = 300,
  LONG = 600
}

export { TransitionDurations, Delay };
