import { AeIcons } from "@aptedge/lib-ui/src/components/Icon/AeIcons";
import {
  useAppDispatch,
  useAppSelector
} from "@aptedge/lib-ui/src/redux/hook/hook";
import { updateProductFilters } from "@aptedge/lib-ui/src/redux/reduxSlice/searchSlice";
import {
  AptAnswersContextType,
  AptAnswersSearchContextItem
} from "@aptedge/lib-ui/src/types/entities";
import * as React from "react";
import { useAptAnswersSearchContext } from "../../../hooks/clients/useAptAnswersSearchContext";
import { SearchFilterMenuRoot } from "./components/SearchFilterMenuRoot";

const ProductFiltersMenu: React.FC = () => {
  const {
    productFiltersIsEmpty: isEmpty,
    productFiltersIsError: isError,
    isLoading,
    productFiltersItems: items
  } = useAptAnswersSearchContext();

  const dispatch = useAppDispatch();
  const { myProducts, productFilters } = useAppSelector(
    (state) => state.search
  );
  const selectedFilterIds = productFilters.map((pf) => pf.id);
  const handleItemClick = (id: string): void => {
    const productFilterItem = items.find((item) => item.id === id);
    if (!productFilterItem) {
      return;
    }
    const updatedProductFilters = selectedFilterIds.includes(id)
      ? productFilters.filter((pf) => pf.id !== id)
      : [...productFilters, productFilterItem];
    dispatch(updateProductFilters(updatedProductFilters));
  };

  const handleSelectAll = (): void => {
    const updatedProductFilters =
      productFilters.length === items.length ? [] : items;
    dispatch(updateProductFilters(updatedProductFilters));
  };

  const handleReset = (): void => {
    const updatedProductFilters: AptAnswersSearchContextItem[] = [];
    dispatch(updateProductFilters(updatedProductFilters));
  };

  React.useEffect(() => {
    if (myProducts.length) {
      dispatch(updateProductFilters(myProducts));
    }
  }, [myProducts, dispatch]);

  if (isEmpty || isError || isLoading) {
    return null;
  }

  return (
    <div>
      <SearchFilterMenuRoot
        contextType={AptAnswersContextType.PRODUCT_FILTER}
        label="Products"
        icon={<AeIcons.ProductFiltersMenu />}
        count={productFilters.length}
        items={items}
        enableSelectAll={true}
        selectedItems={selectedFilterIds}
        handleItemClick={handleItemClick}
        handleSelectAll={handleSelectAll}
        handleReset={handleReset}
      />
    </div>
  );
};

export { ProductFiltersMenu };
