import md5 from "md5";
import querystring from "query-string";

function getGravatarImageSrc(
  email?: string,
  options: { size?: number; defaultImg?: string } = { defaultImg: "mp" }
): string | undefined {
  if (!email) {
    return;
  }

  const base = `https://www.gravatar.com/avatar/`;
  const query = querystring.stringify({
    size: 320,
    r: "g",
    d: options?.defaultImg
  });

  const formattedEmail = email.trim().toLowerCase();
  const hash = md5(formattedEmail, { encoding: "binary" });

  return `${base}${hash}?${query}`;
}

export { getGravatarImageSrc };
