import { IJiraCloudInternalIssueSource } from "@aptedge/lib-ui/src/types/entities";
import { getBackendUrl } from "../../config";
import { request } from "../../utils/request";

function fetchJiraCloudInternalIssueSources(): Promise<
  IJiraCloudInternalIssueSource[]
> {
  const url = `${getBackendUrl()}/jira/tap`;

  return request(url);
}

export { fetchJiraCloudInternalIssueSources };
