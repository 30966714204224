import isEqual from "lodash/isEqual";
import { DependencyList, EffectCallback, useEffect, useRef } from "react";

function useDeepCompareDeps(
  dependencyList?: DependencyList
): DependencyList | undefined {
  const ref = useRef<DependencyList>();

  if (!isEqual(dependencyList, ref.current)) {
    ref.current = dependencyList;
  }

  return ref.current;
}

function useEffectOnlyOnDeepChange(
  callback: EffectCallback,
  deps?: DependencyList
): void {
  useEffect(callback, useDeepCompareDeps(deps));
}

export { useEffectOnlyOnDeepChange, useDeepCompareDeps };
