import { getBackendApiUrl } from "../../config";
import { deleteRequest } from "../../utils/request";
import { deleteInit } from "../utils/buildRequest";

export async function deleteTeam({
  teamId
}: {
  teamId: number;
}): Promise<void> {
  const url = `${getBackendApiUrl()}/teams/${teamId}/`;

  return deleteRequest(url, deleteInit());
}
