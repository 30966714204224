import * as React from "react";
import styles from "./FilterSelectAll.module.scss";

interface FilterSelectAllProps {
  label: string;
  icon: React.ReactNode;
  selected: boolean;
  handleClick: () => void;
}

const FilterSelectAll: React.FC<FilterSelectAllProps> = ({
  label,
  icon,
  selected,
  handleClick
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.infoContainer}>
        <span className={styles.icon}>{icon}</span>
        <span className={styles.label}>{label}</span>
      </div>
      <input type="checkbox" checked={selected} onChange={handleClick} />
    </div>
  );
};

export { FilterSelectAll };
