import { ModalWidth } from "@aptedge/lib-ui/src/components/Modal/Modal";
import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import { IBusinessDataSource } from "@aptedge/lib-ui/src/types/entities";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { ToastType } from "react-toastify";
import { saveBusinessDataSourceClient } from "../../../clients/BusinessDataSources/saveBusinessDataSource";
import { ConfirmationModal } from "../../../components/ConfirmationModal/ConfirmationModal";
import { Toast } from "../../../components/Toast/Toast";
import WithLoading from "../../../components/WithLoading/WithLoading";
import { getBackendUrl } from "../../../config";
import { UseOAuthDataSourceResult } from "../../../hooks/clients/useDataSource";
import { ClientStatus } from "../../../types/clients";
import { AddButton } from "../common/AddButton";
import { StatusButton } from "../common/StatusButton";
import { providerIsAuthorized } from "../utils/providerIsAuthorized";
import {
  SalesforceConfigForm,
  SalesforceFormData
} from "./SalesforceConfigForm";

interface Props {
  resource: UseOAuthDataSourceResult<IBusinessDataSource>;
}

const BusinessSourceConfig: React.FunctionComponent<Props> = (props) => {
  const { resource } = props;

  const [modalIsVisible, setModalIsVisible] = useState<boolean>(false);
  const [redirectStatus, setRedirectStatus] = React.useState(
    ClientStatus.PROMPT
  );

  const source = resource.dataSource || null;
  const isLoading = resource.isLoading;

  const saveSource = useMutation(saveBusinessDataSourceClient, {
    onError: () => {
      setRedirectStatus(ClientStatus.PROMPT);
    },
    onSuccess: () => {
      // Redirect to the OAuth endpoint.
      const url = `${getBackendUrl()}/salesforce/login`;
      window.location.href = url;
    }
  });

  const errorSaving = !!saveSource.error;

  const onFormSubmit = (formData: SalesforceFormData): void => {
    saveSource.mutate({ ...formData, providerUserLogin: "" });
    setRedirectStatus(ClientStatus.LOADING);
  };

  return (
    <section>
      <div className="row">
        <WithLoading isLoading={isLoading} fallback={<Spinner />}>
          <div className="col-md-5 p-2 pr-3">
            <h4 className="text-uppercase">Customer Data</h4>
            <p>Connect AptEdge to your CRM.</p>
          </div>
          <div className="col-md-7 p-2 pl-3">
            <div className="mb-3">
              <h5>Salesforce</h5>
              <p>Configure your OAuth settings below.</p>
            </div>
            {source ? (
              <StatusButton
                status={providerIsAuthorized(source) ? "ok" : "error"}
                onClickEdit={() => setModalIsVisible(true)}
              >
                <>
                  <div>Salesforce</div>
                  <div className="text-muted">
                    {source?.instanceName || "salesforce.com"}
                  </div>
                </>
              </StatusButton>
            ) : (
              <AddButton onClick={() => setModalIsVisible(true)}>
                Connect
              </AddButton>
            )}
          </div>
          <ConfirmationModal
            title="Salesforce OAuth Settings"
            width={ModalWidth.MEDIUM}
            loadingTitle="Redirecting to Salesforce..."
            status={redirectStatus}
            isOpen={modalIsVisible}
            onClose={() => setModalIsVisible(false)}
          >
            <p className="mb-4">Configure your settings below.</p>
            <SalesforceConfigForm source={source} onSubmit={onFormSubmit} />
          </ConfirmationModal>
        </WithLoading>
      </div>
      <hr className="mt-5" />

      {errorSaving && (
        <Toast type={ToastType.ERROR}>Something went wrong.</Toast>
      )}
    </section>
  );
};

export { BusinessSourceConfig };
