import { IEdgeInfo } from "@aptedge/lib-ui/src/types/entities";
import React from "react";

type Action = { type: "setEdge"; payload: IEdgeInfo };
type Dispatch = (action: Action) => void;
type State = { edge: IEdgeInfo };
type EdgeProviderProps = { children: React.ReactNode; edge: IEdgeInfo };

const EdgeStateContext = React.createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined);

const edgeReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "setEdge": {
      return { ...state, edge: action.payload };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

const EdgeProvider: React.FC<EdgeProviderProps> = ({ children, edge }) => {
  const [state, dispatch] = React.useReducer(edgeReducer, { edge });
  const value = { state, dispatch };
  return (
    <EdgeStateContext.Provider value={value}>
      {children}
    </EdgeStateContext.Provider>
  );
};

export { EdgeProvider, EdgeStateContext };
