import { ActionBar } from "@aptedge/lib-ui/src/components/ActionBar/ActionBar";
import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import { List } from "@aptedge/lib-ui/src/components/List/List";
import { ModalFooter } from "@aptedge/lib-ui/src/components/Modal/Modal";
import {
  NoData,
  NoDataMessage
} from "@aptedge/lib-ui/src/components/NoData/NoData";
import { Spacer } from "@aptedge/lib-ui/src/components/Spacer/Spacer";
import { TextInput } from "@aptedge/lib-ui/src/components/TextInput/TextInput";
import { IEdgeInfo, IInternalIssue } from "@aptedge/lib-ui/src/types/entities";
import { useFormik } from "formik";
import React, { useRef, useState } from "react";
import { useMutation } from "react-query";
import * as Yup from "yup";
import { updateEdge } from "../../clients/Edges/updateEdge";
import { convertQueryStateToClientStatus } from "../../clients/utils/convertQueryStateToClientStatus";
import { ConfirmationModal } from "../../components/ConfirmationModal/ConfirmationModal";
import { createErrorMessage } from "../../utils/createErrorMessage";
import { EdgeIssue } from "./EdgeIssue";
import { EdgeSectionLabel } from "./EdgeSectionLabel";

interface Props {
  edge: IEdgeInfo;
  onUpdateEdge: (edge: IEdgeInfo) => void;
}

interface FormData {
  jira: string;
}

const EdgeIssuesWidget: React.FC<Props> = ({ edge, onUpdateEdge }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [issueToDelete, setIssueToDelete] = useState<IInternalIssue>();
  const [focused, setFocused] = useState<boolean>(false);

  const jiraKeys = edge.internalIssues.map((i) => i.externalId);
  const lowerCaseKeys = jiraKeys.map((k) => k.toLowerCase());

  const addJira = useMutation(updateEdge, {
    onSuccess: (updatedEdge) => {
      onUpdateEdge(updatedEdge);
      setFocused(false);
      formik.resetForm();
    },
    onError: (e) => {
      formik.setFieldError("jira", createErrorMessage(e));
    }
  });

  const removeJira = useMutation(updateEdge, {
    onSuccess: (updatedEdge) => {
      onUpdateEdge(updatedEdge);
      setIssueToDelete(undefined);
    }
  });

  const formik = useFormik<FormData>({
    initialValues: {
      jira: ""
    },
    validationSchema: Yup.object({
      jira: Yup.string()
        .required("A Jira ID or URL is required.")
        .test(
          "unique",
          "This Jira ID has already been added.",
          (value) =>
            !!value && !lowerCaseKeys.includes(value.trim().toLowerCase())
        )
    }),
    onSubmit: (data) => {
      const issueIds: string[] = jiraKeys.slice();
      // Add the new issueId to existing ids
      issueIds.push(data.jira);

      addJira.mutate({ edgeId: edge.id, patchContent: { jiraKeys: issueIds } });
    }
  });

  const handleClickDelete = (issue: IInternalIssue): void => {
    removeJira.reset();
    setIssueToDelete(issue);
  };

  const handleDelete = (externalId: string): void => {
    // Remove the issue from issues
    const issueIds: string[] = jiraKeys.filter((i) => i !== externalId);

    removeJira.mutate({
      edgeId: edge.id,
      patchContent: { jiraKeys: issueIds }
    });
  };

  const handleCancel = (): void => {
    formik.resetForm();
    setFocused(false);
  };

  return (
    <div className="issues-widget">
      <div className="header d-flex align-items-baseline justify-content-between">
        <EdgeSectionLabel>Jiras</EdgeSectionLabel>
        <Button
          color="secondary"
          size="small"
          className="issue-add"
          data-testid="issue-add"
          onClick={() => setFocused(true)}
          disabled={edge.archived}
        >
          <Icons.Plus />
          <span>Add</span>
        </Button>
      </div>
      {!!edge.internalIssues.length ? (
        <List>
          {edge.internalIssues.map((i) => (
            <EdgeIssue
              key={i.id}
              issue={i}
              disabled={edge.archived}
              onClickDelete={() => handleClickDelete(i)}
            />
          ))}
        </List>
      ) : (
        <>{!focused && <NoData message={NoDataMessage.LINKED_JIRAS} />}</>
      )}
      <div className="new-issue">
        {focused && (
          <form onSubmit={formik.handleSubmit} data-testid="new-jira-issue">
            <TextInput
              ref={inputRef}
              autoFocus
              name="jira"
              onChange={formik.handleChange}
              onClear={() => formik.resetForm()}
              value={formik.values.jira}
              placeholder="Add Jira ID or Jira URL..."
              error={formik.errors.jira}
            />
            <ActionBar.Container>
              <ActionBar.Right>
                <Button color="secondary" onClick={handleCancel} type="reset">
                  Cancel
                </Button>
                <Button
                  disabled={!formik.values.jira || !!formik.errors.jira}
                  color="primary"
                  type="submit"
                >
                  Save
                </Button>
              </ActionBar.Right>
            </ActionBar.Container>
          </form>
        )}
      </div>

      <ConfirmationModal
        title="Remove issue?"
        status={convertQueryStateToClientStatus(removeJira)}
        isOpen={!!issueToDelete}
        loadingTitle="Removing issue..."
        onClose={() => setIssueToDelete(undefined)}
      >
        <p>Are you sure you want to remove this issue?</p>
        <ModalFooter>
          <ActionBar.Container>
            <ActionBar.Right>
              <Spacer flex row size="medium">
                <Button
                  color="secondary"
                  onClick={() => setIssueToDelete(undefined)}
                >
                  Cancel
                </Button>
                <Button
                  data-testid="delete button"
                  color="danger"
                  onClick={() =>
                    !!issueToDelete && handleDelete(issueToDelete.externalId)
                  }
                >
                  Remove
                </Button>
              </Spacer>
            </ActionBar.Right>
          </ActionBar.Container>
        </ModalFooter>
      </ConfirmationModal>
    </div>
  );
};

export { EdgeIssuesWidget };
