import React from "react";
import { TeamSettings } from "./TeamSettings";
import { TemplateSettings } from "./TemplateSettings";

const WorkflowSettings: React.FC = () => {
  return (
    <>
      <TeamSettings />
      <TemplateSettings />
    </>
  );
};

export { WorkflowSettings };
