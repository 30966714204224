import { RefObject } from "react";
import { Keys } from "../styles/keys";
import { eventWasTriggeredOutsideRef } from "../utils/domUtils";
import { useEventListener } from "./useEventListener";

function useOnKeydownOutside<E extends HTMLElement>(
  ref: RefObject<E>,
  keys: Keys[],
  cb: () => void
): void {
  useEventListener("keydown", (e) => {
    if (
      !!ref.current &&
      keys.includes(e.key as Keys) &&
      eventWasTriggeredOutsideRef(e, ref)
    ) {
      cb();
    }
  });
}

export { useOnKeydownOutside };
