import { Avatar } from "@aptedge/lib-ui/src/components/Avatar/Avatar";
import { Tooltip } from "@aptedge/lib-ui/src/components/Tooltip/Tooltip";
import { BoxSizing } from "@aptedge/lib-ui/src/styles/boxes";
import {
  ITicketComment,
  ITicketInfo
} from "@aptedge/lib-ui/src/types/entities";
import classNames from "classnames";
import he from "he";
import React from "react";
import { displayAge } from "../../utils/time";
import { Highlight } from "../Highlight/Highlight";
import { SearchResultPreview } from "../SearchResultPreview/SearchResultPreview";
import { TicketFrustrationInfo } from "./TicketFrustrationInfo";

interface Props {
  comment: ITicketComment;
  ticket: ITicketInfo;
}

const TicketComment: React.FC<Props> = ({ comment, ticket }) => {
  const jiraReferences =
    ticket.urlReferences?.filter((j) => !!j.productIssueId) || [];
  const urls = jiraReferences.map((j) => j.url) || [];
  const searchWords = ticket.frustration ? [ticket.frustration.text] : [];

  return (
    <div className="comment">
      {comment.fromTicketUser && (
        <Avatar
          color={!comment.internal ? "var(--orange)" : "var(--primary)"}
          text={comment.fromTicketUser.name}
          size={BoxSizing.XXXL}
          showTooltip={false}
        />
      )}
      <div>
        <div className="d-flex justify-content-between comment-header">
          <div>
            {comment.fromTicketUser && (
              <span className="name">{comment.fromTicketUser.name}</span>
            )}
            {!!comment.internal && <span className="internal">(Internal)</span>}
            <span className="comment-age pl-2">
              {displayAge(comment.timestamp)}
            </span>
          </div>
        </div>
        <div
          className={classNames("comment-body", {
            internal: !!comment.internal
          })}
        >
          {comment.plainText.includes("offset-highlight") ? (
            <pre>
              <SearchResultPreview previewSnippet={comment.plainText} />
            </pre>
          ) : (
            <pre>
              <Highlight
                autoEscape={true}
                searchWords={searchWords}
                textToHighlight={comment.plainText}
                sanitize={(f) => {
                  if (f !== comment.plainText) {
                    if (
                      comment.plainText.replace(/&nbsp;/g, " ") ===
                      f.replace(/&nbsp;/g, " ")
                    ) {
                      return comment.plainText;
                    }
                  }
                  return f;
                }}
                urls={urls}
                highlightTag={(props) => {
                  return (
                    <Tooltip
                      content={
                        <TicketFrustrationInfo
                          highlightedText={props.children}
                          frustration={ticket.frustration}
                        />
                      }
                      placement="top-end"
                    >
                      <span className="highlighted">
                        {he.decode(props.children)}
                      </span>
                    </Tooltip>
                  );
                }}
              />
            </pre>
          )}
        </div>
      </div>
    </div>
  );
};

export { TicketComment };
