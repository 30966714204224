import React from "react";
import { useQuery } from "react-query";
import { WebCacheKey } from "../../../clients/cache";
import { fetchCustomFieldMappings } from "../../../clients/CustomFieldMappings/fetchCustomFieldMappings";
import { CustomFieldMappingList } from "./CustomFieldMappingList";

function CustomFieldMappingConfig(): React.ReactElement {
  const customFieldMappingQuery = useQuery(
    WebCacheKey.CUSTOM_FIELD_MAPPING_CONFIG,
    () => fetchCustomFieldMappings()
  );
  const customFieldMappings = customFieldMappingQuery.data;

  const onChange = (): void => {
    customFieldMappingQuery.refetch();
  };

  return (
    <div className="mt-5">
      <CustomFieldMappingList
        customFieldMappings={customFieldMappings || []}
        onChange={onChange}
      />
    </div>
  );
}

export { CustomFieldMappingConfig };
