import classNames from "classnames";
import React from "react";
import "./TabsBar.scss";

interface Props {
  activeTab: number;
  onChangeTab: (idx: number) => void;
  tabs: Array<string>;
}

function TabsBar(props: Props): React.ReactElement {
  return (
    <div className="tabs-bar">
      {props.tabs.map((t, i) => (
        <div
          className={classNames("tab", {
            active: i === props.activeTab
          })}
          key={i}
          onClick={() => props.onChangeTab(i)}
        >
          {t}
        </div>
      ))}
    </div>
  );
}

export { TabsBar };
