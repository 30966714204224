import InfoIcon from "@mui/icons-material/Info";
import classNames from "classnames";
import { FC } from "react";
import styles from "./DiscardModal.module.scss";

export enum DiscardSource {
  ARTICLE_REVIEW_PAGE = "article_review_page",
  ARTICLE_POPUP = "article_popup"
}

type DiscardProps = {
  handleHideDiscard: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleDeleteArticle: (e: React.MouseEvent<HTMLButtonElement>) => void;
  discardSource: DiscardSource;
};

const DiscardModal: FC<DiscardProps> = ({
  handleHideDiscard,
  handleDeleteArticle,
  discardSource
}) => (
  <div
    className={classNames(styles.discardContainer, {
      [styles.discardContainerArticlePopupOrReview]:
        discardSource === DiscardSource.ARTICLE_REVIEW_PAGE ||
        discardSource === DiscardSource.ARTICLE_POPUP
    })}
    data-testid="discard-modal"
  >
    <div
      className={classNames(styles.tooltipArrow, {
        [styles.tooltipArrowReviewPage]:
          discardSource === DiscardSource.ARTICLE_REVIEW_PAGE ||
          discardSource === DiscardSource.ARTICLE_POPUP
      })}
    ></div>
    <div className={styles.discardLeftContainer}>
      <InfoIcon className={styles.infoIcon} />
    </div>
    <div className={styles.discardRightContainer}>
      <span className={styles.discardHeader}>Discard article</span>
      <span className={styles.discardBody}>
        Are you sure you want to discard this article?
      </span>
      <div className={styles.discardActions}>
        <button className={styles.confirm} onClick={handleDeleteArticle}>
          Yes
        </button>
        <button className={styles.cancel} onClick={handleHideDiscard}>
          No
        </button>
      </div>
    </div>
  </div>
);

export { DiscardModal };
