import { Chip } from "@aptedge/lib-ui/src/components/Chip/Chip";
import { ILabel } from "@aptedge/lib-ui/src/types/entities";
import classNames from "classnames";
import React from "react";
import "./LabelChip.scss";

type Props = {
  label: ILabel;
  disabled?: boolean;
  onDelete?: () => void;
};

function LabelChip(props: Props): React.ReactElement {
  const { label, disabled, onDelete } = props;

  return (
    <Chip
      label={label.name}
      className={classNames("label-chip", label.type)}
      disabled={disabled}
      onDelete={onDelete}
    />
  );
}

export { LabelChip };
