export const CACHE_KEY = {
  SEARCH_FILTERS: "searchFilters",
  CSS_CONFIG: "cssConfig",
  SEARCH_STATUS: "searchStatus",
  COMPOSITE_SEARCH_AI: "composite_search_ai",
  WHOAMI: "whoami",
  FEATURE_FLAGS: "feature_flags",
  INSIGHT_AUTO_SEARCH: "insightAutoSearch",
  INSIGHT_SEARCH_SUGGESTION: "insightSearchSuggestion",
  INSIGHT_TICKET_SUMMARY: "insightTicketSummary",
  INSIGHT_CASE_LOG: "insightCaseLog",
  INSIGHT_NEXT_STEPS: "insightNextSteps",
  TICKET_DETAILS: "ticketDetails"
};
export const CACHE_TIME_15 = 15 * 60 * 1000; // 15 minutes
export const STALE_TIME_15 = 15 * 60 * 1000; // 15 minutes
