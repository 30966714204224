import { Editor, Position } from "codemirror";
import range from "lodash/range";
import {
  CodeMirrorTokenType,
  MarkdownBlockType,
  MarkdownInlineType
} from "./types";

const UNORDERED_LIST_RE = /^\s{0,}[*|\-|+]\s{1,}/i;
const ORDERED_LIST_RE = /^\s{0,}[0-9]{1,}(\.\s{1,})/i;
const BLOCKQUOTE_RE = /^>\s/i;
const HEADER_ONE_RE = /^#{1}\s/i;
const HEADER_TWO_RE = /^#{2}\s/i;
const HEADER_THREE_RE = /^#{3}\s/i;
const HEADER_FOUR_RE = /^#{4}\s/i;
const HEADER_FIVE_RE = /^#{5}\s/i;
const HEADER_SIX_RE = /^#{6}\s/i;
const IMAGE_RE = /^!\[(.*)]\(.*\)/i;
const CODE_RE = /^```/i;

const STYLED_BLOCKS = [
  UNORDERED_LIST_RE,
  ORDERED_LIST_RE,
  HEADER_ONE_RE,
  HEADER_TWO_RE,
  HEADER_THREE_RE,
  HEADER_FOUR_RE,
  HEADER_FIVE_RE,
  HEADER_SIX_RE,
  IMAGE_RE,
  CODE_RE
];

const STYLED_BLOCK_RE = new RegExp(
  `(${STYLED_BLOCKS.map((r) => r.source).join("|")})`
);

const BOLD_START_RE = /^(.{0,})([*]{2})/i;
const BOLD_END_RE = /([*]{2})(.{0,})$/i;
const ITALIC_START_RE = /^(.{0,})([*]{1})/i;
const ITALIC_END_RE = /([*]{1})(.{0,})$/i;
const STRIKETHROUGH_START_RE = /^(.{0,})([~]{2})/i;
const STRIKETHROUGH_END_RE = /([~]{2})(.{0,})$/i;
const INLINE_CODE_START_RE = /^(.{0,})([`]{1})/i;
const INLINE_CODE_END_RE = /([`]{1})(.{0,})$/i;

const BOLD_RE = /([*]{2})([^*]{0,})([*]{2})/gi;
const ITALIC_RE = /(\s)([*]{1})([^*]{0,})([*])(\s)/gi;
const STRIKETHROUGH_RE = /([~]{2})([^~]{0,})([~]{2})/gi;
const LINK_RE = /([^!])(\[)(([^[\]]*))(\])\([^()]*\)/gi;
const INLINE_CODE_RE = /([`]{1})([^`]{0,})([`]{1})/gi;

const EditorUtils = {
  getCursorPoints: (editor: Editor): { start: Position; end: Position } => {
    return { start: editor.getCursor("start"), end: editor.getCursor("end") };
  },

  isBlockActive: (editor: Editor, blockType: MarkdownBlockType): boolean => {
    const pos = editor.getCursor("start");
    const line = editor.getLine(pos.line);

    switch (blockType) {
      case MarkdownBlockType.HEADER_ONE:
        return HEADER_ONE_RE.test(line);
      case MarkdownBlockType.HEADER_TWO:
        return HEADER_TWO_RE.test(line);
      case MarkdownBlockType.HEADER_THREE:
        return HEADER_THREE_RE.test(line);
      case MarkdownBlockType.HEADER_FOUR:
        return HEADER_FOUR_RE.test(line);
      case MarkdownBlockType.HEADER_FIVE:
        return HEADER_FIVE_RE.test(line);
      case MarkdownBlockType.HEADER_SIX:
        return HEADER_SIX_RE.test(line);
      case MarkdownBlockType.ORDERED_LIST:
        return ORDERED_LIST_RE.test(line.trimStart());
      case MarkdownBlockType.UNORDERED_LIST:
        return UNORDERED_LIST_RE.test(line.trimStart());
      case MarkdownBlockType.CODE:
        return CODE_RE.test(line);
      case MarkdownBlockType.BLOCKQUOTE:
        return BLOCKQUOTE_RE.test(line);
      case MarkdownBlockType.IMAGE:
        return IMAGE_RE.test(line);
      case MarkdownBlockType.PARAGRAPH:
        return !STYLED_BLOCK_RE.test(line);
      default:
        return false;
    }
  },

  /** This is a really naive, simple implementation: if the start and end points of the selected
   * text have the given type, then return true.
   *
   * This ignores any text between these two points which might not have that formatting type.
   * For now, we don't handle that edge case.
   */
  isInlineStyleActive: (
    editor: Editor,
    inlineType: MarkdownInlineType
  ): boolean => {
    const { start, end } = EditorUtils.getCursorPoints(editor);
    const onSameLine = start.line === end.line;

    // Bail out.
    if (!onSameLine) return false;

    return (
      EditorUtils.positionHasStyle(editor, start, inlineType) &&
      EditorUtils.positionHasStyle(editor, end, inlineType)
    );
  },

  positionHasStyle: (
    editor: Editor,
    position: Position,
    inlineStyle: MarkdownInlineType
  ): boolean => {
    // tokenType is a single string with multiple types joined by a space. Split them.
    const tokenTypes = editor.getTokenTypeAt(position)?.split(" ") || [];

    switch (inlineStyle) {
      case MarkdownInlineType.BOLD:
        return tokenTypes.includes(CodeMirrorTokenType.BOLD);
      case MarkdownInlineType.ITALIC:
        return tokenTypes.includes(CodeMirrorTokenType.ITALIC);
      case MarkdownInlineType.STRIKETHROUGH:
        return tokenTypes.includes(CodeMirrorTokenType.STRIKETHROUGH);
      case MarkdownInlineType.LINK:
        // CodeMirror has an issue where it marks incomplete links.
        // So: we need to loop through the line, get all matches by RegEx,
        // then compare their location with the current cursor.
        const line = editor.getLine(position.line);
        const matches: [number, number][] = [];
        let match: RegExpExecArray | null;
        while ((match = LINK_RE.exec(line)) !== null) {
          matches.push([match.index, match[0].length + match.index]);
        }

        // Returns true if the cursor position (ch) is inside a match.
        return matches.some((m) => position.ch >= m[0] && position.ch <= m[1]);
      case MarkdownInlineType.CODE:
        // TODO: verify this one.
        return tokenTypes.includes(CodeMirrorTokenType.CODE);
      default:
        return false;
    }
  },

  toggleInlineStyle: (editor: Editor, inlineType: MarkdownInlineType): void => {
    const { start, end } = EditorUtils.getCursorPoints(editor);

    const onSameLine = start.line === end.line;

    // Bail out.
    if (!onSameLine) return;

    const selectedText = editor.getSelection();
    const lineText = editor.getLine(start.line);

    if (EditorUtils.isInlineStyleActive(editor, inlineType)) {
      let startPos = { ...start };
      let endPos = { ...end };

      // Simplest implementation: if we're in a style block, restyle the entire thing.
      while (
        EditorUtils.positionHasStyle(editor, startPos, inlineType) &&
        startPos.ch > 0
      ) {
        startPos = { ...startPos, ch: startPos.ch - 1 };
      }

      while (
        EditorUtils.positionHasStyle(editor, endPos, inlineType) &&
        endPos.ch < lineText.length
      ) {
        endPos = { ...endPos, ch: endPos.ch + 1 };
      }

      const styledText = lineText.slice(startPos.ch, endPos.ch);

      // Remove the markdown style markers from beginning and end of the selection.
      const newText = EditorUtils.stripInlineFormattingFromEnds(
        styledText,
        inlineType
      );

      editor.replaceRange(newText, startPos, endPos);
      editor.setCursor({ ...startPos, ch: startPos.ch + newText.length });
    } else {
      // Remove any inner text that has the new style.
      // This is so that when toggling a selection to bold, we remove the inner
      // bold texts since that formatting is no longer needed.
      const strippedText = EditorUtils.stripInlineFormattingInSubString(
        selectedText,
        inlineType
      );

      // We just wrap the selection in the correct tags.
      const newText = EditorUtils.wrapTextInStyle(strippedText, inlineType);

      editor.replaceRange(newText, start, end);

      // If we didn't have anything selected, drop the cursor in the middle.
      if (selectedText.length === 0) {
        editor.setCursor({ ...start, ch: start.ch + newText.length / 2 });
      } else {
        editor.setCursor({ ...start, ch: start.ch + newText.length });
      }
    }
  },

  /**
   * Removes only the styling markers at the start and end of a string for the given type.
   *
   */
  stripInlineFormattingFromEnds: (
    value: string,
    inlineType: MarkdownInlineType
  ): string => {
    switch (inlineType) {
      case MarkdownInlineType.BOLD:
        return value.replace(BOLD_START_RE, "$1").replace(BOLD_END_RE, "$2");

      case MarkdownInlineType.ITALIC:
        return value
          .replace(ITALIC_START_RE, "$1")
          .replace(ITALIC_END_RE, "$2");

      case MarkdownInlineType.STRIKETHROUGH:
        return value
          .replace(STRIKETHROUGH_START_RE, "$1")
          .replace(STRIKETHROUGH_END_RE, "$2");

      case MarkdownInlineType.LINK:
        return value.replace(LINK_RE, "$1$3");

      case MarkdownInlineType.CODE:
        return value
          .replace(INLINE_CODE_START_RE, "$1")
          .replace(INLINE_CODE_END_RE, "$2");

      default:
        return value;
    }
  },

  /**
   * Removes any inner (substring) styling markers that matche the given type.
   */
  stripInlineFormattingInSubString: (
    value: string,
    inlineType: MarkdownInlineType
  ): string => {
    switch (inlineType) {
      case MarkdownInlineType.BOLD:
        return value.replace(BOLD_RE, "$2");

      case MarkdownInlineType.ITALIC:
        return value.replace(ITALIC_RE, "$1$3$5");

      case MarkdownInlineType.STRIKETHROUGH:
        return value.replace(STRIKETHROUGH_RE, "$2");

      case MarkdownInlineType.LINK:
        return value.replace(LINK_RE, "$1$3");

      case MarkdownInlineType.CODE:
        return value.replace(INLINE_CODE_RE, "$2");

      default:
        return value;
    }
  },

  toggleBlock: (editor: Editor, blockType: MarkdownBlockType): void => {
    const { start, end } = EditorUtils.getCursorPoints(editor);

    let lineNumbers: number[] = [];

    if (start.line !== end.line) {
      lineNumbers = [...range(start.line, end.line + 1)];
    } else {
      lineNumbers = [start.line];
    }

    const lines: string[] = lineNumbers.map((n) => editor.getLine(n));
    const [lastLine] = lines.slice(-1);
    const newStartPos = { ...start, ch: 0 };
    const newEndPos = { ...end, ch: lastLine.length };

    if (EditorUtils.isBlockActive(editor, blockType)) {
      const newContent = lines
        .map((l) => EditorUtils.clearBlocksFromLine(l))
        .join("\n");

      editor.replaceRange(newContent, newStartPos, newEndPos);
    } else {
      const newContent = lines
        .map((l) => EditorUtils.clearBlocksFromLine(l))
        .map((l, idx) => EditorUtils.createBlockForLine(l, idx, blockType))
        .filter((l) => l.length > 0)
        .join("\n");

      editor.replaceRange(newContent, newStartPos, newEndPos);
    }
  },

  /**
   * Clear ANY markdown block found at the start of the given line string.
   *
   * This is designed to prevent edge cases where the user has selected across a
   * range that includes multiple block types. We just remove them so that we can replace
   * with their intended new type.
   *
   * For example, given a doc with three blocks
   *    h1
   *    p
   *    ol
   * when a user selects all and clicks `ul`, then we will update the doc to be
   *    ul
   *    ul
   *    ul
   *
   */
  clearBlocksFromLine: (line: string): string => {
    const regExs = [
      UNORDERED_LIST_RE,
      ORDERED_LIST_RE,
      BLOCKQUOTE_RE,
      HEADER_ONE_RE,
      HEADER_TWO_RE,
      HEADER_THREE_RE,
      HEADER_FOUR_RE,
      HEADER_FIVE_RE,
      HEADER_SIX_RE
    ];

    for (const re of regExs) {
      if (re.test(line)) {
        const newLine = line.replace(re, "");

        // Break so we don't make unintended changes.
        // e.g. `# 1. My first point` should become `1. My first point`,
        // not `My first point`.
        return newLine;
      }
    }

    // Special case for images.
    if (IMAGE_RE.test(line)) {
      return line.replace(IMAGE_RE, "$1");
    }

    return line;
  },

  createBlockForLine: (
    line: string,
    idx: number,
    blockType: MarkdownBlockType
  ): string => {
    switch (blockType) {
      case MarkdownBlockType.HEADER_ONE:
        return "# " + line;
      case MarkdownBlockType.HEADER_TWO:
        return "## " + line;
      case MarkdownBlockType.HEADER_THREE:
        return "### " + line;
      case MarkdownBlockType.HEADER_FOUR:
        return "#### " + line;
      case MarkdownBlockType.HEADER_FIVE:
        return "##### " + line;
      case MarkdownBlockType.HEADER_SIX:
        return "###### " + line;
      case MarkdownBlockType.ORDERED_LIST:
        return `${idx + 1}. ` + line;
      case MarkdownBlockType.UNORDERED_LIST:
        return "* " + line;
      case MarkdownBlockType.BLOCKQUOTE:
        return "> " + line;
      case MarkdownBlockType.IMAGE:
        const trimmedText = line.trim();
        const placeholder =
          trimmedText.length > 0 ? trimmedText : "Displayed description";
        return `![${placeholder}](http://)`;
      default:
        return line;
    }
  },

  wrapTextInStyle: (text: string, inlineType: MarkdownInlineType): string => {
    switch (inlineType) {
      case MarkdownInlineType.BOLD:
        return `**${text.trim()}**`;
      case MarkdownInlineType.ITALIC:
        return `*${text.trim()}*`;
      case MarkdownInlineType.STRIKETHROUGH:
        return `~~${text.trim()}~~`;
      case MarkdownInlineType.LINK:
        const trimmedText = text.trim();
        const placeholder =
          trimmedText.length > 0 ? trimmedText : "Displayed text";
        return `[${placeholder}](http://)`;
      case MarkdownInlineType.CODE:
        return `\`${text.trim()}\``;
      default:
        return text;
    }
  }
};

export { EditorUtils };
