import { useAppDispatch } from "@aptedge/lib-ui/src/redux/hook/hook";
import { updateMyProducts } from "@aptedge/lib-ui/src/redux/reduxSlice/searchSlice";
import {
  AptAnswersFiltersResponse,
  AptAnswersFilterType,
  AptAnswersSearchContext,
  AptAnswersSearchContextItem
} from "@aptedge/lib-ui/src/types/entities";
import { useQuery } from "react-query";
import { fetchFilters } from "../../clients/AptAnswers/fetchFilters";
import { WebCacheKey } from "../../clients/cache";
import { fetchMyProducts } from "../../clients/MyProducts/fetchMyProducts";

function getEntriesFromFilterObject(
  data: AptAnswersFiltersResponse | undefined,
  filterType: AptAnswersFilterType
): AptAnswersSearchContextItem[] {
  const filterData = data?.[filterType] ?? {};
  const filterItems = Object.entries(filterData).map((item) => {
    const name = item[0];
    const id = item[1][0]["id"];
    return {
      id,
      name
    };
  });
  return filterItems;
}

function useAptAnswersSearchContext(): AptAnswersSearchContext {
  const dispatch = useAppDispatch();

  const quickFiltersQuery = useQuery(
    [WebCacheKey.APT_ANSWERS_FILTERS, AptAnswersFilterType.QUICK_FILTER],
    () => fetchFilters({ filterType: AptAnswersFilterType.QUICK_FILTER }),
    { refetchOnWindowFocus: false }
  );

  const quickFiltersIsError = quickFiltersQuery.isError;
  const quickFiltersIsSuccess = quickFiltersQuery.isSuccess;
  const quickFiltersItems = getEntriesFromFilterObject(
    quickFiltersQuery.data,
    AptAnswersFilterType.QUICK_FILTER
  );
  const quickFiltersIsEmpty = quickFiltersItems.length === 0;

  const productFiltersQuery = useQuery(
    [WebCacheKey.APT_ANSWERS_FILTERS, AptAnswersFilterType.PRODUCT_FILTER],
    () => fetchFilters({ filterType: AptAnswersFilterType.PRODUCT_FILTER }),
    { refetchOnWindowFocus: false }
  );

  const productFiltersIsError = productFiltersQuery.isError;
  const productFiltersIsSuccess = productFiltersQuery.isSuccess;
  const productFiltersItems = getEntriesFromFilterObject(
    productFiltersQuery.data,
    AptAnswersFilterType.PRODUCT_FILTER
  );
  const productFiltersIsEmpty = productFiltersItems.length === 0;

  const myProductsQuery = useQuery(
    [WebCacheKey.APT_ANSWERS_MY_PRODUCTS],
    () => fetchMyProducts(),
    {
      enabled: productFiltersQuery.isSuccess,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const activeProductFilters = productFiltersItems.map(
          (productFilter) => {
            return { id: productFilter.id, name: productFilter.name };
          }
        );
        const myProductIds = data?.filterIds?.productFilters ?? [];
        const myProducts = myProductIds
          .map((id) => {
            const productFilter = activeProductFilters.find(
              (pf) => id === pf.id
            );
            return productFilter
              ? { id, name: productFilter?.name }
              : { id, name: null };
          })
          .filter((mp) => mp.name);
        dispatch(updateMyProducts(myProducts as AptAnswersSearchContextItem[]));
      }
    }
  );

  const isLoading =
    quickFiltersQuery.isLoading ||
    productFiltersQuery.isLoading ||
    myProductsQuery.isLoading;

  return {
    isLoading,
    quickFiltersIsError,
    quickFiltersIsSuccess,
    quickFiltersIsEmpty,
    quickFiltersItems,
    productFiltersIsError,
    productFiltersIsSuccess,
    productFiltersIsEmpty,
    productFiltersItems
  };
}

export { useAptAnswersSearchContext };
