import { IOneloginUrl } from "@aptedge/lib-ui/src/types/entities";
import { getBackendUrl } from "../../config";
import { request } from "../../utils/request";

function fetchOneloginUrl(): Promise<IOneloginUrl> {
  const url = `${getBackendUrl()}/onelogin/login`;
  return request(url);
}

export { fetchOneloginUrl };
