import { List } from "@aptedge/lib-ui/src/components/List/List";
import {
  NoData,
  NoDataMessage
} from "@aptedge/lib-ui/src/components/NoData/NoData";
import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import { ITicketListing } from "@aptedge/lib-ui/src/types/entities";
import React from "react";
import WithLoading from "../WithLoading/WithLoading";
import TicketListItemV2 from "./TicketListItemV2";
import "./TicketList.scss";
interface Props {
  tickets: ITicketListing[];
  isLoading?: boolean;
  onSelect: (ticket: ITicketListing) => void;
  onOptionSelect: (ticket: ITicketListing) => void;
}

const TICKET_LIST_HEADER = {
  requestedBy: "CUSTOMER",
  id: "ID",
  subject: "SUBJECT",
  priority: "PRIORITY",
  frustrationScore: "SENTIMENT",
  themes: "THEMES",
  createdOn: "CREATED ON"
};
function TicketsList(props: Props): React.ReactElement {
  const { tickets, isLoading, onSelect, onOptionSelect } = props;
  const { flags } = useFlags();

  return (
    <WithLoading isLoading={!!isLoading} fallback={<Spinner className="p-3" />}>
      <List>
        <div className="ticket-list">
          <div>{TICKET_LIST_HEADER.requestedBy}</div>
          <div>{TICKET_LIST_HEADER.id}</div>
          <div>{TICKET_LIST_HEADER.subject}</div>
          <div>{TICKET_LIST_HEADER.priority}</div>
          {flags.sentimentScore && (
            <div>{TICKET_LIST_HEADER.frustrationScore}</div>
          )}
          <div>{TICKET_LIST_HEADER.themes}</div>
          <div>{TICKET_LIST_HEADER.createdOn}</div>
        </div>
        {tickets.length
          ? tickets.map((ticket) => (
              <TicketListItemV2
                key={ticket.id}
                ticket={ticket}
                onSelect={() => onSelect(ticket)}
                onOptionSelect={() => onOptionSelect(ticket)}
              />
            ))
          : !isLoading && <NoData message={NoDataMessage.SEARCH_TICKETS} />}
      </List>
    </WithLoading>
  );
}

export { TicketsList };
