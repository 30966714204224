import { RefObject, useEffect } from "react";

type Options = {
  root: RefObject<Element> | null;
  target: RefObject<Element>;
  onIntersect: () => void;
  threshold?: number;
  rootMargin?: string;
  enabled?: boolean;
};

function useIntersectionObserver(opts: Options): void {
  const {
    root,
    target,
    onIntersect,
    threshold = 1.0,
    rootMargin = "0px",
    enabled = true
  } = opts;

  useEffect(() => {
    if (!enabled) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) =>
        entries.forEach((entry) => entry.isIntersecting && onIntersect()),
      {
        // Defaults to the browser viewport if not specified or if null
        root: (root && root.current) || null,
        rootMargin,
        threshold
      }
    );

    const el = target && target.current;

    if (!el) {
      return;
    }

    observer.observe(el);

    return () => {
      observer.unobserve(el);
    };
  }, [root, target, onIntersect, threshold, rootMargin, enabled]);
}

export { useIntersectionObserver };
