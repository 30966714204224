import { FilterBar } from "@aptedge/lib-ui/src/components/Filter/FilterBar";
import { SavedQueryType } from "@aptedge/lib-ui/src/types/entities";
import classNames from "classnames";
import { DateTime } from "luxon";
import React from "react";
import { CustomerFilter } from "../../components/Filters/CustomerFilter";
import { CustomQueryButton } from "../../components/Filters/CustomQueryButton";
import {
  ASSIGNEE_FILTER,
  CREATED_ON_FILTER,
  CUSTOMER_FILTER,
  EDGE_FILTER,
  FEATURE_FILTER,
  JIRA_FILTER,
  PRIORITY_FILTER,
  PRODUCT_FILTER,
  STATUS_FILTER,
  SUBJECT_FILTER,
  DESCRIPTION_FILTER,
  TEXT_FILTER,
  THEME_FILTER,
  SPACE_FILTER,
  TICKET_TYPE_FILTER,
  TICKET_REASON_FILTER
} from "../../components/Filters/data";
import { DateRangeFilter } from "../../components/Filters/DateRangeFilter";
import { EdgeFilter } from "../../components/Filters/EdgeFilter";
import { FeatureFilter } from "../../components/Filters/FeatureFilter";
import { Filters } from "../../components/Filters/Filters";
import { FilterSelect } from "../../components/Filters/FilterSelect";
import { JiraFilter } from "../../components/Filters/JiraFilter";
import { PriorityFilter } from "../../components/Filters/PriorityFilter";
import { ProductFilter } from "../../components/Filters/ProductFIlter";
import { SpaceFilter } from "../../components/Filters/SpaceFilter";
import {
  TextFilter,
  SubjectTextFilter,
  DescriptionTextFilter
} from "../../components/Filters/TextFilter";
import { ThemeFilter } from "../../components/Filters/ThemeFilter";
import { TicketReasonFilter } from "../../components/Filters/TicketReasonFilter";
import { TicketStatusFilter } from "../../components/Filters/TicketStatusFilter";
import { TicketTypeFilter } from "../../components/Filters/TicketTypeFilter";
import { TicketAssigneeFilter } from "../../components/Filters/TicketUserFilter";
import { TimeDimensions } from "../../components/Filters/TimeDimensions";
import { UpdateSavedFilter } from "../../components/Filters/UpdateSavedFilter";
import {
  SavedQueriesProvider,
  SavedQueriesContext
} from "../../context/SavedQueriesContext";
import { useIsSpaceEnabled } from "../../hooks/useIsSpaceEnabled";
import { SavedQueryWrapper } from "../Explorer/SavedQueryWrapper";
import {
  DEFAULT_CREATED,
  TicketListingQueryParams
} from "./useTicketListingQuery";

const FILTERS = [
  TEXT_FILTER,
  SUBJECT_FILTER,
  DESCRIPTION_FILTER,
  STATUS_FILTER,
  TICKET_TYPE_FILTER,
  TICKET_REASON_FILTER,
  PRIORITY_FILTER,
  ASSIGNEE_FILTER,
  CUSTOMER_FILTER,
  PRODUCT_FILTER,
  FEATURE_FILTER,
  THEME_FILTER,
  JIRA_FILTER,
  EDGE_FILTER
];

const baseFiltersMap = {
  text: TextFilter,
  subject: SubjectTextFilter,
  description: DescriptionTextFilter,
  products: ProductFilter,
  features: FeatureFilter,
  themes: ThemeFilter,
  customers: CustomerFilter,
  jiras: JiraFilter,
  priority: PriorityFilter,
  status: TicketStatusFilter,
  ticketType: TicketTypeFilter,
  reason: TicketReasonFilter,
  assignee: TicketAssigneeFilter,
  edges: EdgeFilter
};

const dimensionsMap = {
  createdOn: DateRangeFilter
};

interface Props {
  params: TicketListingQueryParams;
  className?: string;
  onChange: (partial: Partial<TicketListingQueryParams>) => void;
}

function TicketListingFilterBar(props: Props): React.ReactElement {
  const { params, onChange } = props;

  const dateRangeMin = DateTime.local().endOf("day").minus({ months: 3 });

  const handleFilterChange = (
    value?: Partial<TicketListingQueryParams>
  ): void => {
    onChange({ ...value, page: 1 });
  };

  const isSpaceEnabled = useIsSpaceEnabled();
  const filters = isSpaceEnabled ? [...FILTERS, SPACE_FILTER] : FILTERS;

  const filtersMap = isSpaceEnabled
    ? Object.assign(baseFiltersMap, { spaces: SpaceFilter })
    : baseFiltersMap;

  return (
    <SavedQueriesProvider
      type={[SavedQueryType.CREATED_TICKETS]}
      onChange={onChange}
    >
      <SavedQueriesContext.Consumer>
        {({ editing }) => (
          <FilterBar className={classNames({ editing })}>
            <UpdateSavedFilter type={SavedQueryType.CREATED_TICKETS} />
            <div className="filter-row">
              <SavedQueryWrapper
                onClose={() =>
                  handleFilterChange({
                    filters: [],
                    timeDimensions: [
                      { id: "createdOn", values: [DEFAULT_CREATED] }
                    ]
                  })
                }
              >
                <TimeDimensions
                  specs={[CREATED_ON_FILTER]}
                  dimensions={params.timeDimensions}
                  pinned={[CREATED_ON_FILTER.id]}
                  dateRangeMin={dateRangeMin}
                  dimensionsMap={dimensionsMap}
                  onChange={(timeDimension) => {
                    if (!timeDimension.id) {
                      handleFilterChange({
                        timeDimensions: [
                          { id: "createdOn", values: [DEFAULT_CREATED] }
                        ]
                      });
                    } else {
                      handleFilterChange({ timeDimensions: [timeDimension] });
                    }
                  }}
                />
                <Filters
                  spec={filters}
                  filters={params.filters}
                  filtersMap={filtersMap}
                  onChange={(filters) => handleFilterChange({ filters })}
                />
              </SavedQueryWrapper>
              <FilterSelect
                specs={filters}
                query={params}
                onChange={handleFilterChange}
              />
              <CustomQueryButton />
            </div>
          </FilterBar>
        )}
      </SavedQueriesContext.Consumer>
    </SavedQueriesProvider>
  );
}

export { TicketListingFilterBar };
