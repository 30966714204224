import { ISharepointDriveSource } from "@aptedge/lib-ui/src/types/entities";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

type SliceStateType = {
  dataSource: ISharepointDriveSource | null;
};

const initialState: SliceStateType = {
  dataSource: null
};

const sharepointDriveSlice = createSlice({
  name: "sharepoint-drive",
  initialState,
  reducers: {
    setSharepointDriveTapData: (
      state,
      action: PayloadAction<ISharepointDriveSource | null>
    ) => {
      state.dataSource = action.payload;
    }
  }
});

export const { setSharepointDriveTapData } = sharepointDriveSlice.actions;

export default sharepointDriveSlice.reducer;
