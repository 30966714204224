import {
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState
} from "react";

interface IUseModal {
  modalRef: RefObject<HTMLDivElement> | null;
  modalVisibility: boolean;
  closeModal: Dispatch<SetStateAction<boolean>>;
}

interface UseModalProps {
  closeModalOnClickOutside?: boolean;
}

const useModal = ({
  closeModalOnClickOutside = true
}: UseModalProps): IUseModal => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [modalVisibility, closeModal] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node) &&
        closeModalOnClickOutside
      ) {
        closeModal(false);
      }
    };

    if (modalVisibility) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalVisibility, closeModalOnClickOutside]);

  return { modalRef, modalVisibility, closeModal };
};

export default useModal;
