import { ModalWidth } from "@aptedge/lib-ui/src/components/Modal/Modal";
import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import { IOneloginSource } from "@aptedge/lib-ui/src/types/entities";
import { useState } from "react";
import { useMutation } from "react-query";
import { ToastType } from "react-toastify";
import { createOneloginSource } from "../../../clients/Authentication/createOneloginSource";
import { ConfirmationModal } from "../../../components/ConfirmationModal/ConfirmationModal";
import { Toast } from "../../../components/Toast/Toast";
import WithLoading from "../../../components/WithLoading/WithLoading";
import { UseDataSourceResult } from "../../../hooks/clients/useDataSource";
import { ClientStatus } from "../../../types/clients";
import { AddButton } from "../common/AddButton";
import { StatusButton } from "../common/StatusButton";
import { OneloginConfigForm, OneloginFormData } from "./OneloginConfigForm";

interface Props {
  dataSource: UseDataSourceResult<IOneloginSource> | null;
}

const OneloginConfig: React.FunctionComponent<Props> = (props) => {
  const { dataSource } = props;
  const oneloginSource = dataSource?.dataSource ?? null;
  const hasOneloginSource = Boolean(dataSource?.dataSource?.url);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [status, setStatus] = useState(ClientStatus.PROMPT);

  const addSource = useMutation(createOneloginSource, {
    onError: () => {
      setStatus(ClientStatus.ERROR);
    },
    onSuccess: () => {
      setShowModal(false);
      setStatus(ClientStatus.SUCCESS);
      dataSource?.reload();
    }
  });

  const onSubmit = (formData: OneloginFormData): void => {
    setStatus(ClientStatus.LOADING);
    addSource.mutate({
      ...formData,
      certificate: formData.certificate
        .replace("-----BEGIN CERTIFICATE-----", "")
        .replace("-----END CERTIFICATE-----", "")
        .trim()
    });
  };

  return (
    <div>
      <div className="mb-3">
        <h5>OneLogin</h5>
        <p>Configure your OneLogin settings below.</p>
      </div>
      <div>
        <ConfirmationModal
          title="OneLogin Settings"
          width={ModalWidth.LARGE}
          loadingTitle="Loading..."
          status={status}
          isOpen={showModal}
          onClose={() => setShowModal(false)}
        >
          <p className="mb-4">Configure your settings below.</p>
          <OneloginConfigForm dataSource={oneloginSource} onSubmit={onSubmit} />
        </ConfirmationModal>
      </div>
      <WithLoading
        isLoading={status === ClientStatus.LOADING}
        fallback={
          <div className="d-flex justify-content-center m-4">
            <Spinner />
          </div>
        }
      >
        {hasOneloginSource ? (
          <StatusButton status="ok" onClickEdit={() => setShowModal(true)}>
            <>
              <div>OneLogin</div>
              <p>OneLogin URL: {oneloginSource?.url}</p>
            </>
          </StatusButton>
        ) : (
          <AddButton onClick={() => setShowModal(true)}>
            Connect to OneLogin
          </AddButton>
        )}
      </WithLoading>
      {addSource.isError && (
        <Toast type={ToastType.ERROR}>Something went wrong.</Toast>
      )}
    </div>
  );
};

export { OneloginConfig };
