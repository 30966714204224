import { IUserListing } from "@aptedge/lib-ui/src/types/entities";
import React from "react";

const VERBS = {
  UPDATED: "updated the",
  MERGED_TO: "merged this into an",
  MERGED_FROM: "merged Edges into the",
  CREATED: "created the",
  ADDED: "added a",
  REMOVED: "removed a",
  DELETED: "deleted a",
  EDITED: "edited a"
};

const OBJECTS = {
  EDGE: "Edge",
  ARCHIVE_STATUS: "Archive status",
  ASSIGNEE: "Assignee",
  EXAMPLES: "Examples",
  FILE: "File",
  COMMENT: "Comment",
  LABELS: "Labels",
  INTERNAL_ISSUE: "Jira",
  NAME: "Name",
  STATUS: "Status",
  SUMMARY: "Summary",
  TEAM: "Team",
  SPACE: "Space",
  WEBLINK: "Weblink"
};

interface Internal {
  subject: IUserListing | null;
  verb: string;
  object: string;
  displayText: string;
}

function extractEmail(text: string): string | null {
  if (!text) return null;
  const emails = text.match(
    /([a-zA-Z0-9+._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi
  );
  if (emails?.length) return emails[0];
  return null;
}

function SummarySentence(props: Internal): React.ReactElement {
  const { subject, verb, object, displayText } = props;
  const sub = subject ? subject.email : `AptEdge(${extractEmail(displayText)})`;
  return (
    <div className="audit-summary" data-testid="audit-log-summary">
      <span className="subject">{sub} </span>
      <span>{verb} </span>
      <span className="font-weight-bold">{object}</span>
    </div>
  );
}

SummarySentence.VERBS = VERBS;
SummarySentence.OBJECTS = OBJECTS;

export { SummarySentence };
