import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { ModalWidth } from "@aptedge/lib-ui/src/components/Modal/Modal";
import { TextInput } from "@aptedge/lib-ui/src/components/TextInput/TextInput";
import { GTM_EVENTS, dataLayerPush } from "@aptedge/lib-ui/src/utils/event";
import { useFormik } from "formik";
import React, { createRef } from "react";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { ModalBody, ModalFooter } from "reactstrap";
import * as Yup from "yup";
import { createEdge } from "../../clients/Edges/createEdge";
import { convertQueryStateToClientStatus } from "../../clients/utils/convertQueryStateToClientStatus";
import { toEdgePath } from "../../routes/toPath";
import { createErrorMessage } from "../../utils/createErrorMessage";
import { ConfirmationModal } from "../ConfirmationModal/ConfirmationModal";
import "./CreateEdgeModal.scss";

const CreateEdgeModal = ({ isOpen, onClose }: Props): React.ReactElement => {
  const history = useHistory();

  const inputRef = createRef<HTMLInputElement>();

  const formik = useFormik<FormState>({
    initialValues: { title: "" },
    validationSchema: Yup.object({
      title: Yup.string()
        .required("A title is required.")
        .max(120, "An Edge title can be no longer than 120 characters.")
    }),
    onSubmit: (data: FormState): void => {
      createEdgeMutation.mutate(data.title);
    }
  });

  const triggerEvent = (): void => {
    dataLayerPush({
      event: GTM_EVENTS.GTM_CREATE_EDGE,
      data: {
        created_edge_name: formik.values.title
      }
    });
  };

  const createEdgeMutation = useMutation(createEdge, {
    onSuccess: (res) => {
      onClose();

      triggerEvent();

      // Reset local state
      formik.resetForm();

      history.push(toEdgePath(res.id));
    },
    onError: (err) => formik.setFieldError("title", createErrorMessage(err))
  });

  const handleClose = (): void => {
    formik.resetForm();
    onClose();
  };

  return (
    <ConfirmationModal
      title="New Edge"
      loadingTitle="Creating Edge..."
      className="create-edge"
      width={ModalWidth.LARGE}
      status={convertQueryStateToClientStatus(createEdgeMutation)}
      isOpen={isOpen}
      onClose={handleClose}
    >
      <form onSubmit={formik.handleSubmit} data-testid="create-edge-form">
        <ModalBody>
          <TextInput
            label="Title"
            ref={inputRef}
            name="title"
            autoFocus
            error={formik.errors.title}
            value={formik.values.title}
            placeholder="Enter a title"
            onChange={formik.handleChange}
            onClear={() => formik.setFieldValue("title", "")}
          />
        </ModalBody>
        <ModalFooter>
          <Button type="submit" color="primary">
            Create
          </Button>
        </ModalFooter>
      </form>
    </ConfirmationModal>
  );
};

interface FormState {
  title: string;
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export { CreateEdgeModal };
