import { INotification } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { IPaginatedResponse } from "../../types/clients";
import { request } from "../../utils/request";
import { toQueryString } from "../utils/url";

async function fetchUnreadNotificationCount(): Promise<{
  totalUnread: number;
}> {
  const query = toQueryString({ newOnly: true });
  const url = `${getBackendApiUrl()}/users/notifications/?${query}`;

  return request<IPaginatedResponse<INotification[]>>(url).then((data) => ({
    totalUnread: data.total
  }));
}

export { fetchUnreadNotificationCount };
