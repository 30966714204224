import { AeIcons } from "@aptedge/lib-ui/src/components/Icon/AeIcons";
import {
  DOCUMENT_SOURCE_TYPE,
  ICompositeResult,
  ICompositeResultSupport,
  ISSUES_SOURCE_TYPE,
  SOCIAL_SOURCE_TYPE,
  ARTICLE_SOURCE_TYPE,
  SEARCH_RESULT_TYPE,
  ResultType
} from "@aptedge/lib-ui/src/types/entities";
import { FC, HTMLAttributes, createElement } from "react";

const dateFormatter = new Intl.DateTimeFormat("en-US", {
  day: "numeric",
  month: "short",
  year: "numeric"
});

const useSearchResult = (
  searchResult: ICompositeResult | ICompositeResultSupport
): ResultType => {
  const result = getDetailsByResultType(searchResult);
  return result;
};

const getArticleIcon = (
  sourceType: string
): FC<HTMLAttributes<HTMLSpanElement>> => {
  switch (sourceType) {
    case ARTICLE_SOURCE_TYPE.ZENDESK:
      return AeIcons.Zendesk;
    case ARTICLE_SOURCE_TYPE.CONFLUENCE:
      return AeIcons.Confluence;
    case ARTICLE_SOURCE_TYPE.SALESFORCE:
      return AeIcons.Salesforce;
    case ARTICLE_SOURCE_TYPE.SERVICENOW_KNOWLEDGE:
      return AeIcons.SNow;
    case ARTICLE_SOURCE_TYPE.NOTION:
      return AeIcons.Notion;
    case ARTICLE_SOURCE_TYPE.SHAREPOINT_SITES:
      return AeIcons.Sharepoint;
    case ARTICLE_SOURCE_TYPE.DOC360:
      return AeIcons.Doc360;
    case ARTICLE_SOURCE_TYPE.MADCAP_FLARE:
      return AeIcons.MadCap;
    case ARTICLE_SOURCE_TYPE.CX_ONE_EXPERT:
      return AeIcons.CxOne;
    case ARTICLE_SOURCE_TYPE.DOCUSAURUS:
      return AeIcons.Docusaurus;
    default:
      return AeIcons.Globe;
  }
};

const getDocumentIcon = (
  sourceType: string
): FC<HTMLAttributes<HTMLSpanElement>> => {
  switch (sourceType) {
    case DOCUMENT_SOURCE_TYPE.EDGE:
      return AeIcons.EdgeSR;
    case DOCUMENT_SOURCE_TYPE.SHAREPOINT_DRIVE:
      return AeIcons.Sharepoint;
    default:
      return AeIcons.PdfIcon;
  }
};

const getIssueIcon = (
  sourceType: string
): FC<HTMLAttributes<HTMLSpanElement>> => {
  switch (sourceType) {
    case ISSUES_SOURCE_TYPE.JIRA:
      return AeIcons.Issues;
    case ISSUES_SOURCE_TYPE.AZURE_DEVOPS:
      return AeIcons.AzureDevops;

    default:
      return AeIcons.IssuesIcon;
  }
};
const getSocialIcon = (
  sourceType: string
): FC<HTMLAttributes<HTMLSpanElement>> => {
  switch (sourceType) {
    case SOCIAL_SOURCE_TYPE.SLACK:
      return AeIcons.Slack;
    case SOCIAL_SOURCE_TYPE.MS_TEAMS:
      return AeIcons.MSTeamsIcon;

    default:
      return AeIcons.Slack;
  }
};

export const getDetailsByResultType = (
  searchResult: ICompositeResult | ICompositeResultSupport
): ResultType => {
  const formattedDate = `Updated ${dateFormatter.format(
    searchResult.updatedOn && searchResult.updatedOn * 1000
  )}`;

  switch (searchResult.type) {
    case SEARCH_RESULT_TYPE.EDGE:
      return {
        id: `EDGE-${searchResult.id}`,
        originalId: searchResult.id,
        icon: createElement(AeIcons.Edge),
        date: formattedDate,
        title: searchResult.name,
        description: searchResult.richSummary?.content,
        updatedOn: searchResult.updatedOn,
        labels: searchResult.labels,
        type: searchResult.type,
        url: `${window.location.origin}/edge/${searchResult.id}`
      };
    case SEARCH_RESULT_TYPE.GENERATED_KNOWLEDGE:
      return {
        id: String(searchResult.id),
        originalId: searchResult.id,
        icon: createElement(AeIcons.GeneratedKnowledgeBlue),
        date: formattedDate,
        title: searchResult.title,
        description: searchResult.body.body,
        updatedOn: searchResult.updatedOn,
        type: searchResult.type,
        url: `${window.location.origin}/articles?articleId=${searchResult.id}`
      };
    case SEARCH_RESULT_TYPE.ISSUE:
      return {
        id: `ISSUE-${searchResult.id}`,
        originalId: searchResult.id,
        icon: createElement(getIssueIcon(searchResult.sourceType)),
        sourceType: searchResult.sourceType,
        date: formattedDate,
        title: searchResult.title,
        description: searchResult.description,
        updatedOn: searchResult?.updatedOn,
        type: searchResult.type,
        url: searchResult.displayUrl
      };
    case SEARCH_RESULT_TYPE.ARTICLE:
      return {
        id: `ARTICLE-${searchResult.id}`,
        originalId: searchResult.id,
        icon: createElement(getArticleIcon(searchResult.sourceType)),
        sourceType: searchResult.sourceType,
        date: formattedDate,
        title: searchResult.title,
        description: searchResult.body,
        updatedOn: searchResult.updatedOn,
        type: searchResult.type,
        url: searchResult.displayUrl
      };
    case SEARCH_RESULT_TYPE.DOCUMENT:
      return {
        id: `DOCUMENT-${searchResult.id}`,
        originalId: searchResult.id,
        icon: createElement(getDocumentIcon(searchResult.sourceType)),
        sourceType: searchResult.sourceType,
        sourceId: searchResult.sourceId,
        date: formattedDate,
        title: searchResult.title,
        description: searchResult.body,
        updatedOn: searchResult.updatedOn,
        type: searchResult.type,
        url: searchResult.displayUrl
      };
    case SEARCH_RESULT_TYPE.SOCIAL:
      const socialResultTitle = !searchResult.title
        ? searchResult.channel
          ? `Thread in #${searchResult.channel} at ${formattedDate}`
          : `Thread at ${formattedDate}`
        : searchResult.title;
      return {
        id: `SOCIAL-${searchResult.id}`,
        originalId: searchResult.id,
        sourceType: searchResult.sourceType,
        icon: createElement(getSocialIcon(searchResult.sourceType)),
        date: formattedDate,
        title: socialResultTitle,
        description: searchResult.body,
        updatedOn: searchResult.updatedOn,
        type: searchResult.type,
        url: searchResult.displayUrl
      };
    case SEARCH_RESULT_TYPE.TICKET:
      return {
        id: `TICKET-${searchResult.id}`,
        originalId: searchResult.id,
        icon: createElement(AeIcons.Tickets),
        date: formattedDate,
        title: searchResult.subject,
        description: searchResult.description,
        type: searchResult.type,
        updatedOn: searchResult.updatedOn,
        url: `${window.location.origin}/ticket/${searchResult.id}`,
        summary: searchResult.summary,
        externalId: searchResult.externalId
      };
    case SEARCH_RESULT_TYPE.FEDERATED:
      return {
        id: `FEDERATED-${searchResult.id}`,
        originalId: searchResult.id,
        icon: (
          <img className="img-icon" alt="" src={searchResult.thumbnailUrl} />
        ),
        sourceType: searchResult.sourceType,
        date: formattedDate,
        title: searchResult.title,
        description: searchResult.body,
        updatedOn: searchResult.updatedOn,
        type: searchResult.type,
        url: searchResult.displayUrl
      };
    default:
      return {
        id: `UNKNOWN-${searchResult.id}`,
        originalId: 0,
        icon: createElement(AeIcons.Tickets),
        date: formattedDate,
        title: "",
        updatedOn: 0,
        type: "",
        url: ""
      };
  }
};

export default useSearchResult;
