import { toQueryString } from "../../../../app-web/src/clients/utils/url";
import { request } from "../../../../app-web/src/utils/request";
import { ISearchFilter } from "../../types/entities";
import { getBackendApiUrl } from "../../utils/config";

async function searchSuggestions(
  args: {
    q: string;
  },
  filter: ISearchFilter
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<{ suggestions: string[]; answers: any }> {
  const query = toQueryString(args);
  let url = "";
  if (filter !== ISearchFilter.ALL) {
    url = `${getBackendApiUrl()}/suggest/search/${filter}/?${query}`;
  } else {
    url = `${getBackendApiUrl()}/suggest/search/?${query}`;
  }

  return request(url);
}
export { searchSuggestions };
