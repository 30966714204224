import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import {
  Dropzone,
  FileRejection
} from "@aptedge/lib-ui/src/components/Dropzone/Dropzone";
import {
  Modal,
  ModalFooter,
  ModalWidth
} from "@aptedge/lib-ui/src/components/Modal/Modal";
import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import { ThumbnailPreview } from "@aptedge/lib-ui/src/components/ThumbnailPreview/ThumbnailPreview";
import { IEdgeFile, IEdgeInfo } from "@aptedge/lib-ui/src/types/entities";
import { GTM_EVENTS, dataLayerPush } from "@aptedge/lib-ui/src/utils/event";
import isEqual from "lodash/isEqual";
import React, { ComponentProps, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { uploadEdgeFiles } from "../../../clients/Edges/uploadEdgeFiles";
import { Toast } from "../../../components/Toast/Toast";
import WithLoading from "../../../components/WithLoading/WithLoading";
import { ContentType } from "../../../types/http";
import "./EdgeFileModal.scss";

const ACCEPTED_TYPES = [
  ContentType.IMAGE_JPEG,
  ContentType.IMAGE_PNG,
  ContentType.IMAGE_TIFF,
  ContentType.IMAGE_GIF,
  ContentType.APPLICATION_EXCEL,
  ContentType.APPLICATION_EXCEL_X,
  ContentType.APPLICATION_JSON,
  ContentType.APPLICATION_MSWORD,
  ContentType.APPLICATION_MSWORD_X,
  ContentType.APPLICATION_OCTET_STREAM,
  ContentType.APPLICATION_PDF,
  ContentType.APPLICATION_RTF,
  ContentType.APPLICATION_PPT,
  ContentType.APPLICATION_PPTX,
  ContentType.TEXT_CSV,
  ContentType.TEXT_MARKDOWN,
  ContentType.TEXT_PLAIN,
  ContentType.APPLICATION_PCAP,
  ContentType.APPLICATION_EML,
  ContentType.APPLICATION_ZIP,
  ContentType.APPLICATION_KML,
  ContentType.APPLICATION_KMZ
];

interface Props extends Omit<ComponentProps<typeof Modal>, "title" | "width"> {
  edge: IEdgeInfo;
  onUpdate: (newFile: IEdgeFile[]) => void;
}

const EdgeFileModal: React.FC<Props> = ({ edge, onUpdate, ...modalProps }) => {
  const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>([]);
  const [duplicatedFiles, setDuplicatedFiles] = useState<File[]>([]);
  const [queuedFiles, setQueuedFiles] = React.useState<File[]>([]);
  const uploadFilesMutation = useMutation(uploadEdgeFiles, {
    onSuccess: (data) => {
      dataLayerPush({ event: GTM_EVENTS.GTM_UPDATE_EDGE_DESCRIPTION });
      onUpdate(data);
    }
  });

  useEffect(() => {
    if (!modalProps.isOpen) {
      setQueuedFiles([]);
      setRejectedFiles([]);
      setDuplicatedFiles([]);
    }
  }, [modalProps.isOpen]);

  const handleDelete = (file: File): void => {
    const updatedFiles = queuedFiles.filter((f) => !isEqual(f, file));
    setQueuedFiles(updatedFiles);
  };

  const handleDrop = (accepted: File[], rejected: FileRejection[]): void => {
    const duplicates: File[] = [];
    const valid: File[] = [];

    accepted.forEach((a) => {
      const match = queuedFiles.findIndex(
        (q) => q.size === a.size && q.name === a.name
      );

      if (match === -1) {
        valid.push(a);
      } else {
        duplicates.push(a);
      }
    });

    setQueuedFiles([...queuedFiles, ...valid]);
    setRejectedFiles(rejected);
    setDuplicatedFiles(duplicates);
  };

  return (
    <Modal
      {...modalProps}
      title="Upload files"
      width={ModalWidth.LARGE}
      className="edge-file-modal"
    >
      <WithLoading
        isLoading={uploadFilesMutation.isLoading}
        fallback={<Spinner />}
      >
        <Dropzone onDropFiles={handleDrop} acceptedTypes={ACCEPTED_TYPES} />
        <small className="mt-1 text-muted">
          Single file size limit: 10MB, Allowed file types: Word, Excel,
          PowerPoint, PDF, Image, Text, JSON, EML, KML, ZIP
          {!!rejectedFiles.length && (
            <div className="text-danger">
              {`Unable to upload the following files: ${rejectedFiles
                .map((r) => r.file.name)
                .join(", ")}`}
            </div>
          )}
          {!!duplicatedFiles.length && (
            <div className="text-danger">
              {`The following files have already been uploaded: ${duplicatedFiles
                .map((d) => d.name)
                .join(", ")}`}
            </div>
          )}
        </small>
        {!!queuedFiles.length && (
          <>
            <h4 className="mt-3">Files</h4>
            <div className="d-inline-flex flex-wrap">
              {queuedFiles.map((f) => (
                <ThumbnailPreview
                  key={f.name}
                  file={f}
                  onDelete={handleDelete}
                  data-testid="preview"
                />
              ))}
            </div>
          </>
        )}
        <ModalFooter>
          <div className="d-flex w-100 justify-content-end">
            <Button
              className="mr-2"
              color="secondary"
              onClick={modalProps.onClose}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              onClick={() =>
                uploadFilesMutation.mutate({
                  edgeId: edge.id,
                  files: queuedFiles
                })
              }
              disabled={!queuedFiles.length || uploadFilesMutation.isLoading}
              data-testid="upload-button"
            >
              Upload
            </Button>
          </div>
        </ModalFooter>
      </WithLoading>
      {uploadFilesMutation.error && (
        <Toast type="error">Unable to upload files.</Toast>
      )}
    </Modal>
  );
};

export { EdgeFileModal };
