import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { Icon } from "@aptedge/lib-ui/src/components/Icon/Icon";
import { TextInput } from "@aptedge/lib-ui/src/components/TextInput/TextInput";
import { faEnvelopeOpenText } from "@fortawesome/free-solid-svg-icons/faEnvelopeOpenText";
import { useFormik } from "formik";
import React, { useRef } from "react";
import Helmet from "react-helmet";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { resetPassword } from "../../clients/Account/resetPassword";
import { Routes } from "../../routes/routes";
import { LoginContent } from "../LoginPage/LoginContent";

interface FormState {
  email: string;
}

type Status = "success" | "fail" | null;

const LoginResetPage: React.FC = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [status, setStatus] = React.useState<Status>(null);
  const resetQuery = useMutation(resetPassword, {
    onSuccess: () => setStatus("success"),
    onError: () => setStatus("fail")
  });

  const isLoading = resetQuery.isLoading;

  const formik = useFormik<FormState>({
    initialValues: { email: "" },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Please enter a valid email address.")
        .required("An email address is required.")
    }),
    onSubmit: (data: FormState): void => {
      resetQuery.mutate(data);
    }
  });

  return (
    <>
      <Helmet>
        <title>Reset Password</title>
      </Helmet>
      <LoginContent>
        <div className="header mb-4">
          <img alt="logo" className="logo" src="/trimmed-logo.png" />
          <h2>Reset your password</h2>
        </div>
        {status === "success" ? (
          <div className="mt-3 mb-3 text-center" style={{ minHeight: 60 }}>
            <div className="mb-2">
              <Icon
                icon={faEnvelopeOpenText}
                size="5x"
                color="var(--gray-200)"
              ></Icon>
            </div>
            <p className="mb-0">We sent a recovery link to you at</p>
            <p className="font-weight-bold mt-0">{formik.values.email}</p>
          </div>
        ) : (
          <form className="mb-4" onSubmit={formik.handleSubmit}>
            {status === "fail" && (
              <div className="alert alert-danger">
                <span>
                  We&apos;re sorry, something went wrong. Please email us at{" "}
                </span>
                <a href="mailto:support@aptedge.io">support@aptedge.io.</a>
              </div>
            )}
            <div className="form-group">
              <TextInput
                ref={inputRef}
                type="text"
                id="email"
                name="email"
                className="recovery-email"
                error={formik.errors.email}
                placeholder="Enter your email"
                value={formik.values.email}
                onChange={formik.handleChange}
                label="We'll send a recovery link to"
                autoFocus
              />
            </div>
            <Button type="submit" disabled={isLoading}>
              {isLoading ? "Sending..." : "Send Recovery Link"}
            </Button>
          </form>
        )}
        <hr />
        <div className="d-flex justify-content-center align-items-center">
          <Link to={Routes.LOGIN}>Return to login</Link>
        </div>
      </LoginContent>
    </>
  );
};

export { LoginResetPage };
