import React from "react";

interface Props {
  children: React.ReactNode;
  onClick: () => void;
}
const LoginBtn: React.FC<Props> = ({ children, onClick }) => {
  return (
    <button onClick={onClick} className="ms-auth" type="button">
      {children}
    </button>
  );
};

export default LoginBtn;
