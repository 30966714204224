import { ISharepointDriveSource } from "@aptedge/lib-ui/src/types/entities";
import { getBackendUrl } from "../../config";
import { request } from "../../utils/request";

function fetchSharepointDriveSources(): Promise<ISharepointDriveSource[]> {
  const url = `${getBackendUrl()}/api/integrations/sharepoint-drive`;
  return request(url);
}

export { fetchSharepointDriveSources };
