import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import { IFlagSet } from "@aptedge/lib-ui/src/context/FlagsContext/types";
import { useOutsideAlerter } from "@aptedge/lib-ui/src/hooks/useOutsideClick";
import { MutableRefObject, useRef, useState } from "react";
import {
  useAppDispatch,
  useAppSelector
} from "../../../../../../../src/redux/hook/hook";
import {
  AiModel,
  setAiModel
} from "../../../../../../../src/redux/reduxSlice/answerGPTSlice";
interface IUseSettings {
  settingsVisibility: boolean;
  onSubMenuItemClicked: (model: AiModel) => void;
  onSettingBtnClicked: () => void;
  selectedModel: AiModel;
  settingRef: MutableRefObject<HTMLDivElement | null>;
  flags: IFlagSet;
}
const useSettings = (onRefetchClick: () => void): IUseSettings => {
  const { flags } = useFlags();
  const [settingsVisibility, setSettingsVisibility] = useState(false);
  const { aiModel: selectedModel } = useAppSelector((state) => state.answerGPT);
  const settingRef = useRef<HTMLDivElement>(null);
  const onSettingBtnClicked = (): void => {
    setSettingsVisibility((prev) => !prev);
  };
  const dispatch = useAppDispatch();
  const onSubMenuItemClicked = (model: AiModel): void => {
    dispatch(setAiModel(model));
    onRefetchClick();
    closeSettings();
  };
  useOutsideAlerter(settingRef, () => setSettingsVisibility(false));
  const closeSettings = (): void => {
    setSettingsVisibility(false);
  };
  return {
    settingsVisibility,
    onSubMenuItemClicked,
    onSettingBtnClicked,
    selectedModel,
    settingRef,
    flags
  };
};

export default useSettings;
