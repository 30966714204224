import { AeIcons } from "@aptedge/lib-ui/src/components/Icon/AeIcons";
import classNames from "classnames";
import { isArray } from "lodash";
import { FC, useState } from "react";
import styles from "./styles.module.scss";

type OptionType = {
  key: string;
  value: string | number;
};

interface ConfigInputProps {
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  options?: OptionType[];
  value?: string | number | string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  attrs?: any;
  name?: string;
  dropdownValue?: string | number;
  leadingText?: string;
  multiInput?: boolean;
  onAddNewItem?: (key: string, value: string) => void;
  onRemoveItem?: (key: string, value: string) => void;
  readonly?: boolean;
  isError?: boolean;
  errorMsg?: string;
}
interface MultiInputProps {
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  attrs?: any;
  name: string;
  leadingText?: string;
  separator?: boolean;
  onAddNewItem?: (key: string, value: string) => void;
  onRemoveItem?: (key: string, value: string) => void;
  errorMsg?: string;
}
interface DropdownProps {
  options?: OptionType[];
  className?: string;
}

const ConfigInputField: FC<ConfigInputProps> = ({
  placeholder,
  onChange,
  options,
  value,
  attrs,
  name,
  leadingText,
  multiInput,
  onAddNewItem,
  onRemoveItem,
  isError,
  errorMsg
}) => {
  const hasLeadingDropdown = !!options?.length;
  const separator = hasLeadingDropdown || !!leadingText;

  return (
    <div>
      {multiInput && isArray(value) && name ? (
        <MultiInput
          value={value}
          attrs={attrs}
          leadingText={leadingText}
          name={name}
          onChange={onChange}
          separator={separator}
          placeholder={placeholder}
          onAddNewItem={onAddNewItem}
          onRemoveItem={onRemoveItem}
        />
      ) : (
        <>
          <div className={styles.wrapper}>
            {hasLeadingDropdown && (
              <Dropdown options={options} className={styles.leading}></Dropdown>
            )}
            {!!leadingText && (
              <span className={styles.leading}>{leadingText}</span>
            )}
            <input
              className={classNames(styles.textWDropdown, {
                [styles.separator]: separator,
                [styles.readonly]: attrs?.readonly,
                [styles.errorInput]: isError
              })}
              name={name}
              value={attrs?.disabled ? "" : value}
              onChange={onChange}
              placeholder={placeholder}
              {...attrs}
            />
          </div>
          {isError && <div className={styles.error}>{errorMsg}</div>}
        </>
      )}
    </div>
  );
};

const MultiInput: FC<MultiInputProps> = ({
  value,
  attrs,
  leadingText,
  separator,
  name,
  placeholder,
  onAddNewItem,
  onRemoveItem
}) => {
  const [pageGlob, setPageGlob] = useState("");
  return (
    <div className={styles.multiInputContainer}>
      {value.map((v) => (
        <div className={styles.multiInput} key={v}>
          <div className={classNames(styles.wrapper, styles.inputContainer)}>
            {!!leadingText && (
              <span className={styles.leading}>{leadingText}</span>
            )}
            <div
              className={classNames(styles.pageUrl, {
                [styles.separator]: separator
              })}
            >
              {v}
            </div>
          </div>
          <button
            className={styles.delete}
            onClick={() => !!onRemoveItem && onRemoveItem(name, v)}
          >
            <AeIcons.Trash />
          </button>
        </div>
      ))}
      <div className={styles.multiInput}>
        <div className={classNames(styles.wrapper, styles.inputContainer)}>
          {!!leadingText && (
            <span className={styles.leading}>{leadingText}</span>
          )}
          <input
            className={classNames(styles.input, {
              [styles.separator]: separator
            })}
            value={attrs?.disabled ? "" : pageGlob}
            name={name}
            onChange={(e) => setPageGlob(e.target.value)}
            placeholder={placeholder}
            {...attrs}
          />
        </div>
        <button
          className={classNames(styles.addBtn, {
            [styles.disabledBtn]: !pageGlob || attrs?.disabled
          })}
          disabled={attrs?.disabled || !pageGlob}
          onClick={() => {
            !!onAddNewItem && pageGlob && onAddNewItem(name, pageGlob);
            setPageGlob("");
          }}
        >
          <AeIcons.Plus />
          Add
        </button>
      </div>
    </div>
  );
};

const Dropdown: FC<DropdownProps> = ({ options, className }) => {
  return (
    <select className={className}>
      {options?.map(({ key, value }: OptionType) => {
        return (
          <option key={key} value={value}>
            {value}
          </option>
        );
      })}
    </select>
  );
};

export default ConfigInputField;
