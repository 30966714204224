import { IAzureDevopsIssuesSource } from "@aptedge/lib-ui/src/types/entities";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

type SliceStateType = {
  dataSource: IAzureDevopsIssuesSource | null;
};

const initialState: SliceStateType = {
  dataSource: null
};

const azureDevopsSlice = createSlice({
  name: "azureDevops",
  initialState,
  reducers: {
    setAzureDevopsTapData: (
      state,
      action: PayloadAction<IAzureDevopsIssuesSource | null>
    ) => {
      state.dataSource = action.payload;
    }
  }
});

export const { setAzureDevopsTapData } = azureDevopsSlice.actions;

export default azureDevopsSlice.reducer;
