import { FC } from "react";
import { displayAge } from "../../utils/time";
import "./styles.scss";

interface Props {
  createdOn?: number;
  updatedOn?: number;
  createdVerb?: string;
  updatedVerb?: string;
}
const SearchCardTimings: FC<Props> = ({
  updatedOn,
  createdOn,
  createdVerb,
  updatedVerb
}) => {
  return (
    <div className="timings">
      {createdOn && (
        <div className="created">{`${createdVerb ?? "Created"} ${displayAge(
          createdOn
        )}`}</div>
      )}
      {updatedOn && createdOn !== updatedOn && (
        <div className="updated">{`${updatedVerb ?? "Updated"} ${displayAge(
          updatedOn
        )}`}</div>
      )}
    </div>
  );
};

export default SearchCardTimings;
