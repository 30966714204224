import {
  AptAnswersFilterRule,
  AptAnswersFilterType
} from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";
import { patch } from "../utils/buildRequest";

interface UpdateFilterItem {
  id?: string;
  name: string;
  filterType: AptAnswersFilterType;
  filterRules: AptAnswersFilterRule[];
}

interface UpdateFilterArgs {
  filters: UpdateFilterItem[];
}

interface UpdateFilterResponse {
  filterId: string;
}

async function updateFilter({
  filters
}: UpdateFilterArgs): Promise<UpdateFilterResponse> {
  if (filters.length === 0) {
    return { filterId: "" };
  }
  const url = `${getBackendApiUrl()}/apt-answer-filter/${
    filters[0].filterType
  }`;
  const options = patch({
    filters
  });
  return request(url, options);
}

export { updateFilter };
