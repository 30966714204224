import classNames from "classnames";
import React, { ReactElement } from "react";
import { Manager, Popper, Reference } from "react-popper";
import { useAutoComplete } from "../../hooks/useAutoComplete";
import { Chip } from "../Chip/Chip";
import { DEFAULT_PREVENT_OVERFLOW_MODIFIER } from "../Popper/popper";
import { PopperContent } from "../Popper/PopperContent";
import { Spinner, SpinnerSize } from "../Spinner/Spinner";
import "./MultiSelect.scss";

interface Props {
  name: string;
  // TODO - assign the correct type below where the generic type was temporarily modified to any.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any[];
  // TODO - assign the correct type below where the generic type was temporarily modified to any.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[];
  label?: string;
  className?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  autoComplete?: boolean;
  placeholder?: string;
  noDataPlaceholder?: ReactElement;
  // TODO - assign the correct type below where the generic type was temporarily modified to any.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getOptionLabel: (item: any) => string;
  // TODO - assign the correct type below where the generic type was temporarily modified to any.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderOption?: (item: any) => React.ReactNode;
  // TODO - assign the correct type below where the generic type was temporarily modified to any.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (selected: any[]) => void;
  onInputChange?: (val: string) => void;
}

function MultiSelect(props: Props): ReactElement {
  const {
    data,
    name,
    label,
    isLoading,
    getOptionLabel,
    renderOption = getOptionLabel,
    value,
    placeholder,
    noDataPlaceholder,
    autoComplete = true,
    className,
    onChange,
    onInputChange
  } = props;

  const id = `multi-select-${name}`;
  const {
    results,
    selected,
    popupOpen,
    getRootProps,
    getInputProps,
    getInputLabelProps,
    getListboxProps,
    getInvisibleDivProps,
    getChipProps,
    getOptionProps
  } = useAutoComplete({
    id,
    data,
    getOptionLabel,
    autoComplete,
    selected: value,
    onInputChange,
    onChange
  });

  const inputProps = getInputProps();

  return (
    <div className={classNames("multi-select", className)}>
      <Manager>
        <Reference>
          {({ ref }) => (
            <div ref={ref} {...getRootProps()}>
              <div {...getInvisibleDivProps()}>{inputProps.value}</div>
              {label && <label {...getInputLabelProps()}>{label}</label>}
              <div className="input-container">
                {selected.map((item: Record<string, unknown>, idx: number) => (
                  <Chip key={idx} {...getChipProps(item, idx)} />
                ))}
                <input
                  placeholder={placeholder}
                  data-testid={`${id}-input`}
                  {...inputProps}
                />
                {isLoading && <Spinner size={SpinnerSize.SMALL} />}
              </div>
            </div>
          )}
        </Reference>
        {popupOpen && (
          <Popper
            placement="bottom-end"
            modifiers={[DEFAULT_PREVENT_OVERFLOW_MODIFIER]}
          >
            {({ ref, style, placement, update }) => (
              <PopperContent
                className="popper"
                ref={ref}
                style={style}
                placement={placement}
                resetKeys={[...selected, inputProps.value]}
                scheduleUpdate={update}
              >
                <div {...getListboxProps()} className="multi-select-content">
                  {results.map(
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (item: Record<string, unknown>, idx: number) => (
                      <div key={idx} {...getOptionProps(item, idx)}>
                        {renderOption(item)}
                      </div>
                    )
                  )}
                  {isLoading && (
                    <Spinner className="m-2 mx-auto" size={SpinnerSize.SMALL} />
                  )}
                  {!results.length && !isLoading && (
                    <>
                      {!data.length && !!noDataPlaceholder ? (
                        <div>{noDataPlaceholder}</div>
                      ) : (
                        <div className="no-match">No matching items</div>
                      )}
                    </>
                  )}
                </div>
              </PopperContent>
            )}
          </Popper>
        )}
      </Manager>
    </div>
  );
}

export { MultiSelect };
