import { SearchFilterDropdown } from "../../services/search/searchFilterService";

export const getUpdatedDropDownMenu = (
  dropDownMenu: SearchFilterDropdown[]
): SearchFilterDropdown[] => {
  const seenFilter = new Set();
  const uniqueDropdownList: SearchFilterDropdown[] = [];
  dropDownMenu.forEach((filter) => {
    if (seenFilter.has(filter.filter_key)) return;

    uniqueDropdownList.push(filter);
    seenFilter.add(filter.filter_key);
  });

  return uniqueDropdownList;
};
