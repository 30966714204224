/* istanbul ignore file */

// Needs to be at the top here per
// https://github.com/lukeautry/tsoa/issues/902#issuecomment-770932395. The error seems to be
// caused by unrelated imports, but this fixes it.
import "reflect-metadata";

import { setupSentry } from "@aptedge/lib-ui/src/context/SentryContext";
import { store } from "@aptedge/lib-ui/src/redux/store/store";
import { SentryDSN } from "@aptedge/lib-ui/src/utils/config";
import { inDevOnly, printRelease } from "@aptedge/lib-ui/src/utils/env";
import { sendEventViaBackend } from "@aptedge/lib-ui/src/utils/event";
import {
  createQueryClient,
  setupQueryClientPersistence
} from "@aptedge/lib-ui/src/utils/query";
import log from "loglevel";
import * as React from "react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import * as ReactDOM from "react-dom";
import TagManager from "react-gtm-module";
import { Provider } from "react-redux";
import { Head } from "./components/Head/Head";
import { getTagManagerConfig } from "./config";
import { AppProviders } from "./context/AppProviders";
import { history } from "./history";
import "./index.scss";
import { AppRoutes } from "./routes/AppRoutes";

// Only log warnings and errors in Production and Development.
// This can be overridden in the browser console with `log.setLevel()`.
// See: https://github.com/pimterry/loglevel
log.setDefaultLevel(log.levels.WARN);
window.log = log;

// Skip package typings
TagManager.initialize(getTagManagerConfig());

setupSentry(SentryDSN.WebApp);

// Run mock handlers for any incomplete API endpoints.
inDevOnly(() => {
  // Import the dev server inside the callback so that we don't import it in Production.
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { mockServer } = require("./mocks/mockServer");
  mockServer.start();
});

const queryClient = createQueryClient();

/*
Create a root component that receives the store via props
and wraps the App component with Provider, giving props to containers
*/
function Root(): React.ReactElement {
  React.useEffect(() => {
    const interval = setInterval(() => {
      let event;
      while ((event = (window.aptedgeEvents ?? []).shift())) {
        sendEventViaBackend(event);
      }
    }, 200);

    return () => clearInterval(interval);
  }, []);

  printRelease();

  return (
    <Provider store={store}>
      <AppProviders history={history} queryClient={queryClient}>
        <Head />
        <AppRoutes />
      </AppProviders>
    </Provider>
  );
}

setupQueryClientPersistence(queryClient).then(() => {
  // The app must be rendered after this Promise completes, or queries may not use the cache on
  // initial load. This is very quick, though.
  ReactDOM.render(<Root />, document.getElementById("root"));
});
