import { Chip } from "@aptedge/lib-ui/src/components/Chip/Chip";
import { IEdgeAuditLogExampleChange } from "@aptedge/lib-ui/src/types/entities";
import React from "react";
import { ArrowTransition } from "./ArrowTransition";
import { SummarySentence } from "./SummarySentence";
import { WithAuditLog } from "./WithAuditLog";
import type { AuditDetailsFC, AuditSummaryFC } from "./WithAuditLog";

const Details: AuditDetailsFC<IEdgeAuditLogExampleChange> = (props) => {
  const { log } = props;
  const { added, removed } = log.data;

  const examples = added.length > 0 ? added : removed;
  const displayText = added.length > 0 ? "Added" : "Removed";

  const displayedTo = (
    <div>
      <p>
        <span>{displayText} </span>
        {examples.map((example) => (
          <Chip
            key={`audit-log-label-${example.id}-${
              example.displayId || example.externalId
            }`}
            className="mr-1"
            label={example.displayId || example.externalId}
          />
        ))}
      </p>
    </div>
  );

  return (
    <div className="audit-log-creation">
      <ArrowTransition from={null} to={displayedTo} />
    </div>
  );
};

const Summary: AuditSummaryFC<IEdgeAuditLogExampleChange> = (props) => {
  const { VERBS, OBJECTS } = SummarySentence;
  const { author, log } = props;

  return (
    <SummarySentence
      subject={author}
      verb={VERBS.UPDATED}
      object={OBJECTS.EXAMPLES}
      displayText={log?.displayText ?? ""}
    />
  );
};

Details.displayName = "AuditExampleChangeDetails";
const AuditLogExampleChange = WithAuditLog(Details, Summary);

export { AuditLogExampleChange };
