import React from "react";
import { CellComponentProps } from "../useTable";

function DefaultTableCell<T>(props: CellComponentProps<T>): React.ReactElement {
  const { id, data } = props;

  const propertyValue = data[id];
  const displayedValue =
    propertyValue && typeof propertyValue !== "object"
      ? String(propertyValue)
      : null;

  return <>{displayedValue}</>;
}

export { DefaultTableCell };
