import camelCase from "lodash/camelCase";
import kebabCase from "lodash/kebabCase";

interface Dictionary<T> {
  [index: string]: T;
}
type SimpleTypes = string | number | boolean | null | undefined;
export type ShallowDictionary = Dictionary<SimpleTypes | SimpleTypes[]>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function toKebab(original: Record<string, any>): ShallowDictionary {
  return Object.entries(original).reduce(
    (memo: ShallowDictionary, [key, value]) => {
      memo[kebabCase(key)] = value;

      return memo;
    },
    {}
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function toCamel<T extends Record<string, any>>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  original: Record<string, any>
): T {
  return Object.entries(original).reduce(
    (memo: ShallowDictionary, [key, value]) => {
      memo[camelCase(key)] = value;

      return memo;
    },
    {}
  ) as T;
}

export { toCamel, toKebab };
