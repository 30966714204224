import classNames from "classnames";
import React, { useCallback } from "react";
import { useDropzone, FileRejection, DropEvent } from "react-dropzone";
import "./Dropzone.scss";

interface Props {
  className?: string;
  onDropFiles?: (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void;
  onAccept?: (files: File[], event: DropEvent) => void;
  onReject?: (fileRejections: FileRejection[], event: DropEvent) => void;
  disabled?: boolean;
  acceptedTypes?: string[];
  multiple?: boolean;
  maxSize?: number;
}

const Dropzone: React.FC<Props> = ({
  className,
  onDropFiles,
  onAccept,
  onReject,
  disabled,
  acceptedTypes,
  multiple = true,
  // 10 MB max file size
  maxSize = 10000000,
  children = "Click here or drag and drop to add files"
}) => {
  const onDropAccepted = useCallback(
    (acceptedFiles, event) => {
      if (onAccept) {
        onAccept(acceptedFiles, event);
      }
    },
    [onAccept]
  );
  const onDropRejected = useCallback(
    (rejectedFiles, event) => {
      if (onReject) {
        onReject(rejectedFiles, event);
      }
    },
    [onReject]
  );
  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles, event) => {
      if (onDropFiles) {
        onDropFiles(acceptedFiles, rejectedFiles, event);
      }
    },
    [onDropFiles]
  );

  const { getRootProps, getInputProps } = useDropzone({
    disabled,
    maxSize,
    accept: acceptedTypes,
    onDrop,
    onDropAccepted,
    onDropRejected,
    multiple
  });

  return (
    <div
      {...getRootProps({
        className: classNames("dropzone", { className }, { disabled })
      })}
    >
      <input {...getInputProps()} data-testid="dropzone-input" />
      <div className="dropzone-description">{children}</div>
    </div>
  );
};

export { Dropzone };
export type { FileRejection };
