import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { toQueryString } from "../clients/utils/url";
import { AuthStatus, useAuth } from "../context/AuthContext";
import { Routes } from "./routes";

const PrivateRoute: React.FunctionComponent<Omit<RouteProps, "component">> = ({
  children,
  ...rest
}) => {
  const { status } = useAuth();

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (status === AuthStatus.LOGGED_IN) {
          return <>{children}</>;
        }

        const redirectTo = location.pathname + location.search;

        return (
          <Redirect
            to={{
              pathname: Routes.LOGIN,
              search: toQueryString({ redirectTo })
            }}
          />
        );
      }}
    />
  );
};

export { PrivateRoute };
