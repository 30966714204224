import React from "react";
import { Icons } from "../Icon/Icons";
import "./ThumbnailPreview.scss";

interface Props {
  file: File;
  onDelete: (file: File) => void;
}

const ThumbnailPreview: React.FC<Props> = ({ file, onDelete }) => {
  const [preview, setPreview] = React.useState<string>();

  React.useEffect(() => {
    if (!!file.type.match("image.*")) {
      setPreview(URL.createObjectURL(file));
    }

    return () => {
      if (preview) {
        URL.revokeObjectURL(preview);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  return (
    <div className="thumbnail">
      <div className="thumbnail-overlay">
        <Icons.Trash onClick={() => onDelete(file)} />
        <p className="align-self-center p-2">{file.name}</p>
      </div>
      {preview ? (
        <img className="thumbnail-content" src={preview} alt="No Preview" />
      ) : (
        <div className="thumbnail-content d-flex justify-content-center align-items-center p-2 text-secondary bg-dark h-100">
          <Icons.EyeSlash size="1x" />
          No Preview
        </div>
      )}
    </div>
  );
};

export { ThumbnailPreview };
