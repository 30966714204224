import { getBackendUrl } from "../../config";
import { request } from "../../utils/request";
import { post } from "../utils/buildRequest";

async function createSlackSocialSource(
  args: Record<string, unknown>
): Promise<Record<string, unknown>> {
  const url = `${getBackendUrl()}/slack/collect/`;
  const options: RequestInit = post(args);
  return request(url, options);
}

export { createSlackSocialSource };
