import {
  AptAnswersFiltersResponse,
  AptAnswersFilterType
} from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";

interface FetchFiltersArgs {
  filterType: AptAnswersFilterType;
}

async function fetchFilters({
  filterType
}: FetchFiltersArgs): Promise<AptAnswersFiltersResponse> {
  const url = `${getBackendApiUrl()}/apt-answer-filters/?filter_type=${filterType}`;
  return request(url);
}

export { fetchFilters };
