import { Icon } from "@aptedge/lib-ui/src/components/Icon/Icon";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons/faTrashAlt";
import classNames from "classnames";
import React from "react";
import "./DeleteIcon.scss";

interface Props {
  className?: string;
  onClick: () => void;
}

const DeleteIcon: React.FC<Props> = (props) => {
  const { onClick, className } = props;

  return (
    <button
      className={classNames(
        "btn",
        "delete-icon",
        "invisible",
        "group-hover-visible",
        className
      )}
      onClick={() => onClick()}
      data-testid="delete-icon"
    >
      <Icon icon={faTrashAlt} />
    </button>
  );
};

export { DeleteIcon };
