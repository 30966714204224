import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ISnowKnowledgeArticleSourceData } from "../../types/reduxTypes";

type SliceStateType = {
  dataSource: ISnowKnowledgeArticleSourceData | null;
};

const initialState: SliceStateType = {
  dataSource: null
};

const snowArticleSlice = createSlice({
  name: "snowKnowledge",
  initialState,
  reducers: {
    setSnowTapData: (
      state,
      action: PayloadAction<ISnowKnowledgeArticleSourceData | null>
    ) => {
      state.dataSource = action.payload;
    }
  }
});

export const { setSnowTapData } = snowArticleSlice.actions;

export default snowArticleSlice.reducer;
