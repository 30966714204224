import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import classNames from "classnames";
import * as React from "react";
import styles from "./TicketsTableHeader.module.scss";

const TicketsTableHeader: React.FC = () => {
  const { flags } = useFlags();

  return (
    <div
      className={classNames(styles.container, {
        [styles.containerV2]: flags.kbGenPublishProcess
      })}
    >
      <div className={styles.headerItem}>Ticket</div>
      <div className={styles.headerItem}>Article count</div>
      <div className={styles.headerItem}>Created date</div>
      <div className={styles.headerItem}>Created by</div>
      <div className={classNames(styles.headerItem, styles.actionsItem)}>
        Actions
      </div>
    </div>
  );
};

export { TicketsTableHeader };
