import TiptapEditor from "@aptedge/lib-ui/src/components/Editor/TiptapEditor";
import AEIcon from "@aptedge/lib-ui/src/components/GMIcon/Icon";
import { LightTooltip } from "@aptedge/lib-ui/src/components/Tooltip/LightTooltip";
import {
  ArticleEditType,
  ArticleStatus,
  GeneratedKnowledgeResult,
  UserRole
} from "@aptedge/lib-ui/src/types/entities";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import PlagiarismOutlinedIcon from "@mui/icons-material/PlagiarismOutlined";
import classNames from "classnames";
import truncate from "lodash/truncate";
import { FC } from "react";
import { useArticleItem } from "../../hooks/useArticleItem";
import { ArticleActions } from "../ArticlesPage/ArticleActions";
import { ArticleDetails } from "../ArticlesPage/ArticleDetails";
import { DiscardSource } from "../ArticlesPage/DiscardModal";
import styles from "./ArticleItemV2.module.scss";
import { ArticleMarkdownDisplay } from "./ArticleMarkdownDisplay";

interface ArticleItemProps {
  idx: number;
  article: GeneratedKnowledgeResult;
  articlesLength: number;
}

const ArticleItemV2: FC<ArticleItemProps> = ({
  idx,
  article,
  articlesLength
}) => {
  const {
    isTitleEdit,
    isBodyEdit,
    title,
    body,
    currentUserId,
    currentUserRole,
    handleEditArticle,
    handleTitleChange,
    handleCancelEdit,
    handleBodyChange,
    handleSaveEdit
  } = useArticleItem(article);

  return (
    <>
      <p className={styles.articleCount}>{`Article ${
        idx + 1
      } of ${articlesLength}`}</p>
      <div
        className={classNames(styles.container, {
          [styles.containerForAgent]: article.status === ArticleStatus.IN_REVIEW
        })}
        data-testid="article-item-v2"
      >
        <div className={styles.articleCard}>
          <div className={styles.articleContent}>
            {article && (
              <>
                <div className={styles.headerContainer}>
                  {!isTitleEdit ? (
                    <>
                      <LightTooltip
                        title={title}
                        placement="top"
                        arrow
                        componentsProps={{
                          tooltip: {
                            sx: {
                              width: "200px"
                            }
                          }
                        }}
                      >
                        <h3
                          className={styles.title}
                          onClick={() =>
                            handleEditArticle(ArticleEditType.TITLE)
                          }
                          data-testid="article-title"
                        >
                          {truncate(title, { length: 50 })}
                        </h3>
                      </LightTooltip>
                      <div
                        className={classNames(styles.articleStatusButton, {
                          [styles.draftArticle]:
                            article.status === ArticleStatus.DRAFT,
                          [styles.inReviewArticle]:
                            article.status === ArticleStatus.IN_REVIEW
                        })}
                        data-testid="article-status"
                      >
                        {article.status === ArticleStatus.DRAFT ? (
                          <AEIcon name="edit_document" size="1.125rem" />
                        ) : (
                          <PlagiarismOutlinedIcon
                            className={styles.articleStatusIcon}
                          />
                        )}
                        <span>
                          {article.status === ArticleStatus.DRAFT
                            ? "Draft"
                            : "In review"}
                        </span>
                      </div>
                    </>
                  ) : (
                    <div
                      className={styles.titleInputContainer}
                      data-testid="edit-title"
                    >
                      <input
                        defaultValue={title}
                        onChange={handleTitleChange}
                      />
                      <div className={styles.inputActions}>
                        <div
                          onClick={() => handleSaveEdit(ArticleEditType.TITLE)}
                          className={styles.saveBtn}
                          data-testid="save-edit-title"
                        >
                          <DoneIcon />
                          <span>Save</span>
                        </div>
                        <div
                          onClick={() =>
                            handleCancelEdit(ArticleEditType.TITLE)
                          }
                          className={styles.cancelBtn}
                          data-testid="cancel-edit-title"
                        >
                          <ClearIcon />
                          <span>Cancel</span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {!isBodyEdit ? (
                  // body
                  <div
                    onClick={() => handleEditArticle(ArticleEditType.BODY)}
                    data-testid="article-body"
                  >
                    <ArticleMarkdownDisplay articleBody={body} />
                  </div>
                ) : (
                  <div
                    className={styles.bodyTextareaContainer}
                    data-testid="edit-body"
                  >
                    <TiptapEditor
                      initialContent={body}
                      onChange={handleBodyChange}
                    />
                    <div className={styles.inputActions}>
                      <div
                        onClick={() => handleSaveEdit(ArticleEditType.BODY)}
                        className={styles.saveBtn}
                        data-testid="save-edit-body"
                      >
                        <DoneIcon />
                        <span>Save</span>
                      </div>
                      <div
                        onClick={() => handleCancelEdit(ArticleEditType.BODY)}
                        className={styles.cancelBtn}
                        data-testid="cancel-edit-body"
                      >
                        <ClearIcon />
                        <span>Cancel</span>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <ArticleDetails
          article={article}
          currentUserId={currentUserId}
          currentUserRole={currentUserRole}
        />
      </div>
      {(article.status === ArticleStatus.DRAFT ||
        currentUserRole !== UserRole.AGENT) && (
        <ArticleActions
          article={article}
          title={title}
          body={body}
          discardSource={DiscardSource.ARTICLE_REVIEW_PAGE}
        />
      )}
    </>
  );
};

export { ArticleItemV2 };
