import { IEdgeInfo } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { IUpdateEdgeRequest } from "../../types/clients";
import { request } from "../../utils/request";
import { post } from "../utils/buildRequest";

export type EdgeBulkUpdates = Pick<
  IUpdateEdgeRequest,
  "status" | "assignee" | "teamId" | "archived" | "labels" | "appendLabels"
>;

async function bulkUpdateEdges(args: {
  edgeIds: number[];
  update: EdgeBulkUpdates;
}): Promise<IEdgeInfo> {
  const options = post(args);

  return request(`${getBackendApiUrl()}/edges/edit/`, options);
}

export { bulkUpdateEdges };
