import {
  ArticleStatus,
  GeneratedKnowledgeResult
} from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";
import { patch } from "../utils/buildRequest";

interface EditArticleArgs {
  articleId: string;
  title?: string;
  body?: string;
  status?: ArticleStatus;
  availablePublicly?: boolean;
  reviewers?: number[];
}

function editArticle({
  articleId,
  title,
  body,
  status,
  availablePublicly,
  reviewers
}: EditArticleArgs): Promise<GeneratedKnowledgeResult> {
  const url = `${getBackendApiUrl()}/generated_knowledge/${articleId}`;
  const options: RequestInit = patch({
    title,
    body: { body },
    status,
    public: availablePublicly,
    reviewers
  });
  return request(url, options);
}

export { editArticle };
