import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { Icon } from "@aptedge/lib-ui/src/components/Icon/Icon";
import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import { Tooltip } from "@aptedge/lib-ui/src/components/Tooltip/Tooltip";
import { ITeam } from "@aptedge/lib-ui/src/types/entities";
import { faEdit } from "@fortawesome/free-regular-svg-icons/faEdit";
import classNames from "classnames";
import { useFormik } from "formik";
import React, { createRef, useEffect, useState } from "react";
import { useMutation } from "react-query";
import * as Yup from "yup";
import { deleteTeam } from "../../../clients/Teams/deleteTeam";
import { updateTeam } from "../../../clients/Teams/updateTeam";
import { StyledLink } from "../../../components/StyledLink/StyledLink";
import { Toast } from "../../../components/Toast/Toast";
import { toEdgeListPath } from "../../../routes/toPath";
import { ListItem } from "../common/ListItem";

import "./TeamItem.scss";

interface Props {
  team: ITeam;
  onChange: () => void;
  className?: string;
}

interface FormState {
  name: string;
}

const TeamItem: React.FC<Props> = (props) => {
  const { team, onChange, className } = props;
  const inputRef = createRef<HTMLInputElement>();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  useEffect(() => {
    if (inputRef.current && showEdit) {
      inputRef.current.focus();
    }
  }, [showEdit, inputRef]);

  const formik = useFormik<FormState>({
    initialValues: {
      name: team.name
    },
    onSubmit: (values) => {
      editTeam.mutate({
        id: team.id,
        name: values.name
      });
    },
    validateOnBlur: false,
    validationSchema: Yup.object({
      name: Yup.string().required()
    })
  });

  const removeTeam = useMutation(deleteTeam, {
    onSuccess: () => {
      onChange();
      setShowDeleteConfirm(false);
    }
  });

  const editTeam = useMutation(updateTeam, {
    onSuccess: () => {
      onChange();
      setShowEdit(false);
    }
  });

  const handleDelete = (): void => {
    if (!team.edgeCount) {
      removeTeam.mutate({ teamId: team.id });
    }
  };

  return (
    <ListItem className={classNames("team-list-item", "group", className)}>
      {!showDeleteConfirm && !showEdit ? (
        <>
          <div className="ml-3">
            <div>{team.name}</div>
            <small className="text-muted">Edge Count: {team.edgeCount}</small>
          </div>
          <div className="icons d-flex align-items-center">
            <Button className="btn" onClick={() => setShowEdit(true)}>
              <Icon icon={faEdit} data-testid="team-item-edit" />
            </Button>
            <Tooltip
              disabled={!team.edgeCount}
              content={
                <div>
                  A Team must be unassigned from all Edges in order to be
                  deleted. Click{" "}
                  <StyledLink
                    data-testid="team-filter-link"
                    to={toEdgeListPath({
                      filters: [
                        {
                          id: "teams",
                          operator: "equals",
                          values: [team.id.toString()]
                        }
                      ]
                    })}
                  >
                    here
                  </StyledLink>{" "}
                  to view Edges associated with this Team.
                </div>
              }
            >
              <Button
                className="btn delete"
                onClick={() => setShowDeleteConfirm(true)}
                disabled={!!team.edgeCount}
              >
                <Icons.Trash data-testid="team-item-delete" />
              </Button>
            </Tooltip>
          </div>
        </>
      ) : showEdit ? (
        <form className="w-100" onSubmit={formik.handleSubmit}>
          <div className="d-flex align-items-center justify-content-between">
            <div className="pl-1 pr-2">
              <input
                id="name"
                type="text"
                ref={inputRef}
                className={classNames("form-control", {
                  "is-invalid": !!formik.errors.name
                })}
                value={formik.values.name}
                onChange={formik.handleChange}
              />
            </div>
            <div className="d-flex justify-content-end">
              <Button
                type="button"
                className="mr-2"
                color="secondary"
                onClick={() => {
                  formik.resetForm();
                  setShowEdit(false);
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="primary"
                disabled={!!formik.errors.name}
              >
                Save
              </Button>
            </div>
          </div>
        </form>
      ) : (
        <>
          <span className="text-muted pr-2">
            Are you sure you want to delete <strong>{team.name}</strong>?
          </span>
          <div className="d-flex">
            <Button
              color="secondary"
              className="mr-2"
              onClick={() => setShowDeleteConfirm(false)}
            >
              Cancel
            </Button>
            <Button color="danger" onClick={handleDelete}>
              Delete
            </Button>
          </div>
        </>
      )}
      {(editTeam.error || removeTeam.error) && (
        <Toast type="error">Something went wrong.</Toast>
      )}
    </ListItem>
  );
};

export { TeamItem };
