import { Divider } from "@mui/material";
import classNames from "classnames";
import { FC } from "react";
import { AeIcons } from "../../../../../../../src/components/Icon/AeIcons";
import { AiModels } from "../../../../../../../src/redux/reduxSlice/answerGPTSlice";
import useSettings from "./useSettings";
import "./Settings.scss";

interface Props {
  onRefetchClick: () => void;
}
const Settings: FC<Props> = ({ onRefetchClick }) => {
  const {
    onSettingBtnClicked,
    selectedModel,
    settingsVisibility,
    onSubMenuItemClicked,
    settingRef
  } = useSettings(onRefetchClick);

  return (
    <div className="settings">
      <AeIcons.AiSetting
        className={classNames({ settingsVisibility })}
        onClick={onSettingBtnClicked}
      />
      {settingsVisibility && (
        <div className="settingsMenu" ref={settingRef}>
          <div className="settingsTitle">
            <AeIcons.AiBotWithoutCircle />
            <span>AI Models</span>
          </div>
          <Divider />
          {AiModels.map((aiModel) => (
            <div
              className={classNames("menu-item", {
                selectedModal: selectedModel === aiModel
              })}
              onClick={() => onSubMenuItemClicked(aiModel)}
              key={aiModel}
            >
              <div
                className={classNames("selectedMark", {
                  activeModalMark: selectedModel === aiModel
                })}
              />
              <span className="menu-item-title">{aiModel}</span>
              <div>{selectedModel === aiModel && <AeIcons.Checked />}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
export default Settings;
