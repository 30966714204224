const dePx = (value: string): number => {
  if (value.endsWith("px")) {
    const depxValue = value.slice(0, value.length - 2);
    const numericalValue = Number(depxValue);
    if (!isNaN(numericalValue)) {
      return numericalValue;
    }
  }
  return 16;
};

export { dePx };
