import React from "react";
import { Route } from "react-router-dom";
import { EdgePage } from "../pages/EdgePage/EdgePage";
import { EdgesListingPage } from "../pages/EdgesListingPage/EdgesListingPage";
import { Routes } from "./routes";

const EdgeRoutes: React.FunctionComponent = () => {
  return (
    <>
      <Route exact path={Routes.EDGES} component={EdgesListingPage} />
      <Route exact path={Routes.EDGES_EDGE} component={EdgePage} />
    </>
  );
};

export { EdgeRoutes };
