import { Select } from "@aptedge/lib-ui/src/components/Select/Select";
import { useDebounce } from "@aptedge/lib-ui/src/hooks/useDebounce";
import React, { useState } from "react";
import { useQuery } from "react-query";

type SelectProps = React.ComponentPropsWithoutRef<typeof Select>;
type SharedProps = Omit<
  SelectProps,
  "value" | "data" | "getOptionLabel" | "onChange" | "isSearchable"
>;
type Props<T, V> = SharedProps & {
  value?: V;
  valueKey: string;
  valueLoader: (val: V) => Promise<T>;
  optionsKey: string;
  optionsLoader: (textSearch?: string) => Promise<T[]>;
  getOptionAsValue: (item: T | null) => V | null;
  getOptionLabel: (item: T) => string;
  onChange: (item: V | null) => void;
};

function SelectAsync<T, V>(props: Props<T, V>): React.ReactElement {
  const {
    value,
    valueKey,
    valueLoader,
    optionsKey,
    optionsLoader,
    getOptionAsValue,
    onInputChange,
    onChange,
    ...rest
  } = props;
  const [textSearch, setTextSearch] = useState<string>();
  const { debouncedValue: debouncedSearch } = useDebounce(textSearch, 300);

  const optionsQuery = useQuery([optionsKey, debouncedSearch], () =>
    optionsLoader(debouncedSearch)
  );
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const valueQuery = useQuery([valueKey, value], () => valueLoader(value!), {
    enabled: !!value
  });

  const isLoading = optionsQuery.isLoading || valueQuery.isLoading;

  const onInputChangeHandler = (text?: string): void => {
    setTextSearch(text);
  };

  const onChangeHandler = (val: T | null): void => {
    setTextSearch(undefined);
    onChange(getOptionAsValue(val));
  };

  return (
    <Select
      value={!!valueQuery.data && !!value ? valueQuery.data : undefined}
      data={optionsQuery.data || []}
      onChange={onChangeHandler}
      onInputChange={onInputChangeHandler}
      isSearchable
      isLoading={isLoading}
      {...rest}
    />
  );
}

export { SelectAsync };
