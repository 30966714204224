import { ModalWidth } from "@aptedge/lib-ui/src/components/Modal/Modal";
import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import { useAppDispatch } from "@aptedge/lib-ui/src/redux/hook/hook";
import { setNotionTapData } from "@aptedge/lib-ui/src/redux/reduxSlice/notionArticleSlice";
import {
  IntegrationStatus,
  INTEGRATION_STATUS,
  OAuthStatus
} from "@aptedge/lib-ui/src/types/entities";
import { GATEWAY_TIMEOUT } from "http-status-codes";
import React, { useEffect, useState, useCallback } from "react";
import { useMutation } from "react-query";
import { ToastType } from "react-toastify";
import { createNotionArticleSource } from "../../../../clients/ArticleSources/createNotionArticleSource";
import { ConfirmationModal } from "../../../../components/ConfirmationModal/ConfirmationModal";
import { Toast } from "../../../../components/Toast/Toast";
import WithLoading from "../../../../components/WithLoading/WithLoading";
import { useNotionArticleSource } from "../../../../hooks/clients/useDataSource";
import { ClientStatus } from "../../../../types/clients";
import { AddButton } from "../../common/AddButton";
import { StatusButton } from "../../common/StatusButton";
import { NotionConfigFormData, NotionConfigForm } from "./NotionConfigForm";

const NotionConfig: React.FunctionComponent = () => {
  const dataSource = useNotionArticleSource();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [redirectStatus, setRedirectStatus] = React.useState(
    ClientStatus.PROMPT
  );
  const dispatch = useAppDispatch();

  const notionSource = dataSource?.dataSource ?? null;

  const addSource = useMutation(createNotionArticleSource, {
    onError: () => {
      setRedirectStatus(ClientStatus.ERROR);
    },
    onSuccess: () => {
      setRedirectStatus(ClientStatus.SUCCESS);
      setShowModal(false);
      dataSource.reload();
      window.location.reload();
    }
  });

  const onFormSubmit = (formData: NotionConfigFormData): void => {
    setRedirectStatus(ClientStatus.LOADING);
    addSource.mutate(formData);
  };

  const getStatus = (): IntegrationStatus => {
    if (dataSource?.error?.code === GATEWAY_TIMEOUT)
      return INTEGRATION_STATUS.TIME_OUT;
    if (notionSource?.status === OAuthStatus.AUTHENTICATED)
      return INTEGRATION_STATUS.OK;
    return INTEGRATION_STATUS.ERROR;
  };

  const handleReduxDispatch = useCallback((): void => {
    dispatch(setNotionTapData(notionSource ?? null));
  }, [dispatch, notionSource]);

  useEffect(() => {
    handleReduxDispatch();
  }, [handleReduxDispatch]);

  const isConnectionExist =
    notionSource || getStatus() === INTEGRATION_STATUS.TIME_OUT;
  const isLoaderVisible = addSource.isLoading || dataSource.isLoading;

  return (
    <section>
      <div className="row">
        <WithLoading isLoading={isLoaderVisible} fallback={<Spinner />}>
          <div className="mt-5 pl-3">
            <h5>Notion</h5>
            <p>Configure your Notion settings below.</p>
          </div>

          <div className="col-md-12 p-2 pl-3">
            {isConnectionExist ? (
              <StatusButton
                status={getStatus()}
                onClickEdit={() => setShowModal(true)}
              >
                <>
                  <div>Notion</div>
                  <div className="text-muted">notion.so</div>
                </>
              </StatusButton>
            ) : (
              <AddButton onClick={() => setShowModal(true)}>Connect</AddButton>
            )}
          </div>
        </WithLoading>
        <ConfirmationModal
          title="Notion Settings"
          width={ModalWidth.MEDIUM}
          loadingTitle="Loading...."
          status={redirectStatus}
          isOpen={showModal}
          onClose={() => setShowModal(false)}
        >
          <p className="mb-4">Configure your settings below.</p>
          <NotionConfigForm onSubmit={onFormSubmit} />
        </ConfirmationModal>
        {addSource.isError && (
          <Toast type={ToastType.ERROR}>Something went wrong.</Toast>
        )}
      </div>
    </section>
  );
};

export { NotionConfig };
