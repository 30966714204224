import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { Card } from "@aptedge/lib-ui/src/components/Card/Card";
import React from "react";
import Helmet from "react-helmet";
import { NavLink } from "react-router-dom";
import { Container } from "reactstrap";
import { PageContent } from "../../../../app-web/src/components/PageContent/PageContent";
import { Routes } from "../../../../app-web/src/routes/routes";
import { useAppSelector } from "../../redux/hook/hook";
import { AppType } from "../../types/entities";
import { urlProtocol } from "../../utils/supportApp";
import styles from "./styles.module.scss";
interface IProps {
  baseUrl?: string;
}

const WelcomePage: React.FC<IProps> = ({ baseUrl }) => {
  const { appType } = useAppSelector((state) => state.app);

  const url = `${urlProtocol}://${baseUrl}/settings`;

  if (appType === AppType.SUPPORT_APP)
    return (
      <Card className={styles.welcomeContainerSA}>
        <h2>
          Welcome to AptEdge&nbsp;
          <span role="img" aria-label="hooray">
            🎉
          </span>
        </h2>
        <p>
          We are currently working on setting up your environment. If you&apos;d
          like to review or edit your settings, please click the button below.
        </p>
        <div className="float-left">
          <Button onClick={() => window.open(url, "_blank")}>
            Go to Settings
          </Button>
        </div>
      </Card>
    );
  return (
    <>
      <Helmet>
        <title>Welcome</title>
      </Helmet>
      <PageContent>
        <Container>
          <Card>
            <h2>
              Welcome to AptEdge&nbsp;
              <span role="img" aria-label="hooray">
                🎉
              </span>
            </h2>
            <p>
              We are currently working on setting up your environment. If
              you&apos;d like to review or edit your settings, please click the
              button below.
            </p>
            <div className="float-right">
              <NavLink to={Routes.SETTINGS}>
                <Button>Go to Settings</Button>
              </NavLink>
            </div>
          </Card>
        </Container>
      </PageContent>
    </>
  );
};

export { WelcomePage };
