import { FC, ReactNode } from "react";
import styles from "./styles.module.scss";

interface Props {
  title: string;
  description?: string;
  children: ReactNode;
  divider?: boolean;
}

const SectionLayout: FC<Props> = ({
  title,
  description = "",
  children,
  divider = true
}) => {
  return (
    <>
      <div className={styles.sectionLayout}>
        <div className={styles.details}>
          <div className={styles.title}>{title}</div>
          <div className={styles.description}>{description}</div>
        </div>
        <div className={styles.configContainer}>{children}</div>
      </div>
      {divider && <div className={styles.divider} />}
    </>
  );
};

export default SectionLayout;
