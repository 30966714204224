import { IEdgeListing } from "@aptedge/lib-ui/src/types/entities";
import classNames from "classnames";
import upperFirst from "lodash/upperFirst";
import React from "react";
import { CellComponentProps } from "../useTable";
import "./EdgeStatusCell.scss";

function EdgeStatusCell(
  props: CellComponentProps<IEdgeListing>
): React.ReactElement {
  const { data: edge } = props;
  const statusClass = edge.status?.toLowerCase() || "";
  const status = upperFirst(statusClass);

  return (
    <div className={classNames("edge-status-cell", statusClass)}>
      <div className="edge-status-cell-content">
        <span className="edge-status-cell-dot" />
        <span className="edge-status-cell-status">{status}</span>
      </div>
    </div>
  );
}

export { EdgeStatusCell };
