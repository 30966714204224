import clamp from "lodash/clamp";
import React from "react";
import "./ProgressBar.scss";

interface Props {
  size?: number;
  amount?: number;
}

function ProgressBar(props: Props): React.ReactElement {
  const { amount = 50, size = 4 } = props;
  const progress = clamp(amount, 0, 100); // ensure it's between 0 and 100.

  return (
    <div className="progress-bar" style={{ height: `${size}px` }}>
      <div
        className="bar"
        style={{ width: `${progress}%`, height: `${size}px` }}
        role="progressbar"
        aria-valuemin={0}
        aria-valuenow={progress}
        aria-valuemax={100}
      ></div>
    </div>
  );
}

export { ProgressBar };
