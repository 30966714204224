import {
  LazyImport,
  LazyLoad,
  LazyLoadError
} from "@aptedge/lib-ui/src/components/LazyLoad/LazyLoad";
import { useSentry } from "@aptedge/lib-ui/src/context/SentryContext";
import {
  getFromLocalStorage,
  LocalStorageKeys,
  saveToLocalStorage
} from "@aptedge/lib-ui/src/hooks/useLocalStorage";
import React, { useCallback, useRef } from "react";
import { ErrorBoundary } from "react-error-boundary";
import LoadingPage from "../pages/LoadingPage/LoadingPage";

interface Props
  extends Omit<
    React.ComponentProps<typeof LazyLoad>,
    "fallback" | "errorFallback"
  > {
  storageKey: LocalStorageKeys;
}

type StorageObject = {
  count: number;
};

const MAX_RETRIES = 2;

const LazyLoadRoute: React.FC<Props> = (props) => {
  const { importFn, storageKey } = props;

  const { captureException } = useSentry();
  const hasCapturedError = useRef(false);

  const importFnWithCleanup: LazyImport = useCallback(
    () =>
      importFn().then((value) => {
        localStorage.removeItem(storageKey);
        return value;
      }),
    [importFn, storageKey]
  );

  const handleError = useCallback(
    (e) => {
      if (e instanceof LazyLoadError) {
        const loads = getFromLocalStorage<StorageObject>(storageKey) || {
          count: 0
        };
        const retries = loads.count;

        if (retries <= MAX_RETRIES) {
          const newValue = { count: retries + 1 };
          saveToLocalStorage<StorageObject>(storageKey, newValue);
          window.location.reload();

          return;
        }

        if (!hasCapturedError.current) {
          captureException(e);
          hasCapturedError.current = true;
        }
      } else {
        captureException(e);
      }
    },
    [captureException, storageKey]
  );

  return (
    <ErrorBoundary fallback={<ErrorFallback />} onError={handleError}>
      <LazyLoad importFn={importFnWithCleanup} fallback={<LoadingPage />} />
    </ErrorBoundary>
  );
};

function ErrorFallback(): React.ReactElement {
  return (
    <div className="container-fluid">
      <div className="row mt-5 p-5">
        <div className="col-md-9 col-lg-7 col-xl-6 d-flex m-auto">
          <div className="text-center m-auto">
            <p className="text-muted">
              Sorry, we ran into an issue loading this page.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export { LazyLoadRoute };
