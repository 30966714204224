import { IMSTeamsSource } from "@aptedge/lib-ui/src/types/entities";
import { getBackendUrl } from "../../../config";
import { request } from "../../../utils/request";

function getMSTeamsConnectionInfo(): Promise<IMSTeamsSource[]> {
  const url = `${getBackendUrl()}/api/integrations/msteams`;

  return request(url);
}

export { getMSTeamsConnectionInfo };
