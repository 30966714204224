import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import React, { useContext } from "react";
import { SavedQueriesContext } from "../../context/SavedQueriesContext";

interface Props {
  onClose: () => void;
}

const SavedQueryWrapper: React.FC<Props> = ({ onClose, children }) => {
  const saved = useContext(SavedQueriesContext);

  const handleClose = (): void => {
    saved.setCurrentSavedQuery(undefined);
    onClose();
  };

  if (!saved.currentSavedQuery || saved.editing) {
    return <>{children}</>;
  }

  return (
    <div
      className="saved-query filter-wrapper"
      data-testid="saved-query-wrapper"
    >
      <div className="label">
        <Icons.StarFilled />
        <span>{saved.currentSavedQuery.name}</span>
      </div>
      <div className="filter">
        <div className="filter-row">{children}</div>
      </div>
      <div className="end-cap" onClick={handleClose}>
        <Icons.Times />
      </div>
    </div>
  );
};

export { SavedQueryWrapper };
