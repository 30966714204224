import { FlagsProvider } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import { CACHE_KEY } from "@aptedge/lib-ui/src/data/cacheKeys";
import { FEATURE_FLAGS_QUERY_OPTIONS } from "@aptedge/lib-ui/src/utils/query";
import React from "react";
import { useQuery } from "react-query";
import { fetchFeatureFlags } from "../../clients/flags/fetchFeatureFlags";
import { useWhoami } from "../../clients/User/useWhoami";

const FeatureFlagsProvider: React.FC = ({ children }) => {
  const user = useWhoami();
  const userEmail = user.data?.userEmail;

  // unlike the support app, no need to include the user email in the cache key -- we clear the
  // cache on logout or login, and so we may as well get the slight speed improvement by not having
  // to wait for the /api/whoami query to populate the user email in memory
  const featureFlagsQuery = useQuery(
    [CACHE_KEY.FEATURE_FLAGS, userEmail],
    () => fetchFeatureFlags(),
    {
      ...FEATURE_FLAGS_QUERY_OPTIONS,
      enabled: !!userEmail
    }
  );

  return (
    <FlagsProvider
      flags={featureFlagsQuery.data}
      isLoading={featureFlagsQuery.isLoading}
    >
      {children}
    </FlagsProvider>
  );
};

export { FeatureFlagsProvider };
