import {
  EdgeAuditLogType,
  IEdgeAuditLog
} from "@aptedge/lib-ui/src/types/entities";
import React from "react";
import { AuditLogArchiving } from "./AuditLogArchiving";
import { AuditLogAssigneeChange } from "./AuditLogAssigneeChange";
import { AuditLogCommentChange } from "./AuditLogCommentChange";
import { AuditLogCreation } from "./AuditLogCreation";
import { AuditLogExampleChange } from "./AuditLogExampleChange";
import { AuditLogFileChange } from "./AuditLogFileChange";
import { AuditLogInternalIssueChange } from "./AuditLogInternalIssueChange";
import { AuditLogLabelChange } from "./AuditLogLabelChange";
import { AuditLogMerging } from "./AuditLogMerging";
import { AuditLogMigration } from "./AuditLogMigration";
import { AuditLogNameChange } from "./AuditLogNameChange";
import { AuditLogSpaceChange } from "./AuditLogSpaceChange";
import { AuditLogStatusChange } from "./AuditLogStatusChange";
import { AuditLogSummaryChange } from "./AuditLogSummaryChange";
import { AuditLogTeamChange } from "./AuditLogTeamChange";
import { AuditLogWeblinkChange } from "./AuditLogWeblinkChange";

const AuditLog: React.FC<{ log: IEdgeAuditLog; showDetails?: boolean }> = (
  props
) => {
  const { log, showDetails = true, children } = props;

  switch (log.type) {
    case EdgeAuditLogType.EDGE_ARCHIVING:
      return (
        <AuditLogArchiving log={log} showDetails={showDetails}>
          {children}
        </AuditLogArchiving>
      );
    case EdgeAuditLogType.EDGE_ASSIGNEE_CHANGE:
      return (
        <AuditLogAssigneeChange log={log} showDetails={showDetails}>
          {children}
        </AuditLogAssigneeChange>
      );
    case EdgeAuditLogType.EDGE_COMMENT_CHANGE:
      return (
        <AuditLogCommentChange log={log} showDetails={showDetails}>
          {children}
        </AuditLogCommentChange>
      );
    case EdgeAuditLogType.EDGE_CREATION:
      return (
        <AuditLogCreation log={log} showDetails={showDetails}>
          {children}
        </AuditLogCreation>
      );
    case EdgeAuditLogType.EDGE_EXAMPLE_CHANGE:
      return (
        <AuditLogExampleChange log={log} showDetails={showDetails}>
          {children}
        </AuditLogExampleChange>
      );

    case EdgeAuditLogType.EDGE_FILE_CHANGE:
      return (
        <AuditLogFileChange log={log} showDetails={showDetails}>
          {children}
        </AuditLogFileChange>
      );
    case EdgeAuditLogType.EDGE_INTERNAL_ISSUE_REFERENCE_CHANGE:
      return (
        <AuditLogInternalIssueChange log={log} showDetails={showDetails}>
          {children}
        </AuditLogInternalIssueChange>
      );
    case EdgeAuditLogType.EDGE_LABEL_CHANGE:
      return (
        <AuditLogLabelChange log={log} showDetails={showDetails}>
          {children}
        </AuditLogLabelChange>
      );
    case EdgeAuditLogType.EDGE_MERGING:
      return (
        <AuditLogMerging log={log} showDetails={showDetails}>
          {children}
        </AuditLogMerging>
      );
    case EdgeAuditLogType.EDGE_MIGRATION:
      return (
        <AuditLogMigration log={log} showDetails={showDetails}>
          {children}
        </AuditLogMigration>
      );
    case EdgeAuditLogType.EDGE_NAME_CHANGE:
      return (
        <AuditLogNameChange log={log} showDetails={showDetails}>
          {children}
        </AuditLogNameChange>
      );
    case EdgeAuditLogType.EDGE_STATUS_TRANSITION:
      return (
        <AuditLogStatusChange log={log} showDetails={showDetails}>
          {children}
        </AuditLogStatusChange>
      );
    case EdgeAuditLogType.EDGE_SUMMARY_CHANGE:
      return (
        <AuditLogSummaryChange log={log} showDetails={showDetails}>
          {children}
        </AuditLogSummaryChange>
      );
    case EdgeAuditLogType.EDGE_TEAM_CHANGE:
      return (
        <AuditLogTeamChange log={log} showDetails={showDetails}>
          {children}
        </AuditLogTeamChange>
      );
    case EdgeAuditLogType.EDGE_SPACE_CHANGE:
      return (
        <AuditLogSpaceChange log={log} showDetails={showDetails}>
          {children}
        </AuditLogSpaceChange>
      );
    case EdgeAuditLogType.EDGE_WEBLINK_CHANGE:
      return (
        <AuditLogWeblinkChange log={log} showDetails={showDetails}>
          {children}
        </AuditLogWeblinkChange>
      );
    default:
      return null;
  }
};

export { AuditLog };
