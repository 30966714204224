import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { useAppSelector } from "@aptedge/lib-ui/src/redux/hook/hook";
import { useFormik } from "formik";
import React from "react";
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label
} from "reactstrap";
import * as Yup from "yup";

export interface AzureDevopsCloudConfigFormData {
  organization: string;
  accessToken: string;
}

interface Props {
  onSubmit: (formData: AzureDevopsCloudConfigFormData) => void;
}

const AzureDevopsCloudConfigForm: React.FunctionComponent<Props> = ({
  onSubmit
}) => {
  const azureDevopsSource = useAppSelector((state) => state.azureDevopsSlice);
  const source = azureDevopsSource?.dataSource ?? null;

  const formik = useFormik<AzureDevopsCloudConfigFormData>({
    validationSchema: Yup.object({
      organization: Yup.string().required("Please enter an organization"),
      accessToken: Yup.string().required("Please enter a access token.")
    }),
    initialValues: {
      organization: (source && source.organization) || "",
      accessToken: (source && source.accessToken) || ""
    },
    validateOnChange: false,
    onSubmit: onSubmit
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <FormGroup row>
        <Label sm={3} for="clientId">
          Organization
        </Label>
        <Col sm={9}>
          <Input
            type="text"
            name="organization"
            placeholder=""
            value={formik.values.organization}
            onChange={formik.handleChange}
            invalid={!!formik.errors.organization}
          />
          <FormFeedback>{formik.errors.organization}</FormFeedback>
          <FormText>Name of the configured organization</FormText>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3} for="clientSecret">
          Access Token
        </Label>
        <Col sm={9}>
          <Input
            type="text"
            name="accessToken"
            placeholder=""
            value={formik.values.accessToken}
            onChange={formik.handleChange}
            invalid={!!formik.errors.accessToken}
          />
          <FormFeedback>{formik.errors.accessToken}</FormFeedback>
          <FormText>Access Token provided by Azure Devops</FormText>
        </Col>
      </FormGroup>
      <div className="float-right">
        <Button type="submit">Authorize</Button>
      </div>
    </Form>
  );
};

export { AzureDevopsCloudConfigForm };
