import React, { PropsWithChildren } from "react";
import "./EdgeSectionLabel.scss";

function EdgeSectionLabel(
  props: PropsWithChildren<Record<string, unknown>>
): JSX.Element {
  const { children } = props;

  return <label className="edge-section-label">{children}</label>;
}

export { EdgeSectionLabel };
