import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { DarkModeToggle } from "@aptedge/lib-ui/src/components/DarkModeToggle/DarkModeToggle";
import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import NavItem from "@aptedge/lib-ui/src/components/NavItem/NavItem";
import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import {
  useAppDispatch,
  useAppSelector
} from "@aptedge/lib-ui/src/redux/hook/hook";
import { resetSearch } from "@aptedge/lib-ui/src/redux/reduxSlice/searchSlice";
import { GTM_EVENTS, dataLayerPush } from "@aptedge/lib-ui/src/utils/event";
import classNames from "classnames";
import { useState, useEffect, useCallback, MouseEvent } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { isLocal } from "../../config";
import { useAuth } from "../../context/AuthContext";
import { Routes } from "../../routes/routes";
import { CreateEdgeModal } from "../CreateEdgeModal/CreateEdgeModal";
import { LazyDevTools } from "../DevTools/LazyDevTools";
import { AppBrand } from "./AppBrand";
import { Invite } from "./Invite";
import "./NavBar.scss";
import { Notifications } from "./Notifications";
import { ReactComponent as SettingsIcon } from "./settings.svg";
import UserDropdown from "./UserDropdown";

const NavBar: React.FunctionComponent = () => {
  const { user } = useAuth();
  const { flags, isLoading: isLoadingFlags } = useFlags();
  const history = useHistory();
  const { draftArticleCount, reviewArticleCount } = useAppSelector(
    (state) => state.knowledgeBase
  );

  const settingsRouteMatch = useRouteMatch(Routes.SETTINGS);
  const noShadow = !!settingsRouteMatch;

  const showAdminSetting = user
    ? user?.admin || !user?.onboardingComplete
    : false;

  const showOnboardedLink = user?.onboardingComplete || user?.admin;
  const showExplorerLink = showOnboardedLink && flags.analyticsTab;
  const showEdgesLink = showOnboardedLink;
  const showTicketsLink = showOnboardedLink;
  const showArticlesLink = showOnboardedLink && flags.insightsKBDraft;

  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const handleGlobalSearch = useCallback(
    (e: KeyboardEvent) => {
      if ((e.metaKey && e.key === "k") || (e.ctrlKey && e.key === "k")) {
        history.push(Routes.GLOBAL_SEARCH);
        e.preventDefault();
      }
    },
    [history]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleGlobalSearch);
    return () => {
      document.removeEventListener("keydown", handleGlobalSearch);
    };
  }, [handleGlobalSearch]);

  const getHelpingShortcutText = (): string => {
    if (window.navigator.userAgent.indexOf("Mac OS") !== -1) return "⌘ + k";
    else return "ctrl + k";
  };

  const onRouteChange = (event: MouseEvent): void => {
    const targetId = "navItem";
    let currentElement = event.target as HTMLElement | null;

    // Continue searching up the DOM tree
    while (currentElement) {
      if (currentElement.id === targetId) {
        dispatch(resetSearch());
        return;
      }
      currentElement = currentElement.parentElement;
    }
  };

  const handleEvent = (eventName: string): void => {
    dataLayerPush({
      event: eventName
    });
  };

  const handleCreateEdge = (): void => {
    setShowCreateModal(true);
    handleEvent(GTM_EVENTS.GTM_CREATE_EDGE_MENU_ITEM);
  };

  return (
    <nav
      className={classNames(
        "navbar-expand navbar ae-navbar sticky-top bg-gray-000",
        {
          "no-shadow": noShadow
        }
      )}
      onClick={onRouteChange}
    >
      <AppBrand to={Routes.HOME} />
      <div className="nav navbar p-0 h-100 app-routes justify-content-start">
        <NavItem className="nav-item ml-4 mr-4" to={Routes.GLOBAL_SEARCH}>
          <div className="search-nav">
            <Icons.Search />
            Search
            <span className="search-shortcut">{getHelpingShortcutText()}</span>
          </div>
        </NavItem>
        {showEdgesLink && (
          <NavItem
            className="nav-item mr-4 menu__item-edges"
            to={Routes.EDGES}
            onClick={() => handleEvent(GTM_EVENTS.GTM_EDGES_MENU_ITEM)}
          >
            Edges
          </NavItem>
        )}
        {showTicketsLink && (
          <NavItem
            className="nav-item mr-4 menu__item-tickets"
            to={Routes.TICKETS}
            onClick={() => handleEvent(GTM_EVENTS.GTM_TICKETS_MENU_ITEM)}
          >
            Tickets
          </NavItem>
        )}
        {isLoadingFlags ? null : (
          <>
            {showArticlesLink && (
              <NavItem
                className="nav-item mr-4 position-relative menu__item-articles"
                to={Routes.ARTICLES}
                onClick={() => handleEvent(GTM_EVENTS.GTM_ARTICLES_MENU_ITEM)}
              >
                Articles
                {Boolean(
                  (draftArticleCount || reviewArticleCount) &&
                    flags.kbGenPublishProcess
                ) && (
                  <span
                    className="pending-article-indicator"
                    data-testid="pending-article-indicator"
                  />
                )}
              </NavItem>
            )}
            {showExplorerLink && (
              <NavItem
                className="nav-item mr-4 position-relative menu__item-analytics"
                to={Routes.EXPLORER}
                onClick={() => handleEvent(GTM_EVENTS.GTM_ANALYTICS_MENU_ITEM)}
              >
                Analytics
              </NavItem>
            )}
            {flags.cssAnalytics && (
              <NavItem
                className="nav-item mr-4 position-relative menu__item-analytics"
                to={Routes.ANALYTICS}
                onClick={() => handleEvent(GTM_EVENTS.GTM_ANALYTICS_MENU_ITEM)}
              >
                Analytics
              </NavItem>
            )}
          </>
        )}
        {showEdgesLink && (
          <div className="nav-item mr-4 menu__item-createEdge">
            <Button color="primary" onClick={() => handleCreateEdge()}>
              <Icons.Plus />
              <span>Edge</span>
            </Button>
          </div>
        )}
      </div>

      <div className="nav navbar p-0 ml-auto h-100 justify-content-end">
        <div className="nav-item icon mr-3">
          <Notifications />
        </div>

        {isLocal() && (
          <DarkModeToggle className="nav-item icon mr-3 cursor-pointer menu__item-darkMode" />
        )}

        <LazyDevTools className="nav-item icon  mr-3 menu__item-devTools" />

        <NavItem
          className="nav-item icon mr-3 menu__item-analytics-settings"
          aria-label="settings"
          style={{ fontSize: 24 }}
          to={showAdminSetting ? Routes.SETTINGS : Routes.SETTINGS_WORKFLOW}
          onClick={() => handleEvent(GTM_EVENTS.GTM_SETTINGS)}
        >
          <SettingsIcon
            data-testid="settings-icon"
            id={showAdminSetting ? Routes.SETTINGS : Routes.SETTINGS_WORKFLOW}
          />
        </NavItem>

        <div className="nav-item icon mr-3 menu__item-profile">
          <UserDropdown />
        </div>

        {flags.inviteUser && (
          <div className="nav-item icon menu__item-invite">
            <Invite />
          </div>
        )}
      </div>
      <CreateEdgeModal
        key={`refresh-key-${showCreateModal.toString()}`}
        isOpen={showCreateModal}
        onClose={() => setShowCreateModal(false)}
      />
    </nav>
  );
};

export { NavBar };
