/* eslint-disable @typescript-eslint/no-explicit-any */
import { ModalWidth } from "@aptedge/lib-ui/src/components/Modal/Modal";
import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import {
  IntegrationStatus,
  INTEGRATION_STATUS,
  OAuthStatus
} from "@aptedge/lib-ui/src/types/entities";
import { GATEWAY_TIMEOUT } from "http-status-codes";
import React, { FC, useState } from "react";
import { useMutation } from "react-query";
import { ToastType } from "react-toastify";
import { createDynamicArticleSource } from "../../../../../clients/ArticleSources/DynamicSettings/createArticleSource";
import { updateDynamicArticleSource } from "../../../../../clients/ArticleSources/DynamicSettings/updateArticleSource";
import { ConfirmationModal } from "../../../../../components/ConfirmationModal/ConfirmationModal";
import { Toast } from "../../../../../components/Toast/Toast";
import WithLoading from "../../../../../components/WithLoading/WithLoading";
import { useDynamicArticleSource } from "../../../../../hooks/clients/useDataSource";
import { ClientStatus } from "../../../../../types/clients";
import { AddButton } from "../../../common/AddButton";
import { StatusButton } from "../../../common/StatusButton";
import { Field, IIntegration } from "../DynamicIntegrations";

interface ConfigFormProps<T extends Field[]> {
  fields: T;
  onSubmit: (formData: GenerateFormData<T>) => void;
  initialFormData: Record<string, unknown>;
}
interface Props {
  integration: IIntegration;
  configForm: React.FC<ConfigFormProps<Field[]>>;
}

export type GenerateFormData<T extends Field[]> = {
  [K in T[number]["id"]]: any;
};
export const FIELD_TYPE = { LIST: "list" };

const Integration: FC<Props> = ({ integration, configForm }) => {
  const { integrationName, fields, key, description } = integration;
  const ConfigForm = configForm;
  const [showModal, setShowModal] = useState<boolean>(false);
  const [redirectStatus, setRedirectStatus] = React.useState(
    ClientStatus.PROMPT
  );
  const { dataSource, error } = useDynamicArticleSource();
  const dynamicSource = dataSource?.filter((source) => source.key === key)[0];
  const onSuccess = (): void => {
    setRedirectStatus(ClientStatus.SUCCESS);
    setShowModal(false);
    window.location.reload();
  };

  const addSource = useMutation(createDynamicArticleSource, {
    onError: () => {
      setRedirectStatus(ClientStatus.ERROR);
    },
    onSuccess: onSuccess
  });
  const updateSource = useMutation(updateDynamicArticleSource, {
    onError: () => {
      setRedirectStatus(ClientStatus.ERROR);
    },
    onSuccess: onSuccess
  });

  const onFormSubmit = (formData: GenerateFormData<Field[]>): void => {
    setRedirectStatus(ClientStatus.LOADING);
    formData.websiteType = key;

    const id = dynamicSource?.id as string;
    if (id) updateSource.mutate({ formData, id });
    else addSource.mutate(formData);
  };

  const getStatus = (): IntegrationStatus => {
    if (error?.code === GATEWAY_TIMEOUT) return INTEGRATION_STATUS.TIME_OUT;
    if (dynamicSource?.status === OAuthStatus.AUTHENTICATED)
      return INTEGRATION_STATUS.OK;
    return INTEGRATION_STATUS.ERROR;
  };

  const isConnectionExist =
    dynamicSource || getStatus() === INTEGRATION_STATUS.TIME_OUT;
  const isLoaderVisible = addSource.isLoading;

  return (
    <section>
      <div className="row">
        <WithLoading isLoading={isLoaderVisible} fallback={<Spinner />}>
          <div className="mt-5 pl-3">
            <h5>{integrationName}</h5>
            <p>
              {description ||
                `Configure your ${integrationName} settings below`}
              .
            </p>
          </div>

          <div className="col-md-12 p-2 pl-3">
            {isConnectionExist ? (
              <StatusButton
                status={getStatus()}
                onClickEdit={() => setShowModal(true)}
              >
                <>
                  <div>{integrationName}</div>
                  <div className="text-muted">{integrationName} Settings</div>
                </>
              </StatusButton>
            ) : (
              <AddButton onClick={() => setShowModal(true)}>Connect</AddButton>
            )}
          </div>
        </WithLoading>
        <ConfirmationModal
          title={`${integrationName} Settings`}
          width={ModalWidth.MEDIUM}
          loadingTitle="Loading...."
          status={redirectStatus}
          isOpen={showModal}
          onClose={() => setShowModal(false)}
        >
          <p className="mb-4">Configure your settings below.</p>
          <ConfigForm
            fields={fields}
            onSubmit={onFormSubmit}
            initialFormData={dynamicSource?.inputData ?? {}}
          />
        </ConfirmationModal>
        {addSource.isError && (
          <Toast type={ToastType.ERROR}>Something went wrong.</Toast>
        )}
      </div>
    </section>
  );
};

export default Integration;
