import {
  ISharepointDriveSite,
  ISharepointDriveSiteInfoRequest
} from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";
import { post } from "../utils/buildRequest";

async function getSharepointDriveSiteInfo({
  sitePath,
  siteDomain
}: ISharepointDriveSiteInfoRequest): Promise<ISharepointDriveSite> {
  const url = `${getBackendApiUrl()}/integrations/sharepoint-drive/sites`;
  const options: RequestInit = post({ sitePath, siteDomain });
  return request(url, options);
}

export { getSharepointDriveSiteInfo };
