import {
  AptAnswersFilterRuleUpdate,
  AptAnswersFilterType,
  AptAnswersIntegrationsObject
} from "@aptedge/lib-ui/src/types/entities";
import { getAptAnswersFilterInfo } from "./getAptAnswersFilterInfo";

export enum IntegrationStateUpdateType {
  ADD_RULE = "ADD_RULE",
  DELETE_RULE = "DELETE_RULE",
  UPDATE_RULE = "UPDATE_RULE",
  SELECT_ALL = "SELECT_ALL",
  UNSELECT_ALL = "UNSELECT_ALL"
}

export interface IntegrationUpdate {
  integrationName: string;
  updateType: IntegrationStateUpdateType;
  ruleIdx: number;
  ruleUpdate?: AptAnswersFilterRuleUpdate;
}

interface UpdateIntegrationStateArgs {
  filterType: AptAnswersFilterType;
  integrationValues: AptAnswersIntegrationsObject[];
  integrationUpdate: IntegrationUpdate;
}

export function updateIntegrationState({
  filterType,
  integrationUpdate,
  integrationValues
}: UpdateIntegrationStateArgs): AptAnswersIntegrationsObject[] {
  const updatedIntegrationValues = [...integrationValues];

  const integration = updatedIntegrationValues.find((integration) => {
    return integration.name === integrationUpdate.integrationName;
  });

  if (!integration || !integration.filter) {
    return integrationValues;
  }

  let integrationFilterRules = integration?.filter
    ? integration.filter.filterRules
    : [];

  const { filterConditions } = getAptAnswersFilterInfo(filterType);

  const { ruleIdx, updateType, ruleUpdate } = integrationUpdate;
  if (updateType === IntegrationStateUpdateType.ADD_RULE) {
    const defaultRule = {
      integration: integration.name,
      fieldsStr: integration.fields[0],
      condition: filterConditions[0],
      value: ""
    };
    integrationFilterRules = [...integrationFilterRules, defaultRule];
  }

  if (updateType === IntegrationStateUpdateType.DELETE_RULE) {
    const filteredRules = integrationFilterRules.filter(
      (_, idx) => idx !== ruleIdx
    );
    integrationFilterRules = [...filteredRules];
  }

  if (updateType === IntegrationStateUpdateType.UPDATE_RULE) {
    if (!ruleUpdate) {
      return integrationValues;
    }
    const { idx, key, value } = ruleUpdate;
    const rule = integrationFilterRules[idx];
    rule[key] = value;
  }

  if (updateType === IntegrationStateUpdateType.SELECT_ALL) {
    integration.filter.manageAllSelected = true;
  }

  if (updateType === IntegrationStateUpdateType.UNSELECT_ALL) {
    integration.filter.manageAllSelected = false;
  }

  integration.filter.filterRules = integrationFilterRules;

  return updatedIntegrationValues;
}
