import classNames from "classnames";
import React from "react";
import { useDarkMode } from "../../hooks/useDarkMode";
import { ReactComponent as MoonIcon } from "./moon.svg";
import { ReactComponent as SunIcon } from "./sun.svg";

interface Props {
  className?: string;
}

function DarkModeToggle(props: Props): React.ReactElement | null {
  const { className } = props;

  const [isUsingDarkMode, setIsUsingDarkMode] = useDarkMode();

  return (
    <div
      className={classNames("dark-mode-toggle", className)}
      style={{ fontSize: 24 }}
      onClick={() => setIsUsingDarkMode(!isUsingDarkMode)}
    >
      {isUsingDarkMode ? <MoonIcon fontSize={20} /> : <SunIcon fontSize={20} />}
    </div>
  );
}

export { DarkModeToggle };
