import AEIcon from "@aptedge/lib-ui/src/components/GMIcon/Icon";
import { ArticleUser } from "@aptedge/lib-ui/src/redux/reduxSlice/kbGenSlice";
import {
  ArticleStatus,
  ArticleStatusLabel
} from "@aptedge/lib-ui/src/types/entities";
import FilterListIcon from "@mui/icons-material/FilterList";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PlagiarismOutlinedIcon from "@mui/icons-material/PlagiarismOutlined";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import classNames from "classnames";
import { FC } from "react";
import { useArticleFilter } from "../../hooks/useArticleFilter";
import { UserSearchSource } from "../../hooks/useArticleSearchUser";
import { ArticleSearchUser } from "../ArticleSearchUser/ArticleSearchUser";
import styles from "./ArticleFilter.module.scss";

export const ARTICLE_STATUS_BUTTON = [
  {
    icon: <TaskOutlinedIcon className={styles.articleStatusIcon} />,
    label: ArticleStatusLabel.PUBLISHED,
    selected: false,
    status: ArticleStatus.PUBLISHED
  },
  {
    icon: <AEIcon name="edit_document" size="1.125rem" />,
    label: ArticleStatusLabel.DRAFT,
    selected: false,
    status: ArticleStatus.DRAFT
  },
  {
    icon: <PlagiarismOutlinedIcon className={styles.articleStatusIcon} />,
    label: ArticleStatusLabel.IN_REVIEW,
    selected: false,
    status: ArticleStatus.IN_REVIEW
  },
  {
    icon: <AEIcon name="scan_delete" size="1.125rem" />,
    label: ArticleStatusLabel.DEPRECATED,
    selected: false,
    status: ArticleStatus.DEPRECATED
  }
];

const ArticleFilter: FC = () => {
  const {
    isOpen,
    dropdownRef,
    tabArticleStatus,
    searchArticle,
    statusBtn,
    articleCreatedUser,
    articleReviewedUser,
    shouldReviewAllBtnVisible,
    handleReviewAll,
    handleFilter,
    handleClearFilter,
    handleArticleStatus,
    handleClosePopup,
    handleUserSearchClear
  } = useArticleFilter();

  return (
    <div className={styles.buttonContainer} ref={dropdownRef}>
      {shouldReviewAllBtnVisible && (
        <div className={styles.reviewArticleButton} onClick={handleReviewAll}>
          <PlayCircleIcon />
          <span data-testid="review-all-btn">
            Review all
            {tabArticleStatus === ArticleStatus.DRAFT ? " drafts" : " requests"}
          </span>
        </div>
      )}
      {tabArticleStatus === ArticleStatus.ALL && (
        <div
          className={classNames(styles.articleFilterButton, {
            [styles.disableBtn]: searchArticle
          })}
          onClick={handleFilter}
          data-testid="article-filters"
        >
          <FilterListIcon />
          <span>Filters</span>
          <KeyboardArrowDownIcon />
        </div>
      )}
      {isOpen && (
        <div
          className={styles.filterOptionContainer}
          data-testid="filter-dropdown"
        >
          <div className={styles.filterHeader}>
            <span>Filters</span>
            <div
              className={styles.filterClear}
              onClick={handleClearFilter}
              data-testid="clear-filter"
            >
              <FilterListOffIcon />
              <span className={styles.heading}>Clear</span>
            </div>
          </div>
          <div>
            <span className={styles.heading}>Status</span>
            <div className={styles.articleStatusButtonContainer}>
              {statusBtn.map(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (button: any, index: number) => (
                  <div
                    className={classNames(styles.articleStatusButton, {
                      [styles.articleStatusSelected]: button.selected
                    })}
                    key={button.status}
                    onClick={() => handleArticleStatus(index)}
                    data-testid="article-status-btn"
                  >
                    {button.icon}
                    <span>{button.label}</span>
                  </div>
                )
              )}
            </div>
          </div>
          <div>
            <div className={styles.userSearchContainer}>
              <span className={styles.heading}>Created by</span>
              <ArticleSearchUser
                userInput={articleCreatedUser}
                handleClosePopup={handleClosePopup}
                articleUser={ArticleUser.CREATE}
                handleUserSearchClear={handleUserSearchClear}
                placeholder="Search Users"
                source={UserSearchSource.ARTICLE_FILTER}
              />
            </div>
            <div className={styles.userSearchContainer}>
              <span className={styles.heading}>Reviewed by</span>
              <ArticleSearchUser
                userInput={articleReviewedUser}
                handleClosePopup={handleClosePopup}
                articleUser={ArticleUser.REVIEW}
                handleUserSearchClear={handleUserSearchClear}
                placeholder="Search Users"
                source={UserSearchSource.ARTICLE_FILTER}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export { ArticleFilter };
