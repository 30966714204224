import classNames from "classnames";
import React from "react";
import "./Dot.scss";

interface Props {
  className?: string;
  backgroundColor?: "primary" | "danger";
  size?: "sm" | "med";
}

const Dot: React.FC<Props> = ({
  className,
  backgroundColor = "primary",
  size = "sm"
}) => {
  return (
    <span
      className={classNames("dot", className, size, backgroundColor)}
      datatest-id="dot"
    />
  );
};

export { Dot };
