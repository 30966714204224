import { ApiError } from "@aptedge/lib-ui/src/utils/ApiError";
import {
  getRelease,
  isProd,
  isProdRelease,
  PROD_ENV,
  DEV_ENV
} from "@aptedge/lib-ui/src/utils/env";
import { logger } from "@aptedge/lib-ui/src/utils/logger";
import * as Sentry from "@sentry/react";
import { NOT_FOUND, UNAUTHORIZED } from "http-status-codes";
import posthog from "posthog-js";
import React, { createContext, useContext, useMemo } from "react";
import { setLogger } from "react-query";
import { SentryDSN } from "../utils/config";

export interface IContext {
  // Pass this down for use in components, hooks, and ErrorBoundaries.
  captureException: typeof Sentry.captureException;
}

const defaultContext: IContext = {
  captureException: captureException
};

const SentryContext = createContext<IContext>(defaultContext);

const SentryProvider: React.FC = ({ children }) => {
  logger.log("Running on release", getRelease());

  const value = useMemo(
    () => ({
      captureException: captureException
    }),
    []
  );

  return (
    <SentryContext.Provider value={value}>{children}</SentryContext.Provider>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function captureException(args: any): any {
  if (isProdRelease()) {
    return Sentry.captureException(args);
  } else {
    return logger.error("would report to Sentry", args);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function captureMessage(args: string): any {
  if (isProdRelease()) {
    return Sentry.captureMessage(args);
  } else {
    logger.log("would send log to Sentry", args);
  }
}

function setupReactQueryLogger(): void {
  setLogger({
    log: (message) => {
      captureMessage(message);
    },
    warn: (message) => {
      captureMessage(message);
    },
    error: (error: ApiError) => {
      if ([UNAUTHORIZED, NOT_FOUND].includes(error.code)) {
        return;
      }

      captureException(error);
    }
  });
}

function setupSentry(dsn: SentryDSN): void {
  // Use Sentry as the React Query logger.
  setupReactQueryLogger();

  // get project id from Sentry DSN
  const sentryProjectId = dsn.slice(dsn.lastIndexOf("/") + 1);
  const posthogIntegration = new posthog.SentryIntegration(
    posthog,
    "aptedgeio",
    Number(sentryProjectId)
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const integrations: Array<any> = [
    posthogIntegration,
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false
    })
  ];

  Sentry.init({
    dsn,
    environment: isProd() ? PROD_ENV : DEV_ENV,
    release: getRelease(),

    integrations,

    // Sentry recommends 1.0 (100%) for this because it's relative to tracesSampleRate. So this
    // will profile 100% of *sampled* traces, not 100% of sessions overall.
    // (https://docs.sentry.io/platforms/javascript/profiling/)
    profilesSampleRate: 1.0,

    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    tracesSampler: (samplingContext) => {
      // always sample our manual spans that we force to be transactions (e.g. search and answers)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((samplingContext.transactionContext as any).forceTransaction) {
        return 1.0;
      }
      return 0.1;
    },
    tracePropagationTargets: ["localhost", /^https?:\/\/[^/]+\.aptedge.io.*/]
  });
}

const useSentry = (): IContext => useContext(SentryContext);

export { SentryProvider, useSentry, setupReactQueryLogger, setupSentry };
