import SearchFeedback, {
  FeedbackSource
} from "@aptedge/lib-ui/src/components/SearchFeedback/SearchFeedback";
import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import { useAppSelector } from "@aptedge/lib-ui/src/redux/hook/hook";
import { FC, memo, useEffect, useRef } from "react";
import AnswerPreview from "../../../components/AnswerSearchResult/AnswerPreview/AnswerPreview";
import PreviewDetails from "./PreviewDetails";
import Header from "./SearchPreviewCardHeader";
import Title from "./SearchPreviewCardTitle";
import "./searchResultPreview.scss";

type SearchResultPreviewCardProps = {
  showFeedback: boolean;
};

const handleScroll = (containerRef: React.RefObject<HTMLDivElement>): void => {
  if (containerRef.current) {
    containerRef.current.scrollTo({ top: 0, behavior: "auto" });
    const highlightedText = containerRef.current.querySelector(
      ".offset-highlight"
    );
    if (highlightedText) {
      const rect = highlightedText.getBoundingClientRect();
      const containerRect = containerRef.current.getBoundingClientRect();

      const isInView =
        rect.top >= containerRect.top &&
        rect.bottom <= containerRect.bottom &&
        rect.left >= containerRect.left &&
        rect.right <= containerRect.right;

      if (!isInView) {
        // Scroll only the right section container
        const highlightedElement = highlightedText as HTMLElement;
        const scrollTop =
          highlightedElement.offsetTop -
          containerRef.current.offsetTop -
          containerRef.current.clientHeight / 2 +
          highlightedElement.clientHeight / 2;

        containerRef.current.scrollTo({
          top: scrollTop,
          behavior: "smooth"
        });
      }
    }
  }
};

const SearchResultPreviewCard: FC<SearchResultPreviewCardProps> = memo(
  ({ showFeedback }) => {
    const { flags } = useFlags();
    const { searchCardVisibility, selectedSearchResult } = useAppSelector(
      (state) => state.search
    );

    const { answerCardVisibility } = useAppSelector((state) => state.answerGPT);

    const containerRef = useRef<HTMLDivElement>(null);
    const isPreviewable = searchCardVisibility && !!selectedSearchResult?.id;

    useEffect(() => {
      handleScroll(containerRef);
    }, [containerRef, selectedSearchResult]);

    if (!isPreviewable && !answerCardVisibility) return <></>;

    return (
      <>
        {isPreviewable && (
          <div className="global-search-page-preview">
            <div ref={containerRef} className="preview-content">
              <Header />
              <Title />
              <PreviewDetails />
            </div>
            {showFeedback && (
              <SearchFeedback
                showFeedback={showFeedback}
                feedbackOptions={flags.feedbackOptions.searchFeedbackLabels}
                eventSource={FeedbackSource.SEARCH}
                key={selectedSearchResult.id}
                feedbackId={String(selectedSearchResult.id)}
              />
            )}
          </div>
        )}
        {answerCardVisibility && <AnswerPreview showFeedback={showFeedback} />}
      </>
    );
  }
);
export default SearchResultPreviewCard;
