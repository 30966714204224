import { IEdgeInfo } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";
import { post } from "../utils/buildRequest";

async function addEdgeTicket({
  edgeId,
  ticketId
}: {
  edgeId: number;
  ticketId: number;
}): Promise<IEdgeInfo> {
  const url = `${getBackendApiUrl()}/edges/${edgeId}/examples/?tickets-by-id=${ticketId}`;

  return request(url, post({}));
}

export { addEdgeTicket };
