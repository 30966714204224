import { Select } from "@aptedge/lib-ui/src/components/Select/Select";
import React from "react";
import { useAuth } from "../../context/AuthContext";
import { useUsers } from "../../hooks/useUsers";

interface Props {
  value?: number;
  placeholder?: string;
  clearRowLabel?: string;
  className?: string;
  isError?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  onChange: (val: number | null) => void;
}

function UserSelect(props: Props): React.ReactElement {
  const {
    value,
    placeholder = "Unassigned",
    clearRowLabel,
    className,
    isLoading,
    isDisabled,
    isError,
    onChange
  } = props;

  const { user } = useAuth();
  const { data, isLoading: usersAreLoading } = useUsers();
  const users = data || [];

  const currentUser = users.find((u) => u.id === user?.id);
  const selected = users.find((u) => !!value && value === u.id);
  const sortedUsers = users
    .filter((u) => u.id !== user?.id && !u.banned)
    .sort((a, b) => a.email.localeCompare(b.email));

  if (currentUser) {
    sortedUsers.unshift(currentUser);
  }

  return (
    <Select
      data={sortedUsers}
      placeholder={placeholder}
      className={className}
      getOptionLabel={(a) =>
        a.id === user?.id ? `${user.email} (Assign to me)` : a.email
      }
      getDisplayValue={(a) => a.email}
      clearRowLabel={clearRowLabel}
      value={selected}
      onChange={(user) => onChange(user?.id || null)}
      error={isError ? "Something went wrong." : undefined}
      isDisabled={isDisabled}
      isLoading={isLoading || usersAreLoading}
      isClearable
      isSearchable
    />
  );
}

export { UserSelect };
