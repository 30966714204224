import { IFeature, IProduct } from "@aptedge/lib-ui/src/types/entities";
import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { WebCacheKey } from "../../clients/cache";
import { fetchProducts } from "../../clients/Products/fetchProducts";

function useProducts(
  options?: UseQueryOptions<IProduct[]>
): UseQueryResult<IProduct[]> {
  const opts: UseQueryOptions<IProduct[]> = {
    ...options,
    refetchOnWindowFocus: false,
    refetchOnMount: false
  };
  return useQuery([WebCacheKey.PRODUCTS], () => fetchProducts(), opts);
}

function useFeatures(): UseQueryResult<IFeature[]> {
  const productsQuery = useProducts();
  const features = productsQuery.data?.flatMap((p) => p.features);

  return {
    ...productsQuery,
    data: features
  } as UseQueryResult<IFeature[]>;
}

export { useFeatures, useProducts };
