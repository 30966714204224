import { IEdgeAuditLogStatusChange } from "@aptedge/lib-ui/src/types/entities";
import React from "react";
import { ArrowTransition } from "./ArrowTransition";
import { SummarySentence } from "./SummarySentence";
import { WithAuditLog } from "./WithAuditLog";
import type { AuditDetailsFC, AuditSummaryFC } from "./WithAuditLog";

const Details: AuditDetailsFC<IEdgeAuditLogStatusChange> = (props) => {
  const { log } = props;
  const { previous, current } = log.data;

  return (
    <div className="audit-log-status-change">
      <ArrowTransition
        from={<span>{previous}</span>}
        to={<span>{current}</span>}
      />
    </div>
  );
};

const Summary: AuditSummaryFC<IEdgeAuditLogStatusChange> = (props) => {
  const { author, log } = props;
  const { VERBS, OBJECTS } = SummarySentence;

  return (
    <SummarySentence
      subject={author}
      verb={VERBS.UPDATED}
      object={OBJECTS.STATUS}
      displayText={log?.displayText ?? ""}
    />
  );
};

Details.displayName = "AuditStatusChangeDetails";
const AuditLogStatusChange = WithAuditLog(Details, Summary);

export { AuditLogStatusChange };
