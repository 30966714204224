import { IEdgeInfo } from "@aptedge/lib-ui/src/types/entities";
import React from "react";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { StyledLink } from "../../components/StyledLink/StyledLink";
import { Routes } from "../../routes/routes";
import "./EdgeBreadcrumbs.scss";

function EdgeBreadcrumbs(props: { edge: IEdgeInfo }): JSX.Element {
  const { edge } = props;
  return (
    <div className="edge-breadcrumbs">
      <Breadcrumbs>
        <StyledLink to={Routes.EDGES}>Edges</StyledLink>
        <span>{`EDGE-${edge.id}`}</span>
      </Breadcrumbs>
    </div>
  );
}

export { EdgeBreadcrumbs };
