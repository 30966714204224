import classNames from "classnames";
import React, { forwardRef, PropsWithChildren } from "react";
import { Footer } from "../Footer/Footer";
import "./PageContent.scss";

const PageContent = forwardRef<
  HTMLDivElement,
  PropsWithChildren<{ className?: string }>
>(({ className, children }, ref) => {
  return (
    <div className={classNames("page-content", className)}>
      <div className="content" ref={ref}>
        {children}
      </div>
      <Footer />
    </div>
  );
});

PageContent.displayName = "PageContent";

export { PageContent };
