import { ArticleTicketInfo } from "@aptedge/lib-ui/src/types/entities";
import * as React from "react";
import styles from "./TicketsTable.module.scss";
import { TicketsTableHeader } from "./TicketsTableHeader";
import { TicketsTableItem } from "./TicketsTableItem";
interface TicketsTableProps {
  tickets: ArticleTicketInfo[];
}

const TicketsTable: React.FC<TicketsTableProps> = ({ tickets }) => {
  return (
    <div className={styles.container}>
      <TicketsTableHeader />
      {tickets.map((ticket, idx) => {
        const { ticket_id: ticketId } = ticket.id;
        return (
          <TicketsTableItem
            key={`${ticketId}_${idx}`}
            ticket={ticket}
            idx={idx}
          />
        );
      })}
    </div>
  );
};

export { TicketsTable };
