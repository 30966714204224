import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";
import { post } from "../utils/buildRequest";

async function resetPassword(args: {
  email: string;
}): Promise<{ success?: boolean }> {
  const url = `${getBackendApiUrl()}/password-reset`;

  const options = post(args);

  return request(url, options);
}

export { resetPassword };
