import { IEdgeInfo } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";

async function fetchEdgesById(ids: number[]): Promise<IEdgeInfo[]> {
  const promises: Promise<IEdgeInfo>[] = [];

  ids.forEach((id) => {
    const url = `${getBackendApiUrl()}/edges/${id}/`;

    const promise = request(url);
    promises.push(promise);
  });

  return Promise.all(promises);
}

export { fetchEdgesById };
