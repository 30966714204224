import classNames from "classnames";
import React from "react";
import { SentimentScore } from "../SentimentScore/SentimentScore";
import "./SentimentBar.scss";

interface Props {
  score: number;
  className?: string;
}

const SentimentBar: React.FC<Props> = ({ score, className }) => {
  const scorePct = (score - 50) * 2;

  return (
    <div className={classNames("sentiment-bar", className)}>
      <div className="bar-container">
        <div className="bar">
          <SentimentScore style={{ left: `${scorePct}%` }} score={score} />
        </div>
      </div>
      <div className="d-flex labels mt-3">
        <div className="d-flex flex-column w-50">
          <div className="label">MILD</div>
        </div>
        <div className="d-flex flex-column w-50 text-right">
          <div className="label">HIGH</div>
        </div>
      </div>
    </div>
  );
};

export { SentimentBar };
