import { ITicketUser } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";

function fetchTicketUsers(): Promise<ITicketUser[]> {
  const url = `${getBackendApiUrl()}/tickets/users/`;

  return request(url);
}

export { fetchTicketUsers };
