import { IEdgeInfo, ITicketListing } from "@aptedge/lib-ui/src/types/entities";
import React, { useState } from "react";
import { TicketModal } from "../../../components/TicketModal/TicketModal";
import { EdgeTicketResult } from "./EdgeTicketResult";
import { EdgeTicketsListContainer } from "./EdgeTicketsListContainer";

interface Props {
  edge: IEdgeInfo;
  results: ITicketListing[];
  onUpdate: (value: IEdgeInfo) => void;
}

function EdgeTicketResults(props: Props): React.ReactElement {
  const { edge, results, onUpdate } = props;

  const [ticketToPreview, setTicketToPreview] = useState<number>();

  return (
    <EdgeTicketsListContainer>
      {results.map((r) => (
        <EdgeTicketResult
          edgeId={edge.id}
          key={r.id}
          ticket={r}
          onUpdate={onUpdate}
          onPreview={(ticket) => setTicketToPreview(ticket.id)}
        />
      ))}
      <TicketModal
        ticketId={ticketToPreview}
        onClose={() => setTicketToPreview(undefined)}
        isOpen={!!ticketToPreview}
        isDisabled
      />
    </EdgeTicketsListContainer>
  );
}

export { EdgeTicketResults };
