import { FilterWrapper } from "@aptedge/lib-ui/src/components/Filter/FilterWrapper";
import {
  IQueryFilter,
  IQueryFilterDefinition
} from "@aptedge/lib-ui/src/components/Filter/types";
import { MultiSelect } from "@aptedge/lib-ui/src/components/MultiSelect/MultiSelect";
import React from "react";
import { useUsers } from "../../hooks/useUsers";
import { ASSIGNEE_FILTER, CREATED_BY_FILTER } from "./data";

type Props = {
  value?: IQueryFilter;
  onChange: (val: IQueryFilter) => void;
};

function WithUserFilter(
  spec: IQueryFilterDefinition
): (props: Props) => React.ReactElement {
  function Component(props: Props): React.ReactElement {
    const { data, isLoading } = useUsers();
    const testId = spec.label.toLowerCase().split(" ").join("-");
    const users = data || [];

    return (
      <FilterWrapper {...spec} {...props}>
        {({ values, onChange }) => {
          const selected = users.filter(
            (u) => !!values && values.includes(String(u.id))
          );
          return (
            <MultiSelect
              name={`user-filter-${testId}`}
              value={selected}
              data={users}
              getOptionLabel={(opt) => opt.email}
              onChange={(users) => onChange(users.map((u) => String(u.id)))}
              isLoading={isLoading}
            />
          );
        }}
      </FilterWrapper>
    );
  }

  Component.displayName = `WithUserFilter-${spec.label}`;

  return Component;
}

const AssigneeFilter = WithUserFilter(ASSIGNEE_FILTER);
const CreatedByFilter = WithUserFilter(CREATED_BY_FILTER);

export { AssigneeFilter, CreatedByFilter };
