import {
  ICreateConfluenceCloudArticleSourceRequest,
  IConfluenceCloudArticleSource
} from "@aptedge/lib-ui/src/types/entities";
import { getBackendUrl } from "../../config";
import { request } from "../../utils/request";
import { post } from "../utils/buildRequest";

async function createConfluenceCloudArticleSource(
  args: ICreateConfluenceCloudArticleSourceRequest
): Promise<IConfluenceCloudArticleSource> {
  const url = `${getBackendUrl()}/confluence/collect/`;

  const options: RequestInit = post(args);

  const req = request(url, options);
  return req;
}

export { createConfluenceCloudArticleSource };
