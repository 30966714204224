import {
  ILabelSetting,
  IPhraseSetting,
  ITheme,
  LabelEntity,
  LabelEntityType
} from "@aptedge/lib-ui/src/types/entities";
import { useProducts } from "../../../hooks/clients/useProducts";
import { useThemes } from "../../../hooks/clients/useThemes";

type Settings = {
  labels: ILabelSetting[];
  phrases: IPhraseSetting[];
  commonThemes: ITheme[];
};

function useMLSettings(): Settings {
  const productsQuery = useProducts();
  const themesQuery = useThemes();

  const labels: ILabelSetting[] = [];
  const phrases: IPhraseSetting[] = [];

  productsQuery.data?.forEach((p) => {
    const label = {
      id: p.id,
      name: p.name,
      rule: p.rule,
      type: LabelEntity.PRODUCT as LabelEntityType
    };

    p.rule?.phrases.forEach((pPhrase) => {
      phrases.push({ label, phrase: pPhrase });
    });

    const labelChildren: ILabelSetting[] = [];

    p.features.forEach((f) => {
      const featLabel = {
        id: f.id,
        name: f.name,
        type: LabelEntity.FEATURE as LabelEntityType,
        rule: f.rule,
        children: null
      };

      f.rule?.phrases.forEach((fPhrase) => {
        phrases.push({ label: featLabel, phrase: fPhrase });
      });

      labelChildren.push(featLabel);
    });

    labels.push({
      ...label,
      children: labelChildren.sort((a, b) => a.name.localeCompare(b.name))
    });
  });

  themesQuery.data
    ?.filter((t) => !t.common)
    .forEach((t) => {
      const label = {
        id: t.id,
        name: t.name,
        type: LabelEntity.THEME as LabelEntityType,
        rule: t.rule,
        children: null
      };

      t.rule?.phrases.forEach((tPhrase) => {
        phrases.push({ label, phrase: tPhrase });
      });

      labels.push(label);
    });

  const commonThemes =
    themesQuery.data
      ?.filter((t) => !!t.common)
      .sort((a, b) => a.name.localeCompare(b.name)) || [];

  return { labels, phrases, commonThemes };
}

export { useMLSettings };
