import { AeIcons } from "@aptedge/lib-ui/src/components/Icon/AeIcons";
import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import { MarkdownContent } from "@aptedge/lib-ui/src/components/MarkdownContent/MarkdownContent";
import { Tooltip } from "@aptedge/lib-ui/src/components/Tooltip/Tooltip";
import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import { useSearchPreview } from "@aptedge/lib-ui/src/hooks/useSearchPreview";
import {
  ITicketListing,
  EntityType,
  ICompositeResult
} from "@aptedge/lib-ui/src/types/entities";
import classNames from "classnames";
import React, { useContext, useMemo } from "react";
import { EdgeStateContext } from "../../context/EdgeContext";
import { TooltipMessage } from "../../registry/TooltipMessage";
import { displayAge } from "../../utils/time";
import { LabelChip } from "../LabelChip/LabelChip";
import { LabelChipList } from "../LabelChip/LabelChipList";
import SearchCardTimings from "../SearchCardTimings/SearchCardTimings";
import SearchResultCardActionIcons from "../SearchResultCardActionIcons/SearchResultCardActionIcons";
import { SearchResultPreview } from "../SearchResultPreview/SearchResultPreview";
import { useLabelList } from "../Table/useLabelList";
import WithLoading from "../WithLoading/WithLoading";
import { LinkedBadge } from "./LinkedBadge";
import "./TicketItem.scss";

interface Props {
  className?: string;
  ticket: ITicketListing;
  showActionIcons?: boolean;
  actions?: React.ReactElement;
  disabled?: boolean;
  isLoading?: boolean;
  onSelect?: (ticket: ITicketListing) => void;
}

const TicketItem: React.FC<Props> = ({
  className,
  ticket,
  showActionIcons,
  actions,
  disabled,
  onSelect,
  isLoading = false
}) => {
  const context = useContext(EdgeStateContext);
  const { flags } = useFlags();

  const { hasPreview, previewSnippet, previewType } = useSearchPreview({
    ...ticket,
    type: "ticket"
  });

  const labels = [
    ...useMemo(
      () => ticket.products.map((p) => ({ ...p, type: EntityType.PRODUCT })),
      [ticket.products]
    ),
    ...useMemo(
      () => ticket.features.map((f) => ({ ...f, type: EntityType.FEATURE })),
      [ticket.features]
    ),
    ...useMemo(
      () => ticket.themes.map((t) => ({ ...t, type: EntityType.THEME })),
      [ticket.themes]
    )
  ];

  const { ref, labels: displayedLabels, showMore } = useLabelList({ labels });
  const edge = context?.state?.edge;

  return (
    <div
      className={classNames("ticket-item", className, {
        disabled,
        clickable: !!onSelect
      })}
    >
      <div className={classNames("heading", className)}>
        <h4 onClick={!!onSelect ? () => onSelect(ticket) : undefined}>
          <AeIcons.Tickets className="pl-0" />
          <span className="id">#{ticket.displayId}</span>
          <span className={classNames("subject")}>
            <SearchResultPreview
              previewSnippet={
                hasPreview && previewType === "subject"
                  ? previewSnippet
                  : ticket.subject
              }
            />
          </span>
          {ticket?.customer?.name && (
            <div className="customer">
              <AeIcons.Customer />
              <span>{ticket?.customer?.name}</span>
            </div>
          )}
        </h4>

        {showActionIcons && (
          <SearchResultCardActionIcons
            searchResult={ticket as ICompositeResult}
          />
        )}

        <WithLoading isLoading={isLoading}>
          {!disabled && !!actions && <div>{actions}</div>}
        </WithLoading>
      </div>
      <div className="labels" ref={ref}>
        <LabelChipList>
          {displayedLabels.map((l) => (
            <LabelChip key={l.name} label={l} />
          ))}
          {showMore && (
            <LabelChip
              label={{ id: -1, name: "...", type: EntityType.THEME }}
            />
          )}
        </LabelChipList>
      </div>
      <div className="ticket-summary">
        {hasPreview && previewType === "description" ? (
          <div className="mt-2 description">
            <SearchResultPreview previewSnippet={previewSnippet} />
          </div>
        ) : (
          <MarkdownContent className="mt-2" content={ticket.summary?.content} />
        )}
      </div>
      <div className="ticket-info">
        <div className="left-side">
          {ticket.frustration && flags.sentimentScore && (
            <span className="sentiment">
              <Tooltip content={TooltipMessage.FRUSTRATION_SCORE}>
                <Icons.Angry />
                <span>{ticket.frustration.score}</span>
              </Tooltip>
            </span>
          )}
          <span className="status">{ticket.status}</span>
        </div>
        <div>
          <div className="dates">
            <SearchCardTimings
              createdOn={ticket.createdOn}
              updatedOn={ticket.updatedOn}
            />
            <div>
              {!!edge && (
                <>
                  <LinkedBadge ticket={ticket} edgeId={edge.id} />
                  {`Updated ${displayAge(ticket.updatedOn, undefined, true)}`}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { TicketItem };
