import { ISharepointDriveSiteUpdateSource } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";
import { patch } from "../utils/buildRequest";

async function updateSharepointDriveSites({
  sites,
  tenantId
}: {
  sites: string[];
  tenantId: string;
}): Promise<ISharepointDriveSiteUpdateSource> {
  const url = `${getBackendApiUrl()}/integrations/sharepoint-drive`;
  const options = patch({ sites, tenantId });
  return request(url, options).then((response) => response);
}

export { updateSharepointDriveSites };
