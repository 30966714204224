import React from "react";
import sanitizeHtml from "sanitize-html";
import "./SearchResultPreview.scss";

interface Props {
  previewSnippet: string | null;
}

const sanitizeConfig = {
  allowedAttributes: {
    "*": ["class"]
  }
};

const SearchResultPreview: React.FC<Props> = ({ previewSnippet }) => {
  if (previewSnippet === null) {
    return null;
  }

  const cleanPreviewSnippet = sanitizeHtml(previewSnippet, sanitizeConfig);
  const updatedPreviewSnippet = (cleanPreviewSnippet as string)
    ?.replace(/\\n/g, "")
    ?.replace(/\\t/g, " ");

  return (
    <>
      <span
        className={"search-result-preview-text"}
        dangerouslySetInnerHTML={{
          __html: updatedPreviewSnippet
        }}
      />
    </>
  );
};

export { SearchResultPreview };
