import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import classNames from "classnames";
import { FC } from "react";
import Helmet from "react-helmet";
import { useArticlePage } from "../../hooks/useArticlePage";
import styles from "./ArticlesPage.module.scss";
import { ArticlesTable } from "./ArticlesTable";
import { EmptyState } from "./EmptyState";
import { ErrorMessage } from "./ErrorMessage";
import { Header } from "./Header";
import { Tabs } from "./Tabs";

const ArticlesPage: FC = () => {
  const {
    flags,
    isLoading,
    isError,
    showEmptyState,
    showTicketsTable,
    articles
  } = useArticlePage();

  return (
    <>
      <Helmet>
        <title>Articles | Saved Articles</title>
      </Helmet>
      <div className={styles.container}>
        <Header />
        {!flags.kbGenPublishProcess && <Tabs currentTab="savedArticles" />}
        {isLoading && <Spinner />}
        {isError && <ErrorMessage />}
        {showEmptyState && <EmptyState />}
        {showTicketsTable && (
          <div
            className={classNames({
              [styles.articlesTableContainer]: !flags.kbGenPublishProcess
            })}
          >
            <ArticlesTable articles={articles || []} />
          </div>
        )}
      </div>
    </>
  );
};

export { ArticlesPage };
