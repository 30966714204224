import { FilterWrapper } from "@aptedge/lib-ui/src/components/Filter/FilterWrapper";
import { IQueryFilter } from "@aptedge/lib-ui/src/components/Filter/types";
import { MultiSelect } from "@aptedge/lib-ui/src/components/MultiSelect/MultiSelect";
import React from "react";
import { EDGE_STATUS_LIST } from "../../registry/status";
import { STATUS_FILTER } from "./data";

type Props = {
  value?: IQueryFilter;
  onChange: (val: IQueryFilter) => void;
};

function EdgeStatusFilter(props: Props): React.ReactElement {
  return (
    <FilterWrapper {...STATUS_FILTER} {...props}>
      {({ values, onChange }) => (
        <MultiSelect
          name="status-filter"
          value={values}
          data={EDGE_STATUS_LIST as string[]}
          getOptionLabel={(status) => status}
          onChange={(statuses) => onChange(statuses)}
        />
      )}
    </FilterWrapper>
  );
}

export { EdgeStatusFilter };
