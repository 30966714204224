import classNames from "classnames";
import React from "react";
import { Link, LinkProps } from "react-router-dom";
import "./StyledLink.scss";

interface Props extends LinkProps {
  className?: string;
  disabled?: boolean;
}

const StyledLink: React.FC<Props> = ({
  children,
  className,
  disabled,
  ...rest
}) => {
  return (
    <Link
      className={classNames("link styled", className, { disabled })}
      {...rest}
    >
      {children}
    </Link>
  );
};

export { StyledLink };
