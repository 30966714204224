import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import useModal from "@aptedge/lib-ui/src/hooks/useModal/useModal";
import {
  ArticleStatus,
  GeneratedKnowledgeResult
} from "@aptedge/lib-ui/src/types/entities";
import { GTM_EVENTS, dataLayerPush } from "@aptedge/lib-ui/src/utils/event";
import { isEmpty } from "lodash";
import * as React from "react";
import { useMutation, useQueryClient } from "react-query";
import { WebCacheKey } from "../../clients/cache";
import { deleteArticle } from "../../clients/GeneratedKnowledge/deleteArticle";
import { editArticle } from "../../clients/GeneratedKnowledge/editArticle";
import { EditArticleModal } from "../ArticlesPage/EditArticleModal";
import styles from "./ArticleItem.module.scss";
import { ArticleMarkdownDisplay } from "./ArticleMarkdownDisplay";

interface ArticleItemProps {
  idx: number;
  article: GeneratedKnowledgeResult;
  articlesLength: number;
}

interface EditArticleArgs {
  title: string;
  body: string;
}

const triggerEvent = (
  eventName: string,
  ticketId: string | number,
  articleId: string,
  articleTitle: string,
  articleBody: string
): void => {
  dataLayerPush({
    event: eventName,
    data: {
      article_id: articleId,
      ticket_id: ticketId,
      article_title: articleTitle,
      article_body: articleBody,
      article_status: ArticleStatus.DRAFT
    }
  });
};

const ArticleItem: React.FC<ArticleItemProps> = ({
  idx,
  article,
  articlesLength
}) => {
  const { modalVisibility, closeModal, modalRef } = useModal({});
  const queryClient = useQueryClient();
  const articleContent = `# ${article?.title}\n\n${article?.body.body}`;
  const ticketId = article.ticketIds[0]?.ticket_id || "";

  const deleteArticleMutation = useMutation(deleteArticle, {
    onSuccess: () => {
      queryClient.invalidateQueries([
        WebCacheKey.GENERATED_KNOWLEDGE_PUBLISHED
      ]);
      queryClient.invalidateQueries([WebCacheKey.GENERATED_KNOWLEDGE_ARTICLE]);
    }
  });

  const editArticleMutation = useMutation(editArticle, {
    onSuccess: () => {
      closeModal(false);
      queryClient.invalidateQueries([
        WebCacheKey.GENERATED_KNOWLEDGE_PUBLISHED
      ]);
      queryClient.invalidateQueries([WebCacheKey.GENERATED_KNOWLEDGE_ARTICLE]);
    }
  });

  const handleEditArticleOpen = (): void => {
    closeModal(true);
  };

  const handleCloseModal = (): void => {
    closeModal(false);
  };

  const handleEditArticle = ({ title, body }: EditArticleArgs): void => {
    const isTitleChanged = title !== article?.title && !isEmpty(title);
    const isBodyChanged = body !== article?.body?.body && !isEmpty(body);
    editArticleMutation.mutate({
      articleId: article.id,
      title: isTitleChanged ? title : undefined,
      body: isBodyChanged ? body : undefined
    });
  };

  const handleSaveArticle = (): void => {
    if (article) {
      editArticleMutation.mutate({
        articleId: article.id,
        title: article?.title,
        body: article?.body.body,
        status: ArticleStatus.PUBLISHED
      });
      triggerEvent(
        GTM_EVENTS.GTM_SAVE_KB_GEN_ARTICLE,
        ticketId,
        article.id,
        article.title,
        article.body.body
      );
    }
  };

  const handleDeleteArticle = (): void => {
    deleteArticleMutation.mutate({ articleId: article.id });
    triggerEvent(
      GTM_EVENTS.GTM_DISCARD_KB_GEN_ARTICLE,
      ticketId,
      article.id,
      article?.title || "",
      article?.body.body || ""
    );
  };

  return (
    <div className={styles.container}>
      <p className={styles.articleCount}>{`Article ${
        idx + 1
      } of ${articlesLength}`}</p>
      <div className={styles.articleCard}>
        <div className={styles.articleContent}>
          {article && <ArticleMarkdownDisplay articleBody={articleContent} />}
        </div>
        <div className={styles.articleActions}>
          <button onClick={handleEditArticleOpen} className={styles.editButton}>
            <Icons.Edit />
            Edit
          </button>
          <button
            onClick={handleDeleteArticle}
            className={styles.discardButton}
          >
            <Icons.Trash />
            Discard
          </button>
          <button
            onClick={handleSaveArticle}
            className={styles.saveArticleButton}
          >
            <Icons.Save />
            Save Article
          </button>
        </div>
      </div>
      {modalVisibility && article && (
        <EditArticleModal
          ref={modalRef}
          articleId={article.id}
          handleClose={handleCloseModal}
          handleSave={handleEditArticle}
        />
      )}
    </div>
  );
};

export { ArticleItem };
