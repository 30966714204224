import {
  ICreateOneloginSourceRequest,
  IOneloginSource
} from "@aptedge/lib-ui/src/types/entities";
import { getBackendUrl } from "../../config";
import { request } from "../../utils/request";
import { post } from "../utils/buildRequest";

async function createOneloginSource(
  args: ICreateOneloginSourceRequest
): Promise<IOneloginSource> {
  const url = `${getBackendUrl()}/onelogin/collect`;
  const options: RequestInit = post(args);
  return request(url, options);
}

export { createOneloginSource };
