import { AptAnswersFilterPurpose } from "@aptedge/lib-ui/src/types/entities";
import * as React from "react";
import styles from "./FilterDescription.module.scss";

interface FilterDescriptionProps {
  filterPurpose: AptAnswersFilterPurpose;
  integrationName: string;
}

const FilterDescription: React.FC<FilterDescriptionProps> = ({
  filterPurpose,
  integrationName
}) => {
  const description =
    filterPurpose === AptAnswersFilterPurpose.HIDE
      ? `${integrationName} results are hidden when they meet any of these rules`
      : `Only show ${integrationName} results that meet all of these rules`;
  return <p className={styles.description}>{description}</p>;
};

export { FilterDescription };
