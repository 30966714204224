import {
  AptAnswersFilterPurpose,
  AptAnswersFilterRule,
  AptAnswersIntegrationsObject
} from "@aptedge/lib-ui/src/types/entities";

interface GetFilterRulesForPayloadArgs {
  filterPurpose: AptAnswersFilterPurpose;
  integration: AptAnswersIntegrationsObject;
}

export function getFilterRulesForPayload({
  filterPurpose,
  integration
}: GetFilterRulesForPayloadArgs): AptAnswersFilterRule[] {
  if (!integration.filter) {
    return [];
  }

  if (integration.filter.manageAllSelected) {
    return [
      {
        condition:
          filterPurpose === AptAnswersFilterPurpose.SHOW
            ? "show-everything"
            : "hide-everything",
        fieldsStr: "all-fields",
        integration: integration.name,
        value: ""
      }
    ];
  }

  return integration.filter.filterRules.filter((filterRule) =>
    filterRule.value.trim()
  );
}
