import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { Favicon } from "@aptedge/lib-ui/src/components/Favicon/Favicon";
import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import {
  NoData,
  NoDataMessage
} from "@aptedge/lib-ui/src/components/NoData/NoData";
import { IEdgeInfo, IEdgeLink } from "@aptedge/lib-ui/src/types/entities";
import { GTM_EVENTS, dataLayerPush } from "@aptedge/lib-ui/src/utils/event";
import { sanitizeUrl } from "@aptedge/lib-ui/src/utils/sanitizeUrl";
import React from "react";
import { useMutation } from "react-query";
import { updateEdge } from "../../../clients/Edges/updateEdge";
import { convertQueryStateToClientStatus } from "../../../clients/utils/convertQueryStateToClientStatus";
import { ConfirmationModal } from "../../../components/ConfirmationModal/ConfirmationModal";
import { AddEdgeLink } from "./AddEdgeLink";
import "./EdgeLinkWidget.scss";

type Link = Omit<IEdgeLink, "urlReference">;

interface Props {
  edge: IEdgeInfo;
  showAddLink: boolean;
  onCloseAddLink: () => void;
  onUpdate: (newEdge: IEdgeInfo) => void;
}

const EdgeLinkWidget: React.FC<Props> = (props) => {
  const { edge, showAddLink, onUpdate, onCloseAddLink } = props;

  const [linkToDelete, setLinkToDelete] = React.useState<Link>();
  const isDisabled = edge.archived;

  const addEdgeLink = useMutation(updateEdge, {
    onSuccess: (edge) => {
      dataLayerPush({ event: GTM_EVENTS.GTM_CREATE_EDGE_LINK });
      onUpdate(edge);
      onCloseAddLink();
    }
  });

  const removeEdgeLink = useMutation(updateEdge, {
    onSuccess: (edge) => {
      onUpdate(edge);
      setLinkToDelete(undefined);
    }
  });

  const handleAddLink = (link: Link): void => {
    const links = edge.links.map((l) => ({ url: l.url, label: l.label }));
    addEdgeLink.mutate({
      edgeId: edge.id,
      patchContent: { links: [...links, link] }
    });
  };

  const handleClickDelete = (link: Link): void => {
    removeEdgeLink.reset();
    setLinkToDelete(link);
  };

  const handleCancelDelete = (): void => {
    setLinkToDelete(undefined);
  };

  const handleConfirmDelete = (): void => {
    if (!linkToDelete) {
      return;
    }

    const links = edge.links
      .filter((l) => l.url !== linkToDelete.url)
      .map((l) => ({ url: l.url, label: l.label }));
    removeEdgeLink.mutate({ edgeId: edge.id, patchContent: { links } });
  };

  return (
    <div className="edge-link-widget">
      <small>Weblinks</small>
      {!!edge.links.length ? (
        <ul className="list-group mb-3">
          {edge.links.map((link, key) => {
            const santizedUrl = sanitizeUrl(link.url);
            return (
              <li
                key={`${key}-${link}`}
                className="list-group-item d-flex align-items-center justify-content-between"
              >
                <div>
                  <Favicon linkSrc={santizedUrl} />
                  <span className="ml-3">{link.label || link.url}</span>
                </div>
                <div className="icons">
                  <span className="icon-btn">
                    <a
                      href={santizedUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Icons.ExternalLink />
                    </a>
                  </span>
                  {!isDisabled && (
                    <span
                      className="icon-btn delete"
                      data-testid={`remove-link-${key}`}
                      onClick={() => handleClickDelete(link)}
                    >
                      <Icons.Trash />
                    </span>
                  )}
                </div>
              </li>
            );
          })}
        </ul>
      ) : (
        <NoData message={NoDataMessage.EDGE_LINKS} />
      )}
      {showAddLink && !isDisabled && (
        <AddEdgeLink
          edge={edge}
          onAdd={handleAddLink}
          onCancel={onCloseAddLink}
        />
      )}
      <ConfirmationModal
        title="Delete this link?"
        loadingTitle="Deleting link..."
        status={convertQueryStateToClientStatus(removeEdgeLink)}
        isOpen={!!linkToDelete && !isDisabled}
        onClose={() => setLinkToDelete(undefined)}
      >
        <p>You can add the link later again if you need to.</p>
        <div className="mt-3 d-flex justify-content-end">
          <Button
            className="mr-2"
            color="secondary"
            onClick={() => handleCancelDelete()}
          >
            Cancel
          </Button>
          <Button color="danger" onClick={() => handleConfirmDelete()}>
            Delete
          </Button>
        </div>
      </ConfirmationModal>
    </div>
  );
};

export { EdgeLinkWidget };
