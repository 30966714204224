import { IEdgeFile } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";

async function fetchEdgeFiles(edgeId: number): Promise<IEdgeFile[]> {
  const url = `${getBackendApiUrl()}/edges/${edgeId}/files`;

  return request<IEdgeFile[]>(url);
}

export { fetchEdgeFiles };
