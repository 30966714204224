import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import { useAppSelector } from "@aptedge/lib-ui/src/redux/hook/hook";
import { FC } from "react";
import { ToastType } from "react-toastify";
import { Toast } from "../../../../components/Toast/Toast";
import WithLoading from "../../../../components/WithLoading/WithLoading";
import { MSTeamsAddChannel } from "./MSTeamsAddChannel";
import "./MSTeamsListConfig.scss";
import { MSTeamsList } from "./MSTeamsList";
import { useMSTeamsListConfig } from "./useMSTeamsListConfig";

interface Props {
  getDatasource: () => void;
}

const MSTeamsListConfig: FC<Props> = (props: Props) => {
  const { getDatasource } = props;

  const { msTeamsGroups, dataSource, onReload } = useAppSelector(
    (state) => state.msTeams
  );

  const {
    errorMsg,
    showAddMSTeamsChannels,
    channelInfo,
    setShowAddMSTeamsChannels,
    handleCancel,
    getChannels
  } = useMSTeamsListConfig(dataSource, getDatasource);

  return (
    <>
      <div className="mt-4 d-flex justify-content-between align-items-start">
        <div>
          <h5>Teams</h5>
          <p>Add or remove connected Teams.</p>
        </div>
        <div>
          <Button
            color="secondary"
            data-testid="msteams-add-channel"
            onClick={() => setShowAddMSTeamsChannels(true)}
          >
            <Icons.Plus />
            <span>Add</span>
          </Button>
        </div>
      </div>
      <WithLoading
        isLoading={channelInfo.isLoading || onReload}
        type="overlay"
        fallback={<Spinner />}
      >
        {showAddMSTeamsChannels && (
          <MSTeamsAddChannel
            onCancel={handleCancel}
            getChannels={getChannels}
            errorMsg={errorMsg}
          />
        )}
        {msTeamsGroups.length > 0 && (
          <MSTeamsList getDatasource={getDatasource} />
        )}
      </WithLoading>
      {channelInfo.isError && (
        <Toast type={ToastType.ERROR}>Something went wrong.</Toast>
      )}
    </>
  );
};

export { MSTeamsListConfig };
