import { RelativeRange, RelativeLabel } from "../../types/date";

export type Shortcut = {
  label: RelativeLabel;
  value: RelativeRange;
};

const PAST_WEEK: Shortcut = {
  label: RelativeLabel.ONE_WEEK,
  value: RelativeRange.ONE_WEEK
};

const PAST_TWO_WEEKS: Shortcut = {
  label: RelativeLabel.TWO_WEEKS,
  value: RelativeRange.TWO_WEEKS
};

const PAST_MONTH: Shortcut = {
  label: RelativeLabel.ONE_MONTH,
  value: RelativeRange.ONE_MONTH
};

const PAST_THREE_MONTHS = {
  label: RelativeLabel.THREE_MONTHS,
  value: RelativeRange.THREE_MONTHS
};

const SHORTCUTS = {
  PAST_WEEK,
  PAST_TWO_WEEKS,
  PAST_MONTH,
  PAST_THREE_MONTHS
} as const;

export { SHORTCUTS };
