import { Avatar } from "@aptedge/lib-ui/src/components/Avatar/Avatar";
import { Chip } from "@aptedge/lib-ui/src/components/Chip/Chip";
import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import { ListItem } from "@aptedge/lib-ui/src/components/List/ListItem";
import { Tooltip } from "@aptedge/lib-ui/src/components/Tooltip/Tooltip";
import { IProductIssueDetailed } from "@aptedge/lib-ui/src/types/entities";
import { sanitizeUrl } from "@aptedge/lib-ui/src/utils/sanitizeUrl";
import React from "react";
import { TooltipMessage } from "../../registry/TooltipMessage";
import "./EdgeIssue.scss";

interface Props {
  issue: IProductIssueDetailed;
  disabled?: boolean;
  onClickDelete: () => void;
}

const EdgeIssue: React.FC<Props> = ({ issue, disabled, onClickDelete }) => {
  const santizedUrl = issue.urlReference
    ? sanitizeUrl(issue.urlReference.url)
    : "";
  return (
    <ListItem className="list-item issue">
      <div className="main">
        <div className="details">
          <div className="title">{issue.externalId}</div>
          {issue.details && <div className="name">{issue.details.summary}</div>}
        </div>
        <div className="more">
          {!!issue.details && (
            <>
              <div className="status">
                <Chip label={issue.details.status.name.toUpperCase()} />
              </div>
              {!!issue.details.assignee && (
                <div className="assignee">
                  <Avatar
                    color="var(--gray-300)"
                    size="20px"
                    text={issue.details.assignee.displayName}
                    email={issue.details.assignee.emailAddress}
                  />
                </div>
              )}
            </>
          )}
          {!!issue.urlReference && (
            <Tooltip content={TooltipMessage.VIEW_IN_JIRA}>
              <a
                className="jira-link"
                href={santizedUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icons.ExternalLink />
              </a>
            </Tooltip>
          )}
          {!disabled && (
            <Icons.Times className="remove-icon" onClick={onClickDelete} />
          )}
        </div>
      </div>
    </ListItem>
  );
};

export { EdgeIssue };
