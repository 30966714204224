import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { Dropdown } from "@aptedge/lib-ui/src/components/Dropdown/Dropdown";
import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import { IEdgeInfo } from "@aptedge/lib-ui/src/types/entities";
import React from "react";
import { useQueryClient } from "react-query";
import { WebCacheKey } from "../../../clients/cache";
import { GetDataErrorBoundary } from "../../../components/ErrorHandler/GetDataErrorBoundary";
import { EdgeSectionLabel } from "../EdgeSectionLabel";
import { EdgeFileList } from "./EdgeFileList";
import { EdgeFileModal } from "./EdgeFileModal";
import { EdgeLinkWidget } from "./EdgeLinkWidget";
import "./EdgeResourcesWidget.scss";

interface Props {
  edge: IEdgeInfo;
  onUpdateEdge: (newEdge: IEdgeInfo) => void;
}

const EdgeResourcesWidget: React.FC<Props> = (props) => {
  const { edge, onUpdateEdge } = props;
  const [isAddingLink, setIsAddingLink] = React.useState(false);
  const [uploadModalOpen, setUploadModalOpen] = React.useState<boolean>(false);

  const queryClient = useQueryClient();

  const isDisabled = edge.archived;

  const handleUploadModalClose = (): void => {
    setUploadModalOpen(false);
  };

  return (
    <div className="edge-resources">
      <div className="d-flex justify-content-between align-items-baseline">
        <EdgeSectionLabel>Resources</EdgeSectionLabel>
        <div>
          <Dropdown.Container placement="bottom" disabled={isDisabled}>
            <Dropdown.Toggle>
              <Button color="secondary" size="small" disabled={isDisabled}>
                <Icons.Plus />
                <span>Add</span>
              </Button>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => setUploadModalOpen(true)}>
                Upload files
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setIsAddingLink(true)}>
                Add weblink
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown.Container>
        </div>
      </div>
      <GetDataErrorBoundary message="Unable to get attached files.">
        <EdgeFileList
          edge={edge}
          onUpdate={() => {
            queryClient.invalidateQueries(WebCacheKey.EDGE_FILES);
            queryClient.invalidateQueries([
              WebCacheKey.AUDIT_LOGS_INFINITE,
              edge.id
            ]);
          }}
        />
      </GetDataErrorBoundary>
      <EdgeLinkWidget
        edge={edge}
        showAddLink={isAddingLink}
        onCloseAddLink={() => setIsAddingLink(false)}
        onUpdate={onUpdateEdge}
      />
      <EdgeFileModal
        edge={edge}
        isOpen={uploadModalOpen}
        onClose={handleUploadModalClose}
        onUpdate={() => {
          queryClient.invalidateQueries(WebCacheKey.EDGE_FILES);
          queryClient.invalidateQueries([
            WebCacheKey.AUDIT_LOGS_INFINITE,
            edge.id
          ]);
          handleUploadModalClose();
        }}
      />
    </div>
  );
};

export { EdgeResourcesWidget };
