import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { Feedback } from "@aptedge/lib-ui/src/components/Feedback/Feedback";
import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import classNames from "classnames";
import { useFormik } from "formik";
import React, { createRef, useEffect } from "react";
import { useMutation } from "react-query";
import * as Yup from "yup";
import { createCustomFieldMapping } from "../../../clients/CustomFieldMappings/createCustomFieldMapping";
import WithLoading from "../../../components/WithLoading/WithLoading";

interface Props {
  className?: string;
  onSave: () => void;
  onCancel: () => void;
}

interface FormState {
  fieldName: string;
  displayName: string;
  displayValue: string | null;
}

const CustomFieldMappingCreateItem: React.FC<Props> = (props) => {
  const { className, onCancel, onSave } = props;

  const inputRef = createRef<HTMLInputElement>();

  const addCustomFieldMapping = useMutation(createCustomFieldMapping, {
    onSuccess: onSave
  });

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik<FormState>({
    initialValues: {
      fieldName: "",
      displayName: "",
      displayValue: null
    },
    onSubmit: (values, helpers) => {
      addCustomFieldMapping.mutate({
        fieldName: values.fieldName,
        displayName: values.displayName,
        displayValue: values.displayValue || undefined
      });
    },
    validateOnBlur: false,
    validationSchema: Yup.object({
      fieldName: Yup.string().required("A field name must be specified."),
      displayName: Yup.mixed().required("A display name must be specified."),
      displayValue: Yup.string().nullable()
    })
  });

  return (
    <WithLoading
      isLoading={addCustomFieldMapping.isLoading}
      type="overlay"
      fallback={<Spinner />}
    >
      <div className={classNames("label-create-item p-2", className)}>
        <h5>Create a new custom field mapping</h5>
        <form onSubmit={formik.handleSubmit}>
          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="fieldName">Field Name</label>
            </div>
            <div className="form-group col-md-9">
              <input
                id="fieldName"
                type="text"
                ref={inputRef}
                className={classNames("form-control", {
                  "is-invalid": !!formik.errors.fieldName
                })}
                aria-describedby="fieldNameHelp"
                value={formik.values.fieldName}
                onChange={formik.handleChange}
              />
              {formik.errors.fieldName && (
                <Feedback>{formik.errors.fieldName}</Feedback>
              )}
              <small id="fieldNameHelp" className="form-text text-muted">
                Set the Zendesk Field Name
              </small>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="displayName">Display Name</label>
            </div>
            <div className="form-group col-md-9">
              <input
                id="displayName"
                type="text"
                className={classNames("form-control", {
                  "is-invalid": !!formik.errors.displayName
                })}
                aria-describedby="displayNameHelp"
                value={formik.values.displayName}
                onChange={formik.handleChange}
              />
              {formik.errors.displayName && (
                <Feedback>{formik.errors.displayName}</Feedback>
              )}
              <small id="displayNameHelp" className="form-text text-muted">
                Set the display name for the Custom Field
              </small>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="displayValue">Display Value</label>
            </div>
            <div className="form-group col-sm-9">
              <input
                id="displayValue"
                type="text"
                className={classNames("form-control", {
                  "is-invalid": !!formik.errors.displayValue
                })}
                aria-describedby="displayValueHelp"
                value={
                  formik.values.displayValue ? formik.values.displayValue : ""
                }
                onChange={formik.handleChange}
              />
              {formik.errors.displayValue && (
                <Feedback>{formik.errors.displayValue}</Feedback>
              )}
              <small id="displayValueHelp" className="form-text text-muted">
                Set the display value for the Custom Field. This can be
                customized using $value, for example: https://foobar.baz/$value.
              </small>
            </div>
          </div>

          {addCustomFieldMapping.isError && (
            <Feedback>
              Sorry, we weren&apos;t able to create a Custom Field Mapping
            </Feedback>
          )}

          <div className="d-flex mt-2 justify-content-end">
            <Button
              type="button"
              className="mr-2"
              color="secondary"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Save
            </Button>
          </div>
        </form>
      </div>
    </WithLoading>
  );
};

export { CustomFieldMappingCreateItem };
