import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import { useModalRouter } from "@aptedge/lib-ui/src/components/Modal/ModalRouter";
import { IBulkReply } from "@aptedge/lib-ui/src/types/entities";
import { ApiError } from "@aptedge/lib-ui/src/utils/ApiError";
import { GTM_EVENTS, dataLayerPush } from "@aptedge/lib-ui/src/utils/event";
import classNames from "classnames";
import React from "react";
import { useMutation } from "react-query";
import { createEdgeBulkReply } from "../../../clients/Edges/createEdgeBulkReply";
import { Toast } from "../../../components/Toast/Toast";
import { useBulkReplyContext } from "../../../context/BulkReplyContext";
import { useRecentJob } from "../../../hooks/clients/useJobStatus";
import { IBulkReplyCreateRequest } from "../../../types/clients";
import { ReplyTextArea } from "../ReplyTextArea";
import { Page } from "./BulkReplyRoutes";
import { BulkStatusChange } from "./BulkStatusChange";

function BulkReplyConfirmPage(): React.ReactElement {
  const router = useModalRouter();
  const [
    { edgeId, isInternal, content, status },
    dispatch
  ] = useBulkReplyContext();
  const [, setRecentJob] = useRecentJob();

  const replyMutation = useMutation<
    IBulkReply,
    ApiError,
    { edgeId: number } & IBulkReplyCreateRequest
  >(createEdgeBulkReply, {
    onSuccess: (data) => {
      setRecentJob({ id: data.runId });
      dispatch({ type: "addNewReply", payload: data });
      router.push(Page.SUCCESS);
    }
  });
  const filters = status.from ? { status: [status.from] } : undefined;

  const handleBulkreply = (): void => {
    if (!!content) {
      replyMutation.mutate({
        edgeId: edgeId,
        content,
        internal: isInternal,
        ticketStatus: !!status.to
          ? { from: status.from, to: status.to }
          : undefined,
        filters
      });
    }
    dataLayerPush({ event: GTM_EVENTS.GTM_CREATE_BULK_REPLY });
  };

  return (
    <React.Fragment>
      <div className="confirmation-summary">
        <Icons.ChevronLeft onClick={() => router.push(Page.START)} />
        <span>You are sending an </span>
        <span className="bold">
          {isInternal ? "internal" : "external"} reply{" "}
        </span>
        <span>to </span>
        <span className="bold">{status.from} </span>
        <span>tickets in EDGE-{edgeId}</span>
      </div>
      <div className="text">
        <ReplyTextArea
          disabled
          value={content}
          className={classNames({ internal: isInternal })}
        />
      </div>
      <BulkStatusChange />
      <div className="buttons">
        <Button color="secondary" onClick={() => router.push(Page.START)}>
          Go back
        </Button>
        <Button
          isLoading={replyMutation.isLoading}
          onClick={() => handleBulkreply()}
        >
          Submit
        </Button>
      </div>
      {!!replyMutation.error && (
        <Toast type="error" onClose={replyMutation.reset}>
          {replyMutation.error.displayMessage}
        </Toast>
      )}
    </React.Fragment>
  );
}

export { BulkReplyConfirmPage };
