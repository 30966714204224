import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { HTMLAttributes } from "react";

export interface IconProps extends HTMLAttributes<SVGElement> {
  icon: IconDefinition;
  color?: string;
  size?: SizeProp;
}

const Icon: React.FunctionComponent<IconProps> = ({
  color,
  children,
  ...rest
}) => {
  const iconColor = color || "inherit";

  return <FontAwesomeIcon {...rest} color={iconColor} />;
};

export { Icon };
