import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";

const ABYDOS = "stargate-abydos";

const Portal: React.FunctionComponent = ({ children }) => {
  const [container] = useState(document.createElement("div"));

  // Add a new element to the portal;
  useEffect(() => {
    let stargate: HTMLElement | null = document.getElementById(ABYDOS);

    if (!stargate) {
      stargate = document.createElement("div");
      stargate.id = ABYDOS;
      document.body.appendChild(stargate);
    }

    stargate.appendChild(container);

    return function cleanup() {
      if (stargate) {
        stargate.removeChild(container);
      }
    };
  }, [container]);

  return ReactDOM.createPortal(children, container);
};

export { Portal };
