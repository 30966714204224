import { IBusinessDataSource } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";

function fetchBusinessDataSource(): Promise<IBusinessDataSource[]> {
  const url = `${getBackendApiUrl()}/business-data-sources/`;

  return request(url);
}

export { fetchBusinessDataSource };
