import {
  EntityType,
  LabelEntityType
} from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { deleteRequest } from "../../utils/request";
import { deleteInit } from "../utils/buildRequest";

function getDeleteLabelUrl(id: number, type: LabelEntityType): string {
  const base = getBackendApiUrl();

  switch (type) {
    case EntityType.PRODUCT:
      return `${base}/products/${id}/`;
    case EntityType.FEATURE:
      return `${base}/features/${id}/`;
    case EntityType.THEME:
      return `${base}/themes/${id}/`;
    default:
      throw new Error(`Unsupported entity type when creating a label: ${type}`);
  }
}

async function deleteLabel({
  id,
  type
}: {
  id: number;
  type: LabelEntityType;
}): Promise<void> {
  const url = getDeleteLabelUrl(id, type);

  return deleteRequest(url, deleteInit());
}

export { deleteLabel };
