/* istanbul ignore file */
export enum ContentType {
  MULTIPART_FORM_DATA = "multipart/form-data",

  // Images
  IMAGE_JPEG = "image/jpeg",
  IMAGE_GIF = "image/gif",
  IMAGE_PNG = "image/png",
  IMAGE_TIFF = "image/tiff",

  // Docs
  TEXT_PLAIN = "text/plain",
  TEXT_CSV = "text/csv",
  TEXT_MARKDOWN = "text/markdown",

  APPLICATION_JSON = "application/json",
  APPLICATION_OCTET_STREAM = "application/octet-stream",
  APPLICATION_MSWORD = "application/msword",
  APPLICATION_MSWORD_X = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  APPLICATION_PDF = "application/pdf",
  APPLICATION_RTF = "application/rtf",
  APPLICATION_EXCEL = "application/vnd.ms-excel",
  APPLICATION_EXCEL_X = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  APPLICATION_PPT = "application/vnd.ms-powerpoint",
  APPLICATION_PPTX = "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  APPLICATION_PCAP = ".pcap",
  APPLICATION_EML = ".eml",
  APPLICATION_ZIP = "application/zip",
  APPLICATION_KML = "application/vnd.google-earth.kml+xml",
  APPLICATION_KMZ = "application/vnd.google-earth.kmz"
}

export function getContentTypeOfFile(filePath: string): ContentType | null {
  const ext = filePath.replace(/(.*)(\.[a-z]*)$/, "$2");

  switch (ext) {
    case ".jpg":
      return ContentType.IMAGE_JPEG;
    case ".jpeg":
      return ContentType.IMAGE_JPEG;
    case ".gif":
      return ContentType.IMAGE_GIF;
    case ".tiff":
      return ContentType.IMAGE_TIFF;
    case ".png":
      return ContentType.IMAGE_PNG;
    case ".txt":
      return ContentType.TEXT_PLAIN;
    case ".csv":
      return ContentType.TEXT_CSV;
    case ".md":
      return ContentType.TEXT_MARKDOWN;
    case ".json":
      return ContentType.APPLICATION_JSON;
    case ".doc":
      return ContentType.APPLICATION_MSWORD;
    case ".docx":
      return ContentType.APPLICATION_MSWORD_X;
    case ".pdf":
      return ContentType.APPLICATION_PDF;
    case ".rtf":
      return ContentType.APPLICATION_RTF;
    case ".xls":
      return ContentType.APPLICATION_EXCEL;
    case ".xlsx":
      return ContentType.APPLICATION_EXCEL_X;
    case ".ppt":
      return ContentType.APPLICATION_PPT;
    case ".pptx":
      return ContentType.APPLICATION_PPTX;
    case ".pcap":
      return ContentType.APPLICATION_PCAP;
    case ".eml":
      return ContentType.APPLICATION_EML;
    case ".zip":
      return ContentType.APPLICATION_ZIP;
    case ".kml":
      return ContentType.APPLICATION_KML;
    case ".kmz":
      return ContentType.APPLICATION_KMZ;
    default:
      return null;
  }
}
