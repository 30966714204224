import { ISlackChannelUpdateSource } from "@aptedge/lib-ui/src/types/entities";
import { getBackendUrl } from "../../config";
import { request } from "../../utils/request";
import { patch } from "../utils/buildRequest";

async function updateSlackChannels({
  channels
}: {
  channels: string[];
}): Promise<ISlackChannelUpdateSource> {
  const url = `${getBackendUrl()}/slack/collect/`;
  const options = patch({ channels });
  return request(url, options).then((response) => response);
}

export { updateSlackChannels };
