import { Skeleton } from "@mui/material";
import { FC } from "react";

interface SkeletonLoaderProps {
  lines: number;
  containerClassName?: string;
  loaderClassName?: string;
}
const SkeletonLoader: FC<SkeletonLoaderProps> = ({
  lines,
  containerClassName,
  loaderClassName
}) => (
  <div className={containerClassName}>
    {[...Array(lines)].map((_, i) => (
      <Skeleton
        className={loaderClassName}
        data-testid="answer-skeleton"
        key={i}
      />
    ))}
  </div>
);

export default SkeletonLoader;
