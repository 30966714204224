import { IBusinessDataSource } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { ISaveBusinessDataSourceRequest } from "../../types/clients";
import { request } from "../../utils/request";
import { post } from "../utils/buildRequest";
import { deleteSalesforceTaskFromStorage } from "./utils";

async function saveBusinessDataSourceClient(
  args: ISaveBusinessDataSourceRequest
): Promise<IBusinessDataSource> {
  const url = `${getBackendApiUrl()}/business-data-sources/`;

  const options = post(args);

  // Remove any saved tasks, in case we're switching to a new instance.
  deleteSalesforceTaskFromStorage();

  return request(url, options);
}

export { saveBusinessDataSourceClient };
