import { IEdgeListing } from "@aptedge/lib-ui/src/types/entities";
import React from "react";
import { CellComponentProps } from "../useTable";
import "./EdgeIdCell.scss";

function EdgeIdCell(
  props: CellComponentProps<IEdgeListing>
): React.ReactElement {
  const { data: edge } = props;

  return (
    <div className="edge-id-cell">
      <span className="edge-id-cell-content">EDGE-{edge.id}</span>
    </div>
  );
}

export { EdgeIdCell };
