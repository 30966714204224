import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import { ISlackSocialSource } from "@aptedge/lib-ui/src/types/entities";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { ToastType } from "react-toastify";
import { updateSlackChannels } from "../../../clients/SocialSource/updateSlackChannels";
import { Toast } from "../../../components/Toast/Toast";
import { SlackAddChannel } from "./SlackAddChannel";

interface Props {
  source: ISlackSocialSource;
  onChange: () => void;
}

const SlackChannelsConfig: React.FunctionComponent<Props> = (props: Props) => {
  const { source, onChange } = props;

  const [showAddSlackChannels, setShowAddSlackChannels] = useState<boolean>(
    false
  );

  const slackSocialResource = source ?? null;

  const updateSource = useMutation(updateSlackChannels, {
    onSuccess: () => {
      setShowAddSlackChannels(false);
      onChange();
    }
  });

  const handleSaveChannels = (val: string): void => {
    const channels = slackSocialResource?.channels?.map(
      (channel) => channel.channelId
    );
    const clientChannels = channels ? [...channels, val] : [val];
    updateSource.mutate({ channels: clientChannels });
  };

  return (
    <>
      <div className="mt-4 d-flex justify-content-between align-items-start">
        <div>
          <h5>Slack Channel</h5>
          <p>Add or remove connected channels.</p>
        </div>
        <div>
          <Button
            color="secondary"
            data-testid="slack-add-channel"
            onClick={() => setShowAddSlackChannels(true)}
          >
            <Icons.Plus />
            <span>Add</span>
          </Button>
        </div>
      </div>
      {showAddSlackChannels && (
        <SlackAddChannel
          onSave={handleSaveChannels}
          onCancel={() => setShowAddSlackChannels(false)}
          slackSource={slackSocialResource}
        />
      )}
      {updateSource.isError && (
        <Toast type={ToastType.ERROR}>Something went wrong.</Toast>
      )}
    </>
  );
};

export { SlackChannelsConfig };
