import {
  AptAnswersFilterRule,
  AptAnswersFilterType
} from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";
import { post } from "../utils/buildRequest";

interface CreateFilterItem {
  name: string;
  filterType: AptAnswersFilterType;
  filterRules: AptAnswersFilterRule[];
}

interface CreateFilterArgs {
  filters: CreateFilterItem[];
}

interface CreateFilterResponse {
  filterId: string;
}

async function createFilter({
  filters
}: CreateFilterArgs): Promise<CreateFilterResponse> {
  const url = `${getBackendApiUrl()}/apt-answer-filter/`;
  const options = post({
    filters
  });
  return request(url, options);
}

export { createFilter };
