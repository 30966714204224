import { getBackendApiUrl } from "../../config";
import { deleteRequest } from "../../utils/request";
import { deleteInit } from "../utils/buildRequest";

async function deleteEdgeFile({
  edgeId,
  fileId
}: {
  edgeId: number;
  fileId: string;
}): Promise<void> {
  const url = `${getBackendApiUrl()}/edges/${edgeId}/files/${fileId}`;

  return deleteRequest(url, deleteInit());
}

export { deleteEdgeFile };
