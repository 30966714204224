import React from "react";
import { useQuery } from "react-query";
import { WebCacheKey } from "../../clients/cache";
import { fetchClassificationMetrics } from "../../clients/Metrics/fetchClassificationMetrics";
import WithLoading from "../../components/WithLoading/WithLoading";
import { useThemes } from "../../hooks/clients/useThemes";
import LoadingPage from "../LoadingPage/LoadingPage";
import { MetricsContent } from "./MetricsContent";

const StatusPage: React.FC = () => {
  const themesQuery = useThemes();
  const metricsQuery = useQuery(
    WebCacheKey.CLASSIFICATION_METRICS,
    fetchClassificationMetrics,
    { refetchOnWindowFocus: false }
  );
  const isLoading = metricsQuery.isLoading || themesQuery.isLoading;

  return (
    <WithLoading isLoading={isLoading} fallback={<LoadingPage />}>
      {metricsQuery.data && (
        <MetricsContent
          data={metricsQuery.data}
          themes={themesQuery.data || []}
        />
      )}
    </WithLoading>
  );
};

export { StatusPage };
