import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import { Tooltip } from "@aptedge/lib-ui/src/components/Tooltip/Tooltip";
import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import { IEdgeInfo } from "@aptedge/lib-ui/src/types/entities";
import React from "react";
import { useBusinessDataSourceIsAuthorized } from "../../hooks/clients/useDataSource";
import { TooltipMessage } from "../../registry/TooltipMessage";
import { toAbbreviatedMonetaryStringFromNumber } from "../../utils/value";
import "./EdgeValue.scss";

function EdgeValue(props: { edge: IEdgeInfo }): JSX.Element | null {
  const { edge } = props;

  const { flags } = useFlags();
  const hasBusinessSourceOrDemo =
    useBusinessDataSourceIsAuthorized() || flags.demo;

  return (
    <div className="edge-value">
      {hasBusinessSourceOrDemo ? (
        <Tooltip content={TooltipMessage.REVENUE_AT_RISK}>
          <div className="revenue text-uppercase">
            <Icons.MoneyBill />
            {toAbbreviatedMonetaryStringFromNumber(edge.revenueUsd || 0)}
          </div>
        </Tooltip>
      ) : null}
    </div>
  );
}

export { EdgeValue };
