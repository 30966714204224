import { ModalWidth } from "@aptedge/lib-ui/src/components/Modal/Modal";
import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import { useAppDispatch } from "@aptedge/lib-ui/src/redux/hook/hook";
import { setSnowTapData } from "@aptedge/lib-ui/src/redux/reduxSlice/snowArticleSlice";
import {
  IntegrationStatus,
  INTEGRATION_STATUS,
  OAuthStatus
} from "@aptedge/lib-ui/src/types/entities";
import { GATEWAY_TIMEOUT } from "http-status-codes";
import React, { useEffect, useState, useCallback } from "react";
import { useMutation } from "react-query";
import { ToastType } from "react-toastify";
import { getBackendUrl } from "../../../../../src/config";
import { createSNowArticleSource } from "../../../../clients/ArticleSources/createSNowArticleSource";
import { ConfirmationModal } from "../../../../components/ConfirmationModal/ConfirmationModal";
import { Toast } from "../../../../components/Toast/Toast";
import WithLoading from "../../../../components/WithLoading/WithLoading";
import { useSNowKnowledgeDataSource } from "../../../../hooks/clients/useDataSource";
import { ClientStatus } from "../../../../types/clients";
import { AddButton } from "../../common/AddButton";
import { StatusButton } from "../../common/StatusButton";
import { SNowConfigForm, SNowConfigFormData } from "./SNowConfigForm";

const SNowConfig: React.FunctionComponent = () => {
  const dataSource = useSNowKnowledgeDataSource();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [redirectStatus, setRedirectStatus] = React.useState(
    ClientStatus.PROMPT
  );
  const dispatch = useAppDispatch();

  const snowSource = dataSource?.dataSource ?? null;

  const addSource = useMutation(createSNowArticleSource, {
    onError: () => {
      setRedirectStatus(ClientStatus.ERROR);
    },
    onSuccess: () => {
      setRedirectStatus(ClientStatus.LOADING);
      const url = `${getBackendUrl()}/servicenow/login`;
      window.location.href = url;
    }
  });

  const onFormSubmit = (formData: SNowConfigFormData): void => {
    setRedirectStatus(ClientStatus.LOADING);
    const { oauthClientSecret, oauthClientId, oauthInstanceName } = formData;
    const newFormData = {
      instanceName: oauthInstanceName,
      oauthClientSecret,
      oauthClientId
    };
    addSource.mutate(newFormData);
  };

  const getStatus = (): IntegrationStatus => {
    if (dataSource?.error?.code === GATEWAY_TIMEOUT)
      return INTEGRATION_STATUS.TIME_OUT;
    if (snowSource?.status === OAuthStatus.AUTHENTICATED)
      return INTEGRATION_STATUS.OK;
    return INTEGRATION_STATUS.ERROR;
  };

  const handleReduxDispatch = useCallback((): void => {
    dispatch(setSnowTapData(snowSource ?? null));
  }, [dispatch, snowSource]);

  useEffect(() => {
    handleReduxDispatch();
  }, [handleReduxDispatch]);

  const isConnectionExist =
    snowSource || getStatus() === INTEGRATION_STATUS.TIME_OUT;
  const isLoaderVisible = addSource.isLoading || dataSource.isLoading;

  return (
    <section>
      <div className="row">
        <WithLoading isLoading={isLoaderVisible} fallback={<Spinner />}>
          <div className="mt-5 pl-3">
            <h5>ServiceNow</h5>
            <p>Configure your ServiceNow settings below.</p>
          </div>

          <div className="col-md-12 p-2 pl-3">
            {isConnectionExist ? (
              <StatusButton
                status={getStatus()}
                onClickEdit={() => setShowModal(true)}
              >
                <>
                  <div>ServiceNow</div>
                  <div className="text-muted">{snowSource.instanceName}</div>
                </>
              </StatusButton>
            ) : (
              <AddButton onClick={() => setShowModal(true)}>Connect</AddButton>
            )}
          </div>
        </WithLoading>
        <ConfirmationModal
          title="ServiceNow Settings"
          width={ModalWidth.MEDIUM}
          loadingTitle="Loading...."
          status={redirectStatus}
          isOpen={showModal}
          onClose={() => setShowModal(false)}
        >
          <p className="mb-4">Configure your settings below.</p>
          <SNowConfigForm onSubmit={onFormSubmit} />
        </ConfirmationModal>
        {addSource.isError && (
          <Toast type={ToastType.ERROR}>Something went wrong.</Toast>
        )}
      </div>
    </section>
  );
};

export { SNowConfig };
