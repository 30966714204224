import { IEdgeListing } from "@aptedge/lib-ui/src/types/entities";
import { DateTime } from "luxon";
import React from "react";
import { CellComponentProps } from "../useTable";
import "./EdgeUpdatedOnCell.scss";

function EdgeUpdatedOnCell(
  props: CellComponentProps<IEdgeListing>
): React.ReactElement {
  const { data: edge } = props;

  const dt = DateTime.fromSeconds(edge.userUpdatedOn || edge.updatedOn);

  return (
    <div className="edge-updated-on-cell">
      <p>
        {dt.toLocaleString({
          month: "2-digit",
          day: "2-digit",
          year: "numeric"
        })}
      </p>
      <p className="edge-updated-on-cell-time">
        {dt.toLocaleString(DateTime.TIME_SIMPLE)}
      </p>
    </div>
  );
}

export { EdgeUpdatedOnCell };
