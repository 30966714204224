export enum MarkdownBlockType {
  HEADER_ONE = "header-one",
  HEADER_TWO = "header-two",
  HEADER_THREE = "header-three",
  HEADER_FOUR = "header-four",
  HEADER_FIVE = "header-five",
  HEADER_SIX = "header-six",
  ORDERED_LIST = "ordered-list",
  UNORDERED_LIST = "unordered-list",
  LIST_ITEM = "list-item",
  PARAGRAPH = "paragraph",
  BLOCKQUOTE = "blockquote",
  CODE = "code",
  IMAGE = "image"
}

export enum MarkdownInlineType {
  BOLD = "bold",
  ITALIC = "italic",
  STRIKETHROUGH = "strikethrough",
  LINK = "link",
  CODE = "code"
}

export enum CodeMirrorTokenType {
  HEADER = "header",
  CODE = "comment",
  QUOTE = "quote",
  LIST_1 = "variable-2",
  LIST_2 = "variable-3",
  LIST_3 = "keyword",
  HR = "hr",
  IMAGE = "image",
  IMAGE_ALT_TEXT = "image-alt-text",
  IMAGE_MARKER = "image-marker",
  FORMATTING = "formatting",
  LINK = "link",
  LINK_HREF = "string",
  ITALIC = "em",
  BOLD = "strong",
  STRIKETHROUGH = "strikethrough",
  EMOJI = "builtin"
}
