import { GQADocSearchResult } from "@aptedge/lib-ui/src/types/entities";
import { TimedResponse } from "@aptedge/lib-ui/src/utils/request";
import { FC, createContext } from "react";
import { UseInfiniteQueryResult } from "react-query";
import "./AiAnswer.scss";
import { AeIcons } from "../../../../components/Icon/AeIcons";
import { useAppSelector } from "../../../../redux/hook/hook";
import Body from "../Body/Body";
import Header from "../Header/Header";
import useAiAnswer from "./useAiAnswer";

type GPTContextType = {
  gptAnswer: UseInfiniteQueryResult<
    TimedResponse<GQADocSearchResult>,
    unknown
  > | null;
  refetchAnswer: () => void;
};

export const GPTContext = createContext<GPTContextType>({
  gptAnswer: null,
  refetchAnswer: (): void => undefined
});

const AiAnswer: FC = () => {
  const { mappedResults, totalSearchResults } = useAiAnswer();
  const { answer, isAnswerLoading, additionalInstruction } = useAppSelector(
    (state) => state.answerGPT
  );

  return (
    <div className="answers-container">
      <div className="answer-container">
        <AeIcons.AiBot className="ai-bot" />
        <div>
          <Header />
          <Body
            answer={answer ?? ""}
            skeletonLines={3}
            isLoading={isAnswerLoading}
            resultsMap={mappedResults}
            totalResults={totalSearchResults}
            hasAdditionalInstruction={!!additionalInstruction}
            truncateLongAnswer={true}
          />
        </div>
      </div>
    </div>
  );
};

export default AiAnswer;
