import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import { Modal, ModalWidth } from "@aptedge/lib-ui/src/components/Modal/Modal";
import { Tooltip } from "@aptedge/lib-ui/src/components/Tooltip/Tooltip";
import { IEdgeInfo, IEdgeFile } from "@aptedge/lib-ui/src/types/entities";
import React, { ComponentProps } from "react";
import { getBackendApiUrl } from "../../../config";
import { TooltipMessage } from "../../../registry/TooltipMessage";

interface Props extends Omit<ComponentProps<typeof Modal>, "title" | "width"> {
  edge: IEdgeInfo;
  file: IEdgeFile;
}

const Preview: React.FC<{ file: IEdgeFile; fileUrl: string }> = ({
  file,
  fileUrl
}) => {
  if (!!file.contentType.match("image.*")) {
    return (
      <img
        className="thumbnail-content"
        src={fileUrl}
        alt={file.name}
        width="100%"
      />
    );
  } else if (!!file.contentType.match("application/pdf")) {
    return (
      <iframe
        title={file.name}
        src={fileUrl}
        frameBorder="0"
        width="100%"
        height="600px"
      />
    );
  } else {
    return (
      <div
        className="thumbnail-content d-flex justify-content-center align-items-center flex-column p-2 text-muted"
        style={{ height: "300px" }}
      >
        <Icons.EyeSlash className="pb-3" size="3x" />
        <div className="text-center pb-3" data-testid="no-preview-text">
          Unable to preview this file type.
          <br />
          Try downloading the file to view it.
        </div>
        <a className="text-decoration-none" href={fileUrl} download>
          <Button>Download</Button>
        </a>
      </div>
    );
  }
};

const EdgeFilePreviewModal: React.FC<Props> = ({
  edge,
  file,
  ...modalProps
}) => {
  const fileUrl = `${getBackendApiUrl()}/edges/${edge.id}/files/${file.id}`;

  return (
    <Modal
      {...modalProps}
      title={file.name}
      width={ModalWidth.XL}
      actions={
        <Tooltip content={TooltipMessage.DOWNLOAD_FILE}>
          <a data-testid="download" href={fileUrl}>
            <Icons.Download />
          </a>
        </Tooltip>
      }
    >
      <div>
        <Preview file={file} fileUrl={fileUrl} />
      </div>
    </Modal>
  );
};

export { EdgeFilePreviewModal };
