import { SearchResultPreview } from "@aptedge/lib-support-ui/src/components/SearchResultPreview";
import { ILabel } from "@aptedge/lib-ui/src/types/entities";
import { FC } from "react";
import { LabelChip } from "../../../../src/components/LabelChip/LabelChip";
import { LabelChipList } from "../../../../src/components/LabelChip/LabelChipList";
import useSearchResult from "./useSearchResult";

const Title: FC = () => {
  const { title, labels } = useSearchResult();
  return (
    <div className="preview-title">
      <h5 data-testid="preview-title">
        <SearchResultPreview previewSnippet={title} />
      </h5>
      <div>
        <div className="chip-list">
          {!!labels && labels.length > 0 && (
            <LabelChipList>
              {labels.map((label: ILabel) => (
                <LabelChip key={`${label.id}-${label.type}`} label={label} />
              ))}
            </LabelChipList>
          )}
        </div>
      </div>
    </div>
  );
};

export default Title;
