import "./Invite.scss";
import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import { Modal, ModalWidth } from "@aptedge/lib-ui/src/components/Modal/Modal";
import { TextInput } from "@aptedge/lib-ui/src/components/TextInput/TextInput";
import { useOnKeydownOutside } from "@aptedge/lib-ui/src/hooks/useOnKeyDownOutside";
import { useOnMouseDownOutside } from "@aptedge/lib-ui/src/hooks/useOnMouseDownOutside";
import { Keys } from "@aptedge/lib-ui/src/styles/keys";
import { GTM_EVENTS, dataLayerPush } from "@aptedge/lib-ui/src/utils/event";
import { useFormik } from "formik";
import React from "react";
import { useMutation } from "react-query";
import { ToastType } from "react-toastify";
import * as Yup from "yup";
import { inviteUser } from "../../clients/User/inviteUser";
import { Toast } from "../../components/Toast/Toast";
import { useAuth } from "../../context/AuthContext";
import { createErrorMessage } from "../../utils/createErrorMessage";

interface Props {
  onClose: () => void;
  isOpen: boolean;
}

interface FormState {
  email: string;
}

interface ErrorInterface {
  err_msg: string;
}

function InviteModal(props: Props): React.ReactElement {
  const [toastMsg, setToastMsg] = React.useState("");
  const { onClose, isOpen } = props;
  const { user } = useAuth();

  const inputRef = React.useRef<HTMLInputElement>(null);

  const emailDomain = user?.email.split("@")[1];
  const handleCloseModal = (): void => {
    formik.resetForm();
    onClose();
    setToastMsg("");
  };
  const inviteUsersMutation = useMutation(inviteUser, {
    onSuccess: () => {
      setToastMsg("Coworker invited successfully");
      handleCloseModal();
    },
    onError: (err: ErrorInterface) => {
      formik.setFieldError("email", createErrorMessage(err));
    }
  });

  let emailValidation = Yup.string().required("An email is required.").email();
  if (emailDomain !== "aptedge.io") {
    // exception for our emails to allow us to invite any user
    emailValidation = emailValidation.matches(
      new RegExp(`.*@${emailDomain}`),
      "Email domain must match yours."
    );
  }

  const triggerEvent = (email: string): void => {
    dataLayerPush({
      event: GTM_EVENTS.GTM_INVITE_USER_TEXT,
      data: {
        invite_user_email: email
      }
    });
  };

  const formik = useFormik<FormState>({
    initialValues: { email: "" },
    validationSchema: Yup.object({
      email: emailValidation
    }),
    onSubmit: (data: FormState): void => {
      triggerEvent(data.email);
      inviteUsersMutation.mutate(data.email);
    }
  });
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={handleCloseModal}
        title="Invite your coworkers!"
        width={ModalWidth.MEDIUM}
      >
        <form
          className="invite-form"
          onSubmit={formik.handleSubmit}
          data-testid="create-edge-form"
        >
          <TextInput
            label="Email"
            ref={inputRef}
            name="email"
            autoFocus
            error={formik.errors.email}
            value={formik.values.email}
            placeholder="Enter an email to invite!"
            onChange={formik.handleChange}
            onClear={() => formik.setFieldValue("email", "")}
          />
          <Button
            type="submit"
            color="primary"
            disabled={inviteUsersMutation.isLoading}
          >
            Invite
          </Button>
        </form>
      </Modal>
      {toastMsg && <Toast type={ToastType.SUCCESS}>{toastMsg}</Toast>}
    </>
  );
}

const Invite: React.FC = () => {
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);

  useOnMouseDownOutside(wrapperRef, () => setIsOpen(false));
  useOnKeydownOutside(wrapperRef, [Keys.ESCAPE, Keys.TAB], () =>
    setIsOpen(false)
  );

  return (
    <div className="invite">
      <Button color="primary" onClick={() => setIsOpen((open) => !open)}>
        <Icons.PaperPlane />
      </Button>
      <InviteModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </div>
  );
};

export { Invite };
