import classNames from "classnames";
import { useState } from "react";
import { useAppSelector } from "../../redux/hook/hook";
import { dataLayerPush, isWebApp } from "../../utils/event";
import { AeIcons } from "../Icon/AeIcons";
import { LightTooltip } from "../Tooltip/LightTooltip";
import "./CopyButton.scss";

interface Props {
  text: string;
  copiedElementId?: string;
  className?: string;
  event: string;
}

const triggerCopyEvent = (
  text: string,
  event: string,
  id: string,
  subject: string
): void => {
  dataLayerPush({
    event: event,
    data: {
      text: JSON.stringify(text),
      ticket_title: isWebApp ? undefined : subject,
      ticket_id: isWebApp ? undefined : id
    }
  });
};

function copyElementToClipboard(element: HTMLElement): void {
  // https://stackoverflow.com/a/59462028
  window?.getSelection()?.removeAllRanges();
  const range = document.createRange();
  range.selectNode(element);
  window?.getSelection()?.addRange(range);
  document.execCommand("copy");
  window?.getSelection()?.removeAllRanges();
}

export const CopyButton: React.FC<Props> = ({
  text,
  copiedElementId,
  className,
  event
}) => {
  const [copied, setCopied] = useState(false);

  const { ticket } = useAppSelector((state) => state.cookedTicket);
  const { ticketId, ticketSubject } = ticket;

  const handleCopy = (): void => {
    triggerCopyEvent(text, event, ticketId, ticketSubject);

    if (copiedElementId) {
      // TODO: this component should accept a ref, rather than a global ID
      const element = document.getElementById(copiedElementId);
      if (!element) {
        throw new Error("Missing answer element to copy");
      }
      copyElementToClipboard(element);
    } else {
      navigator.clipboard?.writeText(text ?? "");
    }

    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <div className="copy-btn-container">
      <LightTooltip
        open={Boolean(copied)}
        placement="top"
        title="Copied"
        arrow
        className="copy-btn-tooltip"
      >
        <button
          className={classNames("aptedge-copy-button", className)}
          onClick={handleCopy}
        >
          <AeIcons.Copy />
        </button>
      </LightTooltip>
    </div>
  );
};
