import { ISpace } from "@aptedge/lib-ui/src/types/entities";
import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { WebCacheKey } from "../../clients/cache";
import { fetchSpaces } from "../../clients/Spaces/fetchSpaces";

function useSpaces(
  options?: UseQueryOptions<ISpace[]>
): UseQueryResult<ISpace[]> {
  const opts: UseQueryOptions<ISpace[]> = {
    ...options,
    refetchOnWindowFocus: false,
    refetchOnMount: false
  };
  return useQuery([WebCacheKey.SPACES], () => fetchSpaces(), opts);
}

export { useSpaces };
