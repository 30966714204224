import { FC } from "react";
import "./styles.scss";
import { IconName } from "./iconNames";

type Props = {
  name: IconName;
  weight?: number | string;
  filled?: boolean;
  size?: number | string;
  className?: string;
  onClick?: () => void;
};
const AEIcon: FC<Props> = ({
  name,
  weight,
  filled,
  size,
  className,
  onClick
}) => {
  const isFilled = Number(filled);
  return (
    <span
      onClick={onClick}
      className={`material-symbols-outlined ${className}`}
      style={{
        fontVariationSettings: `"wght" ${weight || 400}, "FILL" ${isFilled}`,
        fontSize: size || "1rem"
      }}
    >
      {name}
    </span>
  );
};

export default AEIcon;
