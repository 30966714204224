import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { AppType, UserRole } from "../../types/entities";
import { isSupportApp } from "../../utils/supportApp";

type SliceStateType = {
  appType: AppType;
  currentUrl: string;
  currentUserRole: UserRole;
};

const initialState: SliceStateType = {
  appType: isSupportApp ? AppType.SUPPORT_APP : AppType.WEB_APP,
  currentUrl: "",
  currentUserRole: {} as UserRole
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setAppType: (state, action: PayloadAction<AppType>) => {
      state.appType = action.payload;
    },
    updateCurrentUrl: (state, action) => {
      state.currentUrl = action.payload;
    },
    updateCurrentUserRole: (state, action: PayloadAction<UserRole>) => {
      state.currentUserRole = action.payload;
    }
  }
});

export const {
  setAppType,
  updateCurrentUrl,
  updateCurrentUserRole
} = appSlice.actions;

export default appSlice.reducer;
