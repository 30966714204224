import { useResizeObserver } from "@aptedge/lib-ui/src/hooks/useResizeObserver";
import { ILabel } from "@aptedge/lib-ui/src/types/entities";
import React, { useMemo, useRef } from "react";

type Options = {
  labels: ILabel[];
  minWidth?: number;
};

type Result = {
  ref: React.RefObject<HTMLDivElement>;
  labels: ILabel[];
  showMore: boolean;
};

function useLabelList(opts: Options): Result {
  const { labels: items, minWidth } = opts;

  const ref = useRef<HTMLDivElement>(null);

  // Watch for changes, and adjust the labels accordlingly.
  const size = useResizeObserver({ ref });

  const displayedLabels = useMemo(() => {
    const labels = items.slice();
    const maxWidth = (size.width || minWidth || 200) / 10;

    while (
      labels.length &&
      labels.reduce((sum, l) => sum + l.name.length, 0) > maxWidth
    ) {
      labels.pop();
    }

    return labels;
  }, [items, size.width, minWidth]);

  const showMore = !!items.length && items.length !== displayedLabels.length;

  return { ref, labels: displayedLabels, showMore };
}

export { useLabelList };
