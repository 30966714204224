import * as Cookies from "js-cookie";
import { isLocal } from "./config";

export type TimedResponse<T> = {
  data: T;
  startTimeMillis: number;
  endTimeMillis: number;
};

function makeTimedResponse<T>(data: T, startTime: number): TimedResponse<T> {
  return {
    data: data,
    startTimeMillis: startTime,
    endTimeMillis: Date.now()
  };
}

function decorateHeadersWithAuth(options: RequestInit = {}): RequestInit {
  options["headers"] = {
    ...options["headers"],
    "X-CSRF-Token": Cookies.get("csrf_access_token") || ""
  };

  if (isLocal()) {
    // Only use CORS in local dev.
    options["credentials"] = "include";
    options["mode"] = "cors";
  } else {
    // Disable CORS in prod.
    options["mode"] = "same-origin";
  }
  return options;
}

export { decorateHeadersWithAuth, makeTimedResponse };
