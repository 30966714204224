import { ITicketListing } from "@aptedge/lib-ui/src/types/entities";
import React, { useState } from "react";
import { PaginationAnalytics } from "../../components/PaginationAnalytics/PaginationAnalytics";
import { PaginationBar } from "../../components/PaginationBar/PaginationBar";
import { TicketModal } from "../../components/TicketModal/TicketModal";
import { TicketsList } from "../../components/TicketsList/TicketsList";
import { TicketThemesModal } from "../../components/TicketThemesModal/TicketThemesModal";
import { IPaginatedRequest, IPaginatedResponse } from "../../types/clients";

interface Props {
  isLoading: boolean;
  ticketsData?: IPaginatedResponse<ITicketListing[]>;
  onPageParamsUpdate: (params: Partial<IPaginatedRequest<unknown>>) => void;
  onTicketUpdate: () => void;
}

function PaginatedTicketsListing(props: Props): React.ReactElement {
  const { ticketsData, isLoading, onPageParamsUpdate, onTicketUpdate } = props;

  const tickets = ticketsData?.items || [];
  const pageData = ticketsData
    ? {
        page: ticketsData.page,
        perPage: ticketsData.perPage,
        total: ticketsData.total,
        totalPages: ticketsData.totalPages
      }
    : null;

  const [selectedTicket, setSelectedTicket] = useState<number>();
  const [selectedTicketOption, setSelectedTicketOption] = useState<
    ITicketListing
  >();

  return (
    <div className="paginated-tickets-listing">
      {pageData && <PaginationAnalytics data={pageData} isApproximate={true} />}

      <TicketsList
        tickets={tickets}
        isLoading={isLoading}
        onSelect={(ticket) => setSelectedTicket(ticket.id)}
        onOptionSelect={(ticket) => setSelectedTicketOption(ticket)}
      />

      {pageData && (
        <div className="mt-3">
          <PaginationBar
            page={pageData.page}
            totalPages={pageData.totalPages}
            onChange={onPageParamsUpdate}
          />
        </div>
      )}

      {/* Ticket Info */}
      <TicketModal
        ticketId={selectedTicket}
        isOpen={!!selectedTicket}
        onTicketUpdate={onTicketUpdate}
        onClose={() => setSelectedTicket(undefined)}
      />

      {/* Edit Ticket Themes */}
      {selectedTicketOption && (
        <TicketThemesModal
          ticket={selectedTicketOption}
          onTicketUpdate={onTicketUpdate}
          isOpen={!!selectedTicketOption}
          onClose={() => setSelectedTicketOption(undefined)}
        />
      )}
    </div>
  );
}

export { PaginatedTicketsListing };
