import { IAzureDevopsIssuesSource } from "@aptedge/lib-ui/src/types/entities";
import { getBackendUrl } from "../../config";
import { request } from "../../utils/request";

function fetchAzureDevopsIssueSources(): Promise<IAzureDevopsIssuesSource[]> {
  const url = `${getBackendUrl()}/azure-devops/tap`;

  return request(url);
}

export { fetchAzureDevopsIssueSources };
