import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import {
  IBusinessDataSource,
  IntegrationStatus,
  ISalesforceKnowledgeArticleSourceData,
  OAuthStatus
} from "@aptedge/lib-ui/src/types/entities";
import { GATEWAY_TIMEOUT } from "http-status-codes";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { createSalesforceKnowledgeArticleSource } from "../../../clients/ArticleSources/createSalesforceKnowledgeArticleSource";
import WithLoading from "../../../components/WithLoading/WithLoading";
import { TooltipMessage } from "../../../registry/TooltipMessage";
import { ClientStatus } from "../../../types/clients";
import { AddButton } from "../common/AddButton";
import { StatusButton } from "../common/StatusButton";

interface Props {
  businessResource?: IBusinessDataSource | null;
  refeshSalesforceKnowlege: () => void;
  salesforceKnowledgeResource: ISalesforceKnowledgeArticleSourceData | null;
  isLoading: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
}

const SalesforceKnowledgeConfig: React.FunctionComponent<Props> = (
  props: Props
) => {
  const [redirectStatus, setRedirectStatus] = useState(ClientStatus.PROMPT);
  const {
    businessResource: businessDataSource,
    refeshSalesforceKnowlege,
    salesforceKnowledgeResource,
    isLoading,
    error
  } = props;
  const isSalesforceKnowledgeConnectActive =
    businessDataSource?.oauthStatus === OAuthStatus.AUTHENTICATED ?? false;
  const isSalesforceKnowledgeAuthenticated =
    salesforceKnowledgeResource?.status === OAuthStatus.AUTHENTICATED;
  const addSource = useMutation(createSalesforceKnowledgeArticleSource, {
    onError: () => {
      setRedirectStatus(ClientStatus.ERROR);
    },
    onSuccess: () => {
      refeshSalesforceKnowlege();
      setRedirectStatus(ClientStatus.SUCCESS);
    }
  });
  const isLoaderVisible = redirectStatus === ClientStatus.LOADING || isLoading;
  const handleSalesforceKnowledgeConnectClick = async (): Promise<void> => {
    const fromBusinessDataSource = businessDataSource?.id;
    if (fromBusinessDataSource) {
      setRedirectStatus(ClientStatus.LOADING);
      addSource.mutate({ fromBusinessDataSource });
    }
  };

  const getStatus = (): IntegrationStatus => {
    if (error?.code === GATEWAY_TIMEOUT) return "timeOut";
    if (isSalesforceKnowledgeAuthenticated) return "ok";
    return "error";
  };

  return (
    <section>
      <div className="row">
        <WithLoading
          isLoading={isLoaderVisible}
          fallback={
            <div className="d-flex justify-content-center m-4">
              <Spinner />
            </div>
          }
        >
          <div className="mt-5 mb-3 pl-3">
            <h5>Salesforce Knowledge</h5>
            <p>Configure your Salesforce Knowledge settings below.</p>
          </div>
          <div className="col-md-12 p-2 pl-3">
            <>
              {salesforceKnowledgeResource ? (
                <StatusButton
                  isReAuthVisible={getStatus() !== "ok"}
                  handleReAuth={handleSalesforceKnowledgeConnectClick}
                  status={getStatus()}
                >
                  <div>Salesforce Knowledge</div>
                  <div className="text-muted">
                    <div>
                      {salesforceKnowledgeResource?.salesforceInstanceName ??
                        "Salesforce Knowledge"}
                    </div>
                  </div>
                </StatusButton>
              ) : (
                <AddButton
                  disabled={!isSalesforceKnowledgeConnectActive}
                  tooltip={
                    businessDataSource
                      ? TooltipMessage.SERVICE_CLOUD_NOT_CONNECTED
                      : TooltipMessage.SALESFORCE_NOT_CONNECTED
                  }
                  onClick={handleSalesforceKnowledgeConnectClick}
                >
                  Connect
                </AddButton>
              )}
            </>
          </div>
        </WithLoading>
      </div>
    </section>
  );
};

export { SalesforceKnowledgeConfig };
