import { ISavedQuery } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { ICreateSavedQueryArgs } from "../../types/clients";
import { request } from "../../utils/request";
import { post } from "../utils/buildRequest";

async function createSavedQuery(
  args: ICreateSavedQueryArgs
): Promise<ISavedQuery> {
  const url = `${getBackendApiUrl()}/saved-filters/`;
  const options = post(args);

  return request(url, options);
}

export { createSavedQuery };
