import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import { useOnMouseDownOutside } from "@aptedge/lib-ui/src/hooks/useOnMouseDownOutside";
import classNames from "classnames";
import React, { useRef, useState } from "react";
import { useQuery } from "react-query";
import { WebCacheKey } from "../../../clients/cache";
import { fetchKBExclusionConfig } from "../../../clients/KnowledgeBaseConfig/fetchKBExclusionConfig";
import WithLoading from "../../../components/WithLoading/WithLoading";
import { KnowledgeBaseCreateExclusion } from "./KnowledgeBaseCreateExclusion";
import { KnowledgeBaseExclusionItem } from "./KnowledgeBaseExclusionItem";

function KnowledgeBaseExclusions(): React.ReactElement {
  const [showAddUrl, setShowAddUrl] = useState(false);

  const knowledgeBaseExclusionQuery = useQuery(
    [WebCacheKey.KNOWLEDGE_BASE_EXCLUSIONS],
    () => fetchKBExclusionConfig(),
    { useErrorBoundary: true }
  );
  const exclusions = knowledgeBaseExclusionQuery.data || [];

  const wrapperRef = useRef<HTMLUListElement>(null);

  useOnMouseDownOutside(wrapperRef, () => {
    setShowAddUrl(false);
  });

  const handleSave = (): void => {
    setShowAddUrl(false);
    knowledgeBaseExclusionQuery.refetch();
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-start mt-4">
        <div className="pr-2">
          <h5>Exclusion Urls</h5>
          <p>Add URLs to ignore when searching for knowledge articles</p>
        </div>
        <div>
          <Button
            color="secondary"
            onClick={() => setShowAddUrl(true)}
            disabled={knowledgeBaseExclusionQuery.isLoading}
          >
            <Icons.Plus />
            <span>Add</span>
          </Button>
        </div>
      </div>
      <ul ref={wrapperRef} className={classNames("article-list list-group")}>
        {showAddUrl && (
          <KnowledgeBaseCreateExclusion
            onSave={handleSave}
            onCancel={() => setShowAddUrl(false)}
          />
        )}
        <WithLoading isLoading={knowledgeBaseExclusionQuery.isLoading}>
          {exclusions.map((e, key) => (
            <KnowledgeBaseExclusionItem
              key={`article-${key}`}
              url={e}
              onChange={() => knowledgeBaseExclusionQuery.refetch()}
            />
          ))}
        </WithLoading>
      </ul>
    </>
  );
}

export { KnowledgeBaseExclusions };
