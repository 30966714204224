import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { Feedback } from "@aptedge/lib-ui/src/components/Feedback/Feedback";
import { IEdgeInfo, IEdgeLink } from "@aptedge/lib-ui/src/types/entities";
import classNames from "classnames";
import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import "./AddEdgeLink.scss";

type Link = Omit<IEdgeLink, "urlReference">;

interface Props {
  edge: IEdgeInfo;
  onAdd: (link: Link) => void;
  onCancel: () => void;
}

const AddEdgeLink: React.FC<Props> = (props) => {
  const { edge, onAdd, onCancel } = props;

  const existingLinks = edge.links.map((link) => link.url.trim().toLowerCase());

  const inputRef = React.createRef<HTMLInputElement>();

  const formik = useFormik<Link>({
    validationSchema: Yup.object({
      url: Yup.string()
        .url("Please enter a valid url starting with https://.")
        .required("Please enter a valid url.")
        .test(
          "unique",
          "This Link has already been added.",
          (value) =>
            !!value && !existingLinks.includes(value.trim().toLowerCase())
        ),
      label: Yup.string().nullable()
    }),
    initialValues: {
      url: "",
      label: ""
    },
    onSubmit: onAdd
  });

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form
      className="add-edge-link-form"
      onSubmit={formik.handleSubmit}
      data-testid="new-edge-link"
    >
      <div className="form-row">
        <div className="form-group col-md-6">
          <label htmlFor="linkUrl">URL</label>
          <input
            id="linkUrl"
            ref={inputRef}
            name="url"
            placeholder="https://www.example.com"
            className={classNames("form-control", {
              "is-invalid": !!formik.errors.url
            })}
            value={formik.values.url}
            onChange={formik.handleChange}
          />
          {formik.errors.url && <Feedback>{formik.errors.url}</Feedback>}
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="linkLabel">Label</label>
          <input
            id="linkLabel"
            name="label"
            placeholder="Add a description..."
            className={classNames("form-control", {
              "is-invalid": !!formik.errors.label
            })}
            value={formik.values.label || ""}
            onChange={formik.handleChange}
          />
          {formik.errors.label && <Feedback>{formik.errors.label}</Feedback>}
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <Button
          className="mr-2"
          color="secondary"
          onClick={onCancel}
          type="reset"
        >
          Cancel
        </Button>
        <Button
          disabled={!formik.values.url || !!formik.errors.url}
          color="primary"
          type="submit"
        >
          Save
        </Button>
      </div>
    </form>
  );
};

export { AddEdgeLink };
