import { TemplateLocation } from "@aptedge/lib-ui/src/types/entities";
import React from "react";
import { useQuery } from "react-query";
import { WebCacheKey } from "../../../clients/cache";
import { fetchTemplates } from "../../../clients/Templates/fetchTemplates";
import { TemplateItem } from "./TemplateItem";

const TemplateSettings: React.FC = () => {
  const templateQuery = useQuery(WebCacheKey.TEMPLATES, fetchTemplates);

  const edgeDescriptionTemplate =
    templateQuery.data?.find(
      (template) => template.location === TemplateLocation.EDGE_DESCRIPTION
    ) || null;
  const ticketDecriptionTemplate =
    templateQuery.data?.find(
      (template) => template.location === TemplateLocation.TICKET_DESCRIPTION
    ) || null;

  return (
    <>
      <section className="row mb-5">
        <div className="col-md-5 p-2 pr-3">
          <h4 className="text-uppercase">Templates</h4>
          <p>
            Templates allow you to create boilerplate text that will prefill
            specific Text Editors in the Edge Workflow. Templates help your team
            write detailed notes about Edges.
          </p>
        </div>
        <div className="col-md-7 p-2 pl-3">
          <div className="mb-3">
            <h5>Edges</h5>
            <p>Create saved templates for Edge fields.</p>
          </div>

          <TemplateItem
            location={TemplateLocation.EDGE_DESCRIPTION}
            template={edgeDescriptionTemplate}
            onChange={() => templateQuery.refetch()}
          />

          <div className="mt-5">
            <h5>Tickets</h5>
            <p>Create saved templates for Ticket fields.</p>
          </div>

          <TemplateItem
            location={TemplateLocation.TICKET_DESCRIPTION}
            template={ticketDecriptionTemplate}
            onChange={() => templateQuery.refetch()}
          />
        </div>
      </section>
      <hr className="mt-4" />
    </>
  );
};

export { TemplateSettings };
