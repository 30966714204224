import { makeAnswerAPICallArgs } from "@aptedge/lib-ui/src/components/Search/hooks/useAiAnswerQuery";
import {
  GQADocSearchResult,
  ISearchFilter
} from "@aptedge/lib-ui/src/types/entities";
import { TimedResponse } from "@aptedge/lib-ui/src/utils/request";
import { getBackendApiUrl } from "../../config";
import { timedRequest } from "../../utils/request";
import { toQueryString } from "../utils/url";

async function gqaSearch(
  args: makeAnswerAPICallArgs
): Promise<TimedResponse<GQADocSearchResult>> {
  // encode query string
  const format = `qna--${args.format}`;
  const encodedQuery = toQueryString({
    ...args,
    format,
    sources: args.filterSubType,
    previews: true
  });
  // FIXME this replaces anything in the query string, including the search query itself!
  const newEncodedQuery = encodedQuery
    .replace("per-page", "per_page")
    .replace("additional-instructions", "additional_instructions")
    .replace("answer-id", "answer_id")
    .replace("first-request", "first_request")
    .replace("product-filters", "product_filters")
    .replace("quick-filters", "quick_filters");

  let url = "";
  if (args.filter !== ISearchFilter.ALL) {
    url = `${getBackendApiUrl()}/search/${args.filter}/?${newEncodedQuery}`;
  } else {
    url = `${getBackendApiUrl()}/search/?${newEncodedQuery}`;
  }

  return timedRequest<GQADocSearchResult>(url);
}
export { gqaSearch };
export type { makeAnswerAPICallArgs };
