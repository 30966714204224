import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { MutationErrorToast } from "../../../components/ErrorHandler/MutationErrorToast";
import { DeleteIcon } from "./DeleteIcon";

interface Props {
  deleteFn: () => Promise<unknown>;
  renderContent: () => React.ReactElement;
  renderConfirmation: () => React.ReactElement;
  onCancel?: () => void;
  onConfirm?: () => void;
}

function InlineDeleteConfirmation(props: Props): React.ReactElement {
  const {
    deleteFn: mutationFn,
    renderConfirmation,
    renderContent,
    onCancel,
    onConfirm
  } = props;
  const [showConfirm, setShowConfirm] = useState(false);

  const mutation = useMutation(mutationFn, {
    onSuccess: () => {
      onConfirm?.();
      setShowConfirm(false);
    }
  });

  return (
    <>
      {!showConfirm ? (
        <>
          {renderContent()}
          <div className="d-flex align-items-center">
            <DeleteIcon onClick={() => setShowConfirm(true)} />
          </div>
        </>
      ) : (
        <>
          {renderConfirmation()}
          <div className="d-flex">
            <Button
              color="secondary"
              className="mr-2"
              onClick={() => {
                onCancel?.();
                setShowConfirm(false);
              }}
            >
              Cancel
            </Button>
            <Button color="danger" onClick={() => mutation.mutate()}>
              Delete
            </Button>
          </div>
        </>
      )}
      <MutationErrorToast error={mutation.error} resetError={mutation.reset} />
    </>
  );
}

export { InlineDeleteConfirmation };
