import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { ModalFooter } from "@aptedge/lib-ui/src/components/Modal/Modal";
import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import {
  IEdgeInfo,
  IEdgeSearchResult,
  ITicketListing
} from "@aptedge/lib-ui/src/types/entities";
import React, { useEffect } from "react";
import { UseMutationResult } from "react-query";
import { IMigrateEdgeTicketsArgs } from "../../../types/clients";
import { MigrateTicketModal } from "./types";
interface Props {
  client: UseMutationResult<IEdgeInfo, Error, IMigrateEdgeTicketsArgs>;
  selectedTickets: ITicketListing[];
  selectedEdge: IEdgeSearchResult;
  setCurrentPage: (page: MigrateTicketModal) => void;
  sourceEdge: IEdgeInfo;
}

function EdgeMigrateModalExec(props: Props): React.ReactElement {
  const {
    selectedTickets,
    client,
    selectedEdge,
    setCurrentPage,
    sourceEdge
  } = props;

  const tickets = selectedTickets.map((t) => ({
    id: t.id
  }));

  useEffect(() => {
    client.mutate({
      sourceEdge: { id: sourceEdge.id },
      targetEdge: { id: selectedEdge.id },
      tickets
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="migrate-tickets-content loading-screen">
        {client.isLoading && <Spinner />}
        {client.isError && (
          <div className="text-center text-danger">
            We&apos;re sorry. Something went wrong.
          </div>
        )}
      </div>
      {client.isError && (
        <ModalFooter>
          <div className="d-flex justify-content-start">
            <Button
              color="secondary"
              onClick={() => setCurrentPage(MigrateTicketModal.THREE)}
            >
              Go Back
            </Button>
          </div>
        </ModalFooter>
      )}
    </>
  );
}

export { EdgeMigrateModalExec };
