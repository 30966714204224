import {
  IQueryFilter,
  IQueryFilterId,
  IQueryFilterOperator
} from "@aptedge/lib-ui/src/components/Filter/types";
import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import { useQueryParams } from "@aptedge/lib-ui/src/hooks/useQueryParams";
import {
  useAppDispatch,
  useAppSelector
} from "@aptedge/lib-ui/src/redux/hook/hook";
import {
  updateTabArticleStatus,
  updatePage,
  updateTotalPages
} from "@aptedge/lib-ui/src/redux/reduxSlice/kbGenSlice";
import {
  ArticleCreationState,
  ArticleStatus,
  GeneratedKnowledgeResult,
  QUERY_PARAMS
} from "@aptedge/lib-ui/src/types/entities";
import { useState } from "react";
import { useQuery } from "react-query";
import { WebCacheKey } from "../clients/cache";
import { fetchArticles } from "../clients/GeneratedKnowledge/fetchArticles";
import { generateArticle } from "../clients/GeneratedKnowledge/generateArticle";
import { useAuth } from "../context/AuthContext";

type ArticleReview = {
  isArticleLoading: boolean;
  isKbDraftPreparing: boolean;
  showErrorMessage: boolean;
  showEmptyState: boolean;
  showArticleItems: boolean;
  articleList: GeneratedKnowledgeResult[];
  handlePaginationChange: ({ page }: { page: number }) => void;
  ticketId: string;
  totalPages: number | undefined;
  page: number;
};

const PER_PAGE = 10;

function useArticleReview(): ArticleReview {
  const { flags } = useFlags();
  const { user: currentUser } = useAuth();
  const { queryParams, removeQueryParams, updateParams } = useQueryParams();
  const [articleList, setArticleList] = useState<GeneratedKnowledgeResult[]>(
    []
  );
  const articleStatus = queryParams.get(QUERY_PARAMS.ARTICLE_STATUS) || "";
  const ticketId = queryParams.get("ticketId") || "";
  const articleState =
    queryParams.get(QUERY_PARAMS.ARTICLE_CREATION_STATE) || "";

  const { page, totalPages } = useAppSelector((state) => state.knowledgeBase);

  const dispatch = useAppDispatch();

  const prepareFilters = (
    id: IQueryFilterId,
    operator: IQueryFilterOperator,
    values: string[]
  ): IQueryFilter => {
    return {
      id,
      operator,
      values
    };
  };

  const getFilters = (): IQueryFilter[] => {
    const newFilters: IQueryFilter[] = [];
    if (!flags.kbGenPublishProcess) {
      newFilters.push(
        prepareFilters("status", "equals", [ArticleStatus.DRAFT])
      );
    } else {
      if (articleStatus === ArticleStatus.DRAFT) {
        newFilters.push(
          ...[
            prepareFilters("status", "equals", [ArticleStatus.DRAFT]),
            prepareFilters("createdBy", "equals", [String(currentUser?.id)])
          ]
        );
      }
      if (articleStatus === ArticleStatus.IN_REVIEW) {
        newFilters.push(
          ...[
            prepareFilters("status", "equals", [ArticleStatus.IN_REVIEW]),
            prepareFilters("reviewedBy", "equals", [String(currentUser?.id)])
          ]
        );
      }
    }
    return newFilters;
  };

  const handlePaginationChange = ({ page }: { page: number }): void => {
    updateParams(QUERY_PARAMS.PAGE, page.toString());
    dispatch(updatePage(page));
  };

  const { isLoading: isKbDraftPreparing } = useQuery(
    [WebCacheKey.TICKET_KB_ARTICLE, ticketId],
    () => generateArticle(ticketId),
    {
      enabled: !!ticketId && articleState === ArticleCreationState.GENERATION,
      retry: 3,
      staleTime: Infinity, // one hour
      onSuccess: () => {
        if (articleState === ArticleCreationState.GENERATION) {
          removeQueryParams(QUERY_PARAMS.ARTICLE_CREATION_STATE);
        }
      }
    }
  );

  const { isLoading: isArticleLoading, isError: showErrorMessage } = useQuery(
    [WebCacheKey.GENERATED_KNOWLEDGE_PUBLISHED, ticketId],
    () =>
      fetchArticles({
        perPage: flags.kbGenPublishProcess ? PER_PAGE : undefined,
        page,
        filters: getFilters(),
        ticketId,
        include: "body"
      }),
    {
      enabled:
        (!!ticketId && articleState !== ArticleCreationState.GENERATION) ||
        (!ticketId &&
          (articleStatus === ArticleStatus.DRAFT ||
            articleStatus === ArticleStatus.IN_REVIEW)),
      retry: 2,
      onSuccess: (data) => {
        if (data) {
          const unpublishedArticlesSorted = data.items.sort((a, b) => {
            if (a.id < b.id) {
              return -1;
            }
            if (a.id > b.id) {
              return 1;
            }
            return 0;
          });
          setArticleList(unpublishedArticlesSorted);
          dispatch(updateTotalPages(data.totalPages));
        }
        if (articleState === ArticleCreationState.GENERATED) {
          removeQueryParams(QUERY_PARAMS.ARTICLE_CREATION_STATE);
        }
        if (articleStatus) {
          dispatch(updateTabArticleStatus(ArticleStatus.ALL));
        }
      }
    }
  );

  const showEmptyState = articleList.length === 0;
  const showArticleItems = articleList.length > 0;

  return {
    isArticleLoading,
    isKbDraftPreparing,
    showErrorMessage,
    showEmptyState,
    showArticleItems,
    articleList,
    handlePaginationChange,
    ticketId,
    totalPages,
    page
  };
}

export { useArticleReview };
