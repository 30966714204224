import { Popover } from "@aptedge/lib-ui/src/components/Popover/Popover";
import { DEFAULT_PREVENT_OVERFLOW_MODIFIER } from "@aptedge/lib-ui/src/components/Popper/popper";
import { ProgressBar } from "@aptedge/lib-ui/src/components/ProgressBar/ProgressBar";
import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import { useOnKeydownOutside } from "@aptedge/lib-ui/src/hooks/useOnKeyDownOutside";
import { useOnMouseDownOutside } from "@aptedge/lib-ui/src/hooks/useOnMouseDownOutside";
import { Keys } from "@aptedge/lib-ui/src/styles/keys";
import { stopPropagation } from "@aptedge/lib-ui/src/utils/eventUtils";
import React from "react";
import { useQuery } from "react-query";
import { WebCacheKey } from "../../clients/cache";
import { fetchUnreadNotificationCount } from "../../clients/Notifications/fetchUnreadNotificationCount";
import {
  jobIsPending,
  useJobStatus,
  useRecentJob
} from "../../hooks/clients/useJobStatus";
import { NotificationList } from "../Notifications/NotificationList";
import { ReactComponent as BellIcon } from "./bell.svg";
import "./Notifications.scss";

const TWO_MINUTES = 60 * 1000 * 2;

const Notifications: React.FC = () => {
  const { flags } = useFlags();
  const wrapperRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [recentJob, setRecentJob] = useRecentJob();
  const jobId = recentJob?.id ?? "";

  const jobStatusQuery = useJobStatus(jobId, {
    enabled: !!jobId && flags.edgeBulkReply,
    onSuccess: (data) => {
      if (data.status === "success") {
        setRecentJob(null);
      }
    }
  });

  const jobStatus = jobStatusQuery.data?.status;

  const unreadRQ = useQuery(
    WebCacheKey.NOTIFICATION_COUNT,
    () => fetchUnreadNotificationCount(),
    {
      refetchInterval: TWO_MINUTES
    }
  );

  const totalUnread = unreadRQ.data?.totalUnread || 0;

  const handleMarkRead = (): void => {
    unreadRQ.refetch();
  };

  const handleClick = (): void => {
    unreadRQ.refetch();
    setIsOpen(false);
  };

  useOnMouseDownOutside(wrapperRef, () => setIsOpen(false));
  useOnKeydownOutside(wrapperRef, [Keys.ESCAPE, Keys.TAB], () =>
    setIsOpen(false)
  );

  return (
    <div className="notifications">
      <Popover.Container>
        <Popover.Toggle
          onMouseDown={stopPropagation(() => setIsOpen((open) => !open))}
        >
          <div className="bell" data-testid="notification-bell">
            <BellIcon fontSize={24} />
            {!!totalUnread && (
              <div className="count">
                {totalUnread >= 100 ? "" : totalUnread}
              </div>
            )}
            {flags.edgeBulkReply && !!jobStatus && jobIsPending(jobStatus) && (
              <div className="progress-bar-container">
                <ProgressBar size={4} />
              </div>
            )}
          </div>
        </Popover.Toggle>
        <Popover.Content
          isOpen={isOpen}
          placement="bottom"
          modifiers={[
            { name: "offset", options: { offset: [0, 8] } },
            DEFAULT_PREVENT_OVERFLOW_MODIFIER
          ]}
        >
          <div className="notifications-popper" ref={wrapperRef}>
            <NotificationList
              unreadCount={totalUnread}
              onMarkRead={handleMarkRead}
              onClick={handleClick}
            />
          </div>
        </Popover.Content>
      </Popover.Container>
    </div>
  );
};

export { Notifications };
