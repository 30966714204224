import { Checkbox } from "@aptedge/lib-ui/src/components/Checkbox/Checkbox";
import { useModalRouter } from "@aptedge/lib-ui/src/components/Modal/ModalRouter";
import classNames from "classnames";
import { capitalize } from "lodash";
import React, { useState } from "react";
import { useBulkReplyContext } from "../../../context/BulkReplyContext";
import { useTicketStatuses } from "../../../hooks/clients/useTicketStatuses";
import { Page } from "./BulkReplyRoutes";
import { BulkStatusDropdown } from "./BulkStatusDropdown";
import "./BulkStatusChange.scss";

const BulkStatusChange: React.FC = () => {
  const [{ status }, dispatch] = useBulkReplyContext();
  const ticketStatusQuery = useTicketStatuses();
  const router = useModalRouter();
  const [checked, setChecked] = useState<boolean>(!!status.to);

  const statuses =
    ticketStatusQuery.data?.statuses
      .slice()
      .map((s) => capitalize(s))
      .sort((a, b) => a.localeCompare(b)) || [];

  const handleCheckbox = (): void => {
    if (checked) {
      dispatch({ type: "setToStatus", payload: { to: undefined } });
    }

    setChecked(!checked);
  };

  if (router.currentRoute === Page.CONFIRM && !status.to) {
    return null;
  } else if (router.currentRoute === Page.CONFIRM) {
    return (
      <div className={classNames("bulk-status-change")}>
        {status.from === "All" ? (
          <>
            <span className="bold">{status.from}</span>
            {` ticket statuses will be
            changed to `}
          </>
        ) : (
          <>
            {`Tickets with statuses of `}
            <span className="bold">{status.from}</span>
            {` will be changed to `}
          </>
        )}
        <span className="bold">{status.to}</span>
      </div>
    );
  }

  return (
    <div className={classNames("bulk-status-change")}>
      <div className="checkbox-container" onClick={handleCheckbox}>
        <Checkbox checked={checked} onChange={handleCheckbox} />
        <span>Change tickets status at the same time.</span>
      </div>
      {checked && (
        <div>
          <span>From</span>
          <BulkStatusDropdown
            data={["All", ...statuses]}
            status={status.from}
            onClick={(from) =>
              dispatch({ type: "setFromStatus", payload: { from } })
            }
            disabled={true}
          />
          <span>to</span>
          <BulkStatusDropdown
            data={statuses}
            status={status.to}
            onClick={(to) => dispatch({ type: "setToStatus", payload: { to } })}
          />
        </div>
      )}
    </div>
  );
};

export { BulkStatusChange };
