import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import {
  ILabelSetting,
  LabelEntity,
  LabelEntityType
} from "@aptedge/lib-ui/src/types/entities";
import classNames from "classnames";
import pluralize from "pluralize";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { deleteLabel } from "../../../clients/Labels/deleteLabel";
import { DeleteIcon } from "../common/DeleteIcon";
import { ListItem } from "../common/ListItem";
import { LabelCreateItem } from "./LabelCreateItem";

interface ItemProps {
  item: ILabelSetting;
  className?: string;
  onShowAdd?: () => void;
  onDelete: () => void;
  onCreate?: () => void;
}

const LabelsListItem: React.FC<ItemProps> = (props) => {
  const { item, className, onDelete, onShowAdd, onCreate } = props;

  const [showConfirm, setShowConfirm] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const canAddChild = item.type === LabelEntity.PRODUCT;

  const removeLabel = useMutation(deleteLabel, { onSuccess: onDelete });

  const handleShowAdd = (): void => {
    setShowAdd(true);
    if (onShowAdd) onShowAdd();
  };

  const handleSave = (): void => {
    setShowAdd(false);
    if (onCreate) onCreate();
  };

  return (
    <>
      <ListItem
        className={classNames(className, "group", {
          "can-add": canAddChild,
          "is-adding": showAdd
        })}
      >
        {!showConfirm ? (
          <>
            <div>{item.name}</div>

            <div className="d-flex align-items-center">
              <span className="badge text-uppercase text-muted mr-3">
                {item.type}
              </span>
              <DeleteIcon onClick={() => setShowConfirm(true)} />
            </div>
          </>
        ) : (
          <>
            <span className="text-muted pr-2">
              <ConfirmMessage label={item} />
            </span>
            <div className="d-flex">
              <Button
                color="secondary"
                className="mr-2"
                onClick={() => setShowConfirm(false)}
              >
                Cancel
              </Button>
              <Button
                color="danger"
                onClick={() =>
                  removeLabel.mutate({ id: item.id, type: item.type })
                }
              >
                Delete
              </Button>
            </div>
          </>
        )}
        {/* </div> */}
        {canAddChild && !showConfirm && (
          <AddBar>
            <div
              className="btn"
              onClick={handleShowAdd}
              data-testid="add-bar-button"
            >
              <Icons.Plus />
            </div>
          </AddBar>
        )}
      </ListItem>
      {canAddChild && !showConfirm && showAdd && onCreate && (
        <LabelCreateItem
          parent={item}
          className="pl-5 nested-create"
          heading={`New feature label for ${item.name}`}
          type={getChildTypeForParent(item)}
          onCancel={() => setShowAdd(false)}
          onSave={handleSave}
        />
      )}
    </>
  );
};

const ConfirmMessage: React.FC<{ label: ILabelSetting }> = ({ label }) => {
  const childCount = label.children?.length || 0;

  return (
    <>
      <span>Are you sure you want to delete {label.name}</span>
      {childCount > 0 && (
        <span>
          <span> and its </span>
          <span className="text-danger">
            {childCount} nested {pluralize("label", childCount)}
          </span>
        </span>
      )}
      <span>?</span>
    </>
  );
};

const AddBar: React.FC<{ className?: string }> = ({ children, className }) => {
  return <div className={classNames("add-bar", className)}>{children}</div>;
};

function getChildTypeForParent(parent: ILabelSetting): LabelEntityType {
  switch (parent.type) {
    case LabelEntity.PRODUCT:
      return LabelEntity.FEATURE;
    default:
      throw new Error(`Unsupported parent type ${parent.type}, ${parent.id}`);
  }
}

export { LabelsListItem };
