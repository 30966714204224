import { IProduct } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";

async function fetchProducts(): Promise<IProduct[]> {
  const url = `${getBackendApiUrl()}/products/`;

  return request(url);
}

export { fetchProducts };
