import React from "react";
import "./Breadcrumbs.scss";

const Breadcrumbs: React.FunctionComponent = ({ children }) => {
  const length = React.Children.count(children);

  return (
    <div className="bread-crumb">
      {React.Children.map(children, (child, index) => {
        return (
          <>
            {child}
            {index < length - 1 && <span className="spacer">/</span>}
          </>
        );
      })}
    </div>
  );
};

export { Breadcrumbs };
