import { IEdgeInfo } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";
import { post } from "../utils/buildRequest";

async function createEdge(name: string): Promise<IEdgeInfo> {
  const url = `${getBackendApiUrl()}/edges/`;
  const options = post({ name });

  return request(url, options);
}

export { createEdge };
