import {
  IMSTeamsSocialChannelInfoRequest,
  IMSTeamsGroup
} from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../../config";
import { request } from "../../../utils/request";
import { post } from "../../utils/buildRequest";

async function getMSTeamsChannelInfo(
  args: IMSTeamsSocialChannelInfoRequest
): Promise<IMSTeamsGroup> {
  const url = `${getBackendApiUrl()}/integrations/msteams/channel-info`;
  const groupUrl = args.groupUrl;
  const options: RequestInit = post({ groupUrl });
  return request(url, options);
}

export { getMSTeamsChannelInfo };
