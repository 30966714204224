import CodeMirror, { Editor, EditorConfiguration } from "codemirror";
import { EditorUtils } from "./EditorUtils";
import { MarkdownInlineType } from "./types";
import "./modes/list";

require("codemirror/addon/selection/mark-selection");
require("codemirror/addon/edit/continuelist");
require("codemirror/mode/markdown/markdown");
require("codemirror/mode/gfm/gfm");
require("codemirror/mode/xml/xml");

export const supportedImageTypes = [
  "image/jpeg",
  "image/gif",
  "image/png",
  "image/tiff"
];

const createCodeMirror = (
  el: HTMLDivElement,
  opts: Pick<EditorConfiguration, "autofocus" | "value" | "screenReaderLabel">
): Editor => {
  return CodeMirror(el, {
    mode: { name: "gfm", gitHubSpice: false },
    value: opts.value || "",
    screenReaderLabel: opts.screenReaderLabel,
    theme: "paper",
    tabSize: 2,
    indentUnit: 2,
    indentWithTabs: true,
    lineNumbers: false,
    autofocus: !!opts.autofocus,
    extraKeys: {
      Enter: "newlineAndIndentContinueMarkdownList",
      "Cmd-B": (e: Editor) =>
        EditorUtils.toggleInlineStyle(e, MarkdownInlineType.BOLD),
      "Ctrl-B": (e: Editor) =>
        EditorUtils.toggleInlineStyle(e, MarkdownInlineType.BOLD),
      "Cmd-I": (e: Editor) =>
        EditorUtils.toggleInlineStyle(e, MarkdownInlineType.ITALIC),
      "Ctrl-I": (e: Editor) =>
        EditorUtils.toggleInlineStyle(e, MarkdownInlineType.ITALIC),
      Tab: "tabAndIndentMarkdownList",
      "Shift-Tab": "shiftTabAndUnindentMarkdownList"
    },
    lineWrapping: true,
    allowDropFileTypes: ["text/plain", ...supportedImageTypes]
  });
};

export { createCodeMirror };
