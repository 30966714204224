import { AeIcons } from "@aptedge/lib-ui/src/components/Icon/AeIcons";
import SkeletonLoader from "@aptedge/lib-ui/src/components/SkeletonLoader/SkeletonLoader";
import useAiAnswer from "@aptedge/lib-ui/src/features/AiAnswer/components/AiAnswer/useAiAnswer";
import MarkdownAnswer from "@aptedge/lib-ui/src/features/AiAnswer/components/MarkdownAnswer";
import { useQueryParams } from "@aptedge/lib-ui/src/hooks/useQueryParams";
import {
  useAppDispatch,
  useAppSelector
} from "@aptedge/lib-ui/src/redux/hook/hook";
import { updateAnswerCardVisibility } from "@aptedge/lib-ui/src/redux/reduxSlice/answerGPTSlice";
import { updateSearchCardVisibility } from "@aptedge/lib-ui/src/redux/reduxSlice/searchSlice";
import { QUERY_PARAMS } from "@aptedge/lib-ui/src/types/entities";
import classNames from "classnames";
import styles from "./AnswerSearchResult.module.scss";

const AnswerSearchResult: React.FC = () => {
  const { removeQueryParams } = useQueryParams();
  const { mappedResults, totalSearchResults } = useAiAnswer();
  const { isSearchLoading } = useAppSelector((state) => state.search);
  const {
    answer,
    answerCardVisibility,
    isAnswerLoaded,
    isAnswerLoading
  } = useAppSelector((state) => state.answerGPT);

  const dispatch = useAppDispatch();

  const showPreview = (): void => {
    dispatch(updateAnswerCardVisibility(true));
    dispatch(updateSearchCardVisibility(false));
    removeQueryParams(QUERY_PARAMS.RESULT_ID);
  };

  const isLoadingState = isSearchLoading || isAnswerLoading || !isAnswerLoaded;

  return (
    <div
      className={classNames(styles.answerSearchResult, {
        [styles.answerCardVisible]: answerCardVisibility
      })}
      onClick={showPreview}
    >
      <div className={styles.answerHeader}>
        <AeIcons.AiAnswer />
        <div className={styles.title}>Answer AI</div>
      </div>
      {isLoadingState ? (
        <SkeletonLoader lines={3} containerClassName={styles.loader} />
      ) : (
        <div className={styles.answerBody}>
          {answer && isAnswerLoaded && (
            <MarkdownAnswer
              resultsMap={mappedResults}
              totalResults={totalSearchResults}
              answer={answer}
            />
          )}
        </div>
      )}
    </div>
  );
};

export { AnswerSearchResult };
