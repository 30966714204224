import React from "react";
import {
  ToastPosition,
  ToastContainer as ToastifyToastContainer
} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ToastContainer.scss";

const ToastContainer: React.FunctionComponent = () => {
  return (
    <ToastifyToastContainer
      className="ae-toast-container"
      position={ToastPosition.TOP_RIGHT}
      draggable={false}
      rtl={false}
      hideProgressBar
      closeOnClick
      pauseOnHover
      newestOnTop
    />
  );
};

export { ToastContainer };
