import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";
import { post } from "../utils/buildRequest";

type Response = { token: string };

async function createNewZendeskAppToken(): Promise<Response> {
  const url = `${getBackendApiUrl()}/ticket-source/generate-new-token/`;
  const options = post({});

  return request(url, options);
}

export { createNewZendeskAppToken };
