import { Icon } from "@aptedge/lib-ui/src/components/Icon/Icon";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight";
import classNames from "classnames";
import React from "react";
import "./ArrowTransition.scss";

interface Props {
  from: React.ReactNode | null;
  to: React.ReactNode | null;
}

function ArrowTransition(props: Props): React.ReactElement {
  const { from, to } = props;
  const showArrow = !!from && !!to;

  return (
    <div className={classNames("arrow-transition container")}>
      <div className="row" data-testid="audit-arrow">
        {from && (
          <div
            className={classNames("from", "col", {
              "mr-2": showArrow
            })}
          >
            {from}
          </div>
        )}
        {showArrow && (
          <div className="arrow col-1">
            <Icon icon={faArrowRight} />
          </div>
        )}
        {to && (
          <div className={classNames("to", "col", { "ml-3": showArrow })}>
            {to}
          </div>
        )}
      </div>
    </div>
  );
}

export { ArrowTransition };
