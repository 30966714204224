import SearchFeedback, {
  FeedbackSource
} from "@aptedge/lib-ui/src/components/SearchFeedback/SearchFeedback";
import useAiAnswers from "@aptedge/lib-ui/src/features/AiAnswer/useAiAnswers";
import { FC } from "react";
import AiAnswerPreview from "./AiAnswerPreview";
import "./AnswerPreview.scss";

interface IAnswers {
  showFeedback: boolean;
}

const AnswerPreview: FC<IAnswers> = ({ showFeedback }) => {
  const { flags, answerRef, answerId } = useAiAnswers();
  return (
    <div className="answers-preview-container" ref={answerRef}>
      <AiAnswerPreview />
      {showFeedback && (
        <SearchFeedback
          showFeedback={showFeedback}
          feedbackOptions={flags.feedbackOptions.answerFeedbackLabels}
          eventSource={FeedbackSource.ANSWERGPT}
          key={answerId}
          feedbackId={answerId}
        />
      )}
    </div>
  );
};

export default AnswerPreview;
