import { FC } from "react";
import SectionLayout from "../../../common/SectionLayout/SectionLayout";
import ConfigInput from "../../ConfigInput/ConfigInput";
import styles from "./styles.module.scss";

type Props = {
  saveConfig: () => void;
  isSaved: boolean;
  isLoading: boolean;
  isError: string[];
};
export const SaveConfig: FC<Props> = ({
  isSaved,
  saveConfig,
  isLoading,
  isError
}) => {
  return (
    <SectionLayout title="" description="" divider={false}>
      <ConfigInput title="" description="">
        <div className={styles.saveConfig}>
          <button
            onClick={saveConfig}
            disabled={isLoading || isError.length > 0}
          >
            Save
          </button>
          {!isSaved && (
            <div className={styles.warningText}>
              Your settings have not been saved yet. Please click the
              <strong> Save CSS App Configuration </strong>
              button to save your configuration.
            </div>
          )}
        </div>
      </ConfigInput>
    </SectionLayout>
  );
};
