import { ITicketSource } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { ITicketSourceRequest } from "../../types/clients";
import { request } from "../../utils/request";
import { post } from "../utils/buildRequest";

async function createTicketSource(
  args: ITicketSourceRequest
): Promise<ITicketSource> {
  const url = `${getBackendApiUrl()}/ticket-sources/`;

  const options: RequestInit = post(args);

  return request(url, options);
}

export { createTicketSource };
