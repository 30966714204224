import classNames from "classnames";
import React from "react";
import "./Pill.scss";

const Pill: React.FunctionComponent<Props> = ({ onClick, text, className }) => {
  return (
    <div
      className={classNames("pill", className, { hoverable: !!onClick })}
      onClick={onClick}
    >
      {text}
    </div>
  );
};

interface Props {
  text: string;
  onClick?: () => void;
  className?: string;
}

export { Pill };
