import classNames from "classnames";
import range from "lodash/range";
import { DateTime, Interval } from "luxon";
import React from "react";
import { Day } from "./Day";
import "./Week.scss";

interface Props {
  week: DateTime;
  currentMonth: DateTime;
  min?: DateTime;
  max?: DateTime;
  selected?: DateTime;
  selectedRange?: Interval;
  onSelect: (day: DateTime) => void;
  "data-testid"?: string;
}

const Week: React.FC<Props> = ({
  selected,
  selectedRange,
  currentMonth,
  week,
  min,
  max,
  onSelect,
  ...rest
}) => {
  const isDisabled = (d: DateTime): boolean => {
    return (
      (!!min && d.startOf("day") < min.startOf("day")) ||
      (!!max && d.startOf("day") > max.startOf("day"))
    );
  };

  const isInsideRange = (d: DateTime): boolean => {
    return !!selectedRange && selectedRange.contains(d);
  };

  const isToday = (d: DateTime): boolean => {
    return datesAreEqual(d, DateTime.local());
  };

  const isSelected = (d: DateTime): boolean => {
    return (
      (!!selected && stringifyDate(d) === stringifyDate(selected)) ||
      (!!selectedRange && datesAreEqual(selectedRange.start, d)) ||
      (!!selectedRange && datesAreEqual(selectedRange.end, d))
    );
  };

  const handleSelect = (d: DateTime): void => {
    if (!isDisabled(d)) onSelect(d);
  };

  return (
    <div className="week" data-testid={rest["data-testid"]}>
      {range(7)
        .map((plus) => week.plus({ days: plus }))
        .map((d, idx) => (
          <Day
            key={d.weekday}
            className={classNames({
              today: isToday(d),
              selected: isSelected(d),
              disabled: isDisabled(d),
              "outside-month": d.month !== currentMonth.month,
              "inside-range": isInsideRange(d)
            })}
            day={d}
            onClick={() => handleSelect(d)}
            data-testid={`day-${d.toFormat("M-d-yyyy")}`}
          />
        ))}
    </div>
  );
};

function datesAreEqual(d1: DateTime, d2: DateTime): boolean {
  return stringifyDate(d1) === stringifyDate(d2);
}

function stringifyDate(d: DateTime): string {
  return d.toSQLDate();
}

export { Week };
