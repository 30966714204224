import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { ModalFooter } from "@aptedge/lib-ui/src/components/Modal/Modal";
import {
  NoData,
  NoDataMessage
} from "@aptedge/lib-ui/src/components/NoData/NoData";
import { Pill } from "@aptedge/lib-ui/src/components/Pill/Pill";
import {
  IEdgeSearchResult,
  ITicketListing
} from "@aptedge/lib-ui/src/types/entities";
import { GTM_EVENTS, dataLayerPush } from "@aptedge/lib-ui/src/utils/event";
import React from "react";
import { EdgeSearchResult } from "../../../components/EdgeSearchResult/EdgeSearchResult";
import { MigrateTicketModal } from "./types";

interface Props {
  selectedEdge?: IEdgeSearchResult;
  selectedTickets: ITicketListing[];
  setCurrentPage: (page: MigrateTicketModal) => void;
}

function EdgeMigrateModalConfirm(props: Props): React.ReactElement {
  const { setCurrentPage, selectedEdge, selectedTickets } = props;

  const handleMigrateEdge = (): void => {
    setCurrentPage(MigrateTicketModal.FOUR);
    dataLayerPush({ event: GTM_EVENTS.GTM_MIGRATE_EDGE_EXAMPLE });
  };

  return (
    <>
      <p className="disclaimer">
        Migrating moves one or more examples from this edge to another edge.
      </p>
      <div className="migrate-tickets-content">
        <h5>Confirm Changes</h5>
        <div className="confirm-section">
          <h5>Migrate:</h5>
          <div className="pill-container">
            {!!selectedTickets.length ? (
              selectedTickets.map((t) => (
                <Pill key={t.id} text={`${t.externalId}: ${t.subject}`}></Pill>
              ))
            ) : (
              <NoData message={NoDataMessage.NO_TICKETS_SELECTED} />
            )}
          </div>
        </div>
        <div className="confirm-section">
          <h5>To the following Edge:</h5>
          <div>
            {!!selectedEdge ? (
              <EdgeSearchResult
                edge={selectedEdge}
                isSelected={true}
                showHighlight={false}
              />
            ) : (
              <NoData message={NoDataMessage.NO_EDGE_SELECTED} />
            )}
          </div>
        </div>
      </div>
      <ModalFooter>
        <div className="d-flex justify-content-between align-items-center">
          <Button
            color="primary"
            onClick={() => setCurrentPage(MigrateTicketModal.TWO)}
          >
            Go Back
          </Button>
          <Button
            color="primary"
            onClick={() => handleMigrateEdge()}
            disabled={!selectedTickets.length || !selectedEdge}
          >
            Migrate
          </Button>
        </div>
      </ModalFooter>
    </>
  );
}

export { EdgeMigrateModalConfirm };
