import { FilterWrapper } from "@aptedge/lib-ui/src/components/Filter/FilterWrapper";
import { IQueryFilter } from "@aptedge/lib-ui/src/components/Filter/types";
import { Select } from "@aptedge/lib-ui/src/components/Select/Select";
import React from "react";
import { ARCHIVED_FILTER } from "./data";

type Props = {
  value?: IQueryFilter;
  onChange: (val: IQueryFilter) => void;
};

const options = [
  { name: "False", value: false },
  { name: "True", value: true }
];

function EdgeArchiveFilter(props: Props): React.ReactElement {
  return (
    <FilterWrapper {...ARCHIVED_FILTER} {...props}>
      {({ values, onChange }) => {
        const selected = options.find(
          (opt) => String(opt.value) === values?.[0]
        );

        return (
          <Select
            value={selected}
            data={options}
            getOptionLabel={(opt) => opt.name}
            onChange={(opt) => onChange(opt ? [String(opt.value)] : [])}
            showArrow={false}
          />
        );
      }}
    </FilterWrapper>
  );
}

export { EdgeArchiveFilter };
