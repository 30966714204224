import { BetaLabel } from "@aptedge/lib-ui/src/components/BetaLabel/BetaLabel";
import { Dropdown } from "@aptedge/lib-ui/src/components/Dropdown/Dropdown";
import { Icon } from "@aptedge/lib-ui/src/components/Icon/Icon";
import { Pill } from "@aptedge/lib-ui/src/components/Pill/Pill";
import { Tooltip } from "@aptedge/lib-ui/src/components/Tooltip/Tooltip";
import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import { ITicketListing } from "@aptedge/lib-ui/src/types/entities";
import { GTM_EVENTS, dataLayerPush } from "@aptedge/lib-ui/src/utils/event";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons/faEllipsisH";
import classNames from "classnames";
import { DateTime } from "luxon";
import React from "react";
import "./TicketListItemV2.scss";

interface IProps {
  ticket: ITicketListing;
  className?: string;
  onSelect: () => void;
  onOptionSelect: () => void;
}
const TicketListItemV2: React.FC<IProps> = ({
  ticket,
  onSelect,
  onOptionSelect
}) => {
  const { flags } = useFlags();

  const onTicketSelect = (): void => {
    dataLayerPush({ event: GTM_EVENTS.GTM_TICKET_CLICK });
    onSelect();
  };

  return (
    <div className="ticket-list-item" onClick={onTicketSelect}>
      <Tooltip content={<span>{ticket.customer?.name}</span>} placement="top">
        <div className="requested-by">{ticket.customer?.name}</div>
      </Tooltip>
      <div className="external-id">#{ticket.externalId}</div>
      <Tooltip content={<span>{ticket.subject}</span>} placement="top">
        <div className="title">{ticket.subject}</div>
      </Tooltip>
      <div className="priority">
        <div className={ticket.priority.toLocaleLowerCase()}>
          {ticket.priority}
        </div>
      </div>
      {flags.sentimentScore && (
        <>
          {!!ticket.frustration?.score ? (
            <div
              className={classNames("frustration", {
                "no-frustration": !ticket.frustration?.score
              })}
            >
              <Tooltip
                placement="top"
                content={
                  <>
                    <span>Frustration Score</span>
                    <BetaLabel />
                  </>
                }
              >
                {ticket.frustration?.score ?? 0}
              </Tooltip>
            </div>
          ) : (
            <div />
          )}
        </>
      )}
      <div className="themes">
        {ticket.themes.map((t) => (
          <Pill key={t.id} text={t.name} />
        ))}
      </div>
      <div className="created-on">
        {DateTime.fromSeconds(ticket.createdOn).toFormat("dd MMM yyyy")}
      </div>
      <div
        className="edit-themes"
        onClick={(e) => e.stopPropagation()}
        role="button"
      >
        <Dropdown.Container className="options">
          <Dropdown.Toggle>
            <Icon icon={faEllipsisH} className="edit-dots" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={onOptionSelect}>Edit Themes</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown.Container>
      </div>
    </div>
  );
};

export default TicketListItemV2;
