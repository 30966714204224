import { IQueryFilter } from "@aptedge/lib-ui/src/components/Filter/types";
import { GeneratedKnowledgeListData } from "@aptedge/lib-ui/src/types/entities";
import { isEmpty, isInteger } from "lodash";
import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";

interface FetchArticlesArgs {
  perPage?: number;
  page?: number;
  limit?: number;
  filters?: IQueryFilter[];
  ticketId?: string;
  include?: string;
}

function fetchArticles({
  perPage,
  page = 1,
  limit = 500,
  filters,
  ticketId,
  include
}: FetchArticlesArgs): Promise<GeneratedKnowledgeListData> {
  let url = `${getBackendApiUrl()}/generated_knowledge/?page=${page}&limit=${limit}`;
  if (isInteger(perPage)) {
    url += `&per-page=${perPage}`;
  }
  if (!isEmpty(filters)) {
    url += `&filters=${JSON.stringify(filters)}`;
  }
  if (!isEmpty(ticketId)) {
    url += `&ticket_id=${ticketId}`;
  }
  if (!isEmpty(include)) {
    url += `&include=${include}`;
  }
  return request(url);
}

export { fetchArticles };
