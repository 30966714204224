import { GeneratedKnowledgeResult } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";

interface FetchArticleArgs {
  articleId: string;
}

function fetchArticle({
  articleId
}: FetchArticleArgs): Promise<GeneratedKnowledgeResult> {
  const url = `${getBackendApiUrl()}/generated_knowledge/${articleId}`;
  return request(url);
}

export { fetchArticle };
