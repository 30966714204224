import { getBackendApiUrl } from "../../config";
import { ContentType } from "../../types/http";
import { deleteRequest } from "../../utils/request";
import { deleteInit } from "../utils/buildRequest";

function deleteFederatedURLPrefixConfig(value: string): Promise<void> {
  const url = `${getBackendApiUrl()}/config/FEDERATED_SEARCH_URL_PREFIXES/`;
  return deleteRequest(
    url,
    deleteInit(ContentType.APPLICATION_JSON, { value })
  );
}

export { deleteFederatedURLPrefixConfig };
