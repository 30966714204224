import React from "react";
import { SupportAppConfiguration } from "./SupportAppConfiguration";
import "./SupportAppSettings.scss";
import { SupportAppSetup } from "./SupportAppSetup";

function SupportAppSettings(): React.ReactElement {
  return (
    <section className="support-app-settings">
      <div className="row mb-5">
        <div className="col-md-5 p-2 pr-3">
          <h4 className="text-uppercase">Support App</h4>
          <p>
            Use these settings to configure the AptEdge Support App in your
            Ticketing system.
          </p>
        </div>
        <div className="col-md-7 p-2 pl-3">
          <SupportAppSetup />
          <SupportAppConfiguration />
        </div>
      </div>
    </section>
  );
}

export { SupportAppSettings };
