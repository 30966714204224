import { EditorContent } from "@tiptap/react";
import { FC } from "react";
import {
  ButtonLabels,
  ToolbarButtonProps,
  useCustomEditor
} from "../../hooks/useCustomEditor";
import { HeadingDropdown } from "./HeadingDropdown";
import "./TiptapEditor.scss";
import { ToolbarButton } from "./ToolbarButton";

type EditorProps = {
  initialContent?: string;
  onChange: (content: string) => void;
};

const TipTapEditor: FC<EditorProps> = ({ initialContent, onChange }) => {
  const { editor, editorToolbarButtons, headingOption } = useCustomEditor(
    onChange,
    initialContent
  );

  return (
    <div className="tiptap-editor">
      <div className="menu-bar">
        {editorToolbarButtons.map((btn: ToolbarButtonProps, index) => {
          return (
            <div key={index}>
              {btn.label === ButtonLabels.HEADING ? (
                <HeadingDropdown options={headingOption} editor={editor} />
              ) : (
                <ToolbarButton
                  key={index}
                  onClick={btn.onClick}
                  icon={btn.icon}
                  label={btn.label}
                  customIcon={btn.customIcon}
                />
              )}
            </div>
          );
        })}
      </div>
      <EditorContent editor={editor} />
    </div>
  );
};

export default TipTapEditor;
