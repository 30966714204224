import {
  LocalStorageKeys,
  useLocalStorage
} from "@aptedge/lib-ui/src/hooks/useLocalStorage";
import { EntityType, ILabel } from "@aptedge/lib-ui/src/types/entities";
import { useCallback } from "react";
import * as Yup from "yup";

const MAX_RECENTS = 10;

function useRecentLabel(): HookResult {
  const [recents, setRecents] = useLocalStorage<ILabel[]>(
    LocalStorageKeys.RECENT_EDGE_LABELS,
    Yup.array<ILabel>().of(
      Yup.object<ILabel>({
        id: Yup.number().required(),
        name: Yup.string().required(),
        parentName: Yup.string().nullable(),
        type: Yup.mixed().oneOf(Object.values(EntityType)).required()
      })
    )
  );

  const addRecentEdgeLabels = useCallback(
    (labels: ILabel[]): void => {
      const previousState = recents ? [...recents] : [];
      const filteredPrevious = previousState.filter(
        (prev) => !labels.some((l) => l.id === prev.id && l.type === prev.type)
      );

      setRecents([...labels, ...filteredPrevious].slice(0, MAX_RECENTS));
    },
    [recents, setRecents]
  );

  return [recents, addRecentEdgeLabels];
}

type HookResult = [ILabel[] | null, (labels: ILabel[]) => void];

export { useRecentLabel };
