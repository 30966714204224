import { ITheme } from "@aptedge/lib-ui/src/types/entities";
import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { WebCacheKey } from "../../clients/cache";
import { fetchThemes } from "../../clients/Themes/fetchThemes";

function useThemes(
  options?: UseQueryOptions<ITheme[]>
): UseQueryResult<ITheme[]> {
  const opts: UseQueryOptions<ITheme[]> = {
    ...options,
    refetchOnWindowFocus: false,
    refetchOnMount: false
  };
  return useQuery([WebCacheKey.THEMES], () => fetchThemes(), opts);
}

export { useThemes };
