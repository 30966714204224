import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { useModalRouter } from "@aptedge/lib-ui/src/components/Modal/ModalRouter";
import { TabsBar } from "@aptedge/lib-ui/src/components/Tabs/TabsBar";
import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import { capitalize } from "lodash";
import React from "react";
import { useQuery } from "react-query";
import { WebCacheKey } from "../../../clients/cache";
import { fetchEdgeBulkReplies } from "../../../clients/Edges/fetchEdgeBulkReplies";
import { EdgeBulkReply } from "../../../components/EdgeBulkReply/EdgeBulkReply";
import { useBulkReplyContext } from "../../../context/BulkReplyContext";
import { useTicketStatuses } from "../../../hooks/clients/useTicketStatuses";
import { ReplyTextArea } from "../ReplyTextArea";
import { Page } from "./BulkReplyRoutes";
import { BulkStatusChange } from "./BulkStatusChange";
import { BulkStatusDropdown } from "./BulkStatusDropdown";

function BulkReplyStartPage(): React.ReactElement {
  const { flags } = useFlags();

  const router = useModalRouter();
  const [
    { edgeId, isInternal, content, status },
    dispatch
  ] = useBulkReplyContext();
  const ticketStatusQuery = useTicketStatuses();

  const replyQuery = useQuery(
    [WebCacheKey.BULK_REPLIES, edgeId],
    () => fetchEdgeBulkReplies(edgeId),
    { enabled: flags.edgeBulkReply }
  );

  const canReply =
    !!(replyQuery.data?.items || []).every((r) => !!r.completedOn) &&
    flags.edgeBulkReply;

  const statuses =
    ticketStatusQuery.data?.statuses
      .slice()
      .map((s) => capitalize(s))
      .sort((a, b) => a.localeCompare(b)) || [];

  return (
    <React.Fragment>
      <div className="tabs-header">
        <TabsBar
          tabs={["Internal", "External"]}
          activeTab={isInternal ? 0 : 1}
          onChangeTab={(i) =>
            dispatch({ type: "setInternal", payload: i === 0 ? true : false })
          }
        />
        <BulkStatusDropdown
          data={statuses}
          status={status.from}
          onClick={(from) =>
            dispatch({ type: "setFromStatus", payload: { from } })
          }
        />
      </div>
      <div className="text">
        <ReplyTextArea
          value={content}
          internal={isInternal}
          onChange={(e) =>
            dispatch({ type: "setContent", payload: e.target.value })
          }
        />
      </div>
      <BulkStatusChange />
      <div className="buttons">
        <Button
          disabled={!canReply || !content}
          onClick={() => router.push(Page.CONFIRM)}
        >
          Next
        </Button>
      </div>
      <div className="recent">
        {!!replyQuery.data?.items.length && <h5>Recent activity</h5>}
        {replyQuery.data?.items.map((reply, idx) => (
          <EdgeBulkReply reply={reply} key={idx} />
        ))}
      </div>
    </React.Fragment>
  );
}

export { BulkReplyStartPage };
