import classNames from "classnames";
import React from "react";
import "./FilterBar.scss";

const FilterBar: React.FC<{ className?: string }> = (props) => {
  const { children, className } = props;

  return (
    <div
      className={classNames("filter-bar", className)}
      data-testid="filter-bar"
    >
      {children}
    </div>
  );
};

export { FilterBar };
