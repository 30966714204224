import { TicketSourceType } from "@aptedge/lib-ui/src/types/entities";
import { useTicketDataSource } from "./clients/useDataSource";

function useIsSpaceEnabled(): boolean {
  const ticketDataSource = useTicketDataSource();
  const dataSourceType = ticketDataSource.dataSource?.type;
  return (
    dataSourceType === TicketSourceType.ZENDESK ||
    dataSourceType === TicketSourceType.MOCK
  );
}

export { useIsSpaceEnabled };
