import {
  AptAnswersConditions,
  AptAnswersFilterRule,
  AptAnswersFilterRuleUpdate
} from "@aptedge/lib-ui/src/types/entities";
import * as React from "react";
import { Select } from "./Select";

interface FilterRulesItemConditionSelectProps {
  rule: AptAnswersFilterRule;
  filterConditions: string[];
  idx: number;
  handleRuleUpdate: (o: AptAnswersFilterRuleUpdate) => void;
  isDisabled: boolean;
  isTop: boolean;
  isBottom: boolean;
}

interface Map {
  [key: string]: string | undefined;
}

const conditionsMap: Map = {
  [AptAnswersConditions.EQUALS]: "Is equal to",
  [AptAnswersConditions.CONTAINS]: "Contains",
  [AptAnswersConditions.STARTS_WITH]: "Starts with",
  [AptAnswersConditions.NOT_EQUALS]: "Is not equal to",
  [AptAnswersConditions.NOT_CONTAINS]: "Does not contain",
  [AptAnswersConditions.NOT_STARTS_WITH]: "Does not start with"
};

const FilterRulesItemConditionSelect: React.FC<FilterRulesItemConditionSelectProps> = ({
  rule,
  filterConditions,
  idx,
  handleRuleUpdate,
  isDisabled,
  isTop,
  isBottom
}) => {
  const currentOption = filterConditions.includes(rule.condition)
    ? rule.condition
    : filterConditions[0];

  const getOptionLabel = (option: string): string => {
    return conditionsMap[option] || "";
  };

  const handleUpdate = (value: string): void => {
    handleRuleUpdate({ idx, key: "condition", value });
  };

  return (
    <Select
      options={filterConditions}
      currentOption={currentOption}
      getOptionLabel={getOptionLabel}
      handleUpdate={handleUpdate}
      isDisabled={isDisabled}
      isTop={isTop}
      isRight={false}
      isBottom={isBottom}
      isLeft={false}
    />
  );
};

export { FilterRulesItemConditionSelect };
