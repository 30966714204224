import { IUser } from "@aptedge/lib-ui/src/types/entities";
import {
  GTM_EVENTS,
  dataLayerPushToGTM
} from "@aptedge/lib-ui/src/utils/event";
import { logger } from "@aptedge/lib-ui/src/utils/logger";
import * as Yup from "yup";

const USER_LOCAL_STORAGE_KEY = "ae_user";
const USER_SCHEMA = Yup.object({
  id: Yup.number().required(),
  email: Yup.string().required(),
  admin: Yup.boolean().required(),
  accountName: Yup.string().required(),
  onboardingComplete: Yup.boolean().required()
});

function fetchUserFromLocalStorage(): IUser | null {
  try {
    const userJson = localStorage.getItem(USER_LOCAL_STORAGE_KEY);

    if (userJson) {
      const userMetadata: IUser = JSON.parse(userJson);
      const validUser = USER_SCHEMA.validateSync(userMetadata, {
        abortEarly: false
      });

      if (!!validUser) {
        return userMetadata;
      }
    }
  } catch (e) {
    logger.error("Unable to fetch user to local storage: " + e);
  }

  return null;
}

function saveUserToLocalStorage(user: IUser): void {
  try {
    const validUser = USER_SCHEMA.validateSync(user, {
      abortEarly: false
    });

    if (!!validUser) {
      localStorage.setItem(USER_LOCAL_STORAGE_KEY, JSON.stringify(validUser));
      dataLayerPushToGTM({
        event: GTM_EVENTS.GTM_USER_CONTEXT,
        data: {
          user_email: validUser.email,
          account_name: validUser.accountName
        }
      });
    }
  } catch (e) {
    logger.error("Unable to save user to local storage: " + e);
  }
}

function deleteUserFromLocalStorage(): void {
  localStorage.removeItem(USER_LOCAL_STORAGE_KEY);
}

export {
  deleteUserFromLocalStorage,
  fetchUserFromLocalStorage,
  saveUserToLocalStorage
};
