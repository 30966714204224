import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { ModalFooter } from "@aptedge/lib-ui/src/components/Modal/Modal";
import {
  NoData,
  NoDataMessage
} from "@aptedge/lib-ui/src/components/NoData/NoData";
import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import { TextInput } from "@aptedge/lib-ui/src/components/TextInput/TextInput";
import { useDebounce } from "@aptedge/lib-ui/src/hooks/useDebounce";
import {
  IEdgeInfo,
  IEdgeSearchResult,
  ITicketListing
} from "@aptedge/lib-ui/src/types/entities";
import React, { useRef, useState } from "react";
import { useQuery } from "react-query";
import { WebCacheKey } from "../../../clients/cache";
import { edgeSearch } from "../../../clients/CompositeSearch/edgeSearch";
import { EdgeSearchResult } from "../../../components/EdgeSearchResult/EdgeSearchResult";
import WithLoading from "../../../components/WithLoading/WithLoading";
import { MigrateTicketModal } from "./types";

interface Props {
  edge: IEdgeInfo;
  setSelectedEdge: React.Dispatch<
    React.SetStateAction<IEdgeSearchResult | undefined>
  >;
  setCurrentPage: (page: MigrateTicketModal) => void;
  selectedTickets: ITicketListing[];
}

function EdgeMigrateModalSearch(props: Props): React.ReactElement {
  const { edge, setCurrentPage, setSelectedEdge } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const [search, setSearch] = useState<string>("");
  const { debouncedValue: debouncedSearch } = useDebounce(search, 300);

  const searchEdgesQuery = useQuery(
    [WebCacheKey.EDGE_SEARCH, debouncedSearch],
    () =>
      edgeSearch({
        q: debouncedSearch
      }),
    { enabled: !!debouncedSearch }
  );
  const searchResults =
    searchEdgesQuery.data?.filter((e) => e.id !== edge.id) || [];

  const selectHandler = (edge: IEdgeSearchResult): void => {
    setSelectedEdge(edge);
    setCurrentPage(MigrateTicketModal.THREE);
  };

  return (
    <>
      <p className="disclaimer">
        Migrating moves one or more examples from this edge to another edge.
      </p>
      <div className="migrate-tickets-content">
        <h5>Choose an Edge</h5>
        <TextInput
          ref={inputRef}
          autoFocus
          placeholder="Search by Edge id, url, or Edge text"
          value={search}
          onChange={(e) => setSearch(e.currentTarget.value)}
          onClear={() => setSearch("")}
        />
        {!!search.length && (
          <div className="edges-results">
            <h5>Results</h5>
            <WithLoading
              isLoading={searchEdgesQuery.isLoading}
              fallback={<Spinner />}
            >
              {!!searchResults.length ? (
                <div className="list-group">
                  {searchResults.map((edge, idx) => (
                    <EdgeSearchResult
                      key={idx}
                      edge={edge}
                      isSelected={false}
                      showHighlight={false}
                      onClick={() => selectHandler(edge)}
                    />
                  ))}
                </div>
              ) : (
                <NoData message={NoDataMessage.SEARCH_EDGES} />
              )}
            </WithLoading>
          </div>
        )}
      </div>
      <ModalFooter>
        <div className="d-flex justify-content-between align-items-center">
          <Button
            color="primary"
            onClick={() => setCurrentPage(MigrateTicketModal.ONE)}
          >
            Go Back
          </Button>
          <div>Select an Edge to continue</div>
        </div>
      </ModalFooter>
    </>
  );
}

export { EdgeMigrateModalSearch };
