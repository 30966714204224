import {
  IDynamicSource,
  IDynamicSourceRequest
} from "@aptedge/lib-ui/src/types/entities";
import { getBackendUrl } from "../../../config";
import { request } from "../../../utils/request";
import { post } from "../../utils/buildRequest";

async function createDynamicArticleSource(
  args: IDynamicSourceRequest
): Promise<IDynamicSource> {
  const url = `${getBackendUrl()}/website/collect/`;

  const options: RequestInit = post(args);

  return request(url, options);
}

export { createDynamicArticleSource };
