import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import { useOnMouseDownOutside } from "@aptedge/lib-ui/src/hooks/useOnMouseDownOutside";
import classNames from "classnames";
import React, { createRef, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import WithLoading from "../../components/WithLoading/WithLoading";
import { InlineDeleteConfirmation } from "./common/InlineDeleteConfirmation";
import { ListItem } from "./common/ListItem";
import { SingleTextItemCreateForm } from "./SingleTextItemCreateForm";

interface Props {
  cacheKey: string;
  subtitle: string;
  createEmailConfig: (val: string) => Promise<string>;
  deleteEmailConfig: (val: string) => Promise<void>;
  fetchEmailConfig: () => Promise<string[]>;
}

function SingleTextItemList(props: Props): React.ReactElement {
  const queryClient = useQueryClient();
  const [showAddUrl, setShowAddUrl] = useState(false);

  const agentEmailQuery = useQuery(
    [props.cacheKey],
    () => props.fetchEmailConfig(),
    { useErrorBoundary: true }
  );

  const createEmailMutation = useMutation(props.createEmailConfig, {
    onSuccess: (_, domain): void => {
      setShowAddUrl(false);
      queryClient.setQueryData<string[]>([props.cacheKey], (val = []) => [
        ...val,
        domain
      ]);
    }
  });

  const domains = agentEmailQuery.data || [];

  const wrapperRef = createRef<HTMLUListElement>();

  useOnMouseDownOutside(wrapperRef, () => {
    setShowAddUrl(false);
  });

  return (
    <>
      <div className="d-flex justify-content-between align-items-start">
        <div className="pr-2">
          <h5>Domains</h5>
          <p>{props.subtitle}</p>
        </div>
        <div>
          <Button
            color="secondary"
            onClick={() => setShowAddUrl(true)}
            disabled={agentEmailQuery.isLoading}
          >
            <Icons.Plus />
            <span>Add</span>
          </Button>
        </div>
      </div>
      <ul ref={wrapperRef} className={classNames("email-list list-group")}>
        {showAddUrl && (
          <SingleTextItemCreateForm
            isLoading={createEmailMutation.isLoading}
            onSubmit={(domain) => createEmailMutation.mutate(domain)}
            onCancel={() => setShowAddUrl(false)}
          />
        )}
        <WithLoading isLoading={agentEmailQuery.isLoading}>
          {domains.map((domain, key) => (
            <ListItem
              key={`agent-email-domain-${key}`}
              className="agent-email-list-item group"
            >
              <InlineDeleteConfirmation
                deleteFn={() => props.deleteEmailConfig(domain)}
                renderContent={() => <div>{domain}</div>}
                renderConfirmation={() => (
                  <span className="text-muted pr-2">
                    <span>Are you sure you want to delete {domain}?</span>
                  </span>
                )}
                onConfirm={() => {
                  queryClient.setQueryData<string[]>(
                    [props.cacheKey],
                    (val) => val?.filter((v) => v !== domain) ?? []
                  );
                }}
              />
            </ListItem>
          ))}
        </WithLoading>
      </ul>
    </>
  );
}

export { SingleTextItemList };
