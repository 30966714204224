import { ISlackSocialSource } from "@aptedge/lib-ui/src/types/entities";
import { getBackendUrl } from "../../config";
import { request } from "../../utils/request";

function fetchSlackSocialSources(): Promise<ISlackSocialSource[]> {
  const url = `${getBackendUrl()}/slack/tap`;

  return request(url);
}

export { fetchSlackSocialSources };
