import CodeMirror, { Editor } from "codemirror";

declare module "codemirror" {
  interface CommandActions {
    tabAndIndentMarkdownList: (cm: Editor) => void;
    shiftTabAndUnindentMarkdownList: (cm: Editor) => void;
  }
}

CodeMirror.commands.tabAndIndentMarkdownList = function (cm: Editor) {
  const ranges = cm.listSelections();
  const pos = ranges[0].head;
  const eolState = cm.getStateAfter(pos.line);
  const inList = eolState.list !== false;

  if (inList) {
    cm.execCommand("indentMore");
    return;
  }

  if (cm.getOption("indentWithTabs")) {
    cm.execCommand("insertTab");
  } else {
    const tabSize = cm.getOption("tabSize") || 2;
    const spaces = Array(tabSize + 1).join(" ");
    cm.replaceSelection(spaces);
  }
};

CodeMirror.commands.shiftTabAndUnindentMarkdownList = function (cm: Editor) {
  const ranges = cm.listSelections();
  const pos = ranges[0].head;
  const eolState = cm.getStateAfter(pos.line);
  const inList = eolState.list !== false;

  if (inList) {
    cm.execCommand("indentLess");
    return;
  }

  if (cm.getOption("indentWithTabs")) {
    cm.execCommand("insertTab");
  } else {
    const tabSize = cm.getOption("tabSize") || 2;
    const spaces = Array(tabSize + 1).join(" ");
    cm.replaceSelection(spaces);
  }
};
