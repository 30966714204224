import { IIssueListing } from "@aptedge/lib-ui/src/types/entities";
import classNames from "classnames";
import React from "react";
import { IssueItem } from "../IssueItem/IssueItem";

interface Props
  extends Omit<
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >,
    "ref"
  > {
  className?: string;
  issue: IIssueListing;
  isSelected?: boolean;
  showActionIcons?: boolean;
}

const IssueSearchResult: React.FC<Props> = ({
  className,
  issue,
  isSelected,
  showActionIcons,
  ...htmlProps
}) => {
  return (
    <div {...htmlProps}>
      <IssueItem
        className={classNames("issue-search-result", className, {
          active: isSelected,
          selectable: !!htmlProps.onClick
        })}
        issue={issue}
        showActionIcons={showActionIcons}
      />
    </div>
  );
};

export { IssueSearchResult };
