import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";

function fetchPasswordComplexityConfig(): Promise<boolean> {
  const url = `${getBackendApiUrl()}/config/HIGH_PASSWORD_COMPLEXITY/`;

  return request(url);
}

export { fetchPasswordComplexityConfig };
