import classNames from "classnames";
import React from "react";
import "./ListItem.scss";

interface Props {
  className?: string;
}

const ListItem: React.FC<Props> = (props) => {
  const { children, className } = props;

  return (
    <li
      className={classNames("list-group-item", "settings-list-item", className)}
    >
      <div className="d-flex justify-content-between align-items-center">
        {children}
      </div>
    </li>
  );
};

export { ListItem };
