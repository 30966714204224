import { History } from "history";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

function useTabHistory(): { tabHistory: History; tabKeyPressed: boolean } {
  const [ctrlKeyPressed, setCtrlKeyPressed] = useState(false);
  const [metaKeyPressed, setMetaKeyPressed] = useState(false);
  const tabKeyPressed = ctrlKeyPressed || metaKeyPressed;

  const handleKeyDown = (e: KeyboardEvent): void => {
    if (e.metaKey) {
      setMetaKeyPressed(true);
    }
    if (e.ctrlKey) {
      setCtrlKeyPressed(true);
    }
  };

  const handleKeyUp = (e: KeyboardEvent): void => {
    if (!e.metaKey) {
      setMetaKeyPressed(false);
    }
    if (!e.ctrlKey) {
      setCtrlKeyPressed(false);
    }
  };

  const history = useHistory();

  const tabHistory = Object.assign({}, history, {
    push: (value: string) => {
      if (tabKeyPressed) {
        window.open(value);
      } else {
        history.push(value);
      }
    }
  });

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  });

  return { tabHistory, tabKeyPressed };
}

export default useTabHistory;
