import { Avatar } from "@aptedge/lib-ui/src/components/Avatar/Avatar";
import { IEdgeListing } from "@aptedge/lib-ui/src/types/entities";
import capitalize from "lodash/capitalize";
import React from "react";
import { CellComponentProps } from "../useTable";

function EdgeAssigneeCell(
  props: CellComponentProps<IEdgeListing>
): React.ReactElement {
  const { data: edge } = props;
  const text = edge.assignee ? capitalize(edge.assignee.email) : "N / A";
  const color = edge.assignee ? "var(--gray-500)" : "var(--gray-300)";
  const tooltip = edge.assignee?.email || "Unassigned";

  return (
    <div className="edge-assignee">
      <Avatar color={color} text={text} tooltip={tooltip} size="40px" />
    </div>
  );
}

export { EdgeAssigneeCell };
