import {
  IZendeskKBArticleSourceRequest,
  IZendeskKBArticleSource
} from "@aptedge/lib-ui/src/types/entities";
import { getBackendUrl } from "../../config";
import { request } from "../../utils/request";
import { post } from "../utils/buildRequest";

async function createZendeskKBArticleSource(
  args: IZendeskKBArticleSourceRequest
): Promise<IZendeskKBArticleSource> {
  const url = `${getBackendUrl()}/zendesk-kb/collect/`;

  const options: RequestInit = post(args);

  return request(url, options);
}

export { createZendeskKBArticleSource };
