import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";

function fetchFederatedURLPrefixConfig(): Promise<string[]> {
  const url = `${getBackendApiUrl()}/config/FEDERATED_SEARCH_URL_PREFIXES/`;

  return request<string[]>(url);
}

export { fetchFederatedURLPrefixConfig };
