import { Dropdown } from "@aptedge/lib-ui/src/components/Dropdown/Dropdown";
import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import classNames from "classnames";
import capitalize from "lodash/capitalize";
import React from "react";
import "./BulkStatusDropdown.scss";

interface Props {
  disabled?: boolean;
  data: string[];
  status?: string;
  onClick: (status: string) => void;
}

const BulkStatusDropdown: React.FC<Props> = ({
  disabled,
  data,
  status,
  onClick
}) => {
  return (
    <Dropdown.Container className="bulk-status-dropdown" disabled={disabled}>
      <Dropdown.Toggle>
        <Icons.Stream />
        <label>Status</label>
        <span className="selection">{status ? capitalize(status) : "All"}</span>
        <Icons.ChevronDown />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {data.map((s, idx) => (
          <Dropdown.Item
            key={idx}
            className={classNames({ active: status === s })}
            onClick={() => onClick(s)}
          >
            {s}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown.Container>
  );
};

export { BulkStatusDropdown };
