/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAppDispatch } from "@aptedge/lib-ui/src/redux/hook/hook";
import { setDynamicTapData } from "@aptedge/lib-ui/src/redux/reduxSlice/dynamicIntegrationSlice";
import {
  IConfluenceCloudArticleSource,
  ISharepointSource,
  ISharepointDriveSource,
  IBusinessDataSource,
  IJiraCloudInternalIssueSource,
  IJiraOnPremiseInternalIssueSource,
  IProviderSource,
  ITicketSource,
  IConfluenceOnPremArticleSource,
  ISlackSocialSource,
  IZendeskKBArticleSource,
  IAzureDevopsIssuesSource,
  INotionSource,
  IOneloginSource,
  IOneloginUrl,
  IMSTeamsSource,
  IDynamicSource
} from "@aptedge/lib-ui/src/types/entities";
import { QueryKey, QueryOptions, useQuery } from "react-query";
import { fetchArticleSource } from "../../clients/ArticleSources/DynamicSettings/fetchArticleSource";
import { fetchConfluenceCloudArticleSources } from "../../clients/ArticleSources/fetchConfluenceCloudArticleSources";
import { fetchConfluenceOnPremArticleSources } from "../../clients/ArticleSources/fetchConfluenceOnPremArticleSources";
import { fetchNotionArticleSource } from "../../clients/ArticleSources/fetchNotionArticleSource";
import { fetchSalesforceKnowledgeArticleSource } from "../../clients/ArticleSources/fetchSalesforceKnowledgeArticleSource";
import { fetchSharepointDriveSources } from "../../clients/ArticleSources/fetchSharepointDriveSources";
import { fetchSharepointSources } from "../../clients/ArticleSources/fetchSharepointSources";
import { fetchSNowKnowledgeArticleSource } from "../../clients/ArticleSources/fetchSNowKnowledgeArticleSource";
import { fetchZendeskKBArticleSource } from "../../clients/ArticleSources/fetchZendeskKBArticleSource";
import { fetchOneloginSource } from "../../clients/Authentication/fetchOneloginSource";
import { fetchOneloginUrl } from "../../clients/Authentication/fetchOneloginUrl";
import { fetchBusinessDataSource } from "../../clients/BusinessDataSources/fetchBusinessDataSource";
import { WebCacheKey } from "../../clients/cache";
import { fetchAzureDevopsIssueSources } from "../../clients/InternalIssueSources/fetchAzureDevopsInternalIssueSource";
import { fetchJiraCloudInternalIssueSources } from "../../clients/InternalIssueSources/fetchJiraCloudInternalIssueSources";
import { fetchJiraOnPremiseInternalIssueSources } from "../../clients/InternalIssueSources/fetchJiraOnPremiseInternalIssuesSource";
import { fetchSlackSocialSources } from "../../clients/SocialSource/fetchSlackSettings";
import { getMSTeamsConnectionInfo } from "../../clients/SocialSource/MSTeamsSource/fetchMSTeamsSettings";
import { fetchTicketSources } from "../../clients/TicketSources/fetchTicketSources";
import { providerIsAuthorized } from "../../pages/SettingsPage/utils/providerIsAuthorized";

export interface IGatewayTimeoutError extends Error {
  code?: number;
}

export interface UseOAuthDataSourceResult<DS extends IProviderSource> {
  isLoading: boolean;
  authorized: boolean;
  dataSource?: DS;
  reload: () => void;
  error: IGatewayTimeoutError | null;
}

export interface UseDataSourceResult<DS> {
  isLoading: boolean;
  dataSource?: DS;
  reload: () => void;
  error: IGatewayTimeoutError | null;
}

type ProviderClientFn<DS extends IProviderSource> = (
  ...args: any[]
) => Promise<DS[]>;

function useDataSource<DS extends IProviderSource>(
  key: QueryKey,
  originalClientFn: ProviderClientFn<DS>,
  options?: QueryOptions<DS[], Error>
): UseOAuthDataSourceResult<DS> {
  const { isLoading, data, error, refetch: reload } = useQuery<DS[], Error>(
    key,
    originalClientFn,
    { ...options, refetchOnMount: false } // cache the response across multiple components
  );

  const dataSource = (data && data[0]) || undefined;
  const authorized = providerIsAuthorized(dataSource);

  return {
    dataSource,
    authorized,
    reload,
    isLoading,
    error
  };
}

function useBusinessDataSource(): UseOAuthDataSourceResult<
  IBusinessDataSource
> {
  return useDataSource(
    WebCacheKey.BUSINESS_DATA_SOURCE,
    fetchBusinessDataSource
  );
}

function useTicketDataSource(): UseOAuthDataSourceResult<ITicketSource> {
  return useDataSource(WebCacheKey.TICKET_DATA_SOURCE, fetchTicketSources);
}

function useJiraCloudInternalIssueDataSource(): UseOAuthDataSourceResult<
  IJiraCloudInternalIssueSource
> {
  return useDataSource(
    WebCacheKey.INTERNAL_ISSUE_JIRA_CLOUD_SOURCE,
    fetchJiraCloudInternalIssueSources
  );
}

function useJiraOnPremiseInternalIssueDataSource(): UseDataSourceResult<
  IJiraOnPremiseInternalIssueSource
> {
  const { isLoading, data, error, refetch: reload } = useQuery<
    IJiraOnPremiseInternalIssueSource[],
    Error
  >(
    WebCacheKey.INTERNAL_ISSUE_JIRA_ON_PREMISE_SOURCE,
    fetchJiraOnPremiseInternalIssueSources,
    { refetchOnMount: false, retry: 3 }
  );

  const dataSource = (data && data[0]) || undefined;
  return {
    dataSource,
    reload,
    isLoading,
    error
  };
}

function useSharepointSource(): UseDataSourceResult<ISharepointSource> {
  const { isLoading, data, error, refetch: reload } = useQuery<
    ISharepointSource[],
    Error
  >(WebCacheKey.SHAREPOINT_SOURCE, fetchSharepointSources, {
    retry: 3
  });

  const dataSource = !!data ? data[0] : undefined;
  return {
    dataSource,
    reload,
    isLoading,
    error
  };
}

function useSharepointDriveSource(): UseDataSourceResult<
  ISharepointDriveSource
> {
  const { isLoading, data, error, refetch: reload } = useQuery<
    ISharepointDriveSource[],
    Error
  >(WebCacheKey.SHAREPOINT_DRIVE_SOURCE, fetchSharepointDriveSources, {
    retry: 3
  });

  const dataSource = !!data ? data[0] : undefined;
  return {
    dataSource,
    reload,
    isLoading,
    error
  };
}

function useConfluenceCloudArticleDataSource(): UseOAuthDataSourceResult<
  IConfluenceCloudArticleSource
> {
  return useDataSource(
    WebCacheKey.CONFLUENCE_CLOUD_ARTICLE_SOURCE,
    fetchConfluenceCloudArticleSources
  );
}

function useConfluenceOnPremArticleDataSource(): UseDataSourceResult<
  IConfluenceOnPremArticleSource
> {
  const { isLoading, data, error, refetch: reload } = useQuery<
    IConfluenceOnPremArticleSource[],
    Error
  >(
    WebCacheKey.CONFLUENCE_ON_PREM_ARTICLE_SOURCE,
    fetchConfluenceOnPremArticleSources,
    { refetchOnMount: false }
  );

  const dataSource = (data && data[0]) || undefined;
  return {
    dataSource,
    reload,
    isLoading,
    error
  };
}

function useZendeskKBDataSource(): UseDataSourceResult<
  IZendeskKBArticleSource
> {
  const { isLoading, data, error, refetch: reload } = useQuery<
    IZendeskKBArticleSource[],
    Error
  >(WebCacheKey.ZENDESK_KB_SOURCE, fetchZendeskKBArticleSource, {
    refetchOnMount: false,
    retry: 3
  });

  const dataSource = (data && data[0]) || undefined;
  return {
    dataSource,
    reload,
    isLoading,
    error
  };
}

function useSalesforceKnowledgeDataSource(): UseDataSourceResult<any> {
  const { isLoading, data, error, refetch: reload } = useQuery<any, Error>(
    WebCacheKey.SALESFORCE_KNOWLEDGE_SOURCE,
    fetchSalesforceKnowledgeArticleSource,
    {
      refetchOnMount: false,
      retry: 3
    }
  );

  const dataSource = (data && data[0]) || undefined;
  return {
    dataSource,
    reload,
    isLoading,
    error
  };
}
function useSNowKnowledgeDataSource(): UseDataSourceResult<any> {
  const { isLoading, data, error, refetch: reload } = useQuery<any, Error>(
    WebCacheKey.SNOW_KNOWLEDGE_SOURCE,
    fetchSNowKnowledgeArticleSource,
    {
      refetchOnMount: false,
      retry: 3
    }
  );

  const dataSource = (data && data[0]) || undefined;
  return {
    dataSource,
    reload,
    isLoading,
    error
  };
}

function useBusinessDataSourceIsAuthorized(): boolean {
  return useBusinessDataSource().authorized;
}

function useSlackSocialDataSource(): UseDataSourceResult<ISlackSocialSource> {
  const { isLoading, data, error, refetch: reload } = useQuery<
    ISlackSocialSource[],
    Error
  >(WebCacheKey.SLACK_SOCIAL_SOURCE, fetchSlackSocialSources, {
    retry: 3
  });

  const dataSource = (data && data[0]) || undefined;
  return {
    dataSource,
    reload,
    isLoading,
    error
  };
}

function useAzureDevopsIssueDataSource(): UseDataSourceResult<
  IAzureDevopsIssuesSource
> {
  const { isLoading, data, error, refetch: reload } = useQuery<
    IAzureDevopsIssuesSource[],
    Error
  >(WebCacheKey.AZURE_DEVOPS_CLOUD, fetchAzureDevopsIssueSources, {
    retry: 3
  });

  const dataSource = (data && data[0]) || undefined;
  return {
    dataSource,
    reload,
    isLoading,
    error
  };
}

function useNotionArticleSource(): UseDataSourceResult<INotionSource> {
  const { isLoading, data, error, refetch: reload } = useQuery<
    INotionSource[],
    Error
  >(WebCacheKey.NOTION_ARTICLE, fetchNotionArticleSource, {
    retry: 3
  });

  const dataSource = (data && data[0]) || undefined;
  return {
    dataSource,
    reload,
    isLoading,
    error
  };
}
function useMsTeamsSource(): UseDataSourceResult<IMSTeamsSource> {
  const { isLoading, data, error, refetch: reload } = useQuery<
    IMSTeamsSource[],
    Error
  >(WebCacheKey.MS_TEAMS, getMSTeamsConnectionInfo, {
    retry: 3
  });

  const dataSource = !!data ? data[0] : undefined;
  return {
    dataSource,
    reload,
    isLoading,
    error
  };
}

function useOneloginSource(): UseDataSourceResult<IOneloginSource> {
  const { isLoading, data, error, refetch: reload } = useQuery<
    IOneloginSource,
    Error
  >(WebCacheKey.ONELOGIN_SOURCE, fetchOneloginSource, {
    retry: 1
  });

  const dataSource = data || undefined;

  return {
    dataSource,
    reload,
    isLoading,
    error
  };
}

function useOneloginUrl(): UseDataSourceResult<IOneloginUrl> {
  const { isLoading, data, error, refetch: reload } = useQuery<
    IOneloginUrl,
    Error
  >(WebCacheKey.ONELOGIN_URL, fetchOneloginUrl, {
    retry: 3
  });

  const dataSource = data || undefined;

  return {
    dataSource,
    reload,
    isLoading,
    error
  };
}

function useDynamicArticleSource(): UseDataSourceResult<IDynamicSource[]> {
  const dispatch = useAppDispatch();
  const { isLoading, data, error, refetch: reload } = useQuery<
    IDynamicSource[],
    Error
  >([WebCacheKey.DYNAMIC_ARTICLE_SOURCE], fetchArticleSource, {
    retry: 3,
    onSuccess: (data) => dispatch(setDynamicTapData(data))
  });

  return {
    dataSource: data,
    reload,
    isLoading,
    error
  };
}

export {
  useBusinessDataSource,
  useTicketDataSource,
  useJiraCloudInternalIssueDataSource,
  useJiraOnPremiseInternalIssueDataSource,
  useConfluenceCloudArticleDataSource,
  useConfluenceOnPremArticleDataSource,
  useBusinessDataSourceIsAuthorized,
  useSlackSocialDataSource,
  useZendeskKBDataSource,
  useSalesforceKnowledgeDataSource,
  useAzureDevopsIssueDataSource,
  useNotionArticleSource,
  useOneloginSource,
  useOneloginUrl,
  useMsTeamsSource,
  useSharepointSource,
  useSharepointDriveSource,
  useSNowKnowledgeDataSource,
  useDynamicArticleSource
};
