import { ModalWidth } from "@aptedge/lib-ui/src/components/Modal/Modal";
import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import { useAppDispatch } from "@aptedge/lib-ui/src/redux/hook/hook";
import { setSharepointTapData } from "@aptedge/lib-ui/src/redux/reduxSlice/sharepointSlice";
import {
  IntegrationStatus,
  INTEGRATION_STATUS,
  OAuthStatus
} from "@aptedge/lib-ui/src/types/entities";
import { GATEWAY_TIMEOUT } from "http-status-codes";
import { useEffect, useState, useCallback, FC } from "react";
import { useMutation } from "react-query";
import { ToastType } from "react-toastify";
import { createSharepointSource } from "../../../clients/ArticleSources/createSharepointSource";
import { updateSharepointSource } from "../../../clients/ArticleSources/updateSharepointSource";
import { ConfirmationModal } from "../../../components/ConfirmationModal/ConfirmationModal";
import { Toast } from "../../../components/Toast/Toast";
import WithLoading from "../../../components/WithLoading/WithLoading";
import { getBackendUrl } from "../../../config";
import { useSharepointSource } from "../../../hooks/clients/useDataSource";
import { ClientStatus } from "../../../types/clients";
import { AddButton } from "../common/AddButton";
import { StatusButton } from "../common/StatusButton";
import {
  SharepointConfigForm,
  SharepointFormData
} from "./SharepointConfigForm";

const SharepointConfig: FC = () => {
  const dataSource = useSharepointSource();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [redirectStatus, setRedirectStatus] = useState(ClientStatus.PROMPT);
  const dispatch = useAppDispatch();

  const sharepointSource = dataSource?.dataSource ?? null;

  const addSource = useMutation(createSharepointSource, {
    onError: () => {
      setRedirectStatus(ClientStatus.ERROR);
    },
    onSuccess: () => {
      setRedirectStatus(ClientStatus.SUCCESS);
      const url = `${getBackendUrl()}/api/integrations/sharepoint/login`;
      window.location.href = url;
    }
  });

  const updateSource = useMutation(updateSharepointSource, {
    onError: () => {
      setRedirectStatus(ClientStatus.ERROR);
    },
    onSuccess: () => {
      setRedirectStatus(ClientStatus.SUCCESS);
      const url = `${getBackendUrl()}/api/integrations/sharepoint/login`;
      window.location.href = url;
    }
  });

  const onFormSubmit = (formData: SharepointFormData): void => {
    setRedirectStatus(ClientStatus.LOADING);
    sharepointSource
      ? updateSource.mutate(formData)
      : addSource.mutate(formData);
  };

  const getStatus = (): IntegrationStatus => {
    if (dataSource?.error?.code === GATEWAY_TIMEOUT)
      return INTEGRATION_STATUS.TIME_OUT;
    if (sharepointSource?.status === OAuthStatus.AUTHENTICATED)
      return INTEGRATION_STATUS.OK;
    return INTEGRATION_STATUS.ERROR;
  };

  const handleReduxDispatch = useCallback((): void => {
    dispatch(setSharepointTapData(sharepointSource ?? null));
  }, [dispatch, sharepointSource]);

  useEffect(() => {
    handleReduxDispatch();
  }, [handleReduxDispatch]);

  const isConnectionExist =
    sharepointSource || getStatus() === INTEGRATION_STATUS.TIME_OUT;
  const isLoaderVisible = addSource.isLoading || dataSource.isLoading;

  return (
    <div>
      <WithLoading isLoading={isLoaderVisible} fallback={<Spinner />}>
        <div className="mb-3 mt-5">
          <h5>Sharepoint Site Pages</h5>
          <p>Configure your SharePoint Pages settings below.</p>
        </div>

        <div>
          {isConnectionExist ? (
            <StatusButton
              status={getStatus()}
              onClickEdit={() => setShowModal(true)}
            >
              <>
                <div>Sharepoint</div>
                <p className="tenantId">
                  Tenant ID: {sharepointSource?.tenantId}
                </p>
              </>
            </StatusButton>
          ) : (
            <AddButton onClick={() => setShowModal(true)}>Connect</AddButton>
          )}
        </div>
      </WithLoading>
      <ConfirmationModal
        title="Sharepoint Settings"
        width={ModalWidth.MEDIUM}
        loadingTitle="Loading...."
        status={redirectStatus}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      >
        <p className="mb-4">Configure your settings below.</p>
        <SharepointConfigForm
          source={sharepointSource}
          onSubmit={onFormSubmit}
        />
      </ConfirmationModal>
      {addSource.isError && (
        <Toast type={ToastType.ERROR}>Something went wrong.</Toast>
      )}
    </div>
  );
};

export { SharepointConfig };
