import React from "react";
import Helmet from "react-helmet";
import "./NotFoundPage.scss";

const NotFoundPage: React.FunctionComponent = () => {
  const page = window.location.pathname;

  return (
    <>
      <Helmet>
        <title>404: {page} not found</title>
      </Helmet>
      <div className="not-found-content">{page} not found.</div>
    </>
  );
};

export default NotFoundPage;
