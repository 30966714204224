import { IEdgeListing } from "@aptedge/lib-ui/src/types/entities";
import React from "react";
import { CellComponentProps } from "../useTable";

function EdgeTicketsCell(
  props: CellComponentProps<IEdgeListing>
): React.ReactElement {
  const { data: edge } = props;

  return <div className="text-center">{edge.tickets.length}</div>;
}

export { EdgeTicketsCell };
