import { IProductIssue } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { IPaginatedRequest, IPaginatedResponse } from "../../types/clients";
import { request } from "../../utils/request";
import { toQueryString } from "../utils/url";

async function fetchProductIssues(
  args: IPaginatedRequest<{ externalIdPrefix?: string }>
): Promise<IPaginatedResponse<IProductIssue[]>> {
  const queryString = toQueryString(args);

  return request(`${getBackendApiUrl()}/issues/?${queryString}`);
}

export { fetchProductIssues };
