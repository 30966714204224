import {
  IQueryFilter,
  IQueryFilterId
} from "@aptedge/lib-ui/src/components/Filter/types";
import partition from "lodash/partition";

function replaceFilter(
  filter: IQueryFilter,
  filters?: IQueryFilter[]
): IQueryFilter[] {
  const [, others] = partition(
    filters || [],
    (f) => f.id === filter.id && f.operator === filter.operator
  );

  return [...others, filter];
}

function mergeFilter(
  filter: IQueryFilter,
  filters?: IQueryFilter[]
): IQueryFilter[] {
  const [duplicates, others] = partition(
    filters || [],
    (f) => f.id === filter.id && f.operator === filter.operator
  );

  const values = duplicates.reduce<string[]>((memo, d) => {
    memo = memo.concat(d.values);

    return memo;
  }, filter.values);

  return [...others, { ...filter, values }];
}

function hasFilterValue(
  filters: IQueryFilter[] | undefined,
  id: IQueryFilterId,
  value: string | number
): boolean {
  return (
    !!filters &&
    filters.some((f) => f.id === id && f.values.includes(value.toString()))
  );
}

function filtersWithDefaults(
  filters: IQueryFilter[] | undefined,
  defaults: IQueryFilter[]
): IQueryFilter[] {
  const result = [...(filters ?? [])];
  defaults.forEach((f) => {
    const match = getFilter(result, f.id);
    if (!match || !match.values.length) {
      result.push(f);
    }
  });

  return result;
}

function getFilter(
  filters: IQueryFilter[] | undefined,
  id: IQueryFilterId
): IQueryFilter | undefined {
  return filters?.find((f) => f.id === id);
}

function filterMatcher(
  filter?: IQueryFilter
): (possibleMatches: string[]) => boolean {
  return (possibleMatches) => {
    if (!filter || !filter.values.length) {
      return true;
    }

    switch (filter.operator) {
      case "equals":
        return filter.values.some((v) => possibleMatches.includes(v));
      case "notEquals":
        return filter.values.every((v) => !possibleMatches.includes(v));
      case "contains":
        return filter.values.some((v) =>
          possibleMatches.some((poss) =>
            poss.toLowerCase().includes(v.toLowerCase())
          )
        );
      case "notContains":
        return !filter.values.every((v) =>
          possibleMatches.some((poss) =>
            poss.toLowerCase().includes(v.toLowerCase())
          )
        );
      default:
        return true;
    }
  };
}

export {
  getFilter,
  filterMatcher,
  filtersWithDefaults,
  replaceFilter,
  mergeFilter,
  hasFilterValue
};
