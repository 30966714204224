import { SDKClient } from "@aptedge/lib-support-ui/src/clients/sdkClient";
import { AeIcons } from "@aptedge/lib-ui/src/components/Icon/AeIcons";
import { ICompositeResult } from "@aptedge/lib-ui/src/types/entities";
import { isWebApp } from "@aptedge/lib-ui/src/utils/event";
import { FC } from "react";
import SourceLink from "../SourceLink/SourceLink";
import "./RenderLink.scss";

interface IRenderLinkProps {
  href: string;
  content: string;
  resultsMap: Map<string, ICompositeResult>;
  totalResults: number;
  client?: SDKClient;
  aptEdgeBaseUrl?: string;
}
const MAX_URL_LENGTH = 50;

const handleMouseDown = (
  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
): void => {
  if (isWebApp) event.preventDefault();
};
const RenderLink: FC<IRenderLinkProps> = ({
  href,
  content,
  resultsMap,
  totalResults,
  client,
  aptEdgeBaseUrl
}) => {
  const sourceDetails = resultsMap?.get(href);
  const linkText =
    content.length > MAX_URL_LENGTH
      ? content.substring(0, MAX_URL_LENGTH) + "..."
      : content;

  if (!!sourceDetails) {
    return (
      <SourceLink
        sourceDetails={sourceDetails}
        topResults={resultsMap}
        totalResults={totalResults}
        client={client}
        aptEdgeBaseUrl={aptEdgeBaseUrl}
      />
    );
  }
  return (
    <a
      href={href}
      className="external-links"
      target="_blank"
      rel="noreferrer"
      onMouseDown={handleMouseDown}
    >
      <AeIcons.Globe />
      {linkText}
    </a>
  );
};

export default RenderLink;
