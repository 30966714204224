import { IArticleSetting } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { ContentType } from "../../types/http";
import { deleteRequest } from "../../utils/request";
import { deleteInit } from "../utils/buildRequest";

function deleteKnowledgeBaseConfig(value: string): Promise<void> {
  const url = `${getBackendApiUrl()}/config/ML_KNOWLEDGE_BASE_ARTICLE_BASE_URLS/`;
  return deleteRequest(
    url,
    deleteInit<IArticleSetting>(ContentType.APPLICATION_JSON, { value })
  );
}

export { deleteKnowledgeBaseConfig };
