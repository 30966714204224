import { useEffect, useCallback, RefObject, MutableRefObject } from "react";

const useOutsideAlerter = (
  ref: RefObject<HTMLElement> | MutableRefObject<HTMLElement | null>,
  handleOusideClick: () => void
): void => {
  /**
   * Alert if clicked on outside of element
   */
  const handleClickOutside = useCallback(
    (event: MouseEvent): void => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        handleOusideClick();
      }
    },
    [ref, handleOusideClick]
  );

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);
};

export { useOutsideAlerter };
