import React, { RefObject } from "react";

function eventWasTriggeredOutsideRef<E extends HTMLElement>(
  e: MouseEvent | React.MouseEvent | KeyboardEvent | React.KeyboardEvent,
  ref: RefObject<E>
): boolean {
  const target = e.target as HTMLElement;

  return !ref.current?.contains(target) && ref.current !== target;
}

export { eventWasTriggeredOutsideRef };
