import { IEdgeInfo, ITicketListing } from "@aptedge/lib-ui/src/types/entities";
import { GTM_EVENTS, dataLayerPush } from "@aptedge/lib-ui/src/utils/event";
import React, { useState } from "react";
import { TicketModal } from "../../../components/TicketModal/TicketModal";
import { EdgeTicket } from "./EdgeTicket";
import { EdgeTicketsListContainer } from "./EdgeTicketsListContainer";

interface Props {
  edge: IEdgeInfo;
  tickets: ITicketListing[];
  onDelete: (ticket: ITicketListing) => void;
  onUpdateTicket: () => void;
}

function EdgeTicketsList(props: Props): React.ReactElement {
  const { edge, tickets, onDelete, onUpdateTicket } = props;

  const [ticketToPreview, setTicketToPreview] = useState<number>();

  const handleOnSelect = (ticketId: number): void => {
    setTicketToPreview(ticketId);
    dataLayerPush({ event: GTM_EVENTS.GTM_EDGE_TICKET_CLICK });
  };

  return (
    <>
      <EdgeTicketsListContainer>
        {tickets.map((t) => (
          <EdgeTicket
            key={t.id}
            ticket={t}
            onDelete={onDelete}
            disabled={edge.archived}
            onSelect={(ticket) => handleOnSelect(ticket.id)}
          />
        ))}
      </EdgeTicketsListContainer>
      <TicketModal
        isOpen={!!ticketToPreview}
        ticketId={ticketToPreview}
        onTicketUpdate={onUpdateTicket}
        onClose={() => setTicketToPreview(undefined)}
      />
    </>
  );
}

export { EdgeTicketsList };
