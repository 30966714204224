/**
 * Converts a number to a human readable string
 *
 * Note - assumes commas every 3 numeric characters
 */
export function toHumanReadableStringFromNumber(n: number): string {
  let result: string;

  if (n >= 1000000) {
    const shorthand = floorToNearestTenth(n / 1000000);
    const withCommas = addCommas(shorthand);
    result = withCommas + "m";
  } else if (n > 1000) {
    const shorthand = floorToNearestTenth(n / 1000);
    const withCommas = addCommas(shorthand);
    result = withCommas + "k";
  } else {
    const withCommas = addCommas(floorToNearestTenth(n));
    result = withCommas;
  }

  return result;
}

export function toAbbreviatedMonetaryStringFromNumber(n: number): string {
  const abbreviated = toHumanReadableStringFromNumber(n);
  return `$${abbreviated}`;
}

/**
 * Converts a number to a string, and adds commas for every 3 number characters
 *
 * Meant to display financial figures
 */
export function addCommas(n: number): string {
  const parts = n.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

function floorToNearestTenth(n: number): number {
  return Math.floor(n * 10) / 10;
}
