import classNames from "classnames";
import { DateTime } from "luxon";
import React from "react";
import "./Day.scss";

interface Props {
  className?: string;
  day: DateTime;
  onClick: () => void;
  "data-testid"?: string;
}

const Day: React.FC<Props> = ({ day, className, onClick, ...rest }) => {
  return (
    <div
      className={classNames("day", className)}
      onClick={onClick}
      data-testid={rest["data-testid"]}
    >
      {day.day}
    </div>
  );
};

export { Day };
