import React, { useState } from "react";
import { TabsBar } from "./TabsBar";

interface Props {
  activeTab?: number;
  onChangeTab?: (idx: number) => void;
  tabs: Array<string>;
}

const Tabs: React.FC<Props> = (props) => {
  const { activeTab: externalActiveTab, onChangeTab, tabs, children } = props;
  const isControlled =
    props.activeTab !== undefined && onChangeTab !== undefined;

  const [internalActiveTab, setActiveTab] = useState<number>(
    externalActiveTab || 0
  );

  const validChildren = React.Children.toArray(children).filter((c) => !!c);
  const count = validChildren?.length || 0;

  // Only show the tabs bar if we have more than one tab and child.
  const showTabsBar = tabs.length > 1 && count > 1;

  // Use external or internal state, depending on usage. If this is a controlled component,
  // state is external.
  const activeTab = isControlled
    ? (externalActiveTab as number)
    : internalActiveTab;

  const handleClick = (idx: number): void => {
    if (isControlled) {
      onChangeTab?.(idx);
    } else {
      setActiveTab(idx);
    }
  };

  return (
    <div className="tabs">
      {showTabsBar && (
        <TabsBar tabs={tabs} activeTab={activeTab} onChangeTab={handleClick} />
      )}
      {React.Children.map(validChildren, (child, index) => {
        // Render a child if we aren't showing the bar or
        // the child's index matches the active tab index.
        if (!showTabsBar || index === activeTab) {
          return child;
        }
      })}
    </div>
  );
};

export { Tabs };
