import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import {
  EntityType,
  IEdgeStatus,
  IEntity,
  ILabel
} from "@aptedge/lib-ui/src/types/entities";
import classNames from "classnames";
import type { Dictionary } from "lodash";
import values from "lodash/values";
import React from "react";
import { useMutation, useQuery } from "react-query";
import { WebCacheKey } from "../../clients/cache";
import { fetchNotificationConfig } from "../../clients/Notifications/fetchNotificationConfig";
import { updateNotificationConfig } from "../../clients/Notifications/updateNotificationConfig";
import { LabelsWidget } from "../../components/LabelWidget/LabelsWidget";

const WatchSettings: React.FC = () => {
  const settingsQuery = useQuery(
    WebCacheKey.NOTIFICATION_CONFIG,
    fetchNotificationConfig
  );
  const settingsMutation = useMutation(updateNotificationConfig, {
    onSuccess: () => {
      settingsQuery.refetch();
    }
  });

  const statusList = settingsQuery.data?.watchedStatuses || "";
  const labelsMap = (settingsQuery.data?.watchedLabels || {}) as Dictionary<
    IEntity[]
  >;

  const statuses = statusList.split(",").filter((s) => !!s);
  const labels = Object.keys(labelsMap).flatMap((key) =>
    labelsMap[key].map((item) => ({ ...item, type: key as EntityType }))
  );

  const updateLabels = (labels: ILabel[]): void => {
    const labelsMap = labels.reduce((map: Dictionary<IEntity[]>, label) => {
      const details: IEntity = { id: label.id, name: label.name };
      if (map[label.type]) {
        map[label.type].push(details);
      } else {
        map[label.type] = [details];
      }
      return map;
    }, {});
    settingsMutation.mutate({
      watchedLabels: labelsMap,
      watchedStatuses: statusList
    });
  };

  const toggleStatus = (status: IEdgeStatus): void => {
    let newStatuses;
    if (statuses.includes(status)) {
      newStatuses = statuses.filter((s) => s !== status);
    } else {
      newStatuses = [...statuses, status];
    }
    settingsMutation.mutate({
      watchedLabels: labelsMap,
      watchedStatuses: newStatuses.join(",")
    });
  };

  return (
    <>
      <section className="row mb-5">
        <div className="col-md-5 pr-3">
          <h4 className="text-uppercase">Notifications</h4>
          <p>
            You can configure these settings to watch groups of Edges and
            receive notifications whenever any of them change.
          </p>
        </div>
        <div className="col-md-7 p-2 pl-3">
          <div className="mb-3">
            <h5>Labels</h5>
            <p>Be notified when any Edge with a certain label changes.</p>
          </div>

          <LabelsWidget
            placeholder={null}
            labels={labels}
            onChange={updateLabels}
          />

          <div className="mt-5">
            <h5>Status changes</h5>
            <p>Be notified when any Edge reaches a certain status.</p>
          </div>

          <div className="d-flex">
            {values(IEdgeStatus).map((status) => (
              <Button
                key={status}
                className={classNames("mr-1 p-2 font-weight-normal", {
                  watching: statuses.includes(status)
                })}
                color={statuses.includes(status) ? "primary" : "secondary"}
                onClick={() => toggleStatus(status)}
              >
                {status}
              </Button>
            ))}
          </div>
        </div>
      </section>
      <hr className="mt-4" />
    </>
  );
};

export { WatchSettings };
