import { UNAUTHORIZED } from "http-status-codes";
import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";
import { post } from "../utils/buildRequest";

async function logout(): Promise<void> {
  const url = `${getBackendApiUrl()}/logout/`;

  return request<void>(url, post({})).catch((error) => {
    if (error.name === "ApiError" && error.code === UNAUTHORIZED) {
      // If we get a 401 trying to log out, we're already logged out,
      // and that's okay.
      return;
    } else {
      throw new Error(error);
    }
  });
}

export { logout };
