import classNames from "classnames";
import React, { ReactNode } from "react";
import { Modal as ReactstrapModal } from "reactstrap";
import { Delay } from "../../styles/animations";
import { Icons } from "../Icon/Icons";
import { Spacer } from "../Spacer/Spacer";
import { Tooltip } from "../Tooltip/Tooltip";
import "./Modal.scss";

export enum ModalWidth {
  SMALL = "sm",
  MEDIUM = "md",
  LARGE = "lg",
  XL = "xl"
}

export interface ModalProps {
  className?: string;
  isOpen: boolean;
  onClose?: () => void;
  title?: ReactNode;
  actions?: ReactNode;
  width?: string;
  minHeight?: number;
  showClose?: boolean;
  escClose?: boolean;
}

const ModalFooter: React.FC = ({ children }) => (
  <div className="ae-modal-footer">{children}</div>
);

const Modal: React.FunctionComponent<ModalProps> = ({
  isOpen,
  width = ModalWidth.SMALL,
  showClose = true,
  escClose = true,
  minHeight = 200,
  className,
  title,
  actions,
  onClose,
  children
}) => {
  return (
    <ReactstrapModal
      size={width}
      style={{ minHeight }}
      modalClassName={classNames("ae-modal", className)}
      contentClassName="content"
      isOpen={isOpen}
      toggle={onClose}
      keyboard={escClose}
    >
      <div className="modal-head d-flex align-items-center justify-content-between">
        <div className={"modal-title"}>{title}</div>

        {(showClose || actions) && (
          <div>
            <Spacer size="large" flex row className="modal-actions">
              {actions}
              {showClose && (
                <Tooltip placement="top" delay={Delay.LONG} content="Close">
                  <Icons.Times onClick={onClose} />
                </Tooltip>
              )}
            </Spacer>
          </div>
        )}
      </div>
      {children}
    </ReactstrapModal>
  );
};

export { Modal, ModalFooter };
