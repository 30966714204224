import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { useAppSelector } from "@aptedge/lib-ui/src/redux/hook/hook";
import { useFormik } from "formik";
import React from "react";
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label
} from "reactstrap";
import * as Yup from "yup";

export interface SNowConfigFormData {
  oauthInstanceName: string;
  oauthClientSecret: string;
  oauthClientId: string;
}

interface Props {
  onSubmit: (formData: SNowConfigFormData) => void;
}

const SNowConfigForm: React.FunctionComponent<Props> = ({ onSubmit }) => {
  const snow = useAppSelector((state) => state.snow);
  const source = snow?.dataSource ?? null;

  const formik = useFormik<SNowConfigFormData>({
    validationSchema: Yup.object({
      oauthInstanceName: Yup.string().required("Please enter instanceName."),
      oauthClientId: Yup.string().required("Please enter oauthClientId."),
      oauthClientSecret: Yup.string().required(
        "Please enter oauthClientSecret."
      )
    }),
    initialValues: {
      oauthInstanceName: (source && source.oauthInstanceName) || "",
      oauthClientSecret: (source && source.oauthClientSecret) || "",
      oauthClientId: (source && source.oauthClientId) || ""
    },
    validateOnChange: false,
    onSubmit: (values: SNowConfigFormData) =>
      onSubmit({
        oauthInstanceName: values.oauthInstanceName.trim(),
        oauthClientId: values.oauthClientId.trim(),
        oauthClientSecret: values.oauthClientSecret.trim()
      })
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <FormGroup row>
        <Label sm={3} for="oauthInstanceName">
          Instance Name
        </Label>
        <Col sm={9}>
          <Input
            type="text"
            name="oauthInstanceName"
            placeholder=""
            value={formik.values.oauthInstanceName}
            onChange={formik.handleChange}
            invalid={!!formik.errors.oauthInstanceName}
          />
          <FormFeedback>{formik.errors.oauthInstanceName}</FormFeedback>
          <FormText>Instance Name provided by ServiceNow</FormText>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3} for="oauthClientId">
          ClientId
        </Label>
        <Col sm={9}>
          <Input
            type="text"
            name="oauthClientId"
            placeholder=""
            value={formik.values.oauthClientId}
            onChange={formik.handleChange}
            invalid={!!formik.errors.oauthClientId}
          />
          <FormFeedback>{formik.errors.oauthClientId}</FormFeedback>
          <FormText>ClientId provided by ServiceNow</FormText>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3} for="oauthClientSecret">
          Client Secret
        </Label>
        <Col sm={9}>
          <Input
            type="text"
            name="oauthClientSecret"
            placeholder=""
            value={formik.values.oauthClientSecret}
            onChange={formik.handleChange}
            invalid={!!formik.errors.oauthClientSecret}
          />
          <FormFeedback>{formik.errors.oauthClientSecret}</FormFeedback>
          <FormText>Client Secret provided by ServiceNow</FormText>
        </Col>
      </FormGroup>
      <div className="float-right">
        <Button type="submit">Authorize</Button>
      </div>
    </Form>
  );
};

export { SNowConfigForm };
