import React from "react";
import sanitizeHtml from "sanitize-html";
import "./SearchResultPreview.scss";

interface Props {
  previewSnippet: string | null;
}

const sanitizeConfig = {
  allowedAttributes: {
    "*": ["class"]
  }
};

const SearchResultPreview: React.FC<Props> = ({ previewSnippet }) => {
  if (previewSnippet === null) {
    return null;
  }

  const cleanPreviewSnippet = sanitizeHtml(previewSnippet, sanitizeConfig);

  return (
    <>
      <span
        className="search-result-preview-text"
        dangerouslySetInnerHTML={{
          __html: cleanPreviewSnippet
        }}
      />
    </>
  );
};

export { SearchResultPreview };
