import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { useAppSelector } from "@aptedge/lib-ui/src/redux/hook/hook";
import { useFormik } from "formik";
import { FC } from "react";
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label
} from "reactstrap";
import * as Yup from "yup";

export interface MSTeamsConfigFormData {
  tenantId: string;
}

interface Props {
  onSubmit: (formData: MSTeamsConfigFormData) => void;
}

const MSTeamsConfigForm: FC<Props> = ({ onSubmit }) => {
  const msTeamsSource = useAppSelector((state) => state.msTeams);
  const source = msTeamsSource?.dataSource ?? null;
  const formik = useFormik<MSTeamsConfigFormData>({
    validationSchema: Yup.object({
      tenantId: Yup.string().required("Please enter the Tenant ID!")
    }),
    initialValues: {
      tenantId: (source && source.tenantId) || ""
    },
    validateOnChange: false,
    onSubmit: (values: MSTeamsConfigFormData) =>
      onSubmit({ tenantId: values.tenantId.trim() })
  });

  return (
    <>
      <Form onSubmit={formik.handleSubmit}>
        <FormGroup row>
          <Label sm={3} for="clientSecret">
            Tenant Id
          </Label>
          <Col sm={9}>
            <Input
              type="text"
              name="tenentId"
              placeholder=""
              value={formik.values.tenantId}
              onChange={(e) => formik.setValues({ tenantId: e.target.value })}
              invalid={!!formik.errors.tenantId}
            />
            <FormFeedback>{formik.errors.tenantId}</FormFeedback>
            <FormText>Tenant Id provided by MS Teams</FormText>
          </Col>
        </FormGroup>
        <div className="float-right">
          <Button type="submit">Authorize</Button>
        </div>
      </Form>
    </>
  );
};

export { MSTeamsConfigForm };
