import { ISavedQuery } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { IUpdateSavedQueryArgs } from "../../types/clients";
import { request } from "../../utils/request";
import { patch } from "../utils/buildRequest";

async function updateSavedQuery(
  args: IUpdateSavedQueryArgs
): Promise<ISavedQuery> {
  const { id, ...requestBody } = { ...args };

  const url = `${getBackendApiUrl()}/saved-filters/${id}/`;
  const options = patch(requestBody);

  return request(url, options);
}

export { updateSavedQuery };
