import { BetaLabel } from "@aptedge/lib-ui/src/components/BetaLabel/BetaLabel";
import { SentimentScore } from "@aptedge/lib-ui/src/components/SentimentScore/SentimentScore";
import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import { ITicketFrustration } from "@aptedge/lib-ui/src/types/entities";
import React from "react";

interface Props {
  highlightedText: string;
  frustration: ITicketFrustration | null;
}

const TicketFrustrationInfo: React.FC<Props> = ({
  highlightedText,
  frustration
}) => {
  const { flags } = useFlags();
  if (!frustration) {
    return null;
  }

  const activeFrustration =
    frustration.text.replace(/&nbsp;/g, " ").trim() ===
    highlightedText.replace(/&nbsp;/g, " ").trim()
      ? frustration
      : undefined;
  return (
    <div className="frustration-info">
      <div className="d-flex align-items-center">
        {activeFrustration && flags.sentimentScore && (
          <>
            <SentimentScore size={11} score={activeFrustration.score} />
            <span className="ml-1">Frustration score</span>
            <BetaLabel />
          </>
        )}
      </div>
    </div>
  );
};

export { TicketFrustrationInfo };
