import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";
import { post } from "../utils/buildRequest";

function createAgentEmailDomainConfig(value: string): Promise<string> {
  const url = `${getBackendApiUrl()}/config/AGENT_EMAIL_DOMAINS/`;
  const options = post({ value });

  return request(url, options);
}

export { createAgentEmailDomainConfig };
