import { RefObject } from "react";
import { eventWasTriggeredOutsideRef } from "../utils/domUtils";
import { useEventListener } from "./useEventListener";

function useOnMouseDownOutside<E extends HTMLElement>(
  ref: RefObject<E>,
  cb: (e: MouseEvent) => void
): void {
  useEventListener("mousedown", (e) => {
    if (!!ref.current && eventWasTriggeredOutsideRef(e, ref)) {
      cb(e);
    }
  });
}

export { useOnMouseDownOutside };
