import { FilterWrapper } from "@aptedge/lib-ui/src/components/Filter/FilterWrapper";
import { IQueryFilter } from "@aptedge/lib-ui/src/components/Filter/types";
import { MultiSelect } from "@aptedge/lib-ui/src/components/MultiSelect/MultiSelect";
import React from "react";
import { useTicketStatuses } from "../../hooks/clients/useTicketStatuses";
import { STATUS_FILTER } from "./data";

type Props = {
  value?: IQueryFilter;
  onChange: (val: IQueryFilter) => void;
};

function TicketStatusFilter(props: Props): React.ReactElement {
  const ticketStatusQuery = useTicketStatuses();

  const statuses =
    ticketStatusQuery.data?.statuses
      .slice()
      .sort((a, b) => a.localeCompare(b)) || [];

  return (
    <FilterWrapper {...STATUS_FILTER} {...props}>
      {({ values, onChange }) => (
        <MultiSelect
          name="status-filter"
          value={values}
          data={statuses}
          getOptionLabel={(status) => status}
          onChange={(statuses) => onChange(statuses)}
        />
      )}
    </FilterWrapper>
  );
}

export { TicketStatusFilter };
