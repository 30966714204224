import { Chip } from "@aptedge/lib-ui/src/components/Chip/Chip";
import { IEdgeAuditLogFileChange } from "@aptedge/lib-ui/src/types/entities";
import React from "react";
import { ArrowTransition } from "./ArrowTransition";
import { SummarySentence } from "./SummarySentence";
import { WithAuditLog } from "./WithAuditLog";
import type { AuditDetailsFC, AuditSummaryFC } from "./WithAuditLog";

const Details: AuditDetailsFC<IEdgeAuditLogFileChange> = (props) => {
  const { log } = props;
  const { added, removed } = log.data;

  const files = added || removed;
  const displayText = !!added ? "Added" : "Removed";

  const displayedTo = (
    <div>
      <p>
        <span>{displayText} </span>
        {!!files &&
          files.map((file) => (
            <Chip
              key={`audit-log-label-${file.fileId}`}
              className="mr-1"
              label={file.fileName}
            />
          ))}
      </p>
    </div>
  );

  return (
    <div className="audit-log-file-change">
      <ArrowTransition from={null} to={displayedTo} />
    </div>
  );
};

const Summary: AuditSummaryFC<IEdgeAuditLogFileChange> = (props) => {
  const { VERBS, OBJECTS } = SummarySentence;
  const { author, log } = props;
  const didAdd = !!log.data.added;

  const verb = didAdd ? VERBS.ADDED : VERBS.REMOVED;

  return (
    <SummarySentence
      subject={author}
      verb={verb}
      object={OBJECTS.FILE}
      displayText={log?.displayText ?? ""}
    />
  );
};

Details.displayName = "AuditFileChangeDetails";
const AuditLogFileChange = WithAuditLog(Details, Summary);

export { AuditLogFileChange };
