import { FilterWrapper } from "@aptedge/lib-ui/src/components/Filter/FilterWrapper";
import { IQueryFilter } from "@aptedge/lib-ui/src/components/Filter/types";
import { MultiSelect } from "@aptedge/lib-ui/src/components/MultiSelect/MultiSelect";
import { IFeature } from "@aptedge/lib-ui/src/types/entities";
import React, { useCallback } from "react";
import { useFeatures } from "../../hooks/clients/useProducts";
import { FEATURE_FILTER } from "./data";

type Props = {
  value?: IQueryFilter;
  onChange: (val: IQueryFilter) => void;
};

function FeatureFilter(props: Props): React.ReactElement {
  const featuresQuery = useFeatures();

  const sortedFeatures = (featuresQuery.data?.slice() ?? []).sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  const renderFeature = useCallback((feature: IFeature): React.ReactElement => {
    return (
      <div>
        <span style={{ fontSize: ".9rem" }}>{feature.name}</span>
        {feature.productName && (
          <span className="d-block text-muted" style={{ fontSize: ".6rem" }}>
            {feature.productName}
          </span>
        )}
      </div>
    );
  }, []);

  return (
    <FilterWrapper {...FEATURE_FILTER} {...props}>
      {({ values, onChange }) => {
        const selected = values
          ? sortedFeatures.filter((f) => values.includes(String(f.id)))
          : undefined;

        return (
          <MultiSelect
            name="feature-filter"
            data={sortedFeatures}
            value={selected}
            getOptionLabel={(t) => t.name}
            renderOption={renderFeature}
            onChange={(features) => onChange(features.map((t) => String(t.id)))}
          />
        );
      }}
    </FilterWrapper>
  );
}

export { FeatureFilter };
