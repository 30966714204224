import { ApiError } from "@aptedge/lib-ui/src/utils/ApiError";

const createErrorMessage = (e: unknown): string => {
  const apiError = e as ApiError;
  const showErrorMessage =
    apiError?.code === 404 || apiError?.code === 422 || apiError?.code === 400;

  return showErrorMessage && apiError.displayMessage
    ? apiError.displayMessage
    : "Something went wrong. Please try again.";
};

export { createErrorMessage };
