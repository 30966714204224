import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import { BoxSizing } from "@aptedge/lib-ui/src/styles/boxes";
import React from "react";

const LoadingPage: React.FC = () => {
  const style = {
    height: "100%",
    minHeight: `calc(100vh - ${BoxSizing.NAVIGATION_BAR})`,
    width: "100%"
  };

  return (
    <div style={style} className="d-flex align-items-center">
      <Spinner />
    </div>
  );
};

export default LoadingPage;
