import { FilterWrapper } from "@aptedge/lib-ui/src/components/Filter/FilterWrapper";
import { IQueryFilter } from "@aptedge/lib-ui/src/components/Filter/types";
import { MultiSelect } from "@aptedge/lib-ui/src/components/MultiSelect/MultiSelect";
import React from "react";
import { useProducts } from "../../hooks/clients/useProducts";
import { PRODUCT_FILTER } from "./data";

type Props = {
  value?: IQueryFilter;
  onChange: (val: IQueryFilter) => void;
};

function ProductFilter(props: Props): React.ReactElement {
  const productsQuery = useProducts();

  const sortedProducts = (productsQuery.data?.slice() ?? []).sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  return (
    <FilterWrapper {...PRODUCT_FILTER} {...props}>
      {({ values, onChange }) => {
        const selected = values
          ? sortedProducts.filter((p) => values.includes(String(p.id)))
          : undefined;

        return (
          <MultiSelect
            name="product-filter"
            data={sortedProducts}
            value={selected}
            getOptionLabel={(p) => p.name}
            onChange={(products) => onChange(products.map((p) => String(p.id)))}
          />
        );
      }}
    </FilterWrapper>
  );
}

export { ProductFilter };
