import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { Feedback } from "@aptedge/lib-ui/src/components/Feedback/Feedback";
import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import {
  EntityType,
  ILabelSetting,
  LabelEntityType
} from "@aptedge/lib-ui/src/types/entities";
import { logger } from "@aptedge/lib-ui/src/utils/logger";
import classNames from "classnames";
import { useFormik } from "formik";
import React, { createRef, useEffect } from "react";
import { useMutation } from "react-query";
import * as Yup from "yup";
import { createLabel } from "../../../clients/Labels/createLabel";
import WithLoading from "../../../components/WithLoading/WithLoading";
import "./LabelCreateItem.scss";

interface Props {
  className?: string;
  heading: string;
  parent: ILabelSetting | null;
  type: LabelEntityType;
  onSave: () => void;
  onCancel: () => void;
}

interface FormState {
  name: string;
  term: string;
}

const LabelCreateItem: React.FC<Props> = (props) => {
  const { type, parent, onCancel, onSave } = props;
  const { heading, className } = props;

  const inputRef = createRef<HTMLInputElement>();

  const addLabel = useMutation(createLabel, { onSuccess: onSave });

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik<FormState>({
    initialValues: {
      name: "",
      term: ""
    },
    onSubmit: (values) => {
      if (type === EntityType.FEATURE) {
        if (!parent) {
          logger.error("Parent is required.");
          return;
        }

        addLabel.mutate({
          name: values.name,
          term: values.term,
          productId: parent.id,
          type
        });
      } else {
        addLabel.mutate({ name: values.name, term: values.term, type });
      }
    },
    validateOnBlur: false,
    validationSchema: Yup.object({
      name: Yup.string().required("A name is required."),
      term: Yup.string().required("A search term is required.")
    })
  });

  return (
    <WithLoading
      isLoading={addLabel.isLoading}
      type="overlay"
      fallback={<Spinner />}
    >
      <div className={classNames("label-create-item p-2", className)}>
        <h5>{heading}</h5>
        <form onSubmit={formik.handleSubmit}>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="name">Name</label>
              <input
                id="name"
                type="text"
                ref={inputRef}
                className={classNames("form-control", {
                  "is-invalid": !!formik.errors.name
                })}
                aria-describedby="nameHelp"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
              {formik.errors.name && <Feedback>{formik.errors.name}</Feedback>}
              <small id="nameHelp" className="form-text text-muted">
                Choose a name for the label
              </small>
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="term">Search term</label>
              <input
                id="term"
                type="text"
                className={classNames("form-control", {
                  "is-invalid": !!formik.errors.term
                })}
                aria-describedby="termHelp"
                value={formik.values.term}
                onChange={formik.handleChange}
              />
              {formik.errors.term && <Feedback>{formik.errors.term}</Feedback>}
              <small id="termHelp" className="form-text text-muted">
                Enter the primary search term we should used to match tickets
                with this label (it can be the label&apos;s name)
              </small>
            </div>
          </div>

          {addLabel.error && (
            <Feedback>{(addLabel.error as Error).message}</Feedback>
          )}

          <div className="d-flex mt-2 justify-content-end">
            <Button
              type="button"
              className="mr-2"
              color="secondary"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Save
            </Button>
          </div>
        </form>
      </div>
    </WithLoading>
  );
};

export { LabelCreateItem };
