import { MyProductsListItem } from "@aptedge/lib-ui/src/types/entities";
import * as React from "react";
import { MyProductsItem } from "./MyProductsItem";
import styles from "./MyProductsItems.module.scss";

interface MyProductsItemsProps {
  items: MyProductsListItem[];
  handleItemClick: (itemId: string) => void;
  selectedIds: string[];
}

const MyProductsItems: React.FC<MyProductsItemsProps> = ({
  items,
  handleItemClick,
  selectedIds
}) => {
  return (
    <div className={styles.container}>
      {items.map((item) => {
        return (
          <MyProductsItem
            key={item.id}
            item={item}
            isSelected={selectedIds.includes(item.id)}
            handleItemClick={handleItemClick}
          />
        );
      })}
    </div>
  );
};

export { MyProductsItems };
