import { Card } from "@aptedge/lib-ui/src/components/Card/Card";
import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import { Tooltip } from "@aptedge/lib-ui/src/components/Tooltip/Tooltip";
import classNames from "classnames";
import { oneLine } from "common-tags";
import { DateTime } from "luxon";
import React from "react";
import { TooltipMessage } from "../../registry/TooltipMessage";
import { displayAge } from "../../utils/time";
import { SlimCardSkeleton } from "./SlimCardSkeleton";
import "./SlimCard.scss";

export interface SlimCardProps {
  title: string;
  subTitle?: string | null;
  change?: number;
  value?: number;
  age?: number;
  formatValue?: (value: number) => string;
  positiveColor?: "green" | "red";
  type?: SlimCardType;
  className?: string;
  isHoverable?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
}

export enum SlimCardType {
  PERIOD_OVER_PERIOD = "period_over_period"
}

const POP_TOOLTIP = oneLine`
  This percentage is calculated by comparing the total for the selected time period and
  the total for the previous time period of the same length. For example, if you have
  selected a 1 month period, the change is based on the total for the month prior to
  the selected period.`;

const TOOLTIPS = {
  [SlimCardType.PERIOD_OVER_PERIOD]: POP_TOOLTIP
} as const;

function SlimCard(props: SlimCardProps): React.ReactElement {
  const {
    value,
    age,
    title,
    subTitle,
    change,
    positiveColor = "green",
    formatValue = (val) => val.toLocaleString(),
    type,
    className,
    isHoverable = false,
    isLoading = false,
    ...rest
  } = props;

  const negativeColor = positiveColor === "green" ? "red" : "green";
  const displayValue = value !== undefined ? formatValue(value) : "N/A";
  const tooltip = type ? TOOLTIPS[type] : null;

  return (
    <div
      className={classNames("slim-card", className, {
        hoverable: isHoverable && !isLoading
      })}
      data-testid={getSlimCardTestId(title)}
      {...rest}
    >
      {isLoading ? (
        <SlimCardSkeleton />
      ) : (
        <Card>
          <div className="slim-card-header">
            <div className="slim-card-title">
              <h3>{title}</h3>
              {subTitle && (
                <div className="slim-card-sub-title">{subTitle}</div>
              )}
            </div>
            {age && (
              <Tooltip content={TooltipMessage.AVERAGE_TICKET_AGE}>
                <div className="average-age">
                  <Icons.Clock />
                  <h3>{getRelativeAverageFromDays(age)}</h3>
                </div>
              </Tooltip>
            )}
          </div>
          <div className="slim-card-content">
            <span className="value">{displayValue}</span>
            {change !== undefined && (
              <Tooltip
                content={tooltip}
                disabled={!tooltip}
                placement="right-end"
              >
                <span
                  className={classNames("change", {
                    [positiveColor]: change >= 0,
                    [negativeColor]: change < 0
                  })}
                >
                  {change > 0 ? "+" : undefined}
                  {change.toLocaleString("en", {
                    style: "percent",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2
                  })}
                </span>
              </Tooltip>
            )}
          </div>
        </Card>
      )}
    </div>
  );
}

const getRelativeAverageFromDays = (days: number): string | null => {
  const now = DateTime.local();
  const then = now.minus({ days }).toSeconds();
  const averageDays = displayAge(then, { unit: "days" });
  const agoPostfix = averageDays?.lastIndexOf(" ago");
  const endIndex = agoPostfix !== -1 && !!agoPostfix ? agoPostfix : undefined;

  return !!averageDays ? averageDays.slice(0, endIndex) : null;
};

function getSlimCardTestId(title: string): string {
  const id = title.replace(/:/gm, "").replace(/\s/gm, "-").toLowerCase();
  return `slim-card-${id}`;
}

export { SlimCard, getSlimCardTestId };
