import { useState } from "react";

function useClipboard(): {
  handleCopy: (text: string) => Promise<void>;
  copied: boolean;
  error: boolean;
} {
  const [copied, setCopied] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const successHandler = (): void => {
    setCopied(true);
    setError(false);
  };

  const errorHandler = (): void => {
    setError(true);
    setCopied(false);
  };

  // Setup clippy every time the selector changes.
  const handleCopy = async (text: string): Promise<void> => {
    try {
      await window.navigator.clipboard.writeText(text);
      successHandler();
    } catch (error) {
      errorHandler();
    }
  };

  return {
    handleCopy,
    copied,
    error
  };
}

export { useClipboard };
