import React, { useEffect } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { Routes } from "../../routes/routes";

const LogoutPage: React.FC = () => {
  const { logout } = useAuth();
  const location = useLocation();

  useEffect(() => {
    logout();
  }, [logout]);
  return <Redirect to={{ pathname: Routes.LOGIN, search: location.search }} />;
};

export { LogoutPage };
