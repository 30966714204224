import * as React from "react";
import { ProductFiltersMenu } from "./ProductFiltersMenu";
import { QuickFiltersMenu } from "./QuickFiltersMenu";
import styles from "./SearchFilters.module.scss";

const SearchFilters: React.FC = () => {
  return (
    <div className={styles.container}>
      <ProductFiltersMenu />
      <QuickFiltersMenu />
    </div>
  );
};

export { SearchFilters };
