import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";

function fetchKBExclusionConfig(): Promise<string[]> {
  const url = `${getBackendApiUrl()}/config/ML_KNOWLEDGE_BASE_ARTICLE_EXCLUDED_URLS/`;

  return request<string[]>(url);
}

export { fetchKBExclusionConfig };
