import { Modal, ModalWidth } from "@aptedge/lib-ui/src/components/Modal/Modal";
import {
  IEdgeInfo,
  IEdgeSearchResult
} from "@aptedge/lib-ui/src/types/entities";
import { GTM_EVENTS, dataLayerPush } from "@aptedge/lib-ui/src/utils/event";
import React, { ComponentProps, useState } from "react";
import { useMutation } from "react-query";
import { mergeEdges } from "../../../clients/Edges/mergeEdges";
import { IMergeEdgeArgs } from "../../../types/clients";
import "./MergeEdgeModal.scss";
import { MergeEdgeModalConfirm } from "./MergeEdgeModalConfirm";
import { MergeEdgeModalExec } from "./MergeEdgeModalExec";
import { MergeEdgeModalSearch } from "./MergeEdgeModalSearch";

enum Page {
  ONE,
  TWO,
  THREE
}

interface Props
  extends Omit<ComponentProps<typeof Modal>, "title" | "width" | "onClose"> {
  edge: IEdgeInfo;
  onClose: () => void;
  onUpdate: (edge: IEdgeInfo) => void;
}

function MergeEdgeModal(props: Props): React.ReactElement {
  const { edge, onClose, onUpdate, ...rest } = props;
  const [currentPage, setCurrentPage] = useState<Page>(Page.ONE);
  const [selectedEdges, setSelectedEdges] = useState<IEdgeSearchResult[]>([]);

  const mergeEdgesClient = useMutation<IEdgeInfo, Error, IMergeEdgeArgs>(
    mergeEdges,
    {
      onSuccess: (newEdge) => {
        onUpdate(newEdge);
        handleClose();
      }
    }
  );

  const allowClosing = currentPage !== Page.THREE;
  const handleClose = (): void => {
    setSelectedEdges([]);
    setCurrentPage(Page.ONE);
    onClose();
  };

  const handleMergeEdge = (): void => {
    setCurrentPage(Page.THREE);
    dataLayerPush({ event: GTM_EVENTS.GTM_MERGE_EDGE });
  };

  return (
    <Modal
      title={`Merge EDGE-${edge.id}`}
      className="merge-edge"
      width={ModalWidth.LARGE}
      showClose={allowClosing}
      escClose={allowClosing}
      onClose={allowClosing ? handleClose : undefined}
      {...rest}
    >
      {rest.isOpen && (
        <>
          {
            {
              [Page.ONE]: (
                <MergeEdgeModalSearch
                  edge={edge}
                  selectedEdges={selectedEdges}
                  setSelectedEdges={setSelectedEdges}
                  onContinue={() => setCurrentPage(Page.TWO)}
                />
              ),
              [Page.TWO]: (
                <MergeEdgeModalConfirm
                  targetEdge={edge}
                  edges={selectedEdges}
                  onBack={() => setCurrentPage(Page.ONE)}
                  onContinue={() => handleMergeEdge()}
                />
              ),
              [Page.THREE]: (
                <MergeEdgeModalExec
                  targetEdge={edge}
                  sourceEdges={selectedEdges}
                  client={mergeEdgesClient}
                  onBack={() => setCurrentPage(Page.TWO)}
                />
              )
            }[currentPage]
          }
        </>
      )}
    </Modal>
  );
}

export { MergeEdgeModal };
