import classNames from "classnames";
import React from "react";
import "./Feedback.scss";

const Feedback: React.FunctionComponent<Props> = ({
  children,
  type = "error"
}) => {
  return (
    <div className={classNames("feedback", `feedback-${type}`)}>{children}</div>
  );
};

interface Props {
  type?: "info" | "success" | "error";
}

export { Feedback };
