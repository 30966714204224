import React from "react";
import "./Loader.scss";

export enum LoaderSize {
  SMALL = 5,
  DEFAULT = 10,
  LARGE = 15
}

export enum LoaderSpacing {
  SMALL = 10,
  DEFAULT = 15,
  LARGE = 20
}

interface Props {
  size?: LoaderSize;
  spacing?: LoaderSpacing;
}

const Loader: React.FC<Props> = (props) => {
  const { size = LoaderSize.DEFAULT, spacing = LoaderSpacing.DEFAULT } = props;

  const dotStyle = {
    width: size,
    height: size
  };

  const beforeStyle = {
    ...dotStyle,
    left: -spacing,
    animationDelay: "0s"
  };

  const afterStyle = {
    ...dotStyle,
    left: spacing,
    animationDelay: "1s"
  };

  return (
    <div data-testid="loader" className="loader-container">
      <div className="loader" style={beforeStyle}></div>
      <div className="loader" style={dotStyle}></div>
      <div className="loader" style={afterStyle}></div>
    </div>
  );
};

export { Loader };
