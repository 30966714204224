import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import * as React from "react";
import styles from "./Header.module.scss";
import { HeaderSummary } from "./HeaderSummary";
import { HeaderTicketItem } from "./HeaderTicketItem";

interface HeaderProps {
  ticketId: string;
  articlesLength: number;
}

const Header: React.FC<HeaderProps> = ({ ticketId, articlesLength }) => {
  const { flags } = useFlags();
  return (
    <div className={styles.container}>
      <HeaderSummary articlesLength={articlesLength} />
      {!flags.kbGenPublishProcess && <HeaderTicketItem ticketId={ticketId} />}
    </div>
  );
};

export { Header };
