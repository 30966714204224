import { EntityType, ILabel } from "@aptedge/lib-ui/src/types/entities";
import faker from "faker";

function buildLabel(overrides: Partial<ILabel> = {}): ILabel {
  const id = overrides.id ?? faker.random.number();

  return {
    name: "Label",
    type: EntityType.THEME,
    ...overrides,
    id
  };
}

export { buildLabel };
