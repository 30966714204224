import { ModalWidth } from "@aptedge/lib-ui/src/components/Modal/Modal";
import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import {
  IBusinessDataSource,
  ITicketSource,
  TicketSourceType
} from "@aptedge/lib-ui/src/types/entities";
import classNames from "classnames";
import React from "react";
import { useMutation } from "react-query";
import { ToastType } from "react-toastify";
import { createTicketSource } from "../../../clients/TicketSources/createTicketSource";
import { ConfirmationModal } from "../../../components/ConfirmationModal/ConfirmationModal";
import { Toast } from "../../../components/Toast/Toast";
import WithLoading from "../../../components/WithLoading/WithLoading";
import { getBackendUrl } from "../../../config";
import { useAuth } from "../../../context/AuthContext";
import { UseOAuthDataSourceResult } from "../../../hooks/clients/useDataSource";
import { TooltipMessage } from "../../../registry/TooltipMessage";
import { ClientStatus } from "../../../types/clients";
import { AddButton } from "../common/AddButton";
import { StatusButton } from "../common/StatusButton";
import { providerIsAuthorized } from "../utils/providerIsAuthorized";
import { CustomFieldMappingConfig } from "./CustomFieldMappingConfig";
import { ZendeskConfigForm, ZendeskFormData } from "./ZendeskConfigForm";

interface Props {
  ticketResource: UseOAuthDataSourceResult<ITicketSource>;
  businessResource: UseOAuthDataSourceResult<IBusinessDataSource>;
}

const TicketSourceConfig: React.FunctionComponent<Props> = (props) => {
  const { ticketResource, businessResource } = props;

  const { user } = useAuth();
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [redirectStatus, setRedirectStatus] = React.useState<ClientStatus>(
    ClientStatus.PROMPT
  );

  const businessDataSource = businessResource.dataSource || null;
  const ticketSource = ticketResource.dataSource || null;
  const isLoading = ticketResource.isLoading || businessResource.isLoading;

  const addServiceCloud = useMutation(createTicketSource, {
    onSuccess: () => ticketResource.reload()
  });
  const addZendesk = useMutation(createTicketSource, {
    onError: (): void => {
      setRedirectStatus(ClientStatus.PROMPT);
    },
    onSuccess: (): void => {
      const url = `${getBackendUrl()}/zendesk/login`;
      window.location.href = url;
    }
  });

  const salesforceButtonIsDisabled =
    !businessDataSource || !businessDataSource.canBeATicketSource;

  const zendeskSource =
    ticketSource && ticketSource.type === TicketSourceType.ZENDESK
      ? ticketSource
      : undefined;
  const salesforceSource =
    ticketSource && ticketSource.type === TicketSourceType.SALESFORCE
      ? ticketSource
      : undefined;
  const isConnectedToSalesforce = providerIsAuthorized(salesforceSource);

  const onFormSubmit = async (formData: ZendeskFormData): Promise<void> => {
    addZendesk.mutate({
      ...formData,
      providerUserLogin: "",
      type: TicketSourceType.ZENDESK
    });
    setRedirectStatus(ClientStatus.LOADING);
  };

  const onClickSalesforce = async (): Promise<void> => {
    if (businessDataSource) {
      addServiceCloud.mutate({
        fromBusinessDataSource: businessDataSource.id,
        providerUserLogin: "",
        type: TicketSourceType.SALESFORCE
      });
    }
  };

  return (
    <section className={"mt-5"}>
      <div className="row">
        <WithLoading isLoading={isLoading} fallback={<Spinner />}>
          <div className="col-md-5 p-2 pr-3">
            <h4 className="text-uppercase">Support Tickets</h4>
            <p>Connect AptEdge to your Support Ticket System.</p>
          </div>
          <div className="col-md-7 p-2 pl-3">
            <>
              <div className="mb-3">
                <h5>Salesforce Service Cloud</h5>
                <p>Configure your OAuth settings below.</p>
              </div>
              {salesforceSource ? (
                <StatusButton status={isConnectedToSalesforce ? "ok" : "error"}>
                  <div>Salesforce Service Cloud</div>
                  <div className="text-muted">
                    {businessDataSource?.instanceName || "salesforce.com"}
                  </div>
                </StatusButton>
              ) : (
                <AddButton
                  disabled={salesforceButtonIsDisabled}
                  tooltip={
                    businessDataSource
                      ? TooltipMessage.SERVICE_CLOUD_NOT_CONNECTED
                      : TooltipMessage.SALESFORCE_NOT_CONNECTED
                  }
                  onClick={onClickSalesforce}
                >
                  Connect
                </AddButton>
              )}
              <div className={classNames("mb-3 mt-5")}>
                <h5>Zendesk</h5>
                <p>Configure your OAuth settings below.</p>
              </div>
              {zendeskSource ? (
                <StatusButton
                  status={providerIsAuthorized(zendeskSource) ? "ok" : "error"}
                  onClickEdit={() => setShowModal(true)}
                >
                  <div>Zendesk</div>
                  <div className="text-muted">
                    {zendeskSource?.instanceName || "salesforce.com"}
                  </div>
                </StatusButton>
              ) : (
                <AddButton onClick={() => setShowModal(true)}>
                  Connect
                </AddButton>
              )}
              {(user?.onboardingComplete || user?.admin) && (
                <div className={classNames("mb-3 mt-5")}>
                  <CustomFieldMappingConfig />
                </div>
              )}
            </>
          </div>
        </WithLoading>
      </div>
      <hr className="mt-4" />
      <ConfirmationModal
        title="Zendesk OAuth Settings"
        loadingTitle="Redirecting to Zendesk..."
        width={ModalWidth.MEDIUM}
        isOpen={showModal}
        status={redirectStatus}
        onClose={() => setShowModal(false)}
      >
        <p className="mb-4">Configure your settings below.</p>
        <ZendeskConfigForm source={zendeskSource} onSubmit={onFormSubmit} />
      </ConfirmationModal>

      {(addZendesk.isError || addServiceCloud.isError) && (
        <Toast type={ToastType.ERROR}>Something went wrong.</Toast>
      )}
    </section>
  );
};

export { TicketSourceConfig };
