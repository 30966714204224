import { Icon } from "@aptedge/lib-ui/src/components/Icon/Icon";
import { Tooltip } from "@aptedge/lib-ui/src/components/Tooltip/Tooltip";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons/faPlusCircle";
import classNames from "classnames";
import React from "react";
import "./AddButton.scss";

interface Props {
  disabled?: boolean;
  tooltip?: string;
  onClick: () => void;
}

const AddButton: React.FC<Props> = (props) => {
  const { disabled, children, onClick, tooltip } = props;

  const handleClick = (): void => {
    if (!disabled) onClick();
  };

  let content = (
    <>
      <Icon size="lg" icon={faPlusCircle} />
      <span className="ml-3">{children}</span>
    </>
  );

  if (tooltip && disabled) {
    content = (
      <Tooltip content={tooltip} placement="auto">
        {content}
      </Tooltip>
    );
  }

  return (
    <div
      data-testid="add-button"
      className={classNames("add-button rounded", { disabled })}
      onClick={handleClick}
    >
      <div className="d-flex align-items-center">{content}</div>
    </div>
  );
};

export { AddButton };
