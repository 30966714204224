import { DateTime, Interval } from "luxon";
import React from "react";
import { Week } from "./internal/Week";
import "./Calendar.scss";

interface Props {
  min?: DateTime;
  max?: DateTime;
  month?: DateTime;
  selected?: DateTime;
  selectedRange?: Interval;
  onSelectDay?: (day: DateTime) => void;
}

const Calendar: React.FC<Props> = ({
  month = DateTime.local(),
  min,
  max,
  selected,
  selectedRange,
  onSelectDay = console.log
}) => {
  const firstDay = month.startOf("month");

  // Luxon's week starts on Monday, and is 1 indexed.
  const firstDisplayedDay =
    firstDay.weekday === 7
      ? firstDay
      : firstDay.startOf("week").minus({ days: 1 });

  const weeks: DateTime[] = [];
  let current: DateTime = DateTime.fromMillis(firstDisplayedDay.toMillis());

  // Create an array of Sundays, as long as they are in the current month
  // or the first week.
  while (
    Interval.fromDateTimes(current, current.plus({ weeks: 1 })).overlaps(
      Interval.fromDateTimes(firstDay, firstDay.plus({ months: 1 }))
    )
  ) {
    weeks.push(current);
    current = current.plus({ weeks: 1 });
  }

  return (
    <div
      className="calendar"
      data-testid={`calendar-${month.toFormat("M-yyyy")}`}
    >
      <header>
        <span>Su</span>
        <span>M</span>
        <span>Tu</span>
        <span>W</span>
        <span>Th</span>
        <span>F</span>
        <span>Sa</span>
      </header>
      {weeks.map((w, idx) => (
        <Week
          key={`${w.month}-${w.day}`}
          week={w}
          min={min}
          max={max}
          currentMonth={firstDay}
          selected={selected}
          selectedRange={selectedRange}
          onSelect={onSelectDay}
          data-testid={`week-${idx}`}
        />
      ))}
    </div>
  );
};

export { Calendar };
