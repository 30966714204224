import { FilterWrapper } from "@aptedge/lib-ui/src/components/Filter/FilterWrapper";
import { IQueryFilter } from "@aptedge/lib-ui/src/components/Filter/types";
import { MultiSelect } from "@aptedge/lib-ui/src/components/MultiSelect/MultiSelect";
import React from "react";
import { useQuery } from "react-query";
import { WebCacheKey } from "../../clients/cache";
import { fetchTicketPriorities } from "../../clients/Tickets/fetchTicketPriorities";
import { PRIORITY_FILTER } from "./data";

const ONE_HOUR = 60 * 1000 * 60;

type Props = {
  value?: IQueryFilter;
  onChange: (val: IQueryFilter) => void;
};

function PriorityFilter(props: Props): React.ReactElement {
  const ticketPrioritiesQuery = useQuery(
    WebCacheKey.TICKET_PRIORITIES,
    fetchTicketPriorities,
    {
      refetchOnWindowFocus: false, // Don't need to revalidate these on window refocus.
      refetchOnMount: false,
      // The time in milliseconds after data is considered stale. Once marked stale, RQ will refetch.
      staleTime: ONE_HOUR,
      // The time in milliseconds that unused/inactive cache (no components using it are mounted) data remains in memory.
      cacheTime: ONE_HOUR
    }
  );

  const sortedPriorities =
    ticketPrioritiesQuery.data?.slice().sort((a, b) => a.localeCompare(b)) ||
    [];

  return (
    <FilterWrapper {...PRIORITY_FILTER} {...props}>
      {({ values, onChange }) => {
        return (
          <MultiSelect
            name="priority-filter"
            value={values}
            data={sortedPriorities}
            getOptionLabel={(t) => t}
            onChange={(priorities) => onChange(priorities)}
          />
        );
      }}
    </FilterWrapper>
  );
}

export { PriorityFilter };
