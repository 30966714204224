import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface TicketData {
  ticketSubject: string;
  ticketId: string;
  ticketStatus: string;
}

type SliceStateType = {
  ticket: TicketData;
};

const initialState: SliceStateType = {
  ticket: {
    ticketSubject: "",
    ticketId: "",
    ticketStatus: ""
  }
};

const cookedTicketSlice = createSlice({
  name: "cookedTicket",
  initialState,
  reducers: {
    updateCookedTicket: (state, action: PayloadAction<TicketData>) => {
      state.ticket = action.payload;
    }
  }
});

export const { updateCookedTicket } = cookedTicketSlice.actions;

export default cookedTicketSlice.reducer;
