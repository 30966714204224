import { ActionBar } from "@aptedge/lib-ui/src/components/ActionBar/ActionBar";
import { Avatar } from "@aptedge/lib-ui/src/components/Avatar/Avatar";
import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { MarkdownContent } from "@aptedge/lib-ui/src/components/MarkdownContent/MarkdownContent";
import { ModalFooter } from "@aptedge/lib-ui/src/components/Modal/Modal";
import { Spacer } from "@aptedge/lib-ui/src/components/Spacer/Spacer";
import {
  IRichTextContent,
  TextEditor
} from "@aptedge/lib-ui/src/components/TextEditor/TextEditor";
import { Tooltip } from "@aptedge/lib-ui/src/components/Tooltip/Tooltip";
import { IEdgeComment } from "@aptedge/lib-ui/src/types/entities";
import capitalize from "lodash/capitalize";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { deleteEdgeComment } from "../../clients/Edges/deleteEdgeComment";
import { convertQueryStateToClientStatus } from "../../clients/utils/convertQueryStateToClientStatus";
import { ConfirmationModal } from "../../components/ConfirmationModal/ConfirmationModal";
import { useAuth } from "../../context/AuthContext";
import { displayAge } from "../../utils/time";

interface Props {
  comment: IEdgeComment;
  onEdit: (id: number, text: IRichTextContent) => void;
  onDelete: (id: number) => void;
  disabled?: boolean;
}

const EdgeComment: React.FC<Props> = ({
  comment,
  onEdit,
  onDelete,
  disabled
}) => {
  const { user } = useAuth();
  const [editing, setEditing] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const deleteComment = useMutation(deleteEdgeComment, {
    onSuccess: () => {
      onDelete(comment.id);
      setOpenDeleteModal(false);
    }
  });

  const editableComment = comment.user.email === user?.email;

  const handleSave = (content: IRichTextContent): void => {
    onEdit(comment.id, content);
    setEditing(false);
  };

  return (
    <>
      <div className="comment">
        <Avatar
          color={editableComment ? "var(--primary)" : "var(--gray-400)"}
          text={capitalize(comment.user.email)}
          tooltip={comment.user.email}
          size="42px"
        />
        <div className="comment-content">
          <Spacer className="comment-info" flex row size="medium">
            <span className="comment-username">{comment.user.email}</span>
            <span className="comment-date">
              {displayAge(comment.createdOn)}
            </span>
            {comment.updatedOn !== comment.createdOn && (
              <span className="comment-edited">
                <Tooltip content={displayAge(comment.updatedOn)}>
                  Edited
                </Tooltip>
              </span>
            )}
          </Spacer>
          <div className="body">
            {editing ? (
              <TextEditor
                content={comment.richText}
                defaultOpen={editing}
                onSave={handleSave}
                onCancel={() => setEditing(false)}
                isImageDropAllowed={false}
              />
            ) : (
              <MarkdownContent content={comment.richText?.content} />
            )}
          </div>
          {editableComment && !editing && !disabled && (
            <div className="actions">
              <span onClick={() => setEditing(true)}>Edit</span>
              <span onClick={() => setOpenDeleteModal(true)}>Delete</span>
            </div>
          )}
        </div>
      </div>
      <ConfirmationModal
        title="Delete comment?"
        status={convertQueryStateToClientStatus(deleteComment)}
        isOpen={openDeleteModal}
        loadingTitle="Deleting comment..."
        onClose={() => setOpenDeleteModal(false)}
      >
        <p>Are you sure you want to delete this comment?</p>
        <ModalFooter>
          <ActionBar.Container>
            <ActionBar.Right>
              <Spacer flex row size="medium">
                <Button
                  color="secondary"
                  onClick={() => setOpenDeleteModal(false)}
                >
                  Cancel
                </Button>
                <Button
                  data-testid="delete button"
                  color="danger"
                  onClick={() =>
                    deleteComment.mutate({
                      edgeId: comment.edgeId,
                      commentId: comment.id
                    })
                  }
                >
                  Delete
                </Button>
              </Spacer>
            </ActionBar.Right>
          </ActionBar.Container>
        </ModalFooter>
      </ConfirmationModal>
    </>
  );
};

export { EdgeComment };
