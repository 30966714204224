import { FilterWrapper } from "@aptedge/lib-ui/src/components/Filter/FilterWrapper";
import { IQueryFilter } from "@aptedge/lib-ui/src/components/Filter/types";
import React from "react";
import { WebCacheKey } from "../../clients/cache";
import { fetchTeams } from "../../clients/Teams/fetchTeams";
import { fetchTeamsById } from "../../clients/Teams/fetchTeamsById";
import { unwrapPaginatedClient } from "../../clients/utils/paginated";
import { Routes } from "../../routes/routes";
import { MultiSelectAsync } from "../SelectAsync/MultiSelectAsync";
import { StyledLink } from "../StyledLink/StyledLink";
import { EDGE_TEAM_FILTER } from "./data";

type Props = {
  value?: IQueryFilter;
  onChange: (val: IQueryFilter) => void;
};

const getTeams = unwrapPaginatedClient(fetchTeams);

function EdgeTeamFilter(props: Props): React.ReactElement {
  const paginationArgs = {
    sortBy: null,
    perPage: 20,
    page: 1
  };

  return (
    <FilterWrapper {...EDGE_TEAM_FILTER} {...props}>
      {({ values, onChange }) => {
        const ids = values?.map(Number);

        return (
          <MultiSelectAsync
            name="team-filter"
            value={ids}
            valueKey={WebCacheKey.TEAMS_BY_ID}
            valueLoader={fetchTeamsById}
            getOptionAsValue={(t) => t.id}
            getOptionLabel={(t) => t.name}
            optionsKey={WebCacheKey.TEAMS}
            optionsLoader={(textSearch) =>
              getTeams({ q: textSearch, ...paginationArgs })
            }
            onChange={(val) => onChange(val ? val.map(String) : [])}
            noDataPlaceholder={
              <div className="mt-2 mb-2 text-center">
                There are no teams configured. Try configuring some teams{" "}
                <StyledLink to={Routes.SETTINGS_WORKFLOW}>here</StyledLink>.
              </div>
            }
          />
        );
      }}
    </FilterWrapper>
  );
}

export { EdgeTeamFilter };
