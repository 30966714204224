/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from "react";

function useEventListener<K extends keyof HTMLElementEventMap>(
  eventName: K,
  handler: HTMLElementEventMap[K],
  element?: HTMLElement
): void;
function useEventListener<K extends keyof DocumentEventMap>(
  eventName: K,
  handler: DocumentEventMap[K],
  element?: Document
): void;
function useEventListener<K extends keyof WindowEventMap>(
  eventName: K,
  handler: WindowEventMap[K],
  element?: Window
): void;
function useEventListener<
  K extends keyof (HTMLElementEventMap & DocumentEventMap & WindowEventMap)
>(
  eventName: K,
  listener: (
    event: (HTMLElementEventMap & DocumentEventMap & WindowEventMap)[K]
  ) => void,
  element?: HTMLElement | Document | Window
): void;
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
function useEventListener(eventName: any, listener: any, element?: any): void {
  useEffect(() => {
    const elem = element
      ? element
      : typeof window === "undefined"
      ? undefined
      : document;

    if (!elem) return;

    const isSupported = !!elem.addEventListener;

    if (!isSupported) return;

    elem.addEventListener(eventName, listener);

    // Cleanup
    return () => {
      elem.removeEventListener(eventName, listener);
    };
  }, [eventName, listener, element]);
}

export { useEventListener };
