import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { Feedback } from "@aptedge/lib-ui/src/components/Feedback/Feedback";
import classNames from "classnames";
import { Formik } from "formik";
import React, { useEffect, useRef } from "react";
import * as Yup from "yup";

const validationSchema = Yup.object({
  domain: Yup.string().required("A Domain is required.")
});

interface Props {
  onCancel: () => void;
  onSubmit: (val: string) => void;
  isLoading?: boolean;
}

function SingleTextItemCreateForm(props: Props): React.ReactElement {
  const { onSubmit, onCancel, isLoading } = props;
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  return (
    <div className="agent-email-create-form p-2">
      <h5>Add a new URL prefix</h5>
      <Formik
        validateOnBlur={false}
        validationSchema={validationSchema}
        initialValues={{ domain: "" }}
        onSubmit={(values) => onSubmit(values.domain)}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <div className="form-row">
              <div className="form-group col">
                <label htmlFor="domain">Domain</label>
                <input
                  id="domain"
                  type="text"
                  ref={inputRef}
                  placeholder="yourwebsite.com"
                  className={classNames("form-control", {
                    "is-invalid": !!formik.errors.domain
                  })}
                  value={formik.values.domain}
                  onChange={formik.handleChange}
                />
                {formik.errors.domain && (
                  <Feedback>{formik.errors.domain}</Feedback>
                )}
              </div>
            </div>
            <div className="d-flex mt-2 justify-content-end">
              <Button
                type="button"
                className="mr-2"
                color="secondary"
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button disabled={isLoading} type="submit" color="primary">
                Save
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

export { SingleTextItemCreateForm };
