import React from "react";
import { createErrorMessage } from "../../utils/createErrorMessage";
import { Toast } from "../Toast/Toast";

interface Props {
  error: unknown | null;
  resetError: () => void;
}

const MutationErrorToast: React.FC<Props> = ({ error, resetError }) => {
  if (!error) {
    return null;
  }

  const errorMessage = createErrorMessage(error);

  return (
    <Toast type="error" onClose={resetError}>
      {errorMessage}
    </Toast>
  );
};

export { MutationErrorToast };
