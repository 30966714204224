import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import {
  NoData,
  NoDataMessage
} from "@aptedge/lib-ui/src/components/NoData/NoData";
import { TextInput } from "@aptedge/lib-ui/src/components/TextInput/TextInput";
import { Tooltip } from "@aptedge/lib-ui/src/components/Tooltip/Tooltip";
import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import { useDebounce } from "@aptedge/lib-ui/src/hooks/useDebounce";
import { useOnMouseDownOutside } from "@aptedge/lib-ui/src/hooks/useOnMouseDownOutside";
import { IEdgeInfo, ITicketListing } from "@aptedge/lib-ui/src/types/entities";
import { GTM_EVENTS, dataLayerPush } from "@aptedge/lib-ui/src/utils/event";
import classNames from "classnames";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState
} from "react";
import { useMutation, UseQueryResult } from "react-query";
import { deleteEdgeTicket } from "../../../clients/Edges/deleteEdgeTicket";
import { convertQueryStateToClientStatus } from "../../../clients/utils/convertQueryStateToClientStatus";
import { ConfirmationModal } from "../../../components/ConfirmationModal/ConfirmationModal";
import { MutationErrorToast } from "../../../components/ErrorHandler/MutationErrorToast";
import { PaginationAnalytics } from "../../../components/PaginationAnalytics/PaginationAnalytics";
import { PaginationBar } from "../../../components/PaginationBar/PaginationBar";
import WithLoading from "../../../components/WithLoading/WithLoading";
import { getBackendApiUrl } from "../../../config";
import { TooltipMessage } from "../../../registry/TooltipMessage";
import { IPaginatedRequest, IPaginatedResponse } from "../../../types/clients";
import { EdgeBulkReplyModal } from "../BulkReply/EdgeBulkReplyModal";
import { EdgeSectionLabel } from "../EdgeSectionLabel";
import { EdgeTicketsAddModal } from "./EdgeTicketsAddModal";
import { EdgeTicketsList } from "./EdgeTicketsList";
import { EdgeTicketsMigrateModal } from "./EdgeTicketsMigrateModal";
import "./EdgeTicketsWidget.scss";

type TicketArgs = IPaginatedRequest<{ edge: number; textQuery?: string }>;

interface Props {
  edge: IEdgeInfo;
  ticketsArgs: TicketArgs;
  setTicketsArgs: Dispatch<SetStateAction<TicketArgs>>;
  ticketsQuery: UseQueryResult<IPaginatedResponse<ITicketListing[]>>;
  onChange: () => void;
  onUpdateEdge: (edge: IEdgeInfo) => void;
}

function EdgeTicketsWidget(props: Props): React.ReactElement {
  const { edge, ticketsQuery, setTicketsArgs, onUpdateEdge, onChange } = props;

  const { flags } = useFlags();

  const containerRef = useRef<HTMLDivElement>(null);
  const searchRef = useRef<HTMLInputElement>(null);

  const tickets = ticketsQuery.data;
  const [searchOpen, setSearchOpen] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openMigrateModal, setOpenMigrateModal] = useState(false);
  const [openBulkReplyModal, setOpenBulkReplyModal] = useState(false);
  const [ticketToDelete, setTicketToDelete] = useState<ITicketListing>();
  const [openDeleteTicketModal, setOpenDeleteTicketModal] = useState<boolean>(
    false
  );

  const [textQuery, setTextQuery] = useState<string>();
  const { debouncedValue: debouncedTextQuery } = useDebounce(textQuery, 300);

  const deleteEdgeTicketClient = useMutation(deleteEdgeTicket, {
    onSuccess: (edge) => {
      onUpdateEdge(edge);
      onChange();
      deleteEdgeTicketClient.reset();
    }
  });

  const isLoading = ticketsQuery.isLoading || deleteEdgeTicketClient.isLoading;

  const handleDelete = (ticket: ITicketListing): void => {
    dataLayerPush({ event: GTM_EVENTS.GTM_DELETE_EDGE_EXAMPLE });
    deleteEdgeTicketClient.mutate({ edgeId: edge.id, ticketId: ticket.id });
    setOpenDeleteTicketModal(false);
  };

  const handleDeleteConfirmation = (ticket: ITicketListing): void => {
    setTicketToDelete(ticket);
    setOpenDeleteTicketModal(true);
  };

  const exampleExportUrl = `${getBackendApiUrl()}/edges/${edge.id}/csv-export/`;

  useOnMouseDownOutside(containerRef, () => {
    setSearchOpen(false);
    setTextQuery(undefined);
  });

  // Reset page to 1 when query changes.
  useEffect(() => {
    setTicketsArgs((old) => ({
      ...old,
      textQuery: debouncedTextQuery,
      page: 1
    }));
  }, [debouncedTextQuery, setTicketsArgs]);

  // Focus the search when toggling it open.
  useEffect(() => {
    if (searchOpen) {
      searchRef.current?.focus();
    }
  }, [searchOpen]);

  return (
    <div ref={containerRef} className="tickets-widget">
      <div className="tickets-widget-header">
        <div className="left">
          <EdgeSectionLabel>
            Tickets
            <Tooltip content={TooltipMessage.TICKET_COUNT}>
              <span className="ml-2 ticket-count">{tickets?.total || 0}</span>
            </Tooltip>
          </EdgeSectionLabel>
          {flags.edgeBulkReply && (
            <Tooltip content={TooltipMessage.BULK_REPLY}>
              <Button
                disabled={edge.archived}
                color="primary"
                onClick={() => setOpenBulkReplyModal(true)}
              >
                Reply
              </Button>
            </Tooltip>
          )}
        </div>
        <div
          className={classNames("right", {
            searching: !!searchOpen
          })}
        >
          <div
            className="ticket-search-container"
            onClick={() => setSearchOpen(true)}
          >
            <TextInput
              ref={searchRef}
              name={`ticket-example-search-${new Date().valueOf().toString()}`}
              className="tickets-widget-search"
              placeholder={
                searchOpen
                  ? "Match text in this edge’s examples by text or ID"
                  : undefined
              }
              indicators={<Icons.Search />}
              value={textQuery || ""}
              onChange={(e) => setTextQuery(e.currentTarget.value)}
              onClear={() => {
                setTextQuery(undefined);
              }}
              onFocus={() => setSearchOpen(true)}
            />
          </div>
          <div className="buttons">
            <Tooltip content={TooltipMessage.EXPORT_TICKETS}>
              <a
                className="text-decoration-none"
                href={exampleExportUrl}
                data-testid="ticket-export"
              >
                <Button
                  color="secondary"
                  size="small"
                  onClick={() =>
                    dataLayerPush({
                      event: GTM_EVENTS.GTM_EXPORT_EDGE_EXAMPLE
                    })
                  }
                >
                  <Icons.Download />
                  <span>Export</span>
                </Button>
              </a>
            </Tooltip>
            <Tooltip content={TooltipMessage.MIGRATE_TICKETS}>
              <Button
                color="secondary"
                className="ticket-migrate"
                onClick={() => setOpenMigrateModal(true)}
                size="small"
                disabled={!tickets?.total || edge.archived}
              >
                <Icons.ShareSquare />
                <span>Migrate</span>
              </Button>
            </Tooltip>
            <Tooltip content={TooltipMessage.ADD_TICKETS}>
              <Button
                color="secondary"
                className="ticket-add"
                data-testid="ticket-add"
                onClick={() => setOpenAddModal(true)}
                size="small"
                disabled={edge.archived}
              >
                <Icons.Plus />
                <span>Add</span>
              </Button>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="tickets mt-2">
        {!!tickets && <PaginationAnalytics data={tickets} />}
        <WithLoading isLoading={isLoading} type="overlay">
          {!!tickets?.items.length ? (
            <EdgeTicketsList
              edge={edge}
              tickets={tickets?.items || []}
              onDelete={handleDeleteConfirmation}
              onUpdateTicket={onChange}
            />
          ) : (
            <>
              {!!textQuery ? (
                <NoData message={NoDataMessage.SEARCH_LINKED_TICKETS} />
              ) : (
                <NoData message={NoDataMessage.LINKED_TICKETS} />
              )}
            </>
          )}
        </WithLoading>
        {tickets && (
          <PaginationBar
            page={tickets.page}
            totalPages={tickets.totalPages}
            onChange={(updates) =>
              setTicketsArgs((old) => ({ ...old, ...updates }))
            }
          />
        )}
      </div>
      <EdgeBulkReplyModal
        edge={edge}
        isOpen={openBulkReplyModal && flags.edgeBulkReply}
        onClose={() => setOpenBulkReplyModal(false)}
      />
      <EdgeTicketsAddModal
        edge={edge}
        onUpdateEdge={(edge) => {
          onUpdateEdge(edge);
          onChange();
        }}
        isOpen={openAddModal}
        onClose={() => setOpenAddModal(false)}
      />
      <EdgeTicketsMigrateModal
        edge={edge}
        onClose={() => setOpenMigrateModal(false)}
        isOpen={openMigrateModal}
      />
      {!!ticketToDelete && (
        <ConfirmationModal
          title={`Remove Ticket #${ticketToDelete.id} from this Edge?`}
          isOpen={openDeleteTicketModal}
          onClose={() => setOpenDeleteTicketModal(false)}
          status={convertQueryStateToClientStatus(deleteEdgeTicketClient)}
          loadingTitle="Removing ticket from Edge..."
        >
          <p>{`Are you sure you want to remove Ticket #${ticketToDelete.id} from this Edge?`}</p>
          <div className="modal-submit-group">
            <Button
              color="secondary"
              onClick={() => setOpenDeleteTicketModal(false)}
            >
              Cancel
            </Button>
            <Button
              data-testid="confirm-delete"
              color="danger"
              onClick={() => {
                handleDelete(ticketToDelete);
              }}
            >
              Remove
            </Button>
          </div>
        </ConfirmationModal>
      )}
      <MutationErrorToast
        error={deleteEdgeTicketClient.error}
        resetError={deleteEdgeTicketClient.reset}
      />
    </div>
  );
}

export { EdgeTicketsWidget };
