import { IEdgeInfo } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { IUpdateEdgeRequest } from "../../types/clients";
import { request } from "../../utils/request";
import { patch } from "../utils/buildRequest";

async function updateEdge({
  edgeId,
  patchContent
}: {
  edgeId: number;
  patchContent: IUpdateEdgeRequest;
}): Promise<IEdgeInfo> {
  const options = patch(patchContent);

  return request(`${getBackendApiUrl()}/edges/${edgeId}/`, options);
}

export { updateEdge };
