import { Placement } from "@popperjs/core";
import isEqual from "lodash/isEqual";
import React, { forwardRef, HTMLAttributes, useEffect } from "react";
import { usePrevious } from "../../hooks/usePrevious";

interface Props extends HTMLAttributes<HTMLDivElement> {
  placement: Placement;
  resetKeys?: Array<unknown>;
  scheduleUpdate: () => void;
  style: React.CSSProperties;
}

const PopperContent = forwardRef<HTMLDivElement, Props>(
  (
    { placement, style, scheduleUpdate, className, children, resetKeys },
    ref
  ) => {
    const previousKeys = usePrevious(resetKeys);

    useEffect(() => {
      // Schedule an update with PopperJS when resetKeys change.
      // This handles cases where the anchor element may have resized (see: MultiSelect).
      if (!isEqual(previousKeys, resetKeys)) {
        scheduleUpdate();
      }
    }, [resetKeys, previousKeys, scheduleUpdate]);

    return (
      <div
        ref={ref}
        data-placement={placement}
        className={className}
        style={style}
      >
        {children}
      </div>
    );
  }
);

PopperContent.displayName = "PopperContent";

export { PopperContent };
