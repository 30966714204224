import { IEdgeInfo } from "@aptedge/lib-ui/src/types/entities";
import { GTM_EVENTS, dataLayerPush } from "@aptedge/lib-ui/src/utils/event";
import React from "react";
import { useMutation } from "react-query";
import { updateEdge } from "../../clients/Edges/updateEdge";
import { EdgeSectionLabel } from "./EdgeSectionLabel";
import { TeamSelect } from "./TeamSelect";

interface Props {
  edge: IEdgeInfo;
  onUpdateEdge: (updatedEdge: IEdgeInfo) => void;
}

function EdgeTeam({ edge, onUpdateEdge }: Props): React.ReactElement {
  const updateTeam = useMutation(updateEdge, {
    onSuccess: (updatedEdge) => {
      dataLayerPush({ event: GTM_EVENTS.GTM_UPDATE_EDGE_TEAM });
      onUpdateEdge(updatedEdge);
    }
  });

  return (
    <React.Fragment>
      <EdgeSectionLabel>Team</EdgeSectionLabel>
      <TeamSelect
        value={edge.team?.id}
        isDisabled={edge.archived}
        isLoading={updateTeam.isLoading}
        onChange={(t) =>
          updateTeam.mutate({ edgeId: edge.id, patchContent: { teamId: t } })
        }
      />
    </React.Fragment>
  );
}

export { EdgeTeam };
