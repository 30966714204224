import classNames from "classnames";
import { DateTime } from "luxon";
import React from "react";
import { DateRange, DateType } from "../../types/date";
import { relativeRangeToInterval } from "../../utils/date";
import { Icons } from "../Icon/Icons";
import { Shortcut } from "./shortcuts";
import "./DateRangeShortcuts.scss";

interface Props {
  shortcuts: Shortcut[];
  value?: DateRange;
  min?: DateTime;
  max?: DateTime;
  calendarOpen?: boolean;
  onSelect: (dateRange: DateRange) => void;
}

const DateRangeShortcuts: React.FC<Props> = ({
  shortcuts,
  calendarOpen = false,
  min,
  max,
  value,
  onSelect
}) => {
  const isDisabled = (shortcut: Shortcut): boolean => {
    if (!min && !max) {
      return false;
    }

    const interval = relativeRangeToInterval(shortcut.value);

    // If "All time", then disable if there's a min.
    if (interval === undefined) {
      return min !== undefined;
    }

    return (
      (!!min && interval.start < min.startOf("day")) ||
      (!!max && interval.contains(max))
    );
  };

  const isSelected = (shortcut: Shortcut): boolean => {
    if (value?.type === DateType.RELATIVE) {
      return value.value === shortcut.value;
    }
    return false;
  };

  return (
    <div className="date-range-shortcuts">
      {shortcuts.map((s, idx) => (
        <div
          key={s.label + idx}
          className={classNames("shortcut", {
            selected: isSelected(s) && !calendarOpen,
            disabled: isDisabled(s)
          })}
          onClick={() => {
            if (!isDisabled(s))
              onSelect({ type: DateType.RELATIVE, value: s.value });
          }}
        >
          {s.label}
        </div>
      ))}
      <div
        className={classNames("shortcut", {
          selected: value?.type === DateType.ABSOLUTE || calendarOpen
        })}
        onClick={() => onSelect({ type: DateType.ABSOLUTE })}
      >
        <Icons.CalendarAlt className="mr-1" />
        Custom date range
        <Icons.ChevronRight size="sm" className="ml-1" />
      </div>
    </div>
  );
};

export { DateRangeShortcuts };
