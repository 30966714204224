import { Select } from "@aptedge/lib-ui/src/components/Select/Select";
import React from "react";
import { useSpaces } from "../../hooks/clients/useSpaces";

interface Props {
  value?: string;
  placeholder?: string;
  clearRowLabel?: string;
  onChange: (spaceName: string | null) => void;
  className?: string;
  isError?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
}

function SpaceSelect(props: Props): React.ReactElement {
  const {
    value,
    placeholder = "Unassigned",
    clearRowLabel,
    onChange,
    className,
    isDisabled,
    isLoading
  } = props;

  const { data, isLoading: spacesAreLoading } = useSpaces();
  const spaces = data || [];
  const selected = spaces.find((s) => !!value && value === s.name);

  return (
    <Select
      data={spaces}
      value={selected}
      placeholder={placeholder}
      className={className}
      getOptionLabel={(s) => s.name}
      getDisplayValue={(s) => s.name}
      clearRowLabel={clearRowLabel}
      isDisabled={isDisabled}
      isLoading={isLoading || spacesAreLoading}
      isClearable
      onChange={(space) => onChange(space?.name || null)}
    />
  );
}

export { SpaceSelect };
