import { ITicketListing } from "@aptedge/lib-ui/src/types/entities";
import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { WebCacheKey } from "../../clients/cache";
import { fetchTicketsForEdge } from "../../clients/Edges/fetchTicketsForEdge";
import { IPaginatedResponse } from "../../types/clients";

type QueryArgs = Parameters<typeof fetchTicketsForEdge>[0];

function useEdgeTicketsQuery(
  queryArgs: QueryArgs,
  options?: UseQueryOptions<IPaginatedResponse<ITicketListing[]>>
): UseQueryResult<IPaginatedResponse<ITicketListing[]>> {
  return useQuery(
    [WebCacheKey.EDGE_TICKETS, queryArgs],
    () => fetchTicketsForEdge(queryArgs),
    options
  );
}

export { useEdgeTicketsQuery };
