import * as React from "react";
import styles from "./MyProductsActions.module.scss";

interface MyProductsActionsProps {
  handleCancel: () => void;
  handleSave: () => void;
}

const MyProductsActions: React.FC<MyProductsActionsProps> = ({
  handleCancel,
  handleSave
}) => {
  return (
    <div className={styles.container}>
      <button className={styles.saveButton} onClick={handleSave}>
        Save
      </button>
      <button className={styles.cancelButton} onClick={handleCancel}>
        Cancel
      </button>
    </div>
  );
};

export { MyProductsActions };
