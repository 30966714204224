import { IUserListing } from "@aptedge/lib-ui/src/types/entities";
import { useUsersById } from "./useUsers";

function useUser(
  authorId: number | null
): { user: IUserListing | null; isLoading: boolean } {
  const { data: usersById, isLoading } = useUsersById();

  const user =
    authorId !== null && !!usersById ? usersById.get(authorId) || null : null;

  return { user, isLoading };
}

export { useUser };
