import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { IJiraOnPremiseInternalIssueSource } from "@aptedge/lib-ui/src/types/entities";
import { useFormik } from "formik";
import React from "react";
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label
} from "reactstrap";
import * as Yup from "yup";

export interface JiraOnPremiseFormData {
  accessToken: string;
  configuredBaseUrl: string;
}

interface Props {
  source: IJiraOnPremiseInternalIssueSource | null;
  onSubmit: (formData: JiraOnPremiseFormData) => void;
}

const JiraOnPremiseConfigForm: React.FunctionComponent<Props> = ({
  source,
  onSubmit
}) => {
  const handleSubmit = (data: JiraOnPremiseFormData): void => {
    onSubmit({
      ...data
    });
  };

  const formik = useFormik<JiraOnPremiseFormData>({
    validationSchema: Yup.object({
      accessToken: Yup.string().required(
        "Please enter a personal access token."
      ),
      configuredBaseUrl: Yup.string().required("Please enter a base URL.")
    }),
    initialValues: {
      accessToken: (source && source.accessToken) || "",
      configuredBaseUrl: (source && source.configuredBaseUrl) || ""
    },
    validateOnChange: false,
    onSubmit: handleSubmit
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <FormGroup row>
        <Label sm={3} for="clientId">
          Jira Base URL
        </Label>
        <Col sm={9}>
          <Input
            data-testid="jira-on-prem-configuredBaseUrl"
            type="text"
            name="configuredBaseUrl"
            placeholder=""
            value={formik.values.configuredBaseUrl}
            onChange={formik.handleChange}
            invalid={!!formik.errors.configuredBaseUrl}
          />
          <FormFeedback>{formik.errors.configuredBaseUrl}</FormFeedback>
          <FormText>Base URL of the Jira Server</FormText>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3} for="clientSecret">
          Personal Access Token
        </Label>
        <Col sm={9}>
          <Input
            data-testid="jira-on-prem-accessToken"
            type="text"
            name="accessToken"
            placeholder=""
            value={formik.values.accessToken}
            onChange={formik.handleChange}
            invalid={!!formik.errors.accessToken}
          />
          <FormFeedback>{formik.errors.accessToken}</FormFeedback>
          <FormText>Personal Access Token generated by Jira Server</FormText>
        </Col>
      </FormGroup>
      <div className="float-right">
        <Button type="submit">Save</Button>
      </div>
    </Form>
  );
};

export { JiraOnPremiseConfigForm };
