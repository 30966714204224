import classNames from "classnames";
import { DateTime } from "luxon";
import { useState } from "react";
import { AeIcons } from "../Icon/AeIcons";
import "./FilterWrapper.scss";
import { Icons } from "../Icon/Icons";
import {
  ITimeDimensionFilter,
  ITimeDimensionFilterId,
  ITimeDimensionFilterDefinition
} from "./types";

interface Props {
  dateRangeMin?: DateTime;
  specs: ITimeDimensionFilterDefinition[];
  value?: ITimeDimensionFilter;
  selectedTimeDimension?: ITimeDimensionFilter;
  dimensions?: ITimeDimensionFilter[];
  error?: boolean;
  onChange: (newValue: ITimeDimensionFilter) => void;
  onRemove?: (selectedTimeDimensionIndex: number) => void;
  children: (args: {
    values?: string[];
    onChange: (newValues: string[]) => void;
  }) => React.ReactNode;
}

function TimeDimensionWrapper(props: Props): React.ReactElement {
  const {
    specs,
    value,
    error,
    onChange,
    onRemove,
    children,
    selectedTimeDimension
  } = props;

  const [
    timeDimensionModalVisibility,
    setTimeDimensionModalVisibility
  ] = useState(false);

  const [
    selectedTimeDimensionFilter,
    setSelectedTimeDimensionFilter
  ] = useState<ITimeDimensionFilterId>(
    selectedTimeDimension?.id ?? value?.id ?? "createdOn"
  );

  const selectedTimeDimensionIndex = specs.findIndex(
    (spec) => spec.id === selectedTimeDimensionFilter
  );

  const handleChange = (
    newValues: string[],
    selectedTimeDimensionFilter: ITimeDimensionFilterId
  ): void => {
    onChange({ id: selectedTimeDimensionFilter, values: newValues });
  };

  const handleRemove = (): void => {
    if (onRemove) onRemove(selectedTimeDimensionIndex);
  };

  const handleTDFilterSection = (filter: ITimeDimensionFilterId): void => {
    setTimeDimensionModalVisibility(false);
    setSelectedTimeDimensionFilter(filter);
    onChange({ id: filter, values: value?.values || ["2W"] });
  };

  const currentFilter = specs.find(
    (spec) => spec.id === selectedTimeDimensionFilter
  );
  if (!currentFilter?.id) return <></>;
  const { label, icon } = currentFilter;

  return (
    <div className="time-dimension">
      <div
        className={classNames("filter-wrapper", { error })}
        data-testid={`filter-wrapper-${label.toLowerCase()}`}
      >
        <div
          className="label"
          onClick={() => setTimeDimensionModalVisibility((prev) => !prev)}
        >
          <span className="icon">{icon}</span>
          <span>{label}</span>
        </div>
        <div className="filter">
          {children({
            values: value?.values,
            onChange: (newValues) =>
              handleChange(newValues, selectedTimeDimensionFilter)
          })}
        </div>
        {onRemove && (
          <div
            className="end-cap"
            data-testid={`filter-wrapper-${label.toLowerCase()}-remove`}
            onClick={handleRemove}
          >
            <Icons.Times />
          </div>
        )}
      </div>
      {timeDimensionModalVisibility && specs.length > 1 && (
        <div className="time-dimension-modal">
          <div
            className="createdOn"
            onClick={() => handleTDFilterSection("createdOn")}
          >
            <AeIcons.CreatedOn /> <span>Created On</span>
          </div>
          <div
            className="updatedOn"
            onClick={() => handleTDFilterSection("updatedOn")}
          >
            <AeIcons.UpdatedOn /> <span>Updated On</span>
          </div>
        </div>
      )}
    </div>
  );
}

export { TimeDimensionWrapper };
