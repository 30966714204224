import { AeIcons } from "@aptedge/lib-ui/src/components/Icon/AeIcons";
import { AptAnswersFilterType } from "@aptedge/lib-ui/src/types/entities";
import * as React from "react";
import styles from "./FilterListAddRow.module.scss";

interface FilterListAddRowProps {
  handleClick: () => void;
  filterType: AptAnswersFilterType;
}

const FilterListAddRow: React.FC<FilterListAddRowProps> = ({
  filterType,
  handleClick
}) => {
  const message = `New ${
    filterType === AptAnswersFilterType.QUICK_FILTER ? "quick" : "product"
  } filter`;
  return (
    <div className={styles.container} onClick={handleClick}>
      <span className={styles.filterIcon}>
        <AeIcons.QuickFilters />
      </span>
      <span className={styles.message}>{message}</span>
      <span className={styles.plusIcon}>
        <AeIcons.Plus />
      </span>
    </div>
  );
};

export { FilterListAddRow };
