import { Placement } from "@popperjs/core";
import React, {
  MouseEventHandler,
  ReactNode,
  useEffect,
  useRef,
  useState
} from "react";
import { useOnMouseDownOutside } from "../../hooks/useOnMouseDownOutside";
import { Delay } from "../../styles/animations";
import { Popover } from "../Popover/Popover";
import { DEFAULT_PREVENT_OVERFLOW_MODIFIER } from "../Popper/popper";
import "./Tooltip.scss";

interface TooltipProps {
  placement?: Placement;
  delay?: Delay;
  disabled?: boolean;
  offset?: { x: 0; y: 8 } | { x: 0; y: 12 };
  triggerType?: "none" | "hover" | "click";
  content: ReactNode;
}

const Tooltip: React.FC<TooltipProps> = ({
  children,
  placement = "top",
  delay = Delay.DEFAULT,
  disabled,
  offset = { x: 0, y: 8 },
  triggerType = "hover",
  content
}) => {
  const popupRef = useRef<HTMLSpanElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const closeTimeout = useRef<number>();

  const [entered, setEntered] = useState<boolean>(false);
  const [active, setActive] = useState<boolean>(false);

  const onTriggerClick: MouseEventHandler<HTMLDivElement> = () => {
    if (triggerType === "click") {
      setActive(!active);
    }

    if (triggerType === "hover") {
      setActive(false);
    }
  };

  const onTriggerMouseEnter: MouseEventHandler<HTMLDivElement> = () => {
    if (triggerType === "hover") {
      setEntered(true);
    }
  };

  const onTriggerMouseLeave: MouseEventHandler<HTMLDivElement> = () => {
    if (triggerType === "hover") {
      setEntered(false);
      setActive(false);
    }
  };

  useOnMouseDownOutside(tooltipRef, (e) => {
    if (!popupRef.current?.contains(e.target as Node)) {
      setActive(false);
    }
  });

  useEffect(() => {
    if (entered) {
      closeTimeout.current = window.setTimeout(() => {
        if (entered) {
          setActive(true);
        }
      }, delay);
    }

    return () => clearTimeout(closeTimeout.current);
  }, [entered, delay]);

  if (disabled) {
    return <span>{children}</span>;
  }

  return (
    <span
      className="ae-tooltip"
      onMouseEnter={onTriggerMouseEnter}
      onMouseLeave={onTriggerMouseLeave}
      onClick={onTriggerClick}
      ref={tooltipRef}
    >
      <Popover.Container>
        <Popover.Toggle>
          <span className="tooltip-trigger">{children}</span>
        </Popover.Toggle>
        <Popover.Content
          isOpen={active}
          placement={placement}
          modifiers={[
            { name: "offset", options: { offset: [offset.x, offset.y] } },
            DEFAULT_PREVENT_OVERFLOW_MODIFIER
          ]}
        >
          <span className="tooltip-popper" ref={popupRef}>
            <div
              className="tooltip-popper-content"
              onClick={(e) => e.stopPropagation()}
            >
              {content}
            </div>
          </span>
        </Popover.Content>
      </Popover.Container>
    </span>
  );
};

export { Tooltip };
