import type { Cancelable, DebounceSettings } from "lodash";
import debounce from "lodash/debounce";
import isEqual from "lodash/isEqual";
import React, { useEffect } from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function useDebouncedCallback<T extends (...args: any[]) => any>(
  cb: T,
  wait: number,
  options?: DebounceSettings
): T & Cancelable {
  const waitRef = React.useRef(wait);
  const optionsRef = React.useRef(options);

  return React.useCallback(debounce(cb, waitRef.current, optionsRef.current), [
    cb
  ]);
}

function useDebounce<T>(
  value: T,
  wait: number,
  options?: DebounceSettings
): { debouncedValue: T } {
  const [internalState, setInternalState] = React.useState<T>(value);

  // Debounce the internal setState so state is only updated
  // after the "wait".
  const setStateDebounced = useDebouncedCallback(
    setInternalState,
    wait,
    options
  );

  useEffect(() => {
    // If no change, do NOT set state and do NOT return a cleanup, as it is not needed.
    // Do not return a cleanup.
    if (isEqual(value, internalState)) return;

    setStateDebounced(value);
    // Clean up - cancel debounce on unmount, or change in value.
    return () => {
      setStateDebounced.cancel();
    };
  }, [value, setStateDebounced, internalState]);

  // Return the debounced state. This will be "stale" by design.
  return {
    debouncedValue: internalState
  };
}

export { useDebounce };
