import { FC, ReactElement } from "react";

interface Props {
  isLoading: boolean;
  fallback: ReactElement;
}
const LameSuspense: FC<Props> = ({ isLoading, fallback, children }) => {
  if (isLoading) {
    return fallback;
  }

  return <>{children}</>;
};

export { LameSuspense };
