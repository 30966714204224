import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import classNames from "classnames";
import React from "react";
import { ISortArg } from "../../types/clients";
import { Column } from "./useTable";
import { getCellProps } from "./utils";
import "./Th.scss";

interface Props<T> {
  col: Column<T>;
  sortBy?: ISortArg<string>[] | null;
  onSortChange?: (newSort: ISortArg<string>[] | null) => void;
}

function Th<T>(props: Props<T>): React.ReactElement {
  const { col, sortBy, onSortChange } = props;
  const sortKey = col.sortKey || col.id;

  const isSortedDesc = !!sortBy?.find((s) => s.id === sortKey && !!s.desc);
  const isSortedAsc = !!sortBy?.find((s) => s.id === sortKey && !s.desc);

  return (
    <th {...getCellProps({ ...col, align: "middle" })}>
      <div className={classNames("th", { sortable: !!col.isSortable })}>
        <div className="th-label">{col.label}</div>
        <div className="th-sort">
          <Icons.CaretUp
            className={classNames({
              active: isSortedAsc,
              disabled: !col.isSortable
            })}
            onClick={() => onSortChange?.([{ id: sortKey, desc: false }])}
          />
          <Icons.CaretDown
            className={classNames({
              active: isSortedDesc,
              disabled: !col.isSortable
            })}
            onClick={() => onSortChange?.([{ id: sortKey, desc: true }])}
          />
        </div>
      </div>
    </th>
  );
}

export { Th };
