import { Chip } from "@aptedge/lib-ui/src/components/Chip/Chip";
import { IEdgeAuditLogMigration } from "@aptedge/lib-ui/src/types/entities";
import pluralize from "pluralize";
import React from "react";
import { Link } from "react-router-dom";
import { toEdgePath } from "../../routes/toPath";
import { ArrowTransition } from "./ArrowTransition";
import { SummarySentence } from "./SummarySentence";
import { WithAuditLog } from "./WithAuditLog";
import type { AuditDetailsFC, AuditSummaryFC } from "./WithAuditLog";

const Details: AuditDetailsFC<IEdgeAuditLogMigration> = (props) => {
  const { from, to, ticketIds } = props.log.data;

  const fromDisplay = (
    <div>
      <p>
        <span>Migrated {pluralize("example", ticketIds.length)} </span>
        {ticketIds.map((t) => (
          <Chip
            key={`audit-log-label-${t}`}
            className="mr-1"
            label={t.toLocaleString()}
          />
        ))}
        {!!from && (
          <>
            <span>from </span>
            <Link to={toEdgePath(from.id)}>{from.displayName}</Link>
          </>
        )}
        {!!to && (
          <>
            <span>into </span>
            <Link to={toEdgePath(to.id)}>{to.displayName}</Link>
          </>
        )}
      </p>
    </div>
  );

  return (
    <div className="audit-log-migrating">
      <ArrowTransition from={fromDisplay} to={null} />
    </div>
  );
};

const Summary: AuditSummaryFC<IEdgeAuditLogMigration> = (props) => {
  const { log, author } = props;
  const { OBJECTS } = SummarySentence;

  const migratedInto = !!log.data.to;
  const ticketIds = log.data.ticketIds;
  const migrateVerb = migratedInto
    ? `migrated ${pluralize("example", ticketIds.length)} into an`
    : `migrated ${pluralize("example", ticketIds.length)} from`;

  return (
    <SummarySentence
      subject={author}
      verb={migrateVerb}
      object={OBJECTS.EDGE}
      displayText={log?.displayText ?? ""}
    />
  );
};

Details.displayName = "AuditMigrationDetails";
const AuditLogMigration = WithAuditLog(Details, Summary);

export { AuditLogMigration };
