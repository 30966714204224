import { KeyboardEvent } from "react";
import uuid from "uuid";

function Truthy<T>(
  value: T
): value is Exclude<T, false | null | undefined | "" | 0> {
  return Boolean(value);
}
export function isTruthy<T>(value: T): boolean {
  if (typeof value === "function") return Boolean(value());
  if (value instanceof Array) return Boolean(value.length);
  if (value instanceof Object) return Boolean(Object.keys(value).length);
  return Boolean(value);
}

const removeCharFromStringAndJoin = (
  str: string,
  charToRemove: string
): string => {
  const strArray = str?.split(charToRemove);
  return strArray?.join(" ");
};

const getTrimmedString = (str: string): string =>
  str && typeof str === "string" ? str?.trim() : "";

export { Truthy, removeCharFromStringAndJoin, getTrimmedString };

export const onEnterKeyPressed = <T>(
  event: KeyboardEvent<T>,
  callbackFn: () => void
): void => {
  if (event.key === "Enter") callbackFn();
};

export const generateUniqueId = (): string => uuid.v4().toString();
