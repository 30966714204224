import { CACHE_KEY } from "@aptedge/lib-ui/src/data/cacheKeys";
import { ConfigResponse } from "@aptedge/lib-ui/src/types/selfService";
import { SyntheticEvent, useState } from "react";
import { useQuery } from "react-query";
import {
  deleteCssConfig,
  fetchCssConfig,
  createCssConfig
} from "../../../../clients/CssConfig";
import {
  CssConfig,
  processConfig,
  SELF_SERVICE_PROACTIVE_ANSWERS_ENABLED,
  SELF_SERVICE_PROACTIVE_ANSWERS_PAGE_GLOBS,
  SELF_SERVICE_PROACTIVE_ANSWERS_SEARCH_INPUT_SELECTOR,
  SELF_SERVICE_PROACTIVE_ANSWERS_USER_AUTH_REQUIRED,
  SELF_SERVICE_REMAINING_SEARCHES
} from "./configFieldKeys";
import { validateInputByName } from ".";

type UseConfigReturnType = {
  config: CssConfig;
  originalConfig?: CssConfig;
  isLoading: boolean;
  handleConfigChange: (event: SyntheticEvent<HTMLInputElement>) => void;
  saveCSSConfig: () => void;
  isSaved: boolean;
  handleAddNewPageGlob: (key: string, value: string) => void;
  removePageGlob: (key: string, value: string) => void;
  isError: string[];
};

const BOOLEAN_INPUTS = [
  SELF_SERVICE_PROACTIVE_ANSWERS_ENABLED,
  SELF_SERVICE_PROACTIVE_ANSWERS_USER_AUTH_REQUIRED
];
const READ_ONLY_CONFIG_INPUTS = [
  SELF_SERVICE_PROACTIVE_ANSWERS_PAGE_GLOBS,
  SELF_SERVICE_REMAINING_SEARCHES
];

export const useCSSConfig = (): UseConfigReturnType => {
  const [config, setConfig] = useState<CssConfig>({} as CssConfig);
  const [isSaved, setIsSaved] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<string[]>([]);

  const {
    data: originalConfig,
    isLoading: isLoadingConfig,
    refetch
  } = useQuery([CACHE_KEY.CSS_CONFIG], fetchCssConfig, {
    staleTime: Infinity,
    onSuccess: (config: ConfigResponse) => {
      const updatedConfig = processConfig(config);
      setConfig({
        ...updatedConfig
      });
    }
  });

  const getDerivedValueByKey = (key: string, value: string): string => {
    if (key === SELF_SERVICE_PROACTIVE_ANSWERS_SEARCH_INPUT_SELECTOR)
      return value.split(",").map(convertSelector).join(", ");
    return value;
  };
  const saveCSSConfig = async (): Promise<void> => {
    setIsLoading(true);
    try {
      const promises = Object.entries(config).map(async ([key, value]) => {
        if (READ_ONLY_CONFIG_INPUTS.includes(key)) return;
        else await createCssConfig(key, getDerivedValueByKey(key, value));
      });
      await Promise.all(promises);
      await refetch();
    } catch (error) {
      console.error("Error saving CSS config:", error);
    } finally {
      setIsLoading(false);
      setIsSaved(true);
    }
  };

  const handleAddNewPageGlob = async (
    key: string,
    value: string
  ): Promise<void> => {
    setIsLoading(true);
    try {
      await createCssConfig(key, value.replace("https://", ""));
      await refetch();
    } catch (error) {
      console.error("Error adding new page glob:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const removePageGlob = async (key: string, value: string): Promise<void> => {
    setIsLoading(true);
    let timer;
    try {
      await deleteCssConfig(key, value);
      setIsSaved(true);
      refetch();
    } catch (error) {
      console.log("Error while removing page", (error as Error).message);
    } finally {
      setIsLoading(false);
      clearTimeout(timer);
    }
  };

  const handleConfigChange = (
    event: SyntheticEvent<HTMLInputElement>
  ): void => {
    const { name, value, checked } = event.target as HTMLInputElement;
    const isSwitchElement = value === "on" && BOOLEAN_INPUTS.includes(name);

    const updatedConfig = {
      ...config,

      [name]: isSwitchElement ? checked : value
    } as CssConfig;

    setConfig(updatedConfig);
    const isValidationFailed = validateInputByName(name, value);
    if (isValidationFailed) setIsError((prev) => [...prev, name]);
    else {
      const updatedError = [...isError].filter((error) => error !== name);
      setIsError(updatedError);
    }

    setIsSaved(false);
  };
  const convertSelector = (selector: string): string => {
    selector = selector.trim();

    if (selector.startsWith("#")) {
      return `[id="${selector.slice(1)}"]`;
    } else if (selector.startsWith(".")) {
      const className = selector.replace(/\./g, " ").trim();
      return `[class="${className}"]`;
    }

    return selector;
  };

  const processedConfig = originalConfig && processConfig(originalConfig);

  return {
    config,
    originalConfig: processedConfig,
    handleConfigChange,
    saveCSSConfig,
    isLoading: isLoading || isLoadingConfig,
    isSaved,
    handleAddNewPageGlob,
    removePageGlob,
    isError
  };
};
