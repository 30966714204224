/*
 * Hardcoding for now - will parameterize later
 */
const backendHost = "localhost";
const backendPort = "8080";

function isLocal(): boolean {
  return window.location.hostname === "localhost";
}

/**
 * Parking this in one place for later parameterization
 */

function getBackendUrl(): string {
  let port = "";
  if (isLocal()) {
    port = `:8080`;
  }
  return `${window.location.protocol}//${window.location.hostname}${port}`;
}

function getBackendApiUrl(): string {
  if (isLocal()) {
    return `http://${backendHost}:${backendPort}/api`;
  } else {
    return `https://${window.location.hostname}/api`;
  }
}

function resolveApi(path: string): string {
  return `${getBackendApiUrl()}/${path.replace(/^\//g, "")}`;
}

function getCubeJsUrl(): string {
  if (isLocal()) {
    return "http://localhost:4000/cubejs-api/v1";
  } else {
    return `https://${window.location.hostname}/cubejs-api/v1`;
  }
}

function getSubdomain(): string {
  if (isLocal()) {
    return "local";
  }
  return window.location.hostname.split(".")[0];
}

function getAccount(): string {
  if (isLocal()) {
    return "AptEdge";
  }
  return getSubdomain();
}

type TagManagerProps = {
  gtmId: string;
};

function getTagManagerConfig(): TagManagerProps {
  if (isLocal()) {
    return {
      gtmId: "GTM-P4N4TSG"
    };
  } else {
    return {
      gtmId: "GTM-KNQS24L"
    };
  }
}

export {
  isLocal,
  getBackendUrl,
  getBackendApiUrl,
  getCubeJsUrl,
  getAccount,
  resolveApi,
  getTagManagerConfig
};
