import { RequestMethod } from "../../types/clients";
import { ContentType } from "../../types/http";

type AvailableMethods =
  | RequestMethod.PATCH
  | RequestMethod.POST
  | RequestMethod.PUT;

function buildRequest(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  requestBody: Record<string, any>,
  method: AvailableMethods,
  contentType: ContentType = ContentType.APPLICATION_JSON
): RequestInit {
  return {
    headers: {
      "content-type": contentType
    },
    method,
    body: JSON.stringify(requestBody)
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function post<T extends Record<string, any>>(requestBody: T): RequestInit {
  return buildRequest(requestBody, RequestMethod.POST);
}

function postForm(requestBody: FormData): RequestInit {
  return { method: RequestMethod.POST, body: requestBody };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function patch<T extends Record<string, any>>(requestBody: T): RequestInit {
  return buildRequest(requestBody, RequestMethod.PATCH);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function put<T extends Record<string, any>>(requestBody: T): RequestInit {
  return buildRequest(requestBody, RequestMethod.PUT);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function deleteInit<T extends Record<string, any>>(
  contentType: ContentType = ContentType.APPLICATION_JSON,
  requestBody?: T
): RequestInit {
  return {
    headers: {
      "content-type": contentType
    },
    method: RequestMethod.DELETE,
    body: JSON.stringify(requestBody)
  };
}

export { post, put, postForm, patch, deleteInit };
