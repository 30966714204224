type PropagatingEvent = {
  stopPropagation: Event["stopPropagation"];
};

function stopPropagation(cb?: () => void): (e: PropagatingEvent) => void {
  return (e) => {
    e.stopPropagation();
    cb?.();
  };
}

type DefaultingEvent = {
  preventDefault: Event["preventDefault"];
};

function preventDefault(cb: () => void): (e: DefaultingEvent) => void {
  return (e) => {
    e.preventDefault();
    cb();
  };
}

export { stopPropagation, preventDefault };
