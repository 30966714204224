import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import { SEARCH_RESULT_TYPE } from "@aptedge/lib-ui/src/types/entities";
import React from "react";
import { useParams } from "react-router-dom";
import { Container } from "reactstrap";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { PageContent } from "../../components/PageContent/PageContent";
import { StyledLink } from "../../components/StyledLink/StyledLink";
import { TicketContent } from "../../components/TicketContent/TicketContent";
import WithLoading from "../../components/WithLoading/WithLoading";
import {
  useTicketMutation,
  useTicketQuery
} from "../../hooks/clients/useTicket";
import { Routes } from "../../routes/routes";
import NotFoundPage from "../NotFoundPage/NotFoundPage";

type TParams = { ticketId: string };

const TicketPage: React.FC = () => {
  const params = useParams<TParams>();

  const ticketId = +params.ticketId;
  const ticketQuery = useTicketQuery(ticketId, SEARCH_RESULT_TYPE.TICKET);
  const ticketMutation = useTicketMutation();

  const { data: ticket, isLoading } = ticketQuery;

  if ((!ticket && !isLoading) || isNaN(ticketId)) {
    return <NotFoundPage />;
  }
  return (
    <PageContent>
      <Container>
        {ticket && (
          <>
            <div className="mb-4">
              <Breadcrumbs>
                <StyledLink to={Routes.TICKETS}>Tickets</StyledLink>
                <span>{ticket.displayId}</span>
              </Breadcrumbs>
            </div>
            <WithLoading
              isLoading={ticketMutation.isLoading || isLoading}
              fallback={<Spinner />}
              type="overlay"
            >
              <TicketContent
                ticket={ticket}
                onTicketUpdate={(updates) =>
                  ticketMutation.mutate({
                    ticketId: ticket.id,
                    patchContent: updates
                  })
                }
              />
            </WithLoading>
          </>
        )}
      </Container>
    </PageContent>
  );
};

export { TicketPage };
