import { ISavedQuery } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";

function fetchSavedQueryById(id: number): Promise<ISavedQuery> {
  const url = `${getBackendApiUrl()}/saved-filters/${id}/`;

  return request(url);
}

export { fetchSavedQueryById };
