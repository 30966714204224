import { IDynamicSource } from "@aptedge/lib-ui/src/types/entities";
import { getBackendUrl } from "../../../config";
import { request } from "../../../utils/request";

function fetchArticleSource(): Promise<IDynamicSource[]> {
  const url = `${getBackendUrl()}/website/tap`;

  return request(url);
}

export { fetchArticleSource };
