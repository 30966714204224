import { ISharepointSource } from "@aptedge/lib-ui/src/types/entities";
import { getBackendUrl } from "../../config";
import { request } from "../../utils/request";

function fetchSharepointSources(): Promise<ISharepointSource[]> {
  const url = `${getBackendUrl()}/api/integrations/sharepoint`;
  return request(url);
}

export { fetchSharepointSources };
