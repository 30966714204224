import { IEdgeInfo } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { ContentType } from "../../types/http";
import { request } from "../../utils/request";
import { deleteInit } from "../utils/buildRequest";

async function deleteEdgeTicket({
  edgeId,
  ticketId
}: {
  edgeId: number;
  ticketId: number;
}): Promise<IEdgeInfo> {
  const url = `${getBackendApiUrl()}/edges/${edgeId}/examples/?tickets-by-id=${ticketId}`;

  return request(url, deleteInit(ContentType.TEXT_PLAIN));
}

export { deleteEdgeTicket };
