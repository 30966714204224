import { ITicketListing } from "@aptedge/lib-ui/src/types/entities";
import { queryParamsToFlaskDateRange } from "@aptedge/lib-ui/src/utils/date";
import {
  IQueryArgs,
  toLZCompressedString
} from "@aptedge/lib-ui/src/utils/query";
import { getBackendApiUrl } from "../../config";
import { IPaginatedRequest, IPaginatedResponse } from "../../types/clients";
import { request } from "../../utils/request";

function fetchTickets(
  args: IPaginatedRequest<IQueryArgs>
): Promise<IPaginatedResponse<ITicketListing[]>> {
  const { timeDimensions: td, filters, ...rest } = args;

  const timeDimensions = td?.map((t) => ({
    ...t,
    values: queryParamsToFlaskDateRange(t.values)
  }));

  const query = toLZCompressedString({
    filters,
    timeDimensions,
    ...rest
  });

  const url = `${getBackendApiUrl()}/tickets/query/?q=${query}`;

  return request(url);
}

export { fetchTickets };
