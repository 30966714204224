import React from "react";
import "./TextFilterAutoLinkInfo.scss";

const HELP_ARTICLE_LINK =
  "https://support.aptedge.io/hc/en-us/articles/6693836493588";

function TextFilterAutoLinkInfo(): React.ReactElement {
  return (
    <div>
      <span>
        Match tickets with all of these words. For more info and examples, see
        this
      </span>{" "}
      <a
        href={HELP_ARTICLE_LINK}
        target="_blank"
        rel="noreferrer"
        className="help-text"
      >
        <strong>help article</strong>
      </a>
      .
    </div>
  );
}

export { TextFilterAutoLinkInfo };
