import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import "./Table.scss";

type Props = React.DetailedHTMLProps<
  React.TableHTMLAttributes<HTMLTableElement>,
  HTMLTableElement
>;

function Table(props: PropsWithChildren<Props>): React.ReactElement {
  const { children, className, ...htmlTableProps } = props;

  return (
    <div className="table-responsive">
      <table className={classNames("table", className)} {...htmlTableProps}>
        {children}
      </table>
    </div>
  );
}

export { Table };
