import classNames from "classnames";
import isEqual from "lodash/isEqual";
import { DateTime } from "luxon";
import {
  FormEvent,
  HTMLProps,
  KeyboardEvent,
  MouseEvent,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from "react";
import { ChipProps } from "../components/Chip/Chip";
import { Keys } from "../styles/keys";
import { usePrevious } from "./usePrevious";

const EMPTY_INPUT_WIDTH = 10;

function useAutoComplete<T extends Record<string, unknown>>(
  props: AutoCompleteProps<T>
): AutoCompleteResults<T> {
  const {
    id,
    data,
    selected: initialSelected,
    autoComplete = true,
    getOptionLabel,
    onChange,
    onInputChange
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const listboxRef = useRef<HTMLDivElement>(null);
  // Used to update the input's width so that it wraps to a new line.
  const invisibleRef = useRef<HTMLDivElement>(null);

  const [popupOpen, setPopupOpen] = useState<boolean>(false);
  const [inputWidth, setInputWidth] = useState<number>(EMPTY_INPUT_WIDTH);
  const [searchValue, setSearchValue] = useState<string>("");
  const [highlighted, setHighlighted] = useState<number>(0);
  const [selected, setSelected] = useState<Record<string, unknown>[]>(
    initialSelected || []
  );
  const previousSelected = usePrevious(initialSelected);

  useEffect(() => {
    if (!isEqual(previousSelected, initialSelected)) {
      setSelected(initialSelected || []);
    }
  }, [initialSelected, previousSelected]);

  useLayoutEffect(() => {
    if (invisibleRef.current) {
      const updated = invisibleRef.current.clientWidth || EMPTY_INPUT_WIDTH;
      setInputWidth(updated + 2);
    }
  }, [searchValue]);

  const results: Array<T> = data.filter((d) => {
    // A result will be listed in the dropdown if either:
    // 1. It is not selected
    // 2. Its label matches the given searchValue (if any)
    return (
      selected.every((s) => getOptionLabel(s) !== getOptionLabel(d)) &&
      (!searchValue ||
        !autoComplete ||
        getOptionLabel(d).toLowerCase().includes(searchValue.toLowerCase()))
    );
  });

  const removeSelected = (idx: number): void => {
    const shallowClone = [...selected];

    if (idx > -1) {
      shallowClone.splice(idx, 1);
    }

    setSelected(shallowClone);

    if (onChange) {
      onChange(shallowClone);
    }
  };

  const addSelected = (item: Record<string, unknown>): void => {
    const shallowClone = [...selected, item];

    setSelected(shallowClone);

    if (onChange) {
      onChange(shallowClone);
    }
  };

  const handleOptionClick = (
    e: MouseEvent<HTMLDivElement>,
    item: Record<string, unknown>
  ): void => {
    e.preventDefault();

    const idx = selected.indexOf(item);

    if (idx > -1) {
      removeSelected(idx);
    } else {
      addSelected(item);
    }

    setSearchValue("");
  };

  const handleChipDelete = (item: Record<string, unknown>): void => {
    const idx = selected.indexOf(item);

    if (idx > -1) {
      removeSelected(idx);
    }
  };

  const changeHighlightedIndex = (diff: number): void => {
    const maxIndex = results.length - 1;

    if (!popupOpen) {
      return;
    }

    // set to maxIndex if -1;
    let newIndex = highlighted + diff;

    if (newIndex < 0) {
      newIndex = maxIndex;
    } else if (newIndex > maxIndex) {
      newIndex = 0;
    }

    setHighlighted(newIndex);

    // Scroll into view.
    const listboxNode = listboxRef.current;

    if (!listboxNode) {
      return;
    }

    const option: HTMLDivElement | null = listboxNode.querySelector(
      `[data-option-index="${newIndex}"]`
    );

    if (!option) {
      return;
    }

    option.scrollIntoView({ block: "nearest" });
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>): void => {
    switch (e.key) {
      case Keys.ESCAPE:
        handleBlur();
        break;
      case Keys.ARROW_DOWN:
        // Prevent cursor move
        e.preventDefault();

        if (!popupOpen) {
          setPopupOpen(true);
          setHighlighted(0);
        }

        changeHighlightedIndex(1);
        break;
      case Keys.ARROW_UP:
        // Prevent cursor move
        e.preventDefault();

        if (!popupOpen) {
          setPopupOpen(true);
          setHighlighted(0);
        }

        changeHighlightedIndex(-1);
        break;
      case Keys.ENTER:
        e.preventDefault();
        const maxIndex = results.length - 1;

        if (highlighted > -1 && highlighted <= maxIndex && popupOpen) {
          addSelected(results[highlighted]);
          setSearchValue("");
          setPopupOpen(false);
        }
        break;
      case Keys.BACKSPACE:
        const idx = selected.length - 1;
        const noCurrentSearch =
          !searchValue || (!!searchValue && !searchValue.length);

        if (idx > -1 && noCurrentSearch) {
          removeSelected(idx);
        }
        break;
      default:
        break;
    }
  };

  const handleBlur = (): void => {
    setPopupOpen(false);
    setHighlighted(0);
  };

  const handleFocus = (): void => {
    setPopupOpen(true);
  };

  const handleInputChange = (e: FormEvent<HTMLInputElement>): void => {
    setSearchValue(e.currentTarget.value);
    setHighlighted(0);

    if (onInputChange) onInputChange(e.currentTarget.value);

    if (!popupOpen) {
      setPopupOpen(true);
    }
  };

  const handleInputMouseDown = (e: MouseEvent<HTMLInputElement>): void => {
    setPopupOpen(!popupOpen);
    setHighlighted(0);
  };

  const handleOptionMouseOver = (
    e: MouseEvent<HTMLDivElement>,
    idx: number
  ): void => {
    e.preventDefault();

    setHighlighted(idx);
  };

  return {
    getRootProps: () => ({
      onKeyDown: handleKeyDown,
      onFocus: () => {
        inputRef.current?.focus();
      }
    }),
    getInputProps: () => ({
      id,
      ref: inputRef,
      // Increment the name so that Chrome's obnoxious autoFill doesn't work.
      name: `${id}-${DateTime.local().toMillis()}`,
      style: { width: inputWidth },
      value: searchValue,
      onBlur: handleBlur,
      onFocus: handleFocus,
      onChange: handleInputChange,
      onMouseDown: handleInputMouseDown,
      // Disable browser's suggestion that might overlap with the popup.
      // (autocomplete and autofill)
      autoComplete: "disabled"
    }),
    getInputLabelProps: () => ({
      id: `${id}-label`,
      htmlFor: id
    }),
    getChipProps: (item: Record<string, unknown>, idx: number) => ({
      key: idx,
      tabIndex: -1,
      label: getOptionLabel(item),
      onDelete: () => handleChipDelete(item)
    }),
    getListboxProps: () => ({
      id: `${id}-popup`,
      "data-testid": `${id}-popup`,
      role: "listbox",
      ref: listboxRef,
      onMouseDown: (e) => e.preventDefault()
    }),
    getOptionProps: (option, idx) => {
      const isSelected = selected.indexOf(option) > -1;

      return {
        tabIndex: -1,
        role: "option",
        className: classNames("option", {
          selected: isSelected,
          highlighted: highlighted === idx
        }),
        "data-option-index": idx,
        onMouseOver: (e) => handleOptionMouseOver(e, idx),
        onClick: (e) => handleOptionClick(e, option)
      };
    },
    getInvisibleDivProps: () => {
      return {
        ref: invisibleRef,
        style: {
          width: "auto",
          display: "inline-block",
          visibility: "hidden",
          position: "fixed",
          overflow: "auto",
          fontSize: "inherit",
          whiteSpace: "pre"
        }
      };
    },
    popupOpen,
    selected,
    results
  };
}

interface AutoCompleteProps<T extends Record<string, unknown>> {
  id: string;
  data: Array<T>;
  selected?: Array<Record<string, unknown>>;
  autoComplete?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getOptionLabel: (item: Record<string, any>) => string;
  onChange?: (items: Array<Record<string, unknown>>) => void;
  onInputChange?: (val: string) => void;
}

interface AutoCompleteResults<T extends Record<string, unknown>> {
  getRootProps: () => HTMLProps<HTMLDivElement>;
  getInputProps: () => HTMLProps<HTMLInputElement>;
  getInputLabelProps: () => HTMLProps<HTMLLabelElement>;
  getListboxProps: () => HTMLProps<HTMLDivElement>;
  getInvisibleDivProps: () => HTMLProps<HTMLDivElement>;
  getChipProps: (item: Record<string, unknown>, idx: number) => ChipProps;
  getOptionProps: (
    option: Record<string, unknown>,
    idx: number
  ) => HTMLProps<HTMLDivElement>;
  selected: Array<Record<string, unknown>>;
  results: Array<T>;
  popupOpen: boolean;
}

export { useAutoComplete };
