/* eslint-disable @typescript-eslint/no-explicit-any */
import isEqual from "lodash/isEqual";
import { useCallback, useEffect, useRef, useState } from "react";
import { Schema } from "yup";
import { logger } from "../utils/logger";

function getFromLocalStorage<T>(
  key: LocalStorageKeys,
  validator?: Schema<any>
): T | null {
  try {
    const value = localStorage.getItem(key);

    if (value) {
      const parsedValue = JSON.parse(value);
      let isValid = true;

      if (validator) {
        isValid = validator.isValidSync(parsedValue);
      }

      if (isValid) {
        return parsedValue as T;
      } else {
        logger.error(`LocalStorage object stored @ ${key} is invalid.`);
        localStorage.removeItem(key);
        logger.log(`Removed invalid object in Storage @ ${key}`);
      }
    }
  } catch (e) {
    localStorage.removeItem(key);
    logger.error(
      `Error occurred while reading object from LocalStorage @ ${key}: ${e}`
    );
  }

  return null;
}

function saveToLocalStorage<T>(key: LocalStorageKeys, value: T | null): void {
  if (value === null) {
    localStorage.removeItem(key);

    return;
  }

  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    logger.error(`Error occurred while writing to LocalStorage @ ${key}: ${e}`);
  }
}
const removeFromLocalStorage = (key: LocalStorageKeys): void =>
  localStorage.removeItem(key);

function useLocalStorage<T>(
  key: LocalStorageKeys,
  validator?: Schema<any>
): [T | null, (value: T | null) => void] {
  const validatorRef = useRef(validator);
  const subscription = useRef<Subscription<T>>();

  const [state, setInternalState] = useState<T | null>(() =>
    getFromLocalStorage(key, validatorRef.current)
  );

  const setValue = useCallback(
    // TODO - assign the correct type below where the generic type was temporarily modified to any.
    (value: any) => {
      if (!isEqual(state, value)) {
        if (validatorRef.current && !validatorRef.current.isValidSync(value)) {
          logger.warn(
            `Tried to store an invalid object in LocalStorage @ ${key}`,
            value
          );
          return;
        }
        setInternalState(value);
        saveToLocalStorage(key, value);
        // Notify all of the other instances in this window
        subscription.current?.emit(value);
      }
    },
    [key, state]
  );

  // Subscribe to changes made by other components in this same window.
  useEffect(() => {
    subscription.current = createSharedState(key, setInternalState);

    return () => subscription.current?.unsubscribe();
  }, [key]);

  return [state, setValue];
}

type SharedState = {
  [key: string]: { callbacks: ((value: any) => void)[] };
};
const sharedState: SharedState = {};

type Subscription<T> = {
  unsubscribe(): void;
  emit(value: T | null): void;
};

function createSharedState<T>(
  key: LocalStorageKeys,
  callback: (value: T) => void
): Subscription<T> {
  if (!sharedState[key]) {
    sharedState[key] = { callbacks: [] };
  }
  sharedState[key].callbacks.push(callback);

  return {
    unsubscribe() {
      const arr = sharedState[key].callbacks;
      const index = arr.indexOf(callback);
      if (index > -1) {
        arr.splice(index, 1);
      }
    },
    emit(value) {
      sharedState[key].callbacks.forEach((cb) => {
        if (callback !== cb) {
          cb(value);
        }
      });
    }
  };
}

export enum LocalStorageKeys {
  RECENT_PAGES = "ae_pages",
  RECENT_EDGE_LABELS = "ae_edge_labels",
  RECENT_EDGES = "ae_edges",
  LAZY_LOAD_ANALYTICS = "ae_lazy_analytics",
  LAZY_LOAD_EDGE = "ae_lazy_edge",
  USER_OPT_IN = "ae_user_opt_in",
  USER_HAS_SEEN = "ae_user_has_seen",
  DARK_MODE = "ae_dark_mode",
  RECENT_JOB = "ae_recent_job",
  RECENT_SEARCH = "ae_recent_search",
  RECENT_SEARCH_KEYS = "ae_recent_search_keys",
  RECENT_SEARCH_ITEMS = "ae_recent_search_items",
  CSS_APP_CONFIG_TEMP = "ae_css_app_config_temp"
}

export {
  useLocalStorage,
  getFromLocalStorage,
  saveToLocalStorage,
  removeFromLocalStorage
};
