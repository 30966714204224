import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";

function fetchTicketSummaryAlertConfig(): Promise<boolean | null> {
  const url = `${getBackendApiUrl()}/config/EMPTY_AGENT_DESCRIPTION_WARNING/`;

  return request<boolean | null>(url);
}

export { fetchTicketSummaryAlertConfig };
