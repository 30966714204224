import { ITeam } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";
import { post } from "../utils/buildRequest";

async function createTeam({ name }: { name: string }): Promise<ITeam> {
  const url = `${getBackendApiUrl()}/teams/`;
  const options = post({ name });

  return request(url, options);
}

export { createTeam };
