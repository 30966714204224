import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import React from "react";
import { ToastType } from "react-toastify";
import { Toast } from "../Toast/Toast";
import "./WithLoading.scss";

interface WithLoadingProps {
  isLoading: boolean;
  error?: Error | null;
  fallback?: React.ReactElement;
  type?: "replace" | "overlay";
}

// Trying to follow React.Suspense's API
// see: https://reactjs.org/docs/react-api.html#reactsuspense
const WithLoading: React.FunctionComponent<WithLoadingProps> = ({
  isLoading,
  error,
  children,
  fallback = <Spinner />,
  type = "replace"
}) => {
  const displayLoading = (): JSX.Element => {
    if (type === "overlay") {
      return (
        <div className="with-loading">
          {isLoading && (
            <div className="overlay">
              <div className="fallback">{fallback}</div>
            </div>
          )}
          {children}
        </div>
      );
    } else if (type === "replace") {
      return <>{isLoading ? fallback : children}</>;
    }
    return <>{isLoading ? fallback : children}</>;
  };

  return (
    <>
      {displayLoading()}
      {error && <Toast type={ToastType.ERROR}>Something went wrong.</Toast>}
    </>
  );
};

export default WithLoading;
