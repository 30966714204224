import React from "react";
import { WebCacheKey } from "../../clients/cache";
import { fetchTeamById } from "../../clients/Teams/fetchTeamById";
import { fetchTeams } from "../../clients/Teams/fetchTeams";
import { unwrapPaginatedClient } from "../../clients/utils/paginated";
import { SelectAsync } from "../../components/SelectAsync/SelectAsync";
import { StyledLink } from "../../components/StyledLink/StyledLink";
import { Routes } from "../../routes/routes";

const getTeams = unwrapPaginatedClient(fetchTeams);
const paginationArgs = {
  sortBy: null,
  perPage: 20,
  page: 1
};

interface Props {
  value?: number;
  placeholder?: string;
  clearRowLabel?: string;
  onChange: (team: number | null) => void;
  className?: string;
  isError?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
}

function TeamSelect(props: Props): React.ReactElement {
  const {
    value,
    placeholder = "Unassigned",
    clearRowLabel,
    onChange,
    className,
    isDisabled,
    isLoading
  } = props;

  return (
    <SelectAsync
      value={value}
      placeholder={placeholder}
      className={className}
      clearRowLabel={clearRowLabel}
      valueKey={WebCacheKey.TEAM}
      valueLoader={fetchTeamById}
      isDisabled={isDisabled}
      isLoading={isLoading}
      getOptionAsValue={(v) => (v ? v.id : null)}
      getOptionLabel={(t) => t.name}
      optionsKey={WebCacheKey.TEAMS}
      optionsLoader={(textSearch) =>
        getTeams({ q: textSearch, ...paginationArgs })
      }
      onChange={onChange}
      noDataPlaceholder={
        <div className="mt-2 mb-2 text-center">
          There are no teams configured. Try configuring some teams{" "}
          <StyledLink to={Routes.SETTINGS_WORKFLOW}>here</StyledLink>.
        </div>
      }
      isClearable
    />
  );
}

export { TeamSelect };
