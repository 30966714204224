import React from "react";
import "./NoData.scss";

enum NoDataMessage {
  SEARCH_EDGES = "Your search did not match any Edges.",
  SEARCH_RESULTS = "No results were found matching your search.",
  SEARCH_TICKETS = "Your search did not match any tickets.",
  SEARCH_LINKED_TICKETS = "Your search did not match any tickets linked with this Edge.",
  SEARCH_LABELS = "Your search did not match any labels.",
  SEARCH_SUB_CATEGORIES = "No sub-categories found matching the current filters.",
  RECENT_EDGES = "No recent Edges found.",
  RECENT_LABELS = "No recent labels found.",
  SUGGESTED_EDGES = "No suggested Edges found.",
  SUGGESTED_LINKED_TICKETS = "No suggested tickets found for this Edge.",
  EDGE_FILES = "No files attached to this Edge.",
  EDGE_LINKS = "No links attached to this Edge.",
  LINKED_TICKETS = "No tickets are linked to this Edge.",
  LINKED_JIRAS = "No Jiras are linked to this Edge.",
  DATA = "No data found.",
  NO_EDGE_SELECTED = "No Edge selected. Please go back and select an Edge.",
  NO_TICKETS_SELECTED = "No tickets selected. Please go back and select tickets to migrate.",
  NO_RECENT_SEARCHRES = "No recent searches",
  NO_USERS_FOUND = "No users found"
}

interface NoDataProps {
  message?: NoDataMessage;
  loading?: boolean;
}

const NoData: React.FC<NoDataProps> = (props) => {
  return (
    <div className="no-data">
      {props.loading ? null : props.message || NoDataMessage.DATA}
    </div>
  );
};

export { NoData, NoDataMessage };
