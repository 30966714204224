import { IClassificationMetrics } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";

function fetchClassificationMetrics(): Promise<IClassificationMetrics[]> {
  const url = `${getBackendApiUrl()}/classification-metrics`;

  return request<{
    metrics: IClassificationMetrics[];
  }>(url).then((res) => res.metrics);
}

export { fetchClassificationMetrics };
