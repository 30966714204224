import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import {
  ISavedQuery,
  SavedQueryType
} from "@aptedge/lib-ui/src/types/entities";
import { noop } from "@aptedge/lib-ui/src/utils/noop";
import {
  fromLZCompressedString,
  IQueryArgs
} from "@aptedge/lib-ui/src/utils/query";
import React, { createContext, useEffect, useState } from "react";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient
} from "react-query";
import { useHistory } from "react-router-dom";
import { WebCacheKey } from "../clients/cache";
import { deleteSavedQuery } from "../clients/SavedQueries/deleteSavedQuery";
import { fetchSavedQueries } from "../clients/SavedQueries/fetchSavedQueries";
import { fetchSavedQueryById } from "../clients/SavedQueries/fetchSavedQueryById";
import { convertQueryStateToClientStatus } from "../clients/utils/convertQueryStateToClientStatus";
import { ConfirmationModal } from "../components/ConfirmationModal/ConfirmationModal";
import { useUrlSearchParams } from "../hooks/useUrlSearchParams";
import { IPaginatedRequest } from "../types/clients";

interface IContext {
  editing: boolean;
  setEditing: (value: boolean) => void;
  deleteModalOpen: boolean;
  setDeleteModalOpen: (value: boolean) => void;
  currentSavedQuery?: ISavedQuery;
  setCurrentSavedQuery: (value?: ISavedQuery) => void;
  savedQueries: ISavedQuery[];
  deleteQuery?: UseMutationResult<void, unknown, number, unknown>;
}

const defaultContext: IContext = {
  editing: false,
  setEditing: noop,
  deleteModalOpen: false,
  setDeleteModalOpen: noop,
  currentSavedQuery: undefined,
  setCurrentSavedQuery: noop,
  savedQueries: [],
  deleteQuery: undefined
};

const SavedQueriesContext = createContext<IContext>(defaultContext);

interface Props {
  type: SavedQueryType[];
  onChange: (value: Partial<IPaginatedRequest<IQueryArgs>>) => void;
}

const SavedQueriesProvider: React.FC<Props> = ({
  type,
  onChange,
  children
}) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const [editing, setEditing] = useState<boolean>(false);
  const [currentSavedQuery, setCurrentSavedQuery] = useState<ISavedQuery>();
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [{ id, q: query }] = useUrlSearchParams({ id: 0, q: undefined });

  const deleteQuery = useMutation(deleteSavedQuery, {
    onSuccess: () => {
      queryClient.invalidateQueries([WebCacheKey.SAVED_QUERIES, type]);
      deleteQuery.reset();
      setDeleteModalOpen(false);
      setCurrentSavedQuery(undefined);
      setEditing(false);
      history.push({
        search: `?q=${query}`
      });
    }
  });

  useQuery([WebCacheKey.SAVED_QUERY, id], () => fetchSavedQueryById(id), {
    onSuccess: (s) => setCurrentSavedQuery(s),
    enabled: !!id
  });

  const savedQueriesClient = useQuery([WebCacheKey.SAVED_QUERIES, type], () =>
    fetchSavedQueries({
      page: 1,
      perPage: 10,
      type,
      sortBy: "desc(pinned),desc(updatedOn)"
    })
  );
  const savedQueries = savedQueriesClient.data?.items || [];

  useEffect(() => {
    if (!!currentSavedQuery && !editing) {
      const q = query || currentSavedQuery.query;
      const queryParams = fromLZCompressedString<IPaginatedRequest<IQueryArgs>>(
        q
      );

      onChange(queryParams);
      history.push({
        search: `?q=${q}&id=${currentSavedQuery.id}`
      });
    }
  }, [currentSavedQuery, editing, history, onChange, query]);

  return (
    <SavedQueriesContext.Provider
      value={{
        savedQueries,
        editing,
        setEditing,
        deleteModalOpen,
        setDeleteModalOpen,
        currentSavedQuery,
        setCurrentSavedQuery,
        deleteQuery
      }}
    >
      {children}
      {!!currentSavedQuery && (
        <ConfirmationModal
          title="Delete the Saved Query?"
          isOpen={deleteModalOpen}
          loadingTitle="Deleting the Saved Query"
          status={convertQueryStateToClientStatus(deleteQuery)}
          onClose={() => setDeleteModalOpen(false)}
        >
          <div>
            Are you sure you want to delete the Saved Query: &quot;
            {currentSavedQuery.name}&quot;?
          </div>
          <div className="modal-submit-group">
            <Button
              className="mr-3"
              color="secondary"
              onClick={() => setDeleteModalOpen(false)}
            >
              Cancel
            </Button>
            <Button
              color="danger"
              onClick={() => deleteQuery.mutate(currentSavedQuery.id)}
              data-testid="confirm-delete"
            >
              Delete
            </Button>
          </div>
        </ConfirmationModal>
      )}
    </SavedQueriesContext.Provider>
  );
};

export { SavedQueriesProvider, SavedQueriesContext };
