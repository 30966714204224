import {
  ILabelSetting,
  IPhraseSetting
} from "@aptedge/lib-ui/src/types/entities";
import React from "react";
import { PhraseList } from "./PhraseList";

interface Props {
  labels: ILabelSetting[];
  phrases: IPhraseSetting[];
  onChange: () => void;
}
const PhraseSettings: React.FC<Props> = (props) => {
  const { labels, phrases, onChange } = props;

  const flatLabels = labels.flatMap((l) => {
    if (l.children?.length) {
      return [l, ...l.children];
    }

    return l;
  });
  const sortedLabels = [...flatLabels].sort((a, b) =>
    a.name.localeCompare(b.name)
  );
  const sortedPhrases = [...phrases].sort((a, b) => {
    if (a.label.id === b.label.id) {
      return a.phrase.value.localeCompare(b.phrase.value);
    }

    return a.label.name.localeCompare(b.label.name);
  });

  return (
    <>
      <section className="row mb-5">
        <div className="col-md-5 p-2 pr-3">
          <h4 className="text-uppercase">Search Terms</h4>
          <p>
            Add search terms that our machine learning will use to correctly tag
            your Zendesk tickets with corresponding labels.
          </p>
        </div>
        <div className="col-md-7 p-2 pl-3">
          <PhraseList
            labels={sortedLabels}
            phrases={sortedPhrases}
            onChange={onChange}
          />
        </div>
      </section>
      <hr className="mt-4" />
    </>
  );
};

export { PhraseSettings };
