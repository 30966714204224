import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { Feedback } from "@aptedge/lib-ui/src/components/Feedback/Feedback";
import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import {
  ISlackSocialSource,
  ISlackChannel
} from "@aptedge/lib-ui/src/types/entities";
import classNames from "classnames";
import { useFormik } from "formik";
import React, { createRef, useEffect } from "react";
import { useMutation } from "react-query";
import * as Yup from "yup";
import { getSlackChannelInfo } from "../../../clients/SocialSource/getSlackChannelInfo";
import WithLoading from "../../../components/WithLoading/WithLoading";

interface Props {
  slackSource: ISlackSocialSource;
  onCancel: () => void;
  onSave: (channelId: string) => void;
}

interface FormState {
  channels: string;
}

const SlackAddChannel: React.FunctionComponent<Props> = ({
  onCancel,
  onSave,
  slackSource
}: Props) => {
  const [errorMsg, setErrorMsg] = React.useState("");

  const inputRef = createRef<HTMLInputElement>();

  const getChannelInfo = useMutation(getSlackChannelInfo, {
    onSuccess: (res: ISlackChannel[]) => {
      const response = res[0];
      if (response?.error) {
        setErrorMsg(
          "Error. This is not a valid channel ID or This channel ID may be private"
        );
      } else {
        setErrorMsg("");
        onSave(response.channelId);
      }
    }
  });

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddChannel = (channelVal: string): void => {
    const isPresent = slackSource?.channels?.find(
      (channel: ISlackChannel) => channel.channelId === channelVal
    );
    if (isPresent?.channelId) {
      setErrorMsg("Channel already added");
    } else {
      setErrorMsg("");
      getChannelInfo.mutate({ channels: [channelVal] });
    }
  };

  const formik = useFormik<FormState>({
    initialValues: {
      channels: ""
    },
    onSubmit: (values: FormState) => {
      handleAddChannel(values?.channels);
    },
    validateOnBlur: false,
    validationSchema: Yup.object({
      channels: Yup.string().required("A channel ID is required")
    })
  });

  return (
    <WithLoading
      isLoading={getChannelInfo.isLoading}
      type="overlay"
      fallback={<Spinner />}
    >
      <div className={classNames("label-create-item p-2")}>
        <h5>Create a new custom field mapping</h5>
        <form onSubmit={formik.handleSubmit}>
          <div className="form-row">
            <div className="form-group col-md-3">
              <label htmlFor="fieldName">Channel ID</label>
            </div>
            <div className="form-group col-md-9">
              <input
                data-testid="slack-channel-id"
                id="channels"
                type="text"
                ref={inputRef}
                className={classNames("form-control", {
                  "is-invalid": !!errorMsg || !!formik.errors.channels
                })}
                aria-describedby="channelsHelp"
                value={formik.values.channels}
                onChange={formik.handleChange}
              />
              {(formik.errors.channels || errorMsg) && (
                <Feedback>{errorMsg || formik.errors.channels}</Feedback>
              )}
            </div>
          </div>

          <div className="d-flex mt-2 justify-content-end">
            <Button
              data-testid="slack-add-channel-cancel"
              type="button"
              className="mr-2"
              color="secondary"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Connect
            </Button>
          </div>
        </form>
      </div>
    </WithLoading>
  );
};

export { SlackAddChannel };
