import { ISalesforceKnowledgeArticleSourceData } from "@aptedge/lib-ui/src/types/entities";
import { getBackendUrl } from "../../config";
import { request } from "../../utils/request";

function fetchSalesforceKnowledgeArticleSource(): Promise<
  ISalesforceKnowledgeArticleSourceData
> {
  const url = `${getBackendUrl()}/salesforce-knowledge/tap`;

  return request(url);
}

export { fetchSalesforceKnowledgeArticleSource };
