import { Dropdown } from "@aptedge/lib-ui/src/components/Dropdown/Dropdown";
import { AeIcons } from "@aptedge/lib-ui/src/components/Icon/AeIcons";
import {
  AptAnswersContextType,
  AptAnswersSearchContextItem
} from "@aptedge/lib-ui/src/types/entities";
import * as React from "react";
import { FilterItem } from "./FilterItem";
import { FilterReset } from "./FilterReset";
import { FilterSelectAll } from "./FilterSelectAll";
import styles from "./SearchFilterMenuRoot.module.scss";

interface SearchFilterMenuRootProps {
  contextType: AptAnswersContextType;
  label: string;
  icon: React.ReactNode;
  count: number;
  items: AptAnswersSearchContextItem[];
  enableSelectAll: boolean;
  selectedItems: string[];
  handleItemClick: (id: string) => void;
  handleSelectAll: () => void;
  handleReset: () => void;
}

const SearchFilterMenuRoot: React.FC<SearchFilterMenuRootProps> = ({
  contextType,
  label,
  icon,
  count,
  items,
  enableSelectAll,
  selectedItems,
  handleItemClick,
  handleSelectAll,
  handleReset
}) => {
  const showSelectAll = items.length > 1 && enableSelectAll;
  const showCount = count > 0;
  return (
    <Dropdown.Container placement="bottom-start">
      <Dropdown.Toggle>
        <div className={styles.toggleContainer}>
          <div className={styles.menuIcon}>{icon}</div>
          <div className={styles.label}>{label}</div>
          {showCount ? (
            <div className={styles.count}>{count}</div>
          ) : (
            <div className={styles.countPlaceholder} />
          )}
          <div className={styles.chevronIcon}>
            <AeIcons.ChevronDown />
          </div>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <div className={styles.menuContainer}>
          {showSelectAll && (
            <FilterSelectAll
              label={`All ${
                contextType === AptAnswersContextType.PRODUCT_FILTER
                  ? "products"
                  : "quick filters"
              }`}
              icon={
                contextType === AptAnswersContextType.PRODUCT_FILTER ? (
                  <AeIcons.ProductFiltersMenu />
                ) : (
                  <AeIcons.QuickFiltersMenu />
                )
              }
              selected={items.length === selectedItems.length}
              handleClick={handleSelectAll}
            />
          )}
          {items.map((item) => (
            <FilterItem
              key={item.id}
              id={item.id}
              name={item.name}
              icon={
                contextType === AptAnswersContextType.PRODUCT_FILTER ? (
                  <AeIcons.ProductFiltersUnselected />
                ) : (
                  <AeIcons.QuickFiltersUnselected />
                )
              }
              selected={selectedItems.includes(item.id)}
              handleItemClick={handleItemClick}
            />
          ))}
          <FilterReset
            label="Reset Sources"
            icon={<AeIcons.Refresh />}
            handleClick={handleReset}
          />
        </div>
      </Dropdown.Menu>
    </Dropdown.Container>
  );
};

export { SearchFilterMenuRoot };
