import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import { useOnMouseDownOutside } from "@aptedge/lib-ui/src/hooks/useOnMouseDownOutside";
import {
  ILabelSetting,
  IPhraseSetting
} from "@aptedge/lib-ui/src/types/entities";
import classNames from "classnames";
import React, { createRef, useState } from "react";
import { PhraseCreateItem } from "./PhraseCreateItem";
import { PhraseListItem } from "./PhraseListItem";

interface Props {
  labels: ILabelSetting[];
  phrases: IPhraseSetting[];
  onChange: () => void;
}

const PhraseList: React.FC<Props> = (props) => {
  const { phrases, labels, onChange } = props;

  const [showAddPhrase, setShowAddPhrase] = useState(false);

  const wrapperRef = createRef<HTMLUListElement>();

  useOnMouseDownOutside(wrapperRef, () => {
    setShowAddPhrase(false);
  });

  const handleSave = (): void => {
    setShowAddPhrase(false);
    onChange();
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-start">
        <div>
          <h5>Terms</h5>
          <p>Add search terms we should use to label tickets.</p>
        </div>
        <div>
          <Button color="secondary" onClick={() => setShowAddPhrase(true)}>
            <Icons.Plus />
            <span>Add</span>
          </Button>
        </div>
      </div>
      <ul ref={wrapperRef} className={classNames("phrase-list list-group")}>
        {showAddPhrase && (
          <PhraseCreateItem
            labels={labels}
            onSave={handleSave}
            onCancel={() => setShowAddPhrase(false)}
          />
        )}
        {phrases.map((p, key) => (
          <PhraseListItem
            key={`phrase-${p.phrase.id}-${p.phrase.value}`}
            phrase={p}
            onChange={onChange}
          />
        ))}
      </ul>
    </>
  );
};

export { PhraseList };
