import { Avatar } from "@aptedge/lib-ui/src/components/Avatar/Avatar";
import { IBulkReply } from "@aptedge/lib-ui/src/types/entities";
import classNames from "classnames";
import capitalize from "lodash/capitalize";
import React from "react";
import { jobIsPending } from "../../hooks/clients/useJobStatus";
import { displayAge } from "../../utils/time";
import "./EdgeBulkReply.scss";
import { EdgeBulkReplyStatus } from "./EdgeBulkReplyStatus";

interface Props {
  reply: IBulkReply;
}

function EdgeBulkReply(props: Props): React.ReactElement {
  const { reply } = props;
  const {
    createdBy,
    createdOn,
    completedOn,
    content,
    filters,
    internal
  } = reply;

  return (
    <div
      className={classNames("edge-bulk-reply", {
        pending: jobIsPending(reply.status)
      })}
    >
      <div className="edge-bulk-reply-details">
        <Avatar
          size="24px"
          color="var(--gray-200)"
          email={createdBy.email}
          text={createdBy.email}
        />
        <span className="edge-bulk-reply-details-created-by">
          {createdBy.email}{" "}
        </span>
        <span className="edge-bulk-reply-details-reply">
          {internal ? "replied internally to " : "replied to "}
        </span>
        <span className="edge-bulk-reply-details-filter">
          {capitalize(filters?.status?.[0] || "all")}{" "}
        </span>
        <span>tickets</span>
      </div>
      <div className={classNames("edge-bulk-reply-content", { internal })}>
        {content}
      </div>
      <div className="edge-bulk-reply-stats">
        <EdgeBulkReplyStatus reply={reply} />
        <div className="edge-bulk-reply-created-on">
          {displayAge(completedOn || createdOn)}
        </div>
      </div>
    </div>
  );
}

export { EdgeBulkReply };
