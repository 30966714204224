/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { useFormik } from "formik";
import { capitalize } from "lodash";
import { FC } from "react";
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label
} from "reactstrap";
import * as Yup from "yup";
import { Field } from "../DynamicIntegrations";
import { FIELD_TYPE, GenerateFormData } from "../Integration/Integration";

type FormSchemaResult = {
  validationSchema: any;
  initialValues: Record<string, string>;
};

interface Props<T extends Field[]> {
  fields: T;
  onSubmit: (formData: GenerateFormData<T>) => void;
  initialFormData: Record<string, any>;
}
const generateFormSchema = (
  fields: Field[],
  initialFormData: Record<string, any>
): FormSchemaResult => {
  const shape: Record<string, Yup.StringSchema<string>> = {};

  fields.forEach((field) => {
    shape[field.id] = Yup.string();
  });

  const validationSchema = Yup.object().shape(shape);

  const initialValues: Record<string, string> = {};
  fields.forEach((field) => {
    if (field.fieldType === FIELD_TYPE.LIST)
      initialValues[field.id] = initialFormData[field.id]?.join(", ") ?? "";
    else initialValues[field.id] = initialFormData[field.id] ?? "";
  });

  return { validationSchema, initialValues };
};

const ArticleConfigForm: FC<Props<Field[]>> = ({
  fields,
  onSubmit,
  initialFormData
}) => {
  const { validationSchema, initialValues } = generateFormSchema(
    fields,
    initialFormData
  );

  const formik = useFormik<GenerateFormData<Field[]>>({
    validationSchema: validationSchema,
    initialValues: {
      ...initialValues
    },
    validateOnChange: false,
    onSubmit: (values) => {
      const formData: Partial<GenerateFormData<Field[]>> = {};

      fields.forEach((field) => {
        if (field.fieldType === FIELD_TYPE.LIST) {
          formData[field.id] = values[field.id]?.split(/,\s*/) || [];
        } else formData[field.id] = values[field.id];
      });

      onSubmit(formData as GenerateFormData<Field[]>);
    }
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      {fields.map((field) => (
        <FormGroup row key={field.id}>
          <Label sm={3} for={field.id as string}>
            {capitalize(field.label.toString())}
          </Label>
          <Col sm={9}>
            <Input
              name={field.id as string}
              placeholder={field?.placeholder ?? ""}
              value={formik.values[field.id]}
              onChange={formik.handleChange}
              invalid={!!formik.errors[field.id]}
            />
            <FormFeedback>{formik.errors[field.id]}</FormFeedback>
            <FormText>
              {field?.hintText ?? field.fieldType === FIELD_TYPE.LIST
                ? `Please add multiple ${field.id} separated by comma`
                : `Please enter ${field.id}`}
            </FormText>
          </Col>
        </FormGroup>
      ))}
      <div className="float-right">
        <Button type="submit">Authorize</Button>
      </div>
    </Form>
  );
};

export { ArticleConfigForm };
