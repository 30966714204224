import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { QueryErrorResetBoundary } from "react-query";
import { GetDataErrorFallback } from "./GetDataErrorFallback";

interface Props {
  message?: string;
}

const GetDataErrorBoundary: React.FC<Props> = (props) => {
  const { children, message } = props;

  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          onReset={reset}
          fallbackRender={({ resetErrorBoundary }) => (
            <GetDataErrorFallback
              message={message}
              resetErrorBoundary={resetErrorBoundary}
            />
          )}
        >
          {children}
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
};

export { GetDataErrorBoundary };
