import { AeIcons } from "@aptedge/lib-ui/src/components/Icon/AeIcons";
import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchIcon from "@mui/icons-material/Search";
import { TextField, TextareaAutosize } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import classNames from "classnames";
import { FC, KeyboardEvent, RefObject } from "react";
import { isSupportApp } from "../../utils/supportApp";
import { onEnterKeyPressed } from "../../utils/utils";
import useSearch from "./hooks/useSearch";
import styles from "./Search.module.scss";

interface IProps {
  className?: string;
  placeholder?: string;
  openInWebApp?: () => void;
  autoFocus?: boolean;
  inputRef?: RefObject<HTMLInputElement>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  suggestions?: any;
}

const defaultProps = {
  className: styles.searchContainer,
  type: "text",
  autoFocus: false
};

const Search: FC<IProps> = (props: IProps) => {
  const {
    className,
    placeholder,
    autoFocus,
    openInWebApp,
    inputRef,
    suggestions
  } = props;

  const { flags } = useFlags();
  const {
    searchQuery,
    onInputChange,
    onEnterPress,
    onCancelClick,
    onBackBtnPress
  } = useSearch(suggestions);

  const searchOnEnter = (event: KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === "Enter") {
      event.preventDefault();
      onEnterKeyPressed(event, onEnterPress);
    }
  };

  const inputPlaceholder = placeholder ?? "Ask Answer AI";

  return (
    <TextField
      inputRef={inputRef}
      data-testid="search-bar"
      className={classNames(className, styles.default)}
      placeholder={inputPlaceholder}
      autoFocus={autoFocus}
      value={searchQuery}
      onChange={onInputChange}
      onKeyDown={searchOnEnter}
      fullWidth={true}
      variant="standard"
      InputProps={{
        disableUnderline: true,
        startAdornment: (
          <>
            {flags.ticketInsightV2 && isSupportApp ? (
              <InputAdornment
                position="start"
                className={styles.backBtn}
                onClick={onBackBtnPress}
              >
                <ArrowBackIcon />
              </InputAdornment>
            ) : (
              <InputAdornment position="start" className={styles.adornmentTop}>
                <SearchIcon />
              </InputAdornment>
            )}
          </>
        ),
        endAdornment: (
          <>
            {isSupportApp && !flags.selfService && (
              <InputAdornment
                onClick={openInWebApp}
                position="end"
                className={styles.adornmentTop}
              >
                <AeIcons.OpenInWeb className={styles.openInWebIcon} />
              </InputAdornment>
            )}
            <InputAdornment
              onClick={onCancelClick}
              position="end"
              className={styles.adornmentTop}
            >
              <AeIcons.CloseIcon className={styles.searchCancelIcon} />
            </InputAdornment>
          </>
        ),
        inputComponent: TextareaAutosize,
        inputProps: {
          minRows: 1,
          className: styles.textfield
        }
      }}
    />
  );
};

Search.defaultProps = defaultProps;

export { Search };
