import { IPhrase } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { ICreatePhraseArgs } from "../../types/clients";
import { request } from "../../utils/request";
import { post } from "../utils/buildRequest";

async function createPhrase(args: ICreatePhraseArgs): Promise<IPhrase> {
  const url = `${getBackendApiUrl()}/phrases/`;
  const options = post(args);

  return request(url, options);
}

export { createPhrase };
