import {
  useAppDispatch,
  useAppSelector
} from "@aptedge/lib-ui/src/redux/hook/hook";
import {
  addMSTeamsGroups,
  updateMSTeamsFlags
} from "@aptedge/lib-ui/src/redux/reduxSlice/msTeamsSlice";
import {
  IMSTeamsGroup,
  IMSTeamsSocialChannelInfoRequest,
  IMSTeamsSource,
  IMSTeamsFlags
} from "@aptedge/lib-ui/src/types/entities";
import {
  useCallback,
  useEffect,
  useState,
  SetStateAction,
  Dispatch
} from "react";
import { UseMutationResult, useMutation } from "react-query";
import { getMSTeamsChannelInfo } from "../../../../clients/SocialSource/MSTeamsSource/getMSTeamsChannelInfo";

const TEAMS_URL =
  "https://teams.microsoft.com/l/team/19%3acHWqPj5-r6RTAkPjo-GPuM9TNHmfdmg0IDnn5vCZRr81%40thread.tacv2/conversations?";

interface MSTeamsListConfig {
  errorMsg: string;
  showAddMSTeamsChannels: boolean;
  channelInfo: UseMutationResult<
    IMSTeamsGroup,
    unknown,
    IMSTeamsSocialChannelInfoRequest,
    unknown
  >;
  setShowAddMSTeamsChannels: Dispatch<SetStateAction<boolean>>;
  handleCancel: () => void;
  getChannels: (groupUrl: string, tenantId: string, groupId: string) => void;
}

function useMSTeamsListConfig(
  source: IMSTeamsSource | null,
  getDatasource: () => void
): MSTeamsListConfig {
  const [showAddMSTeamsChannels, setShowAddMSTeamsChannels] = useState<boolean>(
    false
  );
  const [errorMsg, setErrorMsg] = useState<string>("");

  const dispatch = useAppDispatch();
  const msteamsSocialResource = source ?? null;
  const { msTeamsGroups, mutationTriggered, selectionUpdated } = useAppSelector(
    (state) => state.msTeams
  );

  const [isAllSelected, setIsAllSelected] = useState(
    Array(msTeamsGroups.length).fill(false)
  );

  const channelInfo = useMutation(getMSTeamsChannelInfo, {
    onSuccess: (
      res: IMSTeamsGroup,
      variables: { shouldShowChannels: boolean }
    ) => {
      const response = res;
      if (response?.error) {
        setErrorMsg(
          "Error. This is not a valid group url or This group may be private"
        );
      } else {
        setErrorMsg("");
        handleAddChannels(response, variables.shouldShowChannels);
      }
    }
  });

  const getChannels = useCallback(
    (groupUrl: string, tenantId: string, groupId: string | undefined): void => {
      if (groupUrl) {
        channelInfo.mutate({ groupUrl: groupUrl, shouldShowChannels: true });
      } else {
        const grpUrl = `${TEAMS_URL}groupId=${groupId}&tenantId=${tenantId}`;
        channelInfo.mutate({ groupUrl: grpUrl, shouldShowChannels: false });
        dispatch(
          updateMSTeamsFlags({ mode: IMSTeamsFlags.LOAD, value: false })
        );
      }
    },
    [channelInfo, dispatch]
  );

  const updateSelection = useCallback(
    (selected: boolean | undefined, index: number): void => {
      const newAllSelected = [...isAllSelected];
      newAllSelected[index] = selected;
      setIsAllSelected(newAllSelected);
    },
    [isAllSelected]
  );

  useEffect(() => {
    if (
      msteamsSocialResource &&
      msteamsSocialResource.filters?.length > 0 &&
      !mutationTriggered
    ) {
      msteamsSocialResource.filters?.forEach((group) => {
        getChannels("", msteamsSocialResource.tenantId, group.groupId);
      });
      dispatch(updateMSTeamsFlags({ mode: IMSTeamsFlags.MUTATE, value: true }));
      dispatch(
        updateMSTeamsFlags({ mode: IMSTeamsFlags.RELOAD, value: false })
      );
    }
  }, [msteamsSocialResource, mutationTriggered, getChannels, dispatch]);

  useEffect(() => {
    if (msTeamsGroups && msTeamsGroups.length > 0 && !selectionUpdated) {
      msTeamsGroups.forEach((group, index) => {
        const allSelected = group.channels?.every(
          (channel) => channel.isSelected
        );
        updateSelection(allSelected, index);
      });
      dispatch(updateMSTeamsFlags({ mode: IMSTeamsFlags.SELECT, value: true }));
    }
  }, [msTeamsGroups, selectionUpdated, updateSelection, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(updateMSTeamsFlags({ mode: IMSTeamsFlags.DELETE, value: true }));
    };
  }, [dispatch]);

  const handleCancel = (): void => {
    setShowAddMSTeamsChannels(false);
    getDatasource();
  };

  const handleAddChannels = (
    val: IMSTeamsGroup | [],
    shouldShowChannels: boolean
  ): void => {
    if (Array.isArray(val)) {
      val.forEach((group) => {
        dispatch(addMSTeamsGroups(group));
      });
    } else if (typeof val === "object") {
      const existingItem = msTeamsGroups.find(
        (group) => group.groupId === val?.groupId
      );
      if (!existingItem) {
        dispatch(addMSTeamsGroups(val));
      }
    }
    dispatch(updateMSTeamsFlags({ mode: IMSTeamsFlags.SELECT, value: false }));
    dispatch(updateMSTeamsFlags({ mode: IMSTeamsFlags.CHECK, value: false }));
    dispatch(updateMSTeamsFlags({ mode: IMSTeamsFlags.MUTATE, value: true }));
    if (shouldShowChannels) {
      dispatch(updateMSTeamsFlags({ mode: IMSTeamsFlags.LOAD, value: true }));
    }
    handleCancel();
  };

  return {
    errorMsg,
    showAddMSTeamsChannels,
    channelInfo,
    setShowAddMSTeamsChannels,
    handleCancel,
    getChannels
  };
}

export { useMSTeamsListConfig };
