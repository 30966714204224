import classNames from "classnames";
import * as React from "react";
import { Link } from "react-router-dom";
import styles from "./TabItem.module.scss";

interface TabItemProps {
  to: string;
  label: string;
  count: number | null;
  isCurrent: boolean;
}

const TabItem: React.FC<TabItemProps> = ({ to, label, count, isCurrent }) => {
  const showPendingCount = count !== null && count !== 0;
  return (
    <Link
      to={to}
      className={classNames(
        styles.container,
        isCurrent ? styles.selectedTabContainer : styles.unselectedTabContainer
      )}
    >
      <p
        className={classNames(
          isCurrent ? styles.selectedTabLabel : styles.unselectedTabLabel
        )}
      >
        {label}
      </p>
      {showPendingCount && (
        <div className={styles.countBubbleContainer}>
          <span className={styles.countBubbleLabel}>{count}</span>
        </div>
      )}
    </Link>
  );
};

export { TabItem };
