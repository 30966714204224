/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from "classnames";
import React from "react";
import { Spinner, SpinnerSize } from "../Spinner/Spinner";
import "./Button.scss";

export type ButtonColor = "primary" | "secondary" | "danger" | "warning";
export type Size = "small" | "large";
export interface ButtonProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  type?: "submit" | "reset" | "button";
  size?: Size;
  color?: ButtonColor;
  value?: number | string;
  onClick?: React.MouseEventHandler;
  active?: boolean;
  disabled?: boolean;
  className?: string;
  isLoading?: boolean;
}

/**
 * Renders a styled button as a Link, anchor, or plain button
 * depending on the given props.
 *
 * Include "to" for Link, "href" for anchor, or neither for button.
 *
 * Will always render a child `<Icon />` on the left side.
 *
 * ```
 * // Link
 * <Button to="/products/1">Click me</Button>
 *
 * // Anchor
 * <Button href="https://www.google.com">Google<Button>
 *
 * // Plain HTML button
 * <Button onClick={() => doSomethingCool()}>Click me</Button>
 * ```
 */
const Button: React.FunctionComponent<ButtonProps> = ({
  children,
  className,
  disabled,
  active,
  color = "primary",
  type = "button",
  size = "small",
  isLoading = false,
  ...htmlProps
}) => {
  return (
    <button
      type={type}
      disabled={disabled}
      className={classNames("btn ae-button", color, {}, size, className, {
        disabled: disabled || isLoading,
        loading: isLoading,
        active
      })}
      {...htmlProps}
    >
      <div className="button-content">
        {children}
        {isLoading && (
          <Spinner className="d-inline-block" size={SpinnerSize.SMALL} />
        )}
      </div>
    </button>
  );
};

export interface ButtonColorDetails {
  color: string;
  backgroundColor: string;
  borderColor: string;
}

export { Button };
