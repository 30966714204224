import { IUserListData } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";
import { toQueryString } from "../utils/url";

async function fetchBatchUserList(args: {
  ids?: number[];
}): Promise<IUserListData[]> {
  const query = toQueryString({ ...args });
  let url = "";

  url = `${getBackendApiUrl()}/users/?${query}`;

  return request(url);
}
export { fetchBatchUserList };
