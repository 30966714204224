import { AeIcons } from "@aptedge/lib-ui/src/components/Icon/AeIcons";
import { AptAnswersGroupFilterItem } from "@aptedge/lib-ui/src/types/entities";
import classNames from "classnames";
import * as React from "react";
import styles from "./FilterListItem.module.scss";

interface FilterListItemProps {
  isTopItem?: boolean;
  isBottomItem?: boolean;
  item: AptAnswersGroupFilterItem;
  handleClick: (filterName: string) => void;
}

const FilterListItem: React.FC<FilterListItemProps> = ({
  isTopItem = false,
  isBottomItem = false,
  item,
  handleClick
}) => {
  const filterName = item[0];
  const itemFilters = item[1];
  const rulesCount = itemFilters.reduce(
    (sum, itemFilter) => sum + itemFilter.filterRules.length,
    0
  );
  const rulesMessage =
    rulesCount > 0
      ? `${rulesCount} ${rulesCount === 1 ? "rule" : "rules"}`
      : "";
  return (
    <div
      className={classNames(styles.container, {
        [styles.top]: isTopItem,
        [styles.bottom]: isBottomItem
      })}
      onClick={() => handleClick(filterName)}
    >
      <span className={styles.filterIcon}>
        <AeIcons.QuickFilters />
      </span>
      <span className={styles.filterName}>{filterName}</span>
      <span className={styles.rulesDescription}>{rulesMessage}</span>
    </div>
  );
};

export { FilterListItem };
