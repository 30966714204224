import TiptapEditor from "@aptedge/lib-ui/src/components/Editor/TiptapEditor";
import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import React, { forwardRef, PropsWithChildren, useState } from "react";
import { useQuery } from "react-query";
import turndown from "turndown";
import { WebCacheKey } from "../../clients/cache";
import { fetchArticle } from "../../clients/GeneratedKnowledge/fetchArticle";
import styles from "./EditArticleModal.module.scss";

interface EditArticleModalProps {
  articleId: string;
  handleClose: () => void;
  handleSave: ({ title, body }: { title: string; body: string }) => void;
}
const turndownService = new turndown();
turndownService.addRule("strikethrough", {
  filter: ["s", "strike", "del"] as (keyof HTMLElementTagNameMap)[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  replacement: function (content: any) {
    return "~~" + content + "~~";
  }
});

const EditArticleModal = forwardRef<
  HTMLDivElement,
  PropsWithChildren<EditArticleModalProps>
>(({ articleId, handleClose, handleSave }, ref) => {
  const [title, setTitle] = useState<string>("");
  const [body, setBody] = useState<string>("");

  const { data: article, isLoading } = useQuery(
    [WebCacheKey.ARTICLE, articleId],
    () => fetchArticle({ articleId })
  );

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setTitle(e.target.value);
  };

  const handleBodyChange = (content: string): void => {
    const markdownFromHtml = turndownService.turndown(content);
    setBody(markdownFromHtml);
  };

  const handleSaveChanges = (): void => {
    handleSave({ title, body });
  };

  return (
    <div className={styles.container}>
      <div ref={ref} className={styles.contentContainer}>
        <div className={styles.modalHeader}>
          <h3>Edit Article</h3>
          <button onClick={handleClose}>
            <Icons.Times size="lg" />
          </button>
        </div>
        {isLoading ? (
          <div className={styles.spinnerContainer}>
            <Spinner />
          </div>
        ) : (
          <>
            <div className={styles.articleEditingContainer}>
              <div className={styles.titleInputContainer}>
                <input
                  defaultValue={article?.title}
                  onChange={handleTitleChange}
                />
              </div>
              <div className={styles.bodyTextareaContainer}>
                <TiptapEditor
                  initialContent={article?.body.body}
                  onChange={handleBodyChange}
                />
              </div>
            </div>
            <div className={styles.actionsContainer}>
              <button
                onClick={handleSaveChanges}
                className={styles.saveArticleButton}
              >
                <Icons.Save />
                Save changes
              </button>
              <button onClick={handleClose} className={styles.discardButton}>
                <Icons.Trash />
                Discard changes
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
});

export { EditArticleModal };
