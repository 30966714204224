import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";

function fetchTicketTypes(): Promise<{ ticketTypes: string[] }> {
  const url = `${getBackendApiUrl()}/tickets/ticket-types/`;

  return request(url);
}

export { fetchTicketTypes };
