import { AeIcons } from "@aptedge/lib-ui/src/components/Icon/AeIcons";
import { DEFAULT_PREVENT_OVERFLOW_MODIFIER } from "@aptedge/lib-ui/src/components/Popper/popper";
import { useOnMouseDownOutside } from "@aptedge/lib-ui/src/hooks/useOnMouseDownOutside";
import classNames from "classnames";
import * as React from "react";
import { Manager, Popper, Reference } from "react-popper";
import styles from "./Select.module.scss";

interface SelectProps {
  options: string[];
  currentOption: string;
  getOptionLabel: (option: string) => string;
  handleUpdate: (option: string) => void;
  isDisabled: boolean;
  isTop: boolean;
  isRight: boolean;
  isBottom: boolean;
  isLeft: boolean;
}

const Select: React.FC<SelectProps> = ({
  options,
  currentOption,
  getOptionLabel,
  handleUpdate,
  isDisabled,
  isTop,
  isRight,
  isBottom,
  isLeft
}) => {
  const [popupOpen, setPopupOpen] = React.useState<boolean>(false);

  const containerRef = React.useRef<HTMLDivElement>(null);
  const listBoxRef = React.useRef<HTMLDivElement>(null);
  useOnMouseDownOutside(containerRef, () => setPopupOpen(false));

  const togglePopup = (): void => {
    if (!isDisabled) setPopupOpen(!popupOpen);
  };

  const handleOptionClick = (item: string): void => {
    setPopupOpen(false);
    handleUpdate(item);
  };

  return (
    <div
      className={styles.container}
      ref={containerRef}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <Manager>
        <Reference>
          {({ ref }) => (
            <div
              ref={ref}
              onClick={togglePopup}
              className={classNames(styles.referenceContainer, {
                [styles.referenceContainerBottom]: isBottom,
                [styles.referenceContainerRight]: isRight,
                [styles.referenceContainerTopLeft]: isTop && isLeft,
                [styles.referenceContainerTopRight]: isTop && isRight,
                [styles.referenceContainerBottomLeft]: isBottom && isLeft,
                [styles.referenceContainerBottomRight]: isBottom && isRight,
                [styles.referenceContainerDisabled]: isDisabled
              })}
            >
              <p
                className={classNames(styles.referenceLabel, {
                  [styles.referenceLabelDisabled]: isDisabled
                })}
              >
                {getOptionLabel(currentOption)}
              </p>
              <span>
                <AeIcons.ChevronDown />
              </span>
            </div>
          )}
        </Reference>
        {popupOpen && (
          <Popper
            placement="bottom-end"
            modifiers={[DEFAULT_PREVENT_OVERFLOW_MODIFIER]}
          >
            {({ ref, style, placement }) => (
              <div>
                <div
                  className={styles.popperContainer}
                  ref={ref}
                  style={style}
                  data-placement={placement}
                  onMouseDown={(e) => e.preventDefault()}
                >
                  <div ref={listBoxRef}>
                    {options.map((item, idx) => {
                      const isSelected = item === currentOption;
                      return (
                        <div
                          key={idx}
                          className={classNames(styles.option, {
                            [styles.optionSelected]: isSelected
                          })}
                          onClick={() => handleOptionClick(item)}
                        >
                          <span>{getOptionLabel(item)}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          </Popper>
        )}
      </Manager>
    </div>
  );
};

export { Select };
