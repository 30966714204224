import React from "react";
import { SelfServiceConfiguration } from "./SelfServiceConfiguration";
import "./SupportAppSettings.scss";

function SelfServiceSettings(): React.ReactElement {
  return (
    <section className="support-app-settings">
      <div className="row mb-5">
        <div className="col-md-5 p-2 pr-3">
          <h4 className="text-uppercase">Self-Service App</h4>
          <p>Use these settings to configure the AptEdge Self-Service App.</p>
        </div>
        <div className="col-md-7 p-2 pl-3">
          <SelfServiceConfiguration />
        </div>
      </div>
    </section>
  );
}

export { SelfServiceSettings };
