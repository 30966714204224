import { DateRangePicker } from "@aptedge/lib-ui/src/components/DateRangePicker/DateRangePicker";
import { SHORTCUTS } from "@aptedge/lib-ui/src/components/DateRangeShortcuts/shortcuts";
import { TimeDimensionWrapper } from "@aptedge/lib-ui/src/components/Filter/TimeDimensionWrapper";
import {
  dateRangeToQueryParams,
  queryParamsToDateRange
} from "@aptedge/lib-ui/src/utils/date";
import { DateTime } from "luxon";
import React from "react";
import "./DateRangeFilter.scss";

type Props = Omit<
  React.ComponentPropsWithoutRef<typeof TimeDimensionWrapper>,
  "children"
>;

function DateRangeFilter(props: Props): React.ReactElement {
  return (
    <TimeDimensionWrapper {...props}>
      {({ values, onChange }) => {
        const value = queryParamsToDateRange(values);

        return (
          <div className="date-range-filter">
            <DateRangePicker
              data-testid="date-range-filter"
              min={props.dateRangeMin}
              max={DateTime.local().endOf("day")}
              value={value}
              onChange={(newRange) =>
                onChange(dateRangeToQueryParams(newRange))
              }
              shortcuts={[
                SHORTCUTS.PAST_WEEK,
                SHORTCUTS.PAST_TWO_WEEKS,
                SHORTCUTS.PAST_MONTH,
                SHORTCUTS.PAST_THREE_MONTHS
              ]}
            />
          </div>
        );
      }}
    </TimeDimensionWrapper>
  );
}

export { DateRangeFilter };
