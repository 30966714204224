import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import {
  Modal,
  ModalFooter,
  ModalWidth
} from "@aptedge/lib-ui/src/components/Modal/Modal";
import { IEdgeListing, ILabel } from "@aptedge/lib-ui/src/types/entities";
import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { EdgeBulkUpdates } from "../../clients/Edges/bulkUpdateEdges";
import WithLoading from "../../components/WithLoading/WithLoading";
import { StatusSelect } from "../EdgePage/StatusSelect";
import { TeamSelect } from "../EdgePage/TeamSelect";
import { UserSelect } from "../EdgePage/UserSelect";
import "./BulkEditModal.scss";
import { LabelMultiSelect } from "./LabelMultiSelect";

type BulkChanges = Pick<
  EdgeBulkUpdates,
  "status" | "teamId" | "assignee" | "archived"
> & {
  labels?: ILabel[];
};

interface Props {
  isOpen: boolean;
  isEditing?: boolean;
  edges: IEdgeListing[];
  onClose: () => void;
  onSave: (changes: EdgeBulkUpdates) => void;
}

function BulkEditModal(props: Props): React.ReactElement {
  const { isOpen, isEditing, onClose, onSave } = props;

  const [changes, setChanges] = useState<BulkChanges>({});

  const isDirty = useCallback(
    (key: keyof BulkChanges): boolean => {
      return Object.keys(changes).includes(key);
    },
    [changes]
  );

  const handleSave = (): void => {
    const savedChanges = {
      ...changes,
      labels: changes.labels?.map((l) => ({
        id: l.id,
        type: l.type
      })),
      appendLabels: true
    };

    onSave(savedChanges);
  };

  useEffect(() => {
    if (!isOpen) {
      setChanges({});
    }
  }, [isOpen]);

  return (
    <Modal
      className="bulk-edges"
      title="Bulk Edit"
      width={ModalWidth.MEDIUM}
      isOpen={isOpen}
      onClose={onClose}
    >
      <WithLoading isLoading={!!isEditing}>
        <div className="w-100">
          <label>Labels</label>
          <LabelMultiSelect
            name="label-multi-select"
            placeholder={
              isDirty("labels") && changes.labels?.length
                ? undefined
                : "No change"
            }
            className={classNames({
              dirty: isDirty("labels") && changes.labels?.length
            })}
            value={changes.labels}
            onChange={(labels) =>
              setChanges((prev) => ({
                ...prev,
                labels
              }))
            }
          />
          <div className="subtitle mt-1">
            Labels will be appended to the selected Edges.
          </div>
        </div>
        <div className="w-100 mt-4">
          <label>Team</label>
          <TeamSelect
            value={changes.teamId || undefined}
            placeholder={isDirty("teamId") ? undefined : "No change"}
            className={classNames({ dirty: isDirty("teamId") })}
            clearRowLabel="Unassigned"
            onChange={(teamId) => setChanges((prev) => ({ ...prev, teamId }))}
          />
        </div>
        <div className="d-flex mt-4">
          <div className="w-50 pr-2">
            <label>Assignee</label>
            <UserSelect
              value={changes.assignee?.id}
              placeholder={isDirty("assignee") ? undefined : "No change"}
              className={classNames({ dirty: isDirty("assignee") })}
              clearRowLabel="Unassigned"
              onChange={(val) => {
                const assignee = val ? { id: val } : null;
                setChanges((prev) => ({ ...prev, assignee }));
              }}
            />
          </div>
          <div className="w-50 pl-2">
            <label>Status</label>
            <StatusSelect
              value={changes.status}
              placeholder={isDirty("status") ? undefined : "No change"}
              className={classNames({ dirty: isDirty("status") })}
              onChange={(s) => {
                if (s) {
                  setChanges((prev) => ({ ...prev, status: s }));
                } else {
                  setChanges(({ status, ...prev }) => prev);
                }
              }}
            />
          </div>
        </div>
        <ModalFooter>
          <div className="d-flex justify-content-end">
            <Button
              className="mr-2"
              color="secondary"
              onClick={() => setChanges({})}
            >
              <span>Reset</span>
            </Button>
            <Button onClick={handleSave}>
              <span>Save</span>
            </Button>
          </div>
        </ModalFooter>
      </WithLoading>
    </Modal>
  );
}

export { BulkEditModal };
