import { IEdgeInfo } from "@aptedge/lib-ui/src/types/entities";
import React from "react";
import { useMutation } from "react-query";
import { updateEdge } from "../../clients/Edges/updateEdge";
import { MutationErrorToast } from "../../components/ErrorHandler/MutationErrorToast";
import { LabelsWidget } from "../../components/LabelWidget/LabelsWidget";

interface Props {
  edge: IEdgeInfo;
  onUpdateEdge: (edge: IEdgeInfo) => void;
}

function EdgeLabels(props: Props): React.ReactElement {
  const { edge, onUpdateEdge } = props;

  const labels = [...edge.labels].sort((a, b) => a.name.localeCompare(b.name));

  const edgeMutation = useMutation(updateEdge, { onSuccess: onUpdateEdge });

  return (
    <>
      <LabelsWidget
        labels={labels}
        onChange={(labels) =>
          edgeMutation.mutate({
            edgeId: edge.id,
            patchContent: {
              labels: labels.map((l) => ({ id: l.id, type: l.type }))
            }
          })
        }
        loading={edgeMutation.isLoading}
        disabled={!!edge.archived}
      />
      <MutationErrorToast
        error={edgeMutation.error}
        resetError={edgeMutation.reset}
      />
    </>
  );
}

export { EdgeLabels };
