import { IEdgeAuditLogNameChange } from "@aptedge/lib-ui/src/types/entities";
import React from "react";
import { ArrowTransition } from "./ArrowTransition";
import { SummarySentence } from "./SummarySentence";
import { WithAuditLog } from "./WithAuditLog";
import type { AuditDetailsFC, AuditSummaryFC } from "./WithAuditLog";

const Details: AuditDetailsFC<IEdgeAuditLogNameChange> = (props) => {
  const { log } = props;
  const { oldText, newText } = log.data;

  return (
    <div className="audit-log-name-change">
      <ArrowTransition
        from={<span>{oldText}</span>}
        to={<span>{newText}</span>}
      />
    </div>
  );
};

const Summary: AuditSummaryFC<IEdgeAuditLogNameChange> = (props) => {
  const { author, log } = props;
  const { VERBS, OBJECTS } = SummarySentence;

  return (
    <SummarySentence
      subject={author}
      verb={VERBS.UPDATED}
      object={OBJECTS.NAME}
      displayText={log?.displayText ?? ""}
    />
  );
};

Details.displayName = "AuditNameChangeDetails";
const AuditLogNameChange = WithAuditLog(Details, Summary);

export { AuditLogNameChange };
