import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { ModalFooter } from "@aptedge/lib-ui/src/components/Modal/Modal";
import {
  IEdgeInfo,
  IEdgeSearchResult
} from "@aptedge/lib-ui/src/types/entities";
import classNames from "classnames";
import React from "react";
import { EdgeSearchResult } from "../../../components/EdgeSearchResult/EdgeSearchResult";

interface Props {
  targetEdge: IEdgeInfo;
  edges: IEdgeSearchResult[];
  onBack?: () => void;
  onContinue: () => void;
}

function MergeEdgeModalConfirm(props: Props): React.ReactElement {
  const { edges, targetEdge, onBack, onContinue } = props;

  return (
    <>
      <p className="disclaimer">
        Your selected source Edges will be archived and merged into EDGE-
        {targetEdge.id}. Merging moves all Examples from the source Edges into
        the target Edge. This action cannot be undone.
      </p>
      <div className="merge-edge-content">
        <div className="mt-3">
          <h5>Will merge:</h5>
          {!!edges.length && (
            <div className="list-group edges-selected">
              {edges.map((edge, idx) => (
                <EdgeSearchResult
                  key={idx}
                  edge={edge}
                  isSelected={true}
                  showHighlight={false}
                />
              ))}
            </div>
          )}
        </div>
        <div className="mt-3">
          <h5>Into the following Edge:</h5>
          <div className="mt-4">
            <EdgeSearchResult
              edge={targetEdge}
              isSelected={true}
              showHighlight={false}
            />
          </div>
        </div>
      </div>
      <ModalFooter>
        <div
          className={classNames(
            "d-flex align-items-center",
            !!onBack ? "justify-content-between" : "justify-content-end"
          )}
        >
          {!!onBack && (
            <Button color="secondary" onClick={onBack}>
              Go Back
            </Button>
          )}
          <Button
            color="primary"
            className="justify-self-end"
            onClick={onContinue}
          >
            Merge
          </Button>
        </div>
      </ModalFooter>
    </>
  );
}

export { MergeEdgeModalConfirm };
