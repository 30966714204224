import { IDynamicSource } from "@aptedge/lib-ui/src/types/entities";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

type SliceStateType = {
  dataSource: IDynamicSource[] | null;
};

const initialState: SliceStateType = {
  dataSource: null
};

const dynamicIntegrationSlice = createSlice({
  name: "dynamicIntegrationSlice",
  initialState,
  reducers: {
    setDynamicTapData: (
      state,
      action: PayloadAction<IDynamicSource[] | null>
    ) => {
      state.dataSource = action.payload;
    }
  }
});

export const { setDynamicTapData } = dynamicIntegrationSlice.actions;

export default dynamicIntegrationSlice.reducer;
