import { List } from "@aptedge/lib-ui/src/components/List/List";
import { IEdgeInfo } from "@aptedge/lib-ui/src/types/entities";
import { GTM_EVENTS, dataLayerPush } from "@aptedge/lib-ui/src/utils/event";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { createEdgeComment } from "../../clients/Edges/createEdgeComment";
import { updateEdgeComment } from "../../clients/Edges/updateEdgeComment";
import { EdgeComment } from "./EdgeComment";
import { EdgeNewComment } from "./EdgeNewComment";

const VIEW_MORE = 5;
const DEFAULT_VIEWED = 5;

interface Props {
  edge: IEdgeInfo;
  onUpdate: () => void;
}

const EdgeCommentWidget: React.FC<Props> = ({ edge, onUpdate }) => {
  const [viewing, setViewing] = useState<number>(DEFAULT_VIEWED);
  const sortedComments = [
    ...edge.comments.sort((a, b) => a.createdOn - b.createdOn)
  ];

  const createNewComment = useMutation(createEdgeComment, {
    onSuccess: () => {
      dataLayerPush({ event: GTM_EVENTS.GTM_CREATE_EDGE_COMMENT });
      onUpdate();
    }
  });
  const editComment = useMutation(updateEdgeComment, {
    onSuccess: () => {
      dataLayerPush({ event: GTM_EVENTS.GTM_UPDATE_EDGE_COMMENT });
      onUpdate();
    }
  });

  const onDeleteHandler = (commentId: number): void => {
    dataLayerPush({ event: GTM_EVENTS.GTM_DELETE_EDGE_COMMENT });
    const updatedComments = [...edge.comments];
    const matchedIndex = updatedComments.findIndex((c) => c.id === commentId);

    if (matchedIndex !== -1) {
      updatedComments.splice(matchedIndex, 1);
    }

    onUpdate();
  };

  return (
    <div className="comments-widget">
      <List>
        {viewing < sortedComments.length && (
          <div
            className="view-previous"
            onClick={() => setViewing(viewing + VIEW_MORE)}
          >
            View previous comments
          </div>
        )}
        {sortedComments.slice(-Math.abs(viewing)).map((c) => (
          <EdgeComment
            key={c.id}
            comment={c}
            onEdit={(id, richText) =>
              editComment.mutate({ edgeId: edge.id, commentId: id, richText })
            }
            onDelete={onDeleteHandler}
            disabled={edge.archived}
          />
        ))}
        {!edge.archived && (
          <EdgeNewComment
            submitNewComment={(richText) =>
              createNewComment.mutate({ edgeId: edge.id, richText })
            }
          />
        )}
      </List>
    </div>
  );
};

export { EdgeCommentWidget };
