import { GPTContext } from "@aptedge/lib-ui/src/features/AiAnswer/components/AiAnswer/AiAnswer";
import { useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import { useQueryParams } from "../../../../hooks/useQueryParams";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook/hook";
import { updateAnswerId } from "../../../../redux/reduxSlice/searchSlice";
import { QUERY_PARAMS } from "../../../../types/entities";
import "./ActionButtons.scss";

interface UseActionButtonReturnType {
  answer: string;
  onRefetchClick: () => void;
  isAnswerLoading: boolean;
}

export const useActionButtons = (): UseActionButtonReturnType => {
  const { answer, isAnswerLoading } = useAppSelector(
    (state) => state.answerGPT
  );
  const { refetchAnswer } = useContext(GPTContext);

  const { updateParams } = useQueryParams();

  const dispatch = useAppDispatch();

  const onRefetchClick = (): void => {
    if (typeof refetchAnswer === "function") {
      /* This block of code generates new answerId before refetching answers on regenerate button click */
      const answerId = uuidv4();
      dispatch(updateAnswerId(answerId));
      updateParams(QUERY_PARAMS.ANSWER_ID, answerId);
      refetchAnswer();
    }
  };

  return {
    answer: answer ?? "",
    onRefetchClick,
    isAnswerLoading
  };
};
