import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { Checkbox } from "@aptedge/lib-ui/src/components/Checkbox/Checkbox";
import { AeIcons } from "@aptedge/lib-ui/src/components/Icon/AeIcons";
import { Icon } from "@aptedge/lib-ui/src/components/Icon/Icon";
import { IMSTeamsGroup } from "@aptedge/lib-ui/src/types/entities";
import styled from "@emotion/styled";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons/faTrashAlt";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Menu, MenuItem, Paper } from "@mui/material";
import { FC } from "react";
import { useMSTeamsListItemConfig } from "./useMSTeamsListItemConfig";

interface Props {
  group: IMSTeamsGroup;
  index: number;
  groupList: IMSTeamsGroup[];
  msTeamsHandlers: {
    isDisabled: boolean;
    handleCheckbox: (channelId: string, groupId: string | undefined) => void;
    handleShowChannels: () => void;
    handleDelete: (groupId: string | undefined) => void;
    handleAllSelect: (groupId: string | undefined, index: number) => void;
    handleOnCancel: (index: number) => void;
    isAllSelected: boolean[];
  };
}

const StyledPaper = styled(Paper)`
  width: 48rem;
  max-height: 13.5rem;
`;

const StyledListItem = styled(MenuItem)`
  && {
    display: flex;
    justify-content: space-between;
    font-size: 0.875rem;
  }
`;

const MSTeamsListItem: FC<Props> = (props: Props) => {
  const { group, index, groupList, msTeamsHandlers } = props;

  const {
    isDisabled,
    handleCheckbox,
    handleShowChannels,
    handleDelete,
    handleAllSelect,
    handleOnCancel,
    isAllSelected
  } = msTeamsHandlers;

  const {
    handleUpdateChannels,
    handleClick,
    handleClose,
    anchorEls,
    selectedIndex,
    indexRef
  } = useMSTeamsListItemConfig(handleOnCancel, handleShowChannels, groupList);

  return (
    <div className="channelSelect">
      <div
        className="channelSelectToggle"
        onClick={(e) => handleClick(e, index)}
        ref={indexRef}
      >
        <div>
          <span data-testid="group-name">{group?.groupName}</span>
          {group.isPrivate && (
            <span className="lockIconContainer">
              <AeIcons.Lock />
            </span>
          )}
        </div>
        <span className="icon" data-testid="toggle-arrow-icon">
          {selectedIndex === index ? (
            <KeyboardArrowUpIcon />
          ) : (
            <KeyboardArrowDownIcon />
          )}
        </span>
      </div>
      <div
        className="delete"
        onClick={() => handleDelete(group?.groupId)}
        data-testid="delete-icon"
      >
        <Icon className="trashIcon" icon={faTrashAlt} />
      </div>
      {selectedIndex === index && (
        <Menu
          anchorEl={anchorEls[index]}
          open={selectedIndex === index}
          onClose={() => handleClose(index)}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
          data-testid="menu"
        >
          <StyledPaper>
            <div className="channellistContainer">
              {group?.channels?.map((chn) => (
                <StyledListItem
                  key={chn.channelId}
                  data-testid="channel-list"
                  onClick={() => handleCheckbox(chn.channelId, group.groupId)}
                >
                  <div className="channelSelectItem">
                    <span>{chn.channelName}</span>
                    {chn.isPrivate && (
                      <span className="lockIconContainer">
                        <AeIcons.Lock />
                      </span>
                    )}
                  </div>
                  <div
                    className="checkboxContainer"
                    data-testid="msteams-checkbox"
                  >
                    <Checkbox
                      checked={chn.isSelected}
                      onChange={() =>
                        handleCheckbox(chn.channelId, group.groupId)
                      }
                    />
                  </div>
                </StyledListItem>
              ))}
            </div>
            <div className="btnContainer" key={group.groupId}>
              <div className="selectAll">
                <Button
                  type="submit"
                  color={isAllSelected[index] ? "primary" : "secondary"}
                  onClick={() => handleAllSelect(group.groupId, index)}
                  data-testid="msteams-channel-select-all"
                >
                  <div className="selectAllCheckbox">
                    <Checkbox
                      checked={isAllSelected[index]}
                      onChange={() => handleAllSelect(group.groupId, index)}
                    />
                  </div>
                  {isAllSelected[index] ? "Deselect All" : "Select All"}
                </Button>
              </div>
              <div className="updateChannel">
                <Button
                  data-testid="msteams-update-channel-cancel"
                  type="button"
                  className="mr-2"
                  color="secondary"
                  onClick={() => handleClose(index)}
                >
                  Cancel
                </Button>
                <Button
                  data-testid="msteams-update-channel-save"
                  type="submit"
                  color="primary"
                  onClick={() => handleUpdateChannels()}
                  disabled={isDisabled}
                >
                  Update & Save
                </Button>
              </div>
            </div>
          </StyledPaper>
        </Menu>
      )}
    </div>
  );
};

export { MSTeamsListItem };
