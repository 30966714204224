import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { ModalWidth } from "@aptedge/lib-ui/src/components/Modal/Modal";
import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import {
  IntegrationStatus,
  ISlackSocialSource,
  ISlackStatus
} from "@aptedge/lib-ui/src/types/entities";
import { GATEWAY_TIMEOUT } from "http-status-codes";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { ToastType } from "react-toastify";
import { createSlackSocialSource } from "../../../clients/SocialSource/createSlackSettings";
import { ConfirmationModal } from "../../../components/ConfirmationModal/ConfirmationModal";
import { Toast } from "../../../components/Toast/Toast";
import WithLoading from "../../../components/WithLoading/WithLoading";
import { getBackendUrl } from "../../../config";
import { ClientStatus } from "../../../types/clients";
import { AddButton } from "../common/AddButton";
import { StatusButton } from "../common/StatusButton";
import { SlackChannelList } from "./SlackChannelList";
import { SlackChannelsConfig } from "./SlackChannelsConfig";

interface Props {
  slackResource: ISlackSocialSource | null;
  onChange: () => void;
  isLoading: boolean;
  refetchSlackTap: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
}

const SlackConfig: React.FunctionComponent<Props> = (props: Props) => {
  const { slackResource, onChange, isLoading, error } = props;

  const [showModal, setShowModal] = useState<boolean>(false);
  const [status, setStatus] = useState(ClientStatus.PROMPT);
  const slackSocialResource = slackResource ?? null;
  const isAutheticated =
    slackSocialResource?.status === ISlackStatus.AUTHENTICATED;

  const addSource = useMutation(createSlackSocialSource, {
    onError: () => {
      setShowModal(false);
      setStatus(ClientStatus.ERROR);
    },
    onSuccess: () => {
      setStatus(ClientStatus.SUCCESS);
      const url = `${getBackendUrl()}/slack/login`;
      window.location.href = url;
    }
  });

  const handleSlackLogin = (): void => {
    setStatus(ClientStatus.LOADING);
    addSource.mutate({});
  };

  const handleAddSlack = (): void => {
    setShowModal(true);
  };

  const getStatus = (): IntegrationStatus => {
    if (error?.code === GATEWAY_TIMEOUT) return "timeOut";
    if (isAutheticated) return "ok";
    return "error";
  };

  const isConnectionExist = isAutheticated || getStatus() === "timeOut";
  const isLoaderVisible = status === ClientStatus.LOADING || isLoading;

  return (
    <>
      <div className="mb-3">
        <h5>Slack Config</h5>
        <p>Configure your Slack settings below.</p>
      </div>
      <WithLoading
        isLoading={isLoaderVisible}
        fallback={
          <div className="d-flex justify-content-center m-4">
            <Spinner />
          </div>
        }
      >
        {isConnectionExist ? (
          <StatusButton status={getStatus()} onClickEdit={handleAddSlack}>
            <>
              <div>Slack</div>
              <div className="text-muted">Slack Settings</div>
            </>
          </StatusButton>
        ) : (
          <AddButton onClick={handleAddSlack}>Connect</AddButton>
        )}
      </WithLoading>
      <ConfirmationModal
        title="Slack OAuth Settings"
        width={ModalWidth.MEDIUM}
        loadingTitle="Loading..."
        status={status}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      >
        <p className="mb-4">Please authorize aptedge to configure slack</p>
        <div className="float-right">
          <Button onClick={handleSlackLogin}>Authorize</Button>
        </div>
      </ConfirmationModal>
      {slackSocialResource?.status === ISlackStatus.AUTHENTICATED && (
        <>
          <SlackChannelsConfig
            source={slackSocialResource}
            onChange={onChange}
          />
          <SlackChannelList
            channelList={slackSocialResource?.channels ?? []}
            onChange={onChange}
          />
        </>
      )}
      {addSource.isError && (
        <Toast type={ToastType.ERROR}>Something went wrong.</Toast>
      )}
    </>
  );
};

SlackConfig.defaultProps = {
  slackResource: null
};

export { SlackConfig };
