import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import {
  IRichTextContent,
  TextEditor
} from "@aptedge/lib-ui/src/components/TextEditor/TextEditor";
import { useSentry } from "@aptedge/lib-ui/src/context/SentryContext";
import {
  ISocialComment,
  ITemplate,
  ITicketInfo
} from "@aptedge/lib-ui/src/types/entities";
import { sanitizeSnippet } from "@aptedge/lib-ui/src/utils/generator";
import classNames from "classnames";
import Linkify from "linkify-react";
import { IntermediateRepresentation } from "linkifyjs";
import { FC } from "react";
import ReactMarkdown from "react-markdown";
import sanitizeHtml from "sanitize-html";
import { displayAge } from "../../../../src/utils/time";
import WithLoading from "../../../components/WithLoading/WithLoading";
import TicketPreviewContent from "./TicketPreviewContent";
/* eslint-disable @typescript-eslint/no-var-requires */
const jira2md = require("jira2md");
const rehypeRaw = require("rehype-raw").default;
const remarkGfm = require("remark-gfm").default;

interface SearchPreviewType {
  renderMultiSourceTypeElement: JSX.Element;
  renderMarkdownElement: JSX.Element;
  renderJiraElement: JSX.Element;
  renderTicketElement: JSX.Element;
  renderDocumentElement: JSX.Element;
  renderSocialElement: JSX.Element;
}

const sanitizeConfig = {
  allowedAttributes: {
    "*": ["class"]
  }
};

const useRenderSearchPreview = (
  description: string | undefined,
  summary: IRichTextContent | null | undefined,
  url: string,
  template: ITemplate | undefined,
  ticketIsLoading: boolean,
  socialIsLoading: boolean,
  ticket: ITicketInfo | undefined,
  social: ISocialComment | undefined
): SearchPreviewType => {
  const sentry = useSentry();

  const isSummaryPresent = summary || template?.content;

  const isTicketCommentsPresent =
    ticket?.comments && Boolean(ticket?.comments.length);

  const isSocialCommentsPresent =
    social?.comments && Boolean(social?.comments.length);

  const NewRenderLink: FC<IntermediateRepresentation> = (props) => {
    const { attributes, content } = props;
    return (
      <a
        href={attributes.href}
        target={attributes.target}
        {...props}
        className="preview-link"
      >
        {content}
      </a>
    );
  };

  const renderMultiSource = (multiSource?: string): JSX.Element => (
    <Linkify
      options={{
        render: NewRenderLink,
        target: "_blank"
      }}
    >
      <span
        className="search-result-preview-text"
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(
            multiSource ||
              sanitizeSnippet(sentry, url, sanitizeConfig, description),
            sanitizeConfig
          )
        }}
      />
    </Linkify>
  );

  const renderMultiSourceTypeElement = renderMultiSource();

  const renderMarkdown = (markdown: string): JSX.Element => (
    <div
      className={classNames("rendered", "rendered-img", { "read-only": true })}
    >
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeRaw]}
        components={{
          a: (props) => {
            return (
              <a
                href={props.href}
                className="preview-link"
                target="_blank"
                rel="noreferrer"
              >
                {props.children[0]}
              </a>
            );
          }
        }}
      >
        {markdown}
      </ReactMarkdown>
    </div>
  );

  const renderMarkdownElement = renderMarkdown(
    sanitizeSnippet(sentry, url, sanitizeConfig, description)
  );
  const renderJiraElement = renderMarkdown(
    jira2md.to_markdown(
      sanitizeSnippet(sentry, url, sanitizeConfig, description)
    )
  );

  const renderTicketElement = (
    <>
      <p className="ticket-header">Notes</p>
      {isSummaryPresent ? (
        <TextEditor
          content={summary}
          templateText={template?.content}
          readOnly={true}
          placeholder=""
          isImageDropAllowed={false}
        />
      ) : (
        <p className="no-data">No notes available...</p>
      )}
      {isTicketCommentsPresent && <hr />}
      <WithLoading isLoading={ticketIsLoading} fallback={<Spinner />}>
        <TicketPreviewContent ticket={ticket} />
      </WithLoading>
    </>
  );

  const renderDocumentElement = (
    <div className={classNames("rendered", { "read-only": true })}>
      <iframe title={url} src={url}></iframe>
    </div>
  );

  const renderSocialElement = (
    <>
      <WithLoading isLoading={socialIsLoading} fallback={<Spinner />}>
        <div>
          <span className="comment-author">{social?.name}</span>
          <span className="comment-age">{displayAge(social?.updatedOn)}</span>
        </div>
        <span className="social-body">{renderMultiSource(social?.body)}</span>
        {isSocialCommentsPresent &&
          social?.comments.map((comment) => {
            return (
              <div key={comment.id} className="comment-container">
                <div>
                  <span className="comment-author">{comment.name}</span>
                  <span className="comment-age">
                    {displayAge(social?.updatedOn)}
                  </span>
                </div>
                <span className="social-thread">
                  {renderMultiSource(comment.body)}
                </span>
              </div>
            );
          })}
      </WithLoading>
    </>
  );

  return {
    renderMultiSourceTypeElement,
    renderMarkdownElement,
    renderJiraElement,
    renderTicketElement,
    renderDocumentElement,
    renderSocialElement
  };
};

export default useRenderSearchPreview;
