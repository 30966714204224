import { getBackendApiUrl } from "../../config";
import { ILoginResponse, IUserCredentials } from "../../types/clients";
import { request } from "../../utils/request";
import { post } from "../utils/buildRequest";

async function login(credentials: IUserCredentials): Promise<ILoginResponse> {
  const url = `${getBackendApiUrl()}/login/`;
  const options = post(credentials);

  return request(url, options);
}

export { login };
