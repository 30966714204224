import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { useAppSelector } from "@aptedge/lib-ui/src/redux/hook/hook";
import { useFormik } from "formik";
import React from "react";
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label
} from "reactstrap";
import * as Yup from "yup";

export interface NotionConfigFormData {
  accessToken: string;
}

interface Props {
  onSubmit: (formData: NotionConfigFormData) => void;
}

const NotionConfigForm: React.FunctionComponent<Props> = ({ onSubmit }) => {
  const notionSource = useAppSelector((state) => state.notionArticleSlice);
  const source = notionSource?.dataSource ?? null;

  const formik = useFormik<NotionConfigFormData>({
    validationSchema: Yup.object({
      accessToken: Yup.string().required("Please enter a access token.")
    }),
    initialValues: {
      accessToken: (source && source.accessToken) || ""
    },
    validateOnChange: false,
    onSubmit: (values: NotionConfigFormData) =>
      onSubmit({ accessToken: values.accessToken.trim() })
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <FormGroup row>
        <Label sm={3} for="clientSecret">
          Access Token
        </Label>
        <Col sm={9}>
          <Input
            type="text"
            name="accessToken"
            placeholder=""
            value={formik.values.accessToken}
            onChange={formik.handleChange}
            invalid={!!formik.errors.accessToken}
          />
          <FormFeedback>{formik.errors.accessToken}</FormFeedback>
          <FormText>Access Token provided by Notion</FormText>
        </Col>
      </FormGroup>
      <div className="float-right">
        <Button type="submit">Authorize</Button>
      </div>
    </Form>
  );
};

export { NotionConfigForm };
