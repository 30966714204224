import { Search } from "@aptedge/lib-ui/src/components/Search/Search";
import { SuggestSearchList } from "@aptedge/lib-ui/src/components/SuggestSearchList/SuggestSearchList";
import { useAppSelector } from "@aptedge/lib-ui/src/redux/hook/hook";
import classNames from "classnames";
import { RefObject } from "react";
import styles from "./AutoCompletes.module.scss";
import useAutoComplete from "./hooks/useAutoComplete";

interface Props {
  handleActiveRefForSearchList: (val: boolean) => void;
  searchRef: RefObject<HTMLInputElement>;
}

const AutoComplete: React.FC<Props> = ({
  searchRef,
  handleActiveRefForSearchList
}) => {
  const {
    searchQuery,
    searchSuggestions: list,
    showSuggestion
  } = useAppSelector((state) => state.search);

  const isSearchSuggestionVisible = list.length > 0 && showSuggestion;

  const {
    autoCompleteContainerRef,
    listBoxRef,
    highlighted,
    handleSearchQueryParamsOnSuggestions
  } = useAutoComplete(handleActiveRefForSearchList);

  return (
    <div className={styles.autoCompleteSuggestionWrapper}>
      <div ref={isSearchSuggestionVisible ? autoCompleteContainerRef : null}>
        <Search
          className={classNames(styles.autoCompleteSearch, {
            [styles.searchBottomBorder]: !isSearchSuggestionVisible,
            [styles.autoCompleteBorder]: isSearchSuggestionVisible
          })}
          inputRef={searchRef}
          autoFocus={true}
        />
      </div>
      {isSearchSuggestionVisible && (
        <div className={styles.suggestSearchWrapper}>
          <div ref={listBoxRef} className={styles.suggestSearchResults}>
            {[searchQuery, ...list].map((item: string, index: number) => (
              <SuggestSearchList
                isHidden={item === searchQuery}
                result={item}
                key={`${item}-${index}`}
                isSelected={highlighted === index}
                handleSearchQueryParamsOnSuggestions={
                  handleSearchQueryParamsOnSuggestions
                }
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export { AutoComplete };
