import classNames from "classnames";
import React from "react";
import { findChildByType } from "../../utils/reactUtils";
import "./ActionBar.scss";

const Left: React.FC = ({ children }) => <>{children}</>;
const Right: React.FC = ({ children }) => <>{children}</>;
const Center: React.FC = ({ children }) => <>{children}</>;

Left.displayName = "ActionBarLeft";
Right.displayName = "ActionBarRight";
Center.displayName = "ActionBarCenter";

const Container: React.FunctionComponent<Props> = ({ className, children }) => {
  const leftSlot = findChildByType(children, Left);
  const rightSlot = findChildByType(children, Right);
  const centerSlot = findChildByType(children, Center);

  return (
    <div className={classNames("action-bar", className)}>
      {leftSlot && <div className="left">{leftSlot}</div>}
      {rightSlot && <div className="right">{rightSlot}</div>}
      {centerSlot && <div className="center">{centerSlot}</div>}
    </div>
  );
};

interface Props {
  className?: string;
}

const ActionBar = {
  Container,
  Left,
  Right,
  Center
};

export { ActionBar };
