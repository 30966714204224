import {
  IQueryFilter,
  ITimeDimensionFilter
} from "@aptedge/lib-ui/src/components/Filter/types";
import { AeIcons } from "@aptedge/lib-ui/src/components/Icon/AeIcons";
import classNames from "classnames";
import "./styles.scss";
import { useContext, useEffect } from "react";
import { AutoLinkContext } from "../../../../../src/context/AutoLinkContext";

const SORT_FILTERS = [
  { label: "Created On", key: "createdOn", Icon: AeIcons.CreatedOn },
  { label: "Updated On", key: "updatedOn", Icon: AeIcons.UpdatedOn }
];

type SortType = "createdOn" | "updatedOn";

interface IProps {
  selectedTimeDimension?: ITimeDimensionFilter;
  filters?: IQueryFilter[];
}
const CreatedUpdatedSwitch: React.FC<IProps> = (props) => {
  const {
    setSelectedTimeDimensionFilter,
    selectedTimeDimensionFilter
  } = useContext(AutoLinkContext);

  const selectedTimeDimension = props?.selectedTimeDimension;

  useEffect(() => {
    if (selectedTimeDimension?.id)
      setSelectedTimeDimensionFilter(selectedTimeDimension?.id);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTimeDimension?.id]);

  useEffect(() => {
    const savedFilters = props?.filters?.find(
      (filter) => filter.id === "timestamp"
    );
    if (!!savedFilters?.values[0])
      setSelectedTimeDimensionFilter(savedFilters?.values[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="switchContainer">
      {SORT_FILTERS.map((filter) => (
        <button
          key={filter.key}
          onClick={() => setSelectedTimeDimensionFilter(filter.key as SortType)}
          className={classNames({
            active: selectedTimeDimensionFilter === filter.key
          })}
        >
          <filter.Icon />
          <span className={filter.key}>{filter.label}</span>
        </button>
      ))}
    </div>
  );
};

export default CreatedUpdatedSwitch;
