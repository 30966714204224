import { SearchResultPreview } from "@aptedge/lib-support-ui/src/components/SearchResultPreview";
import { Tooltip } from "@aptedge/lib-ui/src/components/Tooltip/Tooltip";
import { ITicketInfo } from "@aptedge/lib-ui/src/types/entities";
import he from "he";
import { Highlight } from "../../../components/Highlight/Highlight";
import { TicketFrustrationInfo } from "../../../components/TicketContent/TicketFrustrationInfo";
import { TicketThread } from "../../../components/TicketContent/TicketThread";

interface Props {
  ticket: ITicketInfo | undefined;
}

function TicketPreviewContent(props: Props): React.ReactElement {
  const { ticket } = props;

  const jiraReferences =
    ticket?.urlReferences?.filter((j) => !!j.productIssueId) || [];
  const urls = jiraReferences.map((j) => j.url) || [];

  const searchWords = ticket?.frustration ? [ticket.frustration.text] : [];

  const isTicketCommentsPresent =
    ticket?.comments && Boolean(ticket?.comments.length);

  const isTicketDescriptionPresent = Boolean(ticket?.description);

  if (!ticket) return <></>;

  return (
    <div className="thread">
      <div className="main-section description">
        <p className="ticket-header" data-testid="ticket-description">
          Support Ticket Description
        </p>
        <div className="description-content">
          {isTicketDescriptionPresent ? (
            <>
              {ticket.description.includes("offset-highlight") ? (
                <pre>
                  <SearchResultPreview previewSnippet={ticket.description} />
                </pre>
              ) : (
                <pre>
                  <Highlight
                    autoEscape={true}
                    searchWords={searchWords}
                    textToHighlight={ticket.description}
                    urls={urls}
                    highlightTag={(props) => {
                      return (
                        <Tooltip
                          content={
                            <TicketFrustrationInfo
                              highlightedText={props.children}
                              frustration={ticket.frustration}
                            />
                          }
                          placement="top-end"
                        >
                          <span className="highlighted">
                            {he.decode(props.children)}
                          </span>
                        </Tooltip>
                      );
                    }}
                  />
                </pre>
              )}
            </>
          ) : (
            <p className="no-data">
              No support ticket description available...
            </p>
          )}
        </div>
      </div>
      <hr />
      <div className="main-section conversation">
        <p className="ticket-header" data-testid="ticket-conversation">
          Support Ticket Conversation
        </p>
        {isTicketCommentsPresent ? (
          <TicketThread ticket={ticket} />
        ) : (
          <p className="no-data">No support ticket conversation initiated...</p>
        )}
      </div>
    </div>
  );
}

export default TicketPreviewContent;
