import { Tooltip } from "@aptedge/lib-ui/src/components/Tooltip/Tooltip";
import { ITicketListing } from "@aptedge/lib-ui/src/types/entities";
import React from "react";
import { useUsers } from "../../hooks/useUsers";
import { displayAge } from "../../utils/time";

interface Props {
  ticket: ITicketListing;
  edgeId: number;
}

const LinkedBadge: React.FC<Props> = React.memo(function LinkedBadge({
  ticket,
  edgeId
}) {
  const { data: users } = useUsers();

  const edgeMetadata = React.useMemo(
    () => ticket.edgesMetadata?.find((m) => m.edgeId === edgeId),
    [edgeId, ticket.edgesMetadata]
  );
  const isAutoLinked =
    !!edgeMetadata?.filterId ||
    !!edgeMetadata?.issueId ||
    !!edgeMetadata?.urlReferenceId;
  const linkedTicketUserId = !!edgeMetadata?.userId
    ? edgeMetadata.userId
    : undefined;
  const userEmail = React.useMemo(
    () =>
      !!linkedTicketUserId
        ? users?.find((u) => u.id === linkedTicketUserId)?.email
        : undefined,
    [linkedTicketUserId, users]
  );
  const displayDateLinked = !!edgeMetadata?.linkedOn
    ? ` on ${displayAge(edgeMetadata.linkedOn, {}, true)}`
    : null;

  const renderLinkedBadge = (): React.ReactNode => {
    if (isAutoLinked) {
      return <span>Auto linked{displayDateLinked}</span>;
    } else if (!!userEmail) {
      return (
        <Tooltip content={`Linked by ${userEmail}`}>
          <span>Linked{displayDateLinked}</span>
        </Tooltip>
      );
    } else if (!!edgeMetadata) {
      return <span>Linked{displayDateLinked}</span>;
    } else {
      return null;
    }
  };

  if (!renderLinkedBadge()) {
    return null;
  }

  return (
    <>
      {renderLinkedBadge()}
      <span className="mx-1">|</span>
    </>
  );
});

export { LinkedBadge };
