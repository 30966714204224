import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { Dot } from "@aptedge/lib-ui/src/components/Dot/Dot";
import { useModalRouter } from "@aptedge/lib-ui/src/components/Modal/ModalRouter";
import {
  fromLZCompressedString,
  IQueryArgs
} from "@aptedge/lib-ui/src/utils/query";
import React, { useContext } from "react";
import { AutoLinkContext } from "../../../context/AutoLinkContext";
import { IPaginatedRequest } from "../../../types/clients";
import { EdgeListingQueryParams } from "../../EdgesListingPage/useEdgeListingParams";
import "./AutoLinkButton.scss";
import { Page } from "./AutoLinkRoutes";

interface Props {
  onChange: (partial: Partial<EdgeListingQueryParams>) => void;
}

const AutoLinkButton: React.FC<Props> = ({ onChange }) => {
  const { setIsEditing, currentSavedQuery } = useContext(AutoLinkContext);
  const router = useModalRouter();

  const handleLink = (): void => {
    if (!!currentSavedQuery) {
      const newParams = fromLZCompressedString<IPaginatedRequest<IQueryArgs>>(
        currentSavedQuery.query
      );
      onChange({
        filters: newParams.filters,
        timeDimensions: newParams.timeDimensions
      });

      setIsEditing(false);
      router.push(Page.PREVIEW);
    } else {
      router.push(Page.CONDITIONS);
    }
  };

  return (
    <span className="auto-linker" onClick={handleLink}>
      {!!currentSavedQuery && <Dot backgroundColor="danger" />}
      <Button>Auto</Button>
    </span>
  );
};

export { AutoLinkButton };
