import { getBackendUrl } from "../../../config";
import { MSTeamsConfigFormData } from "../../../pages/SettingsPage/Integrations/MSTeams/MSTeamsConfigForm";
import { request } from "../../../utils/request";
import { patch } from "../../utils/buildRequest";

async function updateMSTeamsConnection(
  args: MSTeamsConfigFormData
): Promise<MSTeamsConfigFormData> {
  const url = `${getBackendUrl()}/api/integrations/msteams`;
  const options: RequestInit = patch(args);
  return request(url, options);
}

export { updateMSTeamsConnection };
