import classNames from "classnames";
import { FC } from "react";
import ProgressBar from "../../../common/ProgressBar/ProgressBar";
import SectionLayout from "../../../common/SectionLayout/SectionLayout";
import ConfigInput from "../../ConfigInput/ConfigInput";
import ConfigInputField from "../../ConfigInput/ConfigInputField/ConfigInputField";
import {
  ConfigProps,
  SELF_SERVICE_REMAINING_SEARCHES,
  SELF_SERVICE_USAGE_LIMIT
} from "../../utils/configFieldKeys";
import styles from "./styles.module.scss";

const Limits: FC<ConfigProps> = ({
  config,
  originalConfig,
  isLoading,
  handleConfigChange
}) => {
  const remainingSearches = config?.[SELF_SERVICE_REMAINING_SEARCHES] || 0;
  const usageLimit = originalConfig?.[SELF_SERVICE_USAGE_LIMIT] || 0;
  const remainingSearchesPercentage = (
    100 - Number((remainingSearches / usageLimit) * 100)
  ).toFixed(2);

  const isUsageLimitReached = remainingSearches === 0;

  return (
    <SectionLayout title="Limits">
      <ConfigInput
        title="This month’s usage"
        subTitle={`${remainingSearches} of ${usageLimit} queries used`}
      >
        <div className={styles.progressBarContainer}>
          {isUsageLimitReached ? (
            <div
              className={classNames(styles.title, {
                [styles.warningTitle]: isUsageLimitReached
              })}
            >
              The Self Service app is disabled. You have used 100% of your
              monthly budget.
            </div>
          ) : (
            <div className={styles.title}>
              You have used {remainingSearchesPercentage}% of your monthly
              budget.
            </div>
          )}
          <ProgressBar
            remainingSearchesPercentage={remainingSearchesPercentage}
            isUsageLimitReached={isUsageLimitReached}
          />
        </div>
      </ConfigInput>

      <ConfigInput
        title="Monthly budget"
        description="The Self Service app will be automatically disabled once the limit is reached."
        className={styles.topSpacing}
      >
        <ConfigInputField
          name={SELF_SERVICE_USAGE_LIMIT}
          onChange={handleConfigChange}
          value={config?.[SELF_SERVICE_USAGE_LIMIT] ?? 0}
          leadingText="Queries per month"
          isError={Number(config?.[SELF_SERVICE_USAGE_LIMIT]) > 3000}
          errorMsg="Your monthly budget can't exceed the total usage limit of 3,000 queries."
          attrs={{
            type: "number",
            disabled: isLoading
          }}
        />
      </ConfigInput>
      <ConfigInput title="Usage limit" className={styles.topSpacing}>
        <div className={styles.ussageContainer}>
          <span>
            The maximum number of queries available for your organization.
            Contact support to increase this limit.
          </span>
          <h5>3000 queries per month</h5>
        </div>
      </ConfigInput>
    </SectionLayout>
  );
};

export default Limits;
