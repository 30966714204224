import AEIcon from "@aptedge/lib-ui/src/components/GMIcon/Icon";
import {
  ArticleStatus,
  GeneratedKnowledgeResult
} from "@aptedge/lib-ui/src/types/entities";
import DownloadDoneIcon from "@mui/icons-material/DownloadDone";
import PlagiarismOutlinedIcon from "@mui/icons-material/PlagiarismOutlined";
import classNames from "classnames";
import { FC } from "react";
import { useArticleActions } from "../../hooks/useArticleActions";
import styles from "./ArticleActions.module.scss";
import { DiscardModal, DiscardSource } from "./DiscardModal";

type ArticleActionsProps = {
  article: GeneratedKnowledgeResult;
  title: string;
  body: string;
  discardSource?: DiscardSource;
  handleClose?: () => void;
};

const ArticleActions: FC<ArticleActionsProps> = ({
  article,
  handleClose,
  title,
  body,
  discardSource
}) => {
  const {
    isDiscardOpen,
    dropdownRef,
    handleSaveArticle,
    handleShowDiscard,
    handleHideDiscard,
    handleDeleteArticle
  } = useArticleActions(article, title, body, handleClose);

  return (
    <div
      className={classNames(styles.articleActions, {
        [styles.articleActionsReviewPage]:
          discardSource === DiscardSource.ARTICLE_REVIEW_PAGE ||
          discardSource === DiscardSource.ARTICLE_POPUP
      })}
      data-testid="article-actions"
    >
      <button
        onClick={handleSaveArticle}
        className={styles.saveArticleButton}
        data-testid="save-article"
      >
        {article.status === ArticleStatus.DRAFT ? (
          <PlagiarismOutlinedIcon />
        ) : (
          <DownloadDoneIcon />
        )}
        {article.status === ArticleStatus.DRAFT ? "Submit for review" : "Save"}
      </button>
      <button
        onClick={handleShowDiscard}
        className={styles.discardButton}
        data-testid="discard-article"
      >
        <AEIcon name="delete" size="1.5rem" />
        Discard this article
      </button>
      {isDiscardOpen && (
        <div ref={dropdownRef} className={styles.discardModal}>
          <DiscardModal
            handleHideDiscard={handleHideDiscard}
            handleDeleteArticle={handleDeleteArticle}
            discardSource={DiscardSource.ARTICLE_POPUP}
          />
        </div>
      )}
    </div>
  );
};

export { ArticleActions };
