import {
  NoData,
  NoDataMessage
} from "@aptedge/lib-ui/src/components/NoData/NoData";
import SearchSkeletonLoader from "@aptedge/lib-ui/src/components/SearchSkeletonLoader/SearchSkeletonLoader";
import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import { useAppSelector } from "@aptedge/lib-ui/src/redux/hook/hook";
import { ICompositeResult } from "@aptedge/lib-ui/src/types/entities";
import { FC } from "react";
import WithLoading from "../../WithLoading/WithLoading";
import { GlobalSearchItem } from "../GlobalSearchItem/GlobalSearchItem";
import "./GlobalSearchList.scss";
import { IUseArrowNavigationSL } from "./hooks/useArrowNavigationSL";

const shouldFirstCardHighlighted = (
  resultId: string | number,
  idx: number,
  highlighted: number,
  selectedSearchResult: ICompositeResult
): boolean => {
  return highlighted === idx || selectedSearchResult?.id === resultId;
};

interface Props {
  arrowNavigation: Omit<IUseArrowNavigationSL, "searchContainerRef">;
}

const GlobalSearchList: FC<Props> = ({ arrowNavigation }) => {
  const {
    getListBoxProps,
    getListItemProps,
    listBoxRef,
    highlighted,
    recentSearches
  } = arrowNavigation;

  const {
    searchQuery,
    searchResults,
    selectedSearchResult,
    isSearchLoading
  } = useAppSelector((state) => state.search);

  const { flags } = useFlags();

  return (
    <div
      ref={listBoxRef}
      id="global-search-results"
      {...getListBoxProps()}
      className="global-search-results"
    >
      {searchQuery || isSearchLoading || Boolean(searchResults.length) ? (
        <WithLoading
          isLoading={isSearchLoading}
          fallback={<SearchSkeletonLoader lines={5} flags={flags} />}
        >
          {Boolean(searchResults.length) ? (
            [null, ...searchResults].map((result, idx) => {
              if (result === null) {
                return <div key={idx} />;
              } else {
                return (
                  <GlobalSearchItem
                    result={result}
                    key={`${result.id}`}
                    isSelected={shouldFirstCardHighlighted(
                      result.id,
                      idx,
                      highlighted,
                      selectedSearchResult
                    )}
                    {...getListItemProps(idx)}
                  />
                );
              }
            })
          ) : (
            <NoData message={NoDataMessage.SEARCH_RESULTS} />
          )}
        </WithLoading>
      ) : (
        <WithLoading
          isLoading={isSearchLoading}
          fallback={<SearchSkeletonLoader lines={5} flags={flags} />}
        >
          {!!recentSearches?.length ? (
            [null, ...recentSearches]?.map((result, idx) => {
              if (result === null) {
                return <div key={idx} />;
              } else {
                return (
                  <GlobalSearchItem
                    result={result}
                    key={`${result.id}`}
                    isSelected={shouldFirstCardHighlighted(
                      result.id,
                      idx,
                      highlighted,
                      selectedSearchResult
                    )}
                    {...getListItemProps(idx)}
                  />
                );
              }
            })
          ) : (
            <NoData message={NoDataMessage.NO_RECENT_SEARCHRES} />
          )}
        </WithLoading>
      )}
    </div>
  );
};

export { GlobalSearchList };
