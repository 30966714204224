import { IBulkReply } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { IBulkReplyCreateRequest } from "../../types/clients";
import { request } from "../../utils/request";
import { post } from "../utils/buildRequest";

async function createEdgeBulkReply(
  args: { edgeId: number } & IBulkReplyCreateRequest
): Promise<IBulkReply> {
  const { edgeId, ...body } = args;
  const url = `${getBackendApiUrl()}/edges/${edgeId}/bulk-reply/`;
  const options = post(body);

  return request(url, options);
}

export { createEdgeBulkReply };
