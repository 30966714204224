import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";

function fetchAgentEmailDomainConfig(): Promise<string[]> {
  const url = `${getBackendApiUrl()}/config/AGENT_EMAIL_DOMAINS/`;

  return request<string[]>(url);
}

export { fetchAgentEmailDomainConfig };
