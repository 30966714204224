import Cookies from "js-cookie";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { saveUserToLocalStorage } from "../../../clients/User/utils";
import { post } from "../../../clients/utils/buildRequest";
import { getBackendUrl, isLocal } from "../../../config";
import {
  GOOGLE_OAUTH_FLOW,
  MS_OAUTH_FLOW,
  OAUTH_REDIRECT_URL
} from "../../../types/clients";
import { APTEDGE_COOKIE_DOMAIN } from "../../../utils/oauth";
import { rawRequest } from "../../../utils/request";

interface IUSEOAUTH {
  statusCode: number;
  isExternal: boolean;
}
enum ProviderEnum {
  MS_OAUTH = "ms-oauth",
  ONE_LOGIN = "onelogin",
  GOOGLE_OAUTH = "g-oauth"
}

const useOAuth = (): IUSEOAUTH => {
  const [statusCode, setStatusCode] = useState(200);
  const [isExternal, setIsExternal] = useState(false);
  const { provider } = useParams<{ provider: string }>();

  const processMSOAuth = useCallback(async (): Promise<void> => {
    if (provider !== ProviderEnum.MS_OAUTH) return;
    const redirectUrl = await Cookies.get(OAUTH_REDIRECT_URL);
    await Cookies.remove(OAUTH_REDIRECT_URL, {
      domain: APTEDGE_COOKIE_DOMAIN
    });
    const { flow } = await JSON.parse(Cookies.get(MS_OAUTH_FLOW) ?? "");

    const { pathname, search } = window.location;
    const url = new URLSearchParams(search);
    if (redirectUrl) {
      if (isLocal())
        window.location.href = `http://${redirectUrl}${pathname}${search}`;
      else window.location.href = `https://${redirectUrl}${pathname}${search}`;
    } else {
      if (!url.get("code")) return;
      const option = post({ flow });
      const res = await rawRequest(
        `${getBackendUrl()}/ms-oauth/getatoken${search}`,
        option
      );
      const status = res.status;
      setStatusCode(status);
      const data = await res.json();
      await saveUserToLocalStorage(data);
      if (status === 200) {
        await Cookies.remove(MS_OAUTH_FLOW, {
          domain: APTEDGE_COOKIE_DOMAIN
        });

        await window.open("/", "_self");
      }
    }
    if (!redirectUrl && !url.get("code")) window.history.back();
  }, [provider]);

  const processGoogleOAuth = useCallback(async (): Promise<void> => {
    if (provider !== ProviderEnum.GOOGLE_OAUTH) return;
    const redirectUrl = await Cookies.get(OAUTH_REDIRECT_URL);
    await Cookies.remove(OAUTH_REDIRECT_URL, {
      domain: APTEDGE_COOKIE_DOMAIN
    });
    const expiry = 0.01; // 15 min in terms of Day

    await Cookies.set(GOOGLE_OAUTH_FLOW, JSON.stringify(window.location.href), {
      expires: expiry,
      domain: APTEDGE_COOKIE_DOMAIN
    });

    const { pathname, search } = window.location;
    const url = new URLSearchParams(search);
    if (redirectUrl) {
      if (isLocal())
        window.location.href = `http://${redirectUrl}${pathname}${search}`;
      else window.location.href = `https://${redirectUrl}${pathname}${search}`;
    } else {
      if (!url.get("code")) return;
      const finalUrl = await Cookies.get(GOOGLE_OAUTH_FLOW);
      const option = post({ url: finalUrl, code: url.get("code") });
      const res = await rawRequest(
        `${getBackendUrl()}/g-oauth/getatoken`,
        option
      );
      const status = res.status;
      setStatusCode(status);
      const data = await res.json();
      await saveUserToLocalStorage(data);
      if (status === 200) {
        await Cookies.remove(GOOGLE_OAUTH_FLOW, {
          domain: APTEDGE_COOKIE_DOMAIN
        });

        await window.open("/", "_self");
      }
    }
    if (!redirectUrl && !url.get("code")) window.history.back();
  }, [provider]);

  const processOneLogin = useCallback(async (): Promise<void> => {
    if (provider !== "onelogin") return;

    const { pathname, search, hostname: location } = window.location;

    const queryParams = new URLSearchParams(search.replaceAll("+", "%2B"));

    const redirectUrl = Cookies.get(OAUTH_REDIRECT_URL) ?? "";
    if (!redirectUrl) return setIsExternal(true);

    if (!location.trim().includes(redirectUrl.trim())) {
      const url = `${redirectUrl}${pathname}${search}`;
      window.open(isLocal() ? `http://${url}` : `https://${url}`, "_self");
    } else {
      const oauthId = queryParams.get("id");
      try {
        const res = await fetch(
          `${getBackendUrl()}/onelogin/tenant_redirect?id=${oauthId}`
        );
        const data = await res.json();
        saveUserToLocalStorage(data);
        Cookies.set(OAUTH_REDIRECT_URL, redirectUrl, {
          expires: 30,
          domain: APTEDGE_COOKIE_DOMAIN
        });
        window.open("/", "_self");
      } catch (error) {}
    }
  }, [provider]);

  const processOAuth = useCallback(
    (provider: string) => {
      switch (provider) {
        case ProviderEnum.MS_OAUTH:
          processMSOAuth();
          break;
        case ProviderEnum.GOOGLE_OAUTH:
          processGoogleOAuth();
          break;
        case ProviderEnum.ONE_LOGIN:
          processOneLogin();
          break;
      }
    },
    [processMSOAuth, processGoogleOAuth, processOneLogin]
  );

  useEffect(() => {
    processOAuth(provider);
  }, [processOAuth, provider]);

  return { statusCode, isExternal };
};

export default useOAuth;
