import { RelativeRange } from "@aptedge/lib-ui/src/types/date";
import { IQueryArgs } from "@aptedge/lib-ui/src/utils/query";
import { toSortString } from "../../clients/utils/sort";
import { useLZQuery } from "../../hooks/useLZQuery";
import { useTicketSort } from "../../hooks/useTicketSort";
import { IPaginatedRequest } from "../../types/clients";

const DEFAULT_CREATED = RelativeRange.TWO_WEEKS;

export type TicketListingQueryParams = IPaginatedRequest<IQueryArgs>;

function useTicketListingQuery(): [
  TicketListingQueryParams,
  (partial: Partial<TicketListingQueryParams>) => void
] {
  const [DEFAULT_SORT] = useTicketSort();

  return useLZQuery<TicketListingQueryParams>({
    timeDimensions: [{ id: "createdOn", values: [DEFAULT_CREATED] }],
    filters: [],
    page: 1,
    perPage: 25,
    sortBy: toSortString([DEFAULT_SORT])
  });
}

export { useTicketListingQuery, DEFAULT_CREATED };
