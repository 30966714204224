import { FilterWrapper } from "@aptedge/lib-ui/src/components/Filter/FilterWrapper";
import { IQueryFilter } from "@aptedge/lib-ui/src/components/Filter/types";
import { MultiSelect } from "@aptedge/lib-ui/src/components/MultiSelect/MultiSelect";
import React from "react";
import { useSpaces } from "../../hooks/clients/useSpaces";
import { SPACE_FILTER } from "./data";

type Props = {
  value?: IQueryFilter;
  onChange: (val: IQueryFilter) => void;
};

function SpaceFilter(props: Props): React.ReactElement {
  const spacesQuery = useSpaces();

  const spaces = spacesQuery.data ?? [];
  const sortedSpaces = spaces
    .slice()
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <FilterWrapper {...SPACE_FILTER} {...props}>
      {({ values, onChange }) => {
        const selected = values
          ? spaces.filter((s) => values.includes(String(s.name)))
          : undefined;

        return (
          <MultiSelect
            name="space-filter"
            data={sortedSpaces}
            value={selected}
            getOptionLabel={(s) => s.name}
            onChange={(spaces) => onChange(spaces.map((s) => String(s.name)))}
          />
        );
      }}
    </FilterWrapper>
  );
}

export { SpaceFilter };
