import { ILabel } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { IPaginatedResponse } from "../../types/clients";
import { request } from "../../utils/request";

async function searchLabels(
  searchTerm: string
): Promise<IPaginatedResponse<ILabel[]>> {
  const url = `${getBackendApiUrl()}/labels/search/?search-term=${searchTerm}`;

  return request(url);
}

export { searchLabels };
