import { Select } from "@aptedge/lib-ui/src/components/Select/Select";
import { IEdgeStatus } from "@aptedge/lib-ui/src/types/entities";
import React from "react";
import { EDGE_STATUS_LIST } from "../../registry/status";

interface Props {
  value?: IEdgeStatus;
  placeholder?: string;
  clearRowLabel?: string;
  onChange: (status: IEdgeStatus | null) => void;
  className?: string;
  isError?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
}

const StatusSelect: React.FC<Props> = (props) => {
  const {
    value,
    placeholder,
    clearRowLabel,
    onChange,
    className,
    isError,
    isLoading,
    isDisabled
  } = props;

  return (
    <Select
      data={EDGE_STATUS_LIST}
      placeholder={placeholder}
      clearRowLabel={clearRowLabel}
      className={className}
      getOptionLabel={(s) => s}
      value={value}
      isDisabled={isDisabled}
      onChange={onChange}
      isLoading={isLoading}
      error={isError ? "Something went wrong." : undefined}
    />
  );
};

export { StatusSelect };
