import { ISpace } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";

async function fetchSpaces(): Promise<ISpace[]> {
  const url = `${getBackendApiUrl()}/spaces/`;

  return request(url);
}

export { fetchSpaces };
