import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import React, { useContext } from "react";
import { SavedQueriesContext } from "../../context/SavedQueriesContext";

const CustomQueryButton: React.FC = () => {
  const saved = useContext(SavedQueriesContext);

  const handleClick = (): void => {
    saved.setCurrentSavedQuery(undefined);
    saved.setEditing(true);
  };

  if (saved.editing) {
    return null;
  }

  return (
    <Button color="secondary" onClick={handleClick}>
      <Icons.Plus size="sm" />
      <span className="ml-0">Custom Filter</span>
    </Button>
  );
};

export { CustomQueryButton };
