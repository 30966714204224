import {
  IQueryFilter,
  IQueryFilterId,
  IQueryFilterOperator
} from "@aptedge/lib-ui/src/components/Filter/types";
import { useEventListener } from "@aptedge/lib-ui/src/hooks/useEventListener";
import { useOnMouseDownOutside } from "@aptedge/lib-ui/src/hooks/useOnMouseDownOutside";
import { useAppDispatch } from "@aptedge/lib-ui/src/redux/hook/hook";
import {
  ArticleUser,
  updateArticleCreatedUser,
  updateArticleReviewedUser,
  updateSelectedReviewer
} from "@aptedge/lib-ui/src/redux/reduxSlice/kbGenSlice";
import { Keys } from "@aptedge/lib-ui/src/styles/keys";
import { IUserListing } from "@aptedge/lib-ui/src/types/entities";
import {
  ChangeEventHandler,
  useEffect,
  useRef,
  useState,
  MouseEvent,
  RefObject
} from "react";
import { useQuery } from "react-query";
import { WebCacheKey } from "../clients/cache";
import { fetchUsers } from "../clients/User/fetchUsers";

export enum UserSearchSource {
  ARTICLE_FILTER = "article_filter",
  ARTICLE_REVIEW = "article_review"
}

type UseArticleSearchUserProps = {
  containerRef: RefObject<HTMLDivElement>;
  inputRef: RefObject<HTMLInputElement>;
  textInput: string;
  popupOpen: boolean;
  isLoading: boolean;
  sortedUsers: IUserListing[];
  handleMouseDown: (e: MouseEvent<HTMLDivElement>) => void;
  togglePopup: () => void;
  handleInputChange: ChangeEventHandler<HTMLInputElement>;
  handleClear: (e: React.MouseEvent<HTMLDivElement>) => void;
  handleOptionClick: (index: number) => void;
};

const prepareFilters = (
  id: IQueryFilterId,
  operator: IQueryFilterOperator,
  values: string[]
): IQueryFilter => {
  return {
    id,
    operator,
    values
  };
};

function useArticleSearchUser(
  userInput: IUserListing,
  articleUser: string,
  handleUserSearchClear: (articleUser: string) => void,
  source: UserSearchSource,
  handleClosePopup?: () => void
): UseArticleSearchUserProps {
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [popupOpen, setPopupOpen] = useState(false);
  const [textInput, setTextInput] = useState("");

  const dispatch = useAppDispatch();

  const getFilters = (): IQueryFilter[] => {
    const newFilters: IQueryFilter[] = [];
    if (articleUser === ArticleUser.REVIEW) {
      newFilters.push(prepareFilters("role", "equals", ["Admin", "Reviewer"]));
    }
    if (articleUser === ArticleUser.CREATE) {
      newFilters.push(
        prepareFilters("role", "equals", ["Admin", "Reviewer", "Agent"])
      );
    }
    if (textInput) {
      newFilters.push(prepareFilters("email", "contains", [textInput]));
    }
    return newFilters;
  };

  const { data: users, isLoading } = useQuery(
    [WebCacheKey.USERS, textInput, articleUser],
    () =>
      fetchUsers({
        filters: getFilters()
      }),
    {
      refetchOnMount: false /** cache across components */,
      enabled: popupOpen
    }
  );

  const sortedUsers = (users || []).sort((a, b) =>
    a.email.localeCompare(b.email)
  );

  const onKeyDown = (e: KeyboardEvent): void => {
    if ([Keys.ESCAPE, Keys.TAB].includes(e.key as Keys) && popupOpen) {
      setPopupOpen(false);
    }
  };
  useEventListener("keydown", onKeyDown);

  useOnMouseDownOutside(containerRef, () => setPopupOpen(false));

  const togglePopup = (): void => {
    setPopupOpen(!popupOpen);
  };

  const handleOptionClick = (index: number): void => {
    setTextInput(sortedUsers[index].email);
    if (source === UserSearchSource.ARTICLE_FILTER) {
      if (articleUser === ArticleUser.CREATE) {
        dispatch(updateArticleCreatedUser(sortedUsers[index]));
      } else {
        dispatch(updateArticleReviewedUser(sortedUsers[index]));
      }
    } else {
      dispatch(updateSelectedReviewer(sortedUsers[index]));
    }
    if (handleClosePopup instanceof Function) handleClosePopup();
    setPopupOpen(false);
  };

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e): void => {
    setTextInput(e.target.value);
  };

  const handleClear = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.stopPropagation();
    setTextInput("");
    handleUserSearchClear(articleUser);
  };

  const handleMouseDown = (e: MouseEvent<HTMLDivElement>): void => {
    e.stopPropagation();
  };

  useEffect(() => {
    if (!Boolean(userInput && userInput.email)) {
      setTextInput("");
    } else {
      setTextInput(userInput.email);
    }
  }, [userInput]);

  return {
    containerRef,
    inputRef,
    textInput,
    popupOpen,
    isLoading,
    sortedUsers,
    handleMouseDown,
    togglePopup,
    handleInputChange,
    handleClear,
    handleOptionClick
  };
}

export { useArticleSearchUser };
