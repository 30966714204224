import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { ICustomFieldMapping } from "@aptedge/lib-ui/src/types/entities";
import classNames from "classnames";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { deleteCustomFieldMapping } from "../../../clients/CustomFieldMappings/deleteCustomFieldMapping";
import { DeleteIcon } from "../common/DeleteIcon";
import { ListItem } from "../common/ListItem";

interface Props {
  customFieldMapping: ICustomFieldMapping;
  className?: string;
  onChange: () => void;
}

const CustomFieldMappingListItem: React.FC<Props> = (props) => {
  const { customFieldMapping, className, onChange } = props;
  const [showConfirm, setShowConfirm] = useState(false);

  const removeCustomFieldMapping = useMutation(deleteCustomFieldMapping, {
    onSuccess: onChange
  });

  return (
    <ListItem
      className={classNames("custom-field-list-item", "group", className)}
    >
      {!showConfirm ? (
        <>
          <span className="badge mr-3">{customFieldMapping.fieldName}</span>
          <span className="badge text-muted mr-3">
            {customFieldMapping.displayName}
          </span>
          <span className="badge text-muted mr-3">
            {customFieldMapping.displayValue}
          </span>
          <DeleteIcon onClick={() => setShowConfirm(true)} />
        </>
      ) : (
        <>
          <span className="text-muted pr-2">
            <span>
              Are you sure you want to delete {customFieldMapping.fieldName}?
            </span>
          </span>
          <div className="d-flex">
            <Button
              color="secondary"
              className="mr-2"
              onClick={() => setShowConfirm(false)}
            >
              Cancel
            </Button>
            <Button
              color="danger"
              onClick={() =>
                removeCustomFieldMapping.mutate({ id: customFieldMapping.id })
              }
            >
              Delete
            </Button>
          </div>
        </>
      )}
    </ListItem>
  );
};

export { CustomFieldMappingListItem };
