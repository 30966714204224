import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";
import { post } from "../utils/buildRequest";

function createKBExclusionConfig(value: string): Promise<string> {
  const url = `${getBackendApiUrl()}/config/ML_KNOWLEDGE_BASE_ARTICLE_EXCLUDED_URLS/`;
  const options = post({ value });

  return request(url, options);
}

export { createKBExclusionConfig };
