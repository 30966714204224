import { AeIcons } from "@aptedge/lib-ui/src/components/Icon/AeIcons";
import { Icons } from "@aptedge/lib-ui/src/components/Icon/Icons";
import * as React from "react";
import styles from "./HeaderSummary.module.scss";

interface HeaderSummaryProps {
  articlesLength: number;
}

const HeaderSummary: React.FC<HeaderSummaryProps> = ({ articlesLength }) => {
  const lowEstimate = articlesLength * 2 - 1;
  const highEstimate = articlesLength * 2 + 1;
  return (
    <div className={styles.container}>
      <div className={styles.containerIcon}>
        <AeIcons.GeneratedKnowledgeBlueLarge />
      </div>
      <div className={styles.containerText}>
        <h2>Review articles</h2>
        <div className={styles.containerTime}>
          <Icons.Clock />
          <p>
            {articlesLength
              ? `${lowEstimate}-${highEstimate} minutes`
              : "All done!"}
          </p>
        </div>
      </div>
    </div>
  );
};

export { HeaderSummary };
