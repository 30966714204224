import {
  ICustomer,
  IOriginalCustomer
} from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";

/**
 * Fetch customers with the given ids. Currently uses multiple requests threaded
 * together with `Promise.all()`.
 */
async function fetchCustomersById(ids: number[]): Promise<ICustomer[]> {
  const promises: Promise<ICustomer>[] = [];

  ids.forEach((id) => {
    const url = `${getBackendApiUrl()}/customers/${id}/`;

    const promise = request<IOriginalCustomer>(url);

    promises.push(
      promise.then((customer) => ({
        id: customer.id,
        name: customer.name,
        pinned: customer.pinned,
        monetaryValue: customer.value.amount
      }))
    );
  });

  return Promise.all(promises);
}

export { fetchCustomersById };
