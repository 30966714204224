import { Editor } from "@tiptap/react";
import { v4 as uuidv4 } from "uuid";

type EditorUtilsProps = {
  toggleBold: () => void;
  toggleItalic: () => void;
  setHeading: (level: number) => void;
  toggleBulletList: () => void;
  toggleOrderedList: () => void;
  insertImage: () => void;
  toggleCodeBlock: () => void;
  toggleCode: () => void;
  toggleBlockQuote: () => void;
  toggleStrike: () => void;
  setLink: () => void;
};

const useEditorUtils = (editor: Editor | null): EditorUtilsProps => {
  const toggleBold = (): void => {
    editor?.chain().focus().toggleBold().run();
  };

  const toggleItalic = (): void => {
    editor?.chain().focus().toggleItalic().run();
  };

  const setHeading = (level: number): void => {
    editor
      ?.chain()
      .focus()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .toggleHeading({ level: level as any })
      .run();
  };

  const toggleBulletList = (): void => {
    editor?.chain().focus().toggleBulletList().run();
  };

  const toggleOrderedList = (): void => {
    editor?.chain().focus().toggleOrderedList().run();
  };

  const insertImage = (): void => {
    const url = prompt("Enter image URL");
    if (url) {
      editor
        ?.chain()
        .focus()
        .setImage({ src: url, alt: `image_${uuidv4()}` })
        .run();
    }
  };

  const toggleCodeBlock = (): void => {
    editor?.chain().focus().toggleCodeBlock().run();
  };

  const toggleCode = (): void => {
    editor?.chain().focus().toggleCode().run();
  };

  const toggleBlockQuote = (): void => {
    editor?.chain().focus().toggleBlockquote().run();
  };

  const toggleStrike = (): void => {
    editor?.chain().focus().toggleStrike().run();
  };

  const setLink = (): void => {
    const previousUrl = editor?.getAttributes("link").href;
    const url = prompt("Enter URL", previousUrl);

    if (url === null) {
      return;
    }

    if (url === "") {
      editor?.chain().focus().extendMarkRange("link").unsetLink().run();
      return;
    }
    const newUrl =
      !url.startsWith("http://") && !url.startsWith("https://")
        ? "https://" + url
        : url;
    editor
      ?.chain()
      .focus()
      .extendMarkRange("link")
      .setLink({
        href: newUrl
      })
      .run();
  };

  return {
    toggleBold,
    toggleItalic,
    setHeading,
    toggleBulletList,
    toggleOrderedList,
    insertImage,
    toggleCodeBlock,
    toggleCode,
    toggleBlockQuote,
    toggleStrike,
    setLink
  };
};

export { useEditorUtils };
