import { IBulkReply } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { IPaginatedResponse } from "../../types/clients";
import { request } from "../../utils/request";

async function fetchEdgeBulkReplies(
  edgeId: number
): Promise<IPaginatedResponse<IBulkReply[]>> {
  const url = `${getBackendApiUrl()}/edges/${edgeId}/bulk-reply/`;

  return request(url);
}

export { fetchEdgeBulkReplies };
