import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";
import { post } from "../utils/buildRequest";

type Response = { token: string };

async function createSelfServiceToken(): Promise<Response> {
  const url = `${getBackendApiUrl()}/generate_self_service_token/`;
  const options = post({});

  return request(url, options);
}

export { createSelfServiceToken };
