import { ITheme } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { request } from "../../utils/request";

async function fetchThemes(): Promise<ITheme[]> {
  const url = `${getBackendApiUrl()}/themes/`;

  return request(url);
}

export { fetchThemes };
