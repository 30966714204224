import {
  IQueryFilter,
  IQueryFilterId,
  IQueryFilterOperator
} from "@aptedge/lib-ui/src/components/Filter/types";
import useAiAnswerQuery from "@aptedge/lib-ui/src/components/Search/hooks/useAiAnswerQuery";
import { useFlags } from "@aptedge/lib-ui/src/context/FlagsContext/FlagsContext";
import { IFlagSet } from "@aptedge/lib-ui/src/context/FlagsContext/types";
import {
  useAppDispatch,
  useAppSelector
} from "@aptedge/lib-ui/src/redux/hook/hook";
import {
  updateDraftArticleCount,
  updatePage,
  updateReviewArticleCount,
  updateTotalArticles,
  updateTotalPages
} from "@aptedge/lib-ui/src/redux/reduxSlice/kbGenSlice";
import {
  ArticleStatus,
  GeneratedKnowledgeResult,
  ICompositeResult
} from "@aptedge/lib-ui/src/types/entities";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { WebCacheKey } from "../clients/cache";
import { gqaSearch } from "../clients/CompositeSearch/gqaSearch";
import { fetchArticles } from "../clients/GeneratedKnowledge/fetchArticles";
import { useAuth } from "../context/AuthContext";

type ArticlePageProps = {
  flags: IFlagSet;
  isLoading: boolean;
  isError: boolean;
  showEmptyState?: boolean;
  showTicketsTable?: boolean;
  articles?: GeneratedKnowledgeResult[] | ICompositeResult[];
};

const PER_PAGE = 10;

function useArticlePage(): ArticlePageProps {
  const { flags } = useFlags();
  const { user: currentUser } = useAuth();

  const {
    tabArticleStatus,
    page,
    articleCreatedUser,
    articleReviewedUser,
    filterArticleStatus,
    searchArticle
  } = useAppSelector((state) => state.knowledgeBase);

  const dispatch = useAppDispatch();

  const prepareFilters = (
    id: IQueryFilterId,
    operator: IQueryFilterOperator,
    values: string[]
  ): IQueryFilter => {
    return {
      id,
      operator,
      values
    };
  };

  const getFilters = (): IQueryFilter[] => {
    const newFilters: IQueryFilter[] = [];
    if (flags.kbGenPublishProcess) {
      if (tabArticleStatus === ArticleStatus.DRAFT) {
        newFilters.push(
          ...[
            prepareFilters("status", "equals", [ArticleStatus.DRAFT]),
            prepareFilters("createdBy", "equals", [String(currentUser?.id)])
          ]
        );
      }
      if (tabArticleStatus === ArticleStatus.IN_REVIEW) {
        newFilters.push(
          ...[
            prepareFilters("status", "equals", [ArticleStatus.IN_REVIEW]),
            prepareFilters("reviewedBy", "equals", [String(currentUser?.id)])
          ]
        );
      }
      if (filterArticleStatus.length > 0) {
        newFilters.push(
          prepareFilters("status", "equals", filterArticleStatus)
        );
      }
      if (Boolean(articleCreatedUser && articleCreatedUser.email)) {
        newFilters.push(
          prepareFilters("createdBy", "equals", [String(articleCreatedUser.id)])
        );
      }
      if (Boolean(articleReviewedUser && articleReviewedUser.email)) {
        newFilters.push(
          prepareFilters("reviewedBy", "equals", [
            String(articleReviewedUser.id)
          ])
        );
      }
    }
    if (!flags.kbGenPublishProcess && tabArticleStatus === ArticleStatus.ALL) {
      newFilters.push(
        prepareFilters("status", "equals", [ArticleStatus.PUBLISHED])
      );
    }
    return newFilters;
  };

  const { gptAnswer } = useAiAnswerQuery(
    true,
    flags.streamingAnswers,
    gqaSearch
  );

  const searchResults = gptAnswer.data?.pages[0]?.data.items;

  const {
    data: articleData,
    isLoading: isArticleLoading,
    isError: isArticleError
  } = useQuery(
    [
      WebCacheKey.GENERATED_KNOWLEDGE_PUBLISHED,
      tabArticleStatus,
      filterArticleStatus,
      articleCreatedUser,
      articleReviewedUser,
      page,
      searchArticle
    ],
    () =>
      fetchArticles({
        perPage: flags.kbGenPublishProcess ? PER_PAGE : undefined,
        page,
        filters: getFilters()
      }),
    {
      retry: 2,
      enabled: !searchArticle,
      onSuccess: (data) => {
        if (data) {
          dispatch(updateDraftArticleCount(data.userDrafts));
          dispatch(updateReviewArticleCount(data.userReviews));
          dispatch(updateTotalPages(data.totalPages));
          dispatch(updateTotalArticles(data.total));
        }
      }
    }
  );

  const showEmptyState =
    (articleData && articleData?.items.length === 0) ||
    (searchResults && searchResults.length === 0);
  const showTicketsTable =
    (articleData && articleData?.items.length > 0) ||
    (searchResults && searchResults.length > 0);
  const isLoading = isArticleLoading || gptAnswer.isLoading;
  const isError = isArticleError || gptAnswer.isError;

  const articles = searchArticle ? searchResults : articleData?.items;

  useEffect(() => {
    dispatch(updatePage(1));
  }, [dispatch]);

  return {
    flags,
    isLoading,
    isError,
    showEmptyState,
    showTicketsTable,
    articles
  };
}

export { useArticlePage };
