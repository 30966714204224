import React from "react";
import { IPaginationState } from "../../types/clients";
import { toApproximateStringFromNumber } from "../../utils/rounding";
import "./PaginationAnalytics.scss";

interface Props {
  data?: IPaginationState;
  isLoading?: boolean;
  isApproximate?: boolean;
}

const PaginationAnalytics: React.FC<Props> = (props) => {
  return (
    <div className="pagination-analytics">{getDisplayedMessage(props)}</div>
  );
};

function Empty(): React.ReactElement {
  return <React.Fragment>&nbsp;</React.Fragment>;
}

function getDisplayedMessage(props: Props): React.ReactElement {
  const { data, isLoading } = props;
  if (!data || !!isLoading) return <Empty />;

  const { page, perPage, totalPages, total } = data;

  const pageCount = page * perPage;
  const lastPageCount = page === totalPages ? total : pageCount;
  const approximateTotal = toApproximateStringFromNumber(total);

  return totalPages > 0 ? (
    <React.Fragment>
      {`Showing ${pageCount - (perPage - 1)} to ${lastPageCount} of ${
        props.isApproximate && total > 0 ? "about" : ""
      } ${props.isApproximate ? approximateTotal : total} results`}
    </React.Fragment>
  ) : (
    <Empty />
  );
}

export { PaginationAnalytics };
