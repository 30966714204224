import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { Feedback } from "@aptedge/lib-ui/src/components/Feedback/Feedback";
import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import classNames from "classnames";
import { useFormik } from "formik";
import React, { useEffect, useRef } from "react";
import { useMutation } from "react-query";
import * as Yup from "yup";
import { createKnowledgeBaseConfig } from "../../../clients/KnowledgeBaseConfig/createKnowledgeBaseConfig";
import WithLoading from "../../../components/WithLoading/WithLoading";

interface Props {
  className?: string;
  onSave: () => void;
  onCancel: () => void;
}

interface FormState {
  url: string;
}

const KnowledgeBaseCreateItem: React.FC<Props> = (props) => {
  const { className, onCancel, onSave } = props;

  const inputRef = useRef<HTMLInputElement>(null);

  const addKBConfig = useMutation<string, Error, string, unknown>(
    createKnowledgeBaseConfig,
    {
      onSuccess: onSave
    }
  );

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  const formik = useFormik<FormState>({
    initialValues: {
      url: ""
    },
    onSubmit: (values) => {
      addKBConfig.mutate(values.url);
    },
    validateOnBlur: false,
    validationSchema: Yup.object({
      url: Yup.string()
        .required("A URL prefix is required.")
        .url("Valid URL is required. (e.g. https://aptedge.io)")
    })
  });

  return (
    <WithLoading
      isLoading={addKBConfig.isLoading}
      type="overlay"
      fallback={<Spinner />}
    >
      <div className={classNames("label-create-item p-2", className)}>
        <h5>Add a new URL prefix</h5>
        <form onSubmit={formik.handleSubmit}>
          <div className="form-row">
            <div className="form-group col">
              <label htmlFor="url">URL</label>
              <input
                id="url"
                type="text"
                ref={inputRef}
                className={classNames("form-control", {
                  "is-invalid": !!formik.errors.url
                })}
                value={formik.values.url}
                onChange={formik.handleChange}
              />
              {formik.errors.url && <Feedback>{formik.errors.url}</Feedback>}
            </div>
          </div>
          {addKBConfig.isError && (
            <Feedback>
              {addKBConfig.error?.message || "Something went wrong."}
            </Feedback>
          )}
          <div className="d-flex mt-2 justify-content-end">
            <Button
              type="button"
              className="mr-2"
              color="secondary"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Save
            </Button>
          </div>
        </form>
      </div>
    </WithLoading>
  );
};

export { KnowledgeBaseCreateItem };
