import { ModalWidth } from "@aptedge/lib-ui/src/components/Modal/Modal";
import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import {
  OAuthStatus,
  IConfluenceOnPremArticleSource
} from "@aptedge/lib-ui/src/types/entities";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { ToastType } from "react-toastify";
import { createConfluenceOnPremArticleSource } from "../../../clients/ArticleSources/createConfluenceOnPremArticleSource";
import { updateConfluenceOnPremArticleSource } from "../../../clients/ArticleSources/updateConfluenceOnPremArticleSource";
import { ConfirmationModal } from "../../../components/ConfirmationModal/ConfirmationModal";
import { Toast } from "../../../components/Toast/Toast";
import WithLoading from "../../../components/WithLoading/WithLoading";
import { UseDataSourceResult } from "../../../hooks/clients/useDataSource";
import { ClientStatus } from "../../../types/clients";
import { AddButton } from "../common/AddButton";
import { StatusButton } from "../common/StatusButton";
import {
  ConfluenceOnPremConfigForm,
  ConfluenceOnPremFormData
} from "./ConfluenceOnPremConfigForm";

interface Props {
  dataSource: UseDataSourceResult<IConfluenceOnPremArticleSource> | null;
}

const ConfluenceOnPremConfig: React.FunctionComponent<Props> = (props) => {
  const { dataSource } = props;
  const articleIssueSource = dataSource?.dataSource ?? null;

  const [showModal, setShowModal] = useState<boolean>(false);
  const [status, setStatus] = React.useState(ClientStatus.PROMPT);

  const addSource = useMutation(createConfluenceOnPremArticleSource, {
    onError: () => {
      setStatus(ClientStatus.ERROR);
    },
    onSuccess: () => {
      setShowModal(false);
      setStatus(ClientStatus.SUCCESS);
      dataSource?.reload();
    }
  });

  const updateSource = useMutation(updateConfluenceOnPremArticleSource, {
    onError: () => {
      setStatus(ClientStatus.ERROR);
    },
    onSuccess: () => {
      setShowModal(false);
      setStatus(ClientStatus.SUCCESS);
      dataSource?.reload();
    }
  });

  const onSubmit = (formData: ConfluenceOnPremFormData): void => {
    setStatus(ClientStatus.LOADING);
    articleIssueSource
      ? updateSource.mutate(formData)
      : addSource.mutate(formData);
  };

  return (
    <div>
      <div className="mb-3 mt-5">
        <h5>Confluence On-Premise</h5>
        <p>Configure your Confluence On-Premise settings below.</p>
      </div>
      <div>
        <ConfirmationModal
          title="Confluence On-Premise Settings"
          width={ModalWidth.MEDIUM}
          loadingTitle="Loading..."
          status={status}
          isOpen={showModal}
          onClose={() => setShowModal(false)}
        >
          <p className="mb-4">Configure your settings below.</p>
          <ConfluenceOnPremConfigForm
            source={articleIssueSource}
            onSubmit={onSubmit}
          />
        </ConfirmationModal>
      </div>
      <WithLoading
        isLoading={status === ClientStatus.LOADING}
        fallback={
          <div className="d-flex justify-content-center m-4">
            <Spinner />
          </div>
        }
      >
        {articleIssueSource ? (
          <StatusButton
            status={
              articleIssueSource.status === OAuthStatus.AUTHENTICATED
                ? "ok"
                : "error"
            }
            onClickEdit={() => setShowModal(true)}
          >
            <>
              <div>Confluence On-Premise</div>
            </>
          </StatusButton>
        ) : (
          <AddButton onClick={() => setShowModal(true)}>
            Connect to Confluence On-Premise
          </AddButton>
        )}
      </WithLoading>
      {addSource.isError && (
        <Toast type={ToastType.ERROR}>Something went wrong.</Toast>
      )}
    </div>
  );
};

export { ConfluenceOnPremConfig };
