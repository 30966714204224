import {
  TextInput,
  TextInputProps
} from "@aptedge/lib-ui/src/components/TextInput/TextInput";
import { useDebounce } from "@aptedge/lib-ui/src/hooks/useDebounce";
import { usePrevious } from "@aptedge/lib-ui/src/hooks/usePrevious";
import React, { useEffect, useRef } from "react";
import "./DebouncedTextInput.scss";

interface Props
  extends Pick<
    TextInputProps,
    "placeholder" | "value" | "size" | "autoFocus" | "className"
  > {
  isClearable?: boolean;
  onChange: (value: string | null) => void;
}

const DebouncedTextInput: React.FunctionComponent<Props> = (props) => {
  const {
    onChange,
    value,
    placeholder = "Contains text",
    isClearable = true,
    ...rest
  } = props;

  const [search, setSearch] = React.useState<string | undefined>(value);
  const { debouncedValue } = useDebounce(search, 300);
  const previousValue = usePrevious(debouncedValue);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (val?: string): void => {
    if (val !== undefined) {
      setSearch(val);
    }
  };

  const handleClear = isClearable ? () => handleChange("") : undefined;

  useEffect(() => {
    if (debouncedValue !== undefined && debouncedValue !== previousValue) {
      onChange(debouncedValue || null);
    }
  }, [onChange, debouncedValue, previousValue]);

  useEffect(() => {
    setSearch(value);
  }, [value]);

  return (
    <div className="debounced-text-input">
      <TextInput
        name={`textQuery-${Date.now()}`}
        className="text-search"
        value={search}
        placeholder={placeholder}
        onChange={(e) => handleChange(e.target.value)}
        onClear={handleClear}
        ref={inputRef}
        {...rest}
      />
    </div>
  );
};

export { DebouncedTextInput };
