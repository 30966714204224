import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { Toggle } from "@aptedge/lib-ui/src/components/Toggle/Toggle";
import { ITicketSource } from "@aptedge/lib-ui/src/types/entities";
import { useFormik } from "formik";
import React from "react";
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label
} from "reactstrap";
import * as Yup from "yup";

export interface ZendeskFormData {
  instanceName: string;
  oauthClientId: string;
  oauthClientSecret: string;
  brandNameFilter: string;
  ticketFormNameFilter: string;
  scope: string;
}

interface Props {
  source?: ITicketSource;
  onSubmit: (formData: ZendeskFormData) => void;
}

const ZendeskConfigForm: React.FunctionComponent<Props> = ({
  source,
  onSubmit
}) => {
  const formik = useFormik<ZendeskFormData>({
    validationSchema: Yup.object({
      instanceName: Yup.string().required("Please enter an instance Name."),
      oauthClientId: Yup.string().required("Please enter a client ID."),
      oauthClientSecret: Yup.string().required("Please enter a client secret.")
    }),
    initialValues: {
      instanceName: (source && source.instanceName) || "",
      oauthClientId: (source && source.oauthClientId) || "",
      oauthClientSecret: (source && source.oauthClientSecret) || "",
      brandNameFilter: (source && source.brandNameFilter) || "",
      ticketFormNameFilter: (source && source.ticketFormNameFilter) || "",
      scope: (source && source.scope) || "read"
    },
    validateOnChange: false,
    onSubmit: onSubmit
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <FormGroup row>
        <Label sm={3} for="zendeskInstanceName">
          Instance Name
        </Label>
        <Col sm={9}>
          <Input
            type="text"
            name="instanceName"
            id="zendeskInstanceName"
            placeholder=""
            value={formik.values.instanceName}
            onChange={formik.handleChange}
            invalid={!!formik.errors.instanceName}
          />
          <FormFeedback>{formik.errors.instanceName}</FormFeedback>
          <FormText>
            This is also the subdomain of your Zendesk Support URL. E.g. for
            https://aptedge.zendesk.com it would be “aptedge”
          </FormText>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3} for="oauthClientId">
          OAuth Client ID
        </Label>
        <Col sm={9}>
          <Input
            type="text"
            name="oauthClientId"
            placeholder=""
            value={formik.values.oauthClientId}
            onChange={formik.handleChange}
            invalid={!!formik.errors.oauthClientId}
          />
          <FormFeedback>{formik.errors.oauthClientId}</FormFeedback>
          <FormText>Name of the configured OAuth client</FormText>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3} for="oauthClientSecret">
          OAuth Client Secret
        </Label>
        <Col sm={9}>
          <Input
            type="text"
            name="oauthClientSecret"
            placeholder=""
            value={formik.values.oauthClientSecret}
            onChange={formik.handleChange}
            invalid={!!formik.errors.oauthClientSecret}
          />
          <FormFeedback>{formik.errors.oauthClientSecret}</FormFeedback>
          <FormText>
            Client Secret provided to you in creating an OAuth client
          </FormText>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3} for="brandNameFilter">
          Zendesk Brand Filter
        </Label>
        <Col sm={9}>
          <Input
            type="text"
            name="brandNameFilter"
            placeholder=""
            value={formik.values.brandNameFilter}
            onChange={formik.handleChange}
            invalid={!!formik.errors.brandNameFilter}
          />
          <FormText>
            To filter tickets of a specific Brand, enter its name here
            (optional)
          </FormText>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3} for="ticketFormNameFilter">
          Zendesk Ticket Form Filter
        </Label>
        <Col sm={9}>
          <Input
            type="text"
            name="ticketFormNameFilter"
            placeholder=""
            value={formik.values.ticketFormNameFilter}
            onChange={formik.handleChange}
            invalid={!!formik.errors.ticketFormNameFilter}
          />
          <FormText>
            To filter tickets from a specific Ticket Form, enter its name here
            (optional)
          </FormText>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3} for="scope">
          Write Access
        </Label>
        <Col sm={9}>
          <Toggle
            name="scope"
            checked={formik.values.scope === "write"}
            onChange={(checked: boolean) => {
              formik.setFieldValue("scope", checked ? "write" : "read");
            }}
          />
          <FormText>Grant Aptedge write access to Zendesk Instance</FormText>
        </Col>
      </FormGroup>
      <div className="float-right">
        <Button type="submit">Authorize</Button>
      </div>
    </Form>
  );
};

export { ZendeskConfigForm };
