import { DateTime } from "luxon";
import React from "react";
import "./Footer.scss";

function Footer(): React.ReactElement {
  return (
    <div className="footer p-5">
      <div className="footer-content p-3">
        &copy; {DateTime.local().year} AptEdge. All Rights Reserved.
      </div>
    </div>
  );
}

export { Footer };
