import React from "react";
import styles from "./FiltersNavigation.module.scss";
import { FiltersNavigationItem } from "./FiltersNavigationItem";

export type FilterTab = {
  label: string;
  icon: React.ReactNode;
  show: boolean;
  path: string;
};

interface FiltersNavigationProps {
  filterTabs: FilterTab[];
}

const FiltersNavigation: React.FC<FiltersNavigationProps> = ({
  filterTabs
}) => {
  return (
    <div className={styles.container}>
      {filterTabs
        .filter((filterTab) => filterTab.show)
        .map((filterTab) => (
          <FiltersNavigationItem key={filterTab.label} filterTab={filterTab} />
        ))}
    </div>
  );
};

export { FiltersNavigation };
