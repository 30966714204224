import { AeIcons } from "@aptedge/lib-ui/src/components/Icon/AeIcons";
import { MarkdownContent } from "@aptedge/lib-ui/src/components/MarkdownContent/MarkdownContent";
import { useSearchPreview } from "@aptedge/lib-ui/src/hooks/useSearchPreview";
import { SearchFilterDropdown } from "@aptedge/lib-ui/src/services/search/searchFilterService";
import {
  IGeneratedKnowledgeResult,
  ICompositeResult
} from "@aptedge/lib-ui/src/types/entities";
import classNames from "classnames";
import SearchCardTimings from "../SearchCardTimings/SearchCardTimings";
import SearchResultCardActionIcons from "../SearchResultCardActionIcons/SearchResultCardActionIcons";
import { SearchResultPreview } from "../SearchResultPreview/SearchResultPreview";
import styles from "./GeneratedKnowledgeSearchResult.module.scss";

interface Props
  extends Omit<
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >,
    "ref"
  > {
  filterDetails: SearchFilterDropdown[];
  className?: string;
  article: IGeneratedKnowledgeResult;
  isSelected?: boolean;
  showActionIcons?: boolean;
}

const GeneratedKnowledgeSearchResult: React.FC<Props> = ({
  className,
  article,
  isSelected,
  filterDetails,
  showActionIcons,
  ...htmlProps
}) => {
  const { hasPreview, previewSnippet, previewType } = useSearchPreview({
    ...article,
    body: article.body.body,
    type: "generated_knowledge"
  });

  return (
    <div
      {...htmlProps}
      className={classNames("article-search-result", className, {
        selectable: !!htmlProps.onClick,
        active: isSelected
      })}
    >
      <div>
        <div className={styles.articleHeader}>
          <div className={styles.articleTitle}>
            <AeIcons.GeneratedKnowledgeBlue className="mr-3 p-0" />
            <span className={styles.articleSubject}>
              <SearchResultPreview
                previewSnippet={
                  hasPreview && previewType === "title"
                    ? previewSnippet
                    : article.title
                }
              />
            </span>
          </div>
          {showActionIcons && (
            <div className="article-header-action-icons">
              <SearchResultCardActionIcons
                searchResult={article as ICompositeResult}
              />
            </div>
          )}
        </div>
        <MarkdownContent
          className={classNames(
            styles.articleBody,
            "search-result-preview-text"
          )}
          content={article?.body.body}
          maxLength={120}
          disableShowMore={true}
        />
      </div>
      <SearchCardTimings
        createdOn={article.createdOn}
        updatedOn={article.updatedOn}
      />
    </div>
  );
};

export { GeneratedKnowledgeSearchResult };
