import React from "react";
import { WebCacheKey } from "../../../clients/cache";
import { createAgentEmailDomainConfig } from "../../../clients/Config/createAgentEmailDomainConfig";
import { deleteAgentEmailDomainConfig } from "../../../clients/Config/deleteAgentEmailDomainConfig";
import { fetchAgentEmailDomainConfig } from "../../../clients/Config/fetchAgentEmailDomainConfig";
import { GetDataErrorBoundary } from "../../../components/ErrorHandler/GetDataErrorBoundary";
import { SingleTextItemList } from "../SingleTextItemList";

function AgentEmailSettings(): React.ReactElement {
  return (
    <>
      <section className="row mb-5">
        <div className="col-md-5 p-2 pr-3">
          <h4 className="text-uppercase">Agent Email Domains</h4>
          <p>
            Configure a set of domains that represent your all of your support
            agent&apos;s email address domains.
          </p>
        </div>
        <div className="col-md-7 p-2 pl-3">
          <GetDataErrorBoundary message="Unable to get Agent Email Domains.">
            <SingleTextItemList
              cacheKey={WebCacheKey.AGENT_EMAIL_DOMAINS}
              subtitle="Add domains that AptEdge should use to identify Support Agents by email address."
              createEmailConfig={createAgentEmailDomainConfig}
              deleteEmailConfig={deleteAgentEmailDomainConfig}
              fetchEmailConfig={fetchAgentEmailDomainConfig}
            />
          </GetDataErrorBoundary>
        </div>
      </section>
      <hr className="mt-4" />
    </>
  );
}

export { AgentEmailSettings };
