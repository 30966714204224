import {
  Spinner,
  SpinnerSize
} from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import { EntityType, ILabel } from "@aptedge/lib-ui/src/types/entities";
import sortBy from "lodash/sortBy";
import React from "react";
import { LabelChip } from "../LabelChip/LabelChip";
import WithLoading from "../WithLoading/WithLoading";
import { LabelSearch } from "./LabelSearch";
import "./LabelsWidget.scss";

interface Props {
  labels: ILabel[];
  loading?: boolean;
  disabled?: boolean;
  placeholder?: string | null;
  searchFilter?: (item: ILabel) => boolean;
  onChange?: (labels: ILabel[]) => void;
}

const DEFAULT_SEARCH_FILTER = (item: ILabel): boolean =>
  item.type !== EntityType.CUSTOMER;

const LabelsWidget: React.FC<Props> = (props) => {
  const {
    labels,
    onChange,
    searchFilter = DEFAULT_SEARCH_FILTER,
    placeholder = "No labels associated",
    loading,
    disabled
  } = props;

  const sortedLabels = sortBy(labels, ["type", "name"]);

  const addLabelHandler = (selected: ILabel): void => {
    if (selected.type === EntityType.CUSTOMER) {
      return;
    }

    const updatedLabels = [...labels];
    updatedLabels.push(selected);

    if (onChange) {
      onChange(updatedLabels);
    }
  };

  const deleteLabelHandler = (label: ILabel): void => {
    const updatedLabels = [...labels];
    const labelIndex = updatedLabels.findIndex(
      (l) => l.id === label.id && l.type === label.type
    );

    if (labelIndex !== -1) {
      updatedLabels.splice(labelIndex, 1);
    }

    if (onChange) {
      onChange(updatedLabels);
    }
  };

  return (
    <div className="labels-widget">
      <WithLoading
        isLoading={!!loading}
        fallback={<Spinner size={SpinnerSize.SMALL} />}
        type="overlay"
      >
        <div className="pill-container">
          {!!sortedLabels.length ? (
            sortedLabels.map((l) => (
              <LabelChip
                key={`${l.type}-${l.id}`}
                label={l}
                onDelete={() => deleteLabelHandler(l)}
                disabled={disabled}
              />
            ))
          ) : (
            <span className="no-labels">{placeholder}</span>
          )}
          {!disabled && (
            <LabelSearch
              labels={labels}
              searchFilter={searchFilter}
              onSelect={addLabelHandler}
            />
          )}
        </div>
      </WithLoading>
    </div>
  );
};

export { LabelsWidget };
