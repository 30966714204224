import * as React from "react";
import styles from "./AptAnswersErrorMessage.module.scss";

interface AptAnswersErrorMessageProps {
  message: string;
}

const AptAnswersErrorMessage: React.FC<AptAnswersErrorMessageProps> = ({
  message
}) => {
  return (
    <div className={styles.container}>
      <p className={styles.message}>{message}</p>
    </div>
  );
};

export { AptAnswersErrorMessage };
