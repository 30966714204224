import { IEdgeInfo } from "@aptedge/lib-ui/src/types/entities";
import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { ToastType } from "react-toastify";
import { WebCacheKey } from "../../clients/cache";
import { fetchEdgeById } from "../../clients/Edges/fetchEdgeById";
import { fetchTicketsForEdge } from "../../clients/Edges/fetchTicketsForEdge";
import { toSortString } from "../../clients/utils/sort";
import { useRecentEdges } from "../../components/GlobalSearch/useRecentEdges/useRecentEdges";
import { Toast } from "../../components/Toast/Toast";
import WithLoading from "../../components/WithLoading/WithLoading";
import { EdgeProvider } from "../../context/EdgeContext";
import { useEdgeTicketsQuery } from "../../hooks/clients/useEdgeTickets";
import { useUserBatchQuery } from "../../hooks/clients/useUserBatchQuery";
import { useRecentLabel } from "../../hooks/useRecentLabel";
import LoadingPage from "../LoadingPage/LoadingPage";
import NotFoundPage from "../NotFoundPage/NotFoundPage";
import EdgeContent from "./EdgeContent";
import "./EdgePage.scss";

type TParams = { edgeId: string };
type TicketArgs = Parameters<typeof fetchTicketsForEdge>[0];

/**
 * Edge page
 *
 * Displays the sidebar and edge view.
 */
const EdgePage: React.FC = () => {
  const { edgeId } = useParams<TParams>();
  const queryClient = useQueryClient();
  const id = Number(edgeId);

  const [, addLabels] = useRecentLabel();
  const [, addRecentEdge] = useRecentEdges();

  const [ticketsArgs, setTicketsArgs] = useState<TicketArgs>(() =>
    defaultTicketArgs(id)
  );

  const edgeQuery = useQuery(
    [WebCacheKey.EDGE, id],
    () => fetchEdgeById({ edgeId: id }),
    {
      enabled: !isNaN(id)
    }
  );

  const edgeTicketsQuery = useEdgeTicketsQuery(ticketsArgs, {
    enabled: !isNaN(id)
  });

  const onUpdate = (newEdge: IEdgeInfo): void => {
    queryClient.setQueryData([WebCacheKey.EDGE, id], newEdge);
    queryClient.invalidateQueries([WebCacheKey.AUDIT_LOGS_INFINITE, id]);
  };

  const edge = edgeQuery.data;
  const getUserIds = (): number[] | undefined => {
    const ids = [];
    edge?.assignee?.id && ids.push(edge.assignee?.id);
    edge?.createdBy?.id && ids.push(edge.createdBy?.id);
    edge?.userUpdatedBy?.id && ids.push(edge.userUpdatedBy?.id);

    return ids.length ? ids : undefined;
  };
  const userListData = useUserBatchQuery(getUserIds());

  useEffect(() => {
    setTicketsArgs(defaultTicketArgs(id));
  }, [id]);

  useEffect(() => {
    if (!!edge) {
      addRecentEdge(edge);
      addLabels(edge.labels);
    }
  }, [edge, addLabels, addRecentEdge]);

  if ((!edge && !edgeQuery.isLoading) || isNaN(id)) {
    return <NotFoundPage />;
  }

  return (
    <>
      <Helmet>
        <title>{edge && edge.name}</title>
      </Helmet>
      <WithLoading isLoading={edgeQuery.isLoading} fallback={<LoadingPage />}>
        {edge && (
          <div className="edge-page">
            <EdgeProvider edge={edge}>
              <EdgeContent
                edge={edge}
                ticketsArgs={ticketsArgs}
                setTicketsArgs={setTicketsArgs}
                edgeTicketsQuery={edgeTicketsQuery}
                onUpdateEdge={onUpdate}
                onRevalidate={() => edgeQuery.refetch()}
                userListData={userListData.data ?? []}
              />
            </EdgeProvider>
          </div>
        )}
      </WithLoading>
      {edgeQuery.error && (
        <Toast type={ToastType.ERROR}>Something went wrong.</Toast>
      )}
    </>
  );
};

function defaultTicketArgs(id: number): TicketArgs {
  return {
    page: 1,
    perPage: 6,
    sortBy: toSortString([{ id: "createdOn", desc: true }]),
    edge: id,
    textQuery: undefined
  };
}

export { EdgePage };
