import { Button } from "@aptedge/lib-ui/src/components/Button/Button";
import { Feedback } from "@aptedge/lib-ui/src/components/Feedback/Feedback";
import { Select } from "@aptedge/lib-ui/src/components/Select/Select";
import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import { ILabelSetting } from "@aptedge/lib-ui/src/types/entities";
import classNames from "classnames";
import { useFormik } from "formik";
import React, { createRef, useEffect } from "react";
import { useMutation } from "react-query";
import * as Yup from "yup";
import { createPhrase } from "../../../clients/Phrases/createPhrase";
import WithLoading from "../../../components/WithLoading/WithLoading";

interface Props {
  className?: string;
  labels: ILabelSetting[];
  onSave: () => void;
  onCancel: () => void;
}

interface FormState {
  term: string;
  label: ILabelSetting | null;
}

const PhraseCreateItem: React.FC<Props> = (props) => {
  const { className, labels, onCancel, onSave } = props;

  const inputRef = createRef<HTMLInputElement>();

  const addPhrase = useMutation(createPhrase, { onSuccess: onSave });

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik<FormState>({
    initialValues: {
      term: "",
      label: null
    },
    onSubmit: (values, helpers) => {
      if (!values.label || !values.label.rule) {
        helpers.setFieldError(
          "label",
          "Unable to create a phrase for this label."
        );
        return;
      }
      addPhrase.mutate({ value: values.term, ruleId: values.label.rule.id });
    },
    validateOnBlur: false,
    validationSchema: Yup.object({
      term: Yup.string().required("A search term is required."),
      label: Yup.mixed().required("A label is required.")
    })
  });

  return (
    <WithLoading
      isLoading={addPhrase.isLoading}
      type="overlay"
      fallback={<Spinner />}
    >
      <div className={classNames("label-create-item p-2", className)}>
        <h5>Create a new search term</h5>
        <form onSubmit={formik.handleSubmit}>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="term">Search term</label>
              <input
                id="term"
                type="text"
                ref={inputRef}
                className={classNames("form-control", {
                  "is-invalid": !!formik.errors.term
                })}
                aria-describedby="termHelp"
                value={formik.values.term}
                onChange={formik.handleChange}
              />
              {formik.errors.term && <Feedback>{formik.errors.term}</Feedback>}
              <small id="termHelp" className="form-text text-muted">
                Choose a name for the label
              </small>
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="label">Label</label>
              <Select
                data={labels}
                getOptionLabel={(l) => l.name}
                value={formik.values.label || undefined}
                error={formik.errors.label}
                onChange={(l) => formik.setFieldValue("label", l)}
              />
              {formik.errors.term && <Feedback>{formik.errors.term}</Feedback>}
              <small id="termHelp" className="form-text text-muted">
                Choose the label we should apply to tickets with content
                matching this search term.
              </small>
            </div>
          </div>

          {addPhrase.error && (
            <Feedback>{(addPhrase.error as Error).message}</Feedback>
          )}

          <div className="d-flex mt-2 justify-content-end">
            <Button
              type="button"
              className="mr-2"
              color="secondary"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Save
            </Button>
          </div>
        </form>
      </div>
    </WithLoading>
  );
};

export { PhraseCreateItem };
