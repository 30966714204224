import { getBackendApiUrl } from "../../config";
import { deleteRequest } from "../../utils/request";
import { deleteInit } from "../utils/buildRequest";

async function deleteSavedQuery(id: number): Promise<void> {
  const url = `${getBackendApiUrl()}/saved-filters/${id}/`;
  return deleteRequest(url, deleteInit());
}

export { deleteSavedQuery };
