import { ModalWidth } from "@aptedge/lib-ui/src/components/Modal/Modal";
import { Spinner } from "@aptedge/lib-ui/src/components/Spinner/Spinner";
import {
  IJiraCloudInternalIssueSource,
  IntegrationStatus
} from "@aptedge/lib-ui/src/types/entities";
import { GATEWAY_TIMEOUT } from "http-status-codes";
import React, { useState } from "react";
import { useMutation } from "react-query";
import { ToastType } from "react-toastify";
import { createJiraCloudInternalIssueSource } from "../../../clients/InternalIssueSources/createJiraCloudInternalIssueSource";
import { ConfirmationModal } from "../../../components/ConfirmationModal/ConfirmationModal";
import { Toast } from "../../../components/Toast/Toast";
import WithLoading from "../../../components/WithLoading/WithLoading";
import { getBackendUrl } from "../../../config";
import { ClientStatus } from "../../../types/clients";
import { AddButton } from "../common/AddButton";
import { StatusButton } from "../common/StatusButton";
import { providerIsAuthorized } from "../utils/providerIsAuthorized";
import { JiraCloudConfigForm, JiraCloudFormData } from "./JiraCloudConfigForm";
interface Props {
  issueResource: IJiraCloudInternalIssueSource | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any;
  isLoading: boolean;
}

const JiraCloudConfig: React.FunctionComponent<Props> = (props: Props) => {
  const { issueResource, isLoading, error } = props;

  const [showModal, setShowModal] = useState<boolean>(false);
  const [redirectStatus, setRedirectStatus] = React.useState(
    ClientStatus.PROMPT
  );

  const internalIssueSource = issueResource ?? null;

  const addSource = useMutation(createJiraCloudInternalIssueSource, {
    onError: () => {
      setRedirectStatus(ClientStatus.ERROR);
    },
    onSuccess: () => {
      setRedirectStatus(ClientStatus.SUCCESS);
      const url = `${getBackendUrl()}/jira/login`;

      window.location.href = url;
    }
  });

  const onFormSubmit = (formData: JiraCloudFormData): void => {
    setRedirectStatus(ClientStatus.LOADING);
    addSource.mutate(formData);
  };

  const getStatus = (): IntegrationStatus => {
    if (error?.code === GATEWAY_TIMEOUT) return "timeOut";
    if (providerIsAuthorized(internalIssueSource)) return "ok";
    return "error";
  };

  const isConnectionExist = internalIssueSource || getStatus() === "timeOut";
  const isLoaderVisible = redirectStatus === ClientStatus.LOADING || isLoading;

  return (
    <>
      <div className="mb-3">
        <h5>Jira Cloud</h5>
        <p>Configure your Jira Cloud settings below.</p>
      </div>
      <WithLoading
        isLoading={isLoaderVisible}
        fallback={
          <div className="d-flex justify-content-center m-4">
            <Spinner />
          </div>
        }
      >
        {isConnectionExist ? (
          <StatusButton
            status={getStatus()}
            onClickEdit={() => setShowModal(true)}
          >
            <>
              <div>Jira</div>
              <div className="text-muted">api.atlassian.net</div>
            </>
          </StatusButton>
        ) : (
          <AddButton onClick={() => setShowModal(true)}>Connect</AddButton>
        )}
      </WithLoading>

      <ConfirmationModal
        title="Jira OAuth Settings"
        width={ModalWidth.MEDIUM}
        loadingTitle="Redirecting to Atlassian..."
        status={redirectStatus}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      >
        <p className="mb-4">Configure your settings below.</p>
        <JiraCloudConfigForm
          source={internalIssueSource}
          onSubmit={onFormSubmit}
        />
      </ConfirmationModal>
      {addSource.isError && (
        <Toast type={ToastType.ERROR}>Something went wrong.</Toast>
      )}
    </>
  );
};

JiraCloudConfig.defaultProps = {
  issueResource: null
};

export { JiraCloudConfig };
