import {
  LazyImport,
  LazyLoad
} from "@aptedge/lib-ui/src/components/LazyLoad/LazyLoad";
import { isProd } from "@aptedge/lib-ui/src/utils/env";
import React, { useRef } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { isLocal } from "../../config";

/**
 *  Using a dynamic import here so that Webpack will split the DevTools code into
 *  a separate JS bundle that's only loaded on local, only when the flag is enabled.
 *
 *  Reasons:
 *    1. Keep bundle size down. DevTools is currently very small, but may grow in size.
 *    2. Hide sensitive code. All dev tools code is hidden (not loaded by the browser) until the flag is set.
 **/
const importFn: LazyImport = () => import("./DevTools");

interface Props {
  className?: string;
}

const LazyDevTools: React.FC<Props> = (props) => {
  const { className } = props;

  const showDevTools = useRef(!isProd() && isLocal());

  if (!showDevTools.current) return null;

  return (
    <ErrorBoundary fallbackRender={() => null}>
      <LazyLoad importFn={importFn} className={className} fallback={null} />
    </ErrorBoundary>
  );
};

export { LazyDevTools };
