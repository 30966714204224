import {
  EntityType,
  IPhrase,
  IRule,
  LabelEntityType
} from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import { ICreateLabelArgs } from "../../types/clients";
import { request } from "../../utils/request";
import { createPhrase } from "../Phrases/createPhrase";
import { post } from "../utils/buildRequest";

function getCreateLabelUrl(type: LabelEntityType): string {
  const base = getBackendApiUrl();

  switch (type) {
    case EntityType.PRODUCT:
      return `${base}/products/`;
    case EntityType.FEATURE:
      return `${base}/features/`;
    case EntityType.THEME:
      return `${base}/themes/`;
    default:
      throw new Error(`Unsupported entity type when creating a label: ${type}`);
  }
}

async function createLabel({
  type,
  ...args
}: ICreateLabelArgs & { type: LabelEntityType }): Promise<{
  id: number;
  name: string;
  phrase: IPhrase;
}> {
  const url = getCreateLabelUrl(type);
  const createLabelArgs = Object.keys(args).reduce(
    (memo: { [key: string]: string | number }, k) => {
      if (k !== "term") {
        memo[k] = args[k as keyof typeof args];
      }

      return memo;
    },
    {}
  );
  const options = post(createLabelArgs);

  return request<{
    id: number;
    name: string;
    rule: IRule;
  }>(url, options).then((entity) => {
    return createPhrase({
      ruleId: entity.rule.id,
      value: args.term
    }).then((phrase) => ({
      id: entity.id,
      name: entity.name,
      phrase
    }));
  });
}

export { createLabel };
