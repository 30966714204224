import { Avatar } from "@aptedge/lib-ui/src/components/Avatar/Avatar";
import {
  IRichTextContent,
  TextEditor
} from "@aptedge/lib-ui/src/components/TextEditor/TextEditor";
import capitalize from "lodash/capitalize";
import React from "react";
import { useAuth } from "../../context/AuthContext";

interface Props {
  submitNewComment: (text: IRichTextContent) => void;
}

const EdgeNewComment: React.FC<Props> = ({ submitNewComment }) => {
  const { user } = useAuth();

  return (
    <div className="new-comment">
      <Avatar
        color="var(--primary)"
        text={capitalize(user?.email) || "User"}
        tooltip={user?.email || "User"}
        size="42px"
      />
      <TextEditor
        content={null}
        placeholder="Write a comment..."
        onSave={submitNewComment}
        isImageDropAllowed={false}
      />
    </div>
  );
};

export { EdgeNewComment };
