import { CopyButton } from "@aptedge/lib-ui/src/components/CopyButton/CopyButton";
import { AeIcons } from "@aptedge/lib-ui/src/components/Icon/AeIcons";
import { LightTooltip } from "@aptedge/lib-ui/src/components/Tooltip/LightTooltip";
import { useAppSelector } from "@aptedge/lib-ui/src/redux/hook/hook";
import {
  SearchTelemetryEvent,
  DOCUMENT_SOURCE_TYPE,
  SEARCH_RESULT_TYPE,
  ICompositeResult
} from "@aptedge/lib-ui/src/types/entities";
import { GTM_EVENTS, dataLayerPush } from "@aptedge/lib-ui/src/utils/event";
import {
  calculatePosition,
  generateTopSearchResults
} from "@aptedge/lib-ui/src/utils/generator";
import { History } from "history";
import { FC } from "react";
import useTabHistory from "../../hooks/useTabHistory";
import {
  toEdgePath,
  toGeneratedKnowledgePath,
  toTicketPath
} from "../../routes/toPath";
import "./searchResultCardActionIcons.scss";
interface IProps {
  searchResult: ICompositeResult;
}
const PER_PAGE = 10;

const SearchResultCardActionIcons: FC<IProps> = ({ searchResult }) => {
  const { tabHistory } = useTabHistory();
  const { searchQuery, searchResults, totalSearchResults } = useAppSelector(
    (state) => state.search
  );

  const eventObj: SearchTelemetryEvent = {
    search_query: searchQuery,
    top_search_results: generateTopSearchResults(searchResults),
    total_search_results: totalSearchResults,
    search_result_position:
      searchResults.findIndex((result) => result.id === searchResult.id) + 1
  };

  return (
    <div
      className="search-result-card-action-icons"
      onClick={(e) => e.stopPropagation()}
    >
      <span className="copy-button-container">
        <LightTooltip title="Copy" placement="top" arrow>
          <div>
            <CopyButton
              text={onCopyIconClick(searchResult)}
              event={
                !!searchResult.type
                  ? GTM_EVENTS.GTM_SEARCH_RESULT_LIST_TEXT_COPIED
                  : GTM_EVENTS.GTM_EDGE_TICKET_COPIED
              }
            />
          </div>
        </LightTooltip>
      </span>

      {!!searchResult.type && (
        <LightTooltip title="Open In New Tab" placement="top" arrow>
          <div>
            <AeIcons.OpenLink
              onClick={() =>
                onLinkIconClick(searchResult, tabHistory, eventObj)
              }
              data-testid={`search-result-card-open-link-icon-${searchResult.type}-${searchResult.id}`}
            />
          </div>
        </LightTooltip>
      )}
    </div>
  );
};

const HOST = window.location.host;
const onCopyIconClick = (searchResult: ICompositeResult): string => {
  switch (searchResult.type) {
    case SEARCH_RESULT_TYPE.EDGE:
      return `${HOST}${toEdgePath(searchResult.id)}`;
    case SEARCH_RESULT_TYPE.DOCUMENT:
      if (searchResult.sourceType === DOCUMENT_SOURCE_TYPE.EDGE)
        return `${HOST}${toEdgePath(Number(searchResult.sourceId))}`;
      return searchResult.displayUrl;
    case SEARCH_RESULT_TYPE.TICKET:
      return `${HOST}${toTicketPath(Number(searchResult.id))}`;
    case SEARCH_RESULT_TYPE.GENERATED_KNOWLEDGE:
      return `${HOST}${toGeneratedKnowledgePath(String(searchResult.id))}`;

    default:
      if ("displayUrl" in searchResult) return searchResult.displayUrl;
      if ("url" in searchResult) return searchResult.url;
      return "";
  }
};

const onLinkIconClick = (
  searchResult: ICompositeResult,
  tabHistory: History<History.PoorMansUnknown>,
  eventObj: SearchTelemetryEvent
): void => {
  const search_result_position_computed = eventObj.search_result_position
    ? eventObj.search_result_position
    : 0;
  dataLayerPush({
    event: GTM_EVENTS.GTM_SEARCH_RESULT_LIST_POPOUT,
    data: {
      search_result_type: searchResult.type,
      search_result_id: searchResult.id,
      search_query: eventObj.search_query,
      total_search_results: eventObj.total_search_results,
      top_search_results: eventObj.top_search_results,
      integration_type:
        searchResult.type === "edge" || searchResult.type === "ticket"
          ? ""
          : searchResult.sourceType,
      search_result_position: calculatePosition(
        search_result_position_computed,
        eventObj.page,
        PER_PAGE
      ),
      search_result_position_on_page: search_result_position_computed,
      page: eventObj.page
    }
  });
  switch (searchResult.type) {
    case SEARCH_RESULT_TYPE.EDGE:
      tabHistory.push(toEdgePath(searchResult.id));
      break;
    case SEARCH_RESULT_TYPE.TICKET:
      tabHistory.push(toTicketPath(Number(searchResult.id)));
      break;
    case SEARCH_RESULT_TYPE.DOCUMENT:
      if (searchResult.sourceType === DOCUMENT_SOURCE_TYPE.EDGE)
        tabHistory.push(toEdgePath(Number(searchResult.sourceId)));
      else window.open(searchResult.displayUrl, "_blank");
      break;
    case SEARCH_RESULT_TYPE.GENERATED_KNOWLEDGE:
      tabHistory.push(toGeneratedKnowledgePath(String(searchResult.id)));
      break;
    default:
      if ("displayUrl" in searchResult)
        window.open(searchResult.displayUrl, "_blank");
      else if ("url" in searchResult) window.open(searchResult.url, "_blank");
      break;
  }
};
export default SearchResultCardActionIcons;
