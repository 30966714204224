import { sanitizeUrl } from "@aptedge/lib-ui/src/utils/sanitizeUrl";
import classNames from "classnames";
import React from "react";
import Highlighter from "react-highlight-words";

interface Props {
  /** The class name to be applied to an active match. Use along with activeIndex */
  activeClassName?: string;
  /** Specify the match index that should be actively highlighted. Use along with activeClassName */
  activeIndex?: number;
  /** Escape characters in searchWords which are meaningful in regular expressions */
  autoEscape?: boolean;
  /** CSS class name applied to the outer/wrapper <span> */
  className?: string;
  /** CSS class name applied to highlighted text */
  highlightClassName?: string;
  /**
   * Type of tag to wrap around highlighted matches; defaults to mark but can also be a React element
   * (class or functional)
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  highlightTag?: (props: any) => JSX.Element;
  /**
   * Process each search word and text to highlight before comparing (eg remove accents); signature
   * (text: string): string
   */
  sanitize?: (text: string) => string;
  /** Array of search words. The search terms are treated as RegExps unless autoEscape is set. */
  searchWords: string[];
  /** Text to highlight matches in */
  textToHighlight: string;
  urls?: string[];
}

const Highlight: React.FC<Props> = ({
  autoEscape = true,
  className,
  searchWords,
  textToHighlight,
  urls = [],
  highlightTag,
  ...rest
}) => {
  const trimmedWords = searchWords.map((w) => w.trim());
  const allSearchWords = [...trimmedWords, ...urls];

  return (
    <Highlighter
      className={classNames("highlight", className)}
      autoEscape={autoEscape}
      searchWords={allSearchWords}
      textToHighlight={textToHighlight.trim()}
      highlightTag={
        highlightTag
          ? (props) => {
              if (urls.includes(props.children)) {
                const santizedUrl = sanitizeUrl(props.children);
                return (
                  <a
                    href={santizedUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {props.children}
                  </a>
                );
              }
              return highlightTag(props);
            }
          : undefined
      }
      {...rest}
    />
  );
};

export { Highlight };
