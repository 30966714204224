import { INotification } from "@aptedge/lib-ui/src/types/entities";
import { getBackendApiUrl } from "../../config";
import {
  IFetchNotificationArgs,
  IPaginatedRequest,
  IPaginatedResponse
} from "../../types/clients";
import { request } from "../../utils/request";
import { toQueryString } from "../utils/url";

async function fetchNotifications(
  args: IPaginatedRequest<IFetchNotificationArgs>
): Promise<IPaginatedResponse<INotification[]>> {
  const query = toQueryString(args);
  const url = `${getBackendApiUrl()}/users/notifications/?${query}`;

  return request(url);
}

export { fetchNotifications };
