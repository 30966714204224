import React, { useEffect } from "react";
import { Flip, toast, TypeOptions, ToastOptions } from "react-toastify";

interface ToastProps {
  id?: number | string;
  autoClose?: 2000 | 5000;
  onClose?: () => void;
  type: TypeOptions;
}

// A common ID to prevent multiple toasts from displaying if multiple API calls fail.
const TOAST_ID = "global-error-toast";
const REFRESH_TOAST_ID = "refresh-info-toast";

const TOAST_HEADER = (
  <div className="toast-header">
    <img className="mr-2" alt="logo" src="/trimmed-logo.png" height="14px" />
    <span>AptEdge</span>
  </div>
);

/**
 * A Wrapper Component for displaying a Toast. Use an `id` if you want to
 * prevent showing multiple toasts for the same error.
 *
 * ```
 * <Toast id={1} type={ToastType.INFO} >Some info!!</Toast>
 * ```
 */
const Toast: React.FunctionComponent<ToastProps> = ({
  id = TOAST_ID,
  type,
  autoClose = 5000,
  onClose,
  children
}) => {
  const renderContent = (
    <>
      {TOAST_HEADER}
      <div className="toast-body">{children}</div>
    </>
  );

  useEffect(() => {
    if (toast.isActive(id)) {
      toast.update(id, {
        render: renderContent,
        type,
        autoClose,
        transition: Flip
      });
    } else {
      toast(renderContent, {
        toastId: id,
        className: "toast",
        type,
        autoClose,
        onClose,
        transition: Flip
      });
    }
  }, [autoClose, renderContent, id, type, onClose]);

  return null;
};

function broadcastUpdateAlert(): void {
  const renderContent = (
    <>
      {TOAST_HEADER}
      <div className="toast-body" onClick={() => window.location.reload()}>
        We&apos;ve just deployed a new version of our app. Please click here to
        refresh.
      </div>
    </>
  );

  const options: ToastOptions = {
    type: "info",
    toastId: REFRESH_TOAST_ID,
    autoClose: false,
    transition: Flip,
    closeButton: false
  };

  if (toast.isActive(REFRESH_TOAST_ID)) {
    toast.update(REFRESH_TOAST_ID, {
      render: renderContent,
      ...options
    });
  } else {
    toast(renderContent, options);
  }
}

export { broadcastUpdateAlert, Toast };
