import {
  LocalStorageKeys,
  useLocalStorage
} from "@aptedge/lib-ui/src/hooks/useLocalStorage";
import { ICompositeResult } from "@aptedge/lib-ui/src/types/entities";
import { logger } from "@aptedge/lib-ui/src/utils/logger";
import { useCallback } from "react";
import {
  issueValidator,
  articleValidator,
  ticketValidator,
  edgeValiditor,
  socialValidator
} from "../GlobalSearchValidator";

const MAX_RECENTS = 10;

function useRecentSearch(): [
  ICompositeResult[] | null,
  (val: ICompositeResult) => void
] {
  const [recents, setRecents] = useLocalStorage<ICompositeResult[] | null>(
    LocalStorageKeys.RECENT_SEARCH
  );

  const addRecentSearch = useCallback(
    (recentSearches: ICompositeResult): void => {
      const previousState = recents ? [...recents] : [];
      const printLogs = (): undefined => {
        logger.warn(
          `Tried to store an invalid object in LocalStorage @ ${LocalStorageKeys.RECENT_SEARCH}`,
          recentSearches
        );
        return;
      };
      if (
        recentSearches.type === "edge" &&
        !edgeValiditor.isValidSync(recentSearches)
      ) {
        printLogs();
      } else if (
        recentSearches.type === "issue" &&
        !issueValidator.isValidSync(recentSearches)
      ) {
        printLogs();
      } else if (
        recentSearches.type === "article" &&
        !articleValidator.isValidSync(recentSearches)
      ) {
        printLogs();
      } else if (
        recentSearches.type === "ticket" &&
        !ticketValidator.isValidSync(recentSearches)
      ) {
        printLogs();
      } else if (
        recentSearches.type === "social" &&
        !socialValidator.isValidSync(recentSearches)
      ) {
        printLogs();
      }
      const idx = previousState.findIndex(
        (val) =>
          val.id === recentSearches.id && val.type === recentSearches.type
      );
      if (idx > -1) {
        previousState.splice(idx, 1);
      }
      setRecents([recentSearches, ...previousState.slice(0, MAX_RECENTS - 1)]);
    },
    [recents, setRecents]
  );

  return [recents, addRecentSearch];
}

export { useRecentSearch };
